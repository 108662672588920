import KPIStats from "./KPIStats.js";
import { authenticationOfTypeRequired } from "../../shared/routes/Authentication/Login/sagas";
import reducer from "./reducer";
import watchKPIStatsSagas from "./sagas";

export default {
  "/kpi-stats": {
    component: KPIStats,
    reducer: {
      ui: {
        KPIStats: reducer,
      },
    },
    saga: watchKPIStatsSagas,
    title: "KPI Stats",
    *onEnter() {
      yield authenticationOfTypeRequired(["admin"]);
    },
  },
};
