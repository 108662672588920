import { connect } from "react-redux";
import { get } from "lodash";
import {
  getFormValues,
  isPristine,
  isInvalid,
  formValueSelector,
} from "redux-form";

import {
  userType,
  userCustomerId,
} from "../../../shared/routes/Authentication/Login/reducer";

import { fleetingFormMeta } from "../../../shared/utils/form";
import { groupData, customerData, groupDataForAdmin } from "../reducer";
import {
  createPartnerUser,
  updatePartnerUser,
  requestGroupsAsAdmin,
  resetGroupOptions,
} from "../actions";

import PartnerUserForm from "./PartnerUserForm.jsx";

// this form component is used by usertype `admin` and `partner_admin` -- for both creating and updating. (the spec sort of evolved that way down the line)

function allRequiredFieldsFilledOut(state) {
  const formState = getFormValues("managePartnerUser")(state) || {};
  const { fullname, email, userable_type, customer_id, phone } = formState;
  return fullname && email && userable_type && customer_id;
}

function mapStateToProps(state, { formData }) {
  const isAdmin = userType(state) === "admin";
  // if `formData` is passed as a prop to this component, we know it's a form for updating, otherwise it's for creating
  const isUpdating = !!formData;
  const isCreating = !formData;

  let initialValues = formData;
  // we need to initialize the form with the customer id if it's a partner admin creating another partner admin, since api doesn't automatically figure that out
  if (userType(state) === "partner_admin" && isCreating) {
    initialValues = { customer_id: userCustomerId(state) };
  }

  const createDisabled =
    !allRequiredFieldsFilledOut(state) || isInvalid("managePartnerUser")(state);
  return {
    isAdmin,
    isUpdating,
    initialValues,
    profilePicture: initialValues?.profile_picture_id,
    ownerId: initialValues?.id,
    isEditingSelf:
      isUpdating && get(state, "userInfo.userable_id") === formData.id,
    customerId: formValueSelector("managePartnerUser")(state, "customer_id"),
    groupData: isAdmin ? groupDataForAdmin(state) : groupData(state),
    customerData: customerData(state),
    createDisabled,
    updateDisabled:
      isPristine("managePartnerUser")(state) ||
      !allRequiredFieldsFilledOut(state) ||
      isInvalid("managePartnerUser")(state),
    message: fleetingFormMeta(state, "managePartnerUser").message,
  };
}

const mapDispatchToProps = (dispatch, { formData }) => {
  const isUpdating = !!formData;
  return {
    onSubmit: (values) =>
      dispatch(
        isUpdating
          ? updatePartnerUser({
              ...values,
              requestUpdateRole:
                values.userable_type !== formData.userable_type,
            })
          : createPartnerUser(values)
      ),
    requestGroupsAsAdmin: (customerId) =>
      dispatch(requestGroupsAsAdmin(customerId)),
    resetGroupDataForAdmin: () => dispatch(resetGroupOptions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerUserForm);
