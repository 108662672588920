import axios from "axios";

export const fetchCustomers = () => axios.get("/api/admin/v1/customers/");

export const fetchCustomerTypes = () =>
  axios.get("/api/admin/v1/customers/types/");

export const fetchCustomerDetails = (customerId) =>
  axios.get(`api/admin/v1/customers/${customerId}`);

export const fetchCustomerSelectFields = (customerId) =>
  axios.get(`api/admin/v1/customers/${customerId}/external_fields/select`);

export const fetchCustomerSelectField = ({ customerId, fieldName }) =>
  axios.get(
    `api/admin/v1/customers/${customerId}/external_fields/select/${fieldName}`
  );

export const createCustomer = ({ send_notifications, ...values }) =>
  axios.post("/api/admin/v1/customers/", {
    settings: { send_notifications },
    ...values,
  });

export const updateCustomer = ({ customerId, send_notifications, ...values }) =>
  axios.patch(`/api/admin/v1/customers/${customerId}`, {
    settings: { send_notifications },
    ...values,
  });

export const createFieldValue = ({ customerId, fieldName, ...values }) =>
  axios.post(
    `api/admin/v1/customers/${customerId}/external_fields/select/${fieldName}`,
    values
  );

export const updateFieldValue = ({
  customerId,
  fieldName,
  fieldValue,
  ...values
}) => {
  const encodedVal = encodeURIComponent(fieldValue);
  return axios.put(
    `api/admin/v1/customers/${customerId}/external_fields/select/${fieldName}/${encodedVal}`,
    values
  );
};
