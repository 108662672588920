import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import { validateForm } from "../../../../../shared/utils/form";
import { emailRegexValidator } from "../../";

import SimpleField from "../../../../../shared/components/FormFields/SimpleField";
import MultiSelectField from "../../../../../shared/components/FormFields/MultiSelectField";
import MultiInputField from "../../../../../shared/components/FormFields/MultiInputField";

const rules = {
  name: {
    presence: true,
  },
};

class CreateClinicianForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      programListState: this.props.programList,
    };
  }

  render() {
    const { handleSubmit, onSubmit, onCancel, message } = this.props;
    const { programListState } = this.state;
    return (
      <form className="create-clinician-form" onSubmit={handleSubmit(onSubmit)}>
        <h3 className="form-header">Add New Clinician</h3>

        <div className="input-field form-group">
          <label htmlFor="name">Name *</label>
          <Field
            className="checkbox-field"
            name="name"
            component={SimpleField}
          />
        </div>

        <Field
          name="programs"
          label="Programs"
          component={MultiSelectField}
          values={programListState}
        />

        <Field
          name="emails"
          label="Emails"
          component={MultiInputField}
          regexValidator={emailRegexValidator}
        />

        <div className="input-field form-group">
          <label htmlFor="npi">NPI</label>
          <Field
            className="checkbox-field"
            name="npi"
            component={SimpleField}
          />
        </div>

        <input className="action-btn red" type="submit" value="Create" />
        <button className="action-btn" onClick={onCancel}>
          Cancel
        </button>

        {message && <p className="message">{message}</p>}
      </form>
    );
  }
}

CreateClinicianForm.propTypes = {
  programList: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string,
  // redux-form
  handleSubmit: PropTypes.func.isRequired,
};

CreateClinicianForm.defaultProps = {
  programList: null,
  message: null,
};

export default reduxForm({
  form: "manageClinician",
  validate: validateForm(rules),
  enableReinitialize: true,
})(CreateClinicianForm);
