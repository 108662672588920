import { createAction } from "redux-actions";

const prefixed = (val) => `Programs/ProgramsForm::${val}`;

export const deleteProgram = createAction(
  prefixed("DELETE_PROGRAM", (programId) => programId)
);
export const didDeleteProgram = createAction(
  prefixed("DID_DELETE_PROGRAM", (programId) => programId)
);

export const restoreProgram = createAction(
  prefixed("RESTORE_PROGRAM", (programId) => programId)
);
export const didRestoreProgram = createAction(
  prefixed("DID_RESTORE_PROGRAM", (programId) => programId)
);
