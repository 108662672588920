import { useState } from "react";
import UpdateLanguageComponent from "./UpdateLanguage.jsx";
import PropTypes from "prop-types";

export const availableLanguages = [
  { label: "English", value: "en" },
  { label: "Spanish", value: "es" },
];

function UpdateLanguage({
  userId,
  currentLanguage,
  updateLanguageRequest,
  didUpdateLanguage,
}) {
  const [message, setMessage] = useState("");

  async function onLanguageChange(newLanguage) {
    setMessage("");

    const response = await updateLanguageRequest(userId, newLanguage);
    if (response.status === 200) {
      setMessage("Language updated");
      didUpdateLanguage(userId, newLanguage);
    } else {
      setMessage("Language could not be updated");
    }
  }

  return (
    <UpdateLanguageComponent
      updateLanguage={onLanguageChange}
      currentLanguage={currentLanguage}
      message={message}
      availableLanguages={availableLanguages}
    />
  );
}

UpdateLanguage.propTypes = {
  userId: PropTypes.string.isRequired,
  currentLanguage: PropTypes.string,
  updateLanguageRequest: PropTypes.func.isRequired, // (userId, language) => axios response
  didUpdateLanguage: PropTypes.func.isRequired, // function format (userId, language) => ..
};

export default UpdateLanguage;
