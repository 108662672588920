import { sortBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import SplitPane from "react-split-pane";
import BreadCrumbBar from "../../../../components/BreadCrumbBar/BreadCrumbBar";
import Set from "../../../../shared/components/Data/Set/index";
import CardInteractionsWorker from "./components/CardInteractionsWorker/CardInteractionsWorker";
import Engagement from "./components/Engagement";

import "./SplitPane.css";
import "./UserView.css";
import styles from "./WorkerView.module.css";

const titleStyle = {
  color: "white",
  fontSize: "24px",
};

const dateFormat = "MM/DD/YYYY";

import { eventShape } from "./propTypes";

class WorkerView extends React.Component {
  componentDidMount() {
    this.fetchData(this.props);
  }

  fetchData(props) {
    const { selectedId, fetchWorkerData } = props;
    fetchWorkerData(selectedId);
  }

  renderSidebar() {
    const { user } = this.props;

    const formattedCreatedAt =
      user.created_at && moment(user.created_at).format(dateFormat);

    return (
      <div>
        <h1 style={titleStyle}>{user.about.name}</h1>
        <Set
          className="program"
          label="Program"
          value={user.formal_program_name}
        />
        <Set
          classname="invited"
          label="Assigned on"
          value={formattedCreatedAt}
        />
      </div>
    );
  }

  renderPageContent() {
    const { user, selectedId, engagementScore } = this.props;
    if (!user) {
      return null;
    }

    return (
      <div>
        <div className="engagement-card-interaction-group">
          <div className={`child-1 ${styles["first-row"]}`}>
            <Engagement score={engagementScore} />
            <CardInteractionsWorker workerId={selectedId} />
          </div>
        </div>
      </div>
    );
  }

  renderStates() {
    // Was called events... so
    let { events } = this.props;

    events = sortBy(events, (e) => -e.weight);

    if (!events || events.length === 0) {
      return null;
    }
    return (
      <ul className="user-events">
        {events.map((event) => (
          // bad to use a random number for the key but there is no way to make a unique identifier with the 'event' prop's properties
          <li key={Math.random()} className={event.effect}>
            {event.message}
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const { user, goListWorkers, sidebarWidth, storeSidebarWidth } = this.props;
    if (!user) {
      console.warn("no user");
      return null;
    }
    return (
      <div className="page-container with-sidebar">
        <BreadCrumbBar
          path={[
            { label: "All Workers", href: goListWorkers },
            { label: user.about.name },
          ]}
        />
        <SplitPane
          split="vertical"
          minSize={200}
          defaultSize={sidebarWidth}
          maxSize={400}
          onChange={storeSidebarWidth}
        >
          <div className="sidebar">{this.renderSidebar()}</div>

          <div className="page-content user-view">
            {this.renderStates()}
            <div className="limit-width">{this.renderPageContent()}</div>
          </div>
        </SplitPane>
      </div>
    );
  }
}

WorkerView.propTypes = {
  userType: PropTypes.oneOf(["peer", "invite"]).isRequired,
  user: PropTypes.object, // Determine shape -- for peer, peer data, for invite, invite data
  profile: PropTypes.object, // Determine shape, for peer true profile data, for invite stub
  cardInteractions: PropTypes.array,
  selectedId: PropTypes.string.isRequired,
  authenticatedUserType: PropTypes.string,
  fetchData: PropTypes.func.isRequired,
  fetchHazardPictures: PropTypes.func,
  fetchCareTeam: PropTypes.func,
  fetchOwner: PropTypes.func,
  deviceInfo: PropTypes.object,
  hazardDetails: PropTypes.bool,
  hazardScore: PropTypes.arrayOf(PropTypes.number),
  hazardPictures: PropTypes.arrayOf(PropTypes.object),
  areHazardImagesFetched: PropTypes.bool,
  externalService: PropTypes.string,
  externalServiceSettings: PropTypes.array,
  extraFields: PropTypes.any, // Todo: What?
  events: PropTypes.arrayOf(PropTypes.shape(eventShape)),
  goListWorkers: PropTypes.func.isRequired,

  externalServiceComponent: PropTypes.any.isRequired,
  actionComponent: PropTypes.any,

  storeSidebarWidth: PropTypes.func.isRequired,
  sidebarWidth: PropTypes.number.isRequired,

  // WORKER
  fetchWorkerData: PropTypes.func.isRequired,
};

WorkerView.defaultProps = {
  user: null,
  profile: null,
  externalService: null,
  hazardScore: null,
  hazardPictures: null,
  externalServiceSettings: null,
  warnings: [],
  actionComponent: null,
  areHazardImagesFetched: false,
};

export default WorkerView;
