import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Link } from "redux-little-router";

import BreadCrumbBar from "../../../components/BreadCrumbBar";

import "./BillingReport.css";
import DateInputField from "../../../shared/components/DateInputField";

class BillingReport extends React.Component {
  constructor(props) {
    super(props);
    this._onChangeStartDate = this._onChangeStartDate.bind(this);
    this._onChangeEndDate = this._onChangeEndDate.bind(this);
    this._onChangeAmount = this._onChangeAmount.bind(this);
  }

  componentDidMount() {
    const { requestCustomers } = this.props;
    requestCustomers();
  }

  componentWillReceiveProps(nextProps) {
    const {
      selectedCustomer,
      requestReport,
      startDate,
      endDate,
      amount,
      updateStartDate,
      updateEndDate,
      updateAmount,
    } = this.props;
    if (
      nextProps.selectedCustomer &&
      selectedCustomer !== nextProps.selectedCustomer
    ) {
      updateAmount("30");
      updateStartDate(moment().startOf("month"));
      updateEndDate(moment().endOf("month"));
    } else if (nextProps.startDate && startDate !== nextProps.startDate) {
      if (endDate > nextProps.startDate) {
        requestReport({
          customerId: nextProps.selectedCustomer.id,
          startDate: nextProps.startDate,
          endDate,
          amount,
        });
      }
    } else if (nextProps.endDate && endDate !== nextProps.endDate) {
      if (nextProps.endDate > startDate) {
        requestReport({
          customerId: nextProps.selectedCustomer.id,
          startDate,
          endDate: nextProps.endDate,
          amount,
        });
      }
    } else if (nextProps.amount && amount !== nextProps.amount) {
      if (endDate && startDate) {
        requestReport({
          customerId: selectedCustomer.id,
          startDate,
          endDate,
          amount: nextProps.amount,
        });
      }
    }
  }

  _onChangeStartDate(timestamp) {
    const { updateStartDate } = this.props;
    updateStartDate(timestamp);
  }

  _onChangeEndDate(timestamp) {
    const { updateEndDate } = this.props;
    updateEndDate(timestamp);
  }

  _onChangeAmount(event) {
    const { updateAmount } = this.props;
    updateAmount(event.target.value);
  }

  _renderExportButton() {
    const { selectedCustomer, report, startDate, endDate } = this.props;
    const date = moment(new Date().getTime()).format("MM-DD-YYYY");
    if (report && endDate > startDate) {
      return (
        <CSVLink
          data={report}
          filename={`${selectedCustomer.name} Billing Report ${date}.csv`}
          className="btn btn-primary matrix-export-button"
          target="_blank"
        >
          Export {selectedCustomer.name} Billing Report to CSV
        </CSVLink>
      );
    }
    return null;
  }

  preventNonNumericalInput(e) {
    const charCode = typeof e.which === "undefined" ? e.keyCode : e.which;
    const charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/)) {
      e.preventDefault();
    }
  }

  renderCalendars() {
    const { selectedCustomer, startDate, endDate } = this.props;

    const minEndDate = moment(startDate).add(1, "days");
    const maxEndDate = moment().endOf("month");
    const maxStartDate = moment(endDate).subtract(1, "days");

    if (selectedCustomer) {
      return (
        <div className="calendars">
          <div className="billing-container amount-container">
            <label className="no-date-label">Amount ($)</label>
            <input
              type="number"
              placeholder="Choose Amount"
              defaultValue={30}
              min={0}
              onKeyPress={this.preventNonNumericalInput}
              onChange={this._onChangeAmount}
            />
          </div>
          <div className="billing-container">
            <label className="no-date-label">Start Date</label>
            <DateInputField
              className="inline date-field"
              value={startDate}
              onDateChange={this._onChangeStartDate}
              onMonthChange={this._onChangeStartDate}
              maxDate={maxStartDate}
            />
          </div>
          <div className="billing-container">
            <label className="no-date-label">End Date</label>
            <DateInputField
              className="inline date-field"
              value={endDate}
              onDateChange={this._onChangeEndDate}
              onMonthChange={this._onChangeEndDate}
              minDate={minEndDate}
              maxDate={maxEndDate}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  renderDescription() {
    return (
      <div className="billing-report-description">
        <h1>Billing Report</h1>
        <h2>Enrollment by partner for the purposes of creating invoices</h2>
      </div>
    );
  }

  renderCustomerDropdown() {
    const { customers, selectedCustomer } = this.props;

    if (selectedCustomer) {
      return (
        <BreadCrumbBar>
          <div className="dropdown">
            <span
              className="dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {selectedCustomer.name} <span className="caret" />
            </span>
            <ul className="dropdown-menu">
              {customers.map((customer) => (
                <li key={customer.id}>
                  <Link href={`/billing-report/${customer.id}`}>
                    {customer.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </BreadCrumbBar>
      );
    }
    return null;
  }

  render() {
    return (
      <div>
        {this.renderCustomerDropdown()}
        <div className="content-container">{this._renderExportButton()}</div>
        {this.renderDescription()}
        {this.renderCalendars()}
      </div>
    );
  }
}

BillingReport.propTypes = {
  customers: PropTypes.array.isRequired,
  selectedCustomer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  report: PropTypes.string,
  requestCustomers: PropTypes.func.isRequired,
  requestReport: PropTypes.func.isRequired,
  updateStartDate: PropTypes.func.isRequired,
  updateEndDate: PropTypes.func.isRequired,
  updateAmount: PropTypes.func.isRequired,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  amount: PropTypes.string,
};

BillingReport.defaultProps = {
  selectedCustomer: null,
  startDate: null,
  endDate: null,
  amount: null,
  report: null,
};

export default BillingReport;
