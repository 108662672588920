import { all, takeLatest } from "redux-saga/effects";

import { watchPeerViewSagas } from "./View/sagas";
import { watchCardInteractionsSaga } from "../components/UserView/components/CardInteractions/saga";
import {
  getExternalServiceFields,
  didGetExternalServiceFields,
} from "./actions";
import { fetchExternalServiceFields } from "./api";
import { callAPI } from "../../../shared/utils/sagas";

function* watchPeersSaga() {
  yield takeLatest(
    getExternalServiceFields,
    callAPI(fetchExternalServiceFields, didGetExternalServiceFields)
  );
}

export default function* () {
  yield all([
    watchPeerViewSagas(),
    watchPeersSaga(),
    watchCardInteractionsSaga(),
  ]);
}
