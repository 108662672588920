import React from "react";
import PropTypes from "prop-types";

import Assessment from "./components/Assessment/Assessment";

import { partition } from "../../../../../../shared/utils/partition";

import "./Assessments.css";

class Assessments extends React.Component {
  renderRow(row, index) {
    return <div className="row" key={index}></div>;
  }

  renderAssessment(assessment) {
    return (
      <div key={assessment.title}>
        <Assessment assessment={assessment} />
      </div>
    );
  }

  render() {
    const { assessments } = this.props;

    return (
      <div className="assessment-scores">
        <h1>Patient reported assessments</h1>

        <ul className="assessment-container">
          {!assessments || assessments.length === 0 ? (
            <p>User has not filled in assessments</p>
          ) : (
            assessments.map((assessment) => this.renderAssessment(assessment))
          )}
        </ul>
      </div>
    );
  }
}

Assessments.propTypes = {
  assessments: PropTypes.array,
};

export default Assessments;
