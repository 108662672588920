import { createAction } from "redux-actions";

const prefixed = (val) => `PHASES::${val}`;

export const fetchPhase = createAction(prefixed("FETCH_PHASE"), (phaseId) => ({
  phaseId,
}));
export const didFetchPhase = createAction(prefixed("DID_FETCH_PHASE"));

export const fetchPhasesList = createAction(
  prefixed("FETCH_PHASES_LIST"),
  (deleted, filter, page) => ({ deleted, filter, page })
);
export const didFetchPhasesList = createAction(
  prefixed("DID_FETCH_PHASES_LIST"),
  (phasesList, count) => ({ phasesList, count })
);
