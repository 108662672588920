import config from "../../config";

export function blockMobileIfNeeded() {
  const allowMobile = config.ALLOW_MOBILE;
  // If we detect we are on any mobile platform we have an app for, force redirect to the app instead
  if (!allowMobile || Array.isArray(allowMobile)) {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // Allowing to have just some urls with mobile support
    if (Array.isArray(allowMobile)) {
      if (allowMobile.indexOf(window.location.pathname) !== -1) {
        return;
      }
    }

    if (isMobile) {
      return window.location.replace(`${config.API_URL}${"/app_router"}`);
    }
  }
}
