import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";

import DrillDownSelectField from "../../../shared/components/FormFields/DrillDownSelectField";
import SimpleField from "../../../shared/components/FormFields/SimpleField";
import CheckboxField from "../../../shared/components/FormFields/CheckboxField";

import "./ManageStaff.css";

/* eslint-disable no-mixed-operators */

class ManageStaff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      isBulkCreating: false,
      searchKeyword: "",
      csv: null,
    };
    this.onSelectStaff = this.onSelectStaff.bind(this);
    this.onDeselectStaff = this.onDeselectStaff.bind(this);
    this.onDeselectClinician = this.onDeselectClinician.bind(this);
    this.onUpdateStaff = this.onUpdateStaff.bind(this);
    this.onCreateStaff = this.onCreateStaff.bind(this);
    this.onSubmitCSV = this.onSubmitCSV.bind(this);
    this.renderStaffListItem = this.renderStaffListItem.bind(this);
  }

  componentDidMount() {
    const {
      clinicianData,
      requestCustomers,
      selectedClinicianId,
      requestStaff,
    } = this.props;

    if (!clinicianData.length) {
      requestCustomers();
    }

    if (selectedClinicianId) {
      requestStaff(selectedClinicianId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selectedClinicianId,
      requestStaff,
      deselectStaff,
      message,
      resetStaffForm,
    } = this.props;

    if (
      selectedClinicianId &&
      prevProps.selectedClinicianId !== selectedClinicianId
    ) {
      requestStaff(selectedClinicianId);
    }

    if (prevProps.message && !message) {
      deselectStaff();
      resetStaffForm();
      this.setState({ isCreating: false, isBulkCreating: false });
    }
  }

  onSelectStaff(staffId) {
    const { selectStaff } = this.props;
    selectStaff(staffId);
    this.setState({ searchKeyword: "" });
  }

  onDeselectStaff() {
    const { deselectStaff } = this.props;
    deselectStaff();
  }

  onDeselectClinician() {
    const { deselectClinician, clearStaffData } = this.props;
    clearStaffData();
    deselectClinician();
    this.setState({ isCreating: false, isBulkCreating: false });
  }

  onUpdateStaff(values) {
    const { updateStaff, selectedStaff } = this.props;
    const emailWasUpdated = values.email !== selectedStaff.email;
    // not my favorite workaround, but we don't want to pass the submitted email if it didnt change as it will trigger a server error (email already in use)
    if (!emailWasUpdated) delete values.email;
    updateStaff({ ...values, staffId: selectedStaff.id });
  }

  onCreateStaff(values) {
    const { createStaff, selectedClinicianId } = this.props;
    createStaff({ ...values, clinicianId: selectedClinicianId });
  }

  onSubmitCSV() {
    const { bulkCreateStaff, selectedClinicianId } = this.props;
    const { csv } = this.state;
    bulkCreateStaff(selectedClinicianId, csv);
  }

  renderClinicianDropdown() {
    const {
      clinicianData,
      selectClinician,
      handleSubmit,
      selectedClinicianId,
    } = this.props;

    if (selectedClinicianId) return null;

    return (
      <div className="clinician-dropdown">
        <h4>Select a clinician first</h4>
        <form
          className="select-clinician-form"
          onSubmit={handleSubmit(selectClinician)}
        >
          <Field
            className="select-clinician-drilldown"
            initialLabel="Select Customer or Clinician First"
            name="clinicianId"
            component={DrillDownSelectField}
            values={clinicianData}
            validate={[required()]}
          />
          <input
            type="submit"
            className="btn select-clinician-btn"
            value="Select"
          />
        </form>
      </div>
    );
  }

  renderDisplayInfo() {
    const {
      clinicianDisplayName,
      customerDisplayName,
      clinicianCodes,
      clinicianData,
      selectedClinicianId,
    } = this.props;

    if (!clinicianData || !selectedClinicianId) return null;

    return (
      <div className="display-info">
        <p className="selected-clinician">
          <span>{clinicianDisplayName ? "Clinician" : "Customer"}:</span>{" "}
          {clinicianDisplayName || customerDisplayName}{" "}
          <button className="action-btn" onClick={this.onDeselectClinician}>
            Choose Another
          </button>
        </p>
        <p className="selected-codes">
          <span>Codes:</span> {clinicianCodes}
        </p>
      </div>
    );
  }

  renderStaffListItem(staffMember) {
    return (
      <li
        key={staffMember.id}
        role="button"
        aria-expanded="false"
        className="staff-item"
        onClick={() => this.onSelectStaff(staffMember.id)}
      >
        {staffMember.email}
      </li>
    );
  }

  renderStaffActions() {
    const { staffData, selectedClinicianId, selectedStaff } = this.props;
    const { searchKeyword, isCreating, isBulkCreating } = this.state;

    if (
      !staffData ||
      !selectedClinicianId ||
      selectedStaff ||
      isCreating ||
      isBulkCreating
    )
      return null;

    const filteredStaff = staffData.filter(
      (staffMember) => staffMember.email.indexOf(searchKeyword) !== -1
    );

    return (
      <div className="staff-actions">
        <h4>Add New Nurses</h4>

        <div className="staff-action-btns">
          <button
            className="action-btn"
            onClick={() => this.setState({ isCreating: true })}
          >
            Add Nurse
          </button>
          <button
            className="action-btn"
            onClick={() => this.setState({ isBulkCreating: true })}
          >
            Bulk Add Nurses
          </button>
        </div>

        {staffData.length > 0 && (
          <div>
            <h4>Or Update An Existing Nurse</h4>
            <div className="staff-dropdown">
              <input
                className="staff-search dropdown-toggle"
                placeholder="Select or Search for Nurse"
                data-toggle="dropdown"
                value={searchKeyword}
                onChange={(e) =>
                  this.setState({ searchKeyword: e.target.value })
                }
              />
              <ul className="dropdown-menu staff-list">
                {filteredStaff.map(this.renderStaffListItem)}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderCreateStaffForm() {
    const { isCreating } = this.state;
    const { handleSubmit } = this.props;

    if (!isCreating) return null;

    return (
      <div>
        <h3>Add new nurse</h3>
        <form
          className="create-staff-form"
          onSubmit={handleSubmit(this.onCreateStaff)}
        >
          <div>
            <label htmlFor="email">Email</label>
            <Field
              className="edit-field"
              name="email"
              component={SimpleField}
            />
          </div>

          <div className="edit-password-group">
            <label htmlFor="password">Password</label>
            <Field
              className="edit-field"
              name="password"
              component={SimpleField}
            />
          </div>

          <Field
            className="checkbox-field"
            name="login_blocked"
            label="Login Blocked"
            component={CheckboxField}
          />

          <input className="action-btn btn-danger" type="submit" value="Add" />
          <button
            className="action-btn"
            onClick={() => this.setState({ isCreating: false })}
          >
            Cancel
          </button>
        </form>
      </div>
    );
  }

  renderUpdateStaffForm() {
    const { selectedStaff, handleSubmit } = this.props;

    if (!selectedStaff) return null;

    return (
      <div className="update-staff">
        <p className="update-staff-header">
          <span>Update Information for:</span> {selectedStaff.email}
        </p>

        <form
          className="update-staff-form"
          onSubmit={handleSubmit(this.onUpdateStaff)}
        >
          <div>
            <label htmlFor="email">Email</label>
            <Field
              className="edit-field"
              name="email"
              component={SimpleField}
            />
          </div>

          <div>
            <label htmlFor="password">
              Password{" "}
              <span className="if-left-blank">
                (if left blank, existing password will be kept)
              </span>
            </label>
            <Field
              className="edit-field"
              name="password"
              component={SimpleField}
            />
          </div>

          <Field
            className="checkbox-field login-blocked-checkbox"
            name="login_blocked"
            label="Login Blocked"
            component={CheckboxField}
          />

          <input
            className="action-btn btn-danger"
            type="submit"
            value="Confirm Change"
          />
          <button className="action-btn" onClick={this.onDeselectStaff}>
            Cancel
          </button>
        </form>
      </div>
    );
  }

  renderBulkCreateStaff() {
    const { isBulkCreating, csv } = this.state;

    if (!isBulkCreating) return null;

    return (
      <div className="bulk-create-staff">
        <h3>Bulk upload</h3>
        <div>
          <input
            type="file"
            onChange={(e) => this.setState({ csv: e.target.files[0] })}
          />
          {csv && (
            <button
              className="action-btn btn-danger"
              onClick={this.onSubmitCSV}
            >
              Upload File
            </button>
          )}
          <button
            className="action-btn"
            onClick={() => this.setState({ isBulkCreating: false })}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { message } = this.props;
    return (
      <div className="manage-staff">
        <h2>Manage Staff</h2>
        {this.renderClinicianDropdown()}
        {this.renderDisplayInfo()}
        {this.renderStaffActions()}
        {this.renderCreateStaffForm()}
        {this.renderUpdateStaffForm()}
        {this.renderBulkCreateStaff()}
        {message && <p className="message">{message}</p>}
      </div>
    );
  }
}

ManageStaff.propTypes = {
  requestCustomers: PropTypes.func.isRequired,
  clinicianData: PropTypes.array.isRequired,
  staffData: PropTypes.array,
  clinicianDisplayName: PropTypes.string,
  customerDisplayName: PropTypes.string,
  clinicianCodes: PropTypes.string,
  selectedClinicianId: PropTypes.string,
  requestStaff: PropTypes.func.isRequired,
  deselectClinician: PropTypes.func.isRequired,
  clearStaffData: PropTypes.func.isRequired,
  updateStaff: PropTypes.func.isRequired,
  createStaff: PropTypes.func.isRequired,
  bulkCreateStaff: PropTypes.func.isRequired,
  selectClinician: PropTypes.func.isRequired,
  selectStaff: PropTypes.func.isRequired,
  deselectStaff: PropTypes.func.isRequired,
  selectedStaff: PropTypes.object,
  message: PropTypes.string,
  resetStaffForm: PropTypes.func.isRequired,
  // redux-form
  handleSubmit: PropTypes.func.isRequired,
};

ManageStaff.defaultProps = {
  staffData: null,
  selectedClinicianId: null,
  selectedStaff: null,
  clinicianDisplayName: "",
  customerDisplayName: "",
  clinicianCodes: "",
  message: "",
};

export default reduxForm({
  form: "manageStaff",
  enableReinitialize: true,
})(ManageStaff);
