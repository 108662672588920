import React from "react";
import PropTypes from "prop-types";

import LoadingIndicator from "../../../shared/components/LoadingIndicator";
import UpdateClinicianForm from "./components/UpdateClinicianForm";
import CreateClinicianForm from "./components/CreateClinicianForm";
import SearchableDropdown from "../../../shared/components/SearchableDropdown";

import "./ManageClinician.css";

// TODO: unify css for all Manage routes

class ManageClinician extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: false,
      isEditing: false,
      programsAdded: [], // used for UpdateClinicianForm
      programsArchived: [], // used for UpdateClinicianForm
      archivedProgramsUnarchived: [], // used for UpdateClinicianForm
    };

    this.onCreateClinician = this.onCreateClinician.bind(this);
    this.onUpdateClinician = this.onUpdateClinician.bind(this);
    this.onSelectCustomer = this.onSelectCustomer.bind(this);
    this.onDeselectCustomer = this.onDeselectCustomer.bind(this);
    this.onSelectClinician = this.onSelectClinician.bind(this);
    this.onDeselectClinician = this.onDeselectClinician.bind(this);
    this.onUpdateClinicianProgram = this.onUpdateClinicianProgram.bind(this);
    this.onUpdateClinicianArchivedProgram =
      this.onUpdateClinicianArchivedProgram.bind(this);
  }

  componentDidMount() {
    const {
      customerList,
      programList,
      requestCustomers,
      requestPrograms,
      requestClinician,
      clinicianId,
    } = this.props;

    if (!customerList) {
      requestCustomers();
    }
    if (!programList) {
      requestPrograms();
    }
    if (clinicianId) {
      requestClinician(clinicianId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      clinicianId,
      requestClinician,
      clearClinicianData,
      message,
      customerId,
    } = this.props;

    if (clinicianId && clinicianId !== prevProps.clinicianId) {
      requestClinician(clinicianId);
    }

    if (!clinicianId && prevProps.clinicianId) {
      clearClinicianData();
    }

    if (!message && prevProps.message) {
      document.location.href = `/manage-partners/?customerId=${customerId}`;
    }
  }

  onSelectCustomer(customerId) {
    const { selectCustomer } = this.props;
    selectCustomer(customerId);
  }

  onDeselectCustomer() {
    const { deselectCustomer } = this.props;
    deselectCustomer();
    this.setState({ isEditing: false, isCreating: false });
  }

  onSelectClinician(clinicianId) {
    const { selectClinician } = this.props;
    selectClinician(clinicianId);
  }

  onDeselectClinician() {
    const { deselectClinician } = this.props;
    this.setState({ isEditing: false, isCreating: false });
    deselectClinician();
  }

  onCreateClinician(values) {
    const { createClinician, customerId } = this.props;
    const formattedPrograms = values.programs.map((program) => ({
      aggregate_program: program.value,
    }));
    createClinician({
      customer_id: customerId,
      ...values,
      programs: formattedPrograms,
    });
  }

  onUpdateClinician(values) {
    const { updateClinician, clinicianId } = this.props;
    const { programsArchived, archivedProgramsUnarchived } = this.state;

    // if no npi submitted we don't want the key to exist, else server error
    if (values.npi === null) delete values.npi;

    // Already selected programs contain "program" property and mapped directly, new programs must follow the
    // aggregate_program format
    const formattedPrograms = values.programs.map((program) => {
      if (program.program) {
        return program.program;
      }
      return { aggregate_program: program.value };
    });
    const formattedProgramsArchived = programsArchived.map((program) => {
      return { ...program.program, archived: true };
    });
    const formattedArchivedPrograms = values.archivedPrograms.map((program) => {
      if (program.program) {
        return program.program;
      }
      return { aggregate_program: program.value };
    });
    const formattedArchivedProgramsUnarchived = archivedProgramsUnarchived.map(
      (program) => {
        return { ...program.program, archived: false };
      }
    );
    updateClinician({
      clinicianId,
      ...values,
      programs: [
        ...formattedPrograms,
        ...formattedProgramsArchived,
        ...formattedArchivedPrograms,
        ...formattedArchivedProgramsUnarchived,
      ],
    });
  }

  onUpdateClinicianProgram(newValue, previousValue) {
    if (newValue.length === 0 && previousValue.length === 0) return;

    const { programsAdded, programsArchived } = this.state;

    // check which value was changed and which op was performed: add or remove
    let op = "add";
    let program = newValue.find((nv) =>
      previousValue.every((pv) => pv.value !== nv.value)
    );
    if (!program) {
      op = "remove";
      program = previousValue.find((pv) =>
        newValue.every((nv) => nv.value !== pv.value)
      );
    }

    const idx = programsArchived.findIndex((p) => p.value === program.value);

    if (op === "add") {
      const newProgramsAdded = [...programsAdded];
      newProgramsAdded.push(program);
      this.setState({
        programsAdded: newProgramsAdded,
      });
      // if we're adding, we need to remove from the local state "programsArchived"
      if (idx > -1) {
        const newProgramsArchived = [...programsArchived];
        newProgramsArchived.splice(idx, 1);
        this.setState({
          programsArchived: newProgramsArchived,
        });
      }
    } else if (
      op === "remove" &&
      programsAdded.findIndex((p) => p.value === program.value) === -1
    ) {
      const newProgramsArchived = [...programsArchived];
      newProgramsArchived.push(program);
      this.setState({
        programsArchived: newProgramsArchived,
      });
    }
  }

  onUpdateClinicianArchivedProgram(newValue, previousValue) {
    if (newValue.length === 0 && previousValue.length === 0) return;

    const {
      clinician: { programs },
    } = this.props;
    const { archivedProgramsUnarchived } = this.state;

    // check which value was changed and which op was performed: add or remove
    let op = "add";
    let program = newValue.find((nv) =>
      previousValue.every((pv) => pv.value !== nv.value)
    );
    if (!program) {
      op = "remove";
      program = previousValue.find((pv) =>
        newValue.every((nv) => nv.value !== pv.value)
      );
    }

    const idx = archivedProgramsUnarchived.findIndex(
      (rp) => rp.value === program.value
    );
    if (
      op === "add" &&
      programs.findIndex((p) => p.aggregate_program === program.value) > -1
    ) {
      const newArchivedProgramsUnarchived = [...archivedProgramsUnarchived];
      newArchivedProgramsUnarchived.splice(idx, 1);
      this.setState({
        archivedProgramsUnarchived: newArchivedProgramsUnarchived,
      });
    } else if (op === "remove") {
      const newArchivedProgramsUnarchived = [...archivedProgramsUnarchived];
      newArchivedProgramsUnarchived.push(program);
      this.setState({
        archivedProgramsUnarchived: newArchivedProgramsUnarchived,
      });
    }
  }

  renderHeaderInfo() {
    const { customer, customerId, clinician, clinicianId, isLoading } =
      this.props;

    if (!customer || isLoading) return null;

    return (
      <div className="header-info">
        {customer && customerId && (
          <div className="header-group">
            <p className="selected-customer">
              <span className="header-prefix">Customer:</span>{" "}
              {customer.customer.name}
            </p>
            <button className="action-btn" onClick={this.onDeselectCustomer}>
              Choose Another
            </button>
          </div>
        )}
        {clinician && clinicianId && (
          <div className="header-group">
            <p className="selected-customer">
              <span className="header-prefix">Clinician:</span> {clinician.name}
            </p>
            <button className="action-btn" onClick={this.onDeselectClinician}>
              Choose Another
            </button>
          </div>
        )}
      </div>
    );
  }

  renderChooseCustomer() {
    const { customerList, customerId, isLoading } = this.props;

    if (!customerList || customerId || isLoading) return null;

    const values = customerList.map(({ customer }) => ({
      label: customer.name,
      value: customer.id,
      onClick: () => this.onSelectCustomer(customer.id),
    }));

    return (
      <div className="select-customer">
        <h4>Select a customer first</h4>
        <SearchableDropdown
          values={values}
          placeholder="Select or Search for Customer"
        />
      </div>
    );
  }

  renderChooseClinician() {
    const { customer, clinicianId, isLoading } = this.props;
    const { isCreating } = this.state;

    if (!customer || isCreating || clinicianId || isLoading) return null;

    const values = customer.clinicians.map((c) => ({
      value: c.id,
      label: c.name,
      onClick: () => this.onSelectClinician(c.id),
    }));

    return (
      <div className="select-clinician">
        <h3>Add New Clinician</h3>
        <button
          className="action-btn"
          onClick={() => this.setState({ isCreating: true })}
        >
          Add Clinician
        </button>

        <div className="update-existing">
          <h4>Or Update an Existing Clinician</h4>
          <SearchableDropdown
            values={values}
            placeholder="Select or Search for Clinician"
          />
        </div>
      </div>
    );
  }

  renderCreateClinicianForm() {
    const { programList } = this.props;
    const { isCreating } = this.state;

    if (!isCreating) return null;

    return (
      <CreateClinicianForm
        onSubmit={this.onCreateClinician}
        onCancel={() => this.setState({ isCreating: false })}
        programList={programList}
      />
    );
  }

  renderClinicianActions() {
    const {
      clinicianId,
      clinician,
      goToManageStaff,
      goToManagePrograms,
      isLoading,
    } = this.props;
    const { isEditing } = this.state;

    if (!clinicianId || !clinician || isEditing || isLoading) return null;

    return (
      <div className="clinician-actions">
        <h4>Edit Clinician Details</h4>
        <button
          className="action-btn"
          onClick={() => this.setState({ isEditing: true })}
        >
          Edit Details
        </button>

        <div className="update-existing">
          <p className="or-update">
            <span className="header-prefix">Or Manage Staff/Programs for:</span>{" "}
            {clinician.name}
          </p>
          <button
            className="action-btn"
            onClick={() => goToManageStaff(clinicianId)}
          >
            Manage Staff
          </button>
          {/* To be implemented in a future story */}
          <button
            disabled
            style={{ opacity: "0.5" }}
            className="action-btn"
            onClick={() => goToManagePrograms(clinicianId)}
          >
            Manage Programs
          </button>
        </div>
      </div>
    );
  }

  renderUpdateClinicianForm() {
    const { programList, clinician } = this.props;
    const { isEditing } = this.state;
    if (!isEditing) return null;

    const filteredProgramList = programList
      // we don't want to add the same aggregate program to the clinician twice, so we filter the list out before populating the select
      .filter(
        (program) =>
          !clinician.programs.find(
            (clinicianProgram) =>
              program.value === clinicianProgram.aggregate_program
          )
      )
      // filter out the archived programs
      .filter(
        (program) =>
          !clinician.programs.some(
            (ap) => ap.aggregate_program === program.value && !!ap.archived
          )
      );

    return (
      <UpdateClinicianForm
        onSubmit={this.onUpdateClinician}
        onUpdateClinicianProgram={this.onUpdateClinicianProgram}
        onUpdateClinicianArchivedProgram={this.onUpdateClinicianArchivedProgram}
        onCancel={() => this.setState({ isEditing: false })}
        clinician={clinician}
        programList={filteredProgramList}
      />
    );
  }

  render() {
    return (
      <div className="manage-clinician">
        <h2>Manage Clinician</h2>
        {this.renderHeaderInfo()}

        {this.renderChooseCustomer()}
        {this.renderChooseClinician()}
        {this.renderClinicianActions()}
        {this.renderCreateClinicianForm()}
        {this.renderUpdateClinicianForm()}
        <LoadingIndicator className="manage-loading-indicator" />
      </div>
    );
  }
}

ManageClinician.propTypes = {
  customerList: PropTypes.array,
  programList: PropTypes.array,
  customer: PropTypes.object,
  customerId: PropTypes.string,
  clinician: PropTypes.object,
  clinicianId: PropTypes.string,
  message: PropTypes.string,
  requestCustomers: PropTypes.func.isRequired,
  requestPrograms: PropTypes.func.isRequired,
  requestClinician: PropTypes.func.isRequired,
  createClinician: PropTypes.func.isRequired,
  updateClinician: PropTypes.func.isRequired,
  selectCustomer: PropTypes.func.isRequired,
  deselectCustomer: PropTypes.func.isRequired,
  selectClinician: PropTypes.func.isRequired,
  deselectClinician: PropTypes.func.isRequired,
  clearClinicianData: PropTypes.func.isRequired,
  goToManageStaff: PropTypes.func.isRequired,
  goToManagePrograms: PropTypes.func.isRequired,
};

ManageClinician.defaultProps = {
  customerList: null,
  programList: null,
  customer: null,
  customerId: "",
  clinician: null,
  clinicianId: "",
  message: "",
};

export default ManageClinician;
