import { connect } from "react-redux";

import FilterList from "./FilterList.jsx";

import { filterForType, selectedFilterValueForType } from "../../reducer";

function mapStateToProps(state, ownProps) {
  return {
    values: filterForType(state, ownProps.type),
    selectedValue: selectedFilterValueForType(state, ownProps.type),
  };
}

export default connect(mapStateToProps)(FilterList);
