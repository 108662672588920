import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../../../../../shared/app/requestErrorHandler";
import {
  setFleetingMessage,
  submitFormToAPI,
} from "../../../../../../../../shared/utils/form";
import {
  didGetAvailableAssignedToUsersForInvite,
  didUpdateAssignedTo,
  getAvailableAssignedToUsersForInvite,
  updateAssignedToForInvite,
} from "./actions";
import {
  getAvailableAssignedToUsersForInvite as getAvailableAssignedToUsersForInviteAPI,
  updateAssignedToForInvite as updateAssignedToForInviteAPI,
} from "./api";

function* submitAssignedToUpdate(action) {
  const { inviteId, assignedToId } = action.payload;

  yield put(
    submitFormToAPI(
      "updateAssignedTo",
      updateAssignedToForInviteAPI,
      (result) => didUpdateAssignedTo(inviteId, result),
      inviteId,
      assignedToId
    )
  );
}

function* fetchAvailableAssignedTo(action) {
  const { inviteId } = action.payload;
  try {
    const result = yield getAvailableAssignedToUsersForInviteAPI(inviteId);
    yield put(didGetAvailableAssignedToUsersForInvite(inviteId, result.data));
  } catch (e) {
    yield handleApiError(e);
  }
}

export default function* watchUpdateAssignedToSaga() {
  yield all([
    takeLatest(updateAssignedToForInvite, submitAssignedToUpdate),
    takeLatest(
      didUpdateAssignedTo,
      setFleetingMessage("updateAssignedTo", {
        message: "Assigned to has been updated.",
      })
    ),
    takeLatest(getAvailableAssignedToUsersForInvite, fetchAvailableAssignedTo),
  ]);
}
