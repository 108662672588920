import { handleActions } from "redux-actions";

import { didFetchReportableCodes } from "./sagas";

export function reportableCodes(state) {
  return state.ui.Metrics.reportableCodes;
}

export const groupSelectorReducer = handleActions(
  {
    [didFetchReportableCodes]: (state, action) => ({
      ...state,
      reportableCodes: action.payload,
    }),
  },
  {
    reportableCodes: [],
  }
);
