import axios from "axios";

export const updateProcedureType = (peerId, procedureType) =>
  axios.post(`/api/admin/v1/users/${peerId}/update`, {
    intake_data: {
      procedure_type: procedureType,
    },
  });

export const availableProcedureTypes = (peerId) =>
  axios.get(`/api/admin/v1/users/${peerId}/procedure-types`);
