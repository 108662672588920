import React from "react";
import PropTypes from "prop-types";

import "./Physical.css";

import ChartWrapper from "./components/ChartWrapper";
import ConditionalProfileMetric from "./../../../../../../shared/components/PeerProfile/ProfileMetric/Conditional";

import NoData from "./components/NoData";

class Physical extends React.Component {
  renderMetric(metric) {
    const { user } = this.props;
    const title = metric.info.dashboard_title;
    if (metric.metrics) {
      if (metric.goal && metric.goal.range) {
        return (
          <ConditionalProfileMetric
            metric={metric}
            key={metric.info.title}
            registrationDate={user.created_at}
            isDashboard
          />
        );
      } else {
        return (
          <div key={metric.info.title} className="measurement">
            <ChartWrapper title={title} metric={metric} user={user} />
          </div>
        );
      }
    }

    return (
      <div key={metric.info.title} className="measurement">
        <NoData title={title} />
      </div>
    );
  }

  render() {
    const { metrics } = this.props;
    return (
      <div className="physical-measurements">
        <h1>Physical measurements</h1>
        <div className="measurements">
          {metrics.length === 0 ? (
            <p>User has not done any exercises</p>
          ) : (
            metrics.map((metric) => this.renderMetric(metric))
          )}
        </div>
      </div>
    );
  }
}

Physical.propTypes = {
  metrics: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.object.isRequired,
};

Physical.defaultProps = {
  metrics: [],
};

export default Physical;
