import { createAction } from "redux-actions";

const prefixed = (val) => `CARDS::${val}`;

export const fetchCard = createAction(
  prefixed("FETCH_CARD"),
  (cardId) => cardId
);
export const didFetchCard = createAction(prefixed("DID_FETCH_CARD"));
export const fetchAllCards = createAction(prefixed("FETCH_ALL_CARD_DATA"));

export const fetchCardsList = createAction(
  prefixed("FETCH_CARDS_LIST"),
  (deleted, filter, assessment, page) => ({
    deleted,
    filter,
    assessment,
    page,
  })
);
export const didFetchCardsList = createAction(
  prefixed("DID_FETCH_CARDS_LIST"),
  (cardsList, count) => ({ cardsList, count })
);
