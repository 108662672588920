import { connect } from "react-redux";

import { fleetingFormMeta } from "../../../../../shared/utils/form";

import CreateFieldValueForm from "./CreateFieldValueForm.jsx";

function mapStateToProps(
  state,
  { onSubmit, onCancel, customerField, customerFieldValue }
) {
  return {
    initialValues: {
      visible: true,
    },
    customerField,
    customerFieldValue,
    onSubmit,
    onCancel,
    message: fleetingFormMeta(state, "manageCustomer").message,
  };
}

export default connect(mapStateToProps)(CreateFieldValueForm);
