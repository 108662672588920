import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// NOT A REDUX-FORM FIELD
// Use this is you only want to e.g. update the local state of something or dispatch an action to change the query string

class SearchableDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKeyword: "",
      expanded: false,
    };

    this.inputRef = null;

    this.handleChange = this.handleChange.bind(this);
    this.renderListItem = this.renderListItem.bind(this);
  }

  componentDidMount() {
    const { withRef } = this.props;
    if (withRef) this.inputRef.focus();
  }

  handleChange(e) {
    const value = e.target.value;
    this.setState({ searchKeyword: value, expanded: value.length > 0 });
  }

  renderListItem(item) {
    return (
      <li
        key={item.value}
        role="button"
        aria-expanded="false"
        className="customer-item"
        onClick={item.onClick}
      >
        {item.label}
      </li>
    );
  }

  render() {
    // for now we filter out staging data prefixed with 'acceptance-test' here, since this is an unbounded/unpaginated dropdown TODO: change this
    const { values, placeholder, exclude = "acceptance-test" } = this.props;
    const { searchKeyword, expanded } = this.state;

    const filtered = values.filter(
      ({ label, value }) =>
        value &&
        label.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 &&
        label.toLowerCase().indexOf(exclude) === -1
    );

    return (
      <div className={classNames("customer-dropdown", { open: expanded })}>
        <input
          className="customer-search dropdown-toggle"
          placeholder={placeholder}
          value={searchKeyword}
          onClick={() => this.setState({ expanded: !expanded })}
          onChange={this.handleChange}
          ref={(node) => {
            this.inputRef = node;
          }}
        />
        <ul className="dropdown-menu customer-list">
          {filtered.map(this.renderListItem)}
        </ul>
      </div>
    );
  }
}

SearchableDropdown.propTypes = {
  values: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
};

SearchableDropdown.defaultProps = {
  placeholder: "Search for or Select an item",
};

export default SearchableDropdown;
