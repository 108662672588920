import { takeLatest, put, all } from "redux-saga/effects";

import {
  submitFormToAPI,
  setFleetingMessage,
} from "../../../../../../../shared/utils/form";

import { updateNameForPeer, didUpdateName } from "./actions";
import { updateNameForPeer as updateNameForPeerAPI } from "./api";

function* submitNameUpdate(action) {
  const { peerId, fullname } = action.payload;

  yield put(
    submitFormToAPI(
      "updateName",
      updateNameForPeerAPI,
      didUpdateName(peerId, fullname),
      peerId,
      fullname
    )
  );
}

export default function* watchUpdateNameSaga() {
  yield all([
    takeLatest(updateNameForPeer, submitNameUpdate),
    takeLatest(
      didUpdateName,
      setFleetingMessage("updateName", { message: "Name has been updated." })
    ),
  ]);
}
