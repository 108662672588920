import { createAction } from "redux-actions";

const prefixed = (val) => `BillingReport::${val}`;

export const requestCustomers = createAction(prefixed("REQUEST_CUSTOMERS"));
export const didFetchCustomers = createAction(
  prefixed("DID_FETCH_CUSTOMERS"),
  (customers) => customers
);

export const requestBillingReport = createAction(
  prefixed("REQUEST_BILLING_REPORT")
);
export const didFetchBillingReport = createAction(
  prefixed("DID_FETCH_BILLING_REPORT"),
  (reportData) => reportData
);

export const requestUpdateStartDate = createAction(
  prefixed("REQUEST_UPDATE_START_DATE")
);
export const didUpdateStartDate = createAction(
  prefixed("DID_UPDATE_START_DATE"),
  (startDate) => startDate
);
export const requestUpdateEndDate = createAction(
  prefixed("REQUEST_UPDATE_END_DATE")
);
export const didUpdateEndDate = createAction(
  prefixed("DID_UPDATE_END_DATE"),
  (endDate) => endDate
);
export const requestUpdateAmount = createAction(
  prefixed("REQUEST_UPDATE_AMOUNT")
);
export const didUpdateAmount = createAction(
  prefixed("DID_UPDATE_AMOUNT"),
  (amount) => amount
);
