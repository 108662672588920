import { createAction } from "redux-actions";

const prefixed = (val) => `Invite/View/Name::${val}`;

export const updateNameForInvite = createAction(
  prefixed("UPDATE_NAME_FOR_INVITE"),
  (inviteId, firstName, lastName) => ({ firstName, lastName, inviteId })
);
export const didUpdateName = createAction(
  prefixed("DID_UPDATE_NAME"),
  (inviteId, firstName, lastName) => ({ firstName, lastName, inviteId })
);
