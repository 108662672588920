import axios from "axios";

export const fetchCustomers = () => axios.get("/api/admin/v1/customers/");
export const fetchCustomersGroup = (customerId) =>
  axios.get(`/api/admin/v1/customers/${customerId}/external_fields/select`);
export const fetchAllUsers = (customerId) =>
  axios.get(`/api/admin/v1/partner_users?customer_id=${customerId}`);

export const updateAdmin = (adminId, values) =>
  axios.patch(`/api/admin/v1/partner_admins/${adminId}`, values);
export const updateOwner = (ownerId, values) =>
  axios.patch(`/api/admin/v1/owners/${ownerId}`, values);

export const createAdmin = (values) =>
  axios.post("/api/admin/v1/partner_admins", values);
export const createOwner = (values) =>
  axios.post("/api/admin/v1/owners", values);

export const uploadUsers = ({ customerId, csv }) => {
  const form = new FormData();
  form.append("customer_id", customerId);
  form.append("file", csv, "users-bulk-create-template.csv");

  return axios.post("/api/admin/v1/partner_users_collection", form);
};
