import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { validateForm } from "../../../../../../../shared/utils/form";

import FormGroupField from "./../../../../../../../shared/components/FormFields/FormGroupField";
import SelectField from "./../../../../../../../shared/components/FormFields/SelectField";
import SuccessAnimation from "./../../../../../../../shared/components/SuccessAnimation";

import "./UpdateCareTeamMember.css";

import { CARE_TEAM_JOB_OPTIONS } from "../AddCareTeamMember/careTeamJobOptions";

const rules = {
  job: {
    presence: true,
  },
};

function UpdateCareTeamMember({ onSubmit, handleSubmit, member, message }) {
  return (
    <div>
      <h1>{member.fullname}</h1>
      <form
        className="form update-care-team-member-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="member-info">
          <h3>email</h3>
          <p>{member.email}</p>
        </div>
        <div className="member-job">
          <Field
            label="Job"
            name="job"
            component={SelectField}
            values={CARE_TEAM_JOB_OPTIONS}
            defaultValue={member.job}
          />
        </div>
        {message ? (
          <SuccessAnimation message={message} />
        ) : (
          <input type="submit" className="btn btn-primary" value="Save" />
        )}
      </form>
    </div>
  );
}

UpdateCareTeamMember.propTypes = {
  userId: PropTypes.string.isRequired,
  member: PropTypes.object.isRequired,
  message: PropTypes.string,

  // Automatically added
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

UpdateCareTeamMember.defaultProps = {
  message: null,
};

export default reduxForm({
  form: "updateCareTeamMember",
  validate: validateForm(rules),
})(UpdateCareTeamMember);
