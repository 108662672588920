import React from "react";
import classNames from "classnames";
import moment from "moment";

import NonReduxDateField from "./../../DateInputField";

import "./DateInputField.css";

// Wrapper for the date input field so it works with redux form
class DateInputField extends React.Component {
  renderError() {
    // eslint-disable-next-line no-unused-vars
    const {
      embedded,
      meta: { touched, error, warning, pristine },
    } = this.props;
    const shouldDisplayError = (!pristine || touched) && error;

    return (
      <div>
        {!embedded &&
          shouldDisplayError &&
          ((error && <span className="error-message">{error}</span>) ||
            (warning && <span className="warning-message">{warning}</span>))}
      </div>
    );
  }

  renderDateField() {
    const { label, required, input, subLabel, embedded } = this.props;

    const momentValue = input.value ? moment(input.value) : undefined;
    return (
      <div>
        {!embedded && label && (
          <label>
            {label}
            {required && <span className="asterisk"> *</span>}
          </label>
        )}
        {subLabel && <p className="subLabel">{subLabel}</p>}
        <NonReduxDateField
          {...this.props}
          onDateChange={(date) => input.onChange(date.valueOf())}
          value={momentValue}
        />
      </div>
    );
  }

  renderOptionalField() {
    const { label, input, optionalMessage } = this.props;

    const isChecked = input.value === 0;

    function changeCheckbox(value) {
      input.onChange(0);
    }

    if (optionalMessage) {
      return (
        <div className="optional-date-container">
          <input
            id="optionalDate"
            type="checkbox"
            checked={isChecked}
            onChange={changeCheckbox}
          />
          <label htmlFor="optionalDate">{optionalMessage}</label>
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      meta: { touched, error, pristine },
    } = this.props;
    const shouldDisplayError = (!pristine || touched) && error;

    return (
      <div
        className={classNames(
          { "has-error": shouldDisplayError },
          "dateInputField"
        )}
      >
        {this.renderDateField()}
        {this.renderError()}
        {this.renderOptionalField()}
      </div>
    );
  }
}

export default DateInputField;
