import React from "react";
import PropTypes from "prop-types";

import "./BottomBar.css";

class BottomBar extends React.Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onRestore = this.onRestore.bind(this);
  }

  onRestore(e) {
    const { onRestore } = this.props;
    e.preventDefault();
    onRestore();
    return false;
  }

  onCancel(e) {
    const { onCancel } = this.props;
    e.preventDefault();
    onCancel();
    return false;
  }

  onDelete(e) {
    const { onDelete } = this.props;
    e.preventDefault();
    onDelete();
    return false;
  }

  render() {
    const { programId, deleted } = this.props;
    return (
      <div className="bottom-bar">
        <div className="pull-right buttons">
          <button className="btn btn-cancel" onClick={this.onCancel}>
            Cancel
          </button>
          {programId && !deleted && (
            <button
              className="btn btn-danger"
              onClick={this.onDelete}
              data-test="delete"
            >
              Delete
            </button>
          )}
          {programId && deleted && (
            <button className="btn btn-primary" onClick={this.onRestore}>
              Restore
            </button>
          )}
          <input
            type="submit"
            value="Save"
            className="btn btn-primary"
            data-test="submit"
          />
        </div>
      </div>
    );
  }
}

BottomBar.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  programId: PropTypes.string,
  deleted: PropTypes.bool,
};

export default BottomBar;
