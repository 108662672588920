import React from "react";
import classNames from "classnames";

class GroupDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",
    };
    // TODO: focus input ref when group dropdown submenu opens
    this.inputRef = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { groupValue } = this.props;
    const { searchValue } = this.state;
    if (
      groupValue !== nextProps.groupValue ||
      searchValue !== nextState.searchValue
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { options, groupType, groupValue, groupLabel, onGroupChange } =
      this.props;
    const { searchValue } = this.state;

    // // if a submenu item is selected, we want that to override the group label (e.g. instead of it saying 'Owner' we want it to say the selected owner's name)
    const selectedGroupValue =
      options && options.find(({ value }) => groupValue === value);
    const selectedLabel = selectedGroupValue && selectedGroupValue.label;
    const groupFilterContent = selectedLabel
      ? selectedLabel.substr(0, 12)
      : groupLabel;

    const groupClassName = classNames(
      "group-filter",
      "group-filter-submenu-wrapper",
      { selectedGroupFilter: !!selectedLabel }
    );

    const filteredOptions = options
      .filter(
        ({ value, label }) =>
          value && label.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
      )
      .sort((a, b) => a.label.localeCompare(b.label));

    const submenuListItems = filteredOptions.map((option) => (
      <li
        key={option.value + Math.random()}
        role="button"
        className="group-filter-child"
        aria-expanded="false"
        onClick={() => onGroupChange(groupType, option.value)}
      >
        <span>{option.label}</span>
      </li>
    ));

    // workaround for when there are too many results to render in a dropdown, for now only relevant as an admin working with staging data
    const isLengthyList = filteredOptions.length > 750;
    const isEmptyList = filteredOptions.length === 0;

    const placeholder = isLengthyList ? "Refine search..." : "Search...";

    const tooManyResultsFound = (
      <li
        className="group-filter-child too-many-results"
        style={{ color: "#DB5E4B" }}
        aria-expanded="false"
      >
        {filteredOptions.length} results
      </li>
    );
    const noResultsFound = (
      <li
        className="group-filter-child no-results"
        style={{ color: "#DB5E4B" }}
        aria-expanded="false"
      >
        No results found
      </li>
    );

    let listContent = submenuListItems;
    if (isLengthyList) {
      listContent = tooManyResultsFound;
    }
    if (isEmptyList) {
      listContent = noResultsFound;
    }

    return (
      <li key={groupType} className={groupClassName}>
        <div
          className="dropdown-toggle group-filter-submenu-label"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span>{groupFilterContent}</span>
          <span className="caret" />
        </div>
        <div className="dropdown-menu group-filter-submenu-container">
          <input
            className="group-searchbox"
            placeholder={placeholder}
            value={searchValue}
            onChange={(e) => this.setState({ searchValue: e.target.value })}
            onBlur={() =>
              setTimeout(() => this.setState({ searchValue: "" }), 200)
            }
            ref={(node) => {
              this.inputRef = node;
            }}
          />
          <ul className="group-filter-submenu">{listContent}</ul>
        </div>
      </li>
    );
  }
}

export default GroupDropdown;
