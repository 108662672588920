import { connect } from "react-redux";
import CoreReport from "./CoreReport.jsx";
import {
  requestCoreReport,
  requestResetReport,
  requestUpdateEndDate,
} from "./actions";
import { endDate, report, selected } from "./reducer";

function mapStateToProps(state) {
  return {
    selected: selected(state),
    report: report(state),
    endDate: endDate(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetReport: () => dispatch(requestResetReport()),
    requestCoreReport: (payload) => dispatch(requestCoreReport(payload)),
    updateEndDate: (timestamp) => dispatch(requestUpdateEndDate(timestamp)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CoreReport);
