import { connect } from "react-redux";
import { push } from "redux-little-router";

import { query, updateQuery, clearQuery } from "../../../shared/routes/query";
import { fleetingFormMeta } from "../../../shared/utils/form";
import { shouldDisplayLoadingIndicator } from "../../../shared/components/LoadingIndicator/reducer";

import {
  requestCustomers,
  requestCustomerTypes,
  requestCustomerDetails,
  requestCustomerSelectFields,
  createCustomer,
  updateCustomer,
  createFieldValue,
  updateFieldValue,
  clearCustomerData,
  clearAllData,
} from "./actions";
import {
  allCustomers,
  selectedCustomer,
  customerTypes,
  selectedCustomerSelectFields,
  selectedCustomerField,
  selectedCustomerFieldValue,
  prevPage,
} from "./reducer";

import ManageCustomer from "./ManageCustomer.jsx";

function mapStateToProps(state) {
  return {
    allCustomers: allCustomers(state),
    customer: selectedCustomer(state),
    customerId: query(state, "customerId"),
    customerTypes: customerTypes(state),
    customerSelectFields: selectedCustomerSelectFields(state),
    fieldId: query(state, "fieldId"),
    customerField: selectedCustomerField(state),
    fieldValueId: query(state, "fieldValueId"),
    customerFieldValue: selectedCustomerFieldValue(state),
    isLoading: shouldDisplayLoadingIndicator(state),
    message: fleetingFormMeta(state, "manageCustomer").message,
    // previousPage: prevPage(state)
  };
}

const dispatch = {
  requestCustomers: () => requestCustomers(),
  requestCustomerTypes: () => requestCustomerTypes(),
  requestCustomerDetails: (customerId) => requestCustomerDetails(customerId),
  createCustomer: (values) => createCustomer(values),
  updateCustomer: (values) => updateCustomer(values),
  createFieldValue: (values) => createFieldValue(values),
  updateFieldValue: (values) => updateFieldValue(values),
  requestCustomerSelectFields: (customerId) =>
    requestCustomerSelectFields(customerId),
  selectCustomer: (customerId) => updateQuery({ customerId }),
  deselectCustomer: () => clearQuery(),
  selectField: (fieldId) => updateQuery({ fieldId }),
  deselectField: () =>
    updateQuery({ fieldId: undefined, fieldValueId: undefined }),
  selectFieldValue: (fieldValueId) => updateQuery({ fieldValueId }),
  deselectFieldValue: () => updateQuery({ fieldValueId: undefined }),
  clearCustomerData: () => clearCustomerData(),
  clearAllData: () => clearAllData(),
  goToManageClinician: (customerId) =>
    push(`/manage-clinician/?customerId=${customerId}`),
  goToManageUsers: (customerId) =>
    push(`/manage-partner-users/?customerId=${customerId}`),
  goTo: (page) => push(page),
};

function mergeProps(stateProps, dispatchProps) {
  const { previousPage } = stateProps;
  const { goTo } = dispatchProps;

  const mergedProps = {
    ...stateProps,
    ...dispatchProps,
    goToPreviousPage: () => goTo(previousPage),
  };
  return mergedProps;
}

// function mergeProps (stateProps, dispatchProps, ownProps) {
//   const { isAdmin } = stateProps
//   const { onAdminSubmit, onClinicianSubmit } = dispatchProps

//   const mergedProps = Object.assign({}, stateProps, ownProps, {
//     onSubmit: isAdmin ? onAdminSubmit : onClinicianSubmit
//   })

//   return mergedProps
// }

export default connect(mapStateToProps, dispatch, mergeProps)(ManageCustomer);
