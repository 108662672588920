import { pickBy } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../../../../../shared/app/requestErrorHandler";
import { expandObject } from "../../../../../../../../shared/utils/expandObject";
import { setFleetingFormMeta } from "../../../../../../../../shared/utils/form";
import { requestPeer, requestPeerProfile } from "../../../../sagas";
import {
  didFetchAvailablePrograms,
  fetchAvailablePrograms,
  requestChangePartner,
} from "./actions";
import {
  fetchCliniciansByCustomer as fetchCliniciansByCustomerAPI,
  fetchPrograms as fetchProgramsAPI,
  updatePartner,
} from "./api";

// XXX duplicate of the one in TransitionProgram, extract to a common place for reuse?
function* fetchPrograms(action) {
  try {
    const clinicians = yield fetchCliniciansByCustomerAPI(
      action.payload.customerId
    );
    const programs = yield fetchProgramsAPI();
    yield put(
      didFetchAvailablePrograms({
        clinicians: clinicians.data,
        programs: programs.data,
        peerId: action.payload.userId,
      })
    );
  } catch (e) {
    yield handleApiError(e);
  }
}

function* submitChangePartner(action) {
  const { payload } = action;

  const peerId = payload.peerId;

  const inviteData = pickBy(
    payload,
    (k, v) => v.indexOf("external_service") !== -1
  );

  const { program, part } = payload.part;
  const dataToSubmit = {
    program,
    part,
    partner_transition: {
      customer: payload.customer,
      owner: payload.owner,
      invite_data: expandObject(inviteData.external_service),
    },
  };

  try {
    const response = yield call(updatePartner, peerId, dataToSubmit);

    yield put(
      setFleetingFormMeta("changePartner", { message: "Patient moved" })
    );
    yield put(requestPeer(peerId));
    yield put(requestPeerProfile(peerId));
  } catch (e) {
    let error = e.response && e.response.data && e.response.data.error;
    alert(error); // Todo improve
  }
}

export default function* watchUpdatePatientPartner() {
  yield all([
    yield takeLatest(fetchAvailablePrograms, fetchPrograms),
    yield takeLatest(requestChangePartner, submitChangePartner),
  ]);
}
