import LoginRoute from "./Login";
import DeniedRoute from "./Denied";
import BlockedRoute from "./Blocked";
import ResetPasswordRoute from "./ResetPassword";
import RegistrationRoute from "./Register";

export default Object.assign(
  {},
  LoginRoute,
  DeniedRoute,
  ResetPasswordRoute,
  BlockedRoute,
  RegistrationRoute
);
