import { all, put, takeLatest } from "redux-saga/effects";

import { callAPI } from "../../../shared/utils/sagas";

import { setFleetingMessage } from "../../../shared/utils/form";

import {
  requestCustomers,
  didFetchCustomers,
  requestUser,
  didFetchUser,
  createUser,
  didCreateUser,
  didUploadUsers,
  uploadUsers,
  updateUser,
  requestAllUsers,
  didFetchAllUsers,
  didUpdateUser,
  requestGroups,
  didFetchGroups,
} from "./actions";

import {
  fetchCustomers as fetchCustomersAPI,
  fetchCustomersGroup as fetchCustomersGroupAPI,
  fetchAllUsers as fetchAllUsersAPI,
  createAdmin as createAdminAPI,
  createOwner as createOwnerAPI,
  uploadUsers as uploadUsersAPI,
  updateAdmin as updateAdminAPI,
  updateOwner as updateOwnerAPI,
} from "./api";

function* updateUserLogic(action) {
  const values = action.payload;
  const { type, userId } = values;
  let res;

  if (type === "owner") {
    res = yield updateOwnerAPI(userId, values);
  } else if (type === "partner_admin") {
    res = yield updateAdminAPI(userId, values);
  } else return;
  yield put(didUpdateUser(res.data));
}

function* createUserLogic(action) {
  const values = action.payload;

  const { type } = values;
  let res;

  if (type === "owner") {
    res = yield createOwnerAPI(values);
  } else if (type === "partner_admin") {
    res = yield createAdminAPI(values);
  } else return;
  yield put(didCreateUser(res.data));
}

export default function* watchManageUsers() {
  yield all([
    takeLatest(requestCustomers, callAPI(fetchCustomersAPI, didFetchCustomers)),
    takeLatest(requestGroups, callAPI(fetchCustomersGroupAPI, didFetchGroups)),
    takeLatest(requestUser, didFetchUser),
    takeLatest(requestAllUsers, callAPI(fetchAllUsersAPI, didFetchAllUsers)),
    takeLatest(createUser, createUserLogic),
    takeLatest(uploadUsers, callAPI(uploadUsersAPI, didUploadUsers)),
    takeLatest(updateUser, updateUserLogic),
    takeLatest(
      didUpdateUser,
      setFleetingMessage(
        "updateUsers",
        { message: "Partner user successfully updated!" },
        5000
      )
    ),
    takeLatest(
      didUploadUsers,
      setFleetingMessage(
        "uploadUsers",
        { message: "Partner users successfully uploaded!" },
        5000
      )
    ),
    takeLatest(
      didCreateUser,
      setFleetingMessage(
        "createUsers",
        { message: "Partner user successfully created!" },
        5000
      )
    ),
  ]);
}
