import reduceReducers from "reduce-reducers";
import { push } from "redux-little-router";
import { put } from "redux-saga/effects";
import {
  defaultRoutes,
  userInfoReducer as appUserInfoReducer,
} from "./../../../../routes/Authentication/";
import { logout, resetForm } from "./actions";
import Login from "./Login.js";
import { reducer, uiReducer, userInfoReducer } from "./reducer";
import { watchLoginSaga as saga } from "./sagas";
import { logout as logoutApi } from "./api";

export const signout = function* () {
  try {
    yield logoutApi();
  } catch (error) {
    // Don't fail if bearer token was expired
  }
  yield put(logout());
  yield put(push(defaultRoutes.unauthenticated));
};

export default {
  "/signin": {
    component: Login,
    saga,
    title: "Sign in",
    reducer: {
      ui: { Login: uiReducer },
      authentication: reducer,
      // Only merge app specific userInfo reducer in if needed
      userInfo: appUserInfoReducer
        ? reduceReducers(userInfoReducer, appUserInfoReducer)
        : userInfoReducer,
    },
    *onEnter() {
      yield put(resetForm());
    },
  },
  "/signout": {
    title: "Sign out",
    onEnter: signout,
  },
};
