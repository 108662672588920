import { push } from "redux-little-router";
import { put } from "redux-saga/effects";

import ListRoute from "./List";
import ViewRoute from "./View";
import ViewWorkerRoute from "./View";
import { authenticationOfTypeRequired } from "../../../shared/routes/Authentication/Login/sagas";
import reducer from "./reducer";
import watchPeersSagas from "./sagas";

const RedirectRoute = {
  "/peers": {
    *onEnter() {
      yield put(push("/peers/overview/0/"));
      yield authenticationOfTypeRequired(["admin", "staff"]);
    },
    reducer: {
      ui: {
        PeerView: reducer,
      },
    },
    saga: watchPeersSagas,
    title: "Old Patient Overview",
  },
};

export default Object.assign(
  {},
  ListRoute,
  ViewRoute,
  ViewWorkerRoute,
  RedirectRoute
);
