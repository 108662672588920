import React from "react";
import PropTypes from "prop-types";
import { orderBy } from "lodash";
import { CSVDownload } from "react-csv";

import "./CardsList.css";
import { cardListItemShape } from "./components/Card/proptypes";
import Card from "./components/Card";

class CardsList extends React.Component {
  constructor(props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onSelectedChange = this.onSelectedChange.bind(this);
    this.onAssessmentChange = this.onAssessmentChange.bind(this);
  }

  renderDownloadTrigger(onCardsDownloaded) {
    const { v1CardDownloadData, v2CardDownloadData } = this.props;
    var cardsDownloaded = false;
    var cardsV1DownloadTrigger, cardsV2DownloadTrigger;
    if (v1CardDownloadData) {
      cardsDownloaded = true;
      cardsV1DownloadTrigger = <CSVDownload data={v1CardDownloadData} />;
    }
    if (v2CardDownloadData) {
      cardsDownloaded = true;
      cardsV2DownloadTrigger = <CSVDownload data={v2CardDownloadData} />;
    }
    if (cardsDownloaded) {
      onCardsDownloaded();
    }
    return (
      <div>
        {cardsV1DownloadTrigger}
        {cardsV2DownloadTrigger}
      </div>
    );
  }

  onFilterChange(e) {
    const { updateFilter } = this.props;
    let filter = e.target.value.trim().toLowerCase();
    if (filter.length === 0) {
      filter = null;
    }
    updateFilter(filter);
  }

  onSelectedChange(e) {
    const { onShowDeletedChange } = this.props;
    onShowDeletedChange(e.target.value === "true");
  }

  onAssessmentChange(e) {
    const { onFilterByAssessmentsChange } = this.props;
    onFilterByAssessmentsChange(e.target.value === "true");
  }

  render() {
    const {
      cards,
      isDownloading,
      onAddCardClicked,
      onDownloadCardsClicked,
      onCardsDownloaded,
      onUploadCardsClicked,
    } = this.props;

    if (!cards) {
      return null;
    }
    const orderedCards = orderBy(cards, (card) => card.name.toLowerCase());
    const emptyMessage = orderedCards.length === 0 && "No cards found";
    return (
      <div className="card-portal-container">
        <div className="row top">
          <h2>Card management</h2>
        </div>
        <div className="row">
          <div className="col-md-3">
            <button
              className="btn btn-primary"
              onClick={onAddCardClicked}
              data-test="add-card"
            >
              Add Card
            </button>
          </div>
          <div className="col-md-3">
            <button
              className="btn btn-primary"
              onClick={onDownloadCardsClicked}
            >
              {isDownloading ? "Downloading..." : "Download all card data"}
            </button>

            <button className="btn btn-primary" onClick={onUploadCardsClicked}>
              Upload all card data
            </button>
          </div>
          {this.renderDownloadTrigger(onCardsDownloaded)}
        </div>
        <div className="row filters-title">
          <h3>Search filters</h3>
        </div>
        <div className="row">
          <input
            className="card-filter-searchbox"
            type="text"
            placeholder="Search cards"
            onChange={this.onFilterChange}
            data-test="filter-cards"
          />
        </div>
        <div className="row">
          <div className="col-md-5">
            <label>
              Show deleted cards{" "}
              <input type="checkbox" onChange={this.onSelectedChange} />
            </label>
          </div>
          <div className="col-md-5">
            <label>
              Show only cards with assessment{" "}
              <input type="checkbox" onChange={this.onAssessmentChange} />
            </label>
          </div>
        </div>

        <p className="doubleclick">Double click to edit cards</p>
        {(emptyMessage && (
          <p className="empty-message" data-test="empty-message">
            {emptyMessage}
          </p>
        )) || (
          <ul className="cards-list" data-test="cards-list">
            {orderedCards.map((card) => (
              <Card {...card} key={card.id} />
            ))}
          </ul>
        )}
      </div>
    );
  }
}

CardsList.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape(cardListItemShape)),
  isDownloading: PropTypes.bool,
  onAddCardClicked: PropTypes.func.isRequired,
  onDownloadCardClicked: PropTypes.func.isRequired,
  onShowDeletedChange: PropTypes.func.isRequired,
  onFilterByAssessmentsChange: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  showDeleted: PropTypes.bool,
  filterCardByAssessments: PropTypes.bool,
  filter: PropTypes.string,
};

CardsList.defaultProps = {
  filter: "",
  showDeleted: false,
  filterCardByAssessments: false,
  cards: {},
};

export default CardsList;
