import { createAction } from "redux-actions";
import { push } from "redux-little-router";

const prefixed = (val) => `InvitePortal/CustomerForm::${val}`;

export const requestCustomers = createAction(prefixed("REQUEST_CUSTOMERS"));
export const didFetchCustomers = createAction(
  prefixed("DID_FETCH_CUSTOMERS"),
  (customers) => customers
);

export const selectClinician = ({ clinician_id, customer_id }, asDemo) =>
  push(
    `/invite-portal/${clinician_id}?demo=${asDemo}&customer_id=${customer_id}`
  );
