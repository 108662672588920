import classNames from "classnames";
import React from "react";
import { Field, reduxForm } from "redux-form";
import CheckboxField from "../../../shared/components/FormFields/CheckboxField";
import FormGroupField from "../../../shared/components/FormFields/FormGroupField";
import SuccessAnimation from "../../../shared/components/SuccessAnimation";
import {
  capitalizeName,
  defaultRules,
  downcaseEmail,
  validateForm,
} from "../../../shared/utils/form";
import "./CoachForm.css";

class CoachForm extends React.Component {
  render() {
    const { handleSubmit, onSubmit, isUpdating, message, isEditingSelf } =
      this.props;

    return (
      <form className="coach-form" onSubmit={handleSubmit(onSubmit)}>
        {this.renderHeader()}

        <div className="coach-fields">
          <Field
            className="coach-input-field name"
            name="name"
            label="Name"
            placeholder="Name"
            component={FormGroupField}
            format={capitalizeName}
            errorOnBlur
          />
          <Field
            className="coach-input-field email"
            name="email"
            label="Email"
            placeholder="Email Address"
            component={FormGroupField}
            required
            format={downcaseEmail}
            errorOnBlur
          />
        </div>
        <div className="checkbox-field-container">
          <Field
            className="coach-input-field checkbox"
            name="mfa"
            label=""
            component={CheckboxField}
          />
          <p className="checkbox-description">
            MFA (Multi Factor Authentication)
          </p>
        </div>
        {isUpdating && !isEditingSelf && (
          <div className="checkbox-field-container">
            <Field
              className="coach-input-field checkbox"
              name="disabled"
              label=""
              component={CheckboxField}
            />
            <p className="checkbox-description">
              Block user: this user will no longer have access to the Bardavon
              Recovery dashboard
            </p>
          </div>
        )}
        {message ? <SuccessAnimation message={message} /> : this.renderSubmit()}
      </form>
    );
  }

  renderHeader() {
    const {
      initialValues: { name },
      isUpdating,
    } = this.props;

    return isUpdating ? EditHeader(name) : AddHeader();
  }

  renderSubmit() {
    const { isUpdating, createDisabled, updateDisabled, submitting } =
      this.props;

    return isUpdating
      ? EditSubmit(updateDisabled, submitting)
      : AddSubmit(createDisabled, submitting);
  }
}

export default reduxForm({
  form: "manageCoach",
  validate: validateForm(defaultRules, { fullMessages: false }),
  // comment back in when API supports validating email availability for dashboard users. currently only works for peers / webapp users
  // asyncValidate,
  // asyncChangefields: ['email']
})(CoachForm);

function AddSubmit(createDisabled, submitting) {
  return (
    <input
      className={classNames("coach-submit-button", {
        disabled: createDisabled || submitting,
      })}
      type="submit"
      value="Done"
      disabled={createDisabled || submitting}
    />
  );
}

function EditSubmit(updateDisabled, submitting) {
  return (
    <input
      className={classNames("coach-submit-button", {
        disabled: updateDisabled || submitting,
      })}
      type="submit"
      value="Update"
      disabled={updateDisabled || submitting}
    />
  );
}

function AddHeader() {
  return (
    <div className="coach-header-container">
      <h2 className="coach-form-header">Add coach</h2>
      <span>
        required (<span className="asterisk">*</span>)
      </span>
    </div>
  );
}

function EditHeader(name) {
  return (
    <div className="coach-header-container">
      <h2 className="coach-form-header">Edit {name ?? "coach"}</h2>
      <span>
        required (<span className="asterisk">*</span>)
      </span>
    </div>
  );
}
