import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../../../../../shared/app/requestErrorHandler";
import {
  setFleetingMessage,
  submitFormToAPI,
} from "../../../../../../../../shared/utils/form";
import {
  didGetAvailableAssignedToUsersForPeer,
  didUpdateAssignedTo,
  getAvailableAssignedToUsersForPeer,
  updateAssignedToForPeer,
} from "./actions";
import {
  getAvailableAssignedToUsersForPeer as getAvailableAssignedToUsersForPeerAPI,
  updateAssignedToForPeer as updateAssignedToForPeerAPI,
} from "./api";

function* submitAssignedToUpdate(action) {
  const { peerId, assignedToId } = action.payload;

  yield put(
    submitFormToAPI(
      "updateAssignedTo",
      updateAssignedToForPeerAPI,
      (result) => didUpdateAssignedTo(peerId, result),
      peerId,
      assignedToId
    )
  );
}

function* fetchAvailableAssignedTo(action) {
  const { peerId } = action.payload;
  try {
    const result = yield getAvailableAssignedToUsersForPeerAPI(peerId);
    yield put(didGetAvailableAssignedToUsersForPeer(peerId, result.data));
  } catch (e) {
    yield handleApiError(e);
  }
}

export default function* watchUpdateAssignedToSaga() {
  yield all([
    takeLatest(updateAssignedToForPeer, submitAssignedToUpdate),
    takeLatest(
      didUpdateAssignedTo,
      setFleetingMessage("updateAssignedTo", {
        message: "Assigned to has been updated.",
      })
    ),
    takeLatest(getAvailableAssignedToUsersForPeer, fetchAvailableAssignedTo),
  ]);
}
