import axios from "axios";

export const transitionPatient = (peerId, { program, part }) =>
  axios.post(`/api/admin/v1/patients/${peerId}/program_parts`, {
    program,
    part,
  });

export const fetchPrograms = () =>
  axios.get("/api/admin/v1/programs?aggregated");

export const fetchCliniciansByCustomer = (customerId) =>
  axios.get(`/api/admin/v1/clinicians?customer_id=${customerId}`);
