import { connect } from "react-redux";

import { fleetingFormMeta } from "../../../../../shared/utils/form";

import CreateClinicianForm from "./CreateClinicianForm.jsx";

function mapStateToProps(
  state,
  { onSubmit, onCancel, clinician, programList }
) {
  return {
    initialValues: {
      programs: [],
    },
    onSubmit,
    onCancel,
    clinician,
    programList,
    message: fleetingFormMeta(state, "manageClinician").message,
  };
}

export default connect(mapStateToProps)(CreateClinicianForm);
