import { connect } from "react-redux";
import RemoveCareTeamMember from "./RemoveCareTeamMember.jsx";

import { removeCareTeamMember } from "./actions";
import { hidePanel } from "../../../../../../../../shared/components/ModalPanel/actions";

import { shouldDisplayLoadingIndicator } from "../../../../../../../../shared/components/LoadingIndicator/reducer";
import { fleetingFormMeta } from "../../../../../../../../shared/utils/form";

function mapStateToProps(state) {
  return {
    isDeleting: shouldDisplayLoadingIndicator(state),
    successfulDeleteMessage: fleetingFormMeta(state, "removeCareTeamMember")
      .message,
  };
}

function mapDispatchToProps(dispatch, { userId, member }) {
  return {
    onSubmit: () =>
      dispatch(
        removeCareTeamMember({
          peerId: userId,
          member: member,
        })
      ),
    hidePanel: () => dispatch(hidePanel("removeCareTeamMember")),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveCareTeamMember);
