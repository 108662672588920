import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./../FormGroupField/FormGroupField.css";

function SelectField({
  input,
  label,
  required,
  type,
  meta: { touched, error, warning, pristine },
  disabled,
  className,
  values,
  defaultValue,
}) {
  const shouldDisplayError = !pristine || touched;

  const fieldClass = classNames(
    "form-control",
    { "has-error": shouldDisplayError && error },
    className
  );

  return (
    <div className="form-group">
      <label htmlFor={input.name}>
        {label}
        {required ? <span className="asterisk"> *</span> : null}
      </label>

      <select
        disabled={disabled}
        name={input.name}
        onChange={(e) => {
          const value = e.target.value;
          if (value === "-") input.onChange("");
          // For option "-", don't want to store as label but as an empty string...
          else input.onChange(e);
        }}
        className={fieldClass}
        defaultValue={defaultValue}
      >
        {!required && <option>-</option>}
        {values &&
          values.map((v) => (
            <option
              key={v.value + v.label}
              value={v.value}
              selected={v.selected}
            >
              {v.label}
            </option>
          ))}
      </select>

      {shouldDisplayError &&
        ((error && <span className="error-message">{error}</span>) ||
          (warning && <span className="warning-message">{warning}</span>))}
    </div>
  );
}

SelectField.propTypes = {
  dateProps: PropTypes.object,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string.isRequired,
    })
  ),
  // Also a bunch of redux field props
};

SelectField.defaultProps = {
  dateProps: {},
};

export default SelectField;
