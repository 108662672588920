import { connect } from "react-redux";
import { userType } from "../../../../../../shared/routes/Authentication/Login/reducer";
import { showPanel } from "../../../../../../shared/components/ModalPanel/actions";
import Background from "./Background.jsx";

function mapStateToProps(state) {
  return {
    authenticatedUserType: userType(state),
  };
}

const dispatch = {
  onEditBackground: () => showPanel("updateBackground"),
};

export default connect(mapStateToProps, dispatch)(Background);
