import { createAction } from "redux-actions";

const prefixed = (val) => `CARDS_INTERACTION::${val}`;

export const fetchCard = createAction(
  prefixed("FETCH_CARD"),
  (cardId, clinicianId) => ({ cardId, clinicianId })
);
export const didFetchCard = createAction(prefixed("DID_FETCH_CARD"));
export const resetCard = createAction(prefixed("RESET_CARD"));
