import { handleActions } from "redux-actions";
import { find, get } from "lodash";

import {
  didFetchCustomers,
  didFetchWeeklyAdoptionReport,
  didUpdateEndDate,
  didUpdateStartDate,
} from "./actions";

export function customers(state) {
  return state.ui.WeeklyAdoptionReports.customers;
}

export function startDate(state) {
  return state.ui.WeeklyAdoptionReports.startDate;
}

export function endDate(state) {
  return state.ui.WeeklyAdoptionReports.endDate;
}

// Defaults to first customer if none is selected
export function selectedCustomer(state) {
  const currentCustomers = customers(state);
  if (currentCustomers.length === 0) {
    return null;
  }
  const customerId = state.router.params.customerId;
  return (
    find(currentCustomers, (c) => c.id === customerId) || currentCustomers[0]
  );
}

export function reportForCustomer(state, customerId) {
  return get(state, `ui.WeeklyAdoptionReports.reports.${customerId}`);
}

export default handleActions(
  {
    [didFetchCustomers]: (state, action) => ({
      ...state,
      customers: action.payload,
    }),
    [didUpdateStartDate]: (state, action) => ({
      ...state,
      startDate: action.payload,
    }),
    [didUpdateEndDate]: (state, action) => ({
      ...state,
      endDate: action.payload,
    }),
    [didFetchWeeklyAdoptionReport]: (state, action) => {
      const payload = action.payload;
      const customerId = payload.customerId;
      const reports = state.reports;
      reports[customerId] = payload.data;
      return { ...state, reports };
    },
  },
  {
    customers: [],
    reports: {},
  }
);
