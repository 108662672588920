import { createAction } from "redux-actions";

const prefixed = (val) => `Invites/View/Email::${val}`;

export const updateEmailForInvite = createAction(
  prefixed("UPDATE_EMAIL_FOR_Invite"),
  (inviteId, email) => ({ email, inviteId })
);
export const didUpdateEmail = createAction(
  prefixed("DID_UPDATE_EMAIL"),
  (inviteId, email) => ({ email, inviteId })
);
