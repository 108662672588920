import axios from "axios";
import { objectToQuery } from "../../shared/routes/query";

export const fetchTeamResults = ({
  keyword = "",
  sortBy = "fullname",
  sortDirection = "asc",
  page = 0,
  perPage = 30,
}) => {
  const query = objectToQuery({
    keyword: encodeURIComponent(keyword),
    sort_by: sortBy,
    sort_direction: sortDirection,
    page: page && parseInt(page, 10),
    per_page: perPage && parseInt(perPage, 10),
  });
  return axios.get(`api/admin/v1/owners/?${query}`);
};

export const createOwner = (values) =>
  axios.post("/api/admin/v1/owners/", values);
export const createPartnerAdmin = (values) =>
  axios.post("/api/admin/v1/partner_admins/", values);

export const resendInvite = (partnerUserId) =>
  axios.post(`/api/admin/v1/partner_users/${partnerUserId}/invites`);

export const updateOwner = ({ id, fullname, email, group, blocked }) =>
  axios.patch(`/api/admin/v1/owners/${id}`, {
    fullname,
    email,
    group: group && group.value ? group.value : group,
    blocked: Boolean(blocked),
  });
export const updatePartnerAdmin = ({ id, fullname, email, group, blocked }) =>
  axios.patch(`/api/admin/v1/partner_admins/${id}`, {
    fullname,
    email,
    group: group && group.value ? group.value : group,
    blocked: Boolean(blocked),
  });
export const updateRole = ({ id, userable_type }) =>
  axios.patch(`/api/admin/v1/partner_users/${id}`, { userable_type });

export const fetchExternalFields = () =>
  axios.get("/api/admin/v1/users/external_service_fields");

export const fetchCustomers = () => axios.get("/api/admin/v1/customers/");

export const fetchGroupsAsAdmin = (customerId) =>
  axios.get(`/api/admin/v1/customers/${customerId}/external_fields/select`);
