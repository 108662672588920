import React from "react";
import PropTypes from "prop-types";

import "./NoData.css";

import "./../ChartWrapper";
import ChartWrapper from "../ChartWrapper";

const fakeData = {
  info: {
    title: null,
    best_value_label: "",
    image: null,
  },
  metrics: [
    {
      value: 69,
      timestamp: 1515274282000,
      formatted: 69,
    },
    {
      value: 22,
      timestamp: 1516052542000,
      formatted: 22,
    },
    {
      value: 23,
      timestamp: 1516225945000,
      formatted: 23,
    },
  ],
  best: {
    value: 22,
    formatted: 22,
  },
  range: {
    y: {
      start: {
        value: 20,
        formatted: 20,
      },
      end: {
        value: 76,
        formatted: 76,
      },
    },
    x: {
      start: {
        value: 1515274282000,
        formatted: "Start",
      },
      end: {
        value: 1517927931009,
        formatted: "Now",
      },
      // labels: [{
      //   formatted: 'Surgery',
      //   value: 1515732893000
      // }]
    },
  },
};

function NoData({ title }) {
  const copy = Object.assign({}, fakeData);
  copy.info.title = title;

  return (
    <div className="no-metric-data">
      <h2>{title}</h2>
      <div className="overlay">
        <h3>No {title} Data</h3>
      </div>
      <ChartWrapper metric={Object.assign(copy)} />
    </div>
  );
}

NoData.propTypes = {
  metric: PropTypes.object,
};

NoData.defaultProps = {
  metric: null,
};

export default NoData;
