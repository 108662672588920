import React from "react";

import "./MfaField.css";

function MfaField({
  className,
  input,
  label,
  type,
  meta: { touched, error, warning, pristine },
  id,
}) {
  const inputLength = input.value.length;
  const placeholder = <span className="mfa-dash">-</span>;

  return (
    <div className={className}>
      <div className="mfa-field-wrapper">
        <div className="mfa-field-container">
          <div>
            <div className="input-fake">{inputLength < 1 && placeholder}</div>
            <div className="input-fake">{inputLength < 2 && placeholder}</div>
            <div className="input-fake">{inputLength < 3 && placeholder}</div>
            <div className="input-fake">{inputLength < 4 && placeholder}</div>
            <div className="input-fake">{inputLength < 5 && placeholder}</div>
            <div className="input-fake">{inputLength < 6 && placeholder}</div>
            <input
              {...input}
              className="input-real"
              maxLength="6"
              type={type}
              id={id}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MfaField;
