import { call, put, select, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../../../../../shared/app/requestErrorHandler";
import {
  setFleetingFormMeta,
  setFleetingMessage,
} from "../../../../../../../../shared/utils/form";
import {
  addCareTeamMemberForPeer,
  didAddCareTeamMemberForPeer,
  didUpdateCareTeamForPeer,
  updateCareTeamForPeer,
} from "./actions";
import {
  addCareTeamMemberForPeer as addCareTeamMemberForPeerAPI,
  updateCareTeamForPeer as updateCareTeamForPeerAPI,
} from "./api";
import { requiresCareTeamIds } from "./reducer";

function* submitCreateCareTeamMember(action) {
  try {
    const response = yield call(
      addCareTeamMemberForPeerAPI,
      action.payload.member
    );
    yield put(didAddCareTeamMemberForPeer());
    yield put(
      updateCareTeamForPeer({
        member: response.data,
        peerId: action.payload.peerId,
      })
    );
  } catch (e) {
    let error = e.response && e.response.data && e.response.data.error;
    yield put(setFleetingFormMeta("addCareTeamMember", { apiError: error }));
  }
}

function* submitCareTeam(action) {
  const peerId = action.payload.peerId;
  const member = action.payload.member;
  let ids = yield select(requiresCareTeamIds, peerId);
  if (!ids.includes(member.id)) {
    ids.push(member.id);
    try {
      yield updateCareTeamForPeerAPI(peerId, ids);
      yield put(didUpdateCareTeamForPeer(action.payload));
    } catch (e) {
      yield handleApiError(e);
    }
  } else {
    yield put(
      setFleetingFormMeta("addCareTeamMember", { message: "Already added" })
    );
  }
}

export default function* watchAddCareTeamMember() {
  yield takeLatest(addCareTeamMemberForPeer, submitCreateCareTeamMember);
  yield takeLatest(updateCareTeamForPeer, submitCareTeam);
  yield takeLatest(
    didUpdateCareTeamForPeer,
    setFleetingMessage("addCareTeamMember", { message: "Invitation sent" })
  );
}
