import { connect } from "react-redux";
import NotificationCenter from "./NotificationCenter.jsx";

function mapStateToProps(state) {
  return {
    notifications: state.notifications,
  };
}

export default connect(mapStateToProps)(NotificationCenter);
