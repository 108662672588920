import React from "react";
import PropTypes from "prop-types";

import UploadUsersForm from "./components/UploadUsersForm";
import CreateUsersForm from "./components/CreateUsersForm";
import UpdateUsersForm from "./components/UpdateUsersForm/UpdateUsersForm";
import SearchableDropdown from "../../../shared/components/SearchableDropdown";

import "./ManageUsers.css";

// TODO: unify css for all Manage routes

class ManageAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: false,
      isEditing: false,
      isUploading: false,
      csv: null,
    };

    this.onCreateUser = this.onCreateUser.bind(this);
    this.onUploadUsers = this.onUploadUsers.bind(this);
    this.onUpdateUser = this.onUpdateUser.bind(this);
    this.onSelectCustomer = this.onSelectCustomer.bind(this);
    this.onDeselectCustomer = this.onDeselectCustomer.bind(this);
    this.onSelectUser = this.onSelectUser.bind(this);
    this.onDeselectUser = this.onDeselectUser.bind(this);
  }

  componentDidMount() {
    const {
      customerList,
      requestCustomers,
      requestAllUsers,
      customerId,
      requestGroups,
    } = this.props;

    if (!customerList) {
      requestCustomers();
    }

    if (customerId) {
      requestAllUsers(customerId);
      requestGroups(customerId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      userId,
      clearUserData,
      customerId,
      message,
      requestAllUsers,
      requestGroups,
    } = this.props;

    if (customerId && customerId !== prevProps.customerId) {
      requestAllUsers(customerId);
      requestGroups(customerId);
    }

    if (!userId && prevProps.userId) {
      clearUserData();
    }

    if (!message && prevProps.message) {
      let query = `?customerId=${customerId}`;
      if (userId) {
        query += `&userId=${userId}`;
      }
      document.location.href = `/manage-partner-users/${query}`;
    }
  }

  onSelectCustomer(customerId) {
    const { selectCustomer } = this.props;
    selectCustomer(customerId);
  }

  onDeselectCustomer() {
    const { deselectCustomer } = this.props;
    deselectCustomer();
    this.setState({ isEditing: false, isCreating: false });
  }

  onSelectUser(userId) {
    const { selectUser } = this.props;
    selectUser(userId);
  }

  onDeselectUser() {
    const { deselectUser } = this.props;
    this.setState({ isEditing: false, isCreating: false });
    deselectUser();
  }

  onCreateUser(values) {
    const { createUser, customerId } = this.props;

    createUser({
      customer_id: customerId,
      email: values.email,
      fullname: values.fullname,
      blocked: values.blocked,
      type: values.type,
      group: values.group,
    });
  }

  onUploadUsers() {
    const { uploadUsers, customerId } = this.props;
    const { csv } = this.state;

    uploadUsers(customerId, csv);
  }

  onUpdateUser(values) {
    const { updateUser, userId, user } = this.props;
    updateUser({ userId, type: user.type, ...values });
  }

  renderHeaderInfo() {
    const { customer, customerId, user, userId } = this.props;

    if (!customer) return null;

    return (
      <div className="header-info">
        {customer && customerId && (
          <div className="header-group">
            <p className="selected-customer">
              <span className="header-prefix">Customer:</span>{" "}
              {customer.customer.name}
            </p>
            <button className="action-btn" onClick={this.onDeselectCustomer}>
              Choose Another
            </button>
          </div>
        )}
        {user && userId && (
          <div className="header-group">
            <p className="selected-customer">
              <span className="header-prefix">User:</span> {user.fullname}
            </p>
            <button className="action-btn" onClick={this.onDeselectUser}>
              Choose Another
            </button>
          </div>
        )}
      </div>
    );
  }

  renderChooseCustomer() {
    const { customerList, customerId } = this.props;

    if (!customerList || customerId) return null;

    const values = customerList.map(({ customer }) => ({
      label: customer.name,
      value: customer.id,
      onClick: () => this.onSelectCustomer(customer.id),
    }));

    return (
      <div className="select-customer">
        <h4>Choose a partner first</h4>
        <SearchableDropdown
          values={values}
          placeholder="Select or Search for Customer"
        />
      </div>
    );
  }

  renderChooseUser() {
    const { customer, userId, userList } = this.props;
    const { isCreating, isUploading } = this.state;

    if (!customer || isCreating || isUploading || userId) return null;
    const values = userList
      ? userList.map((user) => ({
          value: user.id,
          label: user.fullname ? user.fullname : "",
          onClick: () => this.onSelectUser(user.id),
        }))
      : [];

    return (
      <div className="select-admin">
        <h3>Add new partner admin or owner</h3>
        <button
          className="action-btn"
          onClick={() => this.setState({ isCreating: true })}
        >
          Add User
        </button>

        <div className="update-existing">
          <h3>Bulk upload</h3>
          <button
            className="action-btn"
            onClick={() => this.setState({ isUploading: true })}
          >
            Add Users
          </button>
        </div>

        <div className="update-existing">
          <h4>Manage existing partner admins & owners</h4>
          <SearchableDropdown
            values={values}
            placeholder="Select or Search for Partner User"
          />
        </div>
      </div>
    );
  }

  renderCreateUserForm() {
    const { isCreating } = this.state;
    const { groups } = this.props;

    if (!isCreating) return null;

    return (
      <CreateUsersForm
        onSubmit={this.onCreateUser}
        groups={groups}
        onCancel={() => this.setState({ isCreating: false })}
      />
    );
  }

  renderUploadUsersForm() {
    const { isUploading } = this.state;

    if (!isUploading) return null;

    return (
      <UploadUsersForm
        onSubmit={this.onUploadUsers}
        onSelectFile={(e) => this.setState({ csv: e.target.files[0] })}
        onCancel={() => this.setState({ isUploading: false })}
      />
    );
  }

  renderUserValues() {
    const { user, groups } = this.props;
    const { isEditing } = this.state;

    if (!user || isEditing) return null;

    return (
      <div className="user-details">
        <h4>User Details</h4>
        <button
          className="action-btn"
          onClick={() => this.setState({ isEditing: true })}
        >
          Edit Details
        </button>
        <div className="update-existing">
          <p className="or-update">
            <span className="header-prefix">Full Name</span> {user.fullname}
          </p>
          <p className="or-update">
            <span className="header-prefix">Email</span> {user.email}
          </p>
          <p className="or-update">
            <span className="header-prefix">Type</span> {user.type}
          </p>
          <p className="or-update">
            <span className="header-prefix">Blocked</span>{" "}
            {user.blocked.toString()}
          </p>
          {groups && user && user.group && (
            <p className="or-update">
              <span className="header-prefix">Group</span>
              {user.group}
            </p>
          )}
        </div>
      </div>
    );
  }

  renderUpdateUserForm() {
    const { isEditing } = this.state;
    const { user, groups } = this.props;

    if (!isEditing) return null;

    return (
      <UpdateUsersForm
        user={user}
        groups={groups}
        onSubmit={this.onUpdateUser}
        onCancel={() => this.setState({ isEditing: false })}
      />
    );
  }

  render() {
    return (
      <div className="manage-admin">
        <h2>Manage Partner Admins and Owners</h2>
        {this.renderHeaderInfo()}
        {this.renderChooseCustomer()}
        {this.renderChooseUser()}
        {this.renderCreateUserForm()}
        {this.renderUploadUsersForm()}
        {this.renderUpdateUserForm()}
        {this.renderUserValues()}
      </div>
    );
  }
}

ManageAdmin.propTypes = {
  customerList: PropTypes.array,
  userList: PropTypes.array,
  customer: PropTypes.object,
  customerId: PropTypes.string,
  user: PropTypes.object,
  userId: PropTypes.string,
  message: PropTypes.string,
  requestCustomers: PropTypes.func.isRequired,
  requestAllUsers: PropTypes.func.isRequired,
  requestGroups: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  uploadUsers: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  selectCustomer: PropTypes.func.isRequired,
  deselectCustomer: PropTypes.func.isRequired,
  selectUser: PropTypes.func.isRequired,
  deselectUser: PropTypes.func.isRequired,
  clearUserData: PropTypes.func.isRequired,
};

ManageAdmin.defaultProps = {
  customerList: null,
  programList: null,
  customer: null,
  customerId: "",
  user: null,
  userId: "",
  message: "",
};

export default ManageAdmin;
