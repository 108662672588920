import { connect } from "react-redux";
import { isInvalid } from "redux-form";
import { fleetingFormMeta } from "../../../../../../../../shared/utils/form";
import {
  requestCustomers,
  requestInviteAdmin,
  requestPartnerUsersAdmin,
} from "../../../../../../../Patients/actions";
import {
  employerFormData,
  extraFields,
  ownerFormData,
  payerFormData,
} from "../../../../../../../Patients/components/PatientInviteForm/selectors";
import { customers } from "../../../../../../../Patients/reducer";
import { fetchAvailablePrograms, requestChangePartner } from "./actions";
import ChangePartner from "./ChangePartner.jsx";
import { getPeerAvailableParts } from "./reducer.js";

function mapStateToProps(state, { userId }) {
  return {
    customers: customers(state),
    extraFields: extraFields(state),
    partnerUserData: ownerFormData(state),
    employerData: employerFormData(state),
    payerData: payerFormData(state),
    disabled: isInvalid("changePartner")(state) || false, //!allRequiredFieldsFilledOut(state),
    message: fleetingFormMeta(state, "changePartner").message,
    availableParts: getPeerAvailableParts(state, userId),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestCustomers: () => dispatch(requestCustomers()),
    requestPartnerUsers: (id) => dispatch(requestPartnerUsersAdmin(id)),
    onSubmit: (values) => dispatch(requestChangePartner(values)),
    requestExtraFieldData: (clinicianId) =>
      dispatch(requestInviteAdmin(clinicianId)),
    fetchAvailablePrograms: (customerId, userId) =>
      dispatch(fetchAvailablePrograms(customerId, userId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePartner);
