import axios from "axios";

export const updatePartner = (peerId, data) =>
  axios.post(`/api/admin/v1/patients/${peerId}/program_parts`, data);

export const fetchPrograms = () =>
  axios.get("/api/admin/v1/programs?aggregated");

export const fetchCliniciansByCustomer = (customerId) =>
  axios.get(`/api/admin/v1/clinicians?customer_id=${customerId}`);
