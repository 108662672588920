import React from "react";
import PropTypes from "prop-types";

import PickDate from "../../../../../../shared/components/PeerProfile/PickDate";

function UpdateSurgeryDate({
  surgeryDate,
  setSurgeryDate,
  surgeryDateRequired,
  clearSurgeryDateMessage,
  userType,
  programType,
  error,
  isRegistered = false,
}) {
  return (
    <div>
      <h1>Update surgery date</h1>
      <PickDate
        name={"Surgery"}
        pickDate={surgeryDate}
        setPickDate={setSurgeryDate}
        pickDateRequired={surgeryDateRequired}
        clearMessage={clearSurgeryDateMessage}
        userType={userType}
        programType={programType}
        error={error}
        isRegistered={isRegistered}
      />
    </div>
  );
}

UpdateSurgeryDate.propTypes = {
  userId: PropTypes.string.isRequired,

  // Automatically added
  surgeryDate: PropTypes.number.isRequired,
  setSurgeryDate: PropTypes.func.isRequired,
  surgeryDateRequired: PropTypes.bool.isRequired,
  clearSurgeryDateMessage: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default UpdateSurgeryDate;
