import React from "react";
import classNames from "classnames";

function PlaceholderFormField({
  input,
  label,
  placeholder,
  required,
  type,
  formatter,
  meta: { touched, error, warning, pristine },
}) {
  const shouldDisplayError = !pristine || touched;

  const fieldClass = classNames("form-control", {
    "has-error": shouldDisplayError && error,
  });

  return (
    <div className="form-group">
      {formatter}
      <label htmlFor={input.name}>
        {label}
        {required ? "*" : null}
      </label>
      <input
        {...input}
        className={fieldClass}
        placeholder={placeholder}
        type={type}
        onChange={(e) => {
          if (formatter) {
            e.target.value = formatter(e.target.value);
          }
          input.onChange(e);
        }}
      />
      {shouldDisplayError &&
        ((error && <span className="error-message">{error}</span>) ||
          (warning && <span className="warning-message">{warning}</span>))}
    </div>
  );
}

export default PlaceholderFormField;
