import { connect } from "react-redux";

// This connect does not map the onSubmit function! This is done for the Peer and Invite view seperately

import Form from "./Form.jsx";
import { fleetingFormMeta } from "../../../../../../../../shared/utils/form";

const FORM_ID = "externalService";

function mapStateToProps(state, { data }) {
  return {
    message: fleetingFormMeta(state, FORM_ID).message,
    initialValues: data,
  };
}

export default connect(mapStateToProps)(Form);
