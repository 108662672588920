import { connect } from "react-redux";
import AddCareTeamMember from "./../../../../../../components/UserView/components/Forms/AddCareTeamMember";
import { setFleetingFormMeta } from "./../../../../../../../../shared/utils/form";

import { addCareTeamMemberForPeer, updateCareTeamForPeer } from "./actions";

function mapDispatchToProps(dispatch, { userId }) {
  return {
    onSubmit: (values) => {
      if ("id" in values) {
        dispatch(
          updateCareTeamForPeer({
            member: values,
            peerId: userId,
          })
        );
      } else {
        dispatch(
          addCareTeamMemberForPeer({
            member: values,
            peerId: userId,
          })
        );
      }
    },
    clearApiError: () =>
      dispatch(setFleetingFormMeta("addCareTeamMember", { apiError: null })),
  };
}

export default connect(null, mapDispatchToProps)(AddCareTeamMember);
