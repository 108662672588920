import { createAction } from "redux-actions";
import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../shared/app/requestErrorHandler";
import { fetchPeers as fetchPeersAPI } from "./api";
import watchFilterSaga from "./components/Filter/sagas";
import watchSearchSaga from "./components/Search/sagas";

const prefixed = (value) => `Peers/List::${value}`;

export const requestPeers = createAction(
  prefixed("REQUEST_PEERS"),
  (page, filter) => ({ page, filter })
);
export const didFetchPeers = createAction(prefixed("DID_FETCH_PEERS"));

function* fetchPeers(action) {
  try {
    const response = yield fetchPeersAPI(
      action.payload.page,
      action.payload.filter
    );
    yield put(didFetchPeers(response.data));
  } catch (e) {
    yield handleApiError(e);
  }
}

export default function* watchListSagas() {
  yield all([
    watchSearchSaga(),
    watchFilterSaga(),
    takeLatest(requestPeers, fetchPeers),
  ]);
}
