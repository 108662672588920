import { put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../shared/app/requestErrorHandler";
import { onDidShowDeletedChanged, onShowDeletedChange } from "./actions";

function* showDeleteSaga(action) {
  try {
    yield put(onDidShowDeletedChanged(action.payload));
  } catch (e) {
    yield handleApiError(e);
  }
}

export default function* watchPhasesListSaga() {
  yield takeLatest(onShowDeletedChange, showDeleteSaga);
}
