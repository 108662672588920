import { handleActions } from "redux-actions";
import { get } from "lodash";

import { didFetchKPIStats } from "./actions";

export function stats(state) {
  return get(state, "ui.KPIStats.stats");
}

export default handleActions(
  {
    [didFetchKPIStats]: (state, action) => ({
      ...state,
      stats: action.payload,
    }),
  },
  {
    stats: null,
  }
);
