import moment from "moment";
import { put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../shared/app/requestErrorHandler";
import {
  didFetchCoreReport,
  didResetReport,
  didUpdateEndDate,
  requestCoreReport,
  requestResetReport,
  requestUpdateEndDate,
} from "./actions";
import {
  fetchAllCustomersCoreReport as fetchAllCustomersCoreReportAPI,
  fetchPredefinedCoreReport as fetchPredefinedCoreReportAPI,
} from "./api";

function* fetchCoreReport(action) {
  const reportType = action.payload.reportType;
  let query = "";

  try {
    if (reportType === "All Customers") {
      if (action.payload.endDate) {
        const ISODate = moment(action.payload.endDate).format("YYYY-MM-DD");
        query += `?end_date=${ISODate}`;
      }
      const response = yield fetchAllCustomersCoreReportAPI(query);
      yield put(didFetchCoreReport(response));
    } else if (reportType === "Predefined Customers") {
      if (action.payload.endDate) {
        const ISODate = moment(action.payload.endDate).format("YYYY-MM-DD");
        query += `&end_date=${ISODate}`;
      }
      const response = yield fetchPredefinedCoreReportAPI(query);
      yield put(didFetchCoreReport(response));
    }
  } catch (e) {
    yield handleApiError(e);
  }
}

function* updateEndDate(action) {
  yield put(didUpdateEndDate(action.payload));
}

function* resetReport(action) {
  yield put(didResetReport(action.payload));
}

export default function* watchCoreReportSaga() {
  yield takeLatest(requestCoreReport, fetchCoreReport);
  yield takeLatest(requestUpdateEndDate, updateEndDate);
  yield takeLatest(requestResetReport, resetReport);
}
