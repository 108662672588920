import { takeLatest } from "redux-saga/effects";
import { callAPI } from "../../../../../../shared/utils/sagas";

import { getFilter as getFilterAPI } from "./api";
import { didFetchPeerFilter, requestPeerFilter } from "./actions";

export default function* watchFilterSaga() {
  yield takeLatest(
    requestPeerFilter,
    callAPI(getFilterAPI, didFetchPeerFilter)
  );
}
