import { success } from "react-notification-system-redux";
import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../shared/app/requestErrorHandler";
import {
  didCreateProgram,
  didSimulateProgram,
  didUpdateProgram,
  simulateProgram,
} from "./actions";
import { simulateProgram as simulateProgramAPI } from "./api";

function* notifyUser() {
  yield put(success({ message: "The changes have been saved." }));
}

function* simulateProgramFunctionSaga(action) {
  const { programId, values } = action.payload;
  try {
    const response = yield simulateProgramAPI(programId, values);
    yield put(didSimulateProgram(response.data, programId));
  } catch (e) {
    yield handleApiError(e);
  }
}

export default function* watchProgramsFormSaga() {
  yield all([
    takeLatest(didCreateProgram, notifyUser),
    takeLatest(didUpdateProgram, notifyUser),
    takeLatest(simulateProgram, simulateProgramFunctionSaga),
  ]);
}
