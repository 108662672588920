import { connect } from "react-redux";

import ProgramsForm from "./ProgramsForm.jsx";
import { submitFormToAPI } from "../../../../shared/utils/form";

import { didCreateProgram, didUpdateProgram, simulateProgram } from "./actions";
import {
  createProgram as createProgramAPI,
  updateProgram as updateProgramAPI,
  fetchAllPhases,
} from "./api";
import { updateQuery } from "../../../../shared/routes/query";

const FORM_ID = "program";

function mapStateToProps(state, { program }) {
  const createProgramInit = {
    name: "",
    dailyCardCap: 0,
    description: "",
    phases: [],
    fillerContent: [],
    bodyPart: "",
    type: "",
  };

  return {
    initialValues: { ...createProgramInit, ...program },
    fetchAllPhases,
  };
}

function mapDispatchToProps(dispatch, { program }) {
  return {
    onSubmit: (values) => {
      values.phases = values.phases ? mapPhasesToIds(values.phases) : [];
      values.fillerContent = values.fillerContent
        ? mapPhasesToIds(values.fillerContent)
        : [];

      if (values.dailyCardCap) {
        values.dailyCardCap = parseInt(values.dailyCardCap, 10);
      }
      // This value, not expected in backend, will be ignored as per #DEV-2337. Removing it to make calls cleaner, will make future debugging easier.
      if (values.pinnedCards) {
        delete values["pinnedCards"];
      }
      if (values.programCards) {
        delete values["programCards"];
      }

      if (program.id) {
        dispatch(
          submitFormToAPI(
            FORM_ID,
            updateProgramAPI,
            didUpdateProgram,
            program.id,
            values
          )
        );
      } else {
        dispatch(
          submitFormToAPI(FORM_ID, createProgramAPI, didCreateProgram, values)
        );
      }
      dispatch(updateQuery({ create: undefined, edit: undefined }));
    },
    onSimulate: (programId, values) =>
      dispatch(simulateProgram(programId, values)),
  };
}

function mapPhasesToIds(phases) {
  return phases.map((phase) => {
    if (phase.value) {
      return phase.value;
    }
    return phase;
  });
}
export default connect(mapStateToProps, mapDispatchToProps)(ProgramsForm);
