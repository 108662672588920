const prodConfig = {
  API_URL: "https://api.peerwell.co",
  APP_URL: "https://dashboard.peerwell.co",
  PROGRAMS_API_URL: "https://program.peerwell.co",
  PREVENTION_API_URL: "https://prevention-api.peerwell.co",
  GOOGLE_ANALYTICS_ID: "UA-55724740-8",
};

function configVar(name) {
  // Precedence:
  // 1) process.env (eg: dev), 2) window.SERVER_DATA (eg: staging), 3) prod_config (prod)
  // REACT_APP_ prefix for env vars in dev, as that’s required by create-react-app - Ref https://create-react-app.dev/docs/adding-custom-environment-variables/
  return (
    process.env["REACT_APP_" + name] ||
    (globalThis.SERVER_DATA && globalThis.SERVER_DATA[name]) ||
    prodConfig[name]
  );
}

const config = {
  API_URL: configVar("API_URL"),
  APP_URL: configVar("APP_URL"),
  PROGRAMS_API_URL: configVar("PROGRAMS_API_URL"),
  PREVENTION_API_URL: configVar("PREVENTION_API_URL"),
};
console.log("config =", config);

export default config;
