import { connect } from "react-redux";

import Module from "./Module.jsx";

import { editModule } from "./actions";

const dispatch = {
  onEditButton: (id) => editModule(id),
};

export default connect(null, dispatch)(Module);
