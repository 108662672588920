import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../../../../shared/app/requestErrorHandler";
import { hidePanel } from "../../../../../../../shared/components/ModalPanel/actions";
import { submitFormToAPI } from "../../../../../../../shared/utils/form";
import {
  didFetchBackgroundForm,
  didUpdateBackground,
  requestBackgroundForm,
  updateBackground,
} from "./actions";
import {
  getIntakeQuestions as getIntakeQuestionsAPI,
  updateBackground as updateBackgroundAPI,
} from "./api";

function* fetchBackgroundForm(action) {
  try {
    const response = yield getIntakeQuestionsAPI(action.payload);
    yield put(didFetchBackgroundForm(response.data));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* submitBackgroundUpdate(action) {
  const { peerId, formValues, healthIssues } = action.payload;

  if (!formValues.intake_data.health_issues) {
    formValues.intake_data.health_issues = healthIssues;
  } else {
    const copyIssues = Object.assign({}, healthIssues);
    Object.keys(copyIssues).forEach((item) => {
      if (
        Object.values(formValues.intake_data.health_issues).filter(
          (el) => el.value === item
        ).length
      )
        copyIssues[item] = true;
      else copyIssues[item] = false;
    });
    formValues.intake_data.health_issues = copyIssues;
  }

  if (formValues.intake_data.weight)
    formValues.intake_data.weight = Number(formValues.intake_data.weight);
  if (formValues.intake_data.height) {
    formValues.intake_data.height.feet = Number(
      formValues.intake_data.height.feet
    );
    formValues.intake_data.height.inches = Number(
      formValues.intake_data.height.inches
    );
  }

  yield put(
    submitFormToAPI(
      "updateBackground",
      updateBackgroundAPI,
      didUpdateBackground(action.payload),
      peerId,
      formValues
    )
  );
  yield put(didUpdateBackground(action.payload));
  yield put(hidePanel());
}

export default function* watchUpdateBackgroundSaga() {
  yield all([
    takeLatest(requestBackgroundForm, fetchBackgroundForm),
    takeLatest(updateBackground, submitBackgroundUpdate),
  ]);
}
