import { castArray } from "lodash";
import { delay } from "redux-saga";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  allowedRole,
  defaultRoutes,
  userRole,
} from "../../../../routes/Authentication/index";
import { handleApiError } from "../../../app/requestErrorHandler";
import { setFleetingMessage } from "../../../utils/form";
import { currentPage, query } from "../../query";
import config from "./../../../../config";
import {
  confirm2Fa,
  failedLogin,
  fetchedUserInfo,
  newCodeSent,
  requestLogin,
  requestUserInfo,
  successFulLogin,
} from "./actions";
import { login as loginRequest, userInfo } from "./api";
import { isAuthenticated, needsPasswordReset, userType } from "./reducer";

const USER_INFO_FETCH_INTERVAL = 15 * 60 * 1000;

export function* performLogin(action) {
  const { email, password, resendingCode } = action.payload;

  try {
    if (!email || !password) {
      yield put(failedLogin("E-mail and password are required"));
    } else {
      const { data } = yield call(loginRequest, action.payload);
      // Check for customer's 2fa settings first
      if (data.mfa_sent === true) {
        // Defer login until they enter the 2fa code
        yield put(confirm2Fa());

        if (resendingCode) {
          yield put(newCodeSent());
        }

        return false;
      }

      const { userable_type, scope } = data;
      if (!allowedRole(userable_type, scope)) {
        yield put(failedLogin("This user cannot sign in here"));
        return false;
      }

      const { refresh_token, id, external_service } = data;
      const token = data.token ?? data.bearer_token;
      yield put(
        successFulLogin(
          token,
          refresh_token,
          email,
          userRole(userable_type, scope),
          id,
          external_service
        )
      );
      return true;
    }
  } catch (error) {
    const err = error.response.data.error;
    yield put(failedLogin(err));

    if (typeof err === "string" && err.toLowerCase() === "blocked user") {
      window.location.assign("/blocked");
    }
  }
}

// Redirects to the correct home page for the user (e.g sign in for unauthenticated, day plans for authenticated in desktop app)
export function* redirectToHome() {
  const authenticated = yield select(isAuthenticated);

  const role = yield select(userType);
  const url = getHomeForRole(role, authenticated);

  if (window.location.pathname !== url) {
    // The push has some weird issue when logging in... so a full refresh for now
    window.location.assign(url);
    // yield put(push(url))
  }
}

function getHomeForRole(role, authenticated) {
  if (authenticated && role === "prevention:read") {
    return "/workers";
  }
  return defaultRoutes[authenticated ? "authenticated" : "unauthenticated"];
}

// If the user is not authenticated he is redirect to the relevant page to authenticate, else nothing happens
export function* authenticationRequired() {
  const hasAuthentication = yield select(isAuthenticated);
  if (!hasAuthentication) {
    const currentUrl = yield select(currentPage);
    const url = `${
      defaultRoutes.unauthenticated
    }?afterLogin=${encodeURIComponent(currentUrl)}`;

    window.location.assign(url);
    // yield put(push(url))
  }
}

// The opposite of above, use this to disallow access on routes when authenticated
export function* authenticationDenied() {
  const hasAuthentication = yield select(isAuthenticated);
  if (hasAuthentication) {
    yield redirectToHome();
  }
}

// Can pass a single type or multiple types which have access
export function* authenticationOfTypeRequired(expectedAuthenticationTypes) {
  const hasAuthentication = yield select(isAuthenticated);
  if (!hasAuthentication) {
    yield authenticationRequired();
  } else {
    const type = yield select(userType);
    if (castArray(expectedAuthenticationTypes).indexOf(type) === -1) {
      window.location.assign("/denied");
      // yield put(push('/denied'))
    }
  }
}

function* performLoginAndRedirect(action) {
  const success = yield performLogin(action);
  if (success) {
    if (action.payload.event) {
      // TODO: make this less hacky
      // WEBAPP ONLY: we are only recording login events in webapp for now
      window.gtag("event", "intake_login_complete");
    }
    const afterLogin = yield select(query, "afterLogin");
    if (afterLogin) {
      const decoded = decodeURIComponent(afterLogin);
      // yield(put(push(decoded)))
      window.location.assign(decoded);
    } else {
      yield redirectToHome();
    }
  } else if (!success && action.payload.event) {
    // WEBAPP ONLY: we are only recording login events in webapp for now
    window.gtag("event", "intake_login_failed");
  }
}

function* fetchUserInfo() {
  try {
    const { data } = yield call(userInfo);
    yield put(fetchedUserInfo(data));

    // Periodically getting the data again
    yield delay(USER_INFO_FETCH_INTERVAL);
    yield put(requestUserInfo());
  } catch (error) {
    yield handleApiError(error);
  }
}

function* forcePasswordResetIfNeeded() {
  const shouldResetPassword = yield select(needsPasswordReset);
  if (shouldResetPassword === true) {
    // Forcing a full redirect to change password page using a full reload
    // That way each time the user navigates away to another page, it will get back here anyway
    // Don't do it if we are already on this page
    const resetPage = "/force-reset-password";
    // eslint-disable-next-line no-undef
    if (window.location.href.indexOf(resetPage) === -1) {
      // eslint-disable-next-line no-undef
      window.location.assign(resetPage);
    }
  }
}

export function* watchLoginSaga() {
  const loginSagas = [
    takeLatest(requestLogin, performLoginAndRedirect),
    takeLatest(fetchedUserInfo, forcePasswordResetIfNeeded),
    takeLatest(
      newCodeSent,
      setFleetingMessage(
        "login",
        { message: "A new code has been sent to your email." },
        3000
      )
    ),
  ];

  // Not all apps need user info to be fetched (eg email etc)- for example exercises / assessments
  if (!config.IGNORE_USER_INFO) {
    loginSagas.push(
      takeLatest(successFulLogin, fetchUserInfo),
      takeLatest(requestUserInfo, fetchUserInfo)
    );
  }

  yield all(loginSagas);
}
