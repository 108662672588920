import React, { useState } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";

import "./DeletePatient.css";
import Confirm from "../../../../../../../../shared/components/Confirm/Confirm";

function DeletePatient({ onSubmit, isDeleting }) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <btn className="btn btn-sm btn-danger" onClick={() => setVisible(true)}>
        Delete Patient
      </btn>
      <Confirm
        callback={(confirmed) => {
          setVisible(false);

          if (confirmed) {
            onSubmit();
          }
        }}
        message="Are you sure you want to delete this patient?"
        subText="This action cannot be undone."
        disabled={isDeleting}
        visible={visible}
      />
    </>
  );
}

DeletePatient.propTypes = {
  isDeleting: PropTypes.bool.isRequired,
  // Automatically added
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "deletePatient",
})(DeletePatient);
