import { createAction } from "redux-actions";

const prefixed = (val) => `BackgroundForm::${val}`;

export const updateBackground = createAction(
  prefixed("UPDATE_BACKGROUND"),
  (peerId, formValues, healthIssues) => ({ peerId, formValues, healthIssues })
);
export const didUpdateBackground = createAction(
  prefixed("DID_UPDATE_BACKGROUND"),
  (payload) => payload
);
export const requestBackgroundForm = createAction(
  prefixed("REQUEST_BACKGROUND_FORM"),
  (peerId) => peerId
);
export const didFetchBackgroundForm = createAction(
  prefixed("DID_FETCH_BACKGROUND_FORM"),
  (backgroundFormData) => backgroundFormData
);
