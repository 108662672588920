import ReactGA from "react-ga";
import { createAction } from "redux-actions";
import config from "../../config";
import { successFulLogin } from "../routes/Authentication/Login/actions";
import authStorage from "./authStorage";
import { blockMobileIfNeeded } from "./blockMobileIfNeeded";
import initAxios from "./request";
import { requestUpdateCheck } from "./updates/sagas";

const prefixed = (value) => `APP::${value}`;
export const appDidStart = createAction(prefixed("DID_START"));

// Will get called after the app is fully loaded and things are hooked up
export default function onAppLoad(store, router) {
  // Setting up the network client
  initAxios();

  const { email, token, userType, id, externalService, refreshToken } =
    authStorage.get();

  // initialize a different Google Analytics tracker id depending on which app is being visited
  if (config.GOOGLE_ANALYTICS_ID && config.ENV !== "test") {
    ReactGA.initialize(config.GOOGLE_ANALYTICS_ID);
  }

  if (email && token && userType && id) {
    store.dispatch(
      successFulLogin(token, refreshToken, email, userType, id, externalService)
    );
  }

  // Notifying the system the app started
  store.dispatch(appDidStart());

  // Firing the initial update check
  store.dispatch(requestUpdateCheck());

  // Make sure the initial load also triggers a "page change"
  const initialRouterState = store.getState().router;
  store.dispatch(router.initializeCurrentLocation(initialRouterState));

  blockMobileIfNeeded();
}
