import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import BottomBar from "../BottomBar";
import PinnedCards from "./component/PinnedCards";

import { Form, Formik } from "formik";
import FormikMdField from "../../../../shared/components/FormFields/FormikMdField";
import FormikSelectField from "../../../../shared/components/FormFields/FormikSelectField";
import FormikTextField from "../../../../shared/components/FormFields/FormikTextField";

const ProgramsForm = ({
  program,
  fetchAllPhases,
  onSubmit,
  initialValues,
  onSimulate,
}) => {
  const bodyParts = [
    "arm",
    "elbow",
    "foot-ankle",
    "knee",
    "hip",
    "leg",
    "wrist-hand",
    "low-back",
    "mid-back",
    "neck-head",
    "shoulder",
  ];

  const [offset, setOffset] = useState(0);
  const [phases, setPhases] = useState([]);

  const PREVENTIVE_TYPE = "preventive";
  const CHRONIC_TYPE = "chronic";
  const SURGERY_TYPE = "surgery";
  const types = [CHRONIC_TYPE, SURGERY_TYPE, PREVENTIVE_TYPE];

  const bodyPartOptions = bodyParts.map((bodyPart) => ({
    value: bodyPart,
    label: bodyPart,
  }));

  const phasesOptions = phases.map((phase) => ({
    value: phase.id,
    label: phase.name,
  }));

  const typeOptions = types.map((type) => ({
    value: type,
    label: type,
  }));

  function handleOffsetChange(e) {
    setOffset(e.target.value);
  }

  function processSimulate(programId) {
    onSimulate(programId, { days: offset });
    return false;
  }

  useEffect(async () => {
    setPhases(await fetchAllPhases());
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
      validationSchema={validation}
    >
      {({ values }) => {
        return (
          <div className="programs">
            <div className="col-md-6 program-edit">
              <h2>Edit Program</h2>
              {program && (
                <Form>
                  <div className="form">
                    <div className="program-form-fields">
                      <FormikTextField label="Name" name="name" required />
                      <FormikSelectField
                        label="Phases"
                        multi
                        name="phases"
                        options={phasesOptions}
                      />
                      <FormikSelectField
                        label="Filler phases"
                        multi
                        name="fillerContent"
                        options={phasesOptions}
                      />
                      <FormikSelectField
                        label="Body Part"
                        name="bodyPart"
                        options={bodyPartOptions}
                      />

                      <FormikSelectField
                        label="Type"
                        name="type"
                        options={typeOptions}
                        required
                      />

                      {values && values.type !== PREVENTIVE_TYPE && (
                        <FormikTextField
                          label="Daily Card Cap"
                          name="dailyCardCap"
                          type="number"
                        />
                      )}

                      <FormikMdField label="Description" name="description" />

                      <FormikMdField label="Notes" name="notes" />
                    </div>

                    <BottomBar
                      deleted={program.deleted}
                      programId={program.id}
                    />
                  </div>
                </Form>
              )}
            </div>
            {program && program.id && (
              <div className="col-md-6 pin-cards">
                <PinnedCards />
              </div>
            )}
            {program && program.id && (
              <div>
                <h2>Program Simulation</h2>
                <span className="programs-field-label">Days to simulate:</span>
                <input
                  id="offset"
                  value={offset}
                  onChange={handleOffsetChange}
                  type="number"
                />
                <button
                  className="btn btn-primary btn-simulate"
                  onClick={() => processSimulate(program.id)}
                >
                  Simulate
                </button>
              </div>
            )}
            {program &&
              program.simulation &&
              program.simulation.map((day, pIndex) => (
                <div key={`program-simulation-${pIndex}`}>
                  <h3>Day {day.day}</h3>
                  {day.cards &&
                    day.cards.map((card, cIndex) => (
                      <p key={`day-cards-${cIndex}`}>
                        Card Name: {card.name} - Card Pillar: {card.pillar}
                      </p>
                    ))}
                </div>
              ))}
          </div>
        );
      }}
    </Formik>
  );
};

const validation = Yup.object({
  name: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  dailyCardCap: Yup.number()
    .typeError("you must specify a number")
    .min(0, "must be higher or equal than 0")
    .max(20, "must be lower or equal than 20."),
});

export default ProgramsForm;
