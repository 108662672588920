import { handleActions } from "redux-actions";

// import { query } from '../../shared/routes/query'
import { didFetchRegistrantEmail } from "./actions";

export const registrationToken = (state) => state.router.params.secret;

export const registrantEmail = (state) => state.ui.Register.email;

export const userableType = (state) => state.ui.Register.type;

export default handleActions(
  {
    [didFetchRegistrantEmail]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  {
    email: null,
    type: null,
  }
);
