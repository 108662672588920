import React from "react";
import FormGroupField from "../FormGroupField/FormGroupField";

import "./CheckboxField.css";

function CheckBoxField(props) {
  props.input.checked = props.input.value === true;
  return (
    <div className="checkbox-form-control">
      <FormGroupField {...props} type="checkbox" className="checkbox" />
    </div>
  );
}

export default CheckBoxField;
