import { handleActions } from "redux-actions";
import { set } from "lodash";

import { didUpdateExternalService } from "./actions";

export default handleActions(
  {
    [didUpdateExternalService]: (state, action) => {
      const { peerId, values } = action.payload;
      const stateClone = { ...state };
      set(stateClone, `${peerId}.info.external_service.data`, values);
      // Hack, no idea why it does not re-render else
      return JSON.parse(JSON.stringify(stateClone));
    },
  },
  {}
);
