import { createAction } from "redux-actions";

const prefixed = (val) => `Peer::${val}`;

export const getExternalServiceFields = createAction(
  prefixed("GET_EXTERNAL_SERVICE_FIELDS")
);
export const didGetExternalServiceFields = createAction(
  prefixed("DID_GET_EXTERNAL_SERVICE_FIELDS"),
  (fields) => fields
);

export const requestEvents = createAction(
  prefixed("REQUEST_EVENTS"),
  (peerId) => peerId
);
export const didGetEvents = createAction(
  prefixed("DID_GET_EVENTS"),
  (peerId, events) => ({ id: peerId, events })
);
