import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "react-fa";
import Select from "react-select";

import SuccessAnimation from "../../../../../../shared/components/SuccessAnimation";

import { fetchCareTeamMember } from "../../../../api";

import "./CareTeamFormSection.css";

import { CARE_TEAM_JOB_OPTIONS } from "../../../../../User/components/UserView/components/Forms/AddCareTeamMember/careTeamJobOptions";

const EMAIL_REGEX_VALIDATOR =
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

class CareTeamFormSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      careTeam: [],
      email: "",
      job: "",
      isInputtingEmail: false,
      isSelectingJob: false,
      careTeamMessage: "",
      disabled: true,
    };

    this.emailInputRef = null;

    this.cancel = this.cancel.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleAddEmail = this.handleAddEmail.bind(this);
    this.handleJobChange = this.handleJobChange.bind(this);
    this.handleAddJob = this.handleAddJob.bind(this);
  }

  componentDidUpdate(_, prevState) {
    if (!prevState.isInputtingEmail && this.state.isInputtingEmail) {
      this.emailRef.focus();
    }
  }

  showFleetingMessage(message) {
    this.setState({ careTeamMessage: message });
    setTimeout(() => this.setState({ careTeamMessage: "" }), 2000);
  }

  addExistingCareTeamMember(memberToBeAdded) {
    const { careTeam } = this.state;
    // the map here is to shallow clone the objects, since you dont want to pass any old refs when setting state...
    const updatedCareTeam = careTeam
      .map((member) => ({ ...member }))
      .concat(memberToBeAdded);

    this.showFleetingMessage("Member added");
    this.setState({
      careTeam: updatedCareTeam,
      isInputtingEmail: false,
      isSelectingJob: false,
      email: "",
      job: "",
      disabled: true,
    });
    this.props.input.onChange(updatedCareTeam);
  }

  handleEmailChange(e) {
    const value = e.target.value;
    const { isSelectingJob, job } = this.state;

    const isValidEmail = EMAIL_REGEX_VALIDATOR.test(value);
    const disabled = (isSelectingJob && !job) || !isValidEmail;

    this.setState({ email: value, disabled });
  }

  handleAddEmail() {
    const { email } = this.state;
    // we run a check to see whether this care team member already exists based on email
    fetchCareTeamMember(email).then((res, err) => {
      if (res && res.data && res.data.results.length) {
        // care team member already exists, so we skip choosing their job and display their full name in the UI
        this.addExistingCareTeamMember(res.data.results[0]);
      } else {
        // care team member doesn't exist yet, need to know what their job is
        this.setState({ isSelectingJob: true, disabled: true });
      }
    });
  }

  handleJobChange({ value }) {
    this.setState({ job: value, disabled: false });
  }

  handleAddJob() {
    const { job, email, careTeam } = this.state;
    fetchCareTeamMember(email).then((res, err) => {
      if (res && res.data && res.data.results.length) {
        // care team member already exists, so we skip choosing their job and display their full name in the UI
        this.addExistingCareTeamMember(res.data.results[0]);
      } else {
        const newCareTeamMember = { email, job };
        this.showFleetingMessage("Invite sent");
        // the map here is to clone the objects, since you dont want to pass any old refs when setting state...
        const updatedCareTeam = careTeam
          .map((member) => ({ ...member }))
          .concat(newCareTeamMember);

        this.setState({
          careTeam: updatedCareTeam,
          isSelectingJob: false,
          isInputtingEmail: false,
          email: "",
          job: "",
          disabled: true,
        });
        this.props.input.onChange(updatedCareTeam);
      }
    });
  }

  cancel() {
    this.setState({
      email: "",
      job: "",
      isInputtingEmail: false,
      isSelectingJob: false,
      disabled: true,
    });
  }

  deleteCareTeamMemberAssociation(memberToBeDeleted) {
    const { careTeam } = this.state;
    const updatedCareTeam = careTeam.filter(
      (member) => member.email !== memberToBeDeleted.email
    );

    this.setState({ careTeam: updatedCareTeam });
    this.props.input.onChange(updatedCareTeam);
  }

  renderCareTeamList() {
    const { careTeam } = this.state;

    if (careTeam.length === 0) return null;

    const labelsForValues = CARE_TEAM_JOB_OPTIONS.reduce(
      (accm, current) => ({ ...accm, [current.value]: current.label }),
      {}
    );

    return (
      <div className="added-members">
        {careTeam.map((member) => {
          const jobLabelForValue = labelsForValues[member.job];

          return (
            <div key={member.email} className="added-member">
              <p className="added-member-job">{jobLabelForValue}</p>
              <p className="added-member-id">
                {member.fullname || member.email}
              </p>
              <div
                role="button"
                onClick={() => this.deleteCareTeamMemberAssociation(member)}
                className="delete-care-team-member"
              >
                <Icon name="times" />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderAddCareTeamMemberButton() {
    const { careTeam, isInputtingEmail, isSelectingJob, careTeamMessage } =
      this.state;

    if (isInputtingEmail || isSelectingJob || careTeamMessage) return null;

    return (
      <button
        className={classNames("add-care-team-member-button", {
          hasAddedMembers: careTeam.length > 0,
        })}
        onClick={() => this.setState({ isInputtingEmail: true })}
      >
        Add Care Team Member
      </button>
    );
  }

  renderEmailInput() {
    const {
      careTeam,
      isInputtingEmail,
      isSelectingJob,
      email,
      disabled,
      careTeamMessage,
    } = this.state;

    if (!isInputtingEmail && !careTeamMessage) return null;

    if (careTeamMessage) return <SuccessAnimation message={careTeamMessage} />;

    return (
      <div
        className={classNames("input-email-group", {
          hasAddedMembers: careTeam.length > 0,
        })}
      >
        <input
          className="form-control care-team-email-input"
          value={email}
          onChange={this.handleEmailChange}
          placeholder="Choose email"
          ref={(node) => {
            this.emailRef = node;
          }}
        />

        {!isSelectingJob && (
          <div className="button-container">
            <button
              className="care-team-cancel-button"
              type="button"
              onClick={this.cancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className={classNames("care-team-email-button", { disabled })}
              onClick={this.handleAddEmail}
              disabled={disabled}
            >
              Add
            </button>
          </div>
        )}
      </div>
    );
  }

  renderJobSelect() {
    const { isSelectingJob, job, disabled } = this.state;

    if (!isSelectingJob) return null;

    return (
      <div className="job-select-group placeholder-select-field">
        <Select
          className="care-team-job-select"
          options={CARE_TEAM_JOB_OPTIONS}
          value={job}
          placeholder="Choose Job"
          onChange={this.handleJobChange}
          clearable={false}
          autoFocus
        />
        <div className="button-container">
          <button
            className="care-team-cancel-button"
            type="button"
            onClick={this.cancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className={classNames("care-team-job-button", { disabled })}
            onClick={this.handleAddJob}
            disabled={disabled}
          >
            Save
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="care-team-section">
        {this.renderCareTeamList()}
        {this.renderAddCareTeamMemberButton()}
        {this.renderEmailInput()}
        {this.renderJobSelect()}
      </div>
    );
  }
}

CareTeamFormSection.propTypes = {
  // redux-form
  meta: PropTypes.object,
  input: PropTypes.object,
};

CareTeamFormSection.defaultProps = {};

export default CareTeamFormSection;
