import moment from "moment";

export const date = (tick) => {
  const asInt = parseInt(tick, 10);
  const valueToFormat = asInt == tick ? asInt : tick; // eslint-disable-line
  return moment(valueToFormat).format("MM/DD/YYYY");
};

export const percentage = (tick) => `${Math.round(tick * 100)}%`;

export const round =
  (decimals = 0) =>
  (tick) =>
    tick.toFixed(decimals);
