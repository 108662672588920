import { all, takeLatest, put } from "redux-saga/effects";
import { push } from "redux-little-router";
import externalServiceSaga from "./components/ExternalService/sagas";
import actionSaga from "./components/Actions/sagas";
import { didFetchInvite } from "./actions";

function* handleDidFetchInvite(action) {
  const invite = action.payload;
  if (invite.peer_id) {
    // If the user is on an invite page but there is already a peer, redirect to that page instead
    yield put(push(`/peers/view/${invite.peer_id}`));
  }
}

function* watchInviteSaga() {
  yield all([
    externalServiceSaga(),
    actionSaga(),
    takeLatest(didFetchInvite, handleDidFetchInvite),
  ]);
}

export default watchInviteSaga;
