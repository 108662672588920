import { put, all, takeLatest } from "redux-saga/effects";

import { callAPI } from "../../../shared/utils/sagas";

import {
  setFleetingMessage,
  submitFormToAPI,
} from "../../../shared/utils/form";

import {
  requestCustomers,
  didFetchCustomers,
  requestPrograms,
  didFetchPrograms,
  requestClinician,
  didFetchClinician,
  createClinician,
  didCreateClinician,
  updateClinician,
  didUpdateClinician,
} from "./actions";
import {
  fetchCustomers as fetchCustomersAPI,
  fetchPrograms as fetchProgramsAPI,
  fetchClinician as fetchClinicianAPI,
  createClinician as createClinicianAPI,
  updateClinician as updateClinicianAPI,
} from "./api";

// export const submitFormToAPI = createAction('SUBMIT_FORM_TO_API', (formId, APIToCall, successAction, ...APIArgs) => ({ formId, APIToCall, APIArgs, successAction }))

function* submitClinicianPatch(action) {
  const { clinicianId, emails, name, npi, programs } = action.payload;
  yield put(
    submitFormToAPI("manageClinician", updateClinicianAPI, didUpdateClinician, {
      clinicianId,
      emails,
      name,
      npi,
      programs,
    })
  );
}

export default function* watchManageClinician() {
  yield all([
    takeLatest(requestCustomers, callAPI(fetchCustomersAPI, didFetchCustomers)),
    takeLatest(requestPrograms, callAPI(fetchProgramsAPI, didFetchPrograms)),
    takeLatest(requestClinician, callAPI(fetchClinicianAPI, didFetchClinician)),
    takeLatest(
      createClinician,
      callAPI(createClinicianAPI, didCreateClinician)
    ),
    takeLatest(updateClinician, submitClinicianPatch),
    takeLatest(
      didCreateClinician,
      setFleetingMessage(
        "manageClinician",
        { message: "Clinician successfully created!" },
        2000
      )
    ),
    takeLatest(
      didUpdateClinician,
      setFleetingMessage(
        "manageClinician",
        { message: "Clinician successfully updated!" },
        2000
      )
    ),
  ]);
}
