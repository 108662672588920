import { connect } from "react-redux";
import { fleetingFormMeta } from "../../../../../../../../shared/utils/form";
import { fetchAvailablePrograms, transitionPeer } from "./actions";
import { getPeerAvailableParts } from "./reducer";
import TransitionProgram from "./TransitionProgram.jsx";

function mapStateToProps(state, { userId }) {
  return {
    message: fleetingFormMeta(state, "transitionProgram").message,
    availableParts: getPeerAvailableParts(state, userId),
  };
}

function mapDispatchToProps(dispatch, { userId }) {
  return {
    onSubmit: (values) => dispatch(transitionPeer(values, userId)),
    fetchAvailablePrograms: (customerId, userId) =>
      dispatch(fetchAvailablePrograms(customerId, userId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransitionProgram);
