import { connect } from "react-redux";
import { get } from "lodash";

import Preview from "./../../../Preview";

import { cardContent } from "../../../../reducer";

const currentFieldValue = (state, field) =>
  get(state, `form.card.values.${field}`);
const currentContentValue = (state) => currentFieldValue(state, "content");

function mapStateToProps(state, { card }) {
  return {
    content: currentContentValue(state) || cardContent(state, card.id),
  };
}

export default connect(mapStateToProps)(Preview);
