import classNames from "classnames";
import { useField } from "formik";
import React from "react";
import "./FormikTextField.css";

const FormikTextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { error, pristine, touched } = meta;
  const {
    className,
    errorOnBlur,
    id,
    name,
    placeholder,
    required,
    description,
  } = props;

  const shouldDisplayError = errorOnBlur
    ? !pristine && touched
    : !pristine || touched;

  const labelClass = classNames({
    "label-has-error": shouldDisplayError && error,
  });

  const fieldClass = classNames(
    "form-control",
    { "has-error": shouldDisplayError && error },
    className
  );

  return (
    <div className="form-group">
      <div className="form-text-column">
        {!placeholder && (
          <label className={labelClass} htmlFor={id || name}>
            {label}
            {required ? " *" : null}
          </label>
        )}
        {description ? <span>{description}</span> : ""}
      </div>

      <input
        className={fieldClass}
        {...field}
        {...props}
        data-test={field.name}
      />
      {touched && error ? <div className="error-message">{error}</div> : null}
    </div>
  );
};

export default FormikTextField;
