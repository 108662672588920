import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { validateForm } from "../../../../../../../shared/utils/form";

import DrillDownSelectField from "./../../../../../../../shared/components/FormFields/DrillDownSelectField";
import SuccessAnimation from "./../../../../../../../shared/components/SuccessAnimation";
import { fetchOwners } from "../../../../../Peer/View/components/Actions/components/UpdateOwner/api";

import "./UpdateOwner.css";

const rules = {
  owner: {
    presence: true,
  },
};

class UpdateOwner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      owners: [],
    };
  }

  componentDidMount() {
    fetchOwners(this.props.customerId).then((res) => {
      if (res) {
        this.setState({ owners: res.data });
      }
    });
  }

  formatUsersForDropDown(users) {
    return users
      .map((user) => ({
        label: user.fullname || user.email,
        value: user,
        key: user.email,
      }))
      .filter((u) => u.label)
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  render() {
    let { owners, disabled } = this.state;
    let {
      authenticatedUserType,
      message,
      customerId,
      userId,
      onSubmit,
      handleSubmit,
      redirectToHome,
    } = this.props;
    if (message && authenticatedUserType === "owner") {
      redirectToHome();
    }
    return (
      <div className="update-owner">
        <h1>Change owner</h1>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <Field
            className="owner-dropdown"
            name="owner"
            component={DrillDownSelectField}
            values={this.formatUsersForDropDown(this.state.owners)}
            initialLabel="Select owner"
          />
          {message ? (
            <SuccessAnimation message={message} />
          ) : (
            <input
              type="submit"
              className="btn btn-primary"
              value="Change owner"
              disabled={disabled}
            />
          )}
        </form>
      </div>
    );
  }
}

UpdateOwner.propTypes = {
  authenticatedUserType: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  message: PropTypes.string,

  // Automatically added
  redirectToHome: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

UpdateOwner.defaultProps = {
  message: null,
};

export default reduxForm({
  form: "updateOwner",
  validate: validateForm(rules),
})(UpdateOwner);
