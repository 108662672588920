import { createAction } from "redux-actions";

const prefixed = (val) => `Programs/Edit/ProgramsForm::${val}`;

export const didCreateProgram = createAction(
  prefixed("DID_CREATE_PROGRAM", (programId, values) => ({
    programId,
    values,
  }))
);
export const didUpdateProgram = createAction(
  prefixed("DID_UPDATE_PROGRAM", (programId, values) => ({
    programId,
    values,
  }))
);

export const simulateProgram = createAction(
  prefixed("SIMULATE_PROGRAM"),
  (programId, values) => ({ programId, values })
);
export const didSimulateProgram = createAction(
  prefixed("DID_SIMULATE_PROGRAM"),
  (simulation, programId) => ({ simulation, programId })
);
