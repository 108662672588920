import { connect } from "react-redux";
import { push } from "redux-little-router";

import { updateQuery, query } from "../../shared/routes/query";
import { showPanel } from "../../shared/components/ModalPanel/actions";
import {
  userId,
  userType,
} from "../../shared/routes/Authentication/Login/reducer";
import { shouldDisplayLoadingIndicator } from "../../shared/components/LoadingIndicator/reducer";

import {
  requestResults,
  requestExternalFields,
  requestGroups,
  requestFilters,
  requestPartnerUsers,
  requestInvite,
} from "./actions";
import {
  searchResults,
  externalFields,
  count,
  groups,
  defaultFilters,
  patientColumns,
  customerId,
  externalFieldsToSearchOn,
} from "./reducer";

import Patients from "./Patients.jsx";

function mapStateToProps(state) {
  return {
    searchResults: searchResults(state),
    externalFields: externalFields(state),
    count: count(state),
    groups: groups(state),
    defaultFilters: defaultFilters(state),
    groupType: query(state, "groupType"),
    groupValue: query(state, "groupValue"),
    keyword: query(state, "keyword"),
    filter: query(state, "events"),
    page: parseInt(query(state, "page"), 10) || 0,
    sortBy: query(state, "sortBy"),
    sortDirection: query(state, "sortDirection"),
    columns: patientColumns(state),
    userId: userId(state),
    userType: userType(state),
    customerId: customerId(state),
    fieldsToSearchOn: externalFieldsToSearchOn(state),
    noQuery: Object.keys(query(state)).length === 0,
    isLoading: shouldDisplayLoadingIndicator(state),
  };
}

function searchIfNeeded(
  groupType,
  groupValue,
  filter,
  keyword,
  sortBy,
  sortDirection,
  page,
  dispatch
) {
  if (!keyword || keyword.length === 0) {
    return dispatch(
      requestResults(
        groupType,
        groupValue,
        filter,
        "",
        sortBy,
        sortDirection,
        page
      )
    ); // Empty string, else it gets sent as null, which the server parses as null string
  } else if (!keyword || keyword.length <= 2) {
    // No need to search on 2 letter keywords
    return null;
  }

  return dispatch(
    requestResults(
      groupType,
      groupValue,
      filter,
      keyword,
      sortBy,
      sortDirection,
      page
    )
  );
}

function mapDispatchToProps(dispatch) {
  return {
    requestResults: (
      groupType,
      groupValue,
      filter,
      keyword,
      sortBy,
      sortDirection,
      page
    ) =>
      searchIfNeeded(
        groupType,
        groupValue,
        filter,
        keyword,
        sortBy,
        sortDirection,
        page,
        dispatch
      ),
    requestExternalFields: () => dispatch(requestExternalFields()),
    requestGroups: () => dispatch(requestGroups()),
    requestFilters: (groupType, groupValue) =>
      dispatch(requestFilters(groupType, groupValue)),
    requestPartnerUsers: (id) => dispatch(requestPartnerUsers(id)),
    requestInvite: () => dispatch(requestInvite()),
    onQueryChange: (options) => dispatch(updateQuery(options)),
    onSortChange: (sortBy, sortDirection) =>
      dispatch(updateQuery({ sortDirection, sortBy, page: 0 })),
    showPatientInviteForm: () => dispatch(showPanel("patientInvite")),
    goToPatientDetails: (href) => dispatch(push(href)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
