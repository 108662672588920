import axios from "axios";

export const fetchCareTeamMember = (email) =>
  axios.get(
    `/api/admin/v1/care_team_users?keyword=${encodeURIComponent(email)}`
  );

export const addCareTeamMemberForPeer = (member) =>
  axios.post("/api/admin/v1/care_team_users", member);
export const updateCareTeamForPeer = (peerId, ids) =>
  axios.post(`/api/admin/v1/patients/${peerId}/care_team`, ids);
