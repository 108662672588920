import { createAction } from "redux-actions";

const prefixed = (val) => `ErrorIndicator::${val}`;

export const showError = createAction(
  prefixed("SHOW_ERROR"),
  (error = null, show = true) => ({ error, show })
);
export const hideError = createAction(
  prefixed("HIDE_ERROR"),
  (error = null, show = false) => ({ error, show })
);
