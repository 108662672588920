// Just a hacky way to get my bugfix for this lib in there, scroll a bit down to see
// Also added prop 'labelValue' so we can have a different value in the label than which is used to draw the arc

// "use strict";

Object.defineProperty(exports, "__esModule", {
  value: true,
});

var _templateObject = _taggedTemplateLiteral(
  [
    "\n  display: block;\n  margin: 1em auto;\n  width: 100%;\n  height: 6em;\n\n  circle {\n    fill: none;\n    stroke-width: 20;\n    transform: translate(100px, 100px) rotate(-89.9deg);\n    \n  }\n\n  .arc-background {\n    stroke: ",
    ";\n  }\n\n  .arc {\n    stroke: ",
    ";\n    stroke-linecap: ",
    ";\n  }\n\n  text {\n    fill: ",
    ";\n    font-size: 50px;\n    font-weight: 600;\n    text-anchor: middle;\n  }\n",
  ],
  [
    "\n  display: block;\n  margin: 1em auto;\n  width: 100%;\n  height: 6em;\n\n  circle {\n    fill: none;\n    stroke-width: 20;\n    transform: translate(100px, 100px) rotate(-89.9deg);\n   }\n\n  .arc-background {\n    stroke: ",
    ";\n  }\n\n  .arc {\n    stroke: ",
    ";\n    stroke-linecap: ",
    ";\n  }\n\n  text {\n    fill: ",
    ";\n    font-size: 50px;\n    font-weight: 600;\n    text-anchor: middle;\n  }\n",
  ]
);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledComponents = require("styled-components");

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _taggedTemplateLiteral(strings, raw) {
  return Object.freeze(
    Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })
  );
}

var Svg = _styledComponents2.default.svg(
  _templateObject,
  function (props) {
    return props.arcBackgroundColor;
  },
  function (props) {
    return props.arcColor;
  },
  function (props) {
    return props.rounded ? "round" : "inherit";
  },
  function (props) {
    return props.textColor;
  }
);

function ProgressArc(_ref) {
  var value = _ref.value,
    max = _ref.max,
    className = _ref.className,
    unit = _ref.unit,
    arcColor = _ref.arcColor,
    arcBackgroundColor = _ref.arcBackgroundColor,
    textColor = _ref.textColor,
    textVisible = _ref.textVisible,
    radius = _ref.radius,
    rounded = _ref.rounded,
    dominantBaseline = _ref.dominantBaseline,
    labelValue = _ref.labelValue;

  var p = 2 * radius * Math.PI;

  // Simple IE11 fix by matthijn, there's probably a better way but works for now
  // Fixed a render bug where the circle was not fully visible in IE11
  var IEFix = null;
  if (window.navigator.userAgent.toLowerCase().indexOf("trident") !== -1) {
    IEFix = 100;
  }

  const theLabel = labelValue !== null ? labelValue : value;

  return _react2.default.createElement(
    Svg,
    {
      className: className,
      arcColor: arcColor,
      arcBackgroundColor: arcBackgroundColor,
      textColor: textColor,
      rounded: rounded,
      viewBox: "0 0 200 200",
    },
    _react2.default.createElement("circle", {
      className: "arc-background",
      r: radius,
      cx: IEFix,
      cy: IEFix,
    }),
    _react2.default.createElement("circle", {
      className: "arc",
      r: radius,
      strokeDashoffset: ((max - value) / max) * p,
      strokeDasharray: p,
      cx: IEFix,
      cy: IEFix,
    }),
    textVisible &&
      _react2.default.createElement(
        "text",
        {
          x: "100",
          y: "100",
          dominantBaseline: dominantBaseline,
        },
        "" + theLabel + unit
      )
  );
}

ProgressArc.propTypes = {
  value: _propTypes2.default.number.isRequired,
  max: _propTypes2.default.number,
  unit: _propTypes2.default.string,
  arcColor: _propTypes2.default.string,
  arcBackgroundColor: _propTypes2.default.string,
  textColor: _propTypes2.default.string,
  textVisible: _propTypes2.default.bool,
  radius: _propTypes2.default.number,
  rounded: _propTypes2.default.bool,
  labelValue: _propTypes2.default.any,
};

ProgressArc.defaultProps = {
  value: 0,
  max: 100,
  unit: "%",
  arcColor: "#818a91",
  arcBackgroundColor: "#eceeef",
  textColor: "#818a91",
  textVisible: true,
  radius: 90,
  rounded: false,
  dominantBaseline: "middle",
};

exports.default = ProgressArc;
