import { connect } from "react-redux";

import UpdateBackground from "./UpdateBackground.jsx";
import { fleetingFormMeta } from "../../../../../../../shared/utils/form";
import { hidePanel } from "../../../../../../../shared/components/ModalPanel/actions";
import { updateBackground, requestBackgroundForm } from "./actions";
import { formData, comorbidities } from "./reducer";

function mapStateToProps(state, { intakeData }) {
  const newIntake = Object.assign({}, intakeData);
  newIntake.health_issues = comorbidities(
    state,
    intakeData.health_issues || {}
  );
  return {
    message: fleetingFormMeta(state, "updateBackground").message,
    formData: formData(state),
    initialValues: { intake_data: newIntake },
  };
}

function mapDispatchToProps(dispatch, { userId, intakeData }) {
  return {
    onSubmit: (formValues) =>
      dispatch(updateBackground(userId, formValues, intakeData.health_issues)),
    requestBackgroundForm: () => dispatch(requestBackgroundForm(userId)),
    onCancel: () => dispatch(hidePanel("updateBackground")),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBackground);
