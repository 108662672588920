import { connect } from "react-redux";
import { getFormValues, isInvalid } from "redux-form";
import Register from "./Register.jsx";
import { requestRegistrantEmail, requestRegistration } from "./actions";
import { registrantEmail, registrationToken, userableType } from "./reducer";

function allRequiredFieldsFilledOut(state) {
  const registrantUserType = userableType(state);
  const formState = getFormValues("registerAccount")(state) || {};
  const { fullname, password, repeat, secret } = formState;
  if (registrantUserType === "care_team_user") {
    return !!password && !!repeat && !!secret && fullname;
  }
  return !!password && !!repeat && !!secret;
}

function mapStateToProps(state) {
  const secret = registrationToken(state);

  return {
    disabled:
      !allRequiredFieldsFilledOut(state) || isInvalid("registerAccount")(state),
    secret,
    registrantEmail: registrantEmail(state),
    registrantUserType: userableType(state),
    initialValues: {
      secret,
    },
  };
}

const dispatch = {
  requestRegistrantEmail: (secret) => requestRegistrantEmail(secret),
  onSubmit: (values) => requestRegistration(values),
};

export default connect(mapStateToProps, dispatch)(Register);
