import { all, takeLatest, call, put, select } from "redux-saga/effects";

import { callAPI } from "../../shared/utils/sagas";
import { setFleetingMessage, submitFormToAPI } from "../../shared/utils/form";
import { updateQuery } from "../../shared/routes/query";

import { userType } from "../../shared/routes/Authentication/Login/reducer";
import { ALL_PATIENTS_FILTER } from "./Patients.jsx";

import {
  requestResults,
  didFetchResults,
  requestExternalFields,
  didFetchExternalFields,
  requestGroups,
  didFetchGroups,
  requestFilters,
  didFetchFilters,
  requestPartnerUsers,
  requestPartnerUsersAdmin,
  didFetchPartnerUsers,
  requestInvite,
  requestInviteAdmin,
  didFetchInvite,
  requestSendInvite,
  didSendInvite,
  requestCustomers,
  didFetchCustomers,
} from "./actions";
import {
  fetchPatientResults as fetchResultsAPI,
  fetchExternalFields as fetchExternalFieldsAPI,
  fetchGroups as fetchGroupsAPI,
  fetchFilters as fetchFiltersAPI,
  fetchAllPartnerUsers as fetchAllPartnerUsersAPI,
  createCareTeamMember as createCareTeamMemberAPI,
  fetchInvite as fetchInviteAPI,
  fetchInviteAdmin as fetchInviteAdminAPI,
  postInvite as postInviteAPI,
  fetchCustomers as fetchCustomersAPI,
} from "./api";

function* getUpdatedResultsAfterInvite() {
  const user = yield select(userType);

  let newQuery = {
    page: 0,
    sortBy: "created_at",
    sortDirection: "desc",
    events: ALL_PATIENTS_FILTER,
    keyword: "",
  };
  if (user === "partner_admin") {
    const partnerAdminQueryOptions = {
      groupType: "all",
      groupValue: undefined,
    };
    newQuery = { ...newQuery, ...partnerAdminQueryOptions };
  }

  const {
    groupType,
    groupValue,
    events,
    keyword,
    sortBy,
    sortDirection,
    page,
  } = newQuery;

  yield put(updateQuery(newQuery));
  yield put(
    requestResults(
      groupType,
      groupValue,
      events,
      keyword,
      sortBy,
      sortDirection,
      page
    )
  );
  yield put(requestFilters());
}

// the filled out invite form sends an array of care team members. some may already exist and some might not
// if some don't, we have to create them on the fly to attach to the invite before actually sending out the invite
function* createCareTeamMembersIfNeeded(careTeam) {
  const careTeamIds = [];

  for (let i = 0; i < careTeam.length; i++) {
    const careTeamMember = careTeam[i];
    // if the id doesn't exist, then it means we weren't able to fetch them when the user was filling out the form, and so need to create them first in order to get their id
    if (!careTeamMember.id) {
      const response = yield call(createCareTeamMemberAPI, careTeamMember);
      careTeamIds.push(response.data.id);
    } else {
      // we were able to fetch them when the user was filling out the care team form section, so we just add that id to the array
      careTeamIds.push(careTeamMember.id);
    }
  }
  return careTeamIds;
}

function* postInvite(action) {
  const values = action.payload;

  const { care_team } = values;

  let careTeamIds;
  if (care_team && care_team.length > 0) {
    careTeamIds = yield call(createCareTeamMembersIfNeeded, care_team);
    values.care_team = careTeamIds;
  }

  // Endpoint expects the program section of the data be at top level
  const {
    consent,
    email_address,
    mobile_number,
    external_service,
    language,
    peer_fullname,
    state,
    surgery_date,
    program: {
      registration_code,
      aggregate_program,
      clinician_id,
      phase_id,
      phase_num,
    },
  } = action.payload;

  const formData = {
    consent,
    email_address,
    mobile_number,
    external_service,
    language,
    peer_fullname,
    state,
    surgery_date,
    registration_code,
    aggregate_program,
    clinician_id,
    phase_id,
    phase_num,
  };

  yield put(
    submitFormToAPI(
      "patientInvite",
      postInviteAPI,
      didSendInvite(formData),
      formData
    )
  );
}

export default function* watchPatients() {
  yield all([
    takeLatest(requestResults, callAPI(fetchResultsAPI, didFetchResults)),
    takeLatest(
      requestExternalFields,
      callAPI(fetchExternalFieldsAPI, didFetchExternalFields)
    ),
    takeLatest(requestGroups, callAPI(fetchGroupsAPI, didFetchGroups)),
    takeLatest(requestFilters, callAPI(fetchFiltersAPI, didFetchFilters)),
    takeLatest(
      requestPartnerUsers,
      callAPI(fetchAllPartnerUsersAPI, didFetchPartnerUsers)
    ),
    takeLatest(
      requestPartnerUsersAdmin,
      callAPI(fetchAllPartnerUsersAPI, didFetchPartnerUsers, false, false)
    ),
    takeLatest(
      requestExternalFields,
      callAPI(fetchExternalFieldsAPI, didFetchExternalFields)
    ),
    takeLatest(requestInvite, callAPI(fetchInviteAPI, didFetchInvite)),
    takeLatest(
      requestInviteAdmin,
      callAPI(fetchInviteAdminAPI, didFetchInvite, false, false)
    ),
    takeLatest(requestSendInvite, postInvite),
    takeLatest(
      requestCustomers,
      callAPI(fetchCustomersAPI, didFetchCustomers, false, false)
    ),
    takeLatest(
      didSendInvite,
      setFleetingMessage("patientInvite", { message: "Invite sent!" }, 3000)
    ),
    takeLatest(didSendInvite, getUpdatedResultsAfterInvite),
  ]);
}
