import { handleActions } from "redux-actions";
import { get } from "lodash";
import reduceReducers from "reduce-reducers";

import { didFetchPeers } from "./sagas";

import searchReducer from "./components/Search/reducer";
import filterReducer from "./components/Filter/reducer";

export const peers = (state) => get(state, "ui.PeersList.peers");
export const totalNumberOfPeers = (state) =>
  get(state, "ui.PeersList.totalNumber");

const reducer = handleActions(
  {
    [didFetchPeers]: (state, action) => ({
      ...state,
      peers: action.payload.peers,
      totalNumber: action.payload.total_peers,
    }),
  },
  {
    peers: null,
    totalNumber: 0,
  }
);

export default reduceReducers(reducer, searchReducer, filterReducer);
