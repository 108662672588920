import { connect } from "react-redux";

import { showPanel } from "../../../../../../shared/components/ModalPanel/actions";
import { userType } from "../../../../../../shared/routes/Authentication/Login/reducer";

import Actions from "./Actions.jsx";

function mapStateToProps(state) {
  return {
    authenticatedUserType: userType(state),
  };
}

const dispatch = {
  onDeletePatient: () => showPanel("deletePatient"),
};

export default connect(mapStateToProps, dispatch)(Actions);
