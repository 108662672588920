import watchRegistrationSaga from "./sagas";
import RegistrationReducer from "./reducer";
import { authenticationDenied } from "../Login/sagas";
import Register from "./Register";

export default {
  "/activate/:secret": {
    component: Register,
    reducer: {
      ui: {
        Register: RegistrationReducer,
      },
    },
    saga: watchRegistrationSaga,
    onEnter: authenticationDenied,
  },
};
