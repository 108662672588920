import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../shared/app/requestErrorHandler";
import { callAPI } from "../../shared/utils/sagas";
import {
  didFetchPhase,
  didFetchPhasesList,
  fetchPhase,
  fetchPhasesList,
} from "./actions";
import {
  fetchPhase as fetchPhaseAPI,
  fetchPhasesList as fetchPhasesListAPI,
} from "./api";
import watchBottomBarSaga from "./components/BottomBar/saga";
import watchPhasesFormSaga from "./components/PhasesForm/saga";
import watchPhasesListSaga from "./components/PhasesList/saga";

function* fetchPhasesListSaga(action) {
  try {
    const response = yield fetchPhasesListAPI(action.payload);
    const count = response.headers["x-total-count"];
    yield put(didFetchPhasesList(response.data, count));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* watchPhasesSaga() {
  yield all([
    takeLatest(fetchPhase, callAPI(fetchPhaseAPI, didFetchPhase)),
    takeLatest(fetchPhasesList, fetchPhasesListSaga),
  ]);
}

export default function* () {
  yield all([
    watchPhasesSaga(),
    watchBottomBarSaga(),
    watchPhasesListSaga(),
    watchPhasesFormSaga(),
  ]);
}
