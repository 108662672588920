import React from "react";
import PropTypes from "prop-types";

import FilterList from "./components/FilterList";

import "./Filter.css";

class Filter extends React.Component {
  componentDidMount() {
    const { fetchFilters } = this.props;
    fetchFilters();
  }

  render() {
    return (
      <div className="filters">
        <FilterList type="registration_codes" />
        <FilterList type="customers" />
        <FilterList type="clinicians" />
      </div>
    );
  }
}

Filter.propTypes = {
  fetchFilters: PropTypes.func.isRequired,
};

export default Filter;
