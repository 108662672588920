import {
  authenticationOfTypeRequired,
  authenticationRequired,
} from "../../../shared/routes/Authentication/Login/sagas";

import ManageUsers from "./ManageUsers.js";
import { ManageUsersReducer } from "./reducer";
import watchManageUsers from "./sagas";

// TODO: flat routes for now, but refactor to nest it like so: /manage/customer
export default {
  "/manage-partner-users": {
    component: ManageUsers,
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired(["admin"]);
    },
    reducer: {
      ui: {
        ManageUsers: ManageUsersReducer,
      },
    },
    saga: watchManageUsers,
    title: "Manage Partner Users",
  },
};

export const emailRegexValidator =
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
