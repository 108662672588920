import React from "react";
import PropTypes from "prop-types";
import { compact } from "lodash";

import { Link } from "redux-little-router";

import Portal from "react-portal/es/PortalCompat";

import "./BreadCrumbBar.css";

import DropdownMenu from "./components/DropdownMenu";

class BreadCrumbBar extends React.Component {
  _renderDropdownMenuItem(menuItem) {
    return <DropdownMenu menuItem={menuItem} />;
  }

  _renderMenuItem(menuItem) {
    throw new Error("Unimplemented");
  }

  renderMenu(menuItems) {
    if (!menuItems) {
      return null;
    }

    return (
      <ul className="breadcrumb-menus">
        {menuItems.map((menuItem) =>
          menuItem.dropdown
            ? this._renderDropdownMenuItem(menuItem)
            : this._renderMenuItem(menuItem)
        )}
      </ul>
    );
  }

  renderPath(path) {
    const crumbItems = path.map((pathItem, index) => {
      const divider = index !== 0 ? <span> / </span> : null;

      if (pathItem.href) {
        let link = null;
        if (typeof pathItem.href === "string") {
          link = <Link href={pathItem.href}>{pathItem.label}</Link>;
        } else {
          link = <a onClick={pathItem.href}>{pathItem.label}</a>;
        }

        return (
          <span key={pathItem.label}>
            {divider}
            {link}
          </span>
        );
      }

      return (
        <span key={pathItem.label}>
          {divider}
          {pathItem.label}
        </span>
      );
    });

    return <div className="breadcrumb-path">{crumbItems}</div>;
  }

  render() {
    const { children, path, menuItems } = this.props;
    return (
      <Portal node={document && document.getElementById("breadCrumbBar")}>
        <div className="breadcrumb-bar">
          {path && this.renderPath(path)}
          {menuItems && this.renderMenu(compact(menuItems))}
          {children}
        </div>
      </Portal>
    );
  }
}

BreadCrumbBar.propTypes = {
  children: PropTypes.node,
  path: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ),
  menuItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      }),
      PropTypes.shape({
        selected: PropTypes.string,
        dropdown: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
              .isRequired,
          })
        ),
        // For now only one menu dropdown can be searchable on a given page...
        searchable: PropTypes.bool,
        name: PropTypes.string.isRequired,
      }),
    ])
  ),
};

export default BreadCrumbBar;
