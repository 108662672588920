import classNames from "classnames";
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import "./FormikSortableSelectField.css";
import { arrayMove, getItemsByIds } from "../../../utils/helpers";

const FormikSortableSelectField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { error, touched, warning } = meta;
  const { className, id, name, options = [], required } = props;
  const shouldDisplayError = touched && error;

  const fieldClass = classNames(
    { "has-error": shouldDisplayError && error },
    className
  );

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!field.value || !options) return;
    if (items.length === 0) setItems(getItemsByIds(field.value, options));
  }, [field.value, options]);

  function persistValues(values) {
    field.onChange({ target: { ...field, value: values } });
    setItems(values);
  }

  function handleChange(value) {
    persistValues([...items, value]);
  }

  function hanldeItemRemoveClick(index) {
    let newArray = [...items];
    newArray.splice(index, 1);
    persistValues(newArray);
  }

  function handleSortEnd({ oldIndex, newIndex }) {
    persistValues(arrayMove(items, oldIndex, newIndex));
  }

  const SortableItem = SortableElement(
    ({ item, onItemRemoveClick, index } = props) => (
      <div className="formik-sortable-field" key={item.key}>
        <span className="item-grab-icon"> </span>
        <span className="item-label">{item.label}</span>
        <span className="item-clear" onClick={onItemRemoveClick}>
          ×
        </span>
      </div>
    )
  );

  const SortableList = SortableContainer(({ items, onItemRemoveClick }) => (
    <div className="formik-sortable-field-container">
      {items &&
        items.map((item = {}, index) => (
          <SortableItem
            key={Math.random()}
            index={index}
            item={item}
            onItemRemoveClick={() => onItemRemoveClick(index)}
          />
        ))}
    </div>
  ));

  return (
    <div className="formik-sortable" data-test={name}>
      <label htmlFor={id || name}>
        {label}
        {required ? " *" : null}
      </label>

      <Select
        {...props}
        className={fieldClass}
        joinValues
        onChange={handleChange}
        options={options}
      />

      {items && items.length > 0 && (
        <SortableList
          distance={1}
          items={items}
          onItemRemoveClick={hanldeItemRemoveClick}
          onSortEnd={handleSortEnd}
        />
      )}

      {shouldDisplayError &&
        ((error && <span className="error-message">{error}</span>) ||
          (warning && <span className="warning-message">{warning}</span>))}
    </div>
  );
};

export default FormikSortableSelectField;
