import { connect } from "react-redux";

import { updateQuery, clearQuery, query } from "../../../shared/routes/query";
import { fleetingFormMeta } from "../../../shared/utils/form";

import {
  requestCustomers,
  createUser,
  clearUserData,
  uploadUsers,
  requestAllUsers,
  updateUser,
  requestGroups,
} from "./actions";
import {
  allCustomers,
  selectedCustomer,
  allUsers,
  selectedUser,
  groups,
} from "./reducer";

import ManageUsers from "./ManageUsers.jsx";

function mapStateToProps(state) {
  return {
    customerList: allCustomers(state),
    customer: selectedCustomer(state),
    userList: allUsers(state),
    groups: groups(state),
    customerId: query(state, "customerId"),
    user: selectedUser(state),
    userId: query(state, "userId"),
    message:
      fleetingFormMeta(state, "createUsers").message ||
      fleetingFormMeta(state, "uploadUsers").message ||
      fleetingFormMeta(state, "updateUsers").message,
  };
}

const dispatch = {
  requestCustomers: () => requestCustomers(),
  requestAllUsers: (customerId) => requestAllUsers(customerId),
  requestGroups: (customerId) => requestGroups(customerId),
  createUser: (values) => createUser(values),
  uploadUsers: (customerId, csv) => uploadUsers(customerId, csv),
  updateUser: (values) => updateUser(values),
  selectCustomer: (customerId) => updateQuery({ customerId }),
  deselectCustomer: () => clearQuery(),
  selectUser: (userId) => updateQuery({ userId }),
  deselectUser: () => updateQuery({ userId: undefined }),
  clearUserData: () => clearUserData(),
};

export default connect(mapStateToProps, dispatch)(ManageUsers);
