import { handleActions } from "redux-actions";
import { onDidShowDeletedChanged } from "./actions";

export const programsList = (state) => state.ui.Programs.list || [];
export const showDeleted = (state) => state.ui.Programs.showDeleted || false;
export const programsCount = (state) => Number(state.ui.Programs.count || 0);

export default handleActions(
  {
    [onDidShowDeletedChanged]: (state, action) => ({
      ...state,
      showDeleted: action.payload,
    }),
  },
  {
    showDeleted: false,
  }
);
