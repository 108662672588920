import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "redux-little-router";

import "./HomeAssessment.css";

const dateFormat = "MM/DD/YYYY";

class HomeAssessment extends React.Component {
  render() {
    const { hazardScore, hazardPictures, hazardImages, user } = this.props;
    if (!hazardScore) return null;
    return (
      <div className="home-assessment">
        <h1>Home assessment</h1>
        <div className="box home-score">
          <div className="numberCircle">{hazardScore[0]}</div>
          <div className="">
            <h2>
              Home Hazard Score -{" "}
              {moment
                .parseZone(hazardPictures[0][0].date_time)
                .utc()
                .format(dateFormat)}
            </h2>
            <h5>
              The HHS is on a scale from 0-10. 0 means no problems were detected
              and 10 means lots of problems were found
            </h5>
          </div>
        </div>
        <div className="row">
          {hazardPictures[0] &&
            hazardPictures[0].map((picture, ind) => {
              const showPicture =
                ind < 4 &&
                picture.file &&
                hazardImages &&
                hazardImages[picture.file];
              return (
                showPicture && (
                  <div className="column">
                    <img
                      src={window.URL.createObjectURL(
                        hazardImages[picture.file]
                      )}
                    />
                  </div>
                )
              );
            })}
        </div>
        <Link href={`/peers/view/${user.id}/hazardDetails`}>
          <btn className="btn btn-sm">See all</btn>
        </Link>
      </div>
    );
  }
}

HomeAssessment.propTypes = {
  hazardScore: PropTypes.array,
  hazardPictures: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.object,
};

HomeAssessment.defaultProps = {
  hazardScore: 0,
  hazardPictures: null,
};

export default HomeAssessment;
