import { connect } from "react-redux";
import { push } from "redux-little-router";
import { debounce } from "lodash";
import { userType } from "../../../../shared/routes/Authentication/Login/reducer";

// Not true redux, since it stores and reads from cookie, since we don't persist our redux data yet
import { getSidebarWidth, storeSidebarWidth } from "./storage";

import DetailsView from "./DetailsView.jsx";

function mapStateToProps(state) {
  return {
    sidebarWidth: getSidebarWidth(),
    authenticatedUserType: userType(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    goListPatients: () => dispatch(push("/patients")),
    goListWorkers: () => dispatch(push("/workers")),
    storeSidebarWidth: debounce(storeSidebarWidth, 200),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsView);
