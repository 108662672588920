import React from "react";
import Spinner from "react-spinkit";
import PropTypes from "prop-types";

function LoadingIndicator({ className, shouldDisplayLoadingIndicator }) {
  if (!shouldDisplayLoadingIndicator) {
    return null;
  }
  return (
    <Spinner name="cube-grid" color="rgba(0,0,0,0.4)" className={className} />
  );
}

LoadingIndicator.propTypes = {
  className: PropTypes.string,
  shouldDisplayLoadingIndicator: PropTypes.bool.isRequired,
};

LoadingIndicator.defaultProps = {
  className: null,
};

export default LoadingIndicator;
