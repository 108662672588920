import React from "react";
import PropTypes from "prop-types";
import { Link } from "redux-little-router";
import classNames from "classnames";
import { range } from "lodash";

import { objectToQuery } from "../../routes/query";
import "./Pagination.css";

function Pagination({
  itemCount,
  itemsPerPage = 15,
  baseUrl,
  currentPage,
  keepQuery,
  query,
  pageInQuery,
}) {
  const pageLink = (index, contents) => {
    const minPageArrows = (
      <div className="min-page-arrows">
        <span className="caret left" />
        <span className="caret left" />
      </div>
    );
    const prevPageArrow = <span className="caret left single-arrow" />;
    const nextPageArrow = <span className="caret right single-arrow" />;
    const maxPageArrows = (
      <div className="max-page-arrows">
        <span className="caret right" />
        <span className="caret right" />
      </div>
    );

    let key;
    switch (contents) {
      case "min-page-arrows":
        key = contents;
        contents = minPageArrows;
        break;
      case "prev-page-arrow":
        key = contents;
        contents = prevPageArrow;
        break;
      case "next-page-arrow":
        key = contents;
        contents = nextPageArrow;
        break;
      case "max-page-arrows":
        key = contents;
        contents = maxPageArrows;
        break;
      default:
        key = contents;
        contents = index + 1;
    }

    let urlWithPage;
    let href;

    // Determine the url, if the page should be in the path or in the query
    if (!pageInQuery) {
      urlWithPage = `${baseUrl}/${index}`;
      href = keepQuery ? `${urlWithPage}?${objectToQuery(query)}` : urlWithPage;
    } else {
      urlWithPage = `${baseUrl}`;
      if (keepQuery) {
        href = `${urlWithPage}?${objectToQuery(
          Object.assign({}, query, { page: index })
        )}`;
      } else {
        href = `${urlWithPage}?${objectToQuery({ page: index })}`;
      }
    }

    return (
      <li key={`page-${index}${key}`}>
        <Link
          href={href}
          className={classNames({
            active: index === parseInt(currentPage, 10),
          })}
        >
          {contents}
        </Link>
      </li>
    );
  };

  const numberOfPages = Math.ceil(itemCount / itemsPerPage);

  if (numberOfPages === 1) {
    return null;
  }

  const visibleNumberOfItems = 5;

  let listItems;

  // There are too few pages to add the "padding"
  if (numberOfPages <= visibleNumberOfItems) {
    listItems = range(numberOfPages).map((i) => pageLink(i));
  } else {
    const numberOfItemsPerSide = Math.floor(visibleNumberOfItems / 2);

    const start = Math.min(
      Math.max(0, currentPage - numberOfItemsPerSide),
      numberOfPages - visibleNumberOfItems
    );

    const end = Math.min(start + visibleNumberOfItems, numberOfPages);

    listItems = range(start, end).map((i) => pageLink(i));
  }

  return (
    <div className="pagination-container">
      <ul className="pagination">
        {pageLink(0, "min-page-arrows")}
        {pageLink(Math.max(currentPage - 1, 0), "prev-page-arrow")}

        {listItems}

        {pageLink(
          Math.min(currentPage + 1, numberOfPages - 1),
          "next-page-arrow"
        )}
        {pageLink(numberOfPages - 1, "max-page-arrows")}
      </ul>
    </div>
  );
}

Pagination.propTypes = {
  itemCount: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  baseUrl: PropTypes.string.isRequired,
  keepQuery: PropTypes.bool.isRequired,
  // When true, it will not put the page in the path but in the query
  pageInQuery: PropTypes.bool,

  // Added automatically
  query: PropTypes.object,
};

Pagination.defaultProps = {
  keepQuery: true,
  pageInQuery: false,
};

export default Pagination;
