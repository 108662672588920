import React from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";

export const metricQueryShape = {
  type: PropTypes.string.isRequired,
  frame: PropTypes.string.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  group: PropTypes.object,
};

// Component that will handle fetching metric information for you
// Pass in the metric information you through the proptypes, and it will pass the prop "data" to it's child containing the metrics, if data is not available it will render Loading...
// You can pass Chart components or Number Components as children so they will read the available data
class Metrics extends React.Component {
  // Passing the metric data to children that want it
  getChildContext() {
    return {
      metricData: this.props.data,
    };
  }

  componentDidMount() {
    this._fetchMetrics();
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.query, this.props.query)) {
      this._fetchMetrics();
    }
  }

  _fetchMetrics() {
    for (const requestQuery of this.props.query) {
      this.props.requestMetricData(requestQuery);
    }
  }

  render() {
    const { children, data } = this.props;
    if (!data) {
      return null;
    }

    return <div>{children}</div>;
  }
}

Metrics.propTypes = {
  query: PropTypes.arrayOf(PropTypes.shape(metricQueryShape)).isRequired,

  // These are supplied by redux-connect
  requestMetricData: PropTypes.func.isRequired,
  data: PropTypes.object,

  children: PropTypes.node,
};

Metrics.defaultProps = {
  data: null,
};

Metrics.childContextTypes = {
  metricData: PropTypes.object,
};

export default Metrics;
