import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import DateInputField from "./../DateInputField";

import "./FormGroupField.css";

function renderDateInputField(input, disabled, fieldClass, dateProps, ref) {
  return (
    <DateInputField
      {...input}
      className={fieldClass}
      withInput
      onDateChange={(v) => input.onChange(v)}
      {...dateProps}
      embedded
      id={input.name}
      disabled={disabled}
      ref={ref}
    />
  );
}

function renderInputField(input, disabled, fieldClass, type, ref, placeholder) {
  return (
    <input
      {...input}
      disabled={disabled}
      className={fieldClass}
      type={type}
      id={input.name}
      ref={ref}
      placeholder={placeholder}
    />
  );
}

function renderPhoneInputField(input, disabled, fieldClass, ref) {
  return (
    <input
      {...input}
      type="tel"
      onChange={(e) => {
        const v = e.target.value;
        const numbersOnly = v.replace(/\D/g, "");
        if (numbersOnly.length > 10) {
          return;
        }
        input.onChange(numbersOnly);
      }}
      disabled={disabled}
      className={`.phone-control ${fieldClass}`}
      ref={ref}
    />
  );
}

function FormGroupField({
  input,
  label,
  required,
  type,
  meta: { touched, error, warning, pristine, dirty },
  dateProps,
  disabled,
  className,
  inputRef,
  placeholder,
  country,
  errorOnBlur = false,
}) {
  // errorOnBlur will display error only when the field is not focused
  const shouldDisplayError = errorOnBlur
    ? !pristine && touched
    : !pristine || touched;

  const labelClass = classNames({
    "label-has-error": shouldDisplayError && error,
  });
  const fieldClass = classNames(
    "form-control",
    { "has-error": shouldDisplayError && error },
    className
  );
  let fieldToRender = null;
  switch (type) {
    case "date":
      fieldToRender = renderDateInputField(
        input,
        disabled,
        fieldClass,
        dateProps,
        inputRef
      );
      break;
    case "phone":
      fieldToRender = renderPhoneInputField(
        input,
        disabled,
        fieldClass,
        inputRef,
        country
      );
      break;
    default:
      fieldToRender = renderInputField(
        input,
        disabled,
        fieldClass,
        type,
        inputRef,
        placeholder
      );
  }

  return (
    <div className="form-group">
      <label className={labelClass} htmlFor={input.name}>
        {label}
        {required ? <span className="asterisk"> *</span> : null}
      </label>

      {fieldToRender}

      {shouldDisplayError &&
        ((error && <span className="error-message">{error}</span>) ||
          (warning && <span className="warning-message">{warning}</span>))}
    </div>
  );
}

FormGroupField.propTypes = {
  dateProps: PropTypes.object,
  // Also a bunch of redux field props
};

FormGroupField.defaultProps = {
  dateProps: {},
};

export default FormGroupField;
