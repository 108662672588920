import axios from "axios";

const predefinedCustomers =
  "573383c66435b18d37dfb35b,57462b06c2dd8aa92857abe2,576061bb916cae597d8edd97," +
  "574f71bc5d3debd449ed5915,57893c0f2d06f9010fd100e1,57b3673b7d26222e44482d98,57bd2a9ed8f1a1c8238382e2," +
  "57fc2cf9686957c6687e5c68,580011d45b51d6d83f6b135c,582f8af4b6fc2978bb114e04,58483953cb06bdb25ef74e25,584c07f5b97777d47feeaf58," +
  "58dbe51e2cb1dd6e1cc5a072,5907a0f5acea5f3ff64ab731,59150f4a0efb20a52f4fff11,596795ecc1b473bdfe839bd7,5988b59ae93fff3c20f5dbd8," +
  "59de4c5afacace357e2bd4e2,5a00f568aeed9f2c92fd73e0,5a396443cd0327456107ab69,5a39a280a1d4374687a7bde8,5ab2952a2a284756f10ecbef," +
  "5adf6928fba0abd23ef79466,5ae899cd09c94918222d7484,5b1ab33715aeb259ebf655e0,5bd8aa95549096fdd4c8790d,5be1d08ed1619252d1acf0cc," +
  "5cd1f2cfed78fe2e0115d7ba,561c496e44349ef538c75d5f,5ea9a8a060bd21a8bf27a59b,5e3479dc00e2c0c3e86b81ac,5e1e3b7a2da1f0bf40be1883," +
  "591636fead8227b31e5c0bab,5ea98e1ffa85dda80a432058,5ebc66647ec2aeccc14678d3,5ebc7416ed35642749f23f81,5ebc71c7ed35642749f23f7f," +
  "5747970824a4498671b3c3a3,5cd0991a3160451d3b97ff6e";

export const fetchAllCustomersCoreReport = (queryString) =>
  axios.get(`/api/admin/v1/reports/core_business/by_customer${queryString}`);
export const fetchPredefinedCoreReport = (queryString) =>
  axios.get(
    `/api/admin/v1/reports/core_business/by_customer?include_customer_ids=${predefinedCustomers}${queryString}`
  );

// for future implementations
export const fetchCustomers = () =>
  axios.get("/api/admin/v1/reports/customers");
