import React from "react";
import PropTypes from "prop-types";
import { omit } from "lodash";

import GroupSelector from "./components/GroupSelector/index";

import { metricQueryShape } from "../../../shared/components/Metrics/Metrics.jsx";
import {
  sum,
  average,
  change,
} from "../../../shared/components/Charts/BaseChart/operations";
import {
  percentage,
  round,
} from "../../../shared/components/Charts/BaseChart/formatters";

import LineChart from "../../../shared/components/Charts/LineChart";
import Metrics from "../../../shared/components/Metrics/";
import FetchedMetrics from "./../../../shared/components/FetchedMetrics";
import Number from "./../../../shared/components/Charts/Number";
import Percentage from "./../../../shared/components/Charts/Percentage";
import { lastChange } from "../../../shared/components/Charts/Number/aggregators";

import BreadCrumbBar from "../../../components/BreadCrumbBar/BreadCrumbBar";

import "./Metric.css";

class Metric extends React.Component {
  constructor(props) {
    super(props);
    this._onGroupsChange = this._onGroupsChange.bind(this);
    this._changeDisplay = this._changeDisplay.bind(this);
  }

  _renderCodePicker() {
    return (
      <div className="form-group">
        <GroupSelector
          selectedGroups={this.props.query.group}
          onChange={this._onGroupsChange}
        />
      </div>
    );
  }

  _renderDisplayPicker() {
    const value = this.props.query.display;
    return (
      <div className="form-group">
        <select
          onChange={this._changeDisplay}
          value={value}
          className="form-control"
          id="show"
        >
          <option value="combined">Combined</option>
          <option value="individually">Individually</option>
        </select>
      </div>
    );
  }

  _onGroupsChange(newGroups) {
    this._updateQuery({ group: newGroups });
  }

  _updateQuery(updates) {
    this.props.gotoQuery(Object.assign({}, this.props.query, updates));
  }

  _changeDisplay(event) {
    const value = event.target.value;
    this._updateQuery({ display: value });
  }

  renderFilter() {
    return (
      <BreadCrumbBar>
        <div style={{ paddingTop: "5px" }}>
          <div
            style={{
              width: "20%",
              boxSizing: "border-box",
              float: "left",
              paddingRight: "10px",
            }}
          >
            {this._renderDisplayPicker()}
          </div>
          <div
            style={{ width: "80%", boxSizing: "border-box", float: "right" }}
          >
            {this._renderCodePicker()}
          </div>
        </div>
      </BreadCrumbBar>
    );
  }

  renderPageContent() {
    // Todo: Only get relevant part
    const urlQuery = this.props.query;
    const display =
      this.props.query.display === "combined" ? "combined" : "individually";

    return (
      <div>
        <h3>Engagement Metrics</h3>
        <br />

        {/*See ./shared/components/Charts/BaseChart/BaseChart.jsx at the bottom (the propTypes) what you can pass and what each thing does */}

        <h4>Users vs Active users </h4>
        {/* This one fetches info from the API the fetchedMetrics doesn't it just reads fetched before from the feed */}
        <Metrics
          query={[Object.assign({ type: "engagement_app_open" }, urlQuery)]}
        >
          <LineChart
            draw={[
              {
                metricType: "engagement_app_open",
                operation: sum("start", "data.nr_users_with_app_opens"),
                description: "Opened app (in last 7 days)",
                label: "App Opens",
                display,
              },
              {
                metricType: "engagement_app_open",
                operation: sum("start", "data.nr_active_users"),
                description: "Did not open app (in last 7 days)",
                label: "Users",
                display,
              },
            ]}
            yDescription="app opens"
            fileName="App Opens"
            yDomain={(min, max) => [0, max * 1.1]}
            xLabelAngle={-90}
            margin={{ bottom: 100 }}
          />
        </Metrics>

        <h4>Percentage active users</h4>
        <FetchedMetrics types="engagement_app_open">
          <LineChart
            draw={[
              {
                metricType: "engagement_app_open",
                operation: average("start", "data.ratio"),
                description: "Percentage of users that opened the app",
                label: "Percentage",
                formatter: percentage,
                display,
              },
            ]}
            yDomain={[0, 1]}
            yAxisFormat={percentage}
            fileName="App Opens Ratio"
            xLabelAngle={-90}
            margin={{ bottom: 100 }}
          />
        </FetchedMetrics>

        <h3>Implementation</h3>
        <br />

        <h4>Registrations</h4>
        {/* This one fetches info from the API the fetchedMetrics doesn't it just reads fetched before from the feed */}
        <Metrics
          query={[
            Object.assign(
              { type: "implementation_registration_invite" },
              urlQuery
            ),
          ]}
        >
          <LineChart
            draw={[
              {
                metricType: "implementation_registration_invite",
                operation: change(sum("start", "data.total_nr_peers")),
                description: "Users that registered",
                label: "Users",
                formatter: round(),
                display,
              },
            ]}
            fileName="Kickoff Rate"
            xLabelAngle={-90}
            margin={{ bottom: 100 }}
          />
        </Metrics>

        <h4>Total invites vs Total registrations</h4>
        <FetchedMetrics types="implementation_registration_invite">
          <LineChart
            draw={[
              {
                metricType: "implementation_registration_invite",
                operation: sum("start", "data.total_nr_invites"),
                description: "Invites",
                label: "Number of invites",
                display,
              },
              {
                metricType: "implementation_registration_invite",
                operation: sum("start", "data.total_nr_peers"),
                description: "Registrations",
                label: "Number of registrations",
                display,
              },
            ]}
            fileName="Invites & Registrations Count"
            xLabelAngle={-90}
            margin={{ bottom: 100 }}
          />
        </FetchedMetrics>

        <h4>Change of registrations</h4>
        <FetchedMetrics types="implementation_registration_invite">
          <LineChart
            draw={[
              {
                metricType: "implementation_registration_invite",
                operation: change(sum("start", "data.total_nr_invites")),
                description: "Invite change by day",
                label: "Invite change",
                display,
              },
              {
                metricType: "implementation_registration_invite",
                operation: change(sum("start", "data.total_nr_peers")),
                description: "Registration change by day",
                label: "Registration change",
                display,
              },
            ]}
            fileName="Invites & Registrations Net Change"
            xLabelAngle={-90}
            margin={{ bottom: 100 }}
          />
        </FetchedMetrics>

        <h4>Registration rate</h4>
        <FetchedMetrics types="implementation_registration_invite">
          <LineChart
            draw={[
              {
                metricType: "implementation_registration_invite",
                operation: average("start", "data.ratio"),
                description: "Number of registrations for each invite",
                label: "Ratio",
                formatter: round(2),
                display,
              },
            ]}
            yDomain={(min, max) => [0, Math.max(max * 1.1, 1.5)]}
            fileName="Invites & Registrations Ratio"
            xLabelAngle={-90}
            margin={{ bottom: 100 }}
          />
        </FetchedMetrics>
      </div>
    );
  }

  renderSidebar() {
    return (
      <FetchedMetrics
        types={["implementation_registration_invite", "engagement_app_open"]}
      >
        <h1>Today</h1>
        <Number
          metric="implementation_registration_invite"
          dataPath="data.total_nr_invites"
          label="Invites"
          aggregator={lastChange}
        />
        <Number
          metric="implementation_registration_invite"
          dataPath="data.total_nr_peers"
          label="Registrations"
          aggregator={lastChange}
        />
        <Percentage
          metric="engagement_app_open"
          dataPath="data.ratio"
          label="Active"
          average
        />
      </FetchedMetrics>
    );
  }

  render() {
    const { showFilter } = this.props;

    return (
      <div className="page-container with-sidebar">
        {showFilter ? this.renderFilter() : null}
        <div className="sidebar">{this.renderSidebar()}</div>
        <div className="page-content">{this.renderPageContent()}</div>
      </div>
    );
  }
}

const queryShape = Object.assign({}, omit(metricQueryShape, "type"), {
  display: PropTypes.string,
});

Metric.propTypes = {
  // It's the same format as MetricQueryShape, but the type property is not needed
  query: PropTypes.shape(queryShape).isRequired,
  gotoQuery: PropTypes.func.isRequired,
  showFilter: PropTypes.bool.isRequired,
};

Metric.defaultProps = {
  query: {
    display: "combined",
  },
};

export default Metric;
