import ModalPanel from "./ModalPanel.js";

import reducer from "./reducer";

export default ModalPanel;

export const ModalPanelRedux = {
  MODAL_PANEL: {
    reducer: {
      ui: {
        ModalPanel: reducer,
      },
    },
  },
};
