import React from "react";
import PropTypes from "prop-types";
import { sortBy } from "lodash";

// Slight alteration of the package fix for our IE problem
import ProgressArc from "./progress_arc_component";

import { appColors } from "../../../../../../../shared/utils/colors";

import "./Arc.css";

function colorForValue(color, value) {
  if (!color) {
    return appColors.peerWellBlue;
  }
  if (typeof color === "string") {
    return color;
  }

  const sortedColors = sortBy(color, (c) => c.value).reverse();
  for (const index in sortedColors) {
    const colorSetting = sortedColors[index];
    if (value >= colorSetting.value) {
      return colorSetting.color;
    }
  }
}

function Arc({
  value,
  maxValue,
  unit,
  label,
  color,
  numberFormatter,
  children,
}) {
  const renderColor = colorForValue(color, value);
  return (
    <div className="arc-container">
      <div className="arc">
        <ProgressArc
          value={parseFloat(value)}
          labelValue={numberFormatter(value)}
          max={parseFloat(maxValue)}
          unit={unit}
          arcColor={renderColor}
          textColor={renderColor}
        />
        {children}
      </div>
      {label && <p>{label}</p>}
    </div>
  );
}

Arc.propTypes = {
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  unit: PropTypes.string,
  label: PropTypes.string,
  numberFormatter: PropTypes.func,
  color: PropTypes.oneOfType([
    PropTypes.string.isRequired,

    // If you pass this, the color is dynamic, it will get the color of the largest number value is greater or equal to
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        color: PropTypes.string.isRequired,
      })
    ),
  ]),
  children: PropTypes.node,
};

Arc.defaultProps = {
  unit: "",
  numberFormatter: (v) => v,
  label: "",
};

export default Arc;
