import originalValidate from "validate.js";
import moment from "moment";

import isValidZipcode from "is-valid-zipcode";
import { isStrongPassword, passwordStrength } from "./password_validation";

originalValidate.extend(originalValidate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse(value, options) {
    return +moment.utc(value);
  },
  // Input is a unix timestamp
  format(value, options) {
    let format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
    return moment.utc(value).format(format);
  },
});

originalValidate.validators.zipCode = (value, options, key, attributes) => {
  if (!value) return null;

  value += ""; // Lib only works with strings...
  const errorMessage = "is not a valid zip code";

  if (!isValidZipcode(value)) {
    return errorMessage;
  }

  return undefined;
};

originalValidate.validators.strongPassword = (value) => {
  if (!value) {
    return null;
  }
  if (!isStrongPassword(value)) {
    const message = passwordStrength(value)
      .filter((p) => !p.passes)
      .map((p) => p.message)
      .join(", ");
    return `Password must contain ${message}`;
  }
};

originalValidate.validators.eitherPresence = function (
  value,
  options,
  key,
  attributes
) {
  if (options.indexOf(".") !== -1) {
    throw new Error("Nested values not supported for now on eitherPresence");
  }

  const otherValue = attributes[options];

  if (!otherValue && !value) {
    return `Required when ${options} is empty`;
  }
};

export const validate = originalValidate;
