import { selectFiles } from "../Team/PartnerUserForm/components/ProfilePicture/helper";
import { uploadFile } from "./api";

export async function uploadCards() {
  const files = await selectFiles();
  if (files.length === 0) {
    return;
  }

  const file = files[0];
  try {
    const res = await uploadFile(file);
    if (res.status === 200 || res.status === 201) {
      alert("Success");
      document.location.reload();
    } else {
      alert(res.data.error);
    }
  } catch (e) {
    const response = e.response;
    if (response.status === 400) {
      alert(response.data.error);
    } else {
      alert("Could not upload CSV");
    }
  }
}
