import PropTypes from "prop-types";

// This component at the moment purely exists so the generation of the legend in the charts don't trigger an error (See BaseChart.jsx where legend is generated)
// Without this wrapped component react-vis passes props to the container div, which triggers warnings, since div's don't have props
// This just allows the div to be the container, without it receiving props
function EmptyComponent({ children }) {
  return children;
}

EmptyComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EmptyComponent;
