import { takeLatest, put, all } from "redux-saga/effects";

import {
  setFleetingMessage,
  submitFormToAPI,
} from "../../../../../../../../shared/utils/form";

import { updateNameForInvite, didUpdateName } from "./actions";
import { updateNameForInvite as updateNameForInviteAPI } from "./api";

function* submitNameUpdate(action) {
  const { inviteId, firstName, lastName } = action.payload;

  yield put(
    submitFormToAPI(
      "updateName",
      updateNameForInviteAPI,
      didUpdateName(inviteId, firstName, lastName),
      inviteId,
      firstName,
      lastName
    )
  );
}

export default function* watchUpdateNameSaga() {
  yield all([
    takeLatest(updateNameForInvite, submitNameUpdate),
    takeLatest(
      didUpdateName,
      setFleetingMessage("updateName", { message: "Name has been updated." })
    ),
  ]);
}
