import { connect } from "react-redux";
import { getFormValues, formValueSelector } from "redux-form";

import { fleetingFormMeta } from "../../../utils/form";

import { requestLogin } from "./actions";
import { isLoggingIn, loginError, isConfirming2Fa } from "./reducer";
import Login from "./Login.jsx";

function allRequiredFieldsFilledOut(state) {
  const confirming2Fa = isConfirming2Fa(state);

  const formState = getFormValues("login")(state) || {};
  const { email, password, mfa_token } = formState;

  if (confirming2Fa) {
    return mfa_token && mfa_token.length === 6;
  }
  return !!email && !!password;
}

function mapStateToProps(state) {
  return {
    loginError: loginError(state),
    message: fleetingFormMeta(state, "login").message,
    isLoggingIn: isLoggingIn(state),
    disabled: !allRequiredFieldsFilledOut(state),
    confirming2Fa: isConfirming2Fa(state),
    email: formValueSelector("login")(state, "email"),
    password: formValueSelector("login")(state, "password"),
  };
}

const dispatch = {
  onSubmit: (values) => requestLogin(values),
};

function mergeProps(stateProps, dispatchProps) {
  const { email, password } = stateProps;
  const { onSubmit } = dispatchProps;

  const mergedProps = {
    ...stateProps,
    ...dispatchProps,
    sendNewCode: () => onSubmit({ email, password, resendingCode: true }),
  };
  return mergedProps;
}

export default connect(mapStateToProps, dispatch, mergeProps)(Login);
