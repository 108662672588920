import { getProgramsAxios } from "../../../../services/api";

export const createProgram = (program) =>
  getProgramsAxios().post("/programs", program);
export const updateProgram = (programId, updates) =>
  getProgramsAxios().patch(`/programs/${programId}`, updates);
export const simulateProgram = (programId, values) =>
  getProgramsAxios().post(
    `/programs/${programId}/daily-cards/simulations`,
    values
  );

export async function fetchAllPhases(fromPage = 0) {
  const phases = await fetchPhasesPage(fromPage);
  const allFetched = phases.length < perPage;
  if (allFetched) {
    return phases;
  }
  return [...phases, ...(await fetchAllPhases(fromPage + 1))];
}

const fetchPhasesPage = async (page) =>
  (await getProgramsAxios().get(`/phases?page=${page}&per_page=${perPage}`))
    .data;

const perPage = 500;
