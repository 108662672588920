import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { validateForm } from "./../../../../../../../shared/utils/form";

import FormGroupField from "./../../../../../../../shared/components/FormFields/FormGroupField";

const rules = {
  fullname: {
    presence: true,
  },
};

function UpdateName({ onSubmit, handleSubmit, message }) {
  return (
    <div>
      <h1>Update Name</h1>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="fullname"
          component={FormGroupField}
          label="Full Name"
          required
        />
        <input type="submit" className="btn btn-primary" value="Update" />
        {message ? <p className="form-message">{message}</p> : null}
      </form>
    </div>
  );
}

UpdateName.propTypes = {
  userId: PropTypes.string.isRequired,
  message: PropTypes.string,

  // Automatically added
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

UpdateName.defaultProps = {
  message: null,
};

export default reduxForm({
  form: "updateName",
  validate: validateForm(rules),
})(UpdateName);
