// Hooking up redux-little-router to redux
// https://github.com/FormidableLabs/redux-little-router
import React from "react";
import {
  routerForBrowser,
  Fragment,
  initializeCurrentLocation,
} from "redux-little-router";
import { map, compact } from "lodash";
import PropTypes from "prop-types";
import ReactGA from "react-ga";

import { AppLayout, appTitle } from "../../routes/index";

import { redirectToHome } from "../routes/Authentication/Login/sagas";

import routes from "./routes";

// Stuff that makes sure different routing events are handled
// and new routing information is updated into the redux store
export const router = routerForBrowser({
  routes,
});

router.initializeCurrentLocation = initializeCurrentLocation;

// Higher order component which logs a pageview for Google Analytics
const withReactGA = (WrappedComponent, { routeTitle, appTitle, key }) =>
  class extends React.Component {
    componentDidMount() {
      ReactGA.pageview(routeTitle || window.location.pathname);
      // Ugly, but necessary. We need to know the route param (/:id) of the DayPlan component in order to grab the title of the card being rendered in it
      if (key !== "/day-plan/(:id)") {
        document.title = routeTitle || appTitle || "PeerWell";
      }
    }
    render() {
      return <WrappedComponent />;
    }
  };

// Each fragment will only be rendered in react if the current url matches the url of the fragment
const fragments = compact(
  map(routes, (route, key) => {
    const component = route.component;
    if (!component) {
      return;
    }
    const Layout = route.layout ? route.layout : AppLayout;
    return (
      <Fragment key={key} forRoute={key}>
        <Layout>
          {React.createElement(
            withReactGA(component, { routeTitle: route.title, appTitle, key })
          )}
        </Layout>
      </Fragment>
    );
  })
);

// Makes sure the router fragments have access to the store and get get current url
export function RouterFragments({ store }) {
  return (
    <Fragment forRoute="/">
      <div>{fragments}</div>
    </Fragment>
  );
}

RouterFragments.propTypes = {
  store: PropTypes.object.isRequired,
};

// Makes sure the onEnter of routes is called when the url changes
export function* onRouterLocationChange(action) {
  // We hit an invalid route, redirecting to a valid page
  if (!action.payload.result) {
    yield redirectToHome();
    return;
  }

  // We hit a valid route
  const onEnter = action.payload.result.onEnter;
  if (!onEnter) {
    return;
  }

  if (typeof onEnter !== "function") {
    throw new Error("Route onEnter property should be a function");
  }

  yield* onEnter(action.payload);
}
