import { handleActions } from "redux-actions";
import { get } from "lodash";

import { didFetchCard, resetCard } from "./actions";

export function cardDescription(state) {
  return get(state, "ui.PeerView.card.description");
}

export function cardId(state) {
  return get(state, "ui.PeerView.card.id");
}

export function modalPanelDisplay(state) {
  return get(state, "ui.ModalPanel.show");
}

export default handleActions(
  {
    [didFetchCard]: (state, action) => {
      return { ...state, card: action.payload };
    },
    [resetCard]: (state) => {
      return { ...state, card: null };
    },
  },
  {
    card: null,
  }
);
