import { get, sortBy, filter as arrayFilter } from "lodash";
// The card metrics are slightly different then the normal metrics. Namely the normal metrics, would have one object that holds values per entry
// The second is, that the card metrics are not graphed per day, but per card

// Example of "normal" metrics entry
// {
//   meta_id: "asdasdas",
//   start: 123123213,
//   end: 123123213,
//   grouped_by_id: 'asdsadsad'
//   data: {
//     singular_value: 1
//     another_value: 2
//   }
// }
// Where the data part of the entry holds a "simple object". With the Cards however, for that given time frame it holds the info per card like so:

// {
//   meta_id: "asdasdsa"
//   start: 12313123,
//   end: 123123213,
//   grouped_by_id: 'asdsadsad'
//   data: [
//     {
//       "behavior_id" : "59c96fec25bacc7142272a1f",
//       "nr_assigned" : 10,
//       "nr_completed" : 5,
//       "ratio" : 0.5
//     },
//     {
//       "behavior_id" : "59c96faf25bacc7142272a1e",
//       "nr_assigned" : 20,
//       "nr_completed" : 8,
//       "ratio" : 0.4
//     }
//   ]
// }

// The above is an entry for a single day, with how well the Cards performed on that day so if we get it from the last 30 days we would hve 30 of those objects
// With the normal metrics we normally chart by date on the x axis. Here however the behavior_id will be the x axis so we can compare card by card for a given code

// This function converts the above metrics to a singular object per card.
// So for each card the total assigned / completed in the period is known and the average ratio over that time
// The returned format would be an array where each entry has the same format as in the data part of the metrics above

import { values } from "lodash";

// I think this can be generalized, but not sure what this operation would be called
export function cardMetricsToPerCardMetrics(metrics, behaviorInfo, sortKey) {
  // The base entry we use to build the data
  const baseValue = {
    nr_assigned: 0,
    nr_completed: 0,
    nr_read: 0,
    completion_ratio: 0,
    read_ratio: 0,
  };

  // This will hold the total metrics per card, indexed by behavior id
  const perCardMetrics = {};

  for (const metric of metrics) {
    for (const card of metric.data) {
      const id = card.behavior_id;
      // Make sure we have this entry
      if (!perCardMetrics[id]) {
        perCardMetrics[id] = Object.assign({}, baseValue, {
          grouped_by: "card",
          grouped_by_id: card.behavior_id,
        });
      }

      const aggregatedCard = perCardMetrics[id];
      aggregatedCard.nr_assigned += card.nr_assigned;
      aggregatedCard.nr_completed += card.nr_completed;
      aggregatedCard.nr_read += card.nr_read;
      // Ratio will be calculated when we have all data per card
    }
  }

  const allCards = values(perCardMetrics);

  const cardsWithInfo = allCards.map((card, index) => {
    // Get the title to add to this card
    const behavior = behaviorInfo[card.grouped_by_id];
    const title = behavior ? behavior.title : "CARD IS NO LONGER IN DB";
    return {
      ...card,
      completion_ratio:
        card.nr_assigned > 0 ? card.nr_completed / card.nr_assigned : 0,
      read_ratio: card.nr_read > 0 ? card.nr_completed / card.nr_read : 0,
      title,
    };
  });

  const sortedCards = sortBy(cardsWithInfo, (card) => -get(card, sortKey));
  return sortedCards;
}

export function filter(chartData, filterValue) {
  if (!filterValue || filterValue.trim() === "") {
    return chartData;
  }

  const lowerCased = filterValue.toLowerCase();
  const filtered = arrayFilter(
    chartData,
    (chartItem) => chartItem.title.toLowerCase().indexOf(lowerCased) !== -1
  );

  return filtered;
}

export function addIndexProperty(chartData) {
  // Need the index for sorting purposes and keeping track of which label in the chart belongs to which card
  return chartData.map((data, index) => ({
    ...data,
    index,
  }));
}
