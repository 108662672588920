import { createAction } from "redux-actions";

const prefixed = (val) => `TEAM::${val}`;

export const requestResults = createAction(
  prefixed("REQUEST_RESULTS"),
  (keyword, sortBy, sortDirection, page) => ({
    keyword,
    sortBy,
    sortDirection,
    page,
  })
);
export const didFetchResults = createAction(prefixed("DID_FETCH_RESULTS"));

export const requestExternalFields = createAction(
  prefixed("REQUEST_EXTERNAL_FIELDS")
);
export const didFetchExternalFields = createAction(
  prefixed("DID_FETCH_EXTERNAL_FIELDS")
);

export const requestCustomers = createAction(prefixed("REQUEST_CUSTOMERS"));
export const didFetchCustomers = createAction(prefixed("DID_FETCH_CUSTOMERS"));

export const createPartnerUser = createAction(prefixed("CREATE_PARTNER_USER"));
export const didCreatePartnerUser = createAction(
  prefixed("DID_CREATE_PARTNER_USER")
);

export const updatePartnerUser = createAction(prefixed("UPDATE_PARTNER_USER"));
export const didUpdatePartnerUser = createAction(
  prefixed("DID_UPDATE_PARTNER_USER")
);

export const requestGroupsAsAdmin = createAction(
  prefixed("REQUEST_GROUPS_AS_ADMIN")
);
export const didFetchGroupsAsAdmin = createAction(
  prefixed("DID_FETCH_GROUPS_AS_ADMIN")
);

export const resendInvite = createAction(prefixed("RESEND_INVITE"));
export const didResendInvite = createAction(prefixed("DID_RESEND_INVITE"));

export const resetGroupOptions = createAction(prefixed("RESET_GROUP_OPTIONS"));
