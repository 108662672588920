import { createAction } from "redux-actions";

const prefixed = (val) => `PINNED_CARDS::${val}`;

export const fetchPinnedCards = createAction(
  prefixed("FETCH_PINNED_CARDS"),
  (programId) => programId
);
export const didFetchPinnedCards = createAction(
  prefixed("DID_FETCH_PINNED_CARDS"),
  (cardsList, count, selectedId) => ({ cardsList, count, selectedId })
);

export const fetchProgramCards = createAction(
  prefixed("FETCH_PROGRAM_CARDS"),
  (programId) => programId
);
export const didFetchProgramCards = createAction(
  prefixed("DID_FETCH_PROGRAM_CARDS"),
  (cardsList, count, selectedId) => ({ cardsList, count, selectedId })
);

export const pinCard = createAction(
  prefixed("PIN_CARD_TO_PROGRAM"),
  (programId, values) => ({ programId, values })
);
export const didPinCard = createAction(
  prefixed("DID_PIN_CARD_TO_PROGRAM"),
  (programId) => programId
);

export const unpinCard = createAction(
  prefixed("UNPIN_CARD_TO_PROGRAM"),
  (programId, cardId) => ({ programId, cardId })
);
export const didUnpinCard = createAction(
  prefixed("DID_UNPIN_CARD_TO_PROGRAM"),
  (programId) => programId
);
