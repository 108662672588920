import React from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";

import Table from "./components/Table";
import Pagination from "./../../../../shared/components/Pagination";
import BreadCrumbBar from "./../../../../components/BreadCrumbBar";
import UserSpecific from "./../../../../shared/components/UserSpecific";

import SimpleNumber from "./../../../../shared/components/Data/SimpleNumber";

import Search from "./components/Search";
import Filter from "./components/Filter";

import "./List.css";

class List extends React.Component {
  componentDidMount() {
    const { fetchPeers, currentPage, filter } = this.props;
    fetchPeers(currentPage, filter);
  }

  componentWillReceiveProps(nextProps) {
    const { fetchPeers, currentPage, filter } = this.props;
    if (
      nextProps.currentPage !== currentPage ||
      !isEqual(nextProps.filter, filter)
    ) {
      fetchPeers(nextProps.currentPage, nextProps.filter);
    }
  }

  renderBreadcrumbBar() {
    return (
      <UserSpecific
        type="admin"
        userSpecificBodyClassName={{
          demo: "no-breadcrumb",
          staff: "no-breadcrumb",
        }}
      >
        <BreadCrumbBar>
          <div className="filter-box">
            <Filter />
          </div>
        </BreadCrumbBar>
      </UserSpecific>
    );
  }

  renderSidebar() {
    const { totalNumberOfPeers } = this.props;
    const { customers, clinicians, registrationCodes } = this.props.counts;
    return (
      <div>
        <h1>Summary</h1>
        <SimpleNumber label="Accounts" value={totalNumberOfPeers} />
        <hr style={{ clear: "both" }} />
        <UserSpecific type="admin">
          <div>
            <SimpleNumber label="Customers" value={customers} />
            <SimpleNumber label="Clinicians" value={clinicians} />
            <SimpleNumber label="Codes" value={registrationCodes} />
          </div>
        </UserSpecific>
      </div>
    );
  }

  render() {
    const { peers, totalNumberOfPeers, currentPage } = this.props;

    function renderPeers() {
      if (!peers) {
        return null;
      } else if (peers.length === 0) {
        return <p>No users with the given criteria have been found</p>;
      }

      return (
        <div>
          <Table peers={peers} />
          <Pagination
            itemCount={totalNumberOfPeers}
            itemsPerPage={15}
            currentPage={currentPage}
            baseUrl="/peers/overview"
          />
        </div>
      );
    }

    return (
      <div className="page-container with-sidebar">
        <div className="sidebar">{this.renderSidebar()}</div>
        <div className="page-content">
          {/*HAS TO BE RENDERED HERE. ELSE IT F-s UP CSS*/}
          {this.renderBreadcrumbBar()}

          <div className="search-box">
            <UserSpecific type={["admin", "staff"]}>
              <div>
                <Search />
              </div>
            </UserSpecific>
          </div>

          <h1>User overview</h1>
          {renderPeers()}
        </div>
      </div>
    );
  }
}

List.propTypes = {
  // All added automatically
  peers: PropTypes.arrayOf(PropTypes.object),
  totalNumberOfPeers: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  fetchPeers: PropTypes.func.isRequired,
  filter: PropTypes.object,
  counts: PropTypes.shape({
    customers: PropTypes.number.isRequired,
    clinicians: PropTypes.number.isRequired,
    registrationCodes: PropTypes.number.isRequired,
  }),
};

List.defaultProps = {
  filter: null,
  peers: null,
};

export default List;
