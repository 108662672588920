import React from "react";
import PropTypes from "prop-types";
import { keyBy } from "lodash";

import BarChart from "./../../../../../shared/components/Charts/BarChart";
import {
  average,
  sum,
} from "../../../../../shared/components/Charts/BaseChart/operations";
import { round } from "../../../../../shared/components/Charts/BaseChart/formatters";
import {
  cardMetricsToPerCardMetrics,
  filter as chartFilter,
  addIndexProperty,
} from "./operations";

class CardChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: null,
    };

    this._getTitle = this._getTitle.bind(this);
  }

  componentDidMount() {
    this.props.requestBehaviorInfo();
  }

  componentWillReceiveProps(nextProps) {
    const chartData = this._getChartDataFromMetricData(nextProps);
    this.setState({
      chartData,
      // For faster lookup with titles in the chart
      indexedChartData: keyBy(chartData, "index"),
    });
  }

  render() {
    const { chartData } = this.state;

    if (!chartData) {
      return null;
    }

    const xTicks = this._getXTicks(chartData);
    const barItemWidth = 30;
    const width = barItemWidth * xTicks.length;

    return (
      <div>
        <BarChart
          draw={[
            {
              metricType: "engagement_card_completion",
              operation: sum("index", "nr_assigned"),
              label: "Assigned",
              description: "Times the card is assigned",
              display: "combined",
            },
            {
              metricType: "engagement_card_completion",
              operation: sum("index", "nr_completed"),
              label: "Completed",
              description: "Times the card is completed",
              display: "combined",
            },
            {
              metricType: "engagement_card_completion",
              operation: sum("index", "nr_read"),
              label: "Read",
              description: "Times the card is read",
              display: "combined",
            },
          ]}
          xTickValues={xTicks}
          xLabelAngle={-90}
          xAxisFormat={this._getTitle}
          xDescription="Card"
          fileName="Card Engagement"
          height={600}
          width={width}
          margin={{ bottom: 300 }}
          className="allow-scroll-x"
        />

        <BarChart
          draw={[
            {
              metricType: "engagement_card_completion",
              operation: average("index", "completion_ratio"),
              label: "Ratio",
              description: "Ratio of assigned & completions",
              display: "combined",
              formatter: round(2),
            },
            {
              metricType: "engagement_card_completion",
              operation: average("index", "read_ratio"),
              label: "Ratio",
              description: "Ratio of assigned & reads",
              display: "combined",
              formatter: round(2),
            },
          ]}
          xTickValues={xTicks}
          xLabelAngle={-90}
          xAxisFormat={this._getTitle}
          xDescription="Card"
          fileName="Card Engagement Ratio"
          height={600}
          width={width}
          margin={{ bottom: 300 }}
          className="allow-scroll-x"
        />
      </div>
    );
  }

  _getXTicks(chartData) {
    return chartData.map((item) => item.index);
  }

  _getTitle(index) {
    return this.state.indexedChartData[index].title;
  }

  _getChartDataFromMetricData(nextProps) {
    if (
      !this.context.metricData.engagement_card_completion ||
      !nextProps.behaviorInfo
    ) {
      return null;
    }

    const { filter } = nextProps;

    // Reformatting the metric data that is passed to the LineChart child before passing it on
    const chartData = cardMetricsToPerCardMetrics(
      this.context.metricData.engagement_card_completion,
      nextProps.behaviorInfo,
      this.props.sortBy
    );

    // Filtering based on search and adding the index property for title lookup and correctly sorting it in the chart
    return addIndexProperty(chartFilter(chartData, filter));
  }

  getChildContext() {
    return {
      metricData: {
        engagement_card_completion: this.state.chartData,
      },
    };
  }
}

CardChart.propTypes = {
  requestBehaviorInfo: PropTypes.func.isRequired,
  behaviorInfo: PropTypes.object,
  sortBy: PropTypes.string,
  filter: PropTypes.string,
};

CardChart.defaultProps = {
  behaviorInfo: [],
  sortBy: "nr_assigned",
  filter: null,
};

CardChart.contextTypes = {
  metricData: PropTypes.object,
};

CardChart.childContextTypes = {
  metricData: PropTypes.object,
};

export default CardChart;
