import { handleActions } from "redux-actions";
import {
  onCardsDownloaded,
  onDidFilterByAssessmentsChanged,
  onDidShowDeletedChanged,
  didFetchCardsData,
  onDownloadCards,
} from "./actions";

var _ = require("lodash");
export const cardsList = (state) => state.ui.Cards.list || [];
export const showDeleted = (state) => state.ui.Cards.showDeleted || false;
export const cardsCount = (state) => state.ui.Cards.count || 0;
export function isDownloading(state) {
  return state.ui.Cards.isDownloading;
}
export const filterCardByAssessment = (state) =>
  state.ui.Cards.filterByAssessment || false;
export const v1CardDownloadData = (state) =>
  state.ui.Cards.v1CardDownloadData || null;
export const v2CardDownloadData = (state) =>
  state.ui.Cards.v2CardDownloadData || null;

export default handleActions(
  {
    [onDidShowDeletedChanged]: (state, action) => ({
      ...state,
      showDeleted: !state.showDeleted,
    }),
    [onDidFilterByAssessmentsChanged]: (state, action) => ({
      ...state,
      filterByAssessment: !state.filterByAssessment,
    }),
    [onCardsDownloaded]: (state, action) => ({
      ...state,
      isDownloading: false,
      v1CardDownloadData: null,
      v2CardDownloadData: null,
    }),
    [onDownloadCards]: (state, action) => ({
      ...state,
      isDownloading: true,
    }),
    [didFetchCardsData]: (state, action) => ({
      ...state,
      v1CardDownloadData: filterV1CardsDownloadInfo(
        action.payload.cardsData.v1Cards
      ),
      v2CardDownloadData: formatV2CardsDownloadInfo(
        action.payload.cardsData.v2Cards
      ),
    }),
  },
  {
    showDeleted: false,
  }
);

function filterV1CardsDownloadInfo(rows) {
  return rows.map((row) => {
    const {
      description,
      health_area,
      title,
      id,
      action_link,
      programs_with_card,
    } = row;
    return escapeCSVQuotes({
      id,
      title,
      description,
      health_area,
      action_link,
      programs: programs_with_card,
    });
  });
}

function formatV2CardsDownloadInfo(rows) {
  if (!rows) {
    return null;
  }

  return rows.map((row) => {
    const {
      id,
      action,
      content,
      description,
      duration,
      es_content,
      es_name,
      es_description,
      es_duration,
      name,
      pillar,
      notes,
      programs,
      v1Ids,
    } = row;
    return escapeCSVQuotes({
      id,
      name,
      content,
      description,
      duration,
      action: action?.value,
      pillar,
      notes,
      programs,
      es_name,
      es_content,
      es_description,
      es_duration,
      v1_ids: v1Ids ?? [], // Import expects a column named "v1_ids", change it here so the user does not have to
    });
  });
}

// react-csv does not escape chars, we have to do it ourselves
function escapeCSVQuotes(cardData) {
  return Object.fromEntries(
    Object.entries(cardData).map((entry) => {
      if (_.isString(entry[1]) && entry[1].includes('"')) {
        return [entry[0], entry[1].replaceAll('"', '""')];
      }
      return entry;
    })
  );
}
