import { pLimit } from "plimit-lit";
import { getApiAxios, getProgramsAxios } from "../../services/api";

export const createCard = ({ card }) => getProgramsAxios().post("/cards", card);
export const updateCard = ({ card }) =>
  getProgramsAxios().patch("/cards", card);
export const fetchCard = (cardId) => getProgramsAxios().get(`/cards/${cardId}`);
export const fetchPillars = () => getProgramsAxios().get("pillars");
export const fetchCardsList = ({ deleted, filter, assessment, page }) => {
  if (assessment) {
    return getProgramsAxios().get(
      `/cards?include_deleted=${deleted}&filter[name]=${filter}&page=${page}&filter[action.linkType]=assessment`
    );
  }
  return getProgramsAxios().get(
    `/cards?include_deleted=${deleted}&filter[name]=${filter}&page=${page}`
  );
};

export const fetchCardV1Data = () => {
  return getApiAxios().get(`/api/admin/v1/cards/?programs=true`);
};

export const fetchCardV2Data = async () => {
  const baseUrl = "/cards?per_page=500";
  const cardsResponse = await getProgramsAxios().get(baseUrl);
  const cards = cardsResponse.data;

  let page = 1;
  const totalCount = cardsResponse.headers["x-total-count"];
  if (totalCount) {
    while (cards.length < totalCount) {
      const nextPage = await getProgramsAxios().get(baseUrl + "&page=" + page);
      cards.push(...nextPage.data);
      page++;
    }
  }

  const cardIds = cards.map((c) => c.id);
  const programs = await fetchProgramsForCards(cardIds);
  const newCards = await fetchSpanishForCardsList(cards);
  return {
    data: newCards.map((c) => ({
      ...c,
      programs: programs[c.id]?.map((p) => p.name),
    })),
  };
};

async function fetchProgramsForCards(cardIds) {
  const BATCH_SIZE = 200;
  let results = {};
  let i = 0;
  let isComplete = false;
  while (!isComplete) {
    const batch = cardIds.slice(i, i + BATCH_SIZE);
    if (batch.length === 0) {
      isComplete = true;
    } else {
      const queryString = batch.map((id) => `cards=${id}`).join("&");
      const programFetchUrl = `/programs/with-cards/?${queryString}`;
      const programsResponse = await getProgramsAxios().get(programFetchUrl);
      if (programsResponse.data)
        results = { ...results, ...programsResponse.data };
      i += BATCH_SIZE;
    }
  }
  return results;
}

async function fetchSpanishForCardsList(cards) {
  let newCards = [];

  const limit = pLimit(10);
  const languageRequests = cards.map((card) =>
    limit(() => fetchSpanishForCard(card))
  );
  await Promise.allSettled(languageRequests).then((results) =>
    results.forEach((result) => {
      if (result.status === "fulfilled") {
        newCards.push(result.value);
      }
    })
  );
  return newCards;
}

async function fetchSpanishForCard(card) {
  try {
    const {
      data: { content, name, description, duration },
    } = await getProgramsAxios().get(`/cards/${card.id}/langs/es`);
    return {
      ...card,
      es_content: content,
      es_name: name,
      es_description: description,
      es_duration: duration,
    };
  } catch (error) {
    if (error.response.status != 404) {
      throw error;
    } else {
      console.warn("No translation data for card with id ", card.id);
      return card;
    }
  }
}

export async function uploadFile(file) {
  let formData = new FormData();
  formData.append("file", file);

  const url = "/cards/imports";

  return getProgramsAxios().post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
