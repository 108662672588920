import axios from "axios";
import config from "../config";
import initAxios from "../shared/app/request";

export function getProgramsAxios() {
  return initAxios(axios.create(), config.PROGRAMS_API_URL);
}

export function getApiAxios() {
  return initAxios(axios.create(), config.API_URL);
}

export function getPreventionAxios() {
  return initAxios(axios.create(), config.PREVENTION_API_URL);
}
