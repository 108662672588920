import { createAction } from "redux-actions";

const prefixed = (val) => `LoadingIndicator::${val}`;

export const incrementLoadingCount = createAction(
  prefixed("INCREMENT_LOADING_COUNT"),
  (count = 1) => count
);
export const decrementLoadingCount = createAction(
  prefixed("DECREMENT_LOADING_COUNT"),
  (count = 1) => count
);
