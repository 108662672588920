import { createAction } from "redux-actions";

const prefixed = (val) => `COACHES::${val}`;

export const requestCoaches = createAction(
  prefixed("REQUEST_RESULTS"),
  (keyword, sortBy, sortDirection, page) => ({
    keyword,
    sortBy,
    sortDirection,
    page,
  })
);
export const didFetchCoaches = createAction(
  prefixed("DID_FETCH_RESULTS"),
  (results, count) => ({ results, count })
);

export const createCoach = createAction(prefixed("CREATE_COACH"));
export const didCreateCoach = createAction(prefixed("DID_CREATE_COACH"));

export const updateCoach = createAction(prefixed("UPDATE_COACH"));
export const didUpdateCoach = createAction(prefixed("DID_UPDATE_COACH"));

export const resendInvite = createAction(prefixed("RESEND_INVITE"));
export const didResendInvite = createAction(prefixed("DID_RESEND_INVITE"));
