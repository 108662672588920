import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import DrillDownSelectField from "../../../../../../../../shared/components/FormFields/DrillDownSelectField";
import SuccessAnimation from "../../../../../../../../shared/components/SuccessAnimation";

import "./TransitionProgram.css";

class TransitionProgram extends React.Component {
  componentWillMount() {
    const { customerId, userId, fetchAvailablePrograms } = this.props;
    fetchAvailablePrograms(customerId, userId);
  }

  render() {
    const { availableParts, message, handleSubmit, onSubmit } = this.props;

    return (
      <div className="transition-program">
        <h1>Available Programs</h1>
        {availableParts && availableParts.length > 0 && (
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Field
              className="program-dropdown"
              name="program"
              component={DrillDownSelectField}
              values={availableParts}
              initialLabel="Select program"
            />
            {message ? (
              <SuccessAnimation message={message} />
            ) : (
              <input
                type="submit"
                className="btn btn-primary"
                value="Change Program"
              />
            )}
          </form>
        )}
        {availableParts && !availableParts.length && (
          <h4>There are no available programs for this patient</h4>
        )}
      </div>
    );
  }
}

TransitionProgram.propTypes = {
  peerId: PropTypes.string.isRequired,
  message: PropTypes.string,

  // Automatically added
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

TransitionProgram.defaultProps = {
  message: null,
};

export default reduxForm({
  form: "transitionProgram",
})(TransitionProgram);
