import { handleActions } from "redux-actions";
import { get } from "lodash";

import { query } from "../../../shared/routes/query";
import {
  didFetchCustomers,
  didFetchCustomerTypes,
  didFetchCustomerDetails,
  didFetchCustomerSelectFields,
  clearCustomerData,
  clearAllData,
} from "./actions";

export function allCustomers(state) {
  const customers = get(state, "ui.ManageCustomer.customers");

  if (!customers) return null;

  return customers.sort((a, b) =>
    a.customer.name.localeCompare(b.customer.name)
  );
}

export function selectedCustomer(state) {
  return get(state, "ui.ManageCustomer.customerDetails");
}

export function selectedCustomerSelectFields(state) {
  return get(state, "ui.ManageCustomer.customerSelectFields");
}

export function customerTypes(state) {
  const types = get(state, "ui.ManageCustomer.customerTypes");
  return types
    ? types.map(({ type, label }) => ({ value: type, label }))
    : null;
}

export function selectedCustomerField(state) {
  const selectedFieldName = query(state, "fieldId");
  const fields = selectedCustomerSelectFields(state);

  if (!fields || !selectedFieldName) return null;

  return fields.find((field) => field.name === selectedFieldName);
}

export function selectedCustomerFieldValue(state) {
  const fieldValue = query(state, "fieldValueId");
  const customerField = selectedCustomerField(state);

  if (!fieldValue || !customerField) return null;

  return customerField.values.find(({ value }) => value === fieldValue);
}

export function customerInitialValues(state) {
  const customer = selectedCustomer(state);

  if (!customer) return null;

  return {
    name: customer.name,
    type: customer.type,
    send_notifications:
      customer.settings && customer.settings.send_notifications,
  };
}

export function fieldValueInitialValues(state) {
  const fieldValue = selectedCustomerFieldValue(state);

  if (!fieldValue) return null;

  return {
    label: fieldValue.label,
    value: fieldValue.value,
    // default to checked (true)
    visible: !fieldValue ? true : fieldValue.visible,
  };
}

// export function prevPage (state) {
//   const customerId = query(state, 'customerId')
//   const fieldId = query(state, 'fieldId')
//   const fieldValueId = query(state, 'fieldValueId')

//   if (!customerId) return '/manage-partners'

//   let prevQuery = { customerId }
//   if (fieldValueId) {
//     prevQuery = { customerId, fieldId }
//   } else if (fieldId) {
//     prevQuery = { customerId, fieldId }
//   }
//   return `manage-partners/?${objectToQuery(prevQuery)}`
// }

export const ManageCustomerReducer = handleActions(
  {
    [didFetchCustomers]: (state, action) => ({
      ...state,
      customers: action.payload,
    }),
    [didFetchCustomerTypes]: (state, action) => ({
      ...state,
      customerTypes: action.payload,
    }),
    [didFetchCustomerDetails]: (state, action) => ({
      ...state,
      customerDetails: action.payload,
    }),
    [didFetchCustomerSelectFields]: (state, action) => ({
      ...state,
      customerSelectFields: action.payload,
    }),
    [clearCustomerData]: (state) => ({
      ...state,
      customerDetails: null,
      customerSelectFields: null,
    }),
    [clearAllData]: (state) => ({
      ...state,
      customers: null,
      customerDetails: null,
      customerSelectFields: null,
    }),
  },
  {
    customers: null,
    customerTypes: null,
    customerDetails: null,
    customerSelectFields: null,
  }
);
