import { handleActions } from "redux-actions";
import { get, set } from "lodash";
import { didRemoveCareTeamMember } from "./actions";

export const getCareTeamIds = (state, peerId) => {
  const careTeam = get(state, `ui.PeerView.${peerId}.care_team`);
  const careTeamIds = careTeam.map((a) => a.id);

  return careTeamIds;
};

export default handleActions(
  {
    [didRemoveCareTeamMember]: (state, action) => {
      const newState = { ...state };
      const peerId = action.payload.peerId;
      const careTeam = state[peerId].care_team;

      set(
        newState,
        `${peerId}.care_team`,
        careTeam.filter((member) => member.id !== action.payload.member.id)
      );

      return newState;
    },
  },
  {}
);
