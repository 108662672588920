import { authenticationOfTypeRequired } from "../../../shared/routes/Authentication/Login/sagas";

import inviteReducer from "./View/reducer";
import watchInviteSaga from "./View/sagas";

import View from "./View";

export default {
  "/invites/view/:id": {
    component: View,
    *onEnter() {
      yield authenticationOfTypeRequired([
        "admin",
        "partner_admin",
        "owner",
        "care_team_user",
        "demo",
        "staff",
      ]);
    },
    reducer: {
      ui: {
        Invite: inviteReducer,
      },
    },
    saga: watchInviteSaga,
    title: "Invite Profile",
  },
};
