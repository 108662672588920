import { values, compact } from "lodash";

import routes from "./routes";
import componentConfiguration from "./../../components";
import sharedComponentConfiguration from "./../../shared/components";

export const appReducers = {};
export var uiReducers = {};

// No recursion here, just going one deep at most
const allReduxConfiguration = values(routes)
  .concat(values(componentConfiguration))
  .concat(values(sharedComponentConfiguration));

const reducers = compact(
  allReduxConfiguration.map((configuration) => configuration.reducer)
);

// Place all ui reducers under the ui property in the store
// Place other reducers on the root of the store
for (const reducersForConfiguration of reducers) {
  for (const reducerKey in reducersForConfiguration) {
    const reducer = reducersForConfiguration[reducerKey];
    // The reducer should be in the ui section
    if (reducerKey === "ui") {
      uiReducers = Object.assign(uiReducers, reducer);
    } else {
      // Add the reducer to the root
      appReducers[reducerKey] = reducer;
    }
  }
}
