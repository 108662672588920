import { createAction } from "redux-actions";

const prefixed = (val) => `Phases/PhasesForm::${val}`;

export const deletePhase = createAction(
  prefixed("DELETE_PHASE", (phaseId) => phaseId)
);
export const didDeletePhase = createAction(
  prefixed("DID_DELETE_PHASE", (phaseId) => phaseId)
);

export const restorePhase = createAction(
  prefixed("RESTORE_PHASE", (phaseId) => phaseId)
);
export const didRestorePhase = createAction(
  prefixed("DID_RESTORE_PHASE", (phaseId) => phaseId)
);
