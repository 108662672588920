import React from "react";
import PropTypes from "prop-types";

import "./Overlay.css";

class Overlay extends React.Component {
  constructor(props) {
    super(props);

    this.mapResult = this.mapResult.bind(this);
    this.mapQuestion = this.mapQuestion.bind(this);
  }

  render() {
    const { assessment, onClear } = this.props;

    return (
      <div className="assessment-overlay" onClick={() => onClear()}>
        <div className="container" onClick={(e) => e.stopPropagation()}>
          <h1>{assessment.title}</h1>

          <h3>Questions</h3>
          <ul className="questions">
            {assessment.questions.map(this.mapQuestion)}
          </ul>

          <h3>Results</h3>
          {assessment.result && (
            <ul className="results">{assessment.result.map(this.mapResult)}</ul>
          )}

          {!assessment.result && <p className="no-results">-</p>}
        </div>
      </div>
    );
  }

  mapQuestion(question) {
    return (
      <li key={question.label}>
        <p className="assessment-label">{question.label || question.name}</p>
        <p className="assessment-value">{question.description}</p>
      </li>
    );
  }

  mapResult(result) {
    return this.mapQuestion(result);
  }
}

Overlay.propTypes = {
  // Todo: Be more specific
  assessment: PropTypes.object.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default Overlay;
