import React from "react";
import PropTypes from "prop-types";
import { orderBy } from "lodash";

import "./PhasesList.css";
import Phase from "./components/Phase/Phase";

class PhasesList extends React.Component {
  constructor(props) {
    super(props);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  onSelectChange(e) {
    const { onShowDeletedChange } = this.props;
    onShowDeletedChange(e.target.value === "true");
  }

  onFilterChange(e) {
    const { updateFilter } = this.props;
    let filter = e.target.value.trim().toLowerCase();
    if (filter.length === 0) {
      filter = null;
    }
    updateFilter(filter);
  }

  render() {
    const { phasesList, onAddPhaseClicked, showDeleted } = this.props;

    if (!phasesList) {
      return null;
    }
    const orderedPhases = orderBy(phasesList, (phase) =>
      phase.name.toLowerCase()
    );

    const emptyPhases = orderedPhases.length === 0;

    const title = "All Phases";
    const emptyMessage = "No phases found";

    return (
      <div className="phases-page">
        <div className="row top">
          <div className="col-md-3">
            <h2>{title}</h2>
          </div>
          <div className="col-md-3">
            <button
              className="btn btn-primary"
              onClick={onAddPhaseClicked}
              data-test="add-phase"
            >
              Add Phase
            </button>
          </div>
          <div className="col-md-3">
            <select value={showDeleted} onChange={this.onSelectChange}>
              <option value={false}>Deleted not included</option>
              <option value>Include deleted</option>
            </select>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className="pull-right form-control form-control-small"
              placeholder="Filter phases"
              onChange={this.onFilterChange}
              data-test="filter-phases"
            />
          </div>
        </div>

        <p className="doubleclick">double click to edit</p>

        {(emptyPhases && (
          <p className="empty-message" data-test="empty-message">
            {emptyMessage}
          </p>
        )) || (
          <ul className="phases-list" data-test="phases-list">
            {orderedPhases.map((phase) => (
              <Phase {...phase} key={phase.id} />
            ))}
          </ul>
        )}
      </div>
    );
  }
}

PhasesList.propTypes = {
  phasesList: PropTypes.arrayOf(PropTypes.object),
  onAddPhaseClicked: PropTypes.func.isRequired,
  fetchPhasesList: PropTypes.func.isRequired,
  onShowDeletedChange: PropTypes.func.isRequired,
  filter: PropTypes.string,
  updateFilter: PropTypes.func.isRequired,
  showDeleted: PropTypes.bool,
  selectedPhase: PropTypes.object,
};

PhasesList.defaultProps = {
  filter: null,
  showDeleted: false,
};

export default PhasesList;
