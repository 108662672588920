import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import DrillDownSelectField from "../../../shared/components/FormFields/DrillDownSelectField";
import { required } from "redux-form-validators";

/* eslint-disable no-mixed-operators */
class CustomerForm extends React.Component {
  componentDidMount() {
    const { requestCustomers } = this.props;
    requestCustomers();
  }

  renderNoClinicianButton() {
    const { demoInviteWithoutClinician } = this.props;

    return (
      <div>
        <p>Send demo invite without nurse</p>
        <input
          type="button"
          onClick={demoInviteWithoutClinician}
          className="btn btn-default"
          value="Go"
        />
      </div>
    );
  }

  render() {
    const { clinicianData, selectClinician, handleSubmit, initialValues } =
      this.props;
    const { asDemo } = initialValues;

    return (
      <div>
        <h2>{(asDemo && "Demo Invite") || "Invite"}</h2>
        <form className="form" onSubmit={handleSubmit(selectClinician)}>
          <Field
            name="clinician"
            component={DrillDownSelectField}
            values={clinicianData}
            label="Clinician"
            initialLabel="Select a clinician"
            validate={[required()]}
          />
          <input type="submit" className="btn btn-primary" value="Select" />
        </form>

        {asDemo && this.renderNoClinicianButton()}
      </div>
    );
  }
}

CustomerForm.propTypes = {
  clinicianData: PropTypes.array,
  selectClinician: PropTypes.func.isRequired,
  requestCustomers: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  demoInviteWithoutClinician: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "CustomerForm",
})(CustomerForm);
