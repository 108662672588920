import { connect } from "react-redux";
import { query } from "../../routes/query";

import Pagination from "./Pagination.jsx";

function mapStateToProps(state) {
  return {
    query: query(state),
  };
}

export default connect(mapStateToProps)(Pagination);
