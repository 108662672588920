import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Select from "react-select";

import "./../FormGroupField/FormGroupField.css";

function MultiSelectField({
  input,
  label,
  required,
  meta: { touched, error, warning, pristine, initial },
  disabled,
  className,
  values,
}) {
  const shouldDisplayError = !pristine || touched;
  const fieldClass = classNames(
    { "has-error": shouldDisplayError && error },
    className
  );

  return (
    <div className="form-group">
      <label htmlFor={input.name}>
        {label}
        {required ? "*" : null}
      </label>

      <Select
        className={fieldClass}
        multi
        joinValues
        value={input.value || initial}
        options={values}
        onChange={input.onChange}
      />

      {shouldDisplayError &&
        ((error && <span className="error-message">{error}</span>) ||
          (warning && <span className="warning-message">{warning}</span>))}
    </div>
  );
}

MultiSelectField.propTypes = {
  dateProps: PropTypes.object,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  // Also a bunch of redux field props
};

MultiSelectField.defaultProps = {
  dateProps: {},
};

export default MultiSelectField;
