import { createAction } from "redux-actions";

const prefixed = (val) => `Peers/View/AddCareTeamMember::${val}`; // TODO: Check

export const addCareTeamMemberForPeer = createAction(
  prefixed("ADD_CARE_TEAM_MEMBER")
);
export const didAddCareTeamMemberForPeer = createAction(
  prefixed("DID_ADD_CARE_TEAM_MEMBER")
);

export const updateCareTeamForPeer = createAction(prefixed("UPDATE_CARE_TEAM"));
export const didUpdateCareTeamForPeer = createAction(
  prefixed("DID_UPDATE_CARE_TEAM")
);
