import { connect } from "react-redux";
import UpdateSurgeryComplications from "./UpdateSurgeryComplications.jsx";

import {
  currentSurgeryComplications,
  surgeryComplicationsMessage,
} from "./reducer";
import {
  updateSurgeryComplications,
  setUpdateSurgeryComplicationsMessage,
} from "./actions";

function mapStateToProps(state, { userId }) {
  return {
    currentSurgeryComplications: currentSurgeryComplications(state, userId),
    message: surgeryComplicationsMessage(state),
  };
}

function mapDispatchToProps(dispatch, { userId }) {
  return {
    updateSurgeryComplications: (bool) =>
      dispatch(updateSurgeryComplications(userId, bool)),
    clearMessage: () => dispatch(setUpdateSurgeryComplicationsMessage(null)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateSurgeryComplications);
