import { connect } from "react-redux";
import { updateQuery } from "../../../../shared/routes/query";
import { deleteModule, restoreModule } from "./actions";
import BottomBar from "./BottomBar.jsx";

function mapDispatchToProps(dispatch, { moduleId }) {
  return {
    onCancel: () =>
      dispatch(updateQuery({ edit: undefined, create: undefined })),
    onDelete: () => {
      dispatch(deleteModule(moduleId));
      dispatch(updateQuery({ edit: undefined, create: undefined }));
    },
    onRestore: () => {
      dispatch(restoreModule(moduleId));
      dispatch(updateQuery({ edit: undefined, create: undefined }));
    },
  };
}

export default connect(null, mapDispatchToProps)(BottomBar);
