import axios from "axios";

export const fetchCustomers = () => axios.get("/api/admin/v1/customers/");

export const fetchPrograms = () =>
  axios.get("/api/admin/v1/programs?aggregated");

export const fetchClinician = (clinicianId) =>
  axios.get(`/api/admin/v1/clinicians/${clinicianId}`);

export const createClinician = (values) =>
  axios.post("/api/admin/v1/clinicians/", values);

export const updateClinician = ({ clinicianId, ...values }) =>
  axios.patch(`api/admin/v1/clinicians/${clinicianId}`, values);
