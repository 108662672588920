import ErrorIndicator from "./ErrorIndicator.js";

import reducer from "./reducer";

export default ErrorIndicator;

export const ErrorIndicatorRedux = {
  ERROR_INDICATOR: {
    reducer: {
      ui: {
        ErrorIndicator: reducer,
      },
    },
  },
};
