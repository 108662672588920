import {
  authenticationOfTypeRequired,
  authenticationRequired,
} from "../../../shared/routes/Authentication/Login/sagas";

import ManageCustomer from "./ManageCustomer.js";
import { ManageCustomerReducer } from "./reducer";
import watchManageCustomer from "./sagas";

// TODO: flat routes for now, but refactor to nest it like so: /manage/customer
export default {
  "/manage-partners": {
    component: ManageCustomer,
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired(["admin"]);
    },
    reducer: {
      ui: {
        ManageCustomer: ManageCustomerReducer,
      },
    },
    saga: watchManageCustomer,
    title: "Manage Partner",
  },
};
