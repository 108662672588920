import { success } from "react-notification-system-redux";
import { put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../shared/app/requestErrorHandler";
import {
  deleteProgram,
  didDeleteProgram,
  didRestoreProgram,
  restoreProgram,
} from "./actions";
import {
  deleteProgram as deleteProgramAPI,
  restoreProgram as restoreProgramAPI,
} from "./api";

function* notifyUser() {
  yield put(success({ message: "The changes have been saved." }));
}

function* deleteProgramFunctionSaga(action) {
  try {
    yield deleteProgramAPI(action.payload);
    yield put(didDeleteProgram(action.payload));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* restoreProgramFunctionSaga(action) {
  try {
    yield restoreProgramAPI(action.payload);
    yield put(didRestoreProgram(action.payload));
  } catch (e) {
    yield handleApiError(e);
  }
}

export default function* watchBottomBarSaga() {
  yield takeLatest(deleteProgram, deleteProgramFunctionSaga);
  yield takeLatest(restoreProgram, restoreProgramFunctionSaga);
  yield takeLatest(didDeleteProgram, notifyUser);
}
