import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import { validateForm } from "../../../../../shared/utils/form";
import { emailRegexValidator } from "../..";

import SimpleField from "../../../../../shared/components/FormFields/SimpleField";
import SelectField from "../../../../../shared/components/FormFields/SelectField";
import CheckboxField from "../../../../../shared/components/FormFields/CheckboxField";

const rules = {
  fullname: {
    presence: true,
  },
  email: {
    presence: true,
    email: true,
  },
  type: {
    presence: true,
  },
};

function CreateUsersForm({
  handleSubmit,
  onSubmit,
  onCancel,
  message,
  groups,
}) {
  const userValues = [
    { label: "Partner admin", value: "partner_admin" },
    { label: "Owner", value: "owner" },
  ];
  let groupValues = null;
  groups.forEach((el) => {
    if (el.name === "group") {
      groupValues = el.values.filter((val) => val.value);
    }
  });

  return (
    <form className="create-users-form" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="form-header">Add new partner user</h3>

      <div className="input-field form-group">
        <label htmlFor="fullname">Full Name *</label>
        <Field name="fullname" component={SimpleField} />
      </div>

      <div className="input-field form-group">
        <label htmlFor="name">Email *</label>
        <Field
          name="email"
          component={SimpleField}
          regexValidator={emailRegexValidator}
        />
      </div>

      <Field
        name="type"
        label="User Type"
        values={userValues}
        component={SelectField}
      />

      <Field
        className="checkbox-field"
        name="blocked"
        label="Blocked"
        component={CheckboxField}
      />

      {groupValues && (
        <Field
          name="group"
          label="Group"
          values={groupValues}
          component={SelectField}
        />
      )}

      <input className="action-btn" type="submit" value="Create" />
      <button className="action-btn red" onClick={onCancel}>
        Cancel
      </button>

      {message && <p className="message">{message}</p>}
    </form>
  );
}

CreateUsersForm.propTypes = {
  programList: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string,
  // redux-form
  handleSubmit: PropTypes.func.isRequired,
};

CreateUsersForm.defaultProps = {
  programList: null,
  message: null,
};

export default reduxForm({
  form: "createUsers",
  validate: validateForm(rules),
  enableReinitialize: true,
})(CreateUsersForm);
