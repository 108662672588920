import { connect } from "react-redux";

import { fleetingFormMeta } from "../../../../../shared/utils/form";

import CreateUsersForm from "./CreateUsersForm.jsx";

function mapStateToProps(state, { onSubmit, onCancel, groups }) {
  return {
    onSubmit,
    onCancel,
    groups,
    message: fleetingFormMeta(state, "createUsers").message,
  };
}

export default connect(mapStateToProps)(CreateUsersForm);
