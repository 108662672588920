import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import ModalPanel from "./../../../../../../shared/components/ModalPanel";

import ResetPassword from "./components/ResetPassword";
import DetransitionPeer from "./components/DetransitionPeer";
import UpdateSurgeryDate from "./components/UpdateSurgeryDate";
import UpdateBirthDate from "./components/UpdateBirthDate/UpdateBirthDate";
import UpdateProcedureType from "./components/UpdateProcedureType";
import UpdateSurgeryComplications from "./components/UpdateSurgeryComplications";
import AddCareTeamMember from "./components/AddCareTeamMember";
import UpdateOwner from "./components/UpdateOwner";
import UpdateEmail from "./components/UpdateEmail";
import UpdatePhone from "./components/UpdatePhone";
import UpdateAssignedTo from "./components/UpdateAssignedTo";
import UpdateName from "./../../../../components/UserView/components/Forms/UpdateName";
import UpdateBackground from "./../../../../components/UserView/components/Forms/UpdateBackground";
import Release from "./components/Release";
import DeletePatient from "./components/DeletePatient";
import UserSpecific from "../../../../../../shared/components/UserSpecific";
import TransitionProgram from "./components/TransitionProgram/TransitionProgram";
import ChangePartner from "./components/ChangePartner";
import UpdateUserLanguage from "../../../../Invite/View/components/Actions/components/UpdateLanguage";

import "./Actions.css";

function Actions({
  onResetPasswordButton,
  user,
  onRelease,
  onDetransitionPeer,
  onDeletePatient,
  authenticatedUserType,
}) {
  const released =
    user.released && moment(user.released.date).isBefore(moment());
  const releasePending = user.released && !released;
  const canDetransition = authenticatedUserType === "admin" && user.had_prehab;
  const canResetPassword = authenticatedUserType === "admin";
  const isWorkersComp =
    user.clinician && user.clinician.external_service === "workerscomp";

  let releaseTitle = "Release Patient";
  if (released) {
    releaseTitle = "Released";
  } else if (releasePending) {
    releaseTitle = "Pending release";
  }

  return (
    <div className="user-actions">
      <ModalPanel name="updateLanguage">
        <UpdateUserLanguage
          userId={user.id}
          currentLanguage={user.about.lang}
          type="peer"
        />
      </ModalPanel>
      <ModalPanel contentHeight="400px" name="updateSurgeryDate">
        <UpdateSurgeryDate userId={user.id} isRegistered />
      </ModalPanel>

      <ModalPanel contentHeight="400px" name="updateBirthDate">
        <UpdateBirthDate userId={user.id} />
      </ModalPanel>

      <ModalPanel name="updateProcedureType">
        <UpdateProcedureType userId={user.id} />
      </ModalPanel>

      <ModalPanel name="updateSurgeryComplications">
        <UpdateSurgeryComplications userId={user.id} />
      </ModalPanel>

      <ModalPanel name="transitionProgram">
        <TransitionProgram
          userId={user.id}
          customerId={user.customer && user.customer.id}
        />
      </ModalPanel>

      <ModalPanel name="changePartner" width="644px">
        <ChangePartner
          userId={user.id}
          customerId={user.customer && user.customer.id}
        />
      </ModalPanel>

      <ModalPanel name="updateEmail">
        <UpdateEmail userId={user.id} />
      </ModalPanel>
      <ModalPanel name="addCareTeamMember" width="310px">
        <AddCareTeamMember userId={user.id} />
      </ModalPanel>
      {user.customer && (
        <ModalPanel name="updateOwner" width="310px">
          <UpdateOwner
            authenticatedUserType={authenticatedUserType}
            userId={user.id}
            customerId={user.customer.id}
          />
        </ModalPanel>
      )}
      <ModalPanel name="updatePhone">
        <UpdatePhone userId={user.id} phone={user.about.phone} />
      </ModalPanel>
      <ModalPanel name="updateName">
        <UpdateName name={user.about.name} userId={user.id} />
      </ModalPanel>

      <ModalPanel name="updateAssignedTo">
        <UpdateAssignedTo userId={user.id} />
      </ModalPanel>
      <ModalPanel topMargin="20px" name="updateBackground">
        <UpdateBackground userId={user.id} intakeData={user.intake_data} />
      </ModalPanel>

      {!!user.clinician && !isWorkersComp && (
        <btn className="btn btn-sm" onClick={onRelease}>
          {releaseTitle}
        </btn>
      )}
      <ModalPanel withCloseButton={false} name="release" width="292px">
        <Release userId={user.id} />
      </ModalPanel>
      <UserSpecific type={["admin", "owner", "partner_admin"]}>
        <DeletePatient patientId={user.id} patientType="peer" />
      </UserSpecific>
    </div>
  );
}

Actions.propTypes = {
  user: PropTypes.object.isRequired,
  authenticatedUserType: PropTypes.string.isRequired,
  onRelease: PropTypes.func.isRequired,
  onResetPasswordButton: PropTypes.func.isRequired,
  onDetransitionPeer: PropTypes.func.isRequired,
  onDeletePatient: PropTypes.func.isRequired,
};

export default Actions;
