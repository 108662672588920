import { connect } from "react-redux";

import CSVDownload from "./CSVDownload.jsx";

import { chartDataToCSV } from "./actions";
import { download } from "../../../../../utils/downloadData";

function mapStateToProps(ownProps) {
  return {};
}

function mapDispatchToProps(dispatch, ownProps) {
  const { chartData, xAxisFormat, xDescription, fileName } = ownProps;
  return {
    downloadCSV: () => {
      const csvData = chartDataToCSV(chartData, xAxisFormat, xDescription);
      download(csvData, `${fileName}.csv`, "text/csv");
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CSVDownload);
