import { connect } from "react-redux";

import { fleetingFormMeta } from "../../../../../shared/utils/form";

import CreateCustomerForm from "./CreateCustomerForm.jsx";

function mapStateToProps(state, { onSubmit, onCancel, customerTypes }) {
  return {
    initialValues: {},
    customerTypes,
    onSubmit,
    onCancel,
    message: fleetingFormMeta(state, "manageCustomer").message,
  };
}

export default connect(mapStateToProps)(CreateCustomerForm);
