import createAction from "redux-actions/es/createAction";

const prefixed = (val) => `Peer/Release::${val}`;

export const cancelRelease = createAction(
  prefixed("CANCEL_RELEASE"),
  (peerId) => peerId
);
export const didCancelRelease = createAction(
  prefixed("DID_CANCEL_RELEASE"),
  (peerId) => peerId
);

export const release = createAction(
  prefixed("RELEASE"),
  (peerId, date, type) => ({ peerId, type, date })
);
export const didRelease = createAction(
  prefixed("DID_RELEASE"),
  (peerId, date, type) => ({ peerId, type, date })
);
