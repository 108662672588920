import { connect } from "react-redux";
import { debounce } from "lodash";

import { query, updateQuery } from "../../shared/routes/query";
import { showPanel } from "../../shared/components/ModalPanel/actions";
import { shouldDisplayLoadingIndicator } from "../../shared/components/LoadingIndicator/reducer";
import { userType } from "../../shared/routes/Authentication/Login/reducer";
import { modalMessage } from "../../shared/components/ModalPanel/reducer";

import {
  requestResults,
  requestExternalFields,
  requestCustomers,
  resendInvite,
} from "./actions";
import { results, columns, count } from "./reducer";

import Team from "./Team.jsx";

function mapStateToProps(state) {
  return {
    searchResults: results(state),
    columns: columns(state),
    count: count(state),
    keyword: query(state, "keyword"),
    page: parseInt(query(state, "page"), 10) || 0,
    sortBy: query(state, "sortBy"),
    sortDirection: query(state, "sortDirection"),
    authenticatedUserType: userType(state),
    message: modalMessage(state),
    isLoading: shouldDisplayLoadingIndicator(state),
  };
}

function searchIfNeeded(keyword, sortBy, sortDirection, page, dispatch) {
  if (!keyword || keyword.length === 0) {
    return dispatch(requestResults("", sortBy, sortDirection, page)); // Empty string, else it gets send as null, which the server parses as null string
  } else if (keyword.length <= 2) {
    // No need to search on 2 letter keywords
    return null;
  }

  return dispatch(requestResults(keyword, sortBy, sortDirection, page));
}

const mapDispatchToProps = (dispatch) => ({
  requestResults: debounce(
    (keyword, sortBy, sortDirection, page) =>
      searchIfNeeded(keyword, sortBy, sortDirection, page, dispatch),
    300
  ),
  requestExternalFields: () => dispatch(requestExternalFields()),
  requestCustomers: () => dispatch(requestCustomers()),
  resendInvite: (partnerUserId) => dispatch(resendInvite(partnerUserId)),
  onSearchChange: (keyword) => dispatch(updateQuery({ keyword, page: 0 })),
  onSortChange: (sortBy, sortDirection) =>
    dispatch(updateQuery({ sortDirection, sortBy, page: 0 })),
  showPartnerUserForm: (partnerUserId) =>
    dispatch(showPanel(`managePartnerUser${partnerUserId || ""}`)),
  showResendInvite: (partnerUserId) =>
    dispatch(showPanel(`resendInvite${partnerUserId}`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
