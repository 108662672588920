import { handleActions } from "redux-actions";
import { get } from "lodash";
import {
  didAddCareTeamMemberForPeer,
  didUpdateCareTeamForPeer,
} from "./actions";

export default handleActions(
  {
    [didAddCareTeamMemberForPeer]: (state, action) => ({ ...state }),
    [didUpdateCareTeamForPeer]: (state, action) => {
      const { peerId } = action.payload;
      const careTeam = state[peerId].care_team;
      state[peerId].care_team = [...careTeam, action.payload.member];
      return Object.assign({}, state);
    },
  },
  {}
);

export const requiresCareTeamIds = (state, peerId) => {
  const careTeam =
    get(state, `ui.PeerView.${peerId}.care_team`) ||
    get(state, `ui.Invite.${peerId}.care_team`) ||
    [];
  return careTeam.map((a) => a.id);
};
