import { takeLatest, put } from "redux-saga/effects";
import { didUpdatePassword, requestPasswordReset } from "./actions";
import { submitFormToAPI } from "../../../../../utils/form";
import { changePasswordAPI } from "./api";

function* changePassword(action) {
  const { token, password } = action.payload;
  const act = submitFormToAPI(
    "resetPassword",
    changePasswordAPI,
    didUpdatePassword,
    token,
    password
  );
  yield put(act);
}

export default function* watchUpdatePasswordSaga() {
  return yield takeLatest(requestPasswordReset, changePassword);
}
