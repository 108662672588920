import { createAction } from "redux-actions";

const prefixed = (val) => `Peers/View/UpdateCareTeamMember::${val}`; // TODO: check/update val

export const updateCareTeamMember = createAction(
  prefixed("UPDATE_CARE_TEAM_MEMBER")
);
export const didUpdateCareTeamMember = createAction(
  prefixed("DID_UPDATE_CARE_TEAM_MEMBER")
);
