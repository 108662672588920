import LoadingIndicator from "./LoadingIndicator.js";

import reducer from "./reducer";

export default LoadingIndicator;

export const LoadingIndicatorRedux = {
  LOADING_INDICATOR: {
    reducer: {
      ui: {
        LoadingIndicator: reducer,
      },
    },
  },
};
