// Removes the "undefined / null" properties from an object
// Eg:
// {
//   foo: 1,
//   bar: null
// }
// becomes
// {
//   foo: 1
// }
// If empty string is true, it will remove those too (string will be trimmed before check)
export function compactObject(object, emptyString = true) {
  const newObject = {};
  for (const key in object) {
    const value = object[key];

    const isEmpty =
      value === undefined ||
      value === null ||
      (emptyString && typeof value === "string" && value.trim() === "");

    if (!isEmpty) {
      newObject[key] = value;
    }
  }
  return newObject;
}
