import { takeLatest, put, all } from "redux-saga/effects";

import {
  setFleetingMessage,
  submitFormToAPI,
} from "../../../../../../../../shared/utils/form";

import {
  release,
  didRelease,
  cancelRelease,
  didCancelRelease,
} from "./actions";

import { updateReleasedForPeer as updateReleaseForPeerAPI } from "./api";

const formName = "release";

function* submitReleaseUpdate(action) {
  const { peerId, date, type } = action.payload;

  yield put(
    submitFormToAPI(
      formName,
      updateReleaseForPeerAPI,
      didRelease(peerId, date, type),
      peerId,
      date,
      type
    )
  );
}

function* submitCancelReleaseUpdate(action) {
  const peerId = action.payload;
  yield put(
    submitFormToAPI(
      "release",
      updateReleaseForPeerAPI,
      didCancelRelease(peerId),
      peerId
    )
  );
}

export default function* watchReleaseUpdateSaga() {
  yield all([
    takeLatest(release, submitReleaseUpdate),
    takeLatest(cancelRelease, submitCancelReleaseUpdate),
    takeLatest(
      didRelease,
      setFleetingMessage(formName, { message: "Patient has been released." })
    ),
    takeLatest(
      didCancelRelease,
      setFleetingMessage(formName, {
        message: "Patient's release has been undone.",
      })
    ),
  ]);
}
