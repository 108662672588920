export const key = "auth";

/**
 * Store authorization info for later use.
 * @param {{email: string, token: string, userType: string, id: string, externalService: string, refreshToken: string}} auth authorization info.
 */
export function set(auth) {
  localStorage.setItem(key, JSON.stringify(auth));
}

/**
 * Trash authorization info.
 */
export function remove() {
  localStorage.removeItem(key);
}

/**
 * Get authorization info from storage.
 * @returns {{email: string, token: string, userType: string, id: string, externalService: string, refreshToken: string}} authorization info.
 */
export function get() {
  return JSON.parse(localStorage.getItem(key)) ?? {};
}

export default { set, remove, get, key };
