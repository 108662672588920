import { push } from "redux-little-router";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { setFleetingFormMeta } from "../../../utils/form";
import { successFulLogin } from "../Login/actions";
import { redirectToHome } from "../Login/sagas";

import { userRole } from "../../../../routes/Authentication";
import { handleApiError } from "../../../app/requestErrorHandler";
import {
  didFetchRegistrantEmail,
  requestRegistrantEmail,
  requestRegistration,
} from "./actions";
import {
  fetchRegistrantEmail as fetchRegistrantEmailAPI,
  postRegistration as postRegistrationAPI,
} from "./api";

function* handleRequestRegistration(action) {
  try {
    const { data } = yield call(postRegistrationAPI, action.payload);

    const {
      bearer_token,
      email,
      external_service,
      id,
      refresh_token,
      scope,
      userable_type,
    } = data;
    yield put(
      successFulLogin(
        bearer_token,
        refresh_token,
        email,
        userRole(userable_type, scope),
        id,
        external_service
      )
    );
    yield redirectToHome();
  } catch (e) {
    yield handleApiError(e);
  }
}

function* handleRequestRegistrantEmail(action) {
  try {
    const response = yield call(fetchRegistrantEmailAPI, action.payload);

    yield put(didFetchRegistrantEmail(response.data));
  } catch (e) {
    // redirect to signin with message that the token is invalid
    yield put(
      setFleetingFormMeta("login", {
        message: "We're sorry, the link you visited no longer works.",
      })
    );
    yield put(push("/signin"));
  }
}

export default function* watchRegistrationSaga() {
  yield all([
    takeLatest(requestRegistrantEmail, handleRequestRegistrantEmail),
    takeLatest(requestRegistration, handleRequestRegistration),
  ]);
}
