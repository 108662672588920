import { connect } from "react-redux";
import UpdateName from "./UpdateName.jsx";

import { fleetingFormMeta } from "../../../../../../../shared/utils/form";
import { updateNameForPeer } from "./actions";

function mapStateToProps(state, ownProps) {
  return {
    message: fleetingFormMeta(state, "updateName").message,
    initialValues: {
      fullname: ownProps.name,
    },
  };
}

function mapDispatchToProps(dispatch, { userId }) {
  return {
    onSubmit: ({ fullname }) => dispatch(updateNameForPeer(userId, fullname)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateName);
