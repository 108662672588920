import { handleActions } from "redux-actions";

import { setSurgeryDateMessage } from "./actions";

export const surgeryDateMessage = (state) =>
  state.ui.PeerView.surgeryDateMessage;

export default handleActions(
  {
    [setSurgeryDateMessage]: (state, action) => ({
      ...state,
      surgeryDateMessage: action.payload,
    }),
  },
  { surgeryDateMessage: null }
);
