import { clearSubmitErrors, startSubmit } from "redux-form";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { setSuccessMessageThenClose } from "../../shared/components/ModalPanel/sagas";
import { updateQuery } from "../../shared/routes/query";
import { setFleetingMessage, submitFormToAPI } from "../../shared/utils/form";
import { callAPI } from "../../shared/utils/sagas";

import { handleApiError } from "../../shared/app/requestErrorHandler";
import {
  createCoach,
  didCreateCoach,
  didFetchCoaches,
  didResendInvite,
  didUpdateCoach,
  requestCoaches,
  resendInvite,
  updateCoach,
} from "./actions";
import {
  createCoach as createCoachAPI,
  defaultSortBy,
  defaultSortDirection,
  fetchCoaches as fetchCoachesAPI,
  resendInvite as resendInviteAPI,
  updateCoach as updateCoachAPI,
} from "./api";

export default function* watchCoaches() {
  try {
    yield all([
      takeLatest(requestCoaches, fetchCoachesSaga),
      takeLatest(createCoach, submitCreateCoach),
      takeLatest(
        resendInvite,
        callAPI(resendInviteAPI, didResendInvite, false, false)
      ),
      takeLatest(updateCoach, submitUpdateCoach),
      takeLatest(
        didCreateCoach,
        showDoneAndClearSearch("manageCoach", "Coach added!")
      ),
      takeLatest(didResendInvite, setSuccessMessageThenClose("Invite resent")),
      takeLatest(
        didUpdateCoach,
        showDoneAndClearSearch("manageCoach", "Coach updated!")
      ),
    ]);
  } catch (error) {
    handleApiError(error);
  }
}

function* submitCreateCoach(action) {
  yield put(
    submitFormToAPI(
      "manageCoach",
      createCoachAPI,
      didCreateCoach(action.payload),
      action.payload
    )
  );
}

function* submitUpdateCoach(action) {
  yield put(clearSubmitErrors("manageCoach"));
  yield put(startSubmit("manageCoach"));

  yield put(
    submitFormToAPI(
      "manageCoach",
      updateCoachAPI,
      didUpdateCoach(action.payload),
      action.payload
    )
  );
}

function* fetchCoachesSaga(action) {
  try {
    const response = yield fetchCoachesAPI(action.payload);
    const count = response.headers["x-total-count"];
    yield put(didFetchCoaches(response.data, parseInt(count)));
  } catch (e) {
    yield handleApiError(e);
  }
}

function showDoneAndClearSearch(formId, message) {
  return function* () {
    yield call(setFleetingMessage(formId, { message }, 2500));
    yield clearSearch();
  };
}

function* clearSearch() {
  const newQuery = {
    keyword: "",
    sortBy: defaultSortBy,
    sortDirection: defaultSortDirection,
    page: 0,
  };
  const { keyword, sortBy, sortDirection, page } = newQuery;

  yield put(updateQuery(newQuery));
  yield put(requestCoaches(keyword, sortBy, sortDirection, page));
}
