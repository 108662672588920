import { put, select, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../../../../../shared/app/requestErrorHandler";
import { didRemoveCareTeamMember, removeCareTeamMember } from "./actions";
import { removeCareTeamMember as removeCareTeamMemberAPI } from "./api";
import { getCareTeamIds } from "./reducer";

function* submitRemoveCareTeamMember(action) {
  const peerId = action.payload.peerId;
  const idToRemove = action.payload.member.id;
  let ids = yield select(getCareTeamIds, action.payload.peerId);
  let idsToSend = ids.filter((id) => id !== idToRemove);
  try {
    const response = yield removeCareTeamMemberAPI(peerId, idsToSend);
    yield put(didRemoveCareTeamMember(action.payload));
  } catch (e) {
    yield handleApiError(e);
  }
}

export default function* watchRemoveCareTeamMemberSaga() {
  yield takeLatest(removeCareTeamMember, submitRemoveCareTeamMember);
}
