import { connect } from "react-redux";

import UpdateSurgeryDate from "./../../../../../../components/UserView/components/Forms/UpdateSurgeryDate";
import { userType } from "../../../../../../../../shared/routes/Authentication/Login/reducer";
import { requiresSurgeryDate, peerInfo } from "../../../../reducer";
import { surgeryDateMessage } from "./reducer";

import { updateSurgeryDate, setSurgeryDateMessage } from "./actions";

function mapStateToProps(state, { userId }) {
  const data = {
    surgeryDateRequired: requiresSurgeryDate(state, userId),
    surgeryDate: peerInfo(state, userId).surgery_date,
    userType: userType(state),
    programType: peerInfo(state, userId).program_kind,
    error: surgeryDateMessage(state),
  };
  return data;
}

function mapDispatchToProps(dispatch, { userId }) {
  return {
    setSurgeryDate: (date) => dispatch(updateSurgeryDate(userId, date)),
    clearSurgeryDateMessage: () => dispatch(setSurgeryDateMessage(null)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSurgeryDate);
