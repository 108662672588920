import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./CardInteractions.css";
import Preview from "./../../../../../Cards/components/Preview";
import ModalPanel from "./../../../../../../shared/components/ModalPanel";

class CardInteractions extends React.Component {
  componentDidUpdate(prevProps) {
    const { showCard, resetCard, cardId, modalPanelDisplay } = this.props;
    if (prevProps.cardId !== cardId && cardId !== null) {
      showCard(cardId);
    }
    if (modalPanelDisplay === null && prevProps.modalPanelDisplay !== null) {
      resetCard();
    }
  }

  renderCardRow = (cardInteraction) => {
    const { cardDescription, fetchCard, clinicianId } = this.props;
    const { completion, behavior_id, activity_name, behavior_v2_id } =
      cardInteraction;
    const isComplete = completion && completion.completed;
    const cardId = behavior_id || behavior_v2_id;
    return (
      <div className="card-row" key={cardId}>
        <ModalPanel name={`showCard-${cardId}`}>
          <Preview content={cardDescription} inModal />
        </ModalPanel>
        <div
          role="button"
          className={classNames({
            complete: isComplete,
            incomplete: !isComplete,
          })}
          onClick={() => isComplete && fetchCard(cardId, clinicianId)}
        />
        <p
          className={classNames({
            cardActivityComplete: isComplete,
            cardActivityIncomplete: !isComplete,
          })}
          onClick={() => fetchCard(cardId, clinicianId)}
        >
          {activity_name}
        </p>
      </div>
    );
  };

  render() {
    const { cardInteractions } = this.props;
    return (
      <div className="card-interactions">
        <h3 className="header-text">Program content interacted with</h3>
        <div
          className={classNames("card-container", {
            cardContainerWithCards: cardInteractions.length > 0,
            cardContainerEmpty: cardInteractions.length === 0,
          })}
        >
          {cardInteractions && cardInteractions.length === 0 ? (
            <p className="no-cards">** Patient has never used any cards **</p>
          ) : (
            cardInteractions && cardInteractions.map(this.renderCardRow)
          )}
        </div>
      </div>
    );
  }
}

CardInteractions.propTypes = {
  cardInteractions: PropTypes.array,
  clinicianId: PropTypes.string,
  modalPanelDisplay: PropTypes.string,
  fetchCard: PropTypes.func,
  cardDescription: PropTypes.string,
};

export default CardInteractions;
