import { connect } from "react-redux";

import { behaviorInfo } from "./reducer";
import CardChart from "./CardChart.jsx";
import { requestBehaviorInfo } from "./sagas";

function mapStateToProps(state, ownProps) {
  return {
    behaviorInfo: behaviorInfo(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    requestBehaviorInfo: () => dispatch(requestBehaviorInfo()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardChart);
