import { handleActions } from "redux-actions";
import { onDidShowDeletedChanged } from "./actions";

export const modulesList = (state) => state.ui.Modules.list || [];
export const showDeleted = (state) => state.ui.Modules.showDeleted || false;
export const modulesCount = (state) => Number(state.ui.Modules.count) || 0;

export default handleActions(
  {
    [onDidShowDeletedChanged]: (state, action) => ({
      ...state,
      showDeleted: action.payload,
    }),
  },
  {
    showDeleted: false,
  }
);
