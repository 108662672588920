export function isStrongPassword(password) {
  const strength = passwordStrength(password);
  return strength.map((i) => i.passes).reduce((l, r) => l && r);
}

export function passwordStrength(password) {
  const passes = [
    (v) => ({ message: "minimal 8 characters", passes: v && v.length >= 8 }),
    (v) => ({
      message: "uppercase characters",
      passes: v && v.toLowerCase() !== v,
    }),
    (v) => ({
      message: "lowercase characters",
      passes: v && v.toUpperCase() !== v,
    }),
    (v) => ({ message: "numbers", passes: v && /[0-9]/.test(v) }),
    (v) => ({
      message: "special characters (like !@# etc)",
      passes: v && /[^a-zA-Z0-9]/.test(v),
    }),
  ];

  return passes.map((pass) => pass(password));
}
