import { createAction } from "redux-actions";

const prefixed = (val) => `Peers/DetransitionPeer::${val}`;

export const detransitionPeer = createAction(
  prefixed("DETRANSITION_PEER"),
  (peerId) => peerId
);
export const didDetransitionPeer = createAction(
  prefixed("DID_DETRANSITION_PEER")
);
