import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import FormikMdField from "../../../../shared/components/FormFields/FormikMdField/FormikMdField";
import FormikSelectField from "../../../../shared/components/FormFields/FormikSelectField";
import FormikTextField from "../../../../shared/components/FormFields/FormikTextField";

import BottomBar from "../BottomBar";

const PhasesForm = ({ fetchAllModules, initialValues, onSubmit }) => {
  const [modules, setModules] = useState([]);

  useEffect(async () => {
    setModules(await fetchAllModules());
  }, []);

  const moduleOptions = modules.map((module) => ({
    value: module.id,
    label: module.name,
  }));

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {({ initialValues: { id, deleted } }) => {
        return (
          <div className="phase-edit">
            <h2>Edit Phase</h2>
            <Form>
              <div className="phase-form-fields">
                <FormikTextField label="Name" name="name" required />
                <FormikSelectField
                  label="Modules"
                  multi
                  name="modules"
                  options={moduleOptions}
                />
                <FormikMdField label="Description" name="description" />
                <FormikMdField label="Notes" name="notes" />
              </div>
              <BottomBar deleted={deleted} phaseId={id} />
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

const validation = Yup.object({
  name: Yup.string().required("Required"),
});

export default PhasesForm;
