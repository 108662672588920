import { takeLatest } from "redux-saga/effects";

import { callAPI } from "../../../../../../../../shared/utils/sagas";
import { setFleetingMessage } from "../../../../../../../../shared/utils/form";

import { detransitionPeer, didDetransitionPeer } from "./actions";
import { detransitionPeer as detransitionPeerAPI } from "./api";

export default function* watchUpdateDetransitionPeerSaga() {
  yield takeLatest(
    detransitionPeer,
    callAPI(detransitionPeerAPI, didDetransitionPeer)
  );
  yield takeLatest(
    didDetransitionPeer,
    setFleetingMessage("detransitionPeer", {
      message: "Peer has been moved back to Prehab.",
    })
  );
}
