import { createAction } from "redux-actions";
import { put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../../shared/app/requestErrorHandler";
import { behaviorInfo as behaviorInfoAPI } from "./api";

const prefixed = (val) => `Metrics/Card::${val}`;

export const requestBehaviorInfo = createAction(
  prefixed("RequestBehaviorInfo")
);
export const didFetchBehaviorInfo = createAction(
  prefixed("DidFetchBehaviorInfo")
);

function* fetchBehaviorInfo(action) {
  try {
    const response = yield behaviorInfoAPI();
    yield put(didFetchBehaviorInfo(response.data));
  } catch (e) {
    yield handleApiError(e);
  }
}

export function* watchCards() {
  yield takeLatest(requestBehaviorInfo, fetchBehaviorInfo);
}
