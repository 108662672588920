import { connect } from "react-redux";

import PhasesList from "./PhasesList.jsx";

import { updateQuery } from "../../../../shared/routes/query";
import { phasesList, showDeleted } from "./reducer";
import { onShowDeletedChange } from "./actions";

function mapStateToProps(state) {
  return {
    phasesList: phasesList(state),
    showDeleted: showDeleted(state),
  };
}

const dispatch = {
  updateFilter: (filter) => updateQuery({ filter }),
  onAddPhaseClicked: () => updateQuery({ create: true }),
  onShowDeletedChange: (deleted) => onShowDeletedChange(deleted),
};

export default connect(mapStateToProps, dispatch)(PhasesList);
