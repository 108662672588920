import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";

import { validateForm } from "../../../../../shared/utils/form";

const rules = {
  name: {
    presence: true,
  },
};

function UploadUsersForm({
  handleSubmit,
  onSubmit,
  onCancel,
  csv,
  onSelectFile,
  message,
}) {
  return (
    <form className="update-clinician-form">
      <p className="update-header">
        <span className="header-prefix">Upload Admins</span>
      </p>

      <p className="update-header">
        <span className="header-prefix">
          The csv file uploaded must have the following columns: EMAIL,
          FULLNAME, ROLE (Owner or PartnerAdmin) and GROUP (can have an empty
          value)
        </span>
      </p>

      <div>
        <input type="file" onChange={onSelectFile} />
        {csv && (
          <button type="submit" className="action-btn btn-danger">
            Upload File
          </button>
        )}
      </div>

      <input
        className="action-btn"
        type="submit"
        onClick={handleSubmit(onSubmit)}
        value="Upload"
      />
      <button className="action-btn red" onClick={onCancel}>
        Cancel
      </button>

      {message && <p className="message">{message}</p>}
    </form>
  );
}

UploadUsersForm.propTypes = {
  admin: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string,
  // redux-form
  handleSubmit: PropTypes.func.isRequired,
};

UploadUsersForm.defaultProps = {
  admin: null,
  programList: null,
  message: null,
};

export default reduxForm({
  form: "uploadUsers",
  validate: validateForm(rules),
  enableReinitialize: true,
})(UploadUsersForm);
