import reduceReducers from "reduce-reducers";
import { handleActions } from "redux-actions";
import { get } from "lodash";

import externalServiceReducer from "./components/ExternalService/reducer";
import updateSurgeryReducer from "./components/Actions/components/UpdateSurgeryDate/reducer";
import updateAssignedToReducer from "./components/Actions/components/UpdateAssignedTo/reducer";
import { didUpdateEmail } from "./components/Actions/components/UpdateEmail/actions";
import { didUpdatePhone } from "./components/Actions/components/UpdatePhone/actions";
import { didUpdateName } from "./components/Actions/components/UpdateName/actions";
import { didFetchInvite } from "./actions";
import { didUpdateLanguage } from "./components/Actions/components/UpdateLanguage/actions";

export const selectedInviteId = (state) => state.router.params.id;
export const invite = (state, inviteId) => state.ui.Invite.list[inviteId];
export const requiresSurgeryDate = (theInvite) =>
  theInvite.program_kind === "rehab";

export function inviteEvents(currentInvite) {
  if (!currentInvite) {
    return null;
  }
  return currentInvite.states;
}

// we are getting the owner and care team from the PeerView state tree even though this is the invite view...eh
export function careTeam(state, inviteId) {
  return get(state, `ui.PeerView.${inviteId}.care_team`);
}

export function owner(state, inviteId) {
  return get(state, `ui.PeerView.${inviteId}.owner`);
}

const inviteViewReducer = handleActions(
  {
    [didFetchInvite]: (state, action) => {
      const newInvite = action.payload;
      const list = state.list || {};
      list[newInvite.id] = newInvite;
      return { ...state, list };
    },
    [didUpdateEmail]: (state, action) => {
      const { inviteId, email } = action.payload;
      state.list[inviteId].email = email;
      return { ...state };
    },
    [didUpdatePhone]: (state, action) => {
      const { inviteId, phone } = action.payload;
      state.list[inviteId].phone = phone;
      return { ...state };
    },
    [didUpdateName]: (state, action) => {
      const { inviteId, fullname } = action.payload;
      state.list[inviteId].fullname = fullname;
      return { ...state };
    },
    [didUpdateLanguage]: (state, action) => {
      document.location.reload();
      // const { language, inviteId } = action.payload;
      // const newState = { ...state };
      // newState.list[inviteId].language = language;
      // return newState;
    },
  },
  {
    list: {},
  }
);

export default reduceReducers(
  inviteViewReducer,
  externalServiceReducer,
  updateSurgeryReducer,
  updateAssignedToReducer
);
