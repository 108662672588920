import { connect } from "react-redux";

import Search from "./Search.jsx";
import { searchResults } from "./reducer";

import { requestFindPeers } from "./actions";

function mapStateToProps(state) {
  return {
    peers: searchResults(state),
  };
}

const dispatch = {
  searchPeers: (keyword) => requestFindPeers(keyword),
};

export default connect(mapStateToProps, dispatch)(Search);
