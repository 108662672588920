import React from "react";
import PropTypes from "prop-types";
import { Link } from "redux-little-router";
import { Icon } from "react-fa";
import { Portal } from "react-portal";
import classNames from "classnames";

import Logo from "../../assets/bardavon-recovery-logo-white.png";
import LoadingIndicator from "./../../shared/components/LoadingIndicator";
import ErrorIndicator from "./../../shared/components/ErrorIndicator";

import "./NavigationBar.css";

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logoLoaded: false,
      partnerLogoLoaded: false,
    };
    this.renderNavigationItem = this.renderNavigationItem.bind(this);
  }

  renderNavigation(pages) {
    const { isAuthenticated, shouldDisplayLoadingIndicator } = this.props;
    if (!isAuthenticated) {
      return null;
    }
    return (
      <div key="nav-pages" className="nav-pages-container">
        <ul className="nav-pages">
          {pages.map(this.renderNavigationItem)}
          {shouldDisplayLoadingIndicator && (
            <LoadingIndicator className="main-loading-indicator" />
          )}
        </ul>
      </div>
    );
  }

  renderNavigationItem({ path, label, items, icon, id }, isChild = false) {
    const { selectedPage } = this.props;

    if (items) {
      return (
        <li
          key={path || label}
          className={classNames("nav-page", "withChild", {
            underlined: selectedPage === label,
          })}
        >
          <span
            className="parent"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {label}
            <span className="caret" />
          </span>
          <ul className="dropdown-menu navigation-dropdown">
            {items.map((item) => this.renderNavigationItem(item, true))}
          </ul>
        </li>
      );
    }
    return (
      <li
        key={path || label}
        className={classNames("nav-page", {
          isChild,
          underlined: selectedPage === label,
        })}
      >
        <Link href={path} id={id}>
          {icon ? (
            <div>
              {" "}
              <Icon name={icon} /> {label}{" "}
            </div>
          ) : (
            label
          )}
        </Link>
      </li>
    );
  }

  renderAccountItem({ path, label, items, icon }) {
    if (items) {
      return (
        <li key={path} className="withChild">
          <span className="parent">{label}</span>
          <ul className="submenu">{items.map(this.renderAccountItem)}</ul>
        </li>
      );
    }

    return (
      <li key={path}>
        <Link href={path}>
          {icon ? (
            <div>
              {" "}
              <Icon name={icon} /> {label}{" "}
            </div>
          ) : (
            label
          )}
        </Link>
      </li>
    );
  }

  renderAccountOptions() {
    const { isAuthenticated, userFullname, userEmail, accountOptions } =
      this.props;

    if (!isAuthenticated) {
      return null;
    }

    return (
      <div key="account-options" className="account-options-container">
        <span
          className="user-fullname"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {userFullname || userEmail}
          <span className="caret" />
        </span>
        <ul className="dropdown-menu account-options">
          {accountOptions.map(this.renderAccountItem)}
        </ul>
      </div>
    );
  }

  renderErrorIndicator() {
    const { shouldDisplayErrorIndicator, onHideError } = this.props;
    return (
      shouldDisplayErrorIndicator && (
        <ErrorIndicator shouldDisplayErrorIndicator onHideError={onHideError} />
      )
    );
  }

  renderHelpLink(user) {
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) {
      return null;
    }
    let href = "#";
    if (user === "sedgwick") {
      href = "https://www.peerwell.co/sedgwick-instructions";
    } else if (user === "core") {
      href = "https://www.peerwell.co/core-instructions";
    }
    if (href == "#") return null;
    return (
      <a key="help" className="help-link" href={href}>
        <Icon name="question-circle" className="question-circle" />{" "}
        <span className="help-text">Help</span>
      </a>
    );
  }

  render() {
    const {
      partnerLogo,
      pages,
      externalService,
      userType,
      isAuthenticated,
      hasUserInfo,
    } = this.props;
    const { logoLoaded: logoLoaded, partnerLogoLoaded } = this.state;

    const logo = Logo;
    const isStaff = ["partner_admin", "owner"].includes(userType);

    // this is for a more fluid ux -- if you don't wait for the images to load it looks super janky. this code would be better if the api returned the partner logos in the response
    let ready = false;
    // if logged in user is either an owner or partner admin, and the user info has come back so we know which customer they belong to, AND the customer has a partner logo to display
    if (isStaff && hasUserInfo && partnerLogo) {
      // then we wait until the partner logo loads before we render the nav bar
      ready = partnerLogoLoaded && logoLoaded;
    }
    // if the user isn't an owner or partner admin,
    // OR the user is one, and the user info has come back so we know which customer they belong to, and the customer doesn't have a partner logo to display
    else if (!isStaff || (isStaff && hasUserInfo && !partnerLogo)) {
      // then we only have to wait for the logo to load
      ready = logoLoaded;
    }

    return (
      <Portal node={document && document.getElementById("navigationBar")}>
        {isAuthenticated ? (
          <div className="navigation-bar">
            <Link href="/" className="logo">
              <img
                onLoad={() => this.setState({ logoLoaded: true })}
                src={logo}
                alt="Bardavon Recovery+"
              />
            </Link>
            <div className="nav-divider" />
            {partnerLogo && (
              <img
                className="partner-logo"
                src={partnerLogo}
                onLoad={() => this.setState({ partnerLogoLoaded: true })}
                alt="Partner Logo"
              />
            )}
            {/* TODO: get img from cdn maybe */}
            {ready && [
              this.renderNavigation(pages),
              this.renderHelpLink(externalService),
              this.renderAccountOptions(),
              this.renderErrorIndicator(),
            ]}
          </div>
        ) : (
          <div />
        )}
      </Portal>
    );
  }
}

NavigationBar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  selectedPage: PropTypes.string,
  externalService: PropTypes.string,
  pages: PropTypes.array.isRequired,
  onHideError: PropTypes.func.isRequired,
  shouldDisplayLoadingIndicator: PropTypes.bool.isRequired,
  shouldDisplayErrorIndicator: PropTypes.bool.isRequired,
};

export default NavigationBar;
