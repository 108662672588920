import React, { useEffect, useState } from "react";
import { useField } from "formik";
import SimpleMDE from "simplemde";
import "simplemde/dist/simplemde.min.css";

import "./FormikMdField.css";

const FormikMdField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const textareaId = props.id || props.name;

  const [mde, setMde] = useState();

  const mdeSetup = () => {
    const textarea = document.getElementById(textareaId);
    const mde = new SimpleMDE(mdeConfig(textarea));
    const onChangeMdeUpdateForm = () => {
      const newValue = mde.value();
      setValue(newValue);
    };
    mde.codemirror?.on("change", onChangeMdeUpdateForm);
    setMde(mde);
  };
  useEffect(mdeSetup, []);

  const mdeInitValue = () => {
    if (mde && field.value && !mde.value()) {
      mde.value(field.value);
    }
  };
  useEffect(mdeInitValue, [field.value]);

  return (
    <div className="formik-markdown-field" data-test={field.name}>
      <label htmlFor={textareaId}>
        {label}
        {props.required ? "*" : null}
      </label>
      <textarea id={textareaId} {...field} {...props} />
      {meta.error && <div className="error-message">{meta.error}</div>}
    </div>
  );
};

function mdeConfig(textarea) {
  return {
    element: textarea,
    renderingConfig: {
      codeSyntaxHighlighting: false,
    },
    spellChecker: false,
    status: false,
    styleSelectedText: false,
  };
}

export default FormikMdField;
