import { connect } from "react-redux";
import UpdateEmail from "./../../../../../../components/UserView/components/Forms/UpdateEmail";

import { updateEmailForPeer } from "./actions";

function mapDispatchToProps(dispatch, { userId }) {
  return {
    onSubmit: ({ email }) => dispatch(updateEmailForPeer(userId, email)),
  };
}

export default connect(null, mapDispatchToProps)(UpdateEmail);
