import moment from "moment";
import { useState } from "react";
import checkMark from "../../../../../../../assets/checkmark-no-bg.svg";
import ModalPanel from "../../../../../../../shared/components/ModalPanel";
import Preview from "../../../../../../Cards/components/Preview";
import { fetchCardContent, WorkerEvent, WorkerEventType } from "../api";
import styles from "./EventItem.module.css";

interface PropTypes {
  event: WorkerEvent;
  onAction: any;
}

const dateFormat = "MM/DD/YYYY";

const EventItem = ({ event, onAction }: PropTypes) => {
  const isViewed = event.type === WorkerEventType.CardViewed;
  const isCompleted = event.type === WorkerEventType.CardCompleted;
  const parsedDate = moment(event.createdAt).format(dateFormat);
  const [cardData, setCardData] = useState("");

  const openCard = async () => {
    // Fetch card data and populate modal
    const cardContentRes = await fetchCardContent(event.card.id);
    setCardData(cardContentRes.data.content);
    // Open the modal (redux state)
    onAction();
  };

  return (
    <li className={styles["event-item"]}>
      <ModalPanel
        name={`showEventCard-${event.createdAt}-${event.card.id}`}
        backgroundColor="#FAFAFA"
        borderRadius="15px"
        width={"1000px"}
      >
        <Preview content={cardData} inModal />
      </ModalPanel>
      <button
        className={styles["event-item-button"]}
        onClick={() => openCard()}
      >
        <div
          className={`${styles["checkmark"]} ${
            isCompleted ? styles["completed"] : ""
          }`}
        >
          <img src={checkMark} />
        </div>
        <div className={styles["content"]}>
          <h3
            className={`${styles["title"]} ${
              isCompleted ? styles["completed"] : ""
            }`}
          >
            {event.card.name}
          </h3>
          {isCompleted ? (
            <p className={styles["subtext"]}>Completed on {parsedDate}</p>
          ) : null}
          {isViewed ? (
            <p className={styles["subtext"]}>Opened on {parsedDate}</p>
          ) : null}
        </div>
      </button>
    </li>
  );
};

export default EventItem;
