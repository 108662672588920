import { connect } from "react-redux";

import ErrorIndicator from "./ErrorIndicator.jsx";
import { error, shouldDisplayErrorIndicator } from "./reducer";

function mapStateToProps(state) {
  return {
    shouldDisplayErrorIndicator: shouldDisplayErrorIndicator(state),
    error: error(state),
  };
}

export default connect(mapStateToProps)(ErrorIndicator);
