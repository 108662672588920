import { connect } from "react-redux";

import Metric from "./Metric.jsx";

import { query, gotoQuery } from "../../../shared/routes/query";
import { userType } from "../../../shared/routes/Authentication/Login/reducer";

function mapStateToProps(state, ownProps) {
  return {
    query: query(state),
    showFilter: userType(state) !== "demo",
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    gotoQuery,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Metric);
