import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../../../../../shared/app/requestErrorHandler";
import {
  didGetProcedureTypes,
  didUpdateProcedureType,
  requestProcedureTypes,
  setUpdateProcedureTypeMessage,
  updateProcedureType,
} from "./actions";
import {
  availableProcedureTypes as availableProcedureTypesAPI,
  updateProcedureType as updateProcedureTypeAPI,
} from "./api";

function* updateSurgery(action) {
  yield put(setUpdateProcedureTypeMessage(null));
  const { peerId, procedureType } = action.payload;
  try {
    yield updateProcedureTypeAPI(peerId, procedureType);
    yield put(setUpdateProcedureTypeMessage("Surgery type has been updated"));
    yield put(didUpdateProcedureType(peerId, procedureType));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* getProcedureTypes(action) {
  const peerId = action.payload;
  try {
    const response = yield availableProcedureTypesAPI(peerId);
    yield put(didGetProcedureTypes(peerId, response.data));
  } catch (e) {
    yield handleApiError(e);
  }
}

export default function* watchUpdateProcedureTypeSaga() {
  yield all([
    takeLatest(requestProcedureTypes, getProcedureTypes),
    takeLatest(updateProcedureType, updateSurgery),
  ]);
}
