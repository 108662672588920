import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import {
  asyncValidation,
  validateForm,
} from "../../../../../../../shared/utils/form";

import FormGroupField from "./../../../../../../../shared/components/FormFields/FormGroupField";

import "./UpdatePhone.css";

const rules = {
  phone: {
    presence: true,
  },
};

function UpdatePhone({ onSubmit, handleSubmit, message }) {
  return (
    <div className="update-phone-form">
      <h1>Update Phone</h1>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="phone"
          component={FormGroupField}
          label="Phone"
          type="phone"
        />
        <input type="submit" className="btn btn-primary" value="Update" />
        {message ? <p className="form-message">{message}</p> : null}
      </form>
    </div>
  );
}

UpdatePhone.propTypes = {
  userId: PropTypes.string.isRequired,
  message: PropTypes.string,

  // Automatically added
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

UpdatePhone.defaultProps = {
  message: null,
};

export default reduxForm({
  form: "updatePhone",
  validate: validateForm(rules),
  asyncValidate: asyncValidation({ phone: "phone" }),
})(UpdatePhone);
