import reduceReducers from "reduce-reducers";
import { handleActions } from "redux-actions";
import { get } from "lodash";
import { query } from "../../shared/routes/query";

import { didFetchPhase, didFetchPhasesList } from "./actions";
import phasesFormReducer from "./components/PhasesForm/reducer";
import bottomBarReducer from "./components/BottomBar/reducer";
import phasesListReducer from "./components/PhasesList/reducer";

export const phase = (state, id) => get(state.ui.Phases, `phases.${id}`);
export const newPhase = (state) =>
  get(state.ui.Phases, "newPhase", { name: "", description: "" });
export const selectedPhaseId = (state) => query(state, "edit");
export const selectedPhase = (state) => phase(state, selectedPhaseId(state));

export const phasesReducer = reduceReducers(
  bottomBarReducer,
  phasesFormReducer,
  phasesListReducer,
  handleActions(
    {
      [didFetchPhase]: (state, action) => {
        const phases = { ...state.phases } || {};
        const payloadPhase = action.payload;
        phases[payloadPhase.id] = payloadPhase;
        return { ...state, phases };
      },
      [didFetchPhasesList]: (state, action) => ({
        ...state,
        list: action.payload.phasesList,
        count: action.payload.count,
      }),
    },
    {
      pending: null,
      phases: {},
    }
  )
);
