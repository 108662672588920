import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import LineChart from "../../../../../../../shared/components/Charts/LineChart/index";
import { sum } from "../../../../../../../shared/components/Charts/BaseChart/operations";

// Converts the profile chart data format to the metric data so I can use the standard Linechart as base
class ChartWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.extraHintValues = this.extraHintValues.bind(this);
  }

  convertMetricData(metrics) {
    return metrics.map((metric) => {
      return {
        end: metric.timestamp,
        start: metric.timestamp,
        grouped_by_id: null,
        meta_id: null,
        grouped_by: metric.type || null,
        data: {
          value: metric.value,
        },
      };
    });
  }

  getChildContext() {
    const { metric } = this.props;
    if (metric.metrics) {
      return {
        metricData: { profile_info: this.convertMetricData(metric.metrics) },
      };
    }
  }

  extraHintValues(value, lineData) {
    const { user } = this.props;
    const registrationDate = user.created_at;
    const surgeryDate = user.surgery_date;
    // const startOfProgram = user.start_date_of_current_program

    const currentDate = value.x;

    const prefix = currentDate <= surgeryDate ? "PreHab" : "ReHab";
    const since = currentDate <= surgeryDate ? registrationDate : surgeryDate;

    const diff = moment(currentDate).diff(since, "day");
    return { title: `${prefix} days`, value: diff + 1 };
  }

  render() {
    const { metric, title } = this.props;

    const yRange = [metric.range.y.start.value, metric.range.y.end.value];
    const xRange = [metric.range.x.start.value, metric.range.x.end.value];

    // Can't use API range for dates.. that is specific for the way charts in app are done

    let goal = null;
    if (metric.goal) {
      goal = [
        {
          description: "GOAL",
          value: metric.goal.value,
        },
      ];
    }

    // // Don't render surgery date if in future
    // // Todo improve this
    let labels = (metric.range.x.labels || []).map((label) => ({
      value: label.value,
      description: label.formatted,
    }));

    const { metrics } = metric;
    if (labels && labels.length > 0 && metrics) {
      if (surgeryDateInFuture(labels, metrics)) {
        labels = [];
      }
    }

    return (
      <div>
        <h2>{title}</h2>
        <LineChart
          draw={[
            {
              metricType: "profile_info",
              operation: sum("start", "data.value"),
              description: metric.info.title,
              label: metric.info.title,
              display: "individually",
            },
          ]}
          yDescription={metric.info.title}
          fileName={metric.info.title}
          yDomain={yRange}
          xDomain={xRange}
          xLabelAngle={-90}
          margin={{ bottom: 100 }}
          goals={goal}
          labels={labels}
          yTickValues={metric.range.y_ticks}
          xTickValues={metric.range.x_ticks}
          extraHintValues={this.extraHintValues}
        />
      </div>
    );
  }
}

ChartWrapper.childContextTypes = {
  metricData: PropTypes.object,
};

ChartWrapper.propTypes = {
  metric: PropTypes.object.isRequired,
  user: PropTypes.object,
};

ChartWrapper.defaultProps = {
  user: null,
};

export default ChartWrapper;

function surgeryDateInFuture(labels, metrics) {
  return metrics.length > 0 && labels[0].value >= lastDay(metrics);

  function lastDay(metrics) {
    metrics[metrics.length - 1].timestamp;
  }
}
