import { success } from "react-notification-system-redux";
import { put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../shared/app/requestErrorHandler";
import {
  deletePhase,
  didDeletePhase,
  didRestorePhase,
  restorePhase,
} from "./actions";
import {
  deletePhase as deletePhaseAPI,
  restorePhase as restorePhaseAPI,
} from "./api";

function* notifyUser() {
  yield put(success({ message: "The changes have been saved." }));
}

function* deletePhaseFunctionSaga(action) {
  try {
    yield deletePhaseAPI(action.payload);
    yield put(didDeletePhase(action.payload));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* restorePhaseFunctionSaga(action) {
  try {
    yield restorePhaseAPI(action.payload);
    yield put(didRestorePhase(action.payload));
  } catch (e) {
    yield handleApiError(e);
  }
}

export default function* watchBottomBarSaga() {
  yield takeLatest(deletePhase, deletePhaseFunctionSaga);
  yield takeLatest(restorePhase, restorePhaseFunctionSaga);
  yield takeLatest(didDeletePhase, notifyUser);
}
