import { handleActions } from "redux-actions";
import { get, sortBy } from "lodash";

import { query } from "../../../shared/routes/query";
import { didFetchAllUsers, didUpdateUser } from "./actions";

export function allUsers(state) {
  return get(state, "ui.ManageCareTeamUsers.users");
}

export function selectedUser(state) {
  const selectedId = query(state, "userId");
  const users = state.ui.ManageCareTeamUsers.users;

  if (!users || !selectedId) return null;
  return users.find((user) => user.id === selectedId);
}

export function userInitialValues(state) {
  const user = selectedUser(state);

  if (!user) return null;

  return {
    fullname: user.fullname,
    email: user.email,
    blocked: user.blocked,
  };
}

export const ManageCareTeamUsersReducer = handleActions(
  {
    [didFetchAllUsers]: (state, action) => ({
      ...state,
      users: action.payload.results,
    }),
    [didUpdateUser]: (state, action) => ({ ...state, user: action.payload }),
    // [clearUserData]: state => ({ ...state, user: null })
  },
  {
    user: null,
  }
);
