import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { singularize, pluralize } from "inflection";

import "./MultiInputField.css";

// This component works with redux-form and allows you to have an input field which stores multiple inputs and they get stored in redux-form state as an array
// For field-level validation on the input itself you can pass a regex (prop key: 'regexValidator')
class MultiInputField extends React.Component {
  constructor(props) {
    super(props);

    let initialValues = [];
    if (props.meta.initial) {
      // make sure it is type array
      initialValues = [].concat(props.meta.initial);
    }
    // inititialize redux-form state
    props.input.onChange(initialValues);

    this.state = {
      multiInput: initialValues,
      input: "",
      message: null,
      error: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  addItem() {
    const multiInput = [...this.state.multiInput, this.state.input];

    this.setState({ multiInput, input: "", message: null, error: null });
    // redux-form's onChange only knows about the multi-input array, not the "primitive" input
    this.props.input.onChange(multiInput);
  }

  removeItem(item) {
    const multiInput = this.state.multiInput.filter((el) => el !== item);

    this.setState({ multiInput });
    this.props.input.onChange(multiInput);
  }

  handleChange(e) {
    const { regexValidator } = this.props;
    const value = e.target.value.toLowerCase();

    // since redux-form is hooked up to the multi-input array, it can't do field-level validation on the primitive input, so we do it here
    let error = null;
    let message = null;

    // duplicates are invalid
    if (this.state.multiInput.find((input) => input === value)) {
      error = "Already in the list";
    }

    // doesn't pass regex validator
    if (regexValidator && value.length > 0 && !regexValidator.test(value)) {
      error = "Does not match required format";
    }

    if (value.length > 0 && !error) {
      message = "Click 'Add' to add this to the list!";
    }

    this.setState({ input: value, error, message });
  }

  renderItem(item) {
    return (
      <li
        key={item}
        className="multi-item"
        role="button"
        onClick={() => this.removeItem(item)}
      >
        {item}
        <span className="remove">Remove X</span>
      </li>
    );
  }

  renderFallbackPlaceholder() {
    const { multiInput } = this.state;
    const { label } = this.props;

    const isSingular = multiInput.length === 1;
    const placeholderItem = isSingular
      ? singularize(label.toLowerCase())
      : pluralize(label.toLowerCase());

    return `${multiInput.length} ${placeholderItem} entered -- add more here`;
  }

  render() {
    const { className, name, label, placeholder } = this.props;
    const { multiInput, input, error, message } = this.state;

    const isDisabled = error || input.length === 0;

    return (
      <div className={classNames("multi-input-field form-group", className)}>
        <div className="label-message-group">
          <label htmlFor={name}>{label}</label>
          {message && <span className="message">{message}</span>}
          {error && <span className="error">{error}</span>}
        </div>

        <div className="multi-input-group">
          <input
            className="multi-input"
            value={input}
            placeholder={placeholder || this.renderFallbackPlaceholder()}
            onChange={this.handleChange}
          />
          <button
            className={classNames("action-btn", { isDisabled })}
            onClick={this.addItem}
            disabled={isDisabled}
          >
            Add
          </button>
        </div>

        <ul className="submitted-list">{multiInput.map(this.renderItem)}</ul>
      </div>
    );
  }
}

MultiInputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  regexValidator: PropTypes.object,
  // redux-form
  meta: PropTypes.object,
  input: PropTypes.object,
};

MultiInputField.defaultProps = {
  className: "",
  placeholder: "",
  regexValidator: null,
};

export default MultiInputField;
