import watchRequestUpdatePasswordSaga from "./routes/RequestUpdatePassword/sagas";
import watchUpdatePasswordSaga from "./routes/UpdatePassword/sagas";

import RequestUpdatePassword from "./routes/RequestUpdatePassword";
import ResetPassword from "./routes/UpdatePassword";
import { authenticationDenied, authenticationRequired } from "../Login/sagas";
import ForceResetPassword from "./routes/ForceResetPassword";

export default {
  "/forgot-password": {
    saga: watchRequestUpdatePasswordSaga,
    component: RequestUpdatePassword,
    onEnter: authenticationDenied,
  },
  "/reset-password/:token": {
    saga: watchUpdatePasswordSaga,
    component: ResetPassword,
    onEnter: authenticationDenied,
  },
  "/force-reset-password": {
    component: ForceResetPassword, // Saga not needed is the same as the url above
    onEnter: authenticationRequired,
  },
};
