import { createAction } from "redux-actions";

const prefixed = (val) => `MANAGE/CUSTOMER::${val}`;

export const requestCustomers = createAction(prefixed("REQUEST_CUSTOMERS"));
export const didFetchCustomers = createAction(
  prefixed("DID_FETCH_CUSTOMERS"),
  (customers) => customers
);

export const requestCustomerTypes = createAction(
  prefixed("REQUEST_CUSTOMER_TYPES")
);
export const didFetchCustomerTypes = createAction(
  prefixed("DID_FETCH_CUSTOMER_TYPES")
);

export const requestCustomerDetails = createAction(
  prefixed("REQUEST_CUSTOMER_DETAILS")
);
export const didFetchCustomerDetails = createAction(
  prefixed("DID_FETCH_CUSTOMER_DETAILS")
);

export const requestCustomerSelectFields = createAction(
  prefixed("REQUEST_CUSTOMER_SELECT_FIELDS")
);
export const didFetchCustomerSelectFields = createAction(
  prefixed("DID_FETCH_CUSTOMER_SELECT_FIELDS")
);

export const requestCustomerSelectField = createAction(
  prefixed("REQUEST_CUSTOMER_SELECT_FIELD")
);
export const didFetchCustomerSelectField = createAction(
  prefixed("DID_FETCH_CUSTOMER_SELECT_FIELD")
);

export const createCustomer = createAction(prefixed("CREATE_CUSTOMER"));
export const didCreateCustomer = createAction(prefixed("DID_CREATE_CUSTOMER"));

export const updateCustomer = createAction(prefixed("UPDATE_CUSTOMER"));
export const didUpdateCustomer = createAction(prefixed("DID_UPDATE_CUSTOMER"));

export const createFieldValue = createAction(prefixed("CREATE_FIELD_VALUE"));
export const didCreateFieldValue = createAction(
  prefixed("DID_CREATE_FIELD_VALUE")
);

export const updateFieldValue = createAction(prefixed("UPDATE_FIELD_VALUE"));
export const didUpdateFieldValue = createAction(
  prefixed("DID_UPDATE_FIELD_VALUE")
);

export const clearCustomerData = createAction(prefixed("CLEAR_CUSTOMER_DATA"));
export const clearAllData = createAction(prefixed("CLEAR_ALL_DATA"));
