import React from "react";
import PropTypes from "prop-types";
import SplitPane from "react-split-pane";
import { flatten, sortBy } from "lodash";

import Basic from "./components/Basic";

import ProgramProgress from "./components/ProgramProgress";
import Physical from "./components/Physical";
import Assessments from "./components/Assessments";
import CardInteractions from "./components/CardInteractions";
import HomeAssessment from "./components/HomeAssessment";
import HazardDetails from "./components/HazardDetails";
import Background from "./components/Background";
import Engagement from "./components/Engagement";

import BreadCrumbBar from "../../../../components/BreadCrumbBar/BreadCrumbBar";

import "./UserView.css";
import "./SplitPane.css";

import { eventShape } from "./propTypes";

// This is shared with the Peer View and the Invite view, so they look the same,
// but some parts are not...
class UserView extends React.Component {
  componentDidMount() {
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const {
      selectedId,
      hazardPictures,
      programParts,
      user,
      fetchHazardImages,
    } = this.props;

    if (
      (nextProps.selectedId && nextProps.selectedId !== selectedId) ||
      (programParts && nextProps.programParts.length > programParts.length)
    ) {
      this.fetchData(nextProps);
    }

    if (nextProps.hazardPictures && !hazardPictures && nextProps.user) {
      if (nextProps.hazardPictures.length > 0) {
        fetchHazardImages(user.id, flatten(nextProps.hazardPictures));
      }
    }
  }

  fetchData(props) {
    const {
      selectedId,
      fetchData,
      fetchHazardPictures,
      authenticatedUserType,
      userType,
      fetchCareTeam,
      fetchOwner,
      fetchProgramParts,
    } = props;
    fetchData(selectedId);
    if (["admin", "partner_admin", "owner"].includes(authenticatedUserType)) {
      fetchCareTeam(selectedId);
      fetchOwner(selectedId);
    }
    if (userType === "peer" && authenticatedUserType === "admin") {
      fetchProgramParts(selectedId);
      fetchHazardPictures(selectedId); // Currently only admin userType can fetch
    }
  }

  renderSidebar() {
    const {
      careTeam,
      owner,
      user,
      userType,
      selectedId,
      deviceInfo,
      actionComponent,
      externalService,
      externalServiceSettings,
      externalServiceComponent,
    } = this.props;

    return (
      <div>
        <Basic
          externalService={externalService}
          externalServiceComponent={externalServiceComponent}
          externalServiceSettings={externalServiceSettings}
          user={user}
          careTeam={careTeam}
          owner={owner}
          userType={userType}
          deviceInfo={deviceInfo}
          foo={Math.random()}
        />
        {actionComponent &&
          React.createElement(actionComponent, { user, selectedId })}
      </div>
    );
  }

  renderPageContent() {
    const {
      profile,
      user,
      cardInteractions,
      hazardScore,
      hazardPictures,
      hazardImages,
      authenticatedUserType,
      areHazardImagesFetched,
    } = this.props;
    if (!profile) {
      return null;
    }

    const engagementScore =
      (profile.engagement && profile.engagement.score) || 0; // 0 is for unregistered users
    const canShowHomeAssessment = authenticatedUserType === "admin";

    return (
      <div>
        <Background user={user} profile={profile} />
        <div className="engagement-card-interaction-group">
          <div className="child-1">
            <Engagement score={engagementScore} />
          </div>
          {cardInteractions && (
            <div className="child-2">
              <CardInteractions
                clinicianId={user.clinician.id}
                cardInteractions={cardInteractions}
              />
            </div>
          )}
        </div>
        <ProgramProgress
          completion={profile.completion}
          countdown={profile.countdown}
        />
        {profile && <Physical metrics={profile.metrics} user={user} />}
        {canShowHomeAssessment && areHazardImagesFetched && (
          <HomeAssessment
            user={user}
            hazardScore={hazardScore}
            hazardPictures={hazardPictures}
            hazardImages={hazardImages}
          />
        )}
        <Assessments assessments={profile.assessments} />
      </div>
    );
  }

  renderHazardDetails() {
    const {
      hazardScore,
      hazardPictures,
      hazardImages,
      areHazardImagesFetched,
      authenticatedUserType,
    } = this.props;
    return (
      <div>
        {areHazardImagesFetched && (
          <HazardDetails
            hazardScore={hazardScore}
            auth={authenticatedUserType}
            hazardImages={hazardImages}
            hazardPictures={hazardPictures}
          />
        )}
      </div>
    );
  }

  renderStates() {
    // Was called events... so
    let { events } = this.props;

    events = sortBy(events, (e) => -e.weight);

    if (!events || events.length === 0) {
      return null;
    }
    return (
      <ul className="user-events">
        {events.map((event) => (
          // bad to use a random number for the key but there is no way to make a unique identifier with the 'event' prop's properties
          <li key={Math.random()} className={event.effect}>
            {event.message}
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const {
      user,
      goListPatients,
      sidebarWidth,
      storeSidebarWidth,
      hazardDetails,
    } = this.props;
    if (!user) {
      return null;
    }
    return (
      <div className="page-container with-sidebar">
        {!hazardDetails && (
          <BreadCrumbBar
            path={[
              { label: "All Users", href: goListPatients },
              { label: user.about.name },
            ]}
          />
        )}
        {hazardDetails && (
          <BreadCrumbBar
            path={[
              { label: "All Users", href: goListPatients },
              { label: user.about.name, href: `/peers/view/${user.id}` },
              { label: "Hazard Detection" },
            ]}
          />
        )}
        <SplitPane
          split="vertical"
          minSize={200}
          defaultSize={sidebarWidth}
          maxSize={400}
          onChange={storeSidebarWidth}
        >
          <div className="sidebar">{this.renderSidebar()}</div>

          <div className="page-content user-view">
            {!hazardDetails && this.renderStates()}
            <div className="limit-width">
              {!hazardDetails && this.renderPageContent()}
              {hazardDetails && this.renderHazardDetails()}
            </div>
          </div>
        </SplitPane>
      </div>
    );
  }
}

UserView.propTypes = {
  userType: PropTypes.oneOf(["peer", "invite"]).isRequired,
  user: PropTypes.object, // Determine shape -- for peer, peer data, for invite, invite data
  profile: PropTypes.object, // Determine shape, for peer true profile data, for invite stub
  cardInteractions: PropTypes.array,
  selectedId: PropTypes.string.isRequired,
  authenticatedUserType: PropTypes.string,
  fetchData: PropTypes.func.isRequired,
  fetchHazardPictures: PropTypes.func,
  fetchCareTeam: PropTypes.func,
  fetchOwner: PropTypes.func,
  deviceInfo: PropTypes.object,
  hazardDetails: PropTypes.bool,
  hazardScore: PropTypes.arrayOf(PropTypes.number),
  hazardPictures: PropTypes.arrayOf(PropTypes.object),
  areHazardImagesFetched: PropTypes.bool,
  externalService: PropTypes.string,
  externalServiceSettings: PropTypes.array,
  extraFields: PropTypes.any, // Todo: What?
  events: PropTypes.arrayOf(PropTypes.shape(eventShape)),
  goListPatients: PropTypes.func.isRequired,

  // Is different based on if this is a peer view or invite view, (part of it is shared) but the connected dispatch values are not
  externalServiceComponent: PropTypes.any.isRequired,
  actionComponent: PropTypes.any,

  storeSidebarWidth: PropTypes.func.isRequired,
  sidebarWidth: PropTypes.number.isRequired,
};

UserView.defaultProps = {
  user: null,
  profile: null,
  externalService: null,
  hazardScore: null,
  hazardPictures: null,
  externalServiceSettings: null,
  warnings: [],
  actionComponent: null,
  areHazardImagesFetched: false,
};

export default UserView;
