import { connect } from "react-redux";
import ResetPassword from "./ResetPassword.jsx";

import { updatePasswordForPeer } from "./actions";
import { fleetingFormMeta } from "../../../../../../../../shared/utils/form";

function mapStateToProps(state) {
  return {
    message: fleetingFormMeta(state, "resetPassword").message,
  };
}

function mapDispatchToProps(dispatch, { userId }) {
  return {
    onSubmit: ({ password }) =>
      dispatch(updatePasswordForPeer(userId, password)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
