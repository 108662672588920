// It's pretty much the app layout, but with an extra div and stylesheet
import React from "react";
import PropTypes from "prop-types";
import BodyClassName from "react-body-classname";
import classNames from "classnames";

import NavigationBar from "./../../components/NavigationBar";

// Order is important here don't change
import "bootstrap/dist/css/bootstrap.css";
import "normalize.css/normalize.css";
import "./../../shared/layouts/AppLayout/AppLayout.css";
import "./DashboardLayout.css";
import "./PrintLayout.css";

function DashboardLayout({
  children,
  authenticatedUserType,
  route,
  welcomeToTheDangerZone,
}) {
  return (
    <BodyClassName
      className={classNames(
        "dashboard-layout",
        `dashboard-as-${styleForRole(authenticatedUserType)}`,
        `dashboard-in-${route}`,
        { "welcome-to-the-danger-zone": welcomeToTheDangerZone }
      )}
    >
      <div className="content-container">
        <NavigationBar />
        {children}
      </div>
    </BodyClassName>
  );
}

function styleForRole(role) {
  if (["owner", "care_team_user"].includes(role)) {
    return role;
  }
  return "default";
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.string,
  authenticatedUserType: PropTypes.string,
  welcomeToTheDangerZone: PropTypes.bool.isRequired,
};

DashboardLayout.defaultProps = {
  authenticatedUserType: "none",
  route: null,
};

export default DashboardLayout;
