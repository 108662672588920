import { connect } from "react-redux";

import ManagePrograms from "./ManagePrograms.jsx";

function mapStateToProps(state) {
  return {};
}

const dispatch = {};

export default connect(mapStateToProps, dispatch)(ManagePrograms);
