import React from "react";
import PropTypes from "prop-types";

import PickDate from "../../../../../../shared/components/PeerProfile/PickDate";

function UpdateBirthDate({
  birthDate,
  setBirthDate,
  clearBirthDateMessage,
  error,
}) {
  return (
    <div>
      <h1>Update birthdate</h1>
      <PickDate
        name={"Birth"}
        pickDate={birthDate}
        setPickDate={setBirthDate}
        pickDateRequired={false}
        clearMessage={clearBirthDateMessage}
        error={error}
      />
    </div>
  );
}

UpdateBirthDate.propTypes = {
  userId: PropTypes.string.isRequired,

  // Automatically added
  birthDate: PropTypes.number.isRequired,
  setBirthDate: PropTypes.func.isRequired,
  birthDateRequired: PropTypes.bool.isRequired,
  clearBirthDateMessage: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default UpdateBirthDate;
