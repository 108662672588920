import { connect } from "react-redux";

import DetailsView from "./../../../User/components/UserView";
import ExternalService from "./components/ExternalService";
import Actions from "./components/Actions";

import {
  careTeam,
  owner,
  selectedPeerId,
  peerInfo,
  peerProfile,
  peerCardInteractions,
  peerStates,
  peerDeviceInfo,
  peerHazardScore,
  peerHazardPictures,
  peerHazardImages,
  peerProgramParts,
  areHazardImagesFetched,
  intoHazardDetails,
  workerInfo,
  workerEngagementScore,
} from "./reducer";
import {
  requestPeer,
  requestPeerProfile,
  requestCardInteractions,
  requestPeerDeviceInfo,
  requestPeerHazardPictures,
  requestCareTeam,
  requestOwner,
  requestPeerHazardImage,
  requestPeerProgramParts,
  requestWorker,
  requestWorkerEngagement,
} from "./sagas"; // Should have been in actions...
import { previousPage } from "../../../../shared/routes/query";

import { WORKER_ROUTE_NAME } from "./index";

function mapStateToProps(state) {
  const userId = selectedPeerId(state);

  let user, engagementScore;
  const isWorker = window.location.href.includes(WORKER_ROUTE_NAME);
  if (isWorker) {
    user = workerInfo(state, userId);
    engagementScore = workerEngagementScore(state, userId) || 0;
  } else {
    user = peerInfo(state, userId);
    engagementScore = 0;
  }

  return {
    userType: "peer",
    user: user,
    engagementScore: engagementScore,
    profile: peerProfile(state, userId),
    cardInteractions: peerCardInteractions(state, userId),
    careTeam: careTeam(state, userId),
    owner: owner(state, userId),
    selectedId: userId,
    programParts: peerProgramParts(state, userId),
    hazardDetails: intoHazardDetails(state),
    deviceInfo: peerDeviceInfo(state, userId),
    hazardScore: peerHazardScore(state, userId),
    hazardPictures: peerHazardPictures(state, userId),
    hazardImages: peerHazardImages(state, userId),
    areHazardImagesFetched: areHazardImagesFetched(state, userId),
    returnToUserList: previousPage(state),
    externalService: user && user.clinician && user.clinician.external_service,
    externalServiceSettings:
      user && user.clinician && user.clinician.external_service_fields,
    events: peerStates(state, userId),
    externalServiceComponent: ExternalService,
    actionComponent: Actions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchData: (peerId) => {
      dispatch(requestPeer(peerId));
      dispatch(requestPeerProfile(peerId));
      dispatch(requestCardInteractions(peerId));
      dispatch(requestPeerDeviceInfo(peerId));
    },
    fetchProgramParts: (peerId) => {
      dispatch(requestPeerProgramParts(peerId));
    },
    fetchHazardPictures: (peerId) => {
      dispatch(requestPeerHazardPictures(peerId));
    },
    fetchHazardImages: (peerId, pictures) =>
      dispatch(requestPeerHazardImage(peerId, pictures)),
    fetchCareTeam: (peerId) => {
      dispatch(requestCareTeam(peerId));
    },
    fetchOwner: (peerId) => {
      dispatch(requestOwner(peerId));
    },
    fetchWorkerData: (workerId) => {
      dispatch(requestWorker(workerId));
      dispatch(requestWorkerEngagement(workerId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsView);
