import { handleActions } from "redux-actions";

import { setBirthDateMessage } from "./actions";

export const birthDateMessage = (state) => state.ui.PeerView.birthDateMessage;

export default handleActions(
  {
    [setBirthDateMessage]: (state, action) => ({
      ...state,
      birthDateMessage: action.payload,
    }),
  },
  { birthDateMessage: null }
);
