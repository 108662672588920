import reduceReducers from "reduce-reducers";
import { handleActions } from "redux-actions";
import externalServiceReducer from "./View/components/ExternalService/reducer";
import { didUpdatePeerLanguage } from "../Invite/View/components/Actions/components/UpdateLanguage/actions";
import viewReducer from "./View/reducer";

import { didGetExternalServiceFields } from "./actions";

export const externalServiceSettings = (state) =>
  state.ui.PeerView.externalFields || [];

export function mapToExternalServiceFields(settings) {
  if (!settings) {
    return null;
  }
  return settings.map((field) => ({
    label: field.label,
    fullPath: field.name,
  }));
}

const reducer = handleActions(
  {
    [didGetExternalServiceFields]: (state, action) => ({
      ...state,
      externalFields: action.payload,
    }),
    [didUpdatePeerLanguage]: (state, action) => {
      const newState = { ...state };
      newState[action.payload.peerId].info.about.lang = action.payload.language;
      // Hacky, but it doesn't seem to live update for now
      document.location.reload();
      return newState;
    },
  },
  {
    externalFields: [],
  }
);

export default reduceReducers(reducer, viewReducer, externalServiceReducer);
