export async function selectFiles() {
  return new Promise((resolve, reject) => {
    // Need the input to show the file picker
    const el = document.createElement("input");
    el.type = "file";
    el.addEventListener("change", (e) => {
      resolve(e.target.files);
    });
    el.click();
  });
}
