import { groupBy, get, values, compact } from "lodash";

// The functions in here convert our metric results to data from which lines in the graph can be drawn
// Every function in here should return a function which, when called returns a list of {x,y} coordinates
// If you can't create it yet, just return an empty array and wait to be called again (eg not all data is available)
// [{x,y}, {x,y}, {x,y}]
// This function will be called with the relevant part of the data from the metric object

// Helper function to create a single line for the charts based of multiple meric entries for the same x coordinate
function groupByX(xPath, reducer) {
  return function (metricData) {
    // Since we are summing, grouping the data by x axis point
    const groupedByX = values(
      groupBy(metricData, (metric) => get(metric, xPath))
    );

    // Create {x: 1, y: 2} entries
    const chartData = groupedByX.map((allDataForX) => ({
      x: get(allDataForX[0], xPath),
      y: allDataForX.reduce(reducer, 0),
    }));

    return chartData;
  };
}

// Converting metric data so only one line is drawn, where the y axis is the sum of all the metrics
// E.g on a registration chart, checking multiple "codes" would chart the sum of registrations per timeframe across those codes
export function sum(xPath, yPath) {
  // Summing the values at the y path
  const reducer = (aggregated, entryForX) => aggregated + get(entryForX, yPath);
  return groupByX(xPath, reducer);
}

export function average(xPath, yPath) {
  // Averaging the values at the y path
  const reducer = (aggregated, entryForX, index) =>
    (aggregated + get(entryForX, yPath)) / (index + 1);
  return groupByX(xPath, reducer);
}

// Showing the over time change
// Pass in any of the other operations into this function to get it's change (e.g average, sum, multiline)
// Example if you normally would have in your chart the sun: get('end', 'data.total_nr_peers')
// Just wrap it like so: change(sum('end', 'data.total_nr_peers'))
// For the first item difference can't be calculated so that value is removed from the list
export function change(operationFunction) {
  return function (metricData) {
    const graphData = operationFunction(metricData);

    // Getting the graph data to display, and converting it to over time change
    const data = graphData.map((dataPoint, index) => {
      if (index === 0) {
        return null;
      }

      // We can't calculate the change for the first item
      const currentValue = dataPoint.y;
      const previousValue = graphData[index - 1].y;
      const difference = currentValue - previousValue;

      return {
        x: dataPoint.x,
        y: difference,
      };
    });

    return compact(data);
  };
}

// Calculates the moving average.
// Pass one of the operations into this function, and an optional frameCount argument
// frameCount is over how many timeframes to calculate the movement (e.g for a seven day moving overage it would be 7)
export function movingAverage(operationFunction, frameCount = 1) {
  return function (metricData) {
    const graphData = operationFunction(metricData);

    // Getting the graph data and converting it to the moving average
    const data = graphData.map((dataPoint, index) => {
      // Can't calculate the moving average for the first x items since there is not enought data yet
      if (index < frameCount) {
        return null;
      }

      const entriesToAverageOut = graphData.slice(
        index - frameCount,
        index + 1
      );
      // Get the average of the array
      const averageOfEntries = entriesToAverageOut.reduce(
        (aggregated, entry, entryIndex) =>
          (aggregated + entry.y) / (entryIndex + 1),
        0
      );

      return {
        x: dataPoint.x,
        y: averageOfEntries,
      };
    });

    return compact(data);
  };
}
