import { get } from "lodash";
import moment from "moment";
import { fleetingFormMeta } from "../../../../../../../../shared/utils/form";
import { handleActions } from "redux-actions";
import { didCancelRelease, didRelease } from "./actions";

export function releaseData(state, peerId) {
  return get(state, `ui.PeerView.${peerId}.info.release`);
}

export function pendingRelease(state, peerId) {
  const data = releaseData(state, peerId);
  return (data && moment(data.date).startOf("day").isAfter(moment())) || false;
}

export function released(state, peerId) {
  const data = releaseData(state, peerId);
  return (data && moment(data.date).startOf("day").isBefore(moment())) || false;
}

export function notification(state, peerId) {
  const data = releaseData(state, peerId);
  const date = data && moment(data.date).format("MMM DD YYYY");
  const releaseType = data && data.type;
  const releaseDate = released(state, peerId);
  const message = fleetingFormMeta(state, "release").message;
  if (!message && releaseDate) {
    return `Patient was ${releaseType}${
      releaseType === "full" ? "y" : "ly"
    } released on ${date}`;
  } else if (message) {
    return message;
  }
  return "";
}

export default handleActions(
  {
    [didRelease]: (state, action) => {
      const { peerId, date, type } = action.payload;
      state[peerId].info.release = { date, type };
      return Object.assign({}, state);
    },
    [didCancelRelease]: (state, action) => {
      const peerId = action.payload;
      state[peerId].info.release = null;
      return Object.assign({}, state);
    },
  },
  {}
);
