import React from "react";
import classNames from "classnames";

function PlaceholderField({
  className,
  input,
  label,
  type,
  meta: { touched, error, warning, pristine },
  autoFocus,
}) {
  const shouldDisplayError = !pristine && touched;

  const fieldClass = classNames("form-control", className, {
    "has-error": shouldDisplayError && error,
  });

  return (
    <div className="form-group">
      <input
        {...input}
        className={fieldClass}
        placeholder={label}
        type={type}
        autoFocus={autoFocus}
      />
      {shouldDisplayError &&
        ((error && <span className="error-message">{error}</span>) ||
          (warning && <span className="warning-message">{warning}</span>))}
    </div>
  );
}

export default PlaceholderField;
