import { connect } from "react-redux";
import AddCareTeamMember from "./AddCareTeamMember.jsx";

import { fleetingFormMeta } from "../../../../../../../shared/utils/form";

function mapStateToProps(state) {
  return {
    message: fleetingFormMeta(state, "addCareTeamMember").message,
    apiError: fleetingFormMeta(state, "addCareTeamMember").apiError,
  };
}

export default connect(mapStateToProps)(AddCareTeamMember);
