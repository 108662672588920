import React from "react";

import { VerticalBarSeries } from "react-vis";

import BaseChart from "./../BaseChart";

class BarChart extends BaseChart {
  renderPlot(data) {
    const bars = this._getBars(data);

    const otherComponents = super.renderPlot(data);
    return otherComponents.concat(bars);
  }

  _getBars(config) {
    const allBars = config.map((chartConfig, index) => {
      const color = this.colorForIndex(index);
      return (
        <VerticalBarSeries
          data={chartConfig.data}
          key={`draw-line-${index}`}
          color={color}
          onNearestX={(value) => this._onNearestXY(index, value)} // Don't check the Y axis, which messes it up for bar charts and is not relevant
          onSeriesMouseOut={this._clearHint}
          onSeriesMouseOver={(event) => this._onSeriesMouseOver(index, event)}
        />
      );
    });

    return allBars;
  }
}

export default BarChart;
