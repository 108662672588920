import { connect } from "react-redux";
import UpdateOwner from "./UpdateOwner.jsx";

import { fleetingFormMeta } from "../../../../../../../shared/utils/form";

function mapStateToProps(state) {
  return {
    message: fleetingFormMeta(state, "updateOwner").message,
  };
}

export default connect(mapStateToProps)(UpdateOwner);
