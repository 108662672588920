import { connect } from "react-redux";
import { push } from "redux-little-router";
import UpdateOwner from "./../../../../../../components/UserView/components/Forms/UpdateOwner";

import { updateOwner } from "./actions";

function mapDispatchToProps(dispatch, { userId }) {
  return {
    onSubmit: (values) => {
      dispatch(
        updateOwner({
          ...values,
          peerId: userId,
        })
      );
    },
    redirectToHome: () => dispatch(push("/")),
  };
}

export default connect(null, mapDispatchToProps)(UpdateOwner);
