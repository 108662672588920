import { createAction } from "redux-actions";

const prefixed = (val) => `Phases/Edit/PhasesForm::${val}`;

export const didCreatePhase = createAction(
  prefixed("DID_CREATE_PHASE", (phaseId, values) => ({ phaseId, values }))
);
export const didUpdatePhase = createAction(
  prefixed("DID_UPDATE_PHASE", (phaseId, values) => ({ phaseId, values }))
);
