import { connect } from "react-redux";
import {
  requestBookOfBusiness,
  requestCustomers,
  requestResetReport,
  requestUpdateEndDate,
  requestUpdateStartDate,
} from "./actions";
import {
  customers,
  endDate,
  reportForCustomer,
  selectedCustomer,
  startDate,
} from "./reducer";
import BookOfBusiness from "./BookOfBusiness.jsx";

function mapStateToProps(state) {
  const customer = selectedCustomer(state);

  return {
    customers: customers(state),
    selectedCustomer: customer,
    startDate: startDate(state),
    endDate: endDate(state),
    report: reportForCustomer(state, customer ? customer.id : null),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestCustomers: () => dispatch(requestCustomers()),
    resetReport: (customerId) => dispatch(requestResetReport(customerId)),
    requestBookOfBusiness: (payload) =>
      dispatch(requestBookOfBusiness(payload)),
    updateStartDate: (timestamp) => dispatch(requestUpdateStartDate(timestamp)),
    updateEndDate: (timestamp) => dispatch(requestUpdateEndDate(timestamp)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookOfBusiness);
