// Helps digging through the getWrappedInstances calls
// You probably don't need this. Since calling function on elements is not the preferred way
function unwrapComponent(object) {
  if (!object) {
    return;
  }
  if (object.getWrappedInstance) {
    return unwrapComponent(object.getWrappedInstance());
  }
  return object;
}

export default unwrapComponent;
