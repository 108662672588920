import { connect } from "react-redux";
import mapArrayToObject from "../../utils/mapArrayToObject";

import Metrics from "./Metrics.jsx";

import { requestMetricData } from "./sagas";
import { availableMetricDataForQuery } from "./reducer";

function allAvailableMetrics(state, ownProps) {
  const data = mapArrayToObject(ownProps.query, (metricQuery, accumulated) => {
    const type = metricQuery.type;
    accumulated[type] = availableMetricDataForQuery(state, metricQuery);
  });

  // Make sure at least a single data section has loaded before returning the block so a loading indicator can be displayed
  for (const dataSectionKey in data) {
    const dataSection = data[dataSectionKey];
    if (dataSection) {
      return data;
    }
  }

  return null;
}

function mapStateToProps(state, ownProps) {
  return {
    // Todo: Should only be the metrics relevant to this object
    data: allAvailableMetrics(state, ownProps),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestMetricData: (metricInfo) => dispatch(requestMetricData(metricInfo)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Metrics);
