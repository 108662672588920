import React from "react";
import PropTypes from "prop-types";

import "./DropdownMenu.css";

class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: null,
    };

    this.onSearchChange = this.onSearchChange.bind(this);
  }

  onSearchChange(e) {
    const searchValue = e.target.value;
    this.setState({ searchValue });
  }

  render() {
    const { menuItem } = this.props;
    const { searchValue } = this.state;

    const selectedItem =
      menuItem.dropdown.find((child) => child.value === menuItem.selected) ||
      menuItem.dropdown[0];

    let filtered = menuItem.dropdown;
    if (menuItem.searchable && searchValue && searchValue.length > 0) {
      filtered = menuItem.dropdown.filter((item) => {
        const searchKey = item.search ? "search" : "label";
        return (
          item[searchKey].toLowerCase().indexOf(searchValue.toLowerCase()) !==
          -1
        );
      });
    }

    return (
      <li className="menu" key={menuItem.name}>
        <span
          className="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {selectedItem.label} <span className="caret" />
        </span>
        <div className="dropdown-menu">
          {menuItem.searchable && (
            <div className="search-field" key="search">
              <input
                type="text"
                className="breadcrumb-search form-control"
                onChange={this.onSearchChange}
              />
            </div>
          )}
          <ul>
            {filtered.map((subItem) => (
              <li
                key={subItem.key || subItem.label}
                onClick={() => subItem.onClick(subItem.value)}
              >
                {subItem.label}
              </li>
            ))}
          </ul>
        </div>
      </li>
    );
  }
}

DropdownMenu.propTypes = {
  menuItem: PropTypes.object.isRequired,
};

export default DropdownMenu;
