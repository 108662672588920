import qs from "qs";

const defaultStringifyOptions = {
  encode: false,
};

const defaultParseOptions = {
  ignoreQueryPrefix: true,
};

// Meh QS doesn't really have a way of setting default options, so doing it this way
const originalStringify = qs.stringify;

qs.stringify = function (object, options) {
  const mergedOptions = Object.assign({}, defaultStringifyOptions, options);
  return originalStringify(object, mergedOptions);
};

const originalParse = qs.parse;

qs.parse = function (string, options) {
  const mergedOptions = Object.assign({}, defaultParseOptions, options);
  return originalParse(string, mergedOptions);
};
