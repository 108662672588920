import axios from "axios";

export const fetchCoaches = ({
  keyword = "",
  sortBy = defaultSortBy,
  sortDirection = defaultSortDirection,
  page = 0,
  perPage = 30,
}) => {
  return axios.get(
    `/auth-server/users?filter[0][email]=${keyword}&filter[1][name]=${keyword}&filter_scope=prevention:read&sort_by=${sortBy}&sort_direction=${sortDirection}&per_page=${perPage}&page=${page}`
  );
};

export const defaultSortBy = "created_at";
export const defaultSortDirection = "desc";

export const createCoach = (values) =>
  axios.post("/auth-server/users/", {
    ...values,
    ...{ scope: "prevention:read" },
  });

export const resendInvite = (coachId) =>
  axios.post(`/auth-server/users/${coachId}/invites?force=true`);

export const updateCoach = ({ id, name, email, mfa, disabled }) =>
  axios.patch(`/auth-server/users/${id}`, {
    name,
    email,
    mfa: Boolean(mfa),
    disabled: Boolean(disabled),
  });
