import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class ErrorIndicator extends React.Component {
  render() {
    const { shouldDisplayErrorIndicator, onHideError, error } = this.props;
    return (
      <div>
        <Modal
          isOpen={shouldDisplayErrorIndicator}
          onRequestClose={onHideError}
          style={customStyles}
          aria-labelledby="modal-label"
          ariaHideApp={false}
          contentLabel="Loading Error"
        >
          <h2>
            {error?.code ? `Request Error: ${error.code}` : "Loading Error"}
          </h2>
          <div>
            {error?.error ||
              error?.message ||
              "There was a problem. Please try again or contact digitalrecovery@bardavon.com."}
          </div>
        </Modal>
      </div>
    );
  }
}

ErrorIndicator.propTypes = {
  shouldDisplayErrorIndicator: PropTypes.bool.isRequired,
  error: PropTypes.object,
  onHideError: PropTypes.func.isRequired,
};

export default ErrorIndicator;
