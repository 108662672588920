import { connect } from "react-redux";

import LoadingIndicator from "./LoadingIndicator.jsx";
import { shouldDisplayLoadingIndicator } from "./reducer";

function mapStateToProps(state) {
  return {
    shouldDisplayLoadingIndicator: shouldDisplayLoadingIndicator(state),
  };
}

export default connect(mapStateToProps)(LoadingIndicator);
