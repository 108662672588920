import Modal from "react-modal";
import { ErrorWithCode } from "../../shared/utils/ErrorWithCode";

/**
 * A simplified version of [ErrorIndicator](https://bitbucket.org/peerwell/peerwell-dashboard/src/d0861c76c1fd56e4cc464f186c05cbfa8ec658f8/src/shared/components/ErrorIndicator/ErrorIndicator.jsx#src/shared/components/ErrorIndicator/ErrorIndicator.jsx-16)
 * @param props
 * @returns
 */
export const ErrorModal = ({
  error,
  onRequestClose,
}: {
  error?: ErrorWithCode;
  onRequestClose: () => void;
}) => (
  <Modal
    isOpen={error != null}
    onRequestClose={onRequestClose}
    style={customStyles}
    aria-labelledby="modal-label"
    ariaHideApp={false}
    contentLabel="Loading Error"
  >
    <h2>{error?.code ? `Request Error: ${error.code}` : "Loading Error"}</h2>
    <div>
      {error?.error ||
        error?.message ||
        "There was a problem. Please try again or contact support@peerwell.co ."}
    </div>
  </Modal>
);

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
