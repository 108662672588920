import { createAction } from "redux-actions";

const prefixed = (val) => `WeeklyAdoptionReport::${val}`;

export const requestCustomers = createAction(prefixed("REQUEST_CUSTOMERS"));
export const didFetchCustomers = createAction(
  prefixed("DID_FETCH_CUSTOMERS"),
  (customers) => customers
);

export const requestWeeklyAdoptionReport = createAction(
  prefixed("REQUEST_WEEKLY_ADOPTION_REPORT")
);
export const didFetchWeeklyAdoptionReport = createAction(
  prefixed("DID_FETCH_WEEKLY_ADOPTION_REPORT"),
  (reportData) => reportData
);

export const requestUpdateStartDate = createAction(
  prefixed("REQUEST_UPDATE_START_DATE")
);
export const didUpdateStartDate = createAction(
  prefixed("DID_UPDATE_START_DATE"),
  (startDate) => startDate
);
export const requestUpdateEndDate = createAction(
  prefixed("REQUEST_UPDATE_END_DATE")
);
export const didUpdateEndDate = createAction(
  prefixed("DID_UPDATE_END_DATE"),
  (endDate) => endDate
);
