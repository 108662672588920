import moment from "moment";
import { delay } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../shared/app/requestErrorHandler";
import { callAPI } from "../../../shared/utils/sagas";
import {
  didFetchBookOfBusiness,
  didFetchCustomers,
  didResetReport,
  didTriggerBookOfBusiness,
  didUpdateEndDate,
  didUpdateStartDate,
  requestBookOfBusiness,
  requestCustomers,
  requestResetReport,
  requestUpdateEndDate,
  requestUpdateStartDate,
} from "./actions";
import {
  fetchBookOfBusiness as fetchBookOfBusinessAPI,
  fetchCustomers as fetchCustomersAPI,
  triggerBookOfBusiness as triggerBookOfBusinessAPI,
} from "./api";

function* triggerBookOfBusinessReport(action) {
  const customerId = action.payload.customerId;
  const assessment = action.payload.selectedSurvey;

  let query = "";
  if (action.payload.startDate) {
    const ISODate = moment(action.payload.startDate).format("YYYY-MM-DD");
    query += `&start_date=${ISODate}`;
  }
  if (action.payload.endDate) {
    const ISODate = moment(action.payload.endDate).format("YYYY-MM-DD");
    query += `&end_date=${ISODate}`;
  }

  if (assessment) {
    query += `&assessment_report=${assessment}`;
  }

  try {
    let status = "pending";
    let res;
    const response = yield triggerBookOfBusinessAPI(customerId, query);
    yield put(didTriggerBookOfBusiness({ data: response.data, customerId }));
    while (status === "pending") {
      res = yield fetchBookOfBusinessAPI(response.data.id);
      status = res.data.status;
      yield delay(10000);
    }
    yield put(didFetchBookOfBusiness({ data: res.data, customerId }));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* updateStartDate(action) {
  yield put(didUpdateStartDate(action.payload));
}

function* updateEndDate(action) {
  yield put(didUpdateEndDate(action.payload));
}

function* resetReport(action) {
  yield put(didResetReport(action.payload));
}

export default function* watchBookOfBusinessSaga() {
  yield takeLatest(requestBookOfBusiness, triggerBookOfBusinessReport);
  yield takeLatest(
    requestCustomers,
    callAPI(fetchCustomersAPI, didFetchCustomers)
  );
  yield takeLatest(requestUpdateStartDate, updateStartDate);
  yield takeLatest(requestUpdateEndDate, updateEndDate);
  yield takeLatest(requestResetReport, resetReport);
}
