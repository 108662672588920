import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../shared/app/requestErrorHandler";
import { callAPI } from "../../shared/utils/sagas";
import {
  didFetchModule,
  didFetchModulesList,
  fetchModule,
  fetchModulesList,
} from "./actions";
import {
  fetchModule as fetchModuleAPI,
  fetchModulesList as fetchModulesListAPI,
} from "./api";
import watchBottomBarSaga from "./components/BottomBar/saga";
import watchModulesFormSaga from "./components/ModulesForm/saga";
import watchModulesListSaga from "./components/ModulesList/saga";

function* fetchPhasesListSaga(action) {
  try {
    const response = yield fetchModulesListAPI(action.payload);
    const count = response.headers["x-total-count"];
    yield put(didFetchModulesList(response.data, count));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* watchModulesSaga() {
  yield all([
    takeLatest(fetchModule, callAPI(fetchModuleAPI, didFetchModule)),
    takeLatest(fetchModulesList, fetchPhasesListSaga),
  ]);
}

export default function* () {
  yield all([
    watchModulesSaga(),
    watchBottomBarSaga(),
    watchModulesListSaga(),
    watchModulesFormSaga(),
  ]);
}
