import React from "react";
import PropTypes from "prop-types";
import { orderBy } from "lodash";

import "./ProgramsList.css";
import Program from "./components/Program/Program";

class ProgramsList extends React.Component {
  constructor(props) {
    super(props);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  onSelectChange(e) {
    const { onShowDeletedChange } = this.props;
    onShowDeletedChange(e.target.value === "true");
  }

  onFilterChange(e) {
    const { updateFilter } = this.props;
    let filter = e.target.value.trim().toLowerCase();
    if (filter.length === 0) {
      filter = null;
    }
    updateFilter(filter);
  }

  render() {
    const { programsList, onAddProgramClicked, showDeleted } = this.props;

    if (!programsList) {
      return null;
    }
    const orderedPrograms = orderBy(programsList, (program) =>
      program.name.toLowerCase()
    );

    const emptyPrograms = orderedPrograms.length === 0;

    const title = "All Programs";
    const emptyMessage = "No programs found";

    return (
      <div className="programs-page">
        <div className="row top">
          <div className="col-md-3">
            <h2>{title}</h2>
          </div>
          <div className="col-md-3">
            <button
              className="btn btn-primary"
              onClick={onAddProgramClicked}
              data-test="add-program"
            >
              Add Program
            </button>
          </div>
          <div className="col-md-3">
            <select value={showDeleted} onChange={this.onSelectChange}>
              <option value={false}>Deleted not included</option>
              <option value>Include deleted</option>
            </select>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className="pull-right form-control form-control-small"
              placeholder="Filter programs"
              onChange={this.onFilterChange}
              data-test="filter-programs"
            />
          </div>
        </div>

        <p className="double-click">Double click to edit the program</p>

        {(emptyPrograms && (
          <p className="empty-message" data-test="empty-message">
            {emptyMessage}
          </p>
        )) || (
          <ul className="programs-list" data-test="programs-list">
            {orderedPrograms.map((program) => (
              <Program {...program} key={program.id} />
            ))}
          </ul>
        )}
      </div>
    );
  }
}

ProgramsList.propTypes = {
  programsList: PropTypes.arrayOf(PropTypes.object),
  onAddProgramClicked: PropTypes.func.isRequired,
  onShowDeletedChange: PropTypes.func.isRequired,
  showDeleted: PropTypes.bool,
  updateFilter: PropTypes.func.isRequired,
  filter: PropTypes.string,
  selectedProgram: PropTypes.object,
};

ProgramsList.defaultProps = {
  showDeleted: false,
  filter: "",
};

export default ProgramsList;
