import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import { validateForm } from "../../../../../shared/utils/form";
import SimpleField from "../../../../../shared/components/FormFields/SimpleField";
import { emailRegexValidator } from "../../index";
import CheckboxField from "../../../../../shared/components/FormFields/CheckboxField";
import SelectField from "../../../../../shared/components/FormFields/SelectField";

const rules = {
  name: {
    presence: true,
  },
  email: {
    presence: true,
    email: true,
  },
};

function UpdateCareTeamUsersForm({
  handleSubmit,
  onSubmit,
  user,
  onCancel,
  message,
  groups,
}) {
  return (
    <form className="update-clinician-form">
      <h3 className="form-header">Edit care team user's details</h3>

      {/* <div className="input-field form-group">
        <label htmlFor="fullname">Full Name *</label>
        <Field name="fullname" component={SimpleField} />
      </div> */}

      {/* <div className="input-field form-group">
        <label htmlFor="name">Email *</label>
        <Field name="email" component={SimpleField} regexValidator={emailRegexValidator} />
      </div> */}

      <Field
        className="checkbox-field"
        name="blocked"
        label="Blocked"
        component={CheckboxField}
      />

      <input
        className="action-btn"
        type="submit"
        onClick={handleSubmit(onSubmit)}
        value="Update"
      />
      <button className="action-btn red" onClick={onCancel}>
        Cancel
      </button>

      {message && <p className="message">{message}</p>}
    </form>
  );
}

UpdateCareTeamUsersForm.propTypes = {
  user: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string,
  // redux-form
  handleSubmit: PropTypes.func.isRequired,
};

UpdateCareTeamUsersForm.defaultProps = {
  user: null,
  programList: null,
  message: null,
};

export default reduxForm({
  form: "updateCareTeamUserForm",
  validate: validateForm(rules),
  enableReinitialize: true,
})(UpdateCareTeamUsersForm);
