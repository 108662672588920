import { get, set } from "lodash";
import { handleActions } from "redux-actions";
import { didFetchAvailablePrograms } from "./actions";

// XXX duplicate of the one in TransitionProgram, extract to a common place for reuse?
export const getPeerAvailableParts = (state, peerId) => {
  return get(state, `ui.PeerView.${peerId}.available_parts`);
};

// XXX duplicate of the one in TransitionProgram, extract to a common place for reuse?
export default handleActions(
  {
    [didFetchAvailablePrograms]: (state, action) => {
      const newState = { ...state };
      const peerId = action.payload.peerId;
      const clinicians = action.payload.clinicians;
      const programs = action.payload.programs;
      const clinicians_program_ids =
        clinicians &&
        clinicians
          .map((clinician) => clinician.programs)
          .flat()
          .map((program) => program.aggregate_program);
      const parts = programs
        .filter((program) => clinicians_program_ids.includes(program.id))
        .map((program) =>
          program.parts.map((part) => ({
            ...part,
            aggregate_program: program.id,
            aggregate_program_name: program.name,
            ...(program.version === "2" && { version: program.version }),
          }))
        )
        .flat();
      const partsWithLabel = parts.map((part) => {
        return {
          label: programLabel(part),
          value: { program: part.aggregate_program, part: part.id },
        };
      });
      set(newState, `${peerId}.available_parts`, partsWithLabel);

      return newState;
    },
  },
  {}
);

function programLabel(part) {
  return part.version === "2"
    ? `${part.name} - ${part.aggregate_program_name}`
    : part.name;
}
