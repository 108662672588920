import PropTypes from "prop-types";

export const eventShape = {
  id: PropTypes.string,
  title: PropTypes.string,
  effect: PropTypes.oneOf(["positive", "negative", "neutral"]),
  state: PropTypes.oneOf([
    "pending",
    "completed",
    "failed",
    "unreachable",
    "program ended",
    "poor engagement",
    "good engagement",
  ]),
  type: PropTypes.string,
};
