import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import HoverImage from "react-hover-image";
import editIcon from "./../../../../assets/edit.svg";
import editIconHover from "./../../../../assets/edit hover.svg";

import "./Set.css";

function Set({ label, value, className, onEditButton, defaultValue }) {
  const displayValue =
    value !== null && value !== undefined ? value : defaultValue;
  return (
    <div
      className={classNames("data-set", className, {
        editable: !!onEditButton,
      })}
    >
      <h3 className={"labels"}> {label} </h3>
      <p className={className} onClick={onEditButton}>
        {" "}
        {displayValue}{" "}
        {onEditButton && <HoverImage src={editIcon} hoverSrc={editIconHover} />}
      </p>
    </div>
  );
}

Set.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node,
  className: PropTypes.string,
  onEditButton: PropTypes.func,
  defaultValue: PropTypes.string,
};

Set.defaultProps = {
  className: "",
  onEditButton: null,
  defaultValue: "-",
  value: null,
};

export default Set;
