import React from "react";
import classNames from "classnames";
import escapeRegexString from "escape-regex-string";

import "./DrillDownSelectField.css";
import deepClone from "../../../utils/deepClone";

// values should be in format
// [
//   { label: 'Parent label', value: 'x', items: [{ label: 'label', value: 'x' }, ...] },
//   ...
// ]
// Todo: add filtering
class DrillDownSelectField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      filter: "",
    };

    this.onFocus = this.onFocus.bind(this);
    this.renderMenuItem = this.renderMenuItem.bind(this);
    this.setItem = this.setItem.bind(this);
    this.filteredValues = this.filteredValues.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.input?.value !== "" && this.props.input?.value === "") {
      this.setState({
        filter: "",
      });
    }
  }

  showMenu(show) {
    this.setState({ isOpen: show });
  }

  setItem(item) {
    const { input } = this.props;
    input.onChange(item.value);

    this.setState({ filter: item.label });

    this.showMenu(false);
  }

  // Will add 'matched: true' to the matched item.
  filteredValues() {
    const { values } = this.props;
    const { filter } = this.state;

    if (!filter || filter === "") {
      return values;
    }

    function filterItem(item) {
      if (item.label.match(new RegExp(escapeRegexString(filter), "gi"))) {
        item.matched = true;
        return true;
      }
      // the second condition is a dirty workaround for the customers and clinicians drilldown menu (used in /invite and /manage-staff).
      // we don't want to match subitems which include 'peerwell' because more than half do (due to most clinicians having a peerwell email).
      // admins usually want to access the customer PeerWell Inc. when typing in "peerwell"
      // this may need to be modified in the future if we use this component for something other than a customers and clinicians drilldown menu
      if (item.items && filter.toLowerCase().indexOf("peer") === -1) {
        for (const subItem of item.items) {
          if (filterItem(subItem, filter)) {
            item.matched = true;
            return true;
          }
        }
      }

      return false;
    }

    return deepClone(values).filter(filterItem);
  }

  onFilterChange(e) {
    const value = e.target.value;
    const { input } = this.props;
    this.setState({
      filter: value,
    });
    input.onChange("");
  }

  onFocus(e) {
    if (e.target.autocomplete) e.target.autocomplete = "chromeisfascist";
    this.showMenu(true);
  }

  renderMenuItem(item) {
    const { label, items, value, matched } = item;
    const key = item.key || `${value}-${label}`;

    if (items) {
      const hasMatchedChild = !!items.find((i) => i.matched);
      return (
        <li key={key} className={classNames("withChild", { hasMatchedChild })}>
          <span className="parent">
            {label}
            <span className="caret" />
          </span>
          <ul className="submenu">{items.map(this.renderMenuItem)}</ul>
        </li>
      );
    }

    return (
      <li
        key={key}
        className={classNames("clickable", { matched })}
        onClick={() => this.setItem(item)}
      >
        {label}
      </li>
    );
  }

  render() {
    const {
      input,
      label,
      required,
      meta: { touched, error, pristine, warning },
      disabled,
      className,
      initialLabel,
    } = this.props;
    const { isOpen, filter } = this.state;

    const shouldDisplayError = !pristine || touched;

    const fieldClass = classNames(
      "form-control",
      { "has-error": shouldDisplayError && error, isOpen },
      className,
      disabled
    );

    return (
      <div className="form-group drilldown-select">
        <label htmlFor={input.name}>
          {label}
          {required ? <span className="asterisk"> *</span> : null}
        </label>

        <div className={fieldClass} id={input.name}>
          {/* need the delay here else it will hide the submenus before you can click when the box looses focus */}
          <input
            type="text"
            className="filterField"
            placeholder={initialLabel}
            onFocus={this.onFocus}
            onBlur={() => setTimeout(() => this.showMenu(false), 300)}
            value={filter}
            onChange={this.onFilterChange}
            autoComplete="new-password"
          />
          <ul className="dropdown">
            {this.filteredValues().map(this.renderMenuItem)}
          </ul>
        </div>

        {shouldDisplayError &&
          ((error && <span className="error-message">{error}</span>) ||
            (warning && <span className="warning-message">{warning}</span>))}
      </div>
    );
  }
}

export default DrillDownSelectField;
