import moment from "moment";
import { put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../shared/app/requestErrorHandler";
import { callAPI } from "../../../shared/utils/sagas";
import {
  didFetchBillingReport,
  didFetchCustomers,
  didUpdateAmount,
  didUpdateEndDate,
  didUpdateStartDate,
  requestBillingReport,
  requestCustomers,
  requestUpdateAmount,
  requestUpdateEndDate,
  requestUpdateStartDate,
} from "./actions";
import {
  fetchBillingReport as fetchBillingReportAPI,
  fetchCustomers as fetchCustomersAPI,
} from "./api";

function* fetchBillingReport(action) {
  const customerId = action.payload.customerId;

  let query = "";
  if (action.payload.startDate) {
    const ISODate = moment(action.payload.startDate).format("YYYY-MM-DD");
    query += `&start_date=${ISODate}`;
  }
  if (action.payload.endDate) {
    const ISODate = moment(action.payload.endDate).format("YYYY-MM-DD");
    query += `&end_date=${ISODate}`;
  }
  if (action.payload.amount) {
    query += `&amount=${action.payload.amount}`;
  }

  try {
    const response = yield fetchBillingReportAPI(customerId, query);
    yield put(didFetchBillingReport({ data: response.data, customerId }));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* updateStartDate(action) {
  yield put(didUpdateStartDate(action.payload));
}

function* updateEndDate(action) {
  yield put(didUpdateEndDate(action.payload));
}

function* updateAmount(action) {
  yield put(didUpdateAmount(action.payload));
}

export default function* watchBillingReportSaga() {
  yield takeLatest(requestBillingReport, fetchBillingReport);
  yield takeLatest(
    requestCustomers,
    callAPI(fetchCustomersAPI, didFetchCustomers)
  );
  yield takeLatest(requestUpdateStartDate, updateStartDate);
  yield takeLatest(requestUpdateEndDate, updateEndDate);
  yield takeLatest(requestUpdateAmount, updateAmount);
}
