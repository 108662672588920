import { createAction } from "redux-actions";
import { setFleetingFormMeta } from "../../../../../utils/form";

const prefixed = (val) => `ResetPassword/RequestUpdatePassword::${val}`;

export const requestUpdatePassword = createAction(
  prefixed("REQUEST_UPDATE_PASSWORD"),
  (email) => email
);
export const didRequestUpdatePassword = setFleetingFormMeta(
  "requestUpdatePassword",
  {
    message:
      "If this address belongs to an account an e-mail with further instructions will be send to it.",
  }
);
