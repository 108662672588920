import Metric from "./Metric.js";

import watchMetrics from "./sagas";
import reducer from "./reducer";

import {
  authenticationOfTypeRequired,
  authenticationRequired,
} from "../../../shared/routes/Authentication/Login/sagas";
import { setQueryDefaultsIfNeeded } from "../../../shared/components/Metrics/sagas";

export default {
  "/metrics": {
    component: Metric,
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired(["admin", "demo"]);
      yield setQueryDefaultsIfNeeded();
    },
    saga: watchMetrics,
    title: "Metrics",
    reducer: {
      ui: {
        Metrics: reducer,
      },
    },
  },
};
