import { all } from "redux-saga/effects";

import watchUpdateSurgeryDateSaga from "./components/UpdateSurgeryDate/sagas";
import watchUpdateEmailSaga from "./components/UpdateEmail/sagas";
import watchUpdateAssignedToSaga from "./components/UpdateAssignedTo/sagas";
import watchUpdatePhoneSaga from "./components/UpdatePhone/sagas";
import watchUpdateNameSaga from "./components/UpdateName/sagas";

export default function* watchActionsSaga() {
  yield all([
    watchUpdateSurgeryDateSaga(),
    watchUpdateEmailSaga(),
    watchUpdateAssignedToSaga(),
    watchUpdatePhoneSaga(),
    watchUpdateNameSaga(),
  ]);
}
