import { createAction } from "redux-actions";

const prefixed = (val) => `MANAGE/USERS::${val}`;

export const requestCustomers = createAction(prefixed("REQUEST_CUSTOMERS"));
export const didFetchCustomers = createAction(
  prefixed("DID_FETCH_CUSTOMERS"),
  (customers) => customers
);

export const requestAllUsers = createAction(prefixed("REQUEST_ALL_USERS"));
export const didFetchAllUsers = createAction(prefixed("DID_FETCH_ALL_USERS"));

export const requestUser = createAction(prefixed("REQUEST_USER"));
export const didFetchUser = createAction(prefixed("DID_FETCH_USER"));

export const requestGroups = createAction(prefixed("REQUEST_GROUPS"));
export const didFetchGroups = createAction(prefixed("DID_FETCH_GROUPS"));

export const createUser = createAction(
  prefixed("CREATE_USER"),
  (values) => values
);
export const didCreateUser = createAction(prefixed("DID_CREATE_USER"));

export const updateUser = createAction(
  prefixed("UPDATE_USER"),
  (values) => values
);
export const didUpdateUser = createAction(prefixed("DID_UPDATE_USER"));

export const uploadUsers = createAction(
  prefixed("UPLOAD_USERS"),
  (customerId, csv) => ({ customerId, csv })
);
export const didUploadUsers = createAction(prefixed("DID_UPLOAD_USERS"));

export const clearUserData = createAction(prefixed("CLEAR_USER_DATA"));
