// Sets up the redux store with redux-saga, a logger and a router as middleware

import { reducer as notificationsReducer } from "react-notification-system-redux";
import { Provider } from "react-redux";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { formMetaReducer } from "../utils/form";
import onAppLoad from "./loaded";
import { appReducers, uiReducers } from "./reducer";
import { router } from "./router";
import rootSaga from "./saga";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, router.middleware];

// Add store logging (only in dev)
if (process.env.NODE_ENV === "development") {
  const { logger } = require("redux-logger");
  middlewares.push(logger);
}

export const reducers = Object.assign({}, appReducers, {
  router: router.reducer,
  form: formMetaReducer, // Usingin custom redux form reducer, so I can add some meta information to each form
  ui: combineReducers(uiReducers),
  notifications: notificationsReducer,
});

export const store = createStore(
  combineReducers(reducers),
  compose(router.enhancer, applyMiddleware(...middlewares))
);

export const ReduxProvider = Provider;

sagaMiddleware.run(rootSaga);

// Call any after app load events
onAppLoad(store, router);
