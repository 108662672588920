import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import editIcon from "./../../../../../../assets/edit.svg";
import Set from "../../../../../../shared/components/Data/Set/index";

import "./Background.css";

const dateFormat = "MM/DD/YYYY";

class Background extends React.Component {
  render() {
    const { user, authenticatedUserType, onEditBackground } = this.props;
    const canUpdateBackground = authenticatedUserType === "admin";
    const formattedGender = user.intake_data && user.intake_data.gender;
    const formattedAge =
      user.intake_data &&
      (user.intake_data.age ||
        (user.intake_data.date_of_birth &&
          Math.floor(
            (Date.now() - user.intake_data.date_of_birth) / (365 * 24 * 3600000)
          )));
    const formattedDateBirth =
      user.intake_data &&
      user.intake_data.date_of_birth &&
      moment(user.intake_data.date_of_birth).format(dateFormat);
    const calculatedBMI =
      user.intake_data &&
      user.intake_data.weight &&
      user.intake_data.height &&
      (
        (user.intake_data.weight * 703) /
        Math.pow(
          user.intake_data.height.feet * 12 + user.intake_data.height.inches,
          2
        )
      ).toFixed(1);
    const formattedHeight =
      user.intake_data &&
      user.intake_data.height &&
      `${user.intake_data.height.feet}'${user.intake_data.height.inches.toFixed(
        0
      )}"`;
    const formattedWeight =
      user.intake_data &&
      user.intake_data.weight &&
      user.intake_data.weight.toFixed(1);
    const comorbidities =
      user.intake_data &&
      user.intake_data.health_issues &&
      Object.entries(user.intake_data.health_issues).reduce(
        (value, current, arr) => {
          if (arr === 1 && value[1]) {
            return value[0];
          }
          if (arr === 1 && !value[1]) {
            return "";
          }
          if (arr !== 1 && current[1] && value.length) {
            return `${value}, ${current[0]}`;
          }
          if (arr !== 1 && current[1] && !value.length) {
            return `${current[0]}`;
          }
          return value;
        }
      );
    return (
      <div className="background-group">
        <h1>
          Patient Background{" "}
          {canUpdateBackground && (
            <img src={editIcon} onClick={onEditBackground} alt="edit" />
          )}
        </h1>
        <div className="background-fields">
          <Set className="background" label="Gender" value={formattedGender} />
          <Set className="background" label="Age" value={formattedAge} />
          <Set className="background" label="BMI" value={calculatedBMI} />
          <Set className="background" label="Height" value={formattedHeight} />
          <Set className="background" label="Weight" value={formattedWeight} />
          <Set
            className="background"
            label="Affected area"
            value={user.intake_data.affected_area}
          />
          <Set
            className="background"
            label="Problem"
            value={user.intake_data.problem}
          />
          <Set
            className="background"
            label="Birthdate"
            value={formattedDateBirth}
          />
          <Set
            className="comorbidities"
            label="Comorbidities"
            value={comorbidities}
          />
        </div>
      </div>
    );
  }
}

Background.propTypes = {
  user: PropTypes.object.isRequired,
  authenticatedUserType: PropTypes.string.isRequired,
  onEditBackground: PropTypes.func.isRequired,
};

export default Background;
