import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../shared/app/requestErrorHandler";
import { didFetchCustomers, requestCustomers } from "./actions";
import { fetchCustomers as fetchCustomersAPI } from "./api";

function* fetchCustomers() {
  try {
    const response = yield fetchCustomersAPI();
    yield put(didFetchCustomers(response.data));
  } catch (e) {
    yield handleApiError(e);
  }
}

export default function* watchCustomerFormSagas() {
  yield all([takeLatest(requestCustomers, fetchCustomers)]);
}
