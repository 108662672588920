import reduceReducers from "reduce-reducers";
import { handleActions } from "redux-actions";
import { get } from "lodash";
import { query } from "../../shared/routes/query";

import { didFetchModule, didFetchModulesList } from "./actions";
import modulesFormReducer from "./components/ModulesForm/reducer";
import bottomBarReducer from "./components/BottomBar/reducer";
import modulesListReducer from "./components/ModulesList/reducer";

export const modulez = (state, id) => get(state.ui.Modules, `modules.${id}`);
export const newModule = (state) =>
  get(state.ui.Modules, "newModule", { name: "", description: "" });
export const selectedModuleId = (state) => query(state, "edit");
export const selectedModule = (state) =>
  modulez(state, selectedModuleId(state));

export const modulesReducer = reduceReducers(
  bottomBarReducer,
  modulesFormReducer,
  modulesListReducer,
  handleActions(
    {
      [didFetchModulesList]: (state, action) => ({
        ...state,
        list: action.payload.modulesList,
        count: action.payload.count,
      }),
      [didFetchModule]: (state, action) => {
        const modules = { ...state.modules } || {};
        const payloadModule = action.payload;
        modules[payloadModule.id] = payloadModule;
        return { ...state, modules };
      },
    },
    {
      pending: null,
      modules: {},
      list: [],
    }
  )
);
