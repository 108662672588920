import React from "react";
import PropTypes from "prop-types";
import { compact } from "lodash";
import moment from "moment";

import {
  FlexibleWidthXYPlot,
  MarkSeries,
  YAxis,
  XAxis,
  Hint,
  HorizontalGridLines,
  VerticalGridLines,
  Crosshair,
  AreaSeries,
} from "react-vis";

import classNames from "classnames";

import { polynomialFunctionBuilder } from "./math";

import "react-vis/dist/style.css";
import "./Chart.css";

const defaultColor = "#61B4FF";
const goodColor = "#0CBE19";
const badColor = "#E9403E";

class Chart extends React.Component {
  constructor(props) {
    super(props);

    const { metric } = this.props;

    this.state = {
      selectedIndex: metric.metrics.length - 1,
    };

    this.dataPoints = {
      strokeWidth: 2,
      size: 7,
      lineColor: "#ffffff",
      color: "#b4b4b6",
    };

    this.nearestIndex = 0;
  }

  renderHeading(metric) {
    return (
      <div className="heading">
        {metric.info.header && <h1>{metric.info.header}</h1>}
        <h2>{metric.info.title}</h2>
        {this.renderLegenda(metric.goal)}
      </div>
    );
  }

  renderLegenda(goal) {
    if (!goal) {
      return null;
    }

    const legendaItem = ({ label, color }) => (
      <li className="legenda-item">
        <span className="color" style={{ backgroundColor: color }} /> {label}
      </li>
    );

    return (
      <ul className="legenda">
        {legendaItem(goal)}
        {goal.mask && legendaItem(goal.mask)}
      </ul>
    );
  }

  renderGoal(goal) {
    if (!goal) {
      return null;
    } else if (goal.value !== undefined && goal.value !== null) {
      return this._renderSingleGoal(goal);
    }

    return this._renderGoalRange(goal);
  }

  _renderSingleGoal({ value }) {
    return (
      <Hint value={{ y: value, x: 0 }}>
        <div className="goal">
          <span className="lines" />
          <span className="annotation-value">GOAL</span>
        </div>
      </Hint>
    );
  }

  _renderGoalRange(goal) {
    const xPoints = this._xPointsOfDensityForRange();

    // Goal range
    const goalRangePoints = xPoints.map((x) =>
      this._calculateGoalDataPointForX(
        goal.y_coefficients,
        goal.y0_coefficients,
        x
      )
    );

    const goalArea = (
      <AreaSeries data={goalRangePoints} color={goal.color} key="goalArea" />
    );

    const goalAreas = [goalArea];
    if (goal.mask) {
      const maskedGoalRangePoints = compact(
        xPoints.map((x) =>
          this._maskedGoalDataPointForX(
            goal.y_coefficients,
            goal.y0_coefficients,
            goal.mask.y,
            goal.mask.y0,
            x
          )
        )
      );
      goalAreas.push(
        <AreaSeries
          data={maskedGoalRangePoints}
          color={goal.mask.color}
          key="goalMaskArea"
        />
      );
    }

    return goalAreas;
  }

  // For the goal range based on coefficients, we need some 'x' points to render them on
  // This function supplies you with x points
  // Higher density, more precise, also slower (eg how many data points between 0 and 1)
  _xPointsOfDensityForRange(fullRangeDensity = 90) {
    const { metric } = this.props;
    const range = metric.goal.range.x;

    const start = range.start.relative_value;
    const end = 1;

    const diff = end - start;

    // If there is no difference between the start and endpoint of the goal range line, there won't be anything to draw
    // So the number of points to draw is 0
    let pointCount = 0;
    if (diff !== 0) {
      pointCount = Math.ceil(fullRangeDensity / diff); // https://media.giphy.com/media/5669bWEI7TS3S/giphy.gif
    }

    const points = new Array(pointCount);

    points.fill(1); // Can't map it else
    return points.map((value, key) => (key / (points.length - 1)) * diff);
  }

  // Makes sure the value is clipped within range of the axis
  _axisRangeClip(value, axis) {
    const { metric } = this.props;
    const range = metric.range[axis];

    const clipA = range.start.value;
    const clipA0 = range.end.value;

    return this._rangeClip(value, clipA, clipA0);
  }

  _rangeClip(value, start, end) {
    const min = Math.min(start, end);
    const max = Math.max(start, end);

    return Math.max(Math.min(value, max), min);
  }

  _calculateGoalDataPointForX(y_coefficients, y0_coefficients, x) {
    // Todo: Respect server set range, but for now the range is always the same, so this is a bit easier
    // The function that generates the target range for a given day is based from day 0 - 90. Which in the chart is on the x axis from 0 - 1
    // So need to map the 0-1 numbers to 0-90 and call the function and then return the data
    const maxDays = 90;

    const yCalculator = polynomialFunctionBuilder(y_coefficients);
    const y0Calculator = polynomialFunctionBuilder(y0_coefficients);

    const day = x * maxDays;

    return {
      x,
      y: this._axisRangeClip(yCalculator(day), "y"),
      y0: this._axisRangeClip(y0Calculator(day), "y"),
    };
  }

  _maskedGoalDataPointForX(
    y_coefficients,
    y0_coefficients,
    y_mask,
    y0_mask,
    x
  ) {
    const goal = this._calculateGoalDataPointForX(
      y_coefficients,
      y0_coefficients,
      x
    );
    const { y, y0 } = goal;

    return {
      y: this._rangeClip(y, y_mask, y0_mask),
      y0: this._rangeClip(y0, y_mask, y0_mask),
      x,
    };
  }

  renderHorizontalLines(yDomain) {
    // Determines the position where the grid lines should be drawn
    function gridLineValues(domain, lineCount = 5) {
      const minDomain = Math.min(domain[0], domain[1]);
      const maxDomain = Math.max(domain[0], domain[1]);
      const sectionSize = (maxDomain - minDomain) / (lineCount - 1);

      const sections = [];
      for (let i = 0; i < lineCount; i += 1) {
        sections.push(minDomain + i * sectionSize);
      }
      return sections;
    }

    const tickValues = gridLineValues(yDomain);

    return [
      <HorizontalGridLines
        style={{ stroke: "#DADBDA" }}
        tickValues={tickValues}
        key="y-lines"
      />,
      <YAxis tickValues={tickValues} hideLine key="y-labels" />,
    ];
  }

  renderVerticalLines(tickValues) {
    const withLines = tickValues.filter((t) => t.line_decorator);
    const lineData = withLines.map((t) => t.relative_value);
    const tickData = tickValues.map((t) => t.relative_value);

    const mapToFormatted = (v) =>
      tickValues.find((t) => t.relative_value === v).formatted;

    return [
      <VerticalGridLines
        style={{ stroke: "#DADBDA" }}
        tickValues={lineData}
        key="x-lines"
      />,
      <XAxis
        tickValues={tickData}
        hideLine
        tickFormat={mapToFormatted}
        key="x-labels"
      />,
    ];
  }

  renderData(metrics, fillColor = "#b4b4b4b6") {
    const data = metrics.map((dataPoint) => ({
      y: dataPoint.value,
      x: dataPoint.x,
    }));

    const nearestXY = (position, { index }) => (this.nearestIndex = index);

    const onClick = () => this.setState({ selectedIndex: this.nearestIndex });

    return (
      <MarkSeries
        data={data}
        color={this.dataPoints.lineColor}
        fill={this.dataPoints.color}
        strokeWidth={this.dataPoints.strokeWidth}
        size={this.dataPoints.size}
        onNearestXY={nearestXY}
        onSeriesClick={onClick}
      />
    );
  }

  renderSelectedItemDot(color, item) {
    const data = [{ y: item.value, x: item.x }];
    return (
      <MarkSeries
        data={data}
        color={this.dataPoints.lineColor}
        fill={color}
        strokeWidth={this.dataPoints.strokeWidth}
        size={this.dataPoints.size}
        key="selectedDot"
      />
    );
  }

  _renderSettingsForSelectedItem(item, metric, yDomain) {
    if (metric.linear_goal) {
      return this._renderSettingsForSelectedLinearGoalItem(
        item,
        metric.linear_goal,
        yDomain
      );
    } else {
      return this._renderSettingsForSelectedGoalRangeItem(item, metric.goal);
    }
  }

  _renderSettingsForSelectedLinearGoalItem(item, goal, yDomain) {
    const { daysSinceRegistration } = this.props;

    if (!goal.better_value) {
      return null;
    }

    const betterFunctions = {
      higher: (v) => v >= goal.value,
      lower: (v) => v <= goal.value,
    };

    const isGoodMeasurement = betterFunctions[goal.better_value](item.value);

    const maskRangeFunctions = {
      higher: () => ({ y: Math.max(yDomain[0], yDomain[1]), y0: goal.value }),
      lower: () => ({ y: Math.min(yDomain[0], yDomain[1]), y0: goal.value }),
    };

    return {
      color: isGoodMeasurement ? goodColor : "#787878",
      bar: {
        goal: {
          range: {
            y: yDomain[0],
            y0: yDomain[1],
          },
          color: null,
        },
        mask: {
          range: maskRangeFunctions[goal.better_value](),
          color: goodColor,
        },
        label: isGoodMeasurement ? "Goal reached!" : "Goal progress",
      },
      value: item.value,
      day: `Day ${daysSinceRegistration(item.timestamp)}`,
      formatted: item.formatted,
      result: isGoodMeasurement ? "Great!" : "Go!",
      isGoodMeasurement,
    };
  }

  _renderSettingsForSelectedGoalRangeItem(item, goal) {
    const { daysSinceRegistration } = this.props;

    // Prehab one, not showing days since etc
    if (item.x < 0) {
      return {
        color: defaultColor,
        bar: {
          goal: {
            range: null,
            color: null,
          },
          mask: {
            range: null,
            color: null,
          },
          label: null,
        },
        value: item.value,
        day: "PreHab",
        formatted: item.formatted,
        result: null,
        isGoodMeasurement: false,
      };
    }

    const goalRange = this._calculateGoalDataPointForX(
      goal.y_coefficients,
      goal.y0_coefficients,
      item.x
    );

    const y0 = goalRange.y0;
    const y = goalRange.y;

    const min = Math.min(y, y0);
    const max = Math.max(y, y0);

    let isGoodMeasurement = item.value >= min && item.value <= max;

    if (!isGoodMeasurement) {
      // Second rule, you can be outside the range but visually above in the chart and be a good value
      const { better_value } = goal;
      isGoodMeasurement =
        (better_value === "higher" && item.value > max) ||
        (better_value === "lower" && item.value < min);
    }

    const bar = {
      goal: {
        range: goalRange,
        color: goal.color,
      },
      mask: {
        range: this._maskedGoalDataPointForX(
          goal.y_coefficients,
          goal.y0_coefficients,
          goal.mask.y,
          goal.mask.y0,
          item.x
        ),
        color: goal.mask.color,
      },
      label: isGoodMeasurement ? "Within Range" : "Outside Range",
    };

    return {
      color: isGoodMeasurement ? goodColor : badColor,
      bar,
      rangeLabel: "Average value",
      day: `Day ${daysSinceRegistration(item.timestamp)}`,
      value: item.value,
      formatted: item.formatted,
      result: isGoodMeasurement ? "Great!" : "Uh oh!",
      isGoodMeasurement,
    };
  }

  renderSelectedItem(settings) {
    if (!settings.bar.goal.range) {
      return this.renderSelectedPrehab(settings);
    }

    // Calculate rotation of bubble
    const min = Math.min(
      settings.value,
      settings.bar.goal.range.y,
      settings.bar.goal.range.y0
    );
    const max = Math.max(
      settings.value,
      settings.bar.goal.range.y,
      settings.bar.goal.range.y0
    );

    const degreeRange = 80;

    const range = max - min;
    const diff = max - settings.value;

    const deg = (diff / range) * degreeRange - degreeRange / 2;

    return (
      <div className="detailedInfo">
        <div
          className="bubbleContainer"
          style={{ transform: `rotate(${deg}deg)` }}
        >
          <div
            className={classNames("bubble", {
              goodBubble: settings.isGoodMeasurement,
              badBubble: settings.isGoodMeasurement === false,
            })}
          />
          <div
            className="bubbleText"
            style={{ transform: `rotate(${-deg}deg)` }}
          >
            <p className="day">{settings.day}</p>
            <p className="value">{settings.formatted}</p>
            {settings.result && <p className="result">{settings.result}</p>}
          </div>
        </div>

        {this.renderSelectedBar(settings)}
      </div>
    );
  }

  renderSelectedBar(settings) {
    const { formatter } = this.props;

    const min = Math.min(
      settings.value,
      settings.bar.goal.range.y,
      settings.bar.goal.range.y0
    );
    const max = Math.max(
      settings.value,
      settings.bar.goal.range.y,
      settings.bar.goal.range.y0
    );

    const range = max - min;

    function distanceFromTop(y, y0) {
      const top = max - Math.max(y, y0);
      const percentageTop = (top / range) * 100;
      return percentageTop;
    }

    function barStyle(color, y, y0) {
      const rangeHeight = Math.abs(y - y0);
      const percentageHeight = (rangeHeight / range) * 100;

      return {
        backgroundColor: color,
        height: `${percentageHeight}%`,
        top: `${distanceFromTop(y, y0)}%`,
      };
    }

    const mainColorStyle = barStyle(
      settings.bar.goal.color,
      settings.bar.goal.range.y,
      settings.bar.goal.range.y0
    );

    let maskColorStyle = null;
    if (settings.bar.mask.range) {
      // Only draw the mask color in the smaller bar if at this point the mask exists in the chart
      maskColorStyle = barStyle(
        settings.bar.mask.color,
        settings.bar.mask.range.y,
        settings.bar.mask.range.y0
      );
    }

    return (
      <div className="barContainer">
        <div className="bar">
          <div className="roundedBar">
            <div className="mainColor" style={mainColorStyle} />
            {maskColorStyle && (
              <div className="maskColor" style={maskColorStyle} />
            )}
          </div>
          <div
            className={classNames("peep", {
              happyPeep: settings.isGoodMeasurement,
            })}
            style={{
              top: `${distanceFromTop(settings.value, settings.value)}%`,
            }}
          />
        </div>

        <div className="labels">
          <p className="topValue">
            <span className="line" />
            <span className="number">{formatter(max)}</span>
          </p>

          <p className="centerLabel">{settings.bar.label}</p>

          <p className="bottomValue">
            <span className="line" />
            <span className="number">{formatter(min)}</span>
          </p>
        </div>
      </div>
    );
  }

  renderSelectedPrehab(settings) {
    return (
      <div className="detailedInfo">
        <div className="bubbleContainer prehabBubbleContainer">
          <div className="bubble prehabBubble" />
          <div className="bubbleText">
            <p className="day">{settings.day}</p>
            <p className="value">{settings.formatted}</p>
            {settings.result && <p className="result">{settings.result}</p>}
          </div>
        </div>

        <div className="barContainer">
          <div className="bar">
            <div className="roundedBar" />
          </div>

          <div className="labels">
            <p className="topValue">
              <span className="line" />
            </p>

            <p className="centerLabel">
              Within <br /> Range
            </p>

            <p className="bottomValue">
              <span className="line" />
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderChart(metric) {
    const { selectedIndex } = this.state;

    const yDomain = [metric.range.y.start.value, metric.range.y.end.value];
    const xDomain = [
      metric.range.x.start.relative_value,
      Math.max(
        metric.goal ? metric.goal.range.x.end.relative_value : -Infinity,
        metric.range.x.end.relative_value
      ),
    ];

    const selectedItem = metric.metrics[selectedIndex];
    const selectedSettings =
      metric.goal || metric.linear_goal
        ? this._renderSettingsForSelectedItem(selectedItem, metric, yDomain)
        : null;

    // Need to add the labels as data too so I can draw the vertical line on the correct point (the line for this data will be transparant (see rgba 0,0,0,0 line)
    return (
      <div>
        <FlexibleWidthXYPlot
          height={280}
          yDomain={yDomain}
          xDomain={xDomain}
          margin={{ left: 60, right: 20, top: 10, bottom: 40 }}
        >
          {this.renderHorizontalLines(yDomain)}
          {this.renderVerticalLines(metric.range.x_ticks)}
          {this.renderLabels(metric.range.x.labels)}
          {this.renderGoal(metric.goal || metric.linear_goal)}
          {this.renderData(metric.metrics)}
          {(selectedSettings &&
            this.renderSelectedItemDot(selectedSettings.color, selectedItem)) ||
            null}
        </FlexibleWidthXYPlot>

        {(selectedSettings && this.renderSelectedItem(selectedSettings)) ||
          null}
      </div>
    );
  }

  renderLabels(labels) {
    if (!labels) {
      return null;
    }

    return labels.map((label) => (
      <Crosshair
        key={label.relative_value}
        values={[{ x: label.relative_value }]}
      >
        <div className="lineArrow" />
        <div className="lineLabel">{label.formatted}</div>
      </Crosshair>
    ));
  }

  // Renders the action button (only works on mobile, so grayed out)
  renderAction(startInfo) {
    const { showActionButton } = this.props;
    if (!startInfo || !showActionButton) {
      return null;
    }
    return (
      <button className="btn btn-primary" disabled>
        {startInfo.label} (Mobile Only)
      </button>
    );
  }

  renderIsOutOfDate(metric) {
    const { metrics } = metric;
    const { isDashboard } = this.props;
    const newestMetric = metrics[metrics.length - 1].timestamp;
    const daysOld = Math.abs(moment(newestMetric).diff(moment(), "days"));
    const message = isDashboard ? (
      <div className="content">
        <p className="days">
          Patient's last measurement was {daysOld} days ago!
        </p>
        <p>We recommend reaching out to remind them.</p>
      </div>
    ) : (
      <div className="content">
        <p className="days">Your score is {daysOld} days old!</p>
        <p>Measure now to check your recovery status!</p>
      </div>
    );
    return (
      <div className="isOutOfDate">
        <p className="icon">!</p>
        {message}
      </div>
    );
  }

  renderOutro(outro) {
    return (
      <div className="outro">
        <h3>{outro.title}</h3>
        {outro.warning && <p className="warning">{outro.warning}</p>}
        {outro.content && <p>{outro.content}</p>}
      </div>
    );
  }

  render() {
    const { metric, isDashboard } = this.props;
    return (
      <div className="profile-section profile-chart">
        {this.renderHeading(metric)}
        {metric.info.out_of_date && this.renderIsOutOfDate(metric)}
        <br style={{ clear: "both" }} />
        {this.renderChart(metric)}
        {metric.outro && !isDashboard && this.renderOutro(metric.outro)}
        {this.renderAction(metric.start_assessment)}
      </div>
    );
  }
}

Chart.propTypes = {
  // Todo: More specific
  metric: PropTypes.object.isRequired,
  // Defaulting to showing the action button if possible, but makes no sense in dashboard
  showActionButton: PropTypes.bool,
  isDashboard: PropTypes.bool,
  endDate: PropTypes.number,
  registrationDate: PropTypes.number.isRequired,
  daysSinceRegistration: PropTypes.func.isRequired,
  formatter: PropTypes.func.isRequired,
};

Chart.defaultProps = {
  showActionButton: true,
};

export default Chart;
