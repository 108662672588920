import React from "react";
import unwrapComponent from "../../utils/unwrapComponent";

// Use sparingly, only needed if you need to have access to the mounted DOM nodes
class BaseComponent extends React.Component {
  // Handling getting and setting of refs, so stuff can be executed when a ref is attached

  _refs = {};

  // Call this when setting a ref
  setRef(name, ref) {
    if (!ref) {
      delete this._refs[name];
    }

    if (!this._refs[name]) {
      this._refs[name] = {};
    }
    if (ref && this._refs[name].node !== ref) {
      this._refs[name].node = unwrapComponent(ref);
      this._callRefHooks(name);
    }
  }

  // Call this to get a ref
  getRef(name) {
    if (this._refs[name]) {
      return this._refs[name].node;
    }
  }

  // This will get called when the ref changes so every hook is called as wel
  _callRefHooks(name) {
    // Make sure there are hooks
    if (this._refs[name] && Array.isArray(this._refs[name].hooks)) {
      const hooks = this._refs[name].hooks;

      // Pop every hook and call em with the ref
      while (hooks.length > 0) {
        const callback = hooks.pop();
        callback(this.getRef(name));
      }
    }
  }

  // Delayed execution of callback where parameters is the mounted ref
  executeWithRef(name, callback) {
    if (!this._refs[name]) {
      this._refs[name] = {};
    }
    if (!this._refs[name].hooks) {
      this._refs[name].hooks = [];
    }

    // If the ref does not exist delay the execution
    if (!this._refs[name].node) {
      this._refs[name].hooks.unshift(callback);
    }
    // Else execute immediately
    else {
      callback(this.getRef(name));
    }
  }
}

export default BaseComponent;
