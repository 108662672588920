import { createAction } from "redux-actions";

const prefixed = (val) => `Peers/View/Assignedto::${val}`;

export const updateAssignedToForPeer = createAction(
  prefixed("UPDATE_ASSIGNED_TO_FOR_PEER"),
  (peerId, assignedToId) => ({ peerId, assignedToId })
);
export const didUpdateAssignedTo = createAction(
  prefixed("DID_UPDATE_ASSIGNED_TO_FOR_PEER"),
  (peerId, assignedToUser) => ({ peerId, assignedToUser })
);

export const getAvailableAssignedToUsersForPeer = createAction(
  prefixed("GET_AVAILABLE_ASSIGNED_TO_USERS_FOR_PEER"),
  (peerId) => ({ peerId })
);
export const didGetAvailableAssignedToUsersForPeer = createAction(
  prefixed("DID_GET_AVAILABLE_ASSIGNED_TO_USERS_FOR_PEER"),
  (peerId, assignedToUsers) => ({ peerId, assignedToUsers })
);
