import { get } from "lodash";
import { connect } from "react-redux";
import { getFormValues, isInvalid, isPristine } from "redux-form";
import { fleetingFormMeta } from "../../../shared/utils/form";
import { createCoach, updateCoach } from "../actions";
import CoachForm from "./CoachForm.jsx";

function allRequiredFieldsFilledOut(state) {
  const { email } = getFormValues("manageCoach")(state) || {};
  return email;
}

function mapStateToProps(state, { formData }) {
  // if `formData` is passed as a prop to this component, we know it's a form for updating, otherwise it's for creating
  const isUpdating = !!formData;
  const initialValues = formData ?? {
    name: "",
    email: "",
    mfa: true,
  };

  return {
    isUpdating,
    initialValues,
    isEditingSelf:
      isUpdating && get(state, "userInfo.userable_id") === formData.id,
    createDisabled:
      !allRequiredFieldsFilledOut(state) || isInvalid("manageCoach")(state),
    updateDisabled:
      isPristine("manageCoach")(state) ||
      !allRequiredFieldsFilledOut(state) ||
      isInvalid("manageCoach")(state),
    message: fleetingFormMeta(state, "manageCoach").message,
  };
}

const mapDispatchToProps = (dispatch, { formData }) => {
  const isUpdating = !!formData;
  return {
    onSubmit: (values) =>
      dispatch(
        isUpdating
          ? updateCoach({
              ...values,
            })
          : createCoach(values)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoachForm);
