import { handleActions } from "redux-actions";

import { didFindPeers } from "./actions";

export function searchResults(state) {
  return state.ui.PeersList.searchResults;
}

export default handleActions(
  {
    [didFindPeers]: (state, action) => ({
      ...state,
      searchResults: action.payload.results,
    }),
  },
  {
    searchResults: [],
  }
);
