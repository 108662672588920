import { mapObjectToArray } from "../../../../../utils/mapObject";

function chartDataToGrid(chartData, xAxisFormat, xDescription) {
  // Converting the cart data to the following format
  // {
  //   xValue: [yValue1, yValue2],
  //   xValue2: [yValue1, yValue2]
  // }
  const aggregatedData = {};

  for (const line of chartData) {
    for (const point of line.data) {
      if (!aggregatedData[point.x]) {
        aggregatedData[point.x] = [];
      }

      const yValue = line.formatter ? line.formatter(point.y) : point.y;
      aggregatedData[point.x].push(yValue);
    }
  }

  // Converting above data format to a 2 dimensional array
  const grid = mapObjectToArray(aggregatedData, (key, value) =>
    [xAxisFormat(key)].concat(value)
  );

  // Getting the keys
  const valueKeys = [xDescription].concat(
    chartData.map((line) => {
      const label = line.shortLabel ? line.shortLabel : line.label;
      return `${label} - ${line.groupedBy}`;
    })
  );

  // Combinding both
  return [valueKeys].concat(grid);
}

function gridDataToCSV(
  gridData,
  quote = '"',
  separator = ",",
  lineSeparator = "\n"
) {
  const quoted = gridData.map((line) =>
    line.map((value) => `${quote}${value}${quote}`)
  );
  const lines = quoted.map((lineData) => lineData.join(separator));
  return lines.join(lineSeparator);
}

export function chartDataToCSV(chartData, xAxisFormat, xDescription) {
  const grid = chartDataToGrid(chartData, xAxisFormat, xDescription);
  return gridDataToCSV(grid);
}
