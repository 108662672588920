import { connect } from "react-redux";
import { formValueSelector, change } from "redux-form";

import InvitePortal from "./InvitePortal.jsx";
import {
  requestInvitePortalStaff,
  requestInvitePortal,
  postInvitePortal,
} from "./actions";
import { userType } from "../../shared/routes/Authentication/Login/reducer";
import {
  invite,
  isDemoInvite,
  programFormData,
  selectedClinicianId,
  selectedProgramTypes,
} from "./selectors";

const formSelector = formValueSelector("invitePortal");

function mapStateToProps(state) {
  const programTypes = selectedProgramTypes(state);
  const programData = programFormData(state, invite, programTypes);

  const demo = isDemoInvite(state);
  const props = {
    invite: invite(state),
    authenticatedUserType: userType(state),
    surgeryDate: formSelector(state, "surgery_date"),
    clinicianId: selectedClinicianId(state),
    isDemo: demo,
    programData,
    selectedProgramTypes: programTypes,
  };

  if (demo) {
    props.demo = {
      day_plans: 1,
    };

    if (props.invite) {
      props.staff = props.invite.programs[0].staff;
    }
  }

  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    requestInvitePortalStaff: () => dispatch(requestInvitePortalStaff()),
    requestInvitePortal: (clinicianId, isDemo) =>
      dispatch(requestInvitePortal(clinicianId, isDemo)),
    onSubmit: (formValues) => dispatch(postInvitePortal(formValues)),
    clearSurgeryDate: () =>
      dispatch(change("invitePortal", "surgery_date", null)),
    clearProgram: () => dispatch(change("invitePortal", "program", null)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvitePortal);
