import axios from "axios";

export const updateInviteLanguage = (inviteId, language) =>
  axios.put(`/api/admin/v1/invite/${inviteId}`, {
    language,
  });

export const updatePeerLanguage = (peerId, language) =>
  axios.post(`/api/admin/v1/users/${peerId}/update`, {
    about: { lang: language },
  });
