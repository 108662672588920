import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { groupBy } from "lodash";

import "./HazardDetails.css";

const dateFormat = "MM/DD/YYYY";

class HazardDetails extends React.Component {
  renderLabels(pictures, images, auth) {
    const showIndex = auth === "admin";

    const grouped = groupBy(pictures, "label");
    const keys = Object.keys(grouped);
    return keys.map((key) => (
      <div>
        <div className="label_title">
          <h2>{key.charAt(0).toUpperCase() + key.slice(1)}</h2>
          <div className="row">
            {grouped[key] &&
              grouped[key].map(
                (picture) =>
                  picture.file &&
                  images &&
                  images[picture.file] && (
                    <div className="column">
                      <img
                        src={window.URL.createObjectURL(images[picture.file])}
                      />
                      {showIndex && (
                        <p>Index: {picture.confidence.toFixed(2)}</p>
                      )}
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
    ));
  }

  render() {
    const { hazardScore, hazardPictures, hazardImages, auth } = this.props;
    if (!hazardScore || !hazardPictures) return null;
    return (
      <div className="home-assessment">
        {hazardScore &&
          hazardScore.map((score, index) => (
            <div>
              <div className="box home-score">
                <div className="numberCircle">{score}</div>
                <div className="">
                  <h2>
                    Home Hazard Score -{" "}
                    {moment
                      .parseZone(hazardPictures[index][0].date_time)
                      .utc()
                      .format(dateFormat)}
                  </h2>
                  <h5>
                    The HHS is on a scale from 0-10. 0 means no problems were
                    detected and 10 means lots of problems were found
                  </h5>
                </div>
              </div>
              {this.renderLabels(hazardPictures[index], hazardImages, auth)}
            </div>
          ))}
      </div>
    );
  }
}

HazardDetails.propTypes = {
  hazardScore: PropTypes.number,
  hazardPictures: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.object,
  auth: PropTypes.string,
};

HazardDetails.defaultProps = {
  hazardScore: 0,
  hazardPictures: null,
};

export default HazardDetails;
