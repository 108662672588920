import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Link } from "redux-little-router";

import "./RemoveCareTeamMember.css";

function RemoveCareTeamMember({
  onSubmit,
  hidePanel,
  handleSubmit,
  successfulDeleteMessage,
  isDeleting,
  member,
}) {
  return (
    <div className="remove-care-team">
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="confirm-prompt">Remove care team</h1>
        <p>
          Are you sure you want to remove {member.fullname || member.email} from
          this patient's care team?
        </p>
        <div className="btn-container">
          <button
            type="submit"
            className="btn btn-danger delete-button"
            disabled={isDeleting}
          >
            Remove
          </button>
          <button onClick={hidePanel} className="btn" disabled={isDeleting}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

RemoveCareTeamMember.propTypes = {
  member: PropTypes.object.isRequired,
  successfulDeleteMessage: PropTypes.string.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  hidePanel: PropTypes.func.isRequired,
  // Automatically added
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "removeCareTeamMember",
})(RemoveCareTeamMember);
