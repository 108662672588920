import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { validateForm } from "../../../../../../../../shared/utils/form";

import FormGroupField from "./../../../../../../../../shared/components/FormFields/FormGroupField";

const rules = {
  password: {
    presence: true,
    length: {
      minimum: 5,
    },
  },
  repeatPassword: {
    presence: true,
    equality: "password",
  },
};

function ResetPassword({ onSubmit, handleSubmit, message }) {
  return (
    <div>
      <h1>Reset password</h1>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="password"
          component={FormGroupField}
          label="Password"
          required
          type="password"
        />
        <Field
          name="repeatPassword"
          component={FormGroupField}
          label="Repeat password"
          required
          type="password"
        />
        <input type="submit" className="btn btn-primary" value="Update" />
        {message ? <p className="form-message">{message}</p> : null}
      </form>
    </div>
  );
}

ResetPassword.propTypes = {
  userId: PropTypes.string.isRequired,
  message: PropTypes.string,

  // Automatically added
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

ResetPassword.defaultProps = {
  message: null,
};

export default reduxForm({
  form: "resetPassword",
  validate: validateForm(rules),
})(ResetPassword);
