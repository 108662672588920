import { all } from "redux-saga/effects";

import { watchUpdatePasswordSaga } from "./components/ResetPassword/sagas";
import watchUpdateSurgeryDateSaga from "./components/UpdateSurgeryDate/sagas";
import watchUpdateBirthDateSaga from "./components/UpdateBirthDate/sagas";
import watchUpdateProcedureTypeSaga from "./components/UpdateProcedureType/sagas";
import watchUpdateSurgeryComplicationsSaga from "./components/UpdateSurgeryComplications/sagas";
import watchUpdateEmailSaga from "./components/UpdateEmail/sagas";
import watchUpdateAssignedToSaga from "./components/UpdateAssignedTo/sagas";
import watchReleaseUpdateSaga from "./components/Release/sagas";
import watchUpdatePhoneSaga from "./components/UpdatePhone/sagas";
import watchUpdateDetransitionPeerSaga from "./components/DetransitionPeer/sagas";
import watchUpdateNameSaga from "./../../../../components/UserView/components/Forms/UpdateName/sagas";
import watchUpdateBackgroundSaga from "./../../../../components/UserView/components/Forms/UpdateBackground/sagas";
import watchDeletePatientSaga from "./components/DeletePatient/sagas";
import watchAddCareTeamMember from "./components/AddCareTeamMember/sagas";
import watchUpdateCareTeamMember from "./components/UpdateCareTeamMember/sagas";
import watchRemoveCareTeamMember from "./components/RemoveCareTeamMember/sagas";
import watchUpdateOwner from "./components/UpdateOwner/sagas";
import watchUpdateTransitionPeerSaga from "./components/TransitionProgram/sagas";
import watchUpdatePatientPartner from "./components/ChangePartner/sagas";

export default function* watchActionsSaga() {
  yield all([
    watchUpdatePasswordSaga(),
    watchUpdateSurgeryDateSaga(),
    watchUpdateBirthDateSaga(),
    watchUpdateProcedureTypeSaga(),
    watchUpdateSurgeryComplicationsSaga(),
    watchUpdateEmailSaga(),
    watchUpdateNameSaga(),
    watchUpdateAssignedToSaga(),
    watchReleaseUpdateSaga(),
    watchUpdatePhoneSaga(),
    watchUpdateBackgroundSaga(),
    watchUpdateDetransitionPeerSaga(),
    watchUpdateTransitionPeerSaga(),
    watchDeletePatientSaga(),
    watchAddCareTeamMember(),
    watchUpdateCareTeamMember(),
    watchRemoveCareTeamMember(),
    watchUpdateOwner(),
    watchUpdatePatientPartner(),
  ]);
}
