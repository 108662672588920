import { takeLatest } from "redux-saga/effects";
import { setFleetingMessage } from "../../../../../../shared/utils/form";
import { didUpdateExternalService } from "./actions";

export default function* watchFormSaga() {
  yield takeLatest(
    didUpdateExternalService,
    setFleetingMessage("externalService", {
      message: "The changes have been saved.",
    })
  );
}
