import { takeLatest, put, all } from "redux-saga/effects";

import {
  setFleetingMessage,
  submitFormToAPI,
} from "../../../../../../../../shared/utils/form";

import { updateEmailForInvite, didUpdateEmail } from "./actions";
import { updateEmailForInvite as updateEmailForInviteAPI } from "./api";

function* submitEmailUpdate(action) {
  const { inviteId, email } = action.payload;

  yield put(
    submitFormToAPI(
      "updateEmail",
      updateEmailForInviteAPI,
      didUpdateEmail(inviteId, email),
      inviteId,
      email
    )
  );
}

export default function* watchUpdateEmailSaga() {
  yield all([
    takeLatest(updateEmailForInvite, submitEmailUpdate),
    takeLatest(
      didUpdateEmail,
      setFleetingMessage("updateEmail", {
        message: "E-mail has been updated.",
      })
    ),
  ]);
}
