import { connect } from "react-redux";

import UpdateBirthDate from "./../../../../../../components/UserView/components/Forms/UpdateBirthDate";
import { requiresBirthDate, peerInfo } from "../../../../reducer";
import { birthDateMessage } from "./reducer";

import { updateBirthDate, setBirthDateMessage } from "./actions";

function mapStateToProps(state, { userId }) {
  const data = {
    birthDateRequired: requiresBirthDate(state, userId),
    birthDate: peerInfo(state, userId).intake_data.date_of_birth,
    error: birthDateMessage(state),
  };
  return data;
}

function mapDispatchToProps(dispatch, { userId }) {
  return {
    setBirthDate: (date) => dispatch(updateBirthDate(userId, date)),
    clearBirthDateMessage: () => dispatch(setBirthDateMessage(null)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBirthDate);
