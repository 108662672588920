import { createAction } from "redux-actions";

const prefixed = (val) => `Peers/TransitionPeer::${val}`;

export const transitionPeer = createAction(
  prefixed("TRANSITION_PATIENT"),
  (values, userId) => ({ values, userId })
);
export const didTransitionPeer = createAction(
  prefixed("DID_TRANSITION_PATIENT")
);

export const fetchAvailablePrograms = createAction(
  prefixed("FETCH_AVAILABLE_PROGRAMS"),
  (customerId, userId) => ({ customerId, userId })
);
export const didFetchAvailablePrograms = createAction(
  prefixed("DID_FETCH_AVAILABLE_PROGRAMS"),
  (data) => data
);
