import CoreReport from "./CoreReport.js";
import {
  authenticationOfTypeRequired,
  authenticationRequired,
} from "../../../shared/routes/Authentication/Login/sagas";
import watchCoreReportSaga from "./sagas";
import reducer from "./reducer";

export default {
  "/core-report(/:reportType)": {
    component: CoreReport,
    reducer: {
      ui: {
        CoreReports: reducer,
      },
    },
    saga: watchCoreReportSaga,
    title: "Core Report",
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired(["admin"]);
    },
  },
};
