import { createAction } from "redux-actions";

const prefixed = (val) => `Peers/View/Phone::${val}`;

export const updatePhoneForPeer = createAction(
  prefixed("UPDATE_PHONE_FOR_PEER"),
  (peerId, phone) => ({ phone, peerId })
);
export const didUpdatePhone = createAction(
  prefixed("DID_UPDATE_PHONE"),
  (peerId, phone) => ({ phone, peerId })
);
