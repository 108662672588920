import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { validateForm } from "../../../../../../../shared/utils/form";
import { fetchCareTeamMember } from "../../../../../Peer/View/components/Actions/components/AddCareTeamMember/api";

import Set from "./../../../../../../../shared/components/Data/Set";
import SelectField from "./../../../../../../../shared/components/FormFields/SelectField";
import SuccessAnimation from "./../../../../../../../shared/components/SuccessAnimation";

import "./AddCareTeamMember.css";
import { CARE_TEAM_JOB_OPTIONS } from "./careTeamJobOptions";

const EMAIL_REGEX_VALIDATOR =
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

const rules = {};

class AddCareTeamMember extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      job: "",
      foundExistng: false,
      isSelectingJob: false,
      disabled: true,
    };

    this.handleJobChange = this.handleJobChange.bind(this);
    this.handleAddEmail = this.handleAddEmail.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleAddEmail() {
    let { clearApiError } = this.props;
    const { email } = this.state;
    clearApiError();
    // we run a check to see whether this care team member already exists based on email
    fetchCareTeamMember(email).then((res) => {
      if (res && res.data && res.data.total === 1) {
        // care team member already exists, so we skip right to adding them to the patient's care_team
        this.setState({
          isSelectingJob: false,
          foundExistng: true,
          disabled: true,
        });
        this.props.onSubmit(res.data.results[0]);
      } else {
        this.setState({
          isSelectingJob: true,
          foundExistng: false,
          disabled: true,
        });
      }
    });
  }

  handleEmailChange(e) {
    const value = e.target.value;
    const isValidEmail = EMAIL_REGEX_VALIDATOR.test(value);

    this.setState({ email: value, disabled: !isValidEmail });
  }

  handleJobChange(e) {
    this.setState({
      job: e.target.value,
      disabled: e.target.value === "Choose role",
    });
  }

  handleClick(e) {
    let formattedState = {
      email: this.state.email,
      job: this.state.job,
    };
    this.props.onSubmit(formattedState);
    this.setState({ disabled: true });
  }

  render() {
    let { disabled, isSelectingJob, foundExistng, job, email } = this.state;
    let { handleSubmit, onSubmit, message, apiError } = this.props;
    let values = CARE_TEAM_JOB_OPTIONS;
    return (
      <div className="add-care-team">
        {/* fake form to register this as a redux form, but it's not used */}
        <form className="form">
          <Field
            className="fake-field"
            name="fake"
            component={SelectField}
            values={values}
            initialLabel="Hidden"
          />
        </form>
        <h1>Add care team</h1>
        {!isSelectingJob && (
          <div>
            <input
              className="form-control"
              name="email"
              type="email"
              value={email}
              onChange={this.handleEmailChange}
              placeholder="Choose email"
            />
            {message ? (
              <SuccessAnimation message={message} />
            ) : (
              <input
                onClick={this.handleAddEmail}
                disabled={disabled}
                type="button"
                className="btn btn-primary"
                value="Add"
              />
            )}
          </div>
        )}
        {isSelectingJob && (
          <div>
            <Set
              label={""}
              value={email}
              onEditButton={() => {
                this.setState({ isSelectingJob: false, disabled: false });
              }}
            />
            {apiError && apiError.email && apiError.email.length > 0 && (
              <p className="apiError">{apiError.email[0]}</p>
            )}
            <select
              className="form-control care-team-job-select"
              value={job}
              onChange={this.handleJobChange}
            >
              <option className="placeholder">Choose role</option>
              {values.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {message ? (
              <SuccessAnimation message={message} />
            ) : (
              <input
                onClick={this.handleClick}
                disabled={disabled}
                type="button"
                className="btn btn-primary"
                value="Save"
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

AddCareTeamMember.propTypes = {
  userId: PropTypes.string.isRequired,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  clearApiError: PropTypes.func.isRequired,
};

AddCareTeamMember.defaultProps = {
  message: null,
  apiError: null,
};

//export default AddCareTeamMember
export default reduxForm({
  form: "addCareTeamMember",
  validate: validateForm(rules),
})(AddCareTeamMember);
