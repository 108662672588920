import React from "react";
import PropTypes from "prop-types";

import config from "../../../../../config";

import "./ProfilePicture.css";
import ProfileShape from "./resources/profile.svg";

const BaseUrl = config.API_URL;

export default class ProfilePicture extends React.Component {
  constructor(props) {
    const { id } = props;
    super(props);

    this.state = {
      pictureId: id,
    };
  }

  render() {
    const { upload, ownerId } = this.props;
    const { pictureId } = this.state;
    const pictureUrl =
      (pictureId &&
        `${BaseUrl}/images/provider_profile_picture/${pictureId}`) ||
      ProfileShape;

    return (
      <div
        className="profile-picture-container"
        onClick={async () => {
          const pictureId = await upload(ownerId);
          this.setState({ pictureId });
        }}
      >
        <div className="profile-picture-edit-text">Edit</div>
        {<img src={pictureUrl} alt="Profile picture" />}
      </div>
    );
  }
}

ProfilePicture.propTypes = {
  id: PropTypes.string.isRequired,
  upload: PropTypes.func.isRequired,
  ownerId: PropTypes.string.isRequired,
};
