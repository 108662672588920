import axios from "axios";

export const fetchInvitePortalStaff = () => axios.get("/api/admin/v1/invite/");
export const fetchInvitePortalAdmin = ({ clinicianId, demo }) => {
  if (demo && !clinicianId) {
    // If you add ?clinicanId=[clinicianId] while it is null, it actually sends it as string 'null' so its... something :/
    return axios.get(`/api/admin/v1/invite?demo=${demo}`);
  }

  return axios.get(
    `/api/admin/v1/invite?clinician_id=${clinicianId}&demo=${demo}`
  );
};
export const postInvitePortal = (formValues) =>
  axios.post("/api/admin/v1/invite/", formValues);
