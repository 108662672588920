import { connect } from "react-redux";
import UpdateAssignedTo from "./../../../../../../components/UserView/components/Forms/UpdateAssignedTo";

import {
  updateAssignedToForPeer,
  getAvailableAssignedToUsersForPeer,
} from "./actions";

import { availableAssignedToUsersForPeer, currentAssignedTo } from "./reducer";

export function mapStateToProps(state, { userId }) {
  const assignedToForPeer = currentAssignedTo(state, userId);
  return {
    availableAssignedToUsers: availableAssignedToUsersForPeer(state, userId),
    assignedTo: assignedToForPeer && assignedToForPeer.id,
  };
}

function mapDispatchToProps(dispatch, { userId }) {
  return {
    fetchAvailableAssignedToUsers: () =>
      dispatch(getAvailableAssignedToUsersForPeer(userId)),
    onAssignedToChange: (e) => {
      let value = e.target.value;
      if (value === "-") {
        value = null;
      }
      return dispatch(updateAssignedToForPeer(userId, value));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAssignedTo);
