import { createAction } from "redux-actions";

const prefixed = (val) => `InvitePortal::${val}`;

export const requestInvitePortalStaff = createAction(
  prefixed("REQUEST_INVITE_PORTAL_STAFF")
);
export const requestInvitePortal = createAction(
  prefixed("REQUEST_INVITE_PORTAL_ADMIN"),
  (clinicianId, demo) => ({ clinicianId, demo })
);
export const didFetchInvitePortal = createAction(
  prefixed("DID_FETCH_INVITE_PORTAL_ADMIN"),
  (invitePortalData) => invitePortalData
);
export const postInvitePortal = createAction(
  prefixed("POST_INVITE"),
  (formValues) => formValues
);
export const didPostInvitePortal = createAction(
  prefixed("DID_POST_INVITE"),
  (formValues) => formValues
);
