import { ModalPanelRedux } from "./ModalPanel";
import { LoadingIndicatorRedux } from "./LoadingIndicator";
import { ErrorIndicatorRedux } from "./ErrorIndicator";

export default Object.assign(
  {},
  ModalPanelRedux,
  LoadingIndicatorRedux,
  ErrorIndicatorRedux
);
