import React from "react";
import Lottie from "react-lottie";
import checkmarkAnimation from "./blue-checkmark.json";

import "./SuccessAnimation.css";

function SuccessAnimation({ message }) {
  if (!message) return null;

  const defaultCheckmarkOptions = {
    loop: false,
    autoplay: true,
    animationData: checkmarkAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="animation-group">
      <Lottie options={defaultCheckmarkOptions} height={35} width={35} />
      <span className="success-message">{message}</span>
    </div>
  );
}

export default SuccessAnimation;
