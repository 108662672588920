import axios from "axios";

export async function uploadPicture(file) {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("type", "provider_profile_picture");

  const url = "/api/v3/images";

  return axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function attachPicture(ownerId, pictureId) {
  return axios.patch(`/api/admin/v1/owners/${ownerId}`, { picture: pictureId });
}
