import React, { useState } from "react";
import PropTypes from "prop-types";

function UpdateLanguage({
  currentLanguage,
  availableLanguages,
  message,
  updateLanguage,
}) {
  const [language, setLanguage] = useState(currentLanguage || "en");

  const pickLanguage = (e) => setLanguage(e.target.value);
  const submitLanguage = (e) => {
    e.preventDefault();
    updateLanguage(language);
  };

  return (
    <div>
      <h1>Update language</h1>
      <form className="form" onSubmit={submitLanguage}>
        <label htmlFor="language">Current</label>
        <select
          className="form-control"
          name="language"
          id="language"
          onChange={pickLanguage}
          value={language}
        >
          {availableLanguages.map((l) => (
            <option key={l.value} value={l.value}>
              {l.label}
            </option>
          ))}
        </select>
        <br />
        <input type="submit" className="btn btn-primary" value="Update" />
      </form>
      <br />
      {message ? <p className="form-message">{message}</p> : null}
    </div>
  );
}

UpdateLanguage.propTypes = {
  currentLanguage: PropTypes.string,
  message: PropTypes.string,
  availableLanguages: PropTypes.array.isRequired, // { label: string, value: string }
  updateLanguage: PropTypes.func.isRequired, // (newLanguage) => void
};

export default UpdateLanguage;
