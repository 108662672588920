import { connect } from "react-redux";

import { fleetingFormMeta } from "../../../../../shared/utils/form";
import { clinicianInitialValues } from "../../reducer";

import UpdateClinicianForm from "./UpdateClinicianForm.jsx";

function mapStateToProps(
  state,
  { onSubmit, onCancel, clinician, programList }
) {
  return {
    initialValues: clinicianInitialValues(state),
    onSubmit,
    onCancel,
    clinician,
    programList,
    message: fleetingFormMeta(state, "manageClinician").message,
  };
}

export default connect(mapStateToProps)(UpdateClinicianForm);
