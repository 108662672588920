import { handleActions } from "redux-actions";
import {
  didCreateProgram,
  didSimulateProgram,
  didUpdateProgram,
} from "./actions";

export default handleActions(
  {
    [didCreateProgram]: (state, action) => {
      const { id } = action.payload;
      const programs = { ...state.programs };
      const currentProgram = programs[id];
      programs[id] = Object.assign({}, currentProgram, action.payload);
      const list = state.list.slice();
      list.push(programs[id]);
      return { ...state, programs, list };
    },
    [didUpdateProgram]: (state, action) => {
      const { id } = action.payload;
      const programs = { ...state.programs };
      const currentProgram = programs[id];
      programs[id] = Object.assign({}, currentProgram, action.payload);
      const list = [...state.list] || [];
      const programIndex = list.findIndex((program) => program.id === id);
      list[programIndex] = programs[id];
      return { ...state, programs, list };
    },
    [didSimulateProgram]: (state, action) => {
      const { simulation, programId } = action.payload;
      const programs = { ...state.programs };
      const currentProgram = programs[programId];
      currentProgram.simulation = simulation;
      programs[programId] = Object.assign({}, currentProgram);
      return { ...state, programs };
    },
  },
  {}
);
