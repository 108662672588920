import { connect } from "react-redux";

import ModalPanel from "./ModalPanel.jsx";

import { shouldShowPanel } from "./reducer";
import { hidePanel } from "./actions";

function route(state) {
  return state.router.route.split("/")[1].replace("(", ""); // Replacement is for routes that have something like /foo(/:optional) so it doesn't become /foo(
}

function mapStateToProps(state, ownProps) {
  return {
    visible: shouldShowPanel(state, ownProps.name),
    route: route(state),
  };
}

const dispatch = {
  onCloseButton: hidePanel,
};

export default connect(mapStateToProps, dispatch)(ModalPanel);
