import React from "react";
import PropTypes from "prop-types";

import "./BottomBar.css";

class BottomBar extends React.Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
  }

  onCancel(e) {
    const { onCancel } = this.props;
    e.preventDefault();
    onCancel();
    return false;
  }

  render() {
    return (
      <div className="bottom-bar">
        <div className="pull-right buttons">
          <button className="btn btn-cancel" onClick={this.onCancel}>
            Cancel
          </button>
          <input
            type="submit"
            value="Save"
            className="btn btn-primary"
            data-test="submit"
          />
        </div>
      </div>
    );
  }
}

BottomBar.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default BottomBar;
