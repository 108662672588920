import { createAction } from "redux-actions";

const prefixed = (val) => `MANAGE/CARE_TEAM_USERS::${val}`;

export const requestAllUsers = createAction(
  prefixed("REQUEST_ALL_CARE_TEAM_USERS")
);
export const didFetchAllUsers = createAction(
  prefixed("DID_FETCH_ALL_CARE_TEAM_USERS")
);

export const requestUser = createAction(prefixed("REQUEST_CARE_TEAM_USER"));
export const didFetchUser = createAction(prefixed("DID_FETCH_CARE_TEAM_USER"));

export const updateUser = createAction(
  prefixed("UPDATE_CARE_TEAM_USER"),
  (values) => values
);
export const didUpdateUser = createAction(prefixed("DID_UPDATE_USER"));
