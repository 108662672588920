import { connect } from "react-redux";

import List from "./List.jsx";

import { requestPeers } from "./sagas";

import { totalNumberOfPeers, peers } from "./reducer";
import { query } from "../../../../shared/routes/query";
import { filterForType } from "./components/Filter/reducer";

function mapStateToProps(state, ownProps) {
  return {
    peers: peers(state),
    totalNumberOfPeers: totalNumberOfPeers(state),
    currentPage: state.router.params.page
      ? parseInt(state.router.params.page, 10)
      : 0,
    filter: query(state, "filter"),
    counts: {
      customers: filterForType(state, "customers").length,
      clinicians: filterForType(state, "clinicians").length,
      registrationCodes: filterForType(state, "registration_codes").length,
    },
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchPeers: (page, filter) => dispatch(requestPeers(page, filter)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
