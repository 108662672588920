import { handleActions } from "redux-actions";
import { didDeleteModule } from "./actions";

export default handleActions(
  {
    [didDeleteModule]: (state, action) => {
      const moduleId = action.payload;
      const { [moduleId]: ignored, ...modules } = { ...state.modules };
      const list = state.list
        .slice()
        .filter((module) => module.id !== moduleId);
      return { ...state, modules, list };
    },
  },
  {}
);
