import { handleActions } from "redux-actions";

import { incrementLoadingCount, decrementLoadingCount } from "./actions";

export const shouldDisplayLoadingIndicator = (state) =>
  state.ui.LoadingIndicator.loadingCount > 0;

export default handleActions(
  {
    [incrementLoadingCount]: (state, action) => ({
      ...state,
      loadingCount: state.loadingCount + action.payload,
    }),
    [decrementLoadingCount]: (state, action) => ({
      ...state,
      loadingCount: state.loadingCount - action.payload,
    }),
  },
  {
    loadingCount: 0,
  }
);
