import { handleActions } from "redux-actions";

import { showError, hideError } from "./actions";

export const shouldDisplayErrorIndicator = (state) =>
  state.ui.ErrorIndicator.showError;
export const error = (state) => state.ui.ErrorIndicator.message;

export default handleActions(
  {
    [showError]: (state, action) => ({
      ...state,
      showError: true,
      message: action.payload.error,
    }),
    [hideError]: (state, action) => ({
      ...state,
      showError: false,
      message: action.payload.error,
    }),
  },
  {
    showError: false,
  }
);
