import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classNames from "classnames";

import DateInputField from "../../DateInputField";

import "./PickDate.css";

class PickDate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newPickDate: props.pickDate,
    };

    this._enablePickDate = this._enablePickDate.bind(this);
    this._disablePickDate = this._disablePickDate.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  // If our props get a new pick date use that as base
  componentWillReceiveProps(nextProps) {
    if (this.props.pickDate !== nextProps.pickDate) {
      this.setState({
        newPickDate: nextProps.pickDate,
      });
    }
  }

  _enablePickDate() {
    // Todo: Focus
  }

  _disablePickDate() {
    this.setState({ newPickDate: 0 });
  }

  // Todo: On surgery date checkbox send current date
  _onChange(timestamp) {
    this.setState({ hasPickDate: true, newPickDate: timestamp });
  }

  render() {
    const {
      error,
      pickDateRequired,
      setPickDate,
      programType,
      pickDate,
      isRegistered,
    } = this.props;
    const { newPickDate } = this.state;

    let minDate;
    let maxDate = moment().add(2, "years");
    if (isRegistered && programType === "rehab") {
      // if patient is registered and in rehab, we restrict the date range to the past only
      maxDate = moment();
    }
    if (isRegistered && programType === "prehab") {
      // if patient is registered and in prehab, we restrict the date range to the future only
      minDate = moment();
    }

    const selectedDate = this.props.pickDate
      ? `${moment(this.props.pickDate).format("MM/DD/YY")}`
      : "No Date Set";
    const pristine = this.state.newPickDate === pickDate;

    return (
      <div className="pick-date-profile">
        <h3
          className={classNames("current-date", {
            noDateSet: !this.props.pickDate,
          })}
        >
          {selectedDate}
        </h3>
        <p className="error">{error}&nbsp;</p>
        <div className="calendar-container">
          <DateInputField
            className="inline date-field"
            value={newPickDate}
            onDateChange={this._onChange}
            onMonthChange={this._onChange}
            minDate={minDate}
            maxDate={maxDate}
          />
          {!pickDateRequired && programType === "prehab" && (
            <div className="no-date-group">
              <input
                type="checkbox"
                checked={newPickDate === 0}
                className="form-control no-date-checkbox"
                onClick={this._disablePickDate}
              />
              <label className="no-date-label">No date</label>
            </div>
          )}
        </div>
        <button
          className="btn btn-sm change-date-button"
          onClick={() => setPickDate(newPickDate)}
          disabled={pristine}
        >
          Update
        </button>
      </div>
    );
  }
}

PickDate.propTypes = {
  pickDate: PropTypes.number.isRequired,
  setPickDate: PropTypes.func.isRequired,
  pickDateRequired: PropTypes.bool.isRequired,
  userType: PropTypes.string,
  error: PropTypes.string,
};

// userType is only defined in UpdateSurgeryDate datepickers
PickDate.defaultProps = {
  userType: undefined,
  error: undefined,
};

export default PickDate;
