import { takeLatest, put } from "redux-saga/effects";

import {
  updateBirthDate,
  didUpdateBirthDate,
  setBirthDateMessage,
} from "./actions";
import { updateBirthDate as updateBirthDateAPI } from "./api";

function* update(action) {
  yield put(setBirthDateMessage(null));

  const payload = action.payload;
  try {
    yield updateBirthDateAPI(payload.peerId, payload.date);
    yield put(setBirthDateMessage("Birthdate has been updated"));
    yield put(didUpdateBirthDate(payload.peerId, payload.date));
  } catch (e) {
    yield put(setBirthDateMessage("Birthdate has not been updated")); // waiting for update backend response data
    // const error = e.response.data.error.intake_data.date_of_birth[0]
    // const shouldBeInPast = error.indexOf('less than')

    // const message = shouldBeInPast ? 'Must be in the past' : 'Must be in the future'
    // yield put(setBirthDateMessage(message))
  }
}

export default function* watchUpdateBirthDate() {
  yield takeLatest(updateBirthDate, update);
}
