import Cards from "./Cards.js";
import watchCards from "./sagas";
import {
  authenticationRequired,
  authenticationOfTypeRequired,
} from "../../../shared/routes/Authentication/Login/sagas";
import { setQueryDefaultsIfNeeded } from "../../../shared/components/Metrics/sagas";
import reducer from "./reducer";

export default {
  "/cards-metrics": {
    component: Cards,
    saga: watchCards,
    reducer: {
      ui: {
        CardMetrics: reducer,
      },
    },
    title: "Cards Metrics",
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired(["admin", "demo"]);
      yield setQueryDefaultsIfNeeded();
    },
  },
};
