import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import { validateForm } from "../../../../../shared/utils/form";

import SimpleField from "../../../../../shared/components/FormFields/SimpleField";
import SelectField from "../../../../../shared/components/FormFields/SelectField";
import CheckboxField from "../../../../../shared/components/FormFields/CheckboxField";

const rules = {
  name: { presence: true },
  type: { presence: true },
  send_notifications: { presence: true },
};

class UpdateCustomerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      warning: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  // eslint-disable-next-line consistent-return
  handleChange(e) {
    const currentType = e.target && e.target.value;
    const initialType = this.props.initialValues.type;

    if (!currentType) {
      // no need to warn since error message takes precedence here
      return this.setState({ warning: false });
    }

    const typeHasChanged = currentType !== initialType;

    this.setState({ warning: typeHasChanged });
  }

  render() {
    const {
      handleSubmit,
      onSubmit,
      onCancel,
      customer,
      customerTypes,
      message,
    } = this.props;
    const { warning } = this.state;
    const notificationTypes = [
      { label: "Default", value: "default" },
      { label: "True", value: "true" },
      { label: "False", value: "false" },
    ];
    const defaultNotificationValue =
      customer.settings &&
      customer.settings.hasOwnProperty("send_notifications")
        ? customer.settings.send_notifications
        : "default";

    return (
      <form className="manage-customer-form" onSubmit={handleSubmit(onSubmit)}>
        <p className="form-header">
          <span>Update Information for:</span> {customer.name}
        </p>

        <div>
          <label htmlFor="name">Name</label>
          <Field className="edit-field" name="name" component={SimpleField} />
        </div>

        <Field
          name="type"
          label="Partner Type"
          values={customerTypes}
          defaultValue={customer.type}
          component={SelectField}
          onChange={this.handleChange}
        />
        {warning && (
          <p className="update-customer-type-warning">
            You have changed the customer's original type. If you click
            "Update", data may be lost. Please be sure you really intend on
            making this change.
          </p>
        )}

        <Field
          name="send_notifications"
          label="Send notifications"
          values={notificationTypes}
          defaultValue={defaultNotificationValue}
          component={SelectField}
        />

        <input className="action-btn btn-danger" type="submit" value="Update" />
        <button className="action-btn" onClick={onCancel}>
          Cancel
        </button>

        {message && <p className="message">{message}</p>}
      </form>
    );
  }
}

UpdateCustomerForm.propTypes = {
  customer: PropTypes.object,
  customerTypes: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string,
  // redux-form
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

UpdateCustomerForm.defaultProps = {
  customer: null,
  customerTypes: null,
  message: null,
};

export default reduxForm({
  form: "manageCustomer",
  validate: validateForm(rules),
  enableReinitialize: true,
})(UpdateCustomerForm);
