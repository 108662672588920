export enum SortDirection {
  Ascending = "asc",
  Descending = "desc",
}

export interface SortableData {
  sortBy: string;
  sortDirection: SortDirection;
  page: number;
  perPage: number;
  searchString?: string;
}
