import { getProgramsAxios } from "../../../../services/api";

export const createPhase = (phase) => getProgramsAxios().post("/phases", phase);
export const updatePhase = (phaseId, updates) =>
  getProgramsAxios().patch(`/phases/${phaseId}`, updates);

export async function fetchAllModules(fromPage = 0) {
  const modules = await fetchModulesPage(fromPage);
  const allFetched = modules.length < perPage;
  if (allFetched) {
    return modules;
  }
  return [...modules, ...(await fetchAllModules(fromPage + 1))];
}

const fetchModulesPage = async (page) =>
  (await getProgramsAxios().get(`/modules?page=${page}&per_page=${perPage}`))
    .data;

const perPage = 500;
