import {
  authenticationOfTypeRequired,
  authenticationRequired,
} from "../../../shared/routes/Authentication/Login/sagas";

import ManageClinician from "./ManageClinician.js";
import { ManageClinicianReducer } from "./reducer";
import watchManageClinician from "./sagas";

// TODO: flat routes for now, but refactor to nest it like so: /manage/customer
export default {
  "/manage-clinician": {
    component: ManageClinician,
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired(["admin"]);
    },
    reducer: {
      ui: {
        ManageClinician: ManageClinicianReducer,
      },
    },
    saga: watchManageClinician,
    title: "Manage Clinician",
  },
};

export const emailRegexValidator =
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
