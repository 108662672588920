import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../shared/app/requestErrorHandler";
import { callAPI } from "../../shared/utils/sagas";
import {
  didFetchCard,
  didFetchCardsList,
  fetchCard,
  fetchCardsList,
} from "./actions";
import {
  fetchCard as fetchCardAPI,
  fetchCardsList as fetchCardsListAPI,
} from "./api";
import watchCardsListSaga from "./components/CardsList/saga";

function* fetchCardsListSaga(action) {
  try {
    const response = yield fetchCardsListAPI(action.payload);
    const count = response.headers["x-total-count"];
    yield put(didFetchCardsList(response.data, count));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* watchCardsSaga() {
  yield all([
    takeLatest(fetchCard, callAPI(fetchCardAPI, didFetchCard)),
    takeLatest(fetchCardsList, fetchCardsListSaga),
  ]);
}

export default function* () {
  yield all([watchCardsSaga(), watchCardsListSaga()]);
}
