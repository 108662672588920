import { get } from "lodash";
import { handleActions } from "redux-actions";
import { didFetchCoaches } from "./actions";
import moment from "moment";

export function results(state) {
  const searchResults = get(state, "ui.Coaches.search.results");

  if (!searchResults) return null;

  return searchResults.map(
    ({ id, email, name, mfa, disabled, activated, created_at }) => ({
      // for populating the list rows
      ...{ id, email, name, mfa, disabled, activated, created_at },
      // for populating the edit form
      formData: {
        id,
        email,
        name: name ?? "",
        mfa,
        disabled,
      },
    })
  );
}

export function count(state) {
  return get(state, "ui.Coaches.search.count");
}

function truncate(longString) {
  return `${longString.substr(0, 27)}...`;
}

export function columns() {
  return [
    {
      columnLabel: "Name",
      displayValue: ({ name }) => getBoundedString(name),
      sortBy: "name",
    },
    {
      columnLabel: "Email",
      displayValue: ({ email }) => getBoundedString(email),
      sortBy: "email",
    },
    {
      columnLabel: "Registered",
      displayValue: ({ activated }) => (activated ? "Y" : "N"),
      sortBy: "activated",
    },
    {
      columnLabel: "Blocked",
      displayValue: ({ disabled }) => (disabled ? "Y" : "N"),
      sortBy: "disabled",
    },
    {
      columnLabel: "MFA",
      displayValue: ({ mfa }) => (mfa ? "Y" : "N"),
    },
    {
      columnLabel: "Date Added",
      displayValue: ({ created_at }) => moment(created_at).format("MM/DD/YYYY"),
      sortBy: "created_at",
    },
  ];
}

const getBoundedString = (value, limit = 50) =>
  value?.length > limit ? truncate(value) : value;

export const CoachesReducer = handleActions(
  {
    [didFetchCoaches]: (state, action) => ({
      ...state,
      search: action.payload,
    }),
  },
  {
    search: null,
  }
);
