import ReactDOM from "react-dom";

import { RouterFragments } from "./router";
import { store, ReduxProvider } from "./store";

import NotificationCenter from "./../components/NotificationCenter";
import { IdleTimerProvider } from "react-idle-timer";
import { logIdleUserOut } from "./idleTimer";

const timeout_in_minutes = 120;
const timeout = 1000 * 60 * timeout_in_minutes;
// const timeout = 1000 * 15 -- for debug

// XXX ReactDOM.render is legacy. Ref: https://reactjs.org/docs/react-dom.html
ReactDOM.render(
  <ReduxProvider store={store}>
    <IdleTimerProvider
      timeout={timeout} // Idle time until onPrompt fires
      onIdle={() => {
        logIdleUserOut();
      }}
    >
      <div>
        <NotificationCenter />
        <RouterFragments store={store} />
      </div>
    </IdleTimerProvider>
  </ReduxProvider>,
  document.getElementById("root")
);
