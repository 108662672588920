import React from "react";
import PropTypes from "prop-types";
import { orderBy } from "lodash";

import "./ModulesList.css";
import Module from "./components/Module/Module";

class ModulesList extends React.Component {
  constructor(props) {
    super(props);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onSelectedChange = this.onSelectedChange.bind(this);
  }

  onFilterChange(e) {
    const { updateFilter } = this.props;
    let filter = e.target.value.trim().toLowerCase();
    if (filter.length === 0) {
      filter = null;
    }
    updateFilter(filter);
  }

  onSelectedChange(e) {
    const { onShowDeletedChange } = this.props;
    onShowDeletedChange(e.target.value === "true");
  }

  render() {
    const { modulesList, onAddModuleClicked, showDeleted } = this.props;

    if (!modulesList) {
      return null;
    }
    const orderedModules = orderBy(modulesList, (module) =>
      module.name.toLowerCase()
    );

    const emptyModules = orderedModules.length === 0;

    const title = "All Modules";
    const emptyMessage = "No modules found";

    return (
      <div className="modules-page" data-test="modules-page">
        <div className="row top">
          <div className="col-md-3">
            <h2>{title}</h2>
          </div>
          <div className="col-md-3">
            <button
              className="btn btn-primary"
              onClick={onAddModuleClicked}
              data-test="add-module"
            >
              Add Module
            </button>
          </div>
          <div className="col-md-3">
            <select value={showDeleted} onChange={this.onSelectedChange}>
              <option value={false}>Deleted not included</option>
              <option value>Include deleted</option>
            </select>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className="pull-right form-control form-control-small"
              placeholder="Filter modules"
              onChange={this.onFilterChange}
              data-test="filter-modules"
            />
          </div>
        </div>

        <p className="doubleclick">double click to edit</p>

        {(emptyModules && (
          <p className="empty-message" data-test="empty-message">
            {emptyMessage}
          </p>
        )) || (
          <ul className="modules-list" data-test="modules-list">
            {orderedModules.map((module) => (
              <Module {...module} key={module.id} />
            ))}
          </ul>
        )}
      </div>
    );
  }
}

ModulesList.propTypes = {
  modulesList: PropTypes.arrayOf(PropTypes.object),
  onAddModuleClicked: PropTypes.func.isRequired,
  onShowDeletedChange: PropTypes.func.isRequired,
  showDeleted: PropTypes.bool,
  filter: PropTypes.string,
  updateFilter: PropTypes.func.isRequired,
  selectedModule: PropTypes.object,
};

ModulesList.defaultProps = {
  showDeleted: false,
  filter: null,
};

export default ModulesList;
