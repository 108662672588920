import { connect } from "react-redux";

import UpdateSurgeryDate from "./../../../../../../components/UserView/components/Forms/UpdateSurgeryDate";
import { userType } from "../../../../../../../../shared/routes/Authentication/Login/reducer";
import { invite, requiresSurgeryDate } from "../../../../reducer";
import { surgeryDateMessage } from "./reducer";

import { updateSurgeryDate, setSurgeryDateMessage } from "./actions";

function mapStateToProps(state, { userId }) {
  const selectedInvite = invite(state, userId);

  return {
    surgeryDateRequired: requiresSurgeryDate(selectedInvite),
    surgeryDate: selectedInvite.surgery_date,
    userType: userType(state),
    programType: selectedInvite.program_kind,
    error: surgeryDateMessage(state),
  };
}

function mapDispatchToProps(dispatch, { userId }) {
  return {
    setSurgeryDate: (date) => dispatch(updateSurgeryDate(userId, date)),
    clearSurgeryDateMessage: () => dispatch(setSurgeryDateMessage(null)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSurgeryDate);
