import {
  authenticationOfTypeRequired,
  authenticationRequired,
} from "../../shared/routes/Authentication/Login/sagas";

import Team from "./Team.js";
import { TeamReducer } from "./reducer";
import watchTeam from "./sagas";

export default {
  "/team": {
    component: Team,
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired(["admin", "partner_admin"]);
    },
    reducer: {
      ui: {
        Team: TeamReducer,
      },
    },
    saga: watchTeam,
    title: "Team",
  },
};
