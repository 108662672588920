import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./ColoredBar.css";

function ColoredBar({ className, score, sections, minScore }) {
  const scoreToDisPlay = Math.max(score, minScore);
  return (
    <div className={classNames("colored-bar", className)}>
      <div className="background-color">
        <div
          className="inner-colored-bar"
          style={{ width: `${scoreToDisPlay * 100}%` }}
        />
      </div>
      {sections && (
        <table className="sections">
          <tbody>
            <tr>
              {sections.map((section) => (
                <td key={section}>{section}</td>
              ))}
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

ColoredBar.propTypes = {
  className: PropTypes.string,
  // Between 0 and 1
  score: PropTypes.number.isRequired,
  sections: PropTypes.arrayOf(PropTypes.string),
  // If you pass this, the bar wont ever empty. Which looks better in some cases
  minScore: PropTypes.number,
};

ColoredBar.defaultProps = {
  minScore: 0,
};

export default ColoredBar;
