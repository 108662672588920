import React from "react";
import PropTypes from "prop-types";
import { Link } from "redux-little-router";
import moment from "moment";
import inflection from "inflection";
import { get } from "lodash";

import { extraFieldsShape } from "../../proptypes";

function Row({ peer, extraFields }) {
  const surgeryDate = peer.surgery_date;
  const registerDate = peer.registered_at;
  const formattedSurgeryDate = surgeryDate
    ? moment(surgeryDate).format("MMMM Do YYYY")
    : "-";
  const formattedRegisterDate = registerDate
    ? moment(registerDate).format("MMMM Do YYYY")
    : "-";

  const inviter = peer.inviter
    ? `${peer.inviter.email} (${inflection.humanize(
        peer.inviter.userable_type
      )})`
    : "-";

  return (
    <tr>
      <td>
        {peer.about && (
          <Link href={`/peers/view/${peer.id}`}>{peer.about.name}</Link>
        )}{" "}
        {peer.warning ? <span className="warning">{peer.warning}</span> : null}
      </td>
      <td>{peer.about && (peer.about.email || "-")}</td>
      <td>{formattedSurgeryDate}</td>
      <td>{peer.clinician && (peer.clinician.name || "-")}</td>
      <td>
        {peer.about && (
          <Link
            href={`/peers/overview/0/?filter[registration_codes]=${peer.about.registration_code}`}
          >
            {peer.about.registration_code}
          </Link>
        )}
      </td>
      <td>
        {peer.intake_data &&
          inflection.humanize(peer.intake_data.procedure_type || "")}
      </td>
      <td>{peer.customer && (peer.customer.name || "-")}</td>
      <td>{inviter}</td>
      <td>{formattedRegisterDate}</td>
      {extraFields.map((field) => (
        <td key={field.fullPath}>
          {peer.external_service
            ? get(peer.external_service.data, field.fullPath, "-")
            : "-"}
        </td>
      ))}
    </tr>
  );
}

Row.propTypes = {
  peer: PropTypes.object.isRequired,
  extraFields: PropTypes.arrayOf(PropTypes.shape(extraFieldsShape)),
};

Row.defaultProps = {
  extraFields: [],
};

export default Row;
