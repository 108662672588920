import { success } from "react-notification-system-redux";
import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../../../shared/app/requestErrorHandler";
import {
  didFetchPinnedCards,
  didFetchProgramCards,
  didPinCard,
  didUnpinCard,
  fetchPinnedCards,
  fetchProgramCards,
  pinCard,
  unpinCard,
} from "./actions";
import {
  fetchPinnedCardsAPI,
  fetchProgramCardsAPI,
  pinCardAPI,
  unpinCardAPI,
} from "./api";

function* notifyUser() {
  yield put(success({ message: "The pinned cards changes have been saved." }));
}

function* fetchPinnedCardsListSaga(action) {
  try {
    const response = yield fetchPinnedCardsAPI(action.payload);
    const count = response.headers["x-total-count"];
    yield put(didFetchPinnedCards(response.data, count, action.payload));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* fetchProgramCardsListSaga(action) {
  try {
    const response = yield fetchProgramCardsAPI(action.payload);
    const count = response.headers["x-total-count"];
    yield put(didFetchProgramCards(response.data, count, action.payload));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* pinCardSaga(action) {
  try {
    const { programId, values } = action.payload;
    yield pinCardAPI(programId, values);
    yield put(didPinCard(programId));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* unpinCardSaga(action) {
  try {
    const { programId, cardId } = action.payload;
    yield unpinCardAPI(programId, cardId);
    yield put(didUnpinCard(programId));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* watchPinnedCardsSaga() {
  yield all([
    takeLatest(fetchPinnedCards, fetchPinnedCardsListSaga),
    takeLatest(fetchProgramCards, fetchProgramCardsListSaga),
    takeLatest(pinCard, pinCardSaga),
    takeLatest(didPinCard, fetchPinnedCardsListSaga),
    takeLatest(didPinCard, notifyUser),
    takeLatest(didUnpinCard, fetchPinnedCardsListSaga),
    takeLatest(didUnpinCard, notifyUser),
    takeLatest(unpinCard, unpinCardSaga),
  ]);
}

export default function* () {
  yield all([watchPinnedCardsSaga()]);
}
