import axios from "axios";
import { getPreventionAxios } from "../../../../services/api";

export const fetchPeer = (peerId) => axios.get(`/api/admin/v1/users/${peerId}`);
// NEEDS TO BE V3 not 3.1) else the chart axis is messed up. This loads the older chart types
// The newer chart types are the ones for knee flexion etc, that is on 3.1
export const fetchPeerProfile = (peerId) =>
  axios.get(`/api/v3/peer/profile/${peerId}`);
export const fetchCardInteractions = (peerId) =>
  axios.get(`/api/admin/v1/users/${peerId}/card_interactions`);
export const fetchPeerDeviceInfo = (peerId) =>
  axios.get(`/api/admin/v1/users/${peerId}/device_features`);
export const fetchPeerHazardPictures = (peerId) =>
  axios.get(
    `/api/v3/mhealth?schema_id[namespace]=exercises&schema_id[name]=hazard&peer_id=${peerId}`
  );
export const fetchPeerHazardImage = (peerId, path, name) =>
  axios.get(`/api/admin/v1/patients/${peerId}/hazard-images/${path}/${name}`, {
    responseType: "blob",
  });
export const fetchPeerProgramParts = (peerId) =>
  axios.get(`/api/admin/v1/patients/${peerId}/program_parts`);
export const fetchCareTeam = (peerId) =>
  axios.get(`/api/admin/v1/patients/${peerId}/care_team`);
export const fetchOwner = (peerId) =>
  axios.get(`/api/admin/v1/patients/${peerId}/owner`);

export const fetchWorker = (workerId) => {
  return getPreventionAxios().get(`/workers/${workerId}`);
};
export const fetchWorkerEngagement = async (workerId) => {
  const dto = await getPreventionAxios().get(`/workers/${workerId}/engagement`);
  dto.data.id = workerId;
  return dto;
};
