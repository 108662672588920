import React from "react";
import PropTypes from "prop-types";

import "./Modules.css";
import ModulesList from "./components/ModulesList/ModulesList";
import ModulesForm from "./components/ModulesForm";
import Pagination from "../../shared/components/Pagination";

class Modules extends React.Component {
  componentDidMount() {
    this._fetchModule(this.props);
    this.props.fetchModulesList(this.props.showDeleted, this.props.filter, 0);
  }

  componentWillReceiveProps(nextProps) {
    const {
      selectedModuleId,
      showDeleted,
      fetchModulesList,
      filter,
      resetPage,
      page,
    } = this.props;
    if (
      nextProps.selectedModuleId &&
      selectedModuleId !== nextProps.selectedModuleId
    ) {
      this._fetchModule(nextProps);
    } else if (
      showDeleted !== nextProps.showDeleted ||
      filter !== nextProps.filter
    ) {
      fetchModulesList(nextProps.showDeleted, nextProps.filter, 0);
      resetPage();
    } else if (page !== nextProps.page) {
      fetchModulesList(showDeleted, filter, nextProps.page);
    }
  }

  _fetchModule(props) {
    const { selectedModuleId, fetchModule } = props;

    if (selectedModuleId) {
      fetchModule(selectedModuleId);
    }
  }

  renderList() {
    const { showDeleted } = this.props;
    return (
      <div className="module-list-columns">
        <ModulesList showDeleted={showDeleted} />
      </div>
    );
  }

  renderCreate() {
    const { newModule } = this.props;
    return (
      <div className="edit-form">
        <div className="col-md-12 edit-col">
          <ModulesForm module={newModule} />
        </div>
      </div>
    );
  }

  renderEdit() {
    const { selectedModule } = this.props;
    if (selectedModule) {
      return (
        <div className="edit-form">
          <div className="col-md-12 edit-col">
            <ModulesForm module={selectedModule} />
          </div>
        </div>
      );
    }
    return null;
  }

  renderPagination() {
    const { page, count } = this.props;

    if (count <= 20) {
      return null;
    }

    return (
      <Pagination
        itemCount={count}
        itemsPerPage={20}
        currentPage={page}
        baseUrl={"/modules"}
        pageInQuery
      />
    );
  }

  render() {
    const { showCreate, showEdit } = this.props;
    return (
      <div>
        <div className="modules">
          {showEdit && this.renderEdit()}
          {showCreate && this.renderCreate()}
          {!showEdit && !showCreate && this.renderList()}
          {!showEdit && !showCreate && this.renderPagination()}
        </div>
      </div>
    );
  }
}

Modules.propTypes = {
  selectedModule: PropTypes.object,
  newModule: PropTypes.object,
  showCreate: PropTypes.bool,
  showEdit: PropTypes.bool,
  fetchModulesList: PropTypes.func.isRequired,
  selectedModuleId: PropTypes.string,
  fetchModule: PropTypes.func.isRequired,
  showDeleted: PropTypes.bool,
  filter: PropTypes.string,
  page: PropTypes.number,
  count: PropTypes.number,
  resetPage: PropTypes.func.isRequired,
};

Modules.defaultProps = {
  selectedModule: null,
  newModule: null,
  showCreate: false,
  showEdit: false,
  showDeleted: false,
  selectedModuleId: null,
  filter: "",
  page: 0,
  count: 0,
};

export default Modules;
