// Helper to map an object (key value, to an array)
export function mapObjectToArray(object, callback) {
  const arr = [];
  for (const key in object) {
    const value = object[key];
    arr.push(callback(key, value));
  }
  return arr;
}

// Allows mapping of values on objects
// Lets say if you have object: {foo: 1, bar: 2}
// And you call it like this: mapObject(obj, (v) => v + 1))
// Your new object will be: { foo: 2, bar: 3 }
// Your callback is called for each property on the original object with (value, key)
export function mapObject(object, callback) {
  const newObj = {};
  for (const key in object) {
    const value = object[key];
    newObj[key] = callback(value, key);
  }
  return newObj;
}
