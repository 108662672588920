import { connect } from "react-redux";
import { updateQuery } from "../../../../../../shared/routes/query";
import BottomBar from "./BottomBar.jsx";

function mapDispatchToProps(dispatch, { cardId }) {
  return {
    onCancel: () =>
      dispatch(updateQuery({ edit: undefined, create: undefined })),
  };
}

export default connect(null, mapDispatchToProps)(BottomBar);
