import { connect } from "react-redux";

import CardInteractions from "./CardInteractions.jsx";

import { fetchCard, resetCard } from "./actions";
import { showPanel } from "./../../../../../../shared/components/ModalPanel/actions";
import { cardId, cardDescription, modalPanelDisplay } from "./reducer";

function mapStateToProps(state) {
  return {
    cardId: cardId(state),
    cardDescription: cardDescription(state),
    modalPanelDisplay: modalPanelDisplay(state),
  };
}

const dispatch = {
  showCard: (cardId) => showPanel(`showCard-${cardId}`),
  fetchCard: (cardId, clinicianId) => fetchCard(cardId, clinicianId),
  resetCard: () => resetCard(),
};

export default connect(mapStateToProps, dispatch)(CardInteractions);
