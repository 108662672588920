import reduceReducers from "reduce-reducers";
import { handleActions } from "redux-actions";

import { didFetchInvitePortal } from "./actions";
import { customerFormReducer } from "./CustomerForm/reducer";

const reducer = handleActions(
  {
    [didFetchInvitePortal]: (state, action) => ({
      ...state,
      invite: action.payload,
    }),
  },
  {
    invite: null,
    message: null,
  }
);

export default reduceReducers(reducer, customerFormReducer);
