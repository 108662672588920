import React from "react";
import { Field, reduxForm } from "redux-form";
import classNames from "classnames";
import moment from "moment";
import { Icon } from "react-fa";
import Spinner from "react-spinkit";

import {
  asyncValidation,
  validateForm,
  defaultRules,
  downcaseEmail,
  capitalizeName,
} from "../../../../shared/utils/form";

import CheckboxField from "../../../../shared/components/FormFields/CheckboxField";
import FormGroupField from "../../../../shared/components/FormFields/FormGroupField";
import DrillDownSelectField from "../../../../shared/components/FormFields/DrillDownSelectField";
import PlaceholderSelectField from "../../../../shared/components/FormFields/PlaceholderSelectField";
import DateInputField from "../../../../shared/components/FormFields/DateInputField";
import SearchableDropdown from "../../../../shared/components/SearchableDropdown";
import SuccessAnimation from "../../../../shared/components/SuccessAnimation";
import CareTeamFormSection from "./components/CareTeamFormSection";

import "./PatientInviteForm.css";
import { availableLanguages } from "../../../User/components/UserView/components/Forms/UpdateLanguage/UpdateLanguage";

const MIN_DATE = moment().subtract(2, "years");
const MAX_DATE = moment().add(2, "years");

const asyncValidate = asyncValidation({
  email_address: "email",
  mobile_number: "phone",
});

// TODO: split out the admin and workerscomp forms, code has become too complicated methinks
class PatientInviteForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCustomer: null,
      selectedClinician: null,
    };

    this.adminInputRef = null;
  }

  componentDidMount() {
    const { isAdmin, customers, requestCustomers } = this.props;
    if (isAdmin && !customers) requestCustomers();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      requestInviteAdmin,
      requestPartnerUsers,
      resetInviteData,
      resetSelectedProgram,
      destroy,
    } = this.props;
    const { selectedCustomer, selectedClinician } = this.state;

    // PW ADMIN UPDATES
    if (selectedCustomer && !prevState.selectedCustomer) {
      this.setState({ clinicians: selectedCustomer.clinicians });
    }
    if (!selectedCustomer && prevState.selectedCustomer) {
      resetInviteData();
      destroy(); // from redux-form
    }
    if (
      (selectedClinician && !prevState.selectedClinician) ||
      selectedClinician != prevState.selectedClinician
    ) {
      requestInviteAdmin(selectedClinician);
      requestPartnerUsers(selectedCustomer.customer.id);
      resetSelectedProgram();
    }
  }

  componentWillUnmount() {
    const { isAdmin, resetInviteData } = this.props;
    if (isAdmin) resetInviteData();
  }

  renderSurgeryDateField() {
    const { resetSelectedProgram } = this.props;

    return (
      <Field
        className="patient-invite-date-field"
        name="surgery_date"
        label="Surgery date"
        component={DateInputField}
        optionalMessage="No date set"
        dateFormat="MM/DD/YY"
        withInput
        minDate={MIN_DATE}
        maxDate={MAX_DATE}
        placeholderText="Choose"
        required
        onChange={() => resetSelectedProgram()}
      />
    );
  }

  renderAdminOverlay() {
    const { isAdmin, customers, hidePanel } = this.props;
    const { selectedCustomer } = this.state;

    if (!isAdmin || selectedCustomer) return null;

    const customerOptions = customers
      ? customers.map((customerBody) => ({
          label: customerBody.customer.name,
          value: customerBody.customer.id,
          onClick: () => this.setState({ selectedCustomer: customerBody }),
        }))
      : [];

    return (
      <div className="admin-overlay">
        <a
          role="button"
          onClick={hidePanel}
          className="admin-overlay-close-modal"
        >
          <Icon name="times" />
        </a>
        {customers ? (
          <SearchableDropdown
            placeholder="Choose a customer first"
            values={customerOptions}
            withRef
          />
        ) : (
          <Spinner
            className="customer-dropdown-loading"
            name="cube-grid"
            color="rgba(0,0,0,0.4)"
          />
        )}
      </div>
    );
  }

  renderExtraFields() {
    const { invite, programData, partnerUserData, extraFields, programTypes } =
      this.props;

    if (!invite || !extraFields)
      return <div className="extra-fields-loading" />;

    if (!programData || !partnerUserData)
      return (
        <div className="extra-fields-loading">
          <Spinner
            className="extra-fields-loading-spinner"
            name="cube-grid"
            color="rgba(0,0,0,0.4)"
          />
        </div>
      );

    return (
      <div className="admin-extra-fields-container">
        {programTypes && this.programCheckboxes(programTypes)}

        <div className="field-pair">
          {this.renderSurgeryDateField()}

          <Field
            className="patient-invite-drilldown-field large"
            name="program"
            component={DrillDownSelectField}
            values={programData}
            label="Program"
            initialLabel="Select a program"
            required
          />
        </div>

        {partnerUserData && partnerUserData.length > 0 && (
          <div className="extra-field">
            <Field
              className="patient-invite-select-field medium"
              name="owner"
              label="Patient owner"
              placeholder="Choose owner"
              values={partnerUserData}
              component={PlaceholderSelectField}
              required
            />
          </div>
        )}

        {extraFields.map((field) => {
          const typeToFieldMapping = {
            select: PlaceholderSelectField,
            text: FormGroupField,
            boolean: CheckboxField,
          };
          const FieldForType = typeToFieldMapping[field.type];

          if (FieldForType) {
            return (
              <div
                key={field.label + field.name}
                className={classNames("extra-field", field.type)}
              >
                <Field
                  className={classNames(
                    `patient-invite-${field.type}-field medium`
                  )}
                  type={field.type}
                  name={`external_service.${field.name}`}
                  component={FieldForType}
                  label={field.label}
                  placeholder={`Choose ${field.label.toLowerCase()}`}
                  values={field.values}
                  required={field.presence}
                />
              </div>
            );
          }

          console.error(
            `There is no field type specified for invite field of type ${field.type}`
          );
          return null;
        })}
      </div>
    );
  }

  programCheckboxes(programTypes) {
    const boxes = [
      { label: "Prehab", value: "prehab" },
      { label: "Rehab", value: "rehab" },
      { label: "Conservative", value: "conservative_care" },
    ];

    return (
      <div className="program_checkbox" style={{ marginBottom: "20px" }}>
        <div className="flex-row">
          {boxes.map((box) => (
            <div className="flex-row" key={box.value}>
              <input
                type="checkbox"
                disabled="disabled"
                checked={programTypes.indexOf(box.value) !== -1}
                className="form-control"
                placeholder={box.label}
              />
              <label>{box.label}</label>
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderDynamicAdminForm() {
    const { isAdmin, handleSubmit, onSubmit, disabled, message, submitting } =
      this.props;
    const { selectedCustomer, selectedClinician, clinicians } = this.state;

    if (!isAdmin) return null;

    const clinicianOptions = clinicians
      ? clinicians.map((clinician) => ({
          label: clinician.name,
          value: clinician.id,
        }))
      : [];
    return (
      <form
        className="patient-invite-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {selectedCustomer && (
          <p
            className="selected-customer"
            role="button"
            onClick={() => this.setState({ selectedCustomer: null })}
          >
            {selectedCustomer.customer.name}
            <a role="button" className="clear-customer">
              <Icon name="times" />
            </a>
          </p>
        )}

        <div className="sections-container">
          <div className="add-patient-section">
            <div className="patient-invite-header-group">
              <h2>Invite a patient</h2>
              <p>
                required (<span className="asterisk">*</span>)
              </p>
            </div>

            <div className="patient-invite-fields">
              <div className="field-pair">
                <Field
                  id="clinicianSelectField"
                  className="patient-invite-select-field large"
                  name="selectedClinician"
                  label="Clinician"
                  values={clinicianOptions}
                  component={PlaceholderSelectField}
                  required
                  onChange={(e, newValue) =>
                    this.setState({ selectedClinician: newValue })
                  }
                  defaultValue={selectedClinician}
                />
              </div>

              {this.renderSharedFields()}

              {this.renderExtraFields()}
            </div>
          </div>

          <Field name="care_team" component={CareTeamFormSection} />
        </div>

        {message ? (
          <SuccessAnimation message={message} />
        ) : (
          <input
            className={classNames("patient-invite-submit-button", {
              disabled: disabled || submitting,
            })}
            type="submit"
            value="Add patient"
            disabled={disabled || submitting}
          />
        )}
      </form>
    );
  }

  renderSharedFields() {
    const { geographyData } = this.props;

    return (
      <>
        <div className="field-pair">
          <Field
            className="patient-invite-text-field large"
            name="peer_fullname"
            label="Patient name"
            placeholder="Patient name"
            component={FormGroupField}
            required
            normalize={capitalizeName}
            errorOnBlur
          />
        </div>

        <div className="field-pair">
          <Field
            className="patient-invite-select-field small"
            name="state"
            label="State / Province"
            placeholder="Choose region"
            values={geographyData}
            component={PlaceholderSelectField}
          />

          <Field
            className="patient-invite-select-field small"
            name="language"
            label="Language"
            placeholder="Choose language"
            values={availableLanguages}
            component={PlaceholderSelectField}
            defaultValue="en"
          />
        </div>

        <div className="field-pair">
          <Field
            className="patient-invite-text-field large"
            name="email_address"
            label="Email address"
            placeholder="Email address"
            component={FormGroupField}
            normalize={downcaseEmail}
            errorOnBlur
          />
          <Field
            className="patient-invite-input-field small"
            type="phone"
            name="mobile_number"
            label="Phone number"
            component={FormGroupField}
            errorOnBlur
          />
        </div>

        <p className="either-phone-email">
          For GHL programs: Phone required, for all other programs: Email
          required
        </p>
      </>
    );
  }

  renderStaticWorkersCompForm() {
    const {
      isAdmin,
      handleSubmit,
      onSubmit,
      disabled,
      message,
      partnerUserData,
      ownerName,
      programData,
      payerData,
      employerData,
      geographyData,
      country,
      submitting,
      programTypes,
    } = this.props;
    // ownerName is only defined if usertype === 'owner'
    const isOwner = !!ownerName;

    if (isAdmin) return null;

    return (
      <form
        className="patient-invite-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="sections-container">
          <div className="add-patient-section">
            <div className="patient-invite-header-group">
              <h2>Invite a patient</h2>
              <p>
                required (<span className="asterisk">*</span>)
              </p>
            </div>

            <div className="patient-invite-fields">
              <div className="field-pair">
                <Field
                  className="patient-invite-input-field large"
                  name="peer_fullname"
                  label="Patient name"
                  placeholder="Patient name"
                  component={FormGroupField}
                  required
                  normalize={capitalizeName}
                  errorOnBlur
                />
                <Field
                  className="patient-invite-input-field small"
                  name="external_service.claim"
                  label="Claim number"
                  placeholder="Claim number"
                  component={FormGroupField}
                  required
                />
              </div>

              <div className="field-pair">
                <Field
                  className="patient-invite-input-field large"
                  name="email_address"
                  label="Email address"
                  placeholder="Email address"
                  component={FormGroupField}
                  required
                  normalize={downcaseEmail}
                  errorOnBlur
                />
                <Field
                  className="patient-invite-input-field small phone-field"
                  type="phone"
                  name="mobile_number"
                  label="Phone number"
                  component={FormGroupField}
                  country={country}
                  errorOnBlur
                />
              </div>

              {programTypes && this.programCheckboxes(programTypes)}

              {this.renderSurgeryDateField()}

              <div className="field-pair">
                <Field
                  className="patient-invite-drilldown-field large"
                  name="program"
                  component={DrillDownSelectField}
                  values={programData}
                  label="Program"
                  initialLabel="Select a program"
                  required
                />
              </div>

              <div className="field-pair">
                <Field
                  className="patient-invite-select-field large"
                  name="external_service.employer"
                  label="Employer"
                  placeholder="Select employer"
                  values={employerData}
                  component={PlaceholderSelectField}
                  required
                />
                <Field
                  className="patient-invite-select-field small"
                  name="owner"
                  label="Patient owner"
                  placeholder={ownerName || "Choose owner"}
                  values={partnerUserData}
                  component={PlaceholderSelectField}
                  required={!isOwner}
                  disabled={isOwner}
                />
              </div>

              <div className="field-pair">
                <Field
                  className="patient-invite-select-field large"
                  name="external_service.payer"
                  label="Payer"
                  placeholder="Choose payer"
                  values={payerData}
                  component={PlaceholderSelectField}
                />
              </div>

              <div className="field-pair">
                <Field
                  className="patient-invite-select-field small"
                  name="state"
                  label="State / Province"
                  placeholder="Choose region"
                  values={geographyData}
                  component={PlaceholderSelectField}
                />

                <Field
                  className="patient-invite-select-field small"
                  name="language"
                  label="Language"
                  placeholder="Choose language"
                  values={availableLanguages}
                  component={PlaceholderSelectField}
                  defaultValue="en"
                />
              </div>

              <div className="consent-checkbox">
                <Field
                  className="patient-invite-checkbox-field"
                  name="consent"
                  type="checkbox"
                  label={
                    <div>
                      <span>
                        Patient agrees to getting SMS messages from Bardavon and
                        acknowledges they can stop at anytime.
                      </span>{" "}
                      <span className="asterisk"> *</span>
                    </div>
                  }
                  component={FormGroupField}
                />
              </div>
            </div>
          </div>

          <Field name="care_team" component={CareTeamFormSection} />
        </div>

        {message ? (
          <SuccessAnimation message={message} />
        ) : (
          <input
            className={classNames("patient-invite-submit-button", {
              disabled: disabled || submitting,
            })}
            type="submit"
            value="Add patient"
            disabled={disabled || submitting}
          />
        )}
      </form>
    );
  }

  render() {
    return (
      <div>
        {this.renderStaticWorkersCompForm()}

        {this.renderAdminOverlay()}
        {this.renderDynamicAdminForm()}
      </div>
    );
  }
}

const rules = {
  ...defaultRules,
  email_address: {
    ...defaultRules.email_address,
    eitherPresence: "mobile_number",
  },
  mobile_number: {
    ...defaultRules.mobile_number,
    eitherPresence: "email_address",
  },
  phone: null, // for manage partners form
  email: null, // for manage partners form
};

export default reduxForm({
  form: "patientInvite",
  validate: validateForm(rules, {
    fullMessages: false,
  }),
  asyncValidate,
  asyncBlurFields: ["mobile_number", "email_address"],
})(PatientInviteForm);
