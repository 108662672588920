import { connect } from "react-redux";
import { query, gotoQuery } from "../../../shared/routes/query";
import Cards from "./Cards.jsx";

import { userType } from "../../../shared/routes/Authentication/Login/reducer";

function mapStateToProps(state, ownProps) {
  const queryWithType = Object.assign({}, query(state), {
    type: "engagement_card_completion",
  });

  const defaults = {
    sort: "nr_assigned",
  };

  return {
    query: Object.assign({}, defaults, queryWithType),
    showFilter: userType(state) !== "demo",
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    gotoQuery,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cards);
