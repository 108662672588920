import React from "react";
import PropTypes from "prop-types";

class Module extends React.Component {
  constructor(props) {
    super(props);
    this.onEditButton = this.onEditButton.bind(this);
  }

  onEditButton(e) {
    const { id, onEditButton } = this.props;

    onEditButton(id);
    e.stopPropagation();
  }

  render() {
    const { name } = this.props;
    return (
      <li className="module-list-item" onDoubleClick={this.onEditButton}>
        {name}
        <i className="fa fa-pencil-square-o" onClick={this.onEditButton} />
      </li>
    );
  }
}

Module.propTypes = {
  onEditButton: PropTypes.func.isRequired,
};

export default Module;
