import { connect } from "react-redux";

import Phases from "./Phases.jsx";
import { query, updateQuery } from "../../shared/routes/query";
import { selectedPhase, selectedPhaseId, newPhase } from "./reducer";
import { fetchPhase, fetchPhasesList } from "./actions";
import { showDeleted, listCount } from "./components/PhasesList/reducer";

function mapStateToProps(state) {
  const phaseId = selectedPhaseId(state);

  return {
    showCreate: !!query(state, "create"),
    showEdit: !!query(state, "edit"),
    showDeleted: showDeleted(state),
    selectedPhaseId: phaseId,
    count: listCount(state),
    selectedPhase: selectedPhase(state),
    newPhase: newPhase(state),
    filter: query(state, "filter"),
    page: query(state, "page"),
  };
}

const dispatch = {
  fetchPhase: (phaseId) => fetchPhase(phaseId),
  fetchPhasesList: (deleted, filter, page) =>
    fetchPhasesList(deleted, filter, page),
  resetPage: () => updateQuery({ page: 0 }),
};

export default connect(mapStateToProps, dispatch)(Phases);
