import axios from "axios";
import qs from "qs";
import moment from "moment-timezone";
import config from "../../config";
import versionInfo from "./../../version.json";
import { AbortedReqError } from "./error/AbortedReqError";

import createAuthRefreshInterceptor from "axios-auth-refresh";
import authStorage from "./authStorage";
import { ExpiredRefreshTokenError } from "./error/ExpiredRefreshTokenError";

export default function initAxios(instance = axios, baseURL = config.API_URL) {
  instance.defaults = Object.assign(instance.defaults, {
    baseURL,
    timeout: 60000,
    paramsSerializer: (params) => qs.stringify(params, { encode: false }),
    headers: {
      "pw-app-version": versionInfo.version,
      "pw-deploy-date": versionInfo.deploy_date,
      "pw-timezone": moment.tz.guess(),
    },
  });

  interceptExpiredToken(instance);

  instance.interceptors.response.use(null, errorInterceptor);

  return instance;
}

function errorInterceptor(error) {
  if (error.message === "Request aborted") {
    throw new AbortedReqError(error.message, { cause: error });
  }
  throw error;
}

function interceptExpiredToken(instance) {
  createAuthRefreshInterceptor(instance, refreshToken);

  // Use latest token, even on concurrent call just after refresh - ref: https://github.com/Flyrell/axios-auth-refresh#request-interceptor
  instance.interceptors.request.use((request) => {
    const token = authStorage.get()?.token;
    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }
    return request;
  });
}

async function refreshToken(failedRequest) {
  const auth = authStorage.get();

  try {
    const {
      data: { bearer_token, refresh_token },
    } = await axios({
      method: "post",
      url: "/auth/signin",
      data: { grant_type: "refresh_token", refresh_token: auth.refreshToken },
      headers: {
        "Content-Type": "application/json",
      },
    });

    authStorage.set({
      ...auth,
      token: bearer_token,
      refreshToken: refresh_token,
    });

    failedRequest.response.config.headers["Authorization"] =
      "Bearer " + bearer_token;
  } catch (err) {
    if (err.response?.status === 400) {
      throw new ExpiredRefreshTokenError();
    }
    throw err;
  }
}
