import { connect } from "react-redux";
import DeletePatient from "./DeletePatient.jsx";

import { deletePatient } from "./actions";
import { hidePanel } from "../../../../../../../../shared/components/ModalPanel/actions";

import { shouldDisplayLoadingIndicator } from "../../../../../../../../shared/components/LoadingIndicator/reducer";
import { fleetingFormMeta } from "../../../../../../../../shared/utils/form";

function mapStateToProps(state) {
  return {
    isDeleting: shouldDisplayLoadingIndicator(state),
    successfulDeleteMessage: fleetingFormMeta(state, "deletePatient").message,
  };
}

function mapDispatchToProps(dispatch, { patientId, patientType }) {
  return {
    onSubmit: () => {
      dispatch(deletePatient(patientId, patientType));
      setTimeout(() => (document.location.href = "/patients"), 500);
    },
    hidePanel: () => dispatch(hidePanel("deletePatient")),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeletePatient);
