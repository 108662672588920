import { put } from "redux-saga/effects";
import { delay } from "redux-saga";
import { setModalMessage, hidePanel } from "./actions";

export function setSuccessMessageThenClose(message, interval = 3000) {
  return function* () {
    yield put(setModalMessage(message));
    yield delay(interval);
    yield put(setModalMessage(null));
    yield put(hidePanel());
  };
}
