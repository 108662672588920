import {
  authenticationOfTypeRequired,
  authenticationRequired,
} from "../../../shared/routes/Authentication/Login/sagas";

import ManagePrograms from "./ManagePrograms.js";
import { ManageProgramsReducer } from "./reducer";
import watchManagePrograms from "./sagas";

// TODO: flat routes for now, but refactor to nest it like so: /manage/customer
export default {
  "/manage-programs": {
    component: ManagePrograms,
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired(["admin"]);
    },
    reducer: {
      ui: {
        ManagePrograms: ManageProgramsReducer,
      },
    },
    saga: watchManagePrograms,
    title: "Manage Programs",
  },
};
