import { handleActions } from "redux-actions";
import { get } from "lodash";
import { didFetchPeerFilter } from "./actions";

import { query } from "../../../../../../shared/routes/query";

export function filterForType(state, type) {
  return get(state, `ui.PeersList.filter.${type}`) || [];
}

export function selectedFilterValueForType(state, type) {
  return query(state, `filter.${type}`);
}

export default handleActions(
  {
    [didFetchPeerFilter]: (state, action) => ({
      ...state,
      filter: action.payload,
    }),
  },
  {
    filter: {},
  }
);
