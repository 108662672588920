import { takeLatest, put, all } from "redux-saga/effects";

import {
  setFleetingMessage,
  submitFormToAPI,
} from "../../../../../../../../shared/utils/form";

import { updatePhoneForInvite, didUpdatePhone } from "./actions";
import { updatePhoneForInvite as updatePhoneForInviteAPI } from "./api";

function* submitPhoneUpdate(action) {
  const { inviteId, phone } = action.payload;

  yield put(
    submitFormToAPI(
      "updatePhone",
      updatePhoneForInviteAPI,
      didUpdatePhone(inviteId, phone),
      inviteId,
      phone
    )
  );
}

export default function* watchUpdatePhoneSaga() {
  yield all([
    takeLatest(updatePhoneForInvite, submitPhoneUpdate),
    takeLatest(
      didUpdatePhone,
      setFleetingMessage("updatePhone", { message: "Phone has been updated." })
    ),
  ]);
}
