import { createAction } from "redux-actions";

const prefixed = (val) => `Authentication/Register::${val}`;

export const requestRegistrantEmail = createAction(
  prefixed("REQUEST_REGISTRANT_EMAIL")
);
export const didFetchRegistrantEmail = createAction(
  prefixed("DID_FETCH_REGISTRANT_EMAIL")
);

export const requestRegistration = createAction(
  prefixed("REQUEST_REGISTRATION")
);
