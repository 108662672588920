import React from "react";
import PropTypes from "prop-types";

import "./Confirm.css";

function Confirm({ message, subText, callback, disabled, visible }) {
  if (!visible) {
    return null;
  }

  function handleClick(confirmed) {
    let potentialPromise = callback(confirmed);
    if (potentialPromise?.then) {
      potentialPromise.catch((err) => console.error(err));
    }
  }

  function cancel() {
    handleClick(false);
  }

  function confirm() {
    handleClick(true);
  }

  return (
    <div className="confirm-outer-container">
      <div className="confirm-container">
        <h4 className="confirm-prompt">{message}</h4>
        <p className="subtext">{subText}</p>

        <form className="form">
          <button
            onClick={confirm}
            type="submit"
            className="btn-lg btn-danger delete-button"
            disabled={disabled}
          >
            Yes
          </button>
          <p className="go-back" onClick={cancel} role="button">
            {">> Go Back"}
          </p>
        </form>
      </div>
    </div>
  );
}

Confirm.propTypes = {
  message: PropTypes.string.isRequired,
  subText: PropTypes.string,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
};

export default Confirm;
