import { createAction } from "redux-actions";

const prefixed = (val) => `Peer/List/Search::${val}`;

export const requestFindPeers = createAction(
  prefixed("FIND_PEERS"),
  (keyword) => keyword
);
export const didFindPeers = createAction(
  prefixed("DID_FIND_PEERS"),
  (peers) => peers
);
