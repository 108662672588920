import PropTypes from "prop-types";
import UpdateLanguage from "../../../../../../components/UserView/components/Forms/UpdateLanguage";
import { updateInviteLanguage, updatePeerLanguage } from "./api";

import { connect } from "react-redux";
import { didUpdateLanguage, didUpdatePeerLanguage } from "./actions";

function UpdateUserLanguage({
  userId,
  currentLanguage,
  didUpdateLanguage,
  type,
}) {
  const api = (type === "invite" && updateInviteLanguage) || updatePeerLanguage;

  return (
    <UpdateLanguage
      currentLanguage={currentLanguage}
      userId={userId}
      updateLanguageRequest={api}
      didUpdateLanguage={didUpdateLanguage}
    />
  );
}

UpdateUserLanguage.propTypes = {
  userId: PropTypes.string.isRequired,
  currentLanguage: PropTypes.string,
};

export default connect(null, function (dispatch, { type }) {
  const updateAction =
    (type === "invite" && didUpdateLanguage) || didUpdatePeerLanguage;

  return {
    didUpdateLanguage: (inviteId, language) =>
      dispatch(updateAction(inviteId, language)),
  };
})(UpdateUserLanguage);
