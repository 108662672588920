import React from "react";
import PropTypes from "prop-types";

import { get, groupBy, values } from "lodash";

import { last } from "./aggregators";

import SimpleNumber from "../../Data/SimpleNumber/SimpleNumber";

class Number extends React.Component {
  // Gets the correct number to display from the metrics
  valueToDisplay() {
    const { metric, dataPath, aggregator, average } = this.props;
    const { metricData } = this.context;

    const relevantMetricData = metricData[metric];
    if (!relevantMetricData) {
      return null;
    }

    // Grouping by date what we have and summing that
    const grouped = values(groupBy(relevantMetricData, "end"));

    const dataAtPath = grouped.map((group) => {
      let sum = 0;

      if (group.length === 0) {
        return 0;
      }

      for (const item of group) {
        // Support for arrays (through paths of data.*.nr_assigned for example)
        // ONLY ONE STAR ALLOWED MAX
        if (dataPath.indexOf(".*") !== -1) {
          let sumOfArr = 0;
          const firstPath = dataPath.split(".*.")[0];
          const subPath = dataPath.split(".*.")[1];
          const arrayData = get(item, firstPath) || [];
          for (const arrayItem of arrayData) {
            sumOfArr += get(arrayItem, subPath);
          }

          sum += sumOfArr;
        } else {
          sum += get(item, dataPath);
        }
      }

      if (average && group.length > 0) {
        return sum / group.length;
      }

      return sum;
    });

    const value = aggregator(dataAtPath);

    return value;
  }

  render() {
    const { label, className } = this.props;

    return (
      <SimpleNumber
        label={label}
        value={this.valueToDisplay()}
        className={className}
      />
    );
  }
}

Number.propTypes = {
  metric: PropTypes.string.isRequired,
  dataPath: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  aggregator: PropTypes.func,
  // TEMP FIX
  average: PropTypes.bool,
};

Number.defaultProps = {
  aggregator: last,
  average: false,
};

Number.contextTypes = {
  metricData: PropTypes.object,
};

export default Number;
