import React from "react";
import PropTypes from "prop-types";
import "./PinnedCards.css";

class PinnedCards extends React.Component {
  constructor(props) {
    super(props);
    this.onPinCard = this.onPinCard.bind(this);
    this.onOffsetChange = this.onOffsetChange.bind(this);
    this.onCardsCompletedChange = this.onCardsCompletedChange.bind(this);
    this.onEventChange = this.onEventChange.bind(this);
    this.state = { offset: 1, event: "surgery", onCardsCompleted: 1 };
  }

  componentDidMount() {
    this._fetchProgramCards(this.props);
    this._fetchPinnedCards(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { selectedProgramId } = this.props;
    if (
      nextProps.selectedProgramId &&
      selectedProgramId !== nextProps.selectedProgramId
    ) {
      this._fetchProgramCards(nextProps);
      this._fetchPinnedCards(nextProps);
    }
  }

  onOffsetChange(e) {
    this.setState({ offset: e.target.value });
  }

  onCardsCompletedChange(e) {
    this.setState({ onCardsCompleted: e.target.value });
  }

  onEventChange(e) {
    this.setState({ event: e.target.value });
  }

  onPinCard(cardId) {
    const { offset, event, onCardsCompleted } = this.state;
    const { selectedProgramId, pinCard, selectedProgramType } = this.props;
    if (
      (event !== "program-start" || offset >= 0) &&
      selectedProgramType !== "preventive"
    ) {
      pinCard(selectedProgramId, { cardId, offset: +offset, event });
    } else if (selectedProgramType === "preventive" && +onCardsCompleted > 0) {
      pinCard(selectedProgramId, {
        cardId,
        offset: +onCardsCompleted,
        deliveryCondition: "cardsCompleted",
      });
    }
  }

  onUnpinCard(cardId) {
    const { selectedProgramId, unpinCard } = this.props;
    unpinCard(selectedProgramId, cardId);
  }

  getPinnedCardLabel(card) {
    const { programCards } = this.props;
    const id = card.cardId ? card.cardId : card.card;
    const filteredCard = programCards
      .slice()
      .filter((programCard) => programCard.id === id);
    return filteredCard.length
      ? `${filteredCard[0].name}` + this.getDeliveryConditionLabel(card)
      : `Unknown card - Offset ${card.offset} days - Event ${card.event}`;
  }

  getDeliveryConditionLabel(card) {
    // Old pins don't have deliveryCondition, so just assume that if they have offset & event, it's time
    if (card.deliveryCondition === "time" || (card.offset && card.event)) {
      return ` - Offset ${card.offset} days from event: ${card.event}`;
    } else if (card.deliveryCondition === "cardsCompleted") {
      return ` - Deliver after ${card.offset} cards completed`;
    } else {
      return ` - Unknown delivery condition ${card.deliveryCondition}`;
    }
  }

  _fetchProgramCards(props) {
    const { selectedProgramId, fetchProgramCards } = props;

    if (selectedProgramId) {
      fetchProgramCards(selectedProgramId);
    }
  }

  _fetchPinnedCards(props) {
    const { selectedProgramId, fetchPinnedCards } = props;

    if (selectedProgramId) {
      fetchPinnedCards(selectedProgramId);
    }
  }

  render() {
    const { programCards, pinnedCards, selectedProgramType } = this.props;
    const { offset, event, onCardsCompleted } = this.state;
    const isPreventiveProgram = selectedProgramType === "preventive";
    const error =
      event === "program-start" && offset < 0
        ? "Cannot pin a card before start"
        : offset == 0
        ? "Offset for pinned cards cannot be 0"
        : isPreventiveProgram && onCardsCompleted < 1
        ? "Cards completed can not be less than 1"
        : null;

    return (
      <div className="list-container">
        <div className="programs-lists">
          <div className="program-cards">
            <span className="programs-field-label">Program Cards</span>
            <ul className="cards-list">
              {programCards &&
                programCards.map((card) => (
                  <li
                    key={card.id}
                    className={"card-list-item"}
                    onDoubleClick={() => this.onPinCard(card.id)}
                  >
                    {card.name}
                    <i
                      className="fa fa-thumb-tack"
                      onClick={() => this.onPinCard(card.id)}
                    />
                  </li>
                ))}
              {!programCards.length && (
                <span className="empty-card-list">-</span>
              )}
            </ul>
          </div>
          <div>
            <span className="programs-field-label">Event:</span>
            <div>
              <input
                className="event-type-radio"
                id="surgery-radio"
                display="inline"
                name="eventType"
                value="surgery"
                type="radio"
                onChange={this.onEventChange}
                checked={event === "surgery"}
                disabled={isPreventiveProgram}
              />
              <label className="radio-field-label" htmlFor="surgery-radio">
                Surgery
              </label>
            </div>
            <input
              className="event-type-radio"
              id="program-start-radio"
              name="eventType"
              value="program-start"
              type="radio"
              checked={event === "program-start"}
              onChange={this.onEventChange}
              disabled={isPreventiveProgram}
            />
            <label className="radio-field-label" htmlFor="program-start-radio">
              Program start
            </label>
            <span className="programs-field-label">Days offset:</span>
            <input
              id="offset"
              value={offset}
              onChange={this.onOffsetChange}
              disabled={isPreventiveProgram}
              type="number"
            />
            <span className="programs-field-label">
              Display after cards completed:
            </span>
            <input
              id="offsetCardsCompleted"
              value={onCardsCompleted}
              onChange={this.onCardsCompletedChange}
              type="number"
              disabled={!isPreventiveProgram}
              min={1}
            />{" "}
            <label> cards</label>
            {error && <span className="error-message">{error}</span>}
          </div>
        </div>
        <div className="pinned-cards">
          <h3 className="programs-field-label">Pinned Cards</h3>
          <ul className="cards-list">
            {programCards &&
              pinnedCards &&
              pinnedCards.map((card) => (
                <li
                  key={card.id}
                  className={"card-list-item"}
                  onDoubleClick={() => this.onUnpinCard(card.id)}
                >
                  {this.getPinnedCardLabel(card)}
                  <i
                    className="fa fa-trash-o"
                    onClick={() => this.onUnpinCard(card.id)}
                  />
                </li>
              ))}
            <li>
              {!pinnedCards.length && (
                <span className="empty-card-list">-</span>
              )}
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

PinnedCards.propTypes = {
  selectedProgramId: PropTypes.string,
};

PinnedCards.defaultProps = {
  selectedProgramId: null,
};

export default PinnedCards;
