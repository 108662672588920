import React from "react";
import PropTypes from "prop-types";

import LoadingIndicator from "../../../shared/components/LoadingIndicator";
import SearchableDropdown from "../../../shared/components/SearchableDropdown";
import CreateCustomerForm from "./components/CreateCustomerForm";
import UpdateCustomerForm from "./components/UpdateCustomerForm";
import CreateFieldValueForm from "./components/CreateFieldValueForm";
import UpdateFieldValueForm from "./components/UpdateFieldValueForm";

import "./ManageCustomer.css";

// TODO:
// unify css
// add warning message for switching customer types

class ManageCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKeyword: "",
      isCreating: false,
      isEditing: false,
    };

    this.onSelectCustomer = this.onSelectCustomer.bind(this);
    this.onDeselectCustomer = this.onDeselectCustomer.bind(this);
    this.onSelectField = this.onSelectField.bind(this);
    this.onDeselectField = this.onDeselectField.bind(this);
    this.onSelectFieldValue = this.onSelectFieldValue.bind(this);
    this.onDeselectFieldValue = this.onDeselectFieldValue.bind(this);
    this.onCreateCustomer = this.onCreateCustomer.bind(this);
    this.onUpdateCustomer = this.onUpdateCustomer.bind(this);
    this.onCreateFieldValue = this.onCreateFieldValue.bind(this);
    this.onUpdateFieldValue = this.onUpdateFieldValue.bind(this);
  }

  componentDidMount() {
    const {
      requestCustomers,
      customerId,
      requestCustomerTypes,
      requestCustomerDetails,
      requestCustomerSelectFields,
    } = this.props;

    requestCustomers();
    requestCustomerTypes();

    if (customerId) {
      requestCustomerSelectFields(customerId);
      requestCustomerDetails(customerId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      customerId,
      requestCustomerDetails,
      requestCustomerSelectFields,
      clearCustomerData,
      message,
    } = this.props;

    if (customerId && customerId !== prevProps.customerId) {
      requestCustomerSelectFields(customerId);
      requestCustomerDetails(customerId);
    }

    if (!customerId && prevProps.customerId) {
      clearCustomerData();
    }

    if (!message && prevProps.message) {
      // document.location.href = previousPage
      let href = "/manage-partners";
      if (customerId) href += `/?customerId=${customerId}`;
      document.location.href = href;
    }
  }

  onSelectCustomer(customerId) {
    const { selectCustomer } = this.props;
    selectCustomer(customerId);
  }

  onDeselectCustomer() {
    const { deselectCustomer, clearCustomerData } = this.props;
    this.setState({ searchKeyword: "", isEditing: false, isCreating: false });
    deselectCustomer();
    clearCustomerData();
  }

  onSelectField(name) {
    const { selectField } = this.props;
    selectField(name);
  }

  onDeselectField() {
    const { deselectField } = this.props;
    deselectField();
  }

  onSelectFieldValue(fieldValue) {
    const { selectFieldValue } = this.props;
    selectFieldValue(fieldValue);
  }

  onDeselectFieldValue() {
    const { deselectFieldValue } = this.props;
    this.setState({ searchKeyword: "" });
    deselectFieldValue();
  }

  onCreateCustomer(values) {
    const { createCustomer } = this.props;
    let send_notifications;
    if (typeof values.send_notifications === "string") {
      send_notifications =
        values.send_notifications === "default"
          ? undefined
          : values.send_notifications === "true";
    }
    createCustomer({ ...values, send_notifications });
  }

  onUpdateCustomer(values) {
    const { updateCustomer, customer } = this.props;
    let send_notifications;
    if (typeof values.send_notifications === "string") {
      send_notifications =
        values.send_notifications === "default"
          ? undefined
          : values.send_notifications === "true";
    } else {
      send_notifications = values.send_notifications;
    }
    updateCustomer({ customerId: customer.id, ...values, send_notifications });
  }

  onCreateFieldValue(values) {
    const { createFieldValue, customer, customerField } = this.props;
    createFieldValue({
      customerId: customer.id,
      fieldName: customerField.name,
      ...values,
    });
  }

  onUpdateFieldValue(values) {
    const { updateFieldValue, customer, customerField, customerFieldValue } =
      this.props;
    updateFieldValue({
      customerId: customer.id,
      fieldName: customerField.name,
      fieldValue: customerFieldValue.value,
      ...values,
    });
  }

  renderHeaderInfo() {
    const {
      customer,
      customerId,
      customerField,
      fieldId,
      customerFieldValue,
      fieldValueId,
      isLoading,
    } = this.props;

    if (!customerId || isLoading) return null;

    return (
      <div className="header-info">
        {customer && customerId && (
          <div className="header-group">
            <p className="selected-customer">
              <span>Partner:</span> {customer.name}
            </p>
            {!fieldId && (
              <button className="action-btn" onClick={this.onDeselectCustomer}>
                Choose Another
              </button>
            )}
          </div>
        )}
        {customerField && fieldId && (
          <div className="header-group">
            <p className="selected-customer">
              <span>Partner list:</span> {customerField.label}
            </p>
            {!fieldValueId && (
              <button className="action-btn" onClick={this.onDeselectField}>
                Choose Another
              </button>
            )}
          </div>
        )}
        {customerFieldValue && fieldValueId && (
          <div className="header-group">
            <p className="selected-customer">
              <span>Partner list item:</span> {customerFieldValue.label}
            </p>
            <button className="action-btn" onClick={this.onDeselectFieldValue}>
              Choose Another
            </button>
          </div>
        )}
      </div>
    );
  }

  renderChooseCustomer() {
    const { isCreating, isEditing } = this.state;
    const { allCustomers, customerId, isLoading } = this.props;

    if (!allCustomers || customerId || isEditing || isCreating || isLoading)
      return null;

    const values = allCustomers.map(({ customer }) => ({
      label: customer.name,
      value: customer.id,
      onClick: () => this.onSelectCustomer(customer.id),
    }));

    return (
      <div className="choose-customer">
        <h4>Create new partner</h4>
        <button
          className="action-btn"
          onClick={() => this.setState({ isCreating: true })}
        >
          Add
        </button>
        <div className="update-existing">
          <h4>Or manage existing partner</h4>
          <SearchableDropdown
            values={values}
            placeholder="Select or Search for a Partner"
          />
        </div>
      </div>
    );
  }

  renderChooseCustomerField() {
    const { isCreating, isEditing } = this.state;
    const {
      customerField,
      customerSelectFields,
      customerFieldValue,
      isLoading,
    } = this.props;

    if (
      !customerSelectFields ||
      !customerField ||
      customerFieldValue ||
      isEditing ||
      isCreating ||
      isLoading
    )
      return null;
    const values = customerField.values.map(({ label, value }) => ({
      label,
      value,
      onClick: () => this.onSelectFieldValue(value),
    }));

    return (
      <div className="customer-actions">
        <h4>Add new {customerField.label.toLowerCase()}</h4>
        <button
          className="action-btn"
          onClick={() => this.setState({ isCreating: true })}
        >
          Add
        </button>

        {customerField.values.length > 1 && (
          <div className="update-existing">
            <h4>Manage partner's {customerField.label.toLowerCase()} list</h4>
            <SearchableDropdown
              values={values}
              placeholder="Select or Search for value"
            />
          </div>
        )}
      </div>
    );
  }

  renderCustomerActions() {
    const {
      customer,
      customerId,
      customerSelectFields,
      customerField,
      goToManageClinician,
      goToManageUsers,
      isLoading,
    } = this.props;
    const { isEditing } = this.state;

    if (
      !customerId ||
      !customer ||
      !customerSelectFields ||
      customerField ||
      isEditing ||
      isLoading
    )
      return null;

    const values = customerSelectFields.map(({ name, label }) => ({
      value: name,
      label,
      onClick: () => this.onSelectField(name),
    }));

    return (
      <div className="customer-actions">
        <h4>Change partner's name or type</h4>
        <button
          className="action-btn"
          onClick={() => this.setState({ isEditing: true })}
        >
          Edit Details
        </button>

        <div className="update-existing">
          <h4>Manage clinicians and programs</h4>
          <button
            className="action-btn"
            onClick={() => goToManageClinician(customerId)}
          >
            Manage Clinician
          </button>
        </div>

        <div className="update-existing">
          <h4>Manage partner admins and owners</h4>
          <button
            className="action-btn"
            onClick={() => goToManageUsers(customerId)}
          >
            Manage Users
          </button>
        </div>

        {customerSelectFields.length > 0 && (
          <div className="update-existing">
            <h4>Manage partner lists (e.g. employers, payers, etc)</h4>
            <SearchableDropdown values={values} placeholder="Select a Field" />
          </div>
        )}
      </div>
    );
  }

  renderCreateCustomerForm() {
    const { isCreating } = this.state;
    const { customerTypes, customerField } = this.props;

    if (!isCreating || customerField) return null;

    return (
      <CreateCustomerForm
        onSubmit={this.onCreateCustomer}
        onCancel={() => this.setState({ isCreating: false })}
        customerTypes={customerTypes}
      />
    );
  }

  renderUpdateCustomerForm() {
    const { customer, customerTypes } = this.props;
    const { isEditing } = this.state;

    if (!isEditing || !customer) return null;

    return (
      <UpdateCustomerForm
        customer={customer}
        customerTypes={customerTypes}
        onSubmit={this.onUpdateCustomer}
        onCancel={() => this.setState({ isEditing: false })}
      />
    );
  }

  renderCreateFieldValueForm() {
    const { isCreating } = this.state;
    const { customerField } = this.props;

    if (!isCreating || !customerField) return null;

    return (
      <div className="manage-customer-form">
        <CreateFieldValueForm
          onSubmit={this.onCreateFieldValue}
          onCancel={() => this.setState({ isCreating: false })}
          customerField={customerField}
        />
      </div>
    );
  }

  renderUpdateFieldValueForm() {
    const { customerField, customerFieldValue } = this.props;

    if (!customerFieldValue) return null;

    return (
      <UpdateFieldValueForm
        onSubmit={this.onUpdateFieldValue}
        submitLabel="Update"
        onCancel={this.onDeselectFieldValue}
        customerField={customerField}
        customerFieldValue={customerFieldValue}
      />
    );
  }

  render() {
    return (
      <div className="manage-customer">
        <h2>Manage Partner</h2>

        {this.renderHeaderInfo()}

        {this.renderChooseCustomer()}
        {this.renderCustomerActions()}
        {this.renderCreateCustomerForm()}
        {this.renderUpdateCustomerForm()}

        {this.renderChooseCustomerField()}
        {this.renderCreateFieldValueForm()}
        {this.renderUpdateFieldValueForm()}
        <LoadingIndicator className="manage-loading-indicator" />
      </div>
    );
  }
}

ManageCustomer.propTypes = {
  allCustomers: PropTypes.array,
  customer: PropTypes.object,
  customerId: PropTypes.string,
  customerTypes: PropTypes.array,
  customerSelectFields: PropTypes.array,
  customerField: PropTypes.object,
  fieldId: PropTypes.string,
  customerFieldValue: PropTypes.object,
  fieldValueId: PropTypes.string,
  requestCustomers: PropTypes.func.isRequired,
  requestCustomerTypes: PropTypes.func.isRequired,
  requestCustomerDetails: PropTypes.func.isRequired,
  createCustomer: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  createFieldValue: PropTypes.func.isRequired,
  updateFieldValue: PropTypes.func.isRequired,
  requestCustomerSelectFields: PropTypes.func.isRequired,
  selectCustomer: PropTypes.func.isRequired,
  deselectCustomer: PropTypes.func.isRequired,
  selectField: PropTypes.func.isRequired,
  deselectField: PropTypes.func.isRequired,
  selectFieldValue: PropTypes.func.isRequired,
  deselectFieldValue: PropTypes.func.isRequired,
  clearCustomerData: PropTypes.func.isRequired,
  clearAllData: PropTypes.func.isRequired,
  goToManageClinician: PropTypes.func.isRequired,
  goToManageAdmins: PropTypes.func.isRequired,
};

ManageCustomer.defaultProps = {
  allCustomers: null,
  customer: null,
  customerId: null,
  customerTypes: null,
  customerSelectFields: null,
  customerField: null,
  fieldId: null,
  customerFieldValue: null,
  fieldValueId: null,
};

export default ManageCustomer;
