import { handleActions } from "redux-actions";
import { didUpdateOwner } from "./actions";

export default handleActions(
  {
    [didUpdateOwner]: (state, action) => {
      const { peerId, owner } = action.payload;
      state[peerId].owner = owner;
      return Object.assign({}, state);
    },
  },
  {}
);
