import axios from "axios";

// Need this specific axios since the other one points to the API server and this file is just in the public dir
const updateAxios = axios.create({
  baseURL: "/",
});

export const version = () => {
  return updateAxios.get(`/version.json?${Date.now()}`).then((response) => {
    const { status, data } = response;
    if (status === 200) {
      return data;
    }
    throw new Error("Could not fetch valid update data");
  });
};

export const updateInfo = () => {
  return updateAxios
    .get(`/updates?${Date.now()}`)
    .then((response) => {
      const { status, data } = response;
      if (status === 200 && data && data.length > 0) {
        return data;
      }
    })
    .catch((e) => null);
};
