import React from "react";
import PropTypes from "prop-types";

import MarkdownIt from "markdown-it";
import MarkdownItVideo from "markdown-it-video";

class Markdown extends React.Component {
  constructor(props) {
    super(props);

    this.md = MarkdownIt({
      html: true,
    }).use(MarkdownItVideo);

    this.state = {
      source: "",
    };
  }

  componentDidMount() {
    this.setContents(this.props.source);
  }

  componentWillReceiveProps(nextProps) {
    this.setContents(nextProps.source);
  }

  setContents(markdown) {
    this.setState({ source: markdown });
  }

  render() {
    if (!this.state.source) {
      return null;
    }
    const htmlFromMarkdown = this.md.render(this.state.source);
    return <div dangerouslySetInnerHTML={{ __html: htmlFromMarkdown }} />;
  }
}

Markdown.propTypes = {
  source: PropTypes.string.isRequired,
};

export default Markdown;
