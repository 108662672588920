import { connect } from "react-redux";
import { updateQuery } from "../../../../shared/routes/query";
import { deletePhase, restorePhase } from "./actions";
import BottomBar from "./BottomBar.jsx";

function mapDispatchToProps(dispatch, { phaseId }) {
  return {
    onCancel: () =>
      dispatch(updateQuery({ edit: undefined, create: undefined })),
    onDelete: () => {
      dispatch(deletePhase(phaseId));
      dispatch(updateQuery({ edit: undefined, create: undefined }));
    },
    onRestore: () => {
      dispatch(restorePhase(phaseId));
      dispatch(updateQuery({ edit: undefined, create: undefined }));
    },
  };
}

export default connect(null, mapDispatchToProps)(BottomBar);
