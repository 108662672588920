import React from "react";
import PropTypes from "prop-types";
import { Icon } from "react-fa";

import "./KPIStats.css";

class KPIStats extends React.Component {
  componentDidMount() {
    const { requestKPIStats } = this.props;
    requestKPIStats();
  }

  renderDifference(difference, type) {
    const percentage = type === "percentage" ? "%" : "";
    if (difference > 0)
      return (
        <div className="difference positive">
          <Icon size="3x" name="caret-up" />
          <p>
            {difference}
            {percentage}
          </p>
        </div>
      );
    else if (difference === 0)
      return (
        <div className="difference">
          <p>
            {difference}
            {percentage}
          </p>
        </div>
      );
    return (
      <div className="difference negative">
        <Icon size="3x" name="caret-down" />
        <p>
          {Math.abs(difference)}
          {percentage}
        </p>
      </div>
    );
  }

  renderStat(stat) {
    const difference = stat.value.current - stat.value.previous;
    if (stat.value && stat.value.type === "number") {
      return (
        <div>
          <div className="stat">
            <h1 data-testid={stat.label} className="value">
              {stat.value.current}
            </h1>
            {this.renderDifference(difference, stat.value.type)}
          </div>
          <p>{stat.label}</p>
        </div>
      );
    }
    if (stat.value && stat.value.type === "percentage") {
      return (
        <div>
          <div className="stat">
            <h1 className="value">{stat.value.current}%</h1>
            {this.renderDifference(difference, stat.value.type)}
          </div>
          <p>{stat.label}</p>
        </div>
      );
    }
    if (!stat.value) {
      return (
        <div>
          <h1 className="negative value">KO</h1>
          <p>{stat.label}</p>
        </div>
      );
    }
    return (
      <div>
        <h2 className="positive value">OK</h2>
        <p>{stat.label}</p>
      </div>
    );
  }

  render() {
    const { stats } = this.props;
    return (
      <div>
        {stats &&
          stats.map((stat) => {
            return (
              <div>
                <div className="stats-container">
                  {stat.items &&
                    stat.items.map((item) => this.renderStat(item))}
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

KPIStats.propTypes = {
  requestKPIStats: PropTypes.func.isRequired,
  stats: PropTypes.object,
};

KPIStats.defaultProps = {
  stats: null,
};

export default KPIStats;
