import { connect } from "react-redux";
import { updateQuery } from "../../../../shared/routes/query";
import { deleteProgram, restoreProgram } from "./actions";
import BottomBar from "./BottomBar.jsx";

function mapDispatchToProps(dispatch, { programId }) {
  return {
    onCancel: () =>
      dispatch(updateQuery({ edit: undefined, create: undefined })),
    onDelete: () => {
      dispatch(deleteProgram(programId));
      dispatch(updateQuery({ edit: undefined, create: undefined }));
    },
    onRestore: () => {
      dispatch(restoreProgram(programId));
      dispatch(updateQuery({ edit: undefined, create: undefined }));
    },
  };
}

export default connect(null, mapDispatchToProps)(BottomBar);
