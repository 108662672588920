import { createAction } from "redux-actions";

const prefixed = (val) => `Peers/ChangePartner::${val}`;

export const requestChangePartner = createAction(
  prefixed("REQUEST_CHANGE_PARTNER")
);

export const fetchAvailablePrograms = createAction(
  prefixed("FETCH_AVAILABLE_PROGRAMS"),
  (customerId, userId) => ({ customerId, userId })
);
export const didFetchAvailablePrograms = createAction(
  prefixed("DID_FETCH_AVAILABLE_PROGRAMS"),
  (data) => data
);
