import {
  authenticationOfTypeRequired,
  authenticationRequired,
} from "../../../shared/routes/Authentication/Login/sagas";

import ManageStaff from "./ManageStaff.js";
import { ManageStaffReducer } from "./reducer";
import watchManageStaff from "./sagas";

// TODO: flat routes for now, but refactor to nest it like so: /manage/staff
export default {
  "/manage-staff": {
    component: ManageStaff,
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired(["admin"]);
    },
    reducer: {
      ui: {
        ManageStaff: ManageStaffReducer,
      },
    },
    saga: watchManageStaff,
    title: "Manage Staff",
  },
};
