import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { reduxForm, Field } from "redux-form";
import { Link } from "redux-little-router";

import MfaField from "../../../components/FormFields/MfaField";
import PlaceholderField from "../../../components/FormFields/PlaceholderField";
import Logo from "../../../../assets/bardavon-recovery-logo.png";

import "./Login.css";

class Login extends React.Component {
  render() {
    const {
      handleSubmit,
      onSubmit,
      disabled,
      isLoggingIn,
      confirming2Fa,
      sendNewCode,
      message,
      loginError,
    } = this.props;

    const loginLogo = (
      <div className="peerwell-logo-container">
        <img
          src={Logo}
          title="Welcome to Bardavon Recovery+!"
          alt="Bardavon Recovery+ Logo"
          onLoad={() => this.setState({ imgLoaded: true })}
        />
      </div>
    );

    const confirm2FaHeader = (
      <h2 className="mfa-header">
        Please add the code from your authentication email here:
      </h2>
    );

    let subtextContent;
    if (message) {
      subtextContent = <span className="new-code-sent">{message}</span>;
    } else if (confirming2Fa) {
      subtextContent = (
        <a
          className={classNames("send-new-code", { isResending: isLoggingIn })}
          onClick={() => !isLoggingIn && sendNewCode()}
        >
          Send new code
        </a>
      );
    } else {
      subtextContent = (
        <Link className="forgot-password" href="/forgot-password">
          I forgot my password
        </Link>
      );
    }

    return (
      <div className="login-container">
        <div className="login-wrapper">
          {confirming2Fa ? confirm2FaHeader : loginLogo}

          <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
            <Field
              autoFocus
              className={classNames("login-field", { hidden: confirming2Fa })}
              name="email"
              type={confirming2Fa ? "hidden" : "email"}
              component={PlaceholderField}
              label="Enter email"
              required
            />

            <Field
              className={classNames("login-field", { hidden: confirming2Fa })}
              name="password"
              type={confirming2Fa ? "hidden" : "password"}
              component={PlaceholderField}
              label="Enter password"
              required
            />

            <Field
              className={classNames("login-field", { hidden: !confirming2Fa })}
              name="mfa_token"
              id="mfa_token"
              type={confirming2Fa ? "text" : "hidden"}
              component={MfaField}
            />

            <button
              className={classNames("login-button", {
                disabled: disabled || isLoggingIn,
              })}
              disabled={disabled || isLoggingIn}
            >
              {confirming2Fa ? "Login" : "Next"}
            </button>

            {subtextContent}

            {loginError && <span className="login-error">{loginError}</span>}
          </form>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (!prevProps?.confirming2Fa && this.props.confirming2Fa) {
      document.getElementById("mfa_token").focus();
    }
  }
}

Login.propTypes = {
  // All added through connect
  loginError: PropTypes.string,
  disableForm: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  confirming2Fa: PropTypes.bool,
};

Login.defaultProps = {
  confirming2Fa: false,
};

export default reduxForm({
  form: "login",
  enableReinitialize: true,
})(Login);
