import { sum as arraySum } from "lodash";

// Returns the last item of the metrics list to display as a singular number
export function last(data) {
  return data[data.length - 1];
}

// Returns the difference between the last item and the item before
export function lastChange(data) {
  const lastValue = last(data);
  if (data.length === 1) {
    return lastValue;
  }

  return lastValue - data[data.length - 2];
}

export function sum(data) {
  return arraySum(data);
}

export function average(data) {
  const sumValue = sum(data);
  return sumValue / data.length;
}
