import React from "react";
import PropTypes from "prop-types";

import { reduxForm, Field } from "redux-form";
import FormGroupField from "../../../../../components/FormFields/FormGroupField";
import { validateForm } from "../../../../../utils/form";

import "./UpdatePassword.css";

const rules = {
  password: {
    presence: true,
    strongPassword: true,
  },
  repeat: {
    presence: true,
    equality: "password",
  },
};

class UpdatePassword extends React.Component {
  renderShowLoginMessage() {
    return (
      <div className="signin-container update-password-container">
        <h1 style={{ textAlign: "center" }}>Password Changed</h1>
        <form className="form">
          <p>
            Your password has been changed. You can now login with the new
            password.
          </p>
          <div className="bottom">
            <a className="btn btn-main" href="/signout">
              Login page
            </a>
          </div>
        </form>
      </div>
    );
  }

  renderUpdatePassword() {
    const { handleSubmit, onSubmit, requirePasswordChange } = this.props;

    return (
      <div className="signin-container update-password-container">
        <h1 style={{ textAlign: "center" }}>Reset password</h1>

        {requirePasswordChange && (
          <p>For security reasons you must change your password.</p>
        )}

        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <Field
            name="password"
            component={FormGroupField}
            label="Password"
            required
            type="password"
          />

          <Field
            name="repeat"
            component={FormGroupField}
            label="Repeat"
            required
            type="password"
          />

          {!requirePasswordChange && (
            <Field name="token" component={FormGroupField} type="hidden" />
          )}

          <div className="bottom">
            <input
              className="btn btn-main"
              type="submit"
              value="Update Password"
            />
          </div>
        </form>
      </div>
    );
  }

  render() {
    const { showLoginMessage } = this.props;
    return showLoginMessage
      ? this.renderShowLoginMessage()
      : this.renderUpdatePassword();
  }
}

UpdatePassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  showLoginMessage: PropTypes.bool.isRequired,
  requirePasswordChange: PropTypes.bool.isRequired,
};

UpdatePassword.defaultProps = {
  message: null,
};

export default reduxForm({
  form: "resetPassword",
  validate: validateForm(rules),
})(UpdatePassword);
