function formatNumber(value) {
  return value;
}

function formatDefault(value) {
  return value;
}

function formatDegree(value) {
  return `${Math.round(value)}º`;
}

function formatInteger(value) {
  return Math.round(value);
}

function formatThousandsAsK(value) {
  if (value < 1000) return value + "";
  else return Math.floor(value / 1000) + "k";
}

export default function buildFormatterOfType(wantedFormat) {
  switch (wantedFormat) {
    case "number":
      return formatNumber;
    case "degree":
      return formatDegree;
    case "integer":
      return formatInteger;
    case "distance":
      return formatThousandsAsK;
    default:
      return formatDefault;
  }
}
