export function mapFormToCard(
  id,
  {
    name,
    pillar,
    content,
    description,
    duration,
    notes,
    slug,
    linkType,
    url,
    assessment,
    deleted,
    v1Ids,
  }
) {
  return {
    ...(id != null && { id }),
    name,
    pillar,
    content,
    description,
    duration,
    notes,
    ...(slug != "" && { slug }),
    ...(deleted != null && { deleted }),
    action: mapToAction({ linkType, url, assessment }),
    v1Ids: v1Ids && v1Ids.length ? v1Ids.split(",") : [],
  };
}

function mapToAction({ linkType, url, assessment }) {
  if (linkType === "url") {
    return { linkType, value: url };
  }
  if (linkType === "assessment") {
    return { linkType, value: assessment };
  }
  return null;
}

export function mapCardToForm(card) {
  if (card == null) {
    return {
      name: "",
      pillar: "",
      content: "",
      description: "",
      duration: "",
      notes: "",
      linkType: "",
      slug: "",
      url: "",
      assessment: "",
      deleted: false,
      v1Ids: "",
    };
  }
  const {
    id,
    pillar,
    name,
    description,
    duration,
    content,
    notes,
    slug,
    action,
    deleted,
    v1Ids,
  } = card;
  const result = {
    ...(id != null && { id }),
    pillar,
    name,
    description,
    duration: duration ?? "",
    content,
    notes,
    slug: slug ?? "",
    ...(deleted != null && { deleted }),
    linkType: action?.linkType ?? "",
    url: action?.linkType === "url" ? action.value : null,
    assessment: action?.linkType === "assessment" ? action.value : null,
    v1Ids: v1Ids && v1Ids.length ? v1Ids.join(",") : "",
  };
  return result;
}
