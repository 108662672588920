import React from "react";
import PropTypes from "prop-types";

import UpdateCareTeamUsersForm from "./components/UpdateCareTeamUsersForm";
import SearchableDropdown from "../../../shared/components/SearchableDropdown";

import "./ManageCareTeamUsers.css";

// TODO: unify css for all Manage routes

class ManageCareTeamUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreating: false,
      isEditing: false,
    };

    this.onUpdateUser = this.onUpdateUser.bind(this);
    this.onSelectUser = this.onSelectUser.bind(this);
    this.onDeselectUser = this.onDeselectUser.bind(this);
  }

  componentDidMount() {
    const { requestAllUsers } = this.props;
    requestAllUsers();
  }

  componentDidUpdate(prevProps) {
    const { userId, userList, clearUserData, message, requestAllUsers } =
      this.props;

    if (!userList && userId !== prevProps.userId) {
      requestAllUsers();
    }

    // if (!userId && prevProps.userId) {
    //   clearUserData()
    // }

    // if (!message && prevProps.message) {
    //   let query = `?customerId=${customerId}`
    //   if (userId) {
    //     query += `&userId=${userId}`
    //   }
    //   document.location.href = `/manage-partner-users/${query}`
    // }
  }

  onSelectUser(user) {
    const { selectUser } = this.props;
    selectUser(user.id);
  }

  onDeselectUser() {
    const { deselectUser } = this.props;
    this.setState({ isEditing: false, isCreating: false });
    deselectUser();
  }

  onUpdateUser(values) {
    const { updateUser, user } = this.props;
    let selectedId = user.id;
    updateUser({ selectedId, ...values });
  }

  renderChooseUser() {
    const { userList } = this.props;
    if (this.state.isEditing) return null;
    const values = userList
      ? userList.map((user) => ({
          value: user.id,
          label: user.fullname ? user.fullname : user.email,
          onClick: () => this.onSelectUser(user),
        }))
      : [];

    return (
      <div className="select-admin">
        <div className="update-existing">
          <h4>Manage existing care team users</h4>
          <SearchableDropdown
            values={values}
            placeholder="Select or Search for Care Team User"
          />
        </div>
      </div>
    );
  }

  renderUserValues() {
    const { isEditing } = this.state;
    const { user } = this.props;
    if (!user || isEditing) return null;

    return (
      <div className="user-details">
        <h4>User Details</h4>
        <button
          className="action-btn"
          onClick={() => this.setState({ isEditing: true })}
        >
          Edit Details
        </button>
        <div className="update-existing">
          <p className="or-update">
            <span className="header-prefix">Full Name</span> {user.fullname}
          </p>
          <p className="or-update">
            <span className="header-prefix">Email</span> {user.email}
          </p>
          <p className="or-update">
            <span className="header-prefix">Job</span> {user.job}
          </p>
          <p className="or-update">
            <span className="header-prefix">Type</span> {user.userable_type}
          </p>
          <p className="or-update">
            <span className="header-prefix">Blocked</span>{" "}
            {user.blocked.toString()}
          </p>
        </div>
      </div>
    );
  }

  renderUpdateUserForm() {
    const { isEditing } = this.state;
    const { user } = this.props;
    if (!isEditing || !user) return null;

    return (
      <UpdateCareTeamUsersForm
        user={user}
        onSubmit={this.onUpdateUser}
        onCancel={() => this.setState({ isEditing: false })}
      />
    );
  }

  render() {
    return (
      <div className="manage-admin">
        <h2>Manage care team users</h2>
        {this.renderChooseUser()}
        {this.renderUserValues()}
        {this.renderUpdateUserForm()}
      </div>
    );
  }
}

ManageCareTeamUsers.propTypes = {};

ManageCareTeamUsers.defaultProps = {
  message: "",
  user: null,
  userId: "",
};

export default ManageCareTeamUsers;
