import { takeLatest, put, all } from "redux-saga/effects";

import {
  setFleetingMessage,
  submitFormToAPI,
} from "../../../../../../../../shared/utils/form";

import { updateEmailForPeer, didUpdateEmail } from "./actions";
import { updateEmailForPeer as updateEmailForPeerAPI } from "./api";

function* submitEmailUpdate(action) {
  const { peerId, email } = action.payload;

  yield put(
    submitFormToAPI(
      "updateEmail",
      updateEmailForPeerAPI,
      didUpdateEmail(peerId, email),
      peerId,
      email
    )
  );
}

export default function* watchUpdateEmailSaga() {
  yield all([
    takeLatest(updateEmailForPeer, submitEmailUpdate),
    takeLatest(
      didUpdateEmail,
      setFleetingMessage("updateEmail", {
        message: "E-mail has been updated.",
      })
    ),
  ]);
}
