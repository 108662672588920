import React from "react";
import { Field, reduxForm } from "redux-form";
import classNames from "classnames";

import {
  validateForm,
  asyncValidation,
  defaultRules,
  downcaseEmail,
  capitalizeName,
} from "../../../shared/utils/form";

import CheckboxField from "../../../shared/components/FormFields/CheckboxField";
import FormGroupField from "../../../shared/components/FormFields/FormGroupField";
import PlaceholderSelectField from "../../../shared/components/FormFields/PlaceholderSelectField";
import SuccessAnimation from "../../../shared/components/SuccessAnimation";
import UserSpecific from "../../../shared/components/UserSpecific";

import ProfilePicture from "./components/ProfilePicture";

import "./PartnerUserForm.css";

// comment back in when API supports validating email availability for dashboard users. currently only works for peers / webapp users
// const asyncValidate = asyncValidation({ email: 'email' })

class PartnerUserForm extends React.Component {
  componentDidMount() {
    const { isAdmin, requestGroupsAsAdmin, initialValues, isUpdating } =
      this.props;

    if (isAdmin && isUpdating) {
      requestGroupsAsAdmin(initialValues.customer_id);
    }
  }

  componentDidUpdate(prevProps) {
    const { isAdmin, requestGroupsAsAdmin, customerId } = this.props;

    if (isAdmin && customerId && customerId !== prevProps.customerId) {
      requestGroupsAsAdmin(customerId);
    }
  }

  componentWillUnmount() {
    const { isAdmin, resetGroupDataForAdmin } = this.props;

    if (isAdmin) resetGroupDataForAdmin();
  }

  renderHeader() {
    const { initialValues, isUpdating } = this.props;

    return isUpdating ? (
      <div className="partner-user-header-container">
        <h2 className="partner-user-form-header">
          Edit {initialValues.fullname}
        </h2>
        <span>
          required (<span className="asterisk">*</span>)
        </span>
      </div>
    ) : (
      <div className="partner-user-header-container">
        <h2 className="partner-user-form-header">Add team member</h2>
        <span>
          required (<span className="asterisk">*</span>)
        </span>
      </div>
    );
  }

  renderSubmit() {
    const { isUpdating, createDisabled, updateDisabled, submitting } =
      this.props;

    return isUpdating ? (
      <input
        className={classNames("partner-user-submit-button", {
          disabled: updateDisabled || submitting,
        })}
        type="submit"
        value="Update"
        disabled={updateDisabled || submitting}
      />
    ) : (
      <input
        className={classNames("partner-user-submit-button", {
          disabled: createDisabled || submitting,
        })}
        type="submit"
        value="Done"
        disabled={createDisabled || submitting}
      />
    );
  }

  render() {
    const {
      handleSubmit,
      onSubmit,
      initialValues,
      isUpdating,
      groupData,
      customerData,
      message,
      isEditingSelf,
      profilePicture,
      ownerId,
    } = this.props;

    const roleOptions = [
      { label: "Partner Admin", value: "partner_admin" },
      { label: "Owner", value: "owner" },
    ];

    return (
      <form className="partner-user-form" onSubmit={handleSubmit(onSubmit)}>
        {this.renderHeader()}

        <UserSpecific className="admin-only-fields" type={["admin"]}>
          <ProfilePicture id={profilePicture} ownerId={ownerId} />
        </UserSpecific>

        <div className="partner-user-fields">
          <UserSpecific className="admin-only-fields" type={["admin"]}>
            <Field
              className="partner-user-input-field customer"
              name="customer_id"
              label="Partner"
              placeholder="Choose Partner"
              values={customerData}
              component={PlaceholderSelectField}
              disabled={initialValues && !!initialValues.customer_id}
              required
            />
          </UserSpecific>
          <Field
            className="partner-user-input-field name"
            name="fullname"
            label="Name"
            placeholder="Name"
            component={FormGroupField}
            required
            format={capitalizeName}
            errorOnBlur
          />
          <Field
            className="partner-user-input-field email"
            name="email"
            label="Email"
            placeholder="Email Address"
            component={FormGroupField}
            required
            format={downcaseEmail}
            errorOnBlur
          />
          <UserSpecific
            className="admin-only-fields"
            type={["admin", "partner_admin"]}
          >
            <Field
              className="partner-user-input-field userable-type"
              name="userable_type"
              label="Role"
              placeholder="Choose Role"
              values={roleOptions}
              component={PlaceholderSelectField}
              required
            />
          </UserSpecific>
          <Field
            className="partner-user-select-field group"
            name="group"
            label="Group"
            placeholder="Choose Group"
            values={groupData}
            component={PlaceholderSelectField}
          />
        </div>
        {isUpdating && !isEditingSelf && (
          <div className="blocked-field-container">
            <Field
              className="partner-user-input-field blocked"
              name="blocked"
              label=""
              component={CheckboxField}
            />
            <p className="blocked-description">
              Block user: this user will no longer have access to the Bardavon
              Recovery dashboard
            </p>
          </div>
        )}
        {message ? <SuccessAnimation message={message} /> : this.renderSubmit()}
      </form>
    );
  }
}

export default reduxForm({
  form: "managePartnerUser",
  validate: validateForm(defaultRules, { fullMessages: false }),
  // comment back in when API supports validating email availability for dashboard users. currently only works for peers / webapp users
  // asyncValidate,
  // asyncChangefields: ['email']
})(PartnerUserForm);
