import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class UpdateSurgeryComplications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasSurgeryComplications: this.props.currentSurgeryComplications,
    };
  }

  componentDidMount() {
    const { clearMessage } = this.props;
    clearMessage();
  }

  componentDidUpdate(prevProps) {
    const { currentSurgeryComplications } = this.props;
    if (currentSurgeryComplications !== prevProps.currentSurgeryComplications) {
      this.setState({ hasSurgeryComplications: currentSurgeryComplications });
    }
  }

  render() {
    const { currentSurgeryComplications, updateSurgeryComplications, message } =
      this.props;
    const { hasSurgeryComplications } = this.state;

    const hasChanged =
      this.state.hasSurgeryComplications !== currentSurgeryComplications;
    const hasChangedFromYesToNo =
      hasChanged && this.state.hasSurgeryComplications === "false";

    return (
      <div className="update-procedure-type">
        <div className="form-group">
          <label htmlFor="surgeryComplications">Has complications</label>
          <select
            name="surgeryComplications"
            className="form-control"
            value={hasSurgeryComplications}
            defaultValue={currentSurgeryComplications}
            onChange={(e) =>
              this.setState({ hasSurgeryComplications: e.target.value })
            }
          >
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select>
        </div>
        {hasChangedFromYesToNo && (
          <p
            style={{
              marginTop: "20px",
              fontSize: "13px",
              fontStyle: "italic",
              fontWeight: "600",
              lineHeight: "18px",
            }}
          >
            Are you sure about removing complications? Your patient reported
            having complications after surgery, which is why they weren't able
            to join Rehab. After clicking 'Confirm', your patient will be able
            to join Rehab. As such, you are acknowledging that they are fully
            able to safely participate in the Rehab program.
          </p>
        )}
        {hasChanged && (
          <button
            onClick={() => updateSurgeryComplications(hasSurgeryComplications)}
            className={classNames("btn btn-sm", {
              btn: hasChanged && !hasChangedFromYesToNo,
              "btn-danger": hasChangedFromYesToNo,
            })}
          >
            Confirm Change
          </button>
        )}
        {message ? <p>{message}</p> : null}
      </div>
    );
  }
}

UpdateSurgeryComplications.propTypes = {
  userId: PropTypes.string.isRequired,

  // Added automatically
  currentSurgeryComplications: PropTypes.string.isRequired,
  updateSurgeryComplications: PropTypes.func.isRequired,
  clearMessage: PropTypes.func.isRequired,
  message: PropTypes.string,
};

UpdateSurgeryComplications.defaultProps = {
  message: null,
};

export default UpdateSurgeryComplications;
