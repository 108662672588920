import { createAction } from "redux-actions";

const prefixed = (val) => `ModalPanel::${val}`;

export const setModalMessage = createAction(prefixed("SET_MODAL_MESSAGE"));
export const showPanel = createAction(
  prefixed("SHOW"),
  (whichPanel) => whichPanel
);
export const hidePanel = createAction(prefixed("HIDE"));
