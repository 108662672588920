import React from "react";
import PropTypes from "prop-types";

import "./Phases.css";
import PhasesList from "./components/PhasesList/PhasesList";
import PhasesForm from "./components/PhasesForm";
import Pagination from "../../shared/components/Pagination";

class Phases extends React.Component {
  componentDidMount() {
    this._fetchPhase(this.props);
    this.props.fetchPhasesList(this.props.showDeleted, this.props.filter, 0);
  }

  componentWillReceiveProps(nextProps) {
    const {
      selectedPhaseId,
      showDeleted,
      fetchPhasesList,
      filter,
      page,
      resetPage,
    } = this.props;
    if (
      nextProps.selectedPhaseId &&
      selectedPhaseId !== nextProps.selectedPhaseId
    ) {
      this._fetchPhase(nextProps);
    } else if (
      showDeleted !== nextProps.showDeleted ||
      filter !== nextProps.filter
    ) {
      resetPage();
      fetchPhasesList(nextProps.showDeleted, nextProps.filter, 0);
    } else if (page !== nextProps.page) {
      fetchPhasesList(showDeleted, filter, nextProps.page);
    }
  }

  _fetchPhase(props) {
    const { selectedPhaseId, fetchPhase } = props;

    if (selectedPhaseId) {
      fetchPhase(selectedPhaseId);
    }
  }

  renderList() {
    const { showDeleted } = this.props;
    return (
      <div className="phase-list-columns">
        <PhasesList showDeleted={showDeleted} />
      </div>
    );
  }

  renderCreate() {
    const { newPhase } = this.props;
    return (
      <div className="edit-form">
        <div className="col-md-12 edit-col">
          <PhasesForm phase={newPhase} />
        </div>
      </div>
    );
  }

  renderEdit() {
    const { selectedPhase } = this.props;
    if (selectedPhase) {
      return (
        <div className="edit-form">
          <div className="col-md-12 edit-col">
            <PhasesForm phase={selectedPhase} />
          </div>
        </div>
      );
    }
    return null;
  }

  renderPagination() {
    const { page, count } = this.props;

    if (count <= 20) {
      return null;
    }

    return (
      <Pagination
        itemCount={count}
        itemsPerPage={20}
        currentPage={page}
        baseUrl={"/phases"}
        pageInQuery
      />
    );
  }

  render() {
    const { showCreate, showEdit } = this.props;
    return (
      <div>
        <div className="phases">
          {showEdit && this.renderEdit()}
          {showCreate && this.renderCreate()}
          {!showEdit && !showCreate && this.renderList()}
          {!showEdit && !showCreate && this.renderPagination()}
        </div>
      </div>
    );
  }
}

Phases.propTypes = {
  selectedPhase: PropTypes.object,
  newPhase: PropTypes.object,
  showCreate: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDeleted: PropTypes.bool,
  selectedPhaseId: PropTypes.string,
  filter: PropTypes.string,
  page: PropTypes.number,
  count: PropTypes.number,
  resetPage: PropTypes.func.isRequired,
  fetchPhase: PropTypes.func.isRequired,
  fetchPhasesList: PropTypes.func.isRequired,
};

Phases.defaultProps = {
  filter: "",
  page: 0,
  count: 0,
  selectedPhase: null,
  newPhase: null,
  showCreate: false,
  showEdit: false,
  showDeleted: false,
  selectedPhaseId: null,
};

export default Phases;
