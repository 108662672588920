import { handleActions } from "redux-actions";
import { get } from "lodash";

import { didFetchBackgroundForm, didUpdateBackground } from "./actions";

export function formData(state) {
  return get(state, "ui.PeerView.formData");
}

export function comorbidities(state, healthIssues) {
  const form = get(state, "ui.PeerView.formData");
  const values =
    form &&
    form.questions &&
    form.questions.filter(
      (question) => question.name === "intake_data.health_issues"
    )[0].values;
  return values && values.filter((item) => healthIssues[item.value]);
}

export default handleActions(
  {
    [didFetchBackgroundForm]: (state, action) => ({
      ...state,
      formData: action.payload,
    }),
    [didUpdateBackground]: (state, action) => {
      const { peerId, formValues } = action.payload;
      const info = Object.assign({}, state[peerId].info);
      info.intake_data = formValues.intake_data;
      state[peerId].info = info;
      return Object.assign({}, state);
    },
  },
  {
    formData: null,
  }
);
