import {
  getPreventionAxios,
  getProgramsAxios,
} from "../../../../../../services/api";

interface FetchWorkerInteractionsRequest {
  workerId: string;
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortDirection?: string;
}

interface FetchWorkerInteractionsResponse {
  data: WorkerEvent[];
}

export enum WorkerEventType {
  CardViewed = "cardViewed",
  CardCompleted = "cardCompleted",
}

export interface WorkerEvent {
  type: WorkerEventType;
  program: string;
  card: Card;
  createdAt: string;
}

export interface Card {
  id: string;
  name: string;
}

export const fetchWorkerInteractions = async ({
  workerId,
  page = 0,
  perPage = 100,
  sortBy = "createdAt",
  sortDirection = "desc",
}: FetchWorkerInteractionsRequest) => {
  const dto: FetchWorkerInteractionsResponse = await getPreventionAxios().get(
    `/workers/${workerId}/events`,
    {
      params: {
        page: page,
        perPage: perPage,
        sortBy: sortBy,
        sortDirection: sortDirection,
      },
    }
  );
  return dto;
};

export const fetchCardContent = async (cardId: string) => {
  return await getProgramsAxios().get(`/cards/${cardId}`);
};
