import qs from "qs";
import { get } from "lodash";
import { push } from "redux-little-router";

// The default way the router parses the queries is not exactly what I want, untill they have an option to parse with the qs libary this code will help with parsing
// If you pass key, it will only return that part of the query, else the entire object
// When key is passed, and value is not present, defaultValue will be returned
export function query(state, key, defaultValue) {
  const originalQueryString = state.router.search;
  const queryObject = qs.parse(originalQueryString) || {};
  return key ? get(queryObject, key, defaultValue) : queryObject;
}

// Todo: Deprecated, use the one below when possible since it does not reload the page
export function gotoQuery(queryObject) {
  const queryString = qs.stringify(queryObject);
  // Todo: With no reload
  document.location.href = `?${queryString}`;
}

export function replaceQuery(query) {
  return push({
    pathname: document.location.pathname,
    query,
  });
}

// Merges existing query variables with the query object to only update specific values but keep existing in tact
export function updateQuery(query) {
  return push(
    {
      pathname: document.location.pathname,
      query,
    },
    { persistQuery: true }
  );
}

export function clearQuery() {
  return replaceQuery(null);
}

// Doesn't work if you use document.location.href to navigate since that refreshes the entire page and JS state is then lost
export function previousPage(state) {
  const previous = state.router.previous;
  if (previous) {
    return `${previous.pathname}${previous.search}`;
  }
  return null;
}

export function currentPage(state) {
  return `${state.router.pathname}${state.router.search}`;
}

export function objectToQuery(object) {
  return qs.stringify(object);
}

export function urlParameter(state, key) {
  return state.router.params[key];
}
