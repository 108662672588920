import { takeLatest, all, spawn } from "redux-saga/effects";
import { values, compact } from "lodash";

// Make sure the default qs settings are loaded on time
// Todo: Don't really like it here, but it has to be before the ROUTER_LOCATION_CHANGED trigger
import "./qs";

import { watchUpdates } from "./updates/sagas";

import { onRouterLocationChange } from "./router";

import { watchAppDidStart } from "./../../app_booted";
import { watchSubmitFormToAPI } from "../utils/form";

import routes from "./routes";
import componentConfiguration from "./../../components";
import sharedComponentConfiguration from "./../../shared/components";
import servicesConfiguration from "./../../services";

import { watchCallToAPI } from "../utils/sagas";

// Making sure we automatically add all sagas for each route and component
// Each route /component will be its own "root saga" so failure on one does not affect others
const allReduxConfigurations = values(routes)
  .concat(values(componentConfiguration))
  .concat(values(sharedComponentConfiguration))
  .concat(values(servicesConfiguration));

const allSagas = compact(
  allReduxConfigurations.map((reduxConfig) => {
    if (reduxConfig.saga) {
      return function* () {
        yield spawn(reduxConfig.saga);
      };
    }
    return null;
  })
);

// Todo: Move to fork instead
function* rootSaga() {
  // Keeping track of router location changes so we can fire onEnter events
  yield spawn(takeLatest, "ROUTER_LOCATION_CHANGED", onRouterLocationChange);
  // Keep track of app updates
  yield spawn(watchUpdates);
  // Allowing the app to hook into the start event
  yield spawn(watchAppDidStart);
  // Get the sagas for the available routes and other shared sagas
  yield all(
    allSagas
      .concat([watchSubmitFormToAPI, watchCallToAPI])
      .map((saga) => saga())
  );
}

export default rootSaga;
