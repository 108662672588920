import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { exclusion } from "redux-form-validators";

import { validateForm } from "../../../../../shared/utils/form";

import SimpleField from "../../../../../shared/components/FormFields/SimpleField";
import CheckboxField from "../../../../../shared/components/FormFields/CheckboxField";

const rules = {
  label: { presence: true },
  value: { presence: true },
  visible: { presence: true },
};

function UpdateFieldValueForm({
  handleSubmit,
  onSubmit,
  onCancel,
  customerField,
  customerFieldValue,
  message,
}) {
  // for redux-form: field-level validation to disallow the user from submitting a duplicate value
  const alreadyTakenFieldValues = customerField.values
    .map(({ value }) => value)
    .filter((value) => customerFieldValue.value !== value);

  return (
    <form className="manage-customer-form" onSubmit={handleSubmit(onSubmit)}>
      <p className="form-header">
        <span>Update info for:</span> {customerFieldValue.label}
      </p>

      <div>
        <label htmlFor="name">Name</label>
        <Field className="edit-field" name="label" component={SimpleField} />
      </div>

      <div>
        <label htmlFor="name">I.D. (usually a 4 digit number)</label>
        <Field
          className="edit-field"
          name="value"
          validate={[exclusion({ in: alreadyTakenFieldValues })]}
          component={SimpleField}
        />
      </div>

      <Field
        className="checkbox-field"
        name="visible"
        label="Display"
        component={CheckboxField}
      />

      <input className="action-btn btn-danger" type="submit" value="Update" />
      <button className="action-btn" onClick={onCancel}>
        Cancel
      </button>

      {message && <p className="message">{message}</p>}
    </form>
  );
}

UpdateFieldValueForm.propTypes = {
  customerField: PropTypes.object,
  customerFieldValue: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string,
  // redux-form
  handleSubmit: PropTypes.func.isRequired,
};

UpdateFieldValueForm.defaultProps = {
  customerField: null,
  customerFieldValue: null,
  message: null,
};

export default reduxForm({
  form: "manageCustomer",
  validate: validateForm(rules),
  enableReinitialize: true,
})(UpdateFieldValueForm);
