import { handleActions } from "redux-actions";
import { get } from "lodash";
import { humanize } from "inflection";

import { userType } from "../../shared/routes/Authentication/Login/reducer";

import {
  didFetchResults,
  didFetchExternalFields,
  didFetchCustomers,
  didFetchGroupsAsAdmin,
  resetGroupOptions,
} from "./actions";

export function externalServiceFields(state) {
  return get(state, "ui.Team.externalFields") || null;
}

export function groupData(state) {
  const externalFields = externalServiceFields(state);

  if (!externalFields) return null;

  const groupSelect = externalFields.find(({ name }) => name === "group");
  return groupSelect && groupSelect.values;
}

export function customerData(state) {
  const customers = get(state, "ui.Team.customers");

  if (!customers) return null;

  return customers
    .sort((a, b) => a.customer.name.localeCompare(b.customer.name))
    .map(({ customer }) => ({ label: customer.name, value: customer.id }));
}

export function results(state) {
  const searchResults = get(state, "ui.Team.search.results");
  const externalFields = externalServiceFields(state);

  if (!searchResults || !externalFields) return null;

  return searchResults.map((partnerUser) => ({
    // this is for populating the display values for the rows
    ...partnerUser,
    // for populating the form for each partner user (doing this in a map)
    formData: {
      customer_id: partnerUser.customer_id,
      userable_type: partnerUser.userable_type,
      fullname: partnerUser.fullname,
      email: partnerUser.email,
      group: partnerUser.group && partnerUser.group.id,
      id: partnerUser.id,
      blocked: partnerUser.blocked,
      profile_picture_id: partnerUser.profile_picture_id,
    },
  }));
}

export function count(state) {
  return get(state, "ui.Team.search.total");
}

export function groupDataForAdmin(state) {
  const customerSelects = get(state, "ui.Team.customerSelects");

  if (!customerSelects) return null;

  const groupSelect = customerSelects.find(({ name }) => name === "group");

  if (!groupSelect) return null;

  return groupSelect.values;
}

function truncate(longString) {
  return `${longString.substr(0, 27)}...`;
}

function getDisplayValue(partnerUser, key, state) {
  const customers = get(state, "ui.Team.customers");
  const rawValue = get(partnerUser, key) || "";

  switch (key) {
    case "fullname":
      return rawValue.length > 30 ? truncate(rawValue) : rawValue;
    case "email":
      return rawValue.length > 30 ? truncate(rawValue) : rawValue;
    case "userable_type":
      return humanize(rawValue);
    case "activated":
      return rawValue ? "Registered" : "Invited";
    case "group":
      return rawValue && rawValue.name;
    case "customer_id": {
      const customerBody =
        customers && customers.find(({ customer }) => customer.id === rawValue);
      const customerDisplayName =
        (customerBody && customerBody.customer.name) || "";
      return customerDisplayName.length > 30
        ? truncate(customerDisplayName)
        : customerDisplayName;
    }
    default:
      return rawValue;
  }
}

export function columns(state) {
  const COLUMNS = [
    {
      columnLabel: "Name",
      displayValue: (partnerUser) =>
        getDisplayValue(partnerUser, "fullname", state),
      sortBy: "fullname",
    },
    {
      columnLabel: "Email",
      displayValue: (partnerUser) =>
        getDisplayValue(partnerUser, "email", state),
      sortBy: "email",
    },
    {
      columnLabel: "Type",
      displayValue: (partnerUser) =>
        getDisplayValue(partnerUser, "userable_type", state),
      sortBy: null,
    },
    {
      columnLabel: "Group",
      displayValue: (partnerUser) =>
        getDisplayValue(partnerUser, "group", state),
      sortBy: "group",
    },
    {
      columnLabel: "Status",
      displayValue: (partnerUser) =>
        getDisplayValue(partnerUser, "activated", state),
      sortBy: "activated",
    },
  ];

  const authenticatedUserType = userType(state);

  if (authenticatedUserType === "admin") {
    COLUMNS.push({
      columnLabel: "Customer",
      displayValue: (partnerUser) =>
        getDisplayValue(partnerUser, "customer_id", state),
      sortBy: "customer_id",
    });
  }

  return COLUMNS;
}

export const TeamReducer = handleActions(
  {
    [didFetchResults]: (state, action) => ({
      ...state,
      search: action.payload,
    }),
    [didFetchExternalFields]: (state, action) => ({
      ...state,
      externalFields: action.payload,
    }),
    [didFetchCustomers]: (state, action) => ({
      ...state,
      customers: action.payload,
    }),
    [didFetchGroupsAsAdmin]: (state, action) => ({
      ...state,
      customerSelects: action.payload,
    }),
    [resetGroupOptions]: (state) => ({ ...state, customerSelects: null }),
  },
  {
    search: null,
    externalFields: null,
    customers: null,
    customerSelects: null,
  }
);
