import axios from "axios";

export const triggerBookOfBusiness = (customerId, queryString) =>
  axios.post(
    `/api/admin/v1/reports/book_of_business/batch_exports?customer_id=${customerId}${queryString}`
  );
export const fetchBookOfBusiness = (id) =>
  axios.get(`/api/admin/v1/reports/book_of_business/batch_exports/${id}`);

export const fetchCustomers = () =>
  axios.get("/api/admin/v1/reports/customers");
