import { createAction } from "redux-actions";
import { put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../app/requestErrorHandler";
import {
  decrementLoadingCount,
  incrementLoadingCount,
} from "../components/LoadingIndicator/actions";

// A bunch of helper sagas that reduce boilerplate code

// Helper function to call an api
// First argument a function that calls the api, the arguments to this function will be the payload of the action that triggered this
// Second argument is the action that will be dispatched on success, its contents will be the server response if it is an action creator (eg a function) else it just fires the action
// If third argument (ignoreFailure) is true, instead of failing on an error, the result will just be called with nothing
// If last argument (incrementLoading)is false, the global loading indicator wont be triggered to render (useful for modals where you need the loading to be local to that modal)
// If last argument (incrementLoading) is true, the main loading indicator will automatically indicate while loading!
export function callAPI(
  apiFunctionToCall,
  successAction,
  ignoreFailure = false,
  incrementLoading = true
) {
  return function* apiCall(action) {
    try {
      if (incrementLoading) yield put(incrementLoadingCount());
      const res = yield apiFunctionToCall(action.payload);
      if (typeof successAction === "function") {
        const result = yield successAction(res.data);
        yield put(result);
      } else {
        yield put(successAction);
      }
    } catch (e) {
      if (ignoreFailure) {
        yield put(successAction(null));
      } else {
        yield handleApiError(e);
      }
    } finally {
      if (incrementLoading) yield put(decrementLoadingCount());
    }
  };
}

// Todo: Get a better name without "action" callAPI is already in use!
// You can use this function to call the API, that way you don't continuesly have to hookup the sagas!
export const callAPIAction = createAction(
  "CALL_API",
  (APIFunctionToCall, callAction, successAction) => ({
    APIFunctionToCall,
    callAction,
    successAction,
  })
);

function* handleCallToAPI(action) {
  const { APIFunctionToCall, successAction, callAction } = action.payload;
  const APICaller = callAPI(APIFunctionToCall, successAction);
  yield APICaller(callAction);
}

export function* watchCallToAPI() {
  yield takeLatest(callAPIAction, handleCallToAPI);
}
