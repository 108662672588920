import { connect } from "react-redux";
import { get } from "lodash";

import { userType } from "../../shared/routes/Authentication/Login/reducer";

import DashboardLayout from "./DashboardLayout.jsx";
import config from "../../config";

// When logged in as matthijn, sam, or other @peerwell.co account, and hitting prod api url, returns true (so we know it's live data)
function welcomeToTheDangerZone(state) {
  const DEV_ACCOUNTS = ["matthijn@me.com", "sam.august@gmail.com"];

  const loggedInAccount = get(state, "authentication.email") || "";
  const isPeerWellAccount =
    DEV_ACCOUNTS.indexOf(loggedInAccount) > -1 ||
    loggedInAccount.indexOf("peerwell.co") > -1;
  const isProductionData = config.API_URL === "https://api.peerwell.co";

  return isPeerWellAccount && isProductionData;
}

function route(state) {
  return state.router.route.split("/")[1].replace("(", ""); // Replacement is for routes that have something like /foo(/:optional) so it doesn't become /foo(
}

function mapStateToProps(state) {
  return {
    authenticatedUserType: userType(state),
    route: route(state),
    welcomeToTheDangerZone: welcomeToTheDangerZone(state),
  };
}

export default connect(mapStateToProps)(DashboardLayout);
