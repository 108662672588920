import { getProgramsAxios } from "../../../../services/api";

export const createModule = (module) =>
  getProgramsAxios().post("/modules", module);
export const updateModule = (moduleId, updates) =>
  getProgramsAxios().patch(`/modules/${moduleId}`, updates);
export const fetchPillars = () => getProgramsAxios().get("/pillars");
export const fetchCards = (page) =>
  getProgramsAxios().get(`/cards?page=${page}`);
export const fetchPageWithNCards = (page, cards_per_page) =>
  getProgramsAxios().get(`/cards?page=${page}&per_page=${cards_per_page}`);

export async function fetchAllCards() {
  const cards_per_page = 100;

  const firstPage = await fetchPageWithNCards(0, cards_per_page);
  const totalCards = firstPage.headers["x-total-count"];
  if (totalCards) {
    const queries = [];
    const limit = await p_limit();

    const totalPages = Math.ceil(totalCards / firstPage.data.length);
    for (let page = 1; page < totalPages; page++) {
      const fetchPage = async () => {
        const newCards = await fetchPageWithNCards(page, cards_per_page);
        return [...newCards.data];
      };
      queries.push(limit(() => fetchPage()));
    }
    const furtherPages = (await Promise.all(queries)).flat();
    firstPage.data = [...firstPage.data, ...furtherPages];
    return firstPage;
  }
  return firstPage;
}

let limit;
async function p_limit() {
  if (limit == null) {
    limit = (await import("p-limit")).default(10);
  }
  return limit;
}
