import { createAction } from "redux-actions";
import { updateQuery, clearQuery } from "../../../shared/routes/query";

const prefixed = (val) => `MANAGE/STAFF::${val}`;

export const requestCustomers = createAction(prefixed("REQUEST_CUSTOMERS"));
export const didFetchCustomers = createAction(
  prefixed("DID_FETCH_CUSTOMERS"),
  (customers) => customers
);

export const requestStaff = createAction(prefixed("REQUEST_STAFF"));
export const didFetchStaff = createAction(
  prefixed("DID_FETCH_STAFF"),
  (staff) => staff
);

export const updateStaff = createAction(prefixed("UPDATE_STAFF"));
export const didUpdateStaff = createAction(
  prefixed("DID_UPDATE_STAFF"),
  (staff) => staff
);

export const createStaff = createAction(prefixed("CREATE_STAFF"));
export const didCreateStaff = createAction(prefixed("DID_CREATE_STAFF"));

export const bulkCreateStaff = createAction(
  prefixed("BULK_CREATE_STAFF"),
  (clinicianId, csv) => ({ clinicianId, csv })
);
export const didBulkCreateStaff = createAction(
  prefixed("DID_BULK_CREATE_STAFF")
);

export const selectClinician = (clinicianId) => updateQuery({ clinicianId });
export const selectStaff = (staffId) => updateQuery({ staffId });
export const deselectStaff = () => updateQuery({ staffId: undefined });
export const deselectClinician = () => clearQuery();

export const clearStaffData = createAction(prefixed("CLEAR_STAFF_DATA"));
