import View from "./View.js";

import { authenticationOfTypeRequired } from "../../../../shared/routes/Authentication/Login/sagas";

export const WORKER_ROUTE_NAME = "workers";
const WORKER_ROUTE = `/${WORKER_ROUTE_NAME}/view/:id`;

const ViewRoute = {
  "/peers/view/:id": {
    component: View,
    title: "Peer Profile",
    *onEnter() {
      yield authenticationOfTypeRequired([
        "admin",
        "demo",
        "staff",
        "partner_admin",
        "owner",
        "care_team_user",
      ]);
    },
  },
};

const ViewWorkerRoute = {
  [WORKER_ROUTE]: {
    component: View,
    title: "Worker Profile",
    *onEnter() {
      yield authenticationOfTypeRequired(["admin", "prevention:read"]);
    },
  },
};

const HazardDetailsRoute = {
  "/peers/view/:id/hazardDetails": {
    component: View,
    title: "Hazard Details",
    *onEnter() {
      yield authenticationOfTypeRequired([
        "admin",
        "demo",
        "staff",
        "partner_admin",
        "owner",
        "care_team_user",
      ]);
    },
  },
};

export default Object.assign(
  {},
  HazardDetailsRoute,
  ViewRoute,
  ViewWorkerRoute
);
