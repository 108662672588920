import { authenticationOfTypeRequired } from "../../shared/routes/Authentication/Login/sagas";
import Modules from "./Modules";
import watchModules from "./saga";
import { modulesReducer } from "./reducer";

const routeSettings = {
  component: Modules,
  saga: watchModules,
  title: "Modules",
  reducer: {
    ui: {
      Modules: modulesReducer,
    },
  },
  *onEnter() {
    yield authenticationOfTypeRequired(["admin"]);
  },
};

export default {
  "/modules": routeSettings,
};
