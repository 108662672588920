import Number from "./../Number";

class Percentage extends Number {
  valueToDisplay() {
    const value = super.valueToDisplay();
    return `${Math.round(value * 100)}%`;
  }
}

export default Percentage;
