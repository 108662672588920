import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import { validateForm } from "../../../../../shared/utils/form";

import SimpleField from "../../../../../shared/components/FormFields/SimpleField";
import SelectField from "../../../../../shared/components/FormFields/SelectField";
import CheckboxField from "../../../../../shared/components/FormFields/CheckboxField";

const rules = {
  name: { presence: true },
  type: { presence: true },
};

function CreateCustomerForm({
  handleSubmit,
  onSubmit,
  onCancel,
  customerTypes,
  message,
}) {
  const notificationTypes = [
    { label: "Default", value: "default" },
    { label: "True", value: "true" },
    { label: "False", value: "false" },
  ];

  return (
    <form className="manage-customer-form" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="form-header">Add New Partner</h3>

      <div>
        <label htmlFor="name">Name</label>
        <Field className="edit-field" name="name" component={SimpleField} />
      </div>

      <Field
        name="type"
        label="Partner Type"
        values={customerTypes}
        component={SelectField}
      />

      <Field
        name="send_notifications"
        label="Send notifications"
        values={notificationTypes}
        component={SelectField}
      />

      <input className="action-btn btn-danger" type="submit" value="Add" />
      <button className="action-btn" onClick={onCancel}>
        Cancel
      </button>

      {message && <p className="message">{message}</p>}
    </form>
  );
}

CreateCustomerForm.propTypes = {
  customerTypes: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string,
  // redux-form
  handleSubmit: PropTypes.func.isRequired,
};

CreateCustomerForm.defaultProps = {
  customerTypes: null,
  message: null,
};

export default reduxForm({
  form: "manageCustomer",
  validate: validateForm(rules),
  enableReinitialize: true,
})(CreateCustomerForm);
