import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { numericality } from "redux-form-validators";
import { validateForm } from "./../../../../../../../shared/utils/form";

import FormGroupField from "./../../../../../../../shared/components/FormFields/FormGroupField";
import DateInputField from "./../../../../../../../shared/components/FormFields/DateInputField";
import MultiSelectField from "./../../../../../../../shared/components/FormFields/MultiSelectField";
import RadioList from "./../../../../../../../shared/components/FormFields/RadioList";

import "./UpdateBackground.css";

const rules = {
  birthdate: { presence: true },
  gender: { presence: true },
  feet: { presence: true },
  inches: { presence: true },
  weight: { presence: true },
};

class UpdateBackground extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      healthIssues: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { requestBackgroundForm } = this.props;
    requestBackgroundForm();
  }

  _packValues(values) {
    return values.reduce((obj, item) => {
      obj[item.value] = item.label;
      return obj;
    }, {});
  }

  handleChange(healthIssues) {
    this.setState({ healthIssues });
  }

  render() {
    const { onSubmit, handleSubmit, onCancel, message, formData } = this.props;
    const { healthIssues } = this.state;
    return (
      <div>
        <h1 className="patient-details-header">Patient Details</h1>
        <form
          className="form patient-details-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {formData &&
            formData.map((question) => {
              if (question.type === "date" || question.type === "surgery_date")
                return (
                  <div className="date-input">
                    <Field
                      name={question.name}
                      component={DateInputField}
                      label={`${question.dashboard_title || "Surgery date"} `}
                      required
                    />
                  </div>
                );
              if (question.type === "choose_one")
                return (
                  <div className="radio-list">
                    <Field
                      name={question.name}
                      horizontal
                      component={RadioList(this._packValues(question.values))}
                      label={`${question.dashboard_title} `}
                      required
                    />
                  </div>
                );
              if (question.type === "height") {
                return (
                  <div className="patient-height-container">
                    <div className="patient-height-input">
                      <Field
                        name="intake_data.height.feet"
                        type="number"
                        component={FormGroupField}
                        validate={[
                          numericality({
                            "<=": question.range[1].feet,
                            ">=": question.range[0].feet,
                          }),
                        ]}
                        label="Height (feet) "
                        required
                      />
                    </div>
                    <div className="patient-height-input">
                      <Field
                        name="intake_data.height.inches"
                        type="number"
                        component={FormGroupField}
                        validate={[
                          numericality({
                            "<=": question.range[1].inches,
                            ">=": question.range[0].inches,
                          }),
                        ]}
                        label="Height (inches) "
                        required
                      />
                    </div>
                  </div>
                );
              }
              if (question.type === "choose_multiple") {
                return (
                  <div className="multiselect">
                    <Field
                      name={question.name}
                      label={question.dashboard_title}
                      component={MultiSelectField}
                      value={healthIssues}
                      values={question.values}
                      onChange={this.handleChange}
                    />
                  </div>
                );
              }
              return (
                <div className="number-input">
                  <Field
                    name={question.name}
                    type="number"
                    component={FormGroupField}
                    label={`${question.dashboard_title} `}
                    required
                  />
                </div>
              );
            })}
          <div className="patient-detail-form-buttons">
            <button className="btn btn-cancel" onClick={onCancel}>
              Cancel
            </button>
            <input type="submit" className="btn btn-primary" value="Update" />
            {message ? <p className="form-message">{message}</p> : null}
          </div>
        </form>
      </div>
    );
  }
}

UpdateBackground.propTypes = {
  message: PropTypes.string,
  formData: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  requestBackgroundForm: PropTypes.func.isRequired,
  // Automatically added
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

UpdateBackground.defaultProps = {
  message: null,
  formData: null,
};

export default reduxForm({
  form: "updateBackground",
  validate: validateForm(rules),
})(UpdateBackground);
