import { connect } from "react-redux";
import DetransitionPeer from "./DetransitionPeer.jsx";
import { detransitionPeer } from "./actions";

import { fleetingFormMeta } from "../../../../../../../../shared/utils/form";

function mapStateToProps(state) {
  return {
    message: fleetingFormMeta(state, "detransitionPeer").message,
  };
}

function mapDispatchToProps(dispatch, { peerId }) {
  return {
    onSubmit: () => dispatch(detransitionPeer(peerId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetransitionPeer);
