import { takeLatest, put, all } from "redux-saga/effects";
import { success } from "react-notification-system-redux";
import { didCreatePhase, didUpdatePhase } from "./actions";

function* notifyUser() {
  yield put(success({ message: "The changes have been saved." }));
}

export default function* watchPhasesFormSaga() {
  yield all([
    yield takeLatest(didCreatePhase, notifyUser),
    yield takeLatest(didUpdatePhase, notifyUser),
  ]);
}
