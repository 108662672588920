// Todo: Clean file up
import axios from "axios";

import { put, takeLatest, select } from "redux-saga/effects";
import { createAction } from "redux-actions";
import { get, invert } from "lodash";
import { delay } from "redux-saga";

import { mapObject } from "./mapObject";
import {
  reducer as formReducer,
  startSubmit,
  stopSubmit,
  clearSubmitErrors,
  setSubmitFailed,
  setSubmitSucceeded,
} from "redux-form";
import { validate } from "./validate";
import { expandObject } from "./expandObject";

import { hidePanel } from "../components/ModalPanel/actions";
import { handleApiError } from "../app/requestErrorHandler";

// Some utilities to make working with Redux Form a bit easier

// It just uses the validate.js library (we have on API too)
// It makes hooking it up to the redux forms easier
export function validateForm(rules, options) {
  return function validating(data) {
    return validate(data, rules, options);
  };
}

// Helper function to make async validation easier. It handles everything for you
// You have to add the rules in the validation_controller in the API
// Fieldmapping should be an object where the key is the value name in the form, and the value is the name of the field in the validation controller
// Example: { email, cellPhone: "phone" }
export function asyncValidation(fieldMapping) {
  return function (values) {
    const dataForServer = mapObject(
      invert(fieldMapping),
      (localFieldName, remoteFieldName) => {
        return values[localFieldName];
      }
    );

    return new Promise((resolve, reject) => {
      axios
        .post("/api/website/validation/validate", dataForServer)
        .then(() => resolve())
        .catch((error) => {
          const errorData = error.response?.data.error;
          // Map it back to the fields of the form
          const formFieldErrors = mapObject(
            fieldMapping,
            (remoteFieldName, localFieldName) => {
              return errorData[remoteFieldName];
            }
          );
          reject(formFieldErrors);
        });
    });
  };
}

// HANDLING OF FORMS SUBMITTING TO API AND ERROR HANDLING
export const submitFormToAPI = createAction(
  "SUBMIT_FORM_TO_API",
  (formId, APIToCall, successAction, ...APIArgs) => ({
    formId,
    APIToCall,
    APIArgs,
    successAction,
  })
);

function* handleSubmitFormToAPI(action) {
  const { formId, APIToCall, APIArgs, successAction } = action.payload;

  yield put(clearSubmitErrors(formId));
  yield put(startSubmit(formId));

  try {
    const response = yield APIToCall.apply(APIToCall, APIArgs);
    if (response) {
      if (typeof successAction === "function") {
        yield put(
          successAction.apply(successAction, [response.data].concat(APIArgs))
        );
      } else {
        yield put(successAction);
      }
      yield put(setSubmitSucceeded(formId));
    }
  } catch (error) {
    const { response } = error;
    yield put(setSubmitFailed(formId));
    if (response.status === 400) {
      yield put(stopSubmit(formId, expandObject(response?.data.error)));
    }
    yield handleApiError(error);
  }
}

export function* watchSubmitFormToAPI() {
  yield takeLatest(submitFormToAPI, handleSubmitFormToAPI);
}
// END HANDLING OF FORMS SUBMITTING TO API AND ERROR HANDLING

// Dispatch this action and the form information will be updated in the form part of the state tree
// Normal form meta will stay there as long as the form exists
export const setFormMeta = createAction(
  "FormMeta::UPDATE_META",
  (formId, metaData) => ({ formId, metaData })
);

// Fleeting form meta will be automatically cleared upon each change to the form (so you can use it for "did save" messages, and if the form is changed, the message is automatically cleared)
export const setFleetingFormMeta = createAction(
  "FormMeta::UPDATE_FLEETING_META",
  (formId, metaData) => ({ formId, metaData })
);
// This will fade a fleeting message after a given interval (default 3000ms)
export const setFleetingMessage = (formId, { message }, timeout = 3000) => {
  return function* () {
    yield put(setFleetingFormMeta(formId, { message }));
    yield delay(timeout);
    const currentFleetingFormMeta = yield select(fleetingFormMeta, formId);
    if (currentFleetingFormMeta.message === message) {
      yield put(setFleetingFormMeta(formId, { message: null }));
      yield put(hidePanel());
    }
  };
};

// Helper function to get meta data for a form from state
export function formMeta(state, formId) {
  return get(state, `form.${formId}.metaData`) || {};
}

export function fleetingFormMeta(state, formId) {
  return get(state, `form.${formId}.fleetingMetaData`) || {};
}

// Making it possible to add meta data to the redux form, which is usefull, since when the form gets destoryed attaced meta data will be destroyed too
// So that way for form related info, I don't have to continuously keep track of cleaning
export function formMetaReducer(state, action) {
  // This is one of our meta data actions, so handle those
  if (
    [setFormMeta().type, setFleetingFormMeta().type].indexOf(action.type) !== -1
  ) {
    const { formId, metaData } = action.payload;
    if (!state[formId]) {
      throw new Error(
        "Cannot attach (fleeting) meta data to a form that does not exist"
      );
    } else {
      const newState = Object.assign({}, state);
      const metaKey =
        action.type === setFormMeta().type ? "metaData" : "fleetingMetaData";
      newState[formId][metaKey] = metaData;
      return newState;
    }
  }

  // Determine if it is a redux form action, if it is, remove fleeting meta data
  const search = "@@redux-form/";
  if (action.type.substr(0, search.length) === search) {
    // Ignoring this action, which is not really a form change
    if (action.type !== "@@redux-form/SET_SUBMIT_SUCCEEDED") {
      const formId = action.meta.form;
      if (state[formId]) {
        const newState = Object.assign({}, state);
        newState[formId].fleetingMetaData = null;
        return formReducer(newState, action);
      }
    }
  }

  // Every other action is just handled by redux form
  return formReducer(state, action);
}

// Fiy only works on sync validation!
export function isValidForm(state, formName) {
  return !get(state, `form.${formName}.syncErrors`);
}

// For normalizing fields when they get submitted, so e.g. someone's fullname is capitalized

export function downcaseEmail(value) {
  return value && value.toLowerCase().trim();
}

export function capitalizeName(value) {
  return (
    value &&
    value
      .split(/\s+/)
      .map((word) => word.substr(0, 1).toUpperCase() + word.substr(1))
      .join(" ")
      .replace(/\s+/, " ")
  );
}

// default rules scheme that is used for new forms in dashboard 2.0 (patient invite, adding/editing partner users)
// we don't pass { presence: true } in the validation rules since we keep track of that through the 'required' attribute on the fields, and the submit button is enabled only when all fields with required attribute get filled
export const defaultRules = {
  email: {
    format: {
      pattern: /^[a-zA-Z0-9_.+-=]+@[a-zA-Z0-9-=]+\.[a-zA-Z0-9-.]+$/,
      message: "Wrong format",
    },
  },
  email_address: {
    format: {
      pattern: /^[a-zA-Z0-9_.+-=]+@[a-zA-Z0-9-=]+\.[a-zA-Z0-9-.]+$/,
      message: "Wrong format",
    },
  },
  fullname: {
    format: {
      // full name needs to have a space somewhere basically, and 4 names max
      pattern: /^\S+(\s)+[\S]+(\s?)(?: [\S]+(\s?)){0,2}$/,
      message: "Wrong format",
    },
  },
  peer_fullname: {
    format: {
      // full name needs to have a space somewhere basically, and 4 names max
      pattern: /^\S+(\s)+[\S]+(\s?)(?: [\S]+(\s?)){0,2}$/,
      message: "Wrong format",
    },
  },
  phone: {
    format: {
      pattern:
        /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/,
      message: "Wrong format",
    },
  },
  mobile_number: {
    format: {
      pattern:
        /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/,
      message: "Wrong format",
    },
  },
  password: {
    format: {
      // at least 8 characters, at least one lower case, one upper case, and one number
      pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
    },
  },
};
