import { createAction } from "redux-actions";
import { setFormMeta } from "../../../../../utils/form";

const prefixed = (val) => `ResetPassword/ResetPassword::${val}`;

// Token is optional when the user is authenticated
export const requestPasswordReset = createAction(
  prefixed("RESET_PASSWORD"),
  (password, token) => ({ password, token })
);
export const didUpdatePassword = setFormMeta("resetPassword", {
  updated: true,
});
