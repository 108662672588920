import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Icon } from "react-fa";
import Spinner from "react-spinkit";
import { Field, reduxForm } from "redux-form";
import CheckboxField from "../../../../../../../../shared/components/FormFields/CheckboxField";
import DrillDownSelectField from "../../../../../../../../shared/components/FormFields/DrillDownSelectField";
import FormGroupField from "../../../../../../../../shared/components/FormFields/FormGroupField";
import PlaceholderSelectField from "../../../../../../../../shared/components/FormFields/PlaceholderSelectField";
import SearchableDropdown from "../../../../../../../../shared/components/SearchableDropdown";
import SuccessAnimation from "../../../../../../../../shared/components/SuccessAnimation";
import "../../../../../../../Patients/components/PatientInviteForm/PatientInviteForm.css";
import "./ChangePartner.css";

class ChangePartner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCustomer: null,
      clinicians: [],
    };
  }

  componentDidMount() {
    const { customers, requestCustomers } = this.props;
    if (!customers) requestCustomers();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      requestPartnerUsers,
      requestExtraFieldData,
      userId,
      fetchAvailablePrograms,
    } = this.props;
    if (
      (this.state.selectedCustomer && !prevState.selectedCustomer) ||
      (this.state.selectedCustomer &&
        prevState.selectedCustomer.id !== this.state.selectedCustomer.id)
    ) {
      fetchAvailablePrograms(this.state.selectedCustomer.id, userId);
      requestPartnerUsers(this.state.selectedCustomer.id);
      requestExtraFieldData(this.state.clinicians[0].id);
    }
  }

  render() {
    const { selectedCustomer } = this.state;

    if (!selectedCustomer) {
      return this.renderCustomerSearch();
    }

    return this.renderDynamicAdminForm(selectedCustomer);
  }

  renderCustomerSearch() {
    const { customers } = this.props;
    if (!customers) {
      return this.spinner();
    }

    const values = customers.map((c) => ({
      label: c.customer.name,
      value: c.customer.id,
      onClick: () =>
        this.setState({
          selectedCustomer: c.customer,
          clinicians: c.clinicians,
        }),
    }));

    return (
      <div className="admin-overlay change-partner-overlay">
        <SearchableDropdown
          placeholder="Choose a customer first"
          values={values}
          withRef
        />
      </div>
    );
  }

  spinner() {
    return (
      <Spinner
        className="customer-dropdown-loading"
        name="cube-grid"
        color="rgba(0,0,0,0.4)"
      />
    );
  }

  renderDynamicAdminForm(customer) {
    const {
      handleSubmit,
      onSubmit,
      submitting,
      partnerUserData,
      message,
      disabled,
      userId,
      extraFields,
    } = this.props;

    return (
      <form
        className="patient-invite-form"
        onSubmit={handleSubmit((v) => {
          onSubmit({
            ...v,
            ...{ customer: this.state.selectedCustomer.id, peerId: userId },
          });
        })}
        noValidate
      >
        <p
          className="selected-customer"
          role="button"
          onClick={() => this.setState({ selectedCustomer: null })}
        >
          {customer.name}
          <a role="button" className="clear-customer">
            <Icon name="times" />
          </a>
        </p>

        <div className="sections-container">
          <div className="add-patient-section">
            <div className="patient-invite-header-group">
              <h2>Move patient</h2>
              <p>
                required (<span className="asterisk">*</span>)
              </p>
            </div>

            <div class="patient-invite-fields">
              {this.renderProgramFields()}

              {partnerUserData &&
                partnerUserData.length > 0 &&
                this.renderCareTeam(partnerUserData)}

              {extraFields && this.renderExtraFields(extraFields)}
            </div>
          </div>
        </div>

        {message ? (
          <SuccessAnimation message={message} />
        ) : (
          <input
            className={classNames("patient-invite-submit-button", {
              disabled: disabled || submitting,
            })}
            type="submit"
            value="Move patient"
            disabled={disabled || submitting}
          />
        )}
      </form>
    );
  }

  renderProgramFields() {
    const { availableParts } = this.props;

    return (
      (availableParts && (
        <div className="field-pair">
          <Field
            className="program-dropdown"
            name="part"
            component={DrillDownSelectField}
            values={availableParts}
            label="Program"
            initialLabel="Select a program"
            required
          />
        </div>
      )) || <div>Fetching programs...</div>
    );
  }

  renderExtraFields() {
    const { extraFields } = this.props;

    if (!extraFields) return <div className="extra-fields-loading" />;

    return (
      <div className="admin-extra-fields-container">
        {extraFields.map((field) => {
          const typeToFieldMapping = {
            select: PlaceholderSelectField,
            text: FormGroupField,
            boolean: CheckboxField,
          };
          const FieldForType = typeToFieldMapping[field.type];

          if (FieldForType) {
            return (
              <div
                key={field.label + field.name}
                className={classNames("extra-field", field.type)}
              >
                <Field
                  className={classNames(
                    `patient-invite-${field.type}-field medium`
                  )}
                  type={field.type}
                  name={`external_service.${field.name}`}
                  component={FieldForType}
                  label={field.label}
                  placeholder={`Choose ${field.label.toLowerCase()}`}
                  values={field.values}
                  required={field.presence}
                />
              </div>
            );
          }

          console.error(
            `There is no field type specified for invite field of type ${field.type}`
          );
          return null;
        })}
      </div>
    );
  }

  renderCareTeam(partnerUserData) {
    return (
      <div className="extra-field">
        <Field
          className="patient-invite-select-field medium"
          name="owner"
          label="Patient owner"
          placeholder="Choose owner"
          values={partnerUserData}
          component={PlaceholderSelectField}
          required
        />
      </div>
    );
  }

  renderNoExtraFields() {
    return (
      <p>
        This customer doesn't have any specific fields or care team users (you
        can still move the patient)
      </p>
    );
  }
}

ChangePartner.propTypes = {
  userId: PropTypes.string.isRequired,
  customerId: PropTypes.string,
  customers: PropTypes.array,
};

export default reduxForm({
  form: "changePartner",
  validate: null,
})(ChangePartner);
