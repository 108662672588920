import { connect } from "react-redux";
import UpdateName from "./UpdateName.jsx";

import { fleetingFormMeta } from "../../../../../../../../shared/utils/form";
import { updateNameForInvite } from "./actions";

function mapStateToProps(state, { name }) {
  return {
    message: fleetingFormMeta(state, "updateName").message,
    initialValues: {
      firstName: (name && name.split(" ")[0]) || null,
      lastName: (name && name.split(" ")[1]) || null,
    },
  };
}

function mapDispatchToProps(dispatch, { userId }) {
  return {
    onSubmit: ({ firstName, lastName }) =>
      dispatch(updateNameForInvite(userId, firstName, lastName)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateName);
