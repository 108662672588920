import { all, takeLatest } from "redux-saga/effects";

import { callAPI } from "../../../shared/utils/sagas";
import { setFleetingMessage } from "../../../shared/utils/form";

import {
  requestCustomers,
  didFetchCustomers,
  requestStaff,
  didFetchStaff,
  updateStaff,
  didUpdateStaff,
  createStaff,
  didCreateStaff,
  bulkCreateStaff,
  didBulkCreateStaff,
} from "./actions";
import {
  fetchCustomers as fetchCustomersAPI,
  fetchStaff as fetchStaffAPI,
  updateStaff as updateStaffAPI,
  createStaff as createStaffAPI,
  bulkCreateStaff as bulkCreateStaffAPI,
} from "./api";

export default function* watchManageStaff() {
  yield all([
    takeLatest(requestCustomers, callAPI(fetchCustomersAPI, didFetchCustomers)),
    takeLatest(requestStaff, callAPI(fetchStaffAPI, didFetchStaff)),
    takeLatest(updateStaff, callAPI(updateStaffAPI, didUpdateStaff)),
    takeLatest(createStaff, callAPI(createStaffAPI, didCreateStaff)),
    takeLatest(
      bulkCreateStaff,
      callAPI(bulkCreateStaffAPI, didBulkCreateStaff)
    ),
    takeLatest(
      didCreateStaff,
      setFleetingMessage(
        "manageStaff",
        { message: "Staff member successfully created!" },
        2000
      )
    ),
    takeLatest(
      didUpdateStaff,
      setFleetingMessage(
        "manageStaff",
        { message: "Staff member successfully updated!" },
        2000
      )
    ),
    takeLatest(
      didBulkCreateStaff,
      setFleetingMessage(
        "manageStaff",
        { message: "Staff members successfully bulk uploaded!" },
        2000
      )
    ),
  ]);
}
