import { handleActions } from "redux-actions";
import {
  didUpdateAssignedTo,
  didGetAvailableAssignedToUsersForPeer,
} from "./actions";

export const availableAssignedToUsersForPeer = (state, peerId) =>
  state.ui.PeerView[peerId].availableNurses;
export const currentAssignedTo = (state, peerId) =>
  state.ui.PeerView[peerId].info.nurse;

export default handleActions(
  {
    [didUpdateAssignedTo]: (state, action) => {
      const { peerId, assignedToUser } = action.payload;
      state[peerId].info.nurse = assignedToUser; // In API it's called nurse... so keeping that structure for peer
      return Object.assign({}, state);
    },
    [didGetAvailableAssignedToUsersForPeer]: (state, action) => {
      const { peerId, assignedToUsers } = action.payload;
      state[peerId].availableNurses = assignedToUsers; // In API it's called nurse...
      return Object.assign({}, state);
    },
  },
  {}
);
