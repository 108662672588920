import { connect } from "react-redux";

import Cards from "./Cards.jsx";
import { query, updateQuery } from "../../shared/routes/query";
import {
  selectedCard,
  selectedCardId,
  cardContent,
  selectedPreviewId,
  selectedPreview,
} from "./reducer";
import { fetchCard, fetchCardsList } from "./actions";
import {
  showDeleted,
  cardsCount,
  filterCardByAssessment,
} from "./components/CardsList/reducer";
import { bindActionCreators } from "redux";

function mapStateToProps(state) {
  const cardId = selectedCardId(state);

  return {
    showEdit: !!query(state, "create") || !!query(state, "edit"),
    selectedCardId: cardId,
    selectedPreviewId: selectedPreviewId(state),
    selectedCard: selectedCard(state),
    selectedPreview: selectedPreview(state),
    count: cardsCount(state),
    showDeleted: showDeleted(state),
    filterCardByName: query(state, "filter"),
    filterCardByAssessment: filterCardByAssessment(state),
    selectedCardPreviewContent: cardContent(state),
    page: query(state, "page"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        fetchCard: (cardId) => fetchCard(cardId),
        fetchCardsList: (deleted, filter, assessment, page) =>
          fetchCardsList(deleted, filter, assessment, page),
        resetPage: () => updateQuery({ page: 0 }),
        navigateToCardsList: () =>
          updateQuery({
            edit: undefined,
            create: undefined,
          }),
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cards);
