import { flatten, get, compact } from "lodash";

// Todo: Probably should move the selectors from the reducer file here too...
import { query, urlParameter } from "../../shared/routes/query";
import { mapObjectToArray } from "../../shared/utils/mapObject";
import { formValueSelector, change } from "redux-form";
import moment from "moment";

export const isDemoInvite = (state) => {
  const isDemo = query(state, "demo", false);
  if (typeof isDemo === "string") {
    // ugh, it's a string in the url
    return isDemo === "true";
  }
  return isDemo;
};

export function selectedProgramTypes(state, form = "invitePortal") {
  const selectedSurgeryDate = formValueSelector(form)(state, "surgery_date");

  if (!selectedSurgeryDate) {
    return ["prehab", "conservative_care"];
  }

  if (moment().isBefore(selectedSurgeryDate)) {
    return ["prehab"];
  }

  return ["rehab"];
}

export function programFormData(state, invites_selector, activeProgramTypes) {
  const invites = invites_selector(state);
  if (!invites) {
    return;
  }
  // For now we just get one clinician back, but in theory there could be multiple at some point
  const programs = invites.programs[0].programs_by_body_part;

  // Conservative programs are filtered below when needed (hidden when any surgery date is selected)
  // Depending on prehab / rehab / conservative care different options are available

  // API send conservative care and prehab in prehab section, rehab has it's own section
  const programType =
    activeProgramTypes.indexOf("rehab") !== -1 ? "rehab" : "prehab";

  const programsOfType = programs[programType];

  const programsPerPart = mapObjectToArray(
    programsOfType,
    (bodyPart, programsOfBodyPart) => {
      const fieldItems = programsOfBodyPart.map((programData) => {
        const isV2 = programData.version === 2;

        return programData.clinical_descriptions.map((description) => ({
          label: isV2 ? programData.name : description,
          value: isV2
            ? {
                aggregate_program: programData.aggregate_program,
                clinician_id: programData.clinician,
                phase_id:
                  programData.version === 2 ? programData.program : null,
                phase_num: programData.kind === "prehab" ? 1 : 2,
              }
            : { registration_code: programData.code },
        }));
      });

      const flattened = flatten(fieldItems);

      const marked =
        activeProgramTypes.indexOf("conservative_care") === -1
          ? filterConservative(flattened)
          : flattened;

      if (marked.length === 0) {
        return null;
      }

      return {
        label: bodyPart,
        value: bodyPart,
        items: marked,
      };
    }
  );

  return compact(programsPerPart);
}

function filterConservative(items) {
  return items.filter(
    (item) => item.label.toLowerCase().indexOf("conservative") === -1
  );
}

export function invite(state) {
  return get(state, "ui.InvitePortal.invite");
}

export function inviteExtraFields(state) {
  const inviteList = get(state, "ui.InvitePortal.invite");
  const extraFields = {};
  if (inviteList && inviteList.programs[0].extra_fields) {
    inviteList.programs[0].extra_fields.forEach((field) => {
      if (field.type === "select")
        extraFields[field.type] = field.values[0].value;
    });
  }
  return extraFields;
}

export function selectedClinicianId(state) {
  return urlParameter(state, "clinician");
}
