import React from "react";
import PropTypes from "prop-types";

import Arc from "./Arc";

import "./ProgramProgress.css";
import { appColors } from "../../../../../../shared/utils/colors";

function ProgramProgress({ completion, countdown }) {
  const totalDays = countdown.total_nr_days;
  let daysLeft = totalDays - countdown.current_day;

  if (daysLeft === Number.POSITIVE_INFINITY) {
    daysLeft = "N/A";
  }

  if (!completion.interaction_percentage) {
    completion.interaction_percentage = 0;
  }

  return (
    <div className="program-progress">
      <h1>Bardavon Recovery+ Progress</h1>

      <div className="row">
        <div className="col-md-4">
          <Arc
            value={completion.percentage}
            maxValue={100}
            label="Card Compliance"
            color={[
              { value: 25, color: appColors.goodColor },
              { value: 10, color: appColors.warningColor },
              { value: 0, color: appColors.errorColor },
            ]}
            unit="%"
          />
        </div>
      </div>
    </div>
  );
}

ProgramProgress.propTypes = {
  completion: PropTypes.object.isRequired,
  countdown: PropTypes.object.isRequired,
};

export default ProgramProgress;
