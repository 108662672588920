import { connect } from "react-redux";

import Table from "./Table.jsx";

import {
  externalServiceSettings,
  mapToExternalServiceFields,
} from "../../../reducer";

function mapStateToProps(state) {
  return {
    extraFields: mapToExternalServiceFields(externalServiceSettings(state)),
  };
}

export default connect(mapStateToProps)(Table);
