import React, { useEffect, useRef, useState } from "react";
import { SortableData, SortDirection } from "./types";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridSortModel,
} from "@mui/x-data-grid";

import "./DataTable.css";

interface PropTypes {
  columns: GridColDef[];
  rows: GridRowsProp;
  totalItems: number;
  fetchItems: (sortableData: SortableData) => any;
  name?: string;
  description?: string;
  isLoading?: boolean;
  defaultSortBy?: string; // Which field the table sorts by without specific sorting model
}

export const DEFAULT_PER_PAGE = 25;
const DEBOUNCE_DELAY = 500;

// Simple all-inclusive data table that can handle:
// Server-run Pagination, Sorting, & Searching
const DataTable = ({
  columns,
  rows,
  totalItems,
  fetchItems,
  name,
  description,
  isLoading = false,
  defaultSortBy = "",
}: PropTypes) => {
  // PAGINATION
  // Data format required for mui DataGrid
  const [paginationModel, setPaginationModel] = useState({
    pageSize: DEFAULT_PER_PAGE,
    page: 0,
  });
  const onPageModelChange = (newPaginationModel: any) => {
    setPaginationModel(newPaginationModel);
    fetchItems({
      page: newPaginationModel.page,
      perPage: newPaginationModel.pageSize,
      sortBy: sortingModel.field,
      sortDirection: sortingModel.sort,
      searchString: searchString,
    });
  };

  // SORTING
  // Data format required for mui DataGrid
  const defaultSortingModel = {
    field: defaultSortBy || "",
    sort: SortDirection.Descending,
  };
  const [sortingModel, setSortingModel] = useState(defaultSortingModel);
  const onSortModelChange = (newSortingModel: any) => {
    if (!newSortingModel || !newSortingModel[0]) {
      // Reset to default if not provided
      setSortingModel(defaultSortingModel);
      fetchItems({
        page: paginationModel.page,
        perPage: paginationModel.pageSize,
        sortBy: defaultSortingModel.field,
        sortDirection: defaultSortingModel.sort,
        searchString: searchString,
      });
    } else {
      setSortingModel(newSortingModel[0]);
      setPaginationModel({
        page: 0,
        pageSize: DEFAULT_PER_PAGE,
      });

      fetchItems({
        page: paginationModel.page,
        perPage: paginationModel.pageSize,
        sortBy: newSortingModel[0].field || "",
        sortDirection: newSortingModel[0].sort || "",
        searchString: searchString,
      });
    }
  };

  // SEARCHING
  const searchTimeoutIdRef = useRef(0);
  const [searchString, setSearchString] = useState<string>("");
  const performSearch = () => {
    setPaginationModel({
      page: 0,
      pageSize: DEFAULT_PER_PAGE,
    });

    fetchItems({
      page: 0,
      perPage: DEFAULT_PER_PAGE,
      sortBy: sortingModel.field,
      sortDirection: sortingModel.sort,
      searchString: searchString,
    });
  };
  useEffect(() => {
    // Replace current search debounce
    if (searchTimeoutIdRef.current) {
      clearTimeout(searchTimeoutIdRef.current);
    }
    // Perform search after debounce has completed
    searchTimeoutIdRef.current = setTimeout(
      () => performSearch(),
      DEBOUNCE_DELAY
    ) as unknown as number;
  }, [searchString]);

  // ON LOAD
  useEffect(() => {
    fetchItems({
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
      sortBy: defaultSortBy || sortingModel.field,
      sortDirection: sortingModel.sort,
      searchString: searchString,
    });
  }, []);

  return (
    <div className="datatable" style={{ width: "100%" }}>
      <div className="datatable-top">
        <div className="datatable-name">
          {name ? <h1>{name}</h1> : null}
          {description ? <p>{description}</p> : null}
        </div>
        <div className="datatable-search">
          <input
            type="text"
            placeholder="Search..."
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
        </div>
      </div>
      <DataGrid
        rows={rows}
        columns={columns}
        rowCount={totalItems}
        pageSizeOptions={[DEFAULT_PER_PAGE]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={onPageModelChange}
        sortingMode="server"
        onSortModelChange={onSortModelChange}
        loading={isLoading}
        disableRowSelectionOnClick
      />
    </div>
  );
};

export default DataTable;
