import { connect } from "react-redux";

import GroupSelector from "./GroupSelector.jsx";

import { reportableCodes } from "./reducer";
import { requestReportableCodes } from "./sagas";

function mapStateToProps(state, ownProps) {
  // Combining the reportable codes fetched from the server with the all_reportable_codes property so we can change to that one too
  const groups = {
    all_reportable_codes: true,
    code: reportableCodes(state),
  };

  return { groups };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    requestReportableCodes: () => dispatch(requestReportableCodes()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupSelector);
