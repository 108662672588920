import { createAction } from "redux-actions";

const prefixed = (val) => `Peers/View/Email::${val}`;

export const updateEmailForPeer = createAction(
  prefixed("UPDATE_EMAIL_FOR_PEER"),
  (peerId, email) => ({ email, peerId })
);
export const didUpdateEmail = createAction(
  prefixed("DID_UPDATE_EMAIL"),
  (peerId, email) => ({ email, peerId })
);
