import { authenticationOfTypeRequired } from "../../shared/routes/Authentication/Login/sagas";
import Programs from "./Programs";
import watchPrograms from "./saga";
import { programsReducer } from "./reducer";

const routeSettings = {
  component: Programs,
  saga: watchPrograms,
  title: "Programs",
  reducer: {
    ui: {
      Programs: programsReducer,
    },
  },
  *onEnter() {
    yield authenticationOfTypeRequired(["admin"]);
  },
};

export default {
  "/programs": routeSettings,
};
