import { connect } from "react-redux";

import { requestPeerFilter } from "./actions";

import Filter from "./Filter.jsx";

const dispatch = {
  fetchFilters: () => requestPeerFilter(),
};

export default connect(null, dispatch)(Filter);
