import { put } from "redux-saga/effects";
import List from "./List.js";

import { authenticationOfTypeRequired } from "../../../../shared/routes/Authentication/Login/sagas";

import reducer from "./reducer";
import watchListSaga from "./sagas";
import { getExternalServiceFields } from "../actions";
// # Todo: Allow nesting or something
export default {
  "/peers/overview/(:page)(/)": {
    component: List,
    *onEnter() {
      yield authenticationOfTypeRequired(["admin", "demo", "staff"]);
      yield put(getExternalServiceFields());
    },
    reducer: {
      ui: {
        // Todo:Move to Peers
        PeersList: reducer,
      },
    },
    saga: watchListSaga,
    title: "Peer List",
  },
};
