import { connect } from "react-redux";
import {
  requestCustomers,
  requestUpdateEndDate,
  requestUpdateStartDate,
  requestWeeklyAdoptionReport,
} from "./actions";
import {
  customers,
  endDate,
  reportForCustomer,
  selectedCustomer,
  startDate,
} from "./reducer";
import WeeklyAdoptionReport from "./WeeklyAdoptionReport.jsx";

function mapStateToProps(state) {
  const customer = selectedCustomer(state);

  return {
    customers: customers(state),
    selectedCustomer: customer,
    startDate: startDate(state),
    endDate: endDate(state),
    report: reportForCustomer(state, customer ? customer.id : null),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestCustomers: () => dispatch(requestCustomers()),
    requestReport: (payload) => dispatch(requestWeeklyAdoptionReport(payload)),
    updateStartDate: (timestamp) => dispatch(requestUpdateStartDate(timestamp)),
    updateEndDate: (timestamp) => dispatch(requestUpdateEndDate(timestamp)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeeklyAdoptionReport);
