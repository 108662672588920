import { connect } from "react-redux";
import UpdatePhone from "./../../../../../../components/UserView/components/Forms/UpdatePhone";

import { updatePhoneForInvite } from "./actions";

function mapStateToProps(state, { phone }) {
  return {
    initialValues: {
      phone,
    },
  };
}

function mapDispatchToProps(dispatch, { userId }) {
  return {
    onSubmit: ({ phone }) => dispatch(updatePhoneForInvite(userId, phone)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePhone);
