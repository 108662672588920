import { connect } from "react-redux";
import UpdateCareTeamMember from "./UpdateCareTeamMember.jsx";

import { fleetingFormMeta } from "../../../../../../../shared/utils/form";

function mapStateToProps(state, { userId, member }) {
  let initialValues = {
    job: member.job,
  };

  return {
    initialValues,
    message: fleetingFormMeta(state, "updateCareTeamMember").message,
  };
}

export default connect(mapStateToProps)(UpdateCareTeamMember);
