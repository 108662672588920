import { find } from "lodash";

import ExternalService from "./ExternalService.js";

// For some field types the pretty value is not stored in the db, so need to look that up for display purposes
export function prettyLabelForExternalFieldValue(
  field,
  value,
  externalFieldSettings
) {
  const fieldName = field.fullPath || field.name;
  const settingsForField = find(
    externalFieldSettings,
    (settings) => settings.name === fieldName
  );

  if (settingsForField && settingsForField.type === "select") {
    const values = settingsForField.values;
    const valueSetting = find(values, (v) => v.value === value);
    if (valueSetting) {
      // Having this if, so if we can't find we fallback to the stored value
      return valueSetting.label;
    }
  }

  return value;
}

export default ExternalService;
