import { connect } from "react-redux";

import {
  fetchPinnedCards,
  fetchProgramCards,
  pinCard,
  unpinCard,
} from "./actions";
import PinnedCards from "./PinnedCards.jsx";
import {
  selectedProgramId,
  selectedProgramCards,
  selectedPinnedCards,
  selectedProgramType,
} from "./reducer";

function mapStateToProps(state) {
  const programId = selectedProgramId(state);
  const programType = selectedProgramType(state);
  return {
    selectedProgramId: programId,
    selectedProgramType: programType,
    pinnedCards: selectedPinnedCards(state),
    programCards: selectedProgramCards(state),
  };
}

const dispatch = {
  fetchPinnedCards: (programId) => fetchPinnedCards(programId),
  fetchProgramCards: (programId) => fetchProgramCards(programId),
  pinCard: (programId, values) => pinCard(programId, values),
  unpinCard: (programId, cardId) => unpinCard(programId, cardId),
};

export default connect(mapStateToProps, dispatch)(PinnedCards);
