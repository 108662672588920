// Just firing the app opened event here
import { takeLatest } from "redux-saga/effects";

import { appDidStart } from "./shared/app/loaded";

function* onAppStart() {}

export function* watchAppDidStart() {
  yield takeLatest(appDidStart, onAppStart);
}
