import authStorage from "./authStorage";
import { error } from "react-notification-system-redux";
import { store } from "./store";
import { logout } from "../routes/Authentication/Login/actions";
import { defaultRoutes } from "../../routes/Authentication";
import { push } from "redux-little-router";

const logoutNotification = {
  title: "You have been logged out",
  message:
    "For security reasons, you have been logged out due to inactivity. You will be redirected to the login page.",
  position: "tc",
  autoDismiss: 0,
};

export const logIdleUserOut = () => {
  // No need to logout if we are not logged in
  if (authStorage.get() && Object.keys(authStorage.get()).length != 0) {
    try {
      store.dispatch(logout());
      store.dispatch(push(defaultRoutes.unauthenticated));
    } catch (error) {
      // Errors here are auth errors, and we are logging out, so ignore them
    }
    store.dispatch(error(logoutNotification));
  }
};
