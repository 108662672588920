import { createAction } from "redux-actions";
import { put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../../shared/app/requestErrorHandler";
import { reportableCodes as reportableCodesAPI } from "./api";

const prefixed = (value) => `Metrics/GroupSelector::${value}`;

export const requestReportableCodes = createAction(
  prefixed("requestReportableCodes")
);
export const didFetchReportableCodes = createAction(
  prefixed("didFetchReportableCodes")
);

function* fetchReportableCodes(action) {
  try {
    const reportableCodes = yield reportableCodesAPI();
    yield put(didFetchReportableCodes(reportableCodes.data));
  } catch (e) {
    yield handleApiError(e);
  }
}

export function* watchRequestReportableCodes() {
  yield takeLatest(requestReportableCodes, fetchReportableCodes);
}
