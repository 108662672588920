import { createAction } from "redux-actions";

const prefixed = (val) => `Invites/View/Language::${val}`;

export const didUpdateLanguage = createAction(
  prefixed("DID_UPDATE_LANGUAGE"),
  (inviteId, language) => ({ language, inviteId })
);

export const didUpdatePeerLanguage = createAction(
  prefixed("DID_UPDATE_PEER_LANGUAGE"),
  (peerId, language) => ({ language, peerId })
);
