import { handleActions } from "redux-actions";
import { castArray, get, sortBy } from "lodash";
import { didFetchCustomers } from "./actions";

export function customers(state) {
  const customersList = get(state, "ui.InvitePortal.customers");
  // eslint-disable-next-line no-return-assign
  if (customersList)
    customersList.forEach(
      (customer) => (customer.clinicians = sortBy(customer.clinicians, "name"))
    );
  return customersList && sortBy(customersList, "customer.name");
}

export function clinicianFormData(state) {
  const currentCustomers = customers(state) || [];
  return currentCustomers.map((customer) => ({
    label: customer.customer.name,
    value: customer.customer.id,
    items: customer.clinicians.map((c) => ({
      value: { clinician_id: c.id, customer_id: customer.customer.id },
      label: `${c.name} (${castArray(c.emails).join(", ")})`,
    })),
  }));
}

export const customerFormReducer = handleActions(
  {
    [didFetchCustomers]: (state, action) => ({
      ...state,
      customers: action.payload,
    }),
  },
  {
    customers: null,
  }
);
