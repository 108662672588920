import WeeklyAdoptionReport from "./WeeklyAdoptionReport.js";

import {
  authenticationOfTypeRequired,
  authenticationRequired,
} from "../../../shared/routes/Authentication/Login/sagas";
import watchWeeklyAdoptionReportSaga from "./sagas";
import reducer from "./reducer";

export default {
  "/weekly-adoption-report(/:customerId)": {
    component: WeeklyAdoptionReport,
    reducer: {
      ui: {
        WeeklyAdoptionReports: reducer,
      },
    },
    saga: watchWeeklyAdoptionReportSaga,
    title: "Weekly Adoption Report",
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired(["admin"]);
    },
  },
};
