import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { cardListItemShape } from "./proptypes";

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.onEditButton = this.onEditButton.bind(this);
    this.onPreviewButton = this.onPreviewButton.bind(this);
  }

  onEditButton(e) {
    const { id, onEditButton, userType } = this.props;
    const canEdit = ["admin", "clinician"];

    if (canEdit.indexOf(userType) === -1) {
      return;
    }

    onEditButton(id);
    e.stopPropagation();
  }

  onPreviewButton() {
    const { id, onPreviewButton } = this.props;
    onPreviewButton(id);
  }

  render() {
    const { name, isSelected } = this.props;
    return (
      <li
        className={classNames("card-list-item", {
          "card-list-item-active": isSelected,
        })}
        onClick={this.onPreviewButton}
        onDoubleClick={this.onEditButton}
      >
        {name}
        <i className="fa fa-pencil-square-o" onClick={this.onEditButton} />
      </li>
    );
  }
}

Card.propTypes = Object.assign({}, cardListItemShape, {
  onEditButton: PropTypes.func.isRequired,
  onPreviewButton: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  userType: PropTypes.string.isRequired,
});

export default Card;
