import React from "react";
import PropTypes from "prop-types";

import Chart from "./../Chart";
import Locked from "./../Locked";

// Renders either the locked component or the chart component depening on what is relevant
class Conditional extends React.Component {
  render() {
    const { metric, isDashboard, registrationDate } = this.props;

    // Still locked
    if (metric.unlock) {
      return <Locked metric={metric} isDashboard={isDashboard} />;
    }

    return (
      <Chart
        metric={metric}
        showActionButton={!isDashboard}
        isDashboard={isDashboard}
        registrationDate={registrationDate}
      />
    );
  }
}

Conditional.propTypes = {
  metric: PropTypes.object.isRequired,
  isDashboard: PropTypes.bool,
  registrationDate: PropTypes.number.isRequired,
};

Conditional.defaultProps = {
  isDashboard: false,
};

export default Conditional;
