import { authenticationOfTypeRequired } from "../../shared/routes/Authentication/Login/sagas";
import Phases from "./Phases";
import watchPhases from "./saga";
import { phasesReducer } from "./reducer";

const routeSettings = {
  component: Phases,
  saga: watchPhases,
  title: "Phases",
  reducer: {
    ui: {
      Phases: phasesReducer,
    },
  },
  *onEnter() {
    yield authenticationOfTypeRequired(["admin"]);
  },
};

export default {
  "/phases": routeSettings,
};
