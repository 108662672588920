export function polynomialFunctionBuilder(coefficients) {
  return function (value) {
    var result = 0;
    for (var i = 0; i < coefficients.length; i++) {
      const coefficient = coefficients[i];
      result += coefficient * Math.pow(value, i);
    }
    return result;
  };
}
