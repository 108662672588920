import { connect } from "react-redux";
import {
  requestBillingReport,
  requestCustomers,
  requestUpdateAmount,
  requestUpdateEndDate,
  requestUpdateStartDate,
} from "./actions";
import {
  amount,
  customers,
  endDate,
  reportForCustomer,
  selectedCustomer,
  startDate,
} from "./reducer";
import BillingReport from "./BillingReport.jsx";

function mapStateToProps(state) {
  const customer = selectedCustomer(state);

  return {
    customers: customers(state),
    selectedCustomer: customer,
    startDate: startDate(state),
    endDate: endDate(state),
    amount: amount(state),
    report: reportForCustomer(state, customer ? customer.id : null),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestCustomers: () => dispatch(requestCustomers()),
    requestReport: (payload) => dispatch(requestBillingReport(payload)),
    updateStartDate: (timestamp) => dispatch(requestUpdateStartDate(timestamp)),
    updateEndDate: (timestamp) => dispatch(requestUpdateEndDate(timestamp)),
    updateAmount: (value) => dispatch(requestUpdateAmount(value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingReport);
