import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import "./SimpleNumber.css";

function SimpleNumber({ label, value, className, hideWhenNone }) {
  if (hideWhenNone && (value === null || value === undefined)) {
    return null;
  }

  return (
    <div className={classNames("simple-number", className)}>
      <h2>{value}</h2>
      <p>{label}</p>
    </div>
  );
}

SimpleNumber.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node,
  hideWhenNone: PropTypes.bool,
  className: PropTypes.string,
};

SimpleNumber.defaultProps = {
  hideWhenNone: false,
  className: null,
  value: null,
};

export default SimpleNumber;
