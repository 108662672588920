import Cards from "./Cards.js";

import { authenticationOfTypeRequired } from "../../shared/routes/Authentication/Login/sagas";

import watchCards from "./saga";
import { cardsReducer } from "./reducer";

const routeSettings = {
  component: Cards,
  saga: watchCards,
  title: "Cards",
  reducer: {
    ui: {
      Cards: cardsReducer,
    },
  },
  *onEnter() {
    yield authenticationOfTypeRequired(["admin"]);
  },
};

export default {
  "/cards(/:program)": routeSettings,
};
