import { connect } from "react-redux";

import { fleetingFormMeta } from "../../../../../shared/utils/form";

import UploadUsersForm from "./UploadUsersForm.jsx";

function mapStateToProps(state, { onSubmit, onCancel, admin }) {
  return {
    onSubmit,
    onCancel,
    admin,
    message: fleetingFormMeta(state, "uploadUsers").message,
  };
}

export default connect(mapStateToProps)(UploadUsersForm);
