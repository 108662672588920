import { success } from "react-notification-system-redux";
import { put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../shared/app/requestErrorHandler";
import {
  deleteModule,
  didDeleteModule,
  didRestoreModule,
  restoreModule,
} from "./actions";
import {
  deleteModule as deleteModuleAPI,
  restoreModule as restoreModuleAPI,
} from "./api";

function* notifyUser() {
  yield put(success({ message: "The changes have been saved." }));
}

function* deleteModuleSaga(action) {
  try {
    yield deleteModuleAPI(action.payload);
    yield put(didDeleteModule(action.payload));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* restoreModuleSaga(action) {
  try {
    yield restoreModuleAPI(action.payload);
    yield put(didRestoreModule(action.payload));
  } catch (e) {
    yield handleApiError(e);
  }
}

export default function* watchBottomBarSaga() {
  yield takeLatest(deleteModule, deleteModuleSaga);
  yield takeLatest(restoreModule, restoreModuleSaga);
  yield takeLatest(didDeleteModule, notifyUser);
}
