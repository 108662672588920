import { connect } from "react-redux";

import ModulesList from "./ModulesList.jsx";

import { updateQuery } from "../../../../shared/routes/query";
import { modulesList, showDeleted } from "./reducer";
import { onShowDeletedChange } from "./actions";

function mapStateToProps(state) {
  return {
    modulesList: modulesList(state),
    showDeleted: showDeleted(state),
  };
}

const dispatch = {
  updateFilter: (filter) => updateQuery({ filter }),
  onAddModuleClicked: () => updateQuery({ create: true }),
  onShowDeletedChange: (deleted) => onShowDeletedChange(deleted),
};

export default connect(mapStateToProps, dispatch)(ModulesList);
