import { connect } from "react-redux";
import Program from "./Program.jsx";
import { editProgram } from "./actions";
import { isSelectedForPinCards } from "./reducer";

function mapStateToProps(state, { id }) {
  return {
    isSelected: isSelectedForPinCards(state, id),
  };
}

const dispatch = {
  onEditButton: (id) => editProgram(id),
};

export default connect(mapStateToProps, dispatch)(Program);
