import { createAction } from "redux-actions";

const prefixed = (val) => `Peers/View/RemoveCareTeamMember::${val}`;

export const removeCareTeamMember = createAction(
  prefixed("REMOVE_CARE_TEAM_MEMBER")
);
export const didRemoveCareTeamMember = createAction(
  prefixed("DID_REMOVE_CARE_TEAM_MEMBER")
);
