import { handleActions } from "redux-actions";
import authStorage from "../../../app/authStorage";
import {
  confirm2Fa,
  failedLogin,
  fetchedUserInfo,
  logout,
  requestLogin,
  resetForm,
  successFulLogin,
} from "./actions";
import { firstSupportedRole } from "../../../../routes/Authentication";

export function isAuthenticated(state) {
  return !!state.authentication.token;
}

export function userType(state) {
  return (
    state.authentication.userType ??
    firstSupportedRole(state.authentication.scope)
  );
}

// You probably won't send this to the server! (Use authentication instead)
export function userId(state) {
  return state.authentication.id;
}

export function userEmail(state) {
  return state.authentication.email;
}

export function userFullname(state) {
  return state.userInfo.fullname;
}

export function userCustomerId(state) {
  return state.userInfo.customer_id;
}

export function isLoggingIn(state) {
  return state.ui.Login.isLoggingIn;
}

export function isBlocked(state) {
  const error = state.ui.Login.error;
  return (
    error && typeof error === "string" && error.toLowerCase() === "blocked user"
  );
}

export function loginError(state) {
  const error = state.ui.Login.error;
  // we intercept the error when the user is blocked because we don't want to display the error on the screen
  if (isBlocked(state)) return null;

  return error;
}

export function loginMessage(state) {
  return state.ui.Login.message;
}

export function isConfirming2Fa(state) {
  return state.ui.Login.isConfirming2Fa;
}

export function needsPasswordReset(state) {
  return state.userInfo.force_password_reset;
}

const uiDefaults = {
  error: null,
  isLoggingIn: false,
};

export const uiReducer = handleActions(
  {
    [failedLogin]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoggingIn: false,
    }),
    [requestLogin]: (state, action) => ({
      ...state,
      error: null,
      isLoggingIn: true,
    }),
    [resetForm]: (state, action) => ({
      ...state,
      ...uiDefaults,
      isLoggingIn: false,
    }),
    [confirm2Fa]: (state, action) => ({
      ...state,
      isLoggingIn: false,
      isConfirming2Fa: true,
    }),
  },
  uiDefaults
);

const defaults = {
  email: null,
  token: null,
};

export const userInfoReducer = handleActions(
  {
    [fetchedUserInfo]: (state, action) => ({ ...state, ...action.payload }),
  },
  {}
);

export const reducer = handleActions(
  {
    [successFulLogin]: (state, action) => {
      const { token, tokenType, ...restAuth } = action.payload;

      // Officially a reducer should only update state, but this is in a way setting state
      // and it should always be in sync anyway, so it makes sense to have it here
      authStorage.set({ token, ...restAuth });

      return { ...state, ...action.payload };
    },
    [logout]: (state, action) => {
      authStorage.remove();
      // Empty state will remove Authentication there
      return {};
    },
  },
  defaults
);
