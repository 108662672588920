import { put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../../../../../shared/app/requestErrorHandler";
import { setFleetingMessage } from "../../../../../../../../shared/utils/form";
import { didUpdateOwner, updateOwner } from "./actions";
import { updateOwner as updateOwnerAPI } from "./api";

function* submitOwnerUpdate(action) {
  let owner = action.payload.owner;
  let peerId = action.payload.peerId;
  try {
    const response = yield updateOwnerAPI(peerId, owner);
    yield put(didUpdateOwner(action.payload));
  } catch (e) {
    yield handleApiError(e);
  }
}

export default function* watchUpdateOwner() {
  yield takeLatest(updateOwner, submitOwnerUpdate);
  yield takeLatest(
    didUpdateOwner,
    setFleetingMessage("updateOwner", { message: "Invitation sent" })
  );
}
