import { SortableData, SortDirection } from "../../components/DataTable/types";
import { getPreventionAxios } from "../../services/api";
import { objectToQuery } from "../../shared/routes/query";
import { Worker } from "./types";

export enum ApiStatus {
  OK = 200,
}

interface FetchWorkersRequest extends SortableData {}

interface FetchWorkersRequestObject {
  filter?: string;
  sortBy: string;
  sortDirection: SortDirection;
  page: number;
  perPage: number;
}

interface FetchWorkersResult {
  data: Worker[];
  status: number;
  headers?: any;
}

export const fetchWorkers = ({
  searchString = "",
  sortBy = "",
  sortDirection = SortDirection.Ascending,
  page = 0,
  perPage = 5,
}: FetchWorkersRequest) => {
  const requestObject: FetchWorkersRequestObject = {
    sortBy: sortBy,
    sortDirection: sortDirection,
    page: page,
    perPage: perPage,
  };
  if (searchString !== "") {
    requestObject.filter = encodeURIComponent(searchString);
  }
  const query = objectToQuery(requestObject);
  return getPreventionAxios().get(
    `/workers?${query}`
  ) as unknown as Promise<FetchWorkersResult>;
};
