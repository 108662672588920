import { createAction } from "redux-actions";

const prefixed = (val) => `Peers/View/Name::${val}`;

export const updateNameForPeer = createAction(
  prefixed("UPDATE_NAME_FOR_PEER"),
  (peerId, fullname) => ({ fullname, peerId })
);
export const didUpdateName = createAction(
  prefixed("DID_UPDATE_NAME"),
  (peerId, fullname) => ({ fullname, peerId })
);
