import {
  authenticationOfTypeRequired,
  authenticationRequired,
} from "../../../shared/routes/Authentication/Login/sagas";

import ManageCareTeamUsers from "./ManageCareTeamUsers.js";
import { ManageCareTeamUsersReducer } from "./reducer";
import watchManageCareTeamUsers from "./sagas";

// TODO: flat routes for now, but refactor to nest it like so: /manage/customer
export default {
  "/manage-care-team-users": {
    component: ManageCareTeamUsers,
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired(["admin"]);
    },
    reducer: {
      ui: {
        ManageCareTeamUsers: ManageCareTeamUsersReducer,
      },
    },
    saga: watchManageCareTeamUsers,
    title: "Manage Care Team Users",
  },
};

export const emailRegexValidator =
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
