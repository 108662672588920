import { takeLatest, put } from "redux-saga/effects";

import {
  setFleetingMessage,
  submitFormToAPI,
} from "../../../../../../../../shared/utils/form";

import { updateCareTeamMember, didUpdateCareTeamMember } from "./actions";
import { updateCareTeamMember as updateCareTeamMemberAPI } from "./api";

function* submitCareTeamMemberUpdate(action) {
  const { peerId, job, member } = action.payload;

  yield put(
    submitFormToAPI(
      "updateCareTeamMember",
      updateCareTeamMemberAPI,
      didUpdateCareTeamMember(action.payload),
      peerId,
      job,
      member
    )
  );
}

export default function* watchUpdateCareTeamMember() {
  yield takeLatest(updateCareTeamMember, submitCareTeamMemberUpdate);
  yield takeLatest(
    didUpdateCareTeamMember,
    setFleetingMessage("updateCareTeamMember", {
      message: "Update successful",
    })
  );
}
