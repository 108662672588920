import React from "react";
import Portal from "react-portal/lib/LegacyPortal"; // Not sure why that libs direct access does not work.. eg import { Portal } from 'react-portal'...
import PropTypes from "prop-types";
import { Icon } from "react-fa";
import classNames from "classnames";

import ClickOutsideListener from "../ClickOutsideListener";

import "./ModalPanel.css";

class ModalPanel extends React.Component {
  calculateHeight(margin = 0) {
    const windowHeight = window.innerHeight;
    // Subtracting things in the panel that take height too, for example the close button and things like that
    const otherPanelComponentsHeight = 50;

    return (
      windowHeight -
      parseInt(margin, 10) * 2 -
      otherPanelComponentsHeight +
      "px"
    );
  }

  render() {
    const {
      visible,
      route,
      maskBackground,
      children,
      onCloseButton,
      backgroundColor,
      borderRadius,
      contentHeight,
      contentPadding,
      topMargin,
      width,
      withCloseButton,
      name,
      withClickOutsideListener,
    } = this.props;

    if (!visible) {
      return null;
    }

    // If the height is a percentage, calculate it of screen height
    const calculatedHeight =
      contentHeight === "fill"
        ? this.calculateHeight(topMargin)
        : contentHeight;

    const modal = (
      <div>
        <div
          className={classNames("modal-panel", {
            "modal-panel-with-width": !!width,
          })}
          style={{
            marginTop: topMargin,
            paddingLeft: contentPadding,
            paddingRight: contentPadding,
            backgroundColor,
            borderRadius,
            width,
          }}
        >
          {withCloseButton && (
            <a role="button" onClick={onCloseButton} className="close-button">
              <Icon name="times" />
            </a>
          )}
          <div
            className="content-container"
            style={{ height: calculatedHeight }}
          >
            {children}
          </div>
        </div>
      </div>
    );

    return (
      <Portal>
        <div
          className={classNames(
            "portal-container",
            `portal-${name}`,
            `portal-container-in-${route}`
          )}
        >
          {withClickOutsideListener ? (
            <ClickOutsideListener onClickOutside={onCloseButton}>
              {modal}
            </ClickOutsideListener>
          ) : (
            modal
          )}
          {maskBackground ? <div className="background-mask" /> : null}
        </div>
      </Portal>
    );
  }
}

ModalPanel.propTypes = {
  // You can set these
  name: PropTypes.string.isRequired,
  maskBackground: PropTypes.bool,
  children: PropTypes.element.isRequired,
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.string,
  contentPadding: PropTypes.string,
  topMargin: PropTypes.string,
  contentHeight: PropTypes.string, // Can be either a css value, or "fill" which calculates the height in such a way that it fills the height of the screen minus (topMargin * 2)
  withCloseButton: PropTypes.bool,
  withClickOutsideListener: PropTypes.bool, // lets you close the modal by clicking outside of the dom node's boundaries
  // These are automatically set
  visible: PropTypes.bool.isRequired,
  onCloseButton: PropTypes.func.isRequired,
  width: PropTypes.string,
  route: PropTypes.string.isRequired,
};

ModalPanel.defaultProps = {
  borderRadius: null,
  backgroundColor: null,
  contentPadding: "20px",
  topMargin: "200px",
  contentHeight: null,
  maskBackground: true,
  onClose: null,
  withCloseButton: true,
  withClickOutsideListener: false,
};

export default ModalPanel;
