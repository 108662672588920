import React, { useState } from "react";
import axios from "axios";
import Confirm from "../../../../shared/components/Confirm/Confirm";

export function Archive({ patient }) {
  const [archived, setArchived] = useState(!!patient.archived_at);
  const [loading, setLoading] = useState(false);
  const [archiveAlertVisible, setArchiveAlertVisible] = useState(false);

  async function updateArchived(newArchived) {
    setLoading(true);
    await axios.post(`/api/admin/v1/search/archive/${patient.id}`, {
      is_archived: newArchived,
    });
    setArchived(newArchived);
    setLoading(false);
    document.location.reload();
  }

  if (loading) {
    return <input type="checkbox" disabled checked={archived} />;
  }

  return (
    <>
      <input
        style={{ cursor: "pointer" }}
        onClick={async () => {
          setArchiveAlertVisible(true);
        }}
        type="checkbox"
        checked={archived}
        className={archived ? "archived-checkbox" : "unarchived-checkbox"}
      />
      <Confirm
        callback={(confirmed) => {
          setArchiveAlertVisible(false);

          if (confirmed) {
            return updateArchived(!archived);
          }
        }}
        message={message(patient, archived)}
        subText={subText(patient, archived)}
        visible={archiveAlertVisible}
      />
    </>
  );
}

function message(patient, isArchived) {
  const n = patient.display_name || patient.name;
  const action = isArchived ? "unarchive" : "archive";
  return `Are you sure you want to ${action} ${n}`;
}

function subText(patient, isArchived) {
  if (!hasSubText(patient)) {
    return;
  }

  const append = isArchived ? "." : " in the future.";

  return `This patient will reset to program start when unarchived${append}`;
}

function hasSubText(patient) {
  const p = patient.program || patient.formal_program_name.toLowerCase();

  const in_ghl_program = p.indexOf("ghl") !== -1;
  const in_blank_ghl_program = p.indexOf("blank") !== -1;

  return in_ghl_program && !in_blank_ghl_program;
}
