import { connect } from "react-redux";

import RequestUpdatePassword from "./RequestUpdatePassword.jsx";
import { fleetingFormMeta } from "../../../../../utils/form";
import { requestUpdatePassword } from "./actions";

function mapStateToProps(state) {
  return {
    message: fleetingFormMeta(state, "requestUpdatePassword").message,
  };
}

const dispatch = {
  onSubmit: ({ email }) => requestUpdatePassword(email),
};

export default connect(mapStateToProps, dispatch)(RequestUpdatePassword);
