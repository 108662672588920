import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { reduxForm, Field } from "redux-form";

import {
  validateForm,
  defaultRules,
  capitalizeName,
} from "../../../utils/form";
import { passwordStrength } from "../../../utils/password_validation";
import Logo from "../../../../assets/bardavon-recovery-logo.png";

import PlaceholderField from "../../../components/FormFields/PlaceholderField";

import "./Register.css";

const rules = {
  password: {
    presence: true,
    strongPassword: true,
  },
  repeat: {
    presence: true,
    equality: "password",
  },
  fullname: {
    ...defaultRules.fullname,
  },
  mfa_token: {
    length: {
      minimum: 6,
    },
  },
};

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgLoaded: false,
      showPasswordRequirements: false,
    };
    this.onPasswordFocus = this.onPasswordFocus.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onPasswordFocus = this.onPasswordFocus.bind(this);
    this.onPasswordBlur = this.onPasswordBlur.bind(this);
  }

  componentDidMount() {
    const { requestRegistrantEmail, secret } = this.props;
    requestRegistrantEmail(secret);
  }

  onPasswordFocus() {
    this.setState({ showPasswordRequirements: true });
  }

  onPasswordBlur() {
    this.setState({ showPasswordRequirements: false });
  }

  // onRepeatFocus () {
  //   this.setState({ showRepeatRequirments: true })
  // }

  // onRepeatBlur () {
  //   this.setState({ showRepeatRequirements: false })
  // }

  onPasswordChange(e) {
    const input = e.target.value;
    const requirementsResult = passwordStrength(input);

    this.setState({ requirementsResult });
  }

  renderPasswordRequirements() {
    const { showPasswordRequirements, requirementsResult } = this.state;
    const { registrantUserType } = this.props;

    return (
      <div
        className={classNames("password-requirements", {
          asCareTeamUser: registrantUserType === "care_team_user",
          showPasswordRequirements,
          hidePasswordRequirements: !showPasswordRequirements,
        })}
      >
        <div>Must contain:</div>
        {requirementsResult &&
          requirementsResult.map((r) => {
            return (
              <div
                className={classNames("requirement", {
                  requirementSatisfied: r.passes,
                })}
              >
                <div className="checkmark" />
                <div className="description">{r.message}</div>
              </div>
            );
          })}
      </div>
    );
  }

  render() {
    const {
      handleSubmit,
      onSubmit,
      registrantEmail,
      registrantUserType,
      disabled,
      submitting,
    } = this.props;
    // make sure everything displays at once, otherwise janky looking ui
    const containerStyle =
      this.state.imgLoaded && registrantEmail ? {} : { display: "none" };

    return (
      <div style={containerStyle} className="registration-container">
        <div className="registration-wrapper">
          <div className="peerwell-logo-container">
            <img
              src={Logo}
              title="Welcome to Bardavon Recovery+!"
              alt="Bardavon Recovery+ Logo"
              onLoad={() => this.setState({ imgLoaded: true })}
            />
          </div>

          <div className="registrant-email">{registrantEmail}</div>

          <form className="registration-form" onSubmit={handleSubmit(onSubmit)}>
            {registrantUserType === "care_team_user" && (
              <Field
                className="registration-field"
                name="fullname"
                type="text"
                component={PlaceholderField}
                label="First last name"
                format={capitalizeName}
                required
              />
            )}

            {/* because chrome autofills these fields whenever there is a password field present, and i couldnt find a better way to get rid of them (i tried all the most common stack overflow answers) */}
            <input className="dummy-field" name="email" type="email" />
            <input className="dummy-field" type="password" />

            <Field
              className="registration-field"
              name="password"
              type="password"
              autoComplete="new-password"
              component={PlaceholderField}
              label="Choose password"
              onFocus={this.onPasswordFocus}
              onBlur={this.onPasswordBlur}
              onChange={this.onPasswordChange}
              required
            />

            <Field
              className="registration-field"
              name="repeat"
              type="password"
              autoComplete="new-password"
              component={PlaceholderField}
              label="Repeat password"
              required
            />

            <Field name="secret" type="hidden" component={PlaceholderField} />

            <button
              className={classNames("registration-button", {
                disabled: disabled || submitting,
              })}
              disabled={disabled || submitting}
            >
              Register
            </button>

            {this.renderPasswordRequirements()}
          </form>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

Register.defaultProps = {
  message: null,
};

export default reduxForm({
  form: "registerAccount",
  enableReinitialize: true,
  validate: validateForm(rules),
})(Register);
