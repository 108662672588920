import axios from "axios";

export const fetchCustomers = () => axios.get("/api/admin/v1/customers/");

export const fetchStaff = (clinicianId) =>
  axios.get("/api/admin/v1/staff/", {
    params: { clinician_id: clinicianId, per_page: 500 },
  });

export const updateStaff = ({ staffId, ...values }) =>
  axios.patch(`/api/admin/v1/staff/${staffId}`, { ...values });

export const createStaff = ({ clinicianId, ...values }) =>
  axios.post("/api/admin/v1/staff/", {
    clinicians: [clinicianId],
    ...values,
  });

export const bulkCreateStaff = ({ clinicianId, csv }) => {
  const form = new FormData();
  form.append("clinician_id", clinicianId);
  form.append("file", csv, "staff-bulk-create-template.csv");

  return axios.post("/api/admin/v1/staff-collection/", form);
};
