import { connect } from "react-redux";

import Phase from "./Phase.jsx";

import { editPhase } from "./actions";

const dispatch = {
  onEditButton: (id) => editPhase(id),
};

export default connect(null, dispatch)(Phase);
