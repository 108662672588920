import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import FormGroupField from "../../../../../../../../shared/components/FormFields/FormGroupField";
import AutocompleteSelectField from "../../../../../../../../shared/components/FormFields/AutocompleteSelectField";
import DrillDownSelectField from "../../../../../../../../shared/components/FormFields/DrillDownSelectField";
import CheckboxField from "../../../../../../../../shared/components/FormFields/CheckboxField";
import { validateForm } from "../../../../../../../../shared/utils/form";

let rules = {};

class Form extends React.Component {
  renderField(field) {
    // Make sure the rules object is updated so live validation works
    rules[field.name] = field.rules;

    const component =
      (field.type === "select" && DrillDownSelectField) ||
      (field.type === "boolean" && CheckboxField) ||
      FormGroupField;

    return (
      <Field
        name={field.name}
        label={field.label}
        key={field.name}
        component={component}
        values={field.values}
      />
    );
  }

  render() {
    const { service, settings, onSubmit, message, handleSubmit } = this.props;

    return (
      <div>
        <h1>Update {service}</h1>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          {settings.map((field) => this.renderField(field))}
          <input type="submit" className="btn btn-primary" value="Update" />
          {message ? <p className="form-message">{message}</p> : null}
        </form>
      </div>
    );
  }
}

Form.propTypes = {
  settings: PropTypes.array,
  service: PropTypes.string.isRequired,
  data: PropTypes.object,
  message: PropTypes.string,
  userId: PropTypes.string.isRequired,

  // Redux form
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "externalService",
  validate: validateForm(rules),
})(Form);
