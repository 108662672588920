import { connect } from "react-redux";

import { updateQuery, clearQuery, query } from "../../../shared/routes/query";

import { requestAllUsers, updateUser } from "./actions";
import { allUsers, selectedUser } from "./reducer";

import ManageCareTeamUsers from "./ManageCareTeamUsers.jsx";

function mapStateToProps(state) {
  return {
    userList: allUsers(state),
    user: selectedUser(state),
    userId: query(state, "userId"),
  };
}

const dispatch = {
  requestAllUsers: () => requestAllUsers(),
  updateUser: (values) => updateUser(values),
  selectUser: (userId) => updateQuery({ userId }),
};

export default connect(mapStateToProps, dispatch)(ManageCareTeamUsers);
