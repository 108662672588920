// Helper to convert array to object.
// Example with data: [{type:"x"}, {type: "y"}]

// mapArrayToObject(bar, (item, accumulated) => {
//   accumulated[item.type] = item
// })
//
// becomes: {x: type: 'x', y: type: 'y'}
function mapArrayToObject(arr, callback) {
  let acc = {};
  for (let i = 0; i < arr.length; i++) {
    const value = arr[i];
    acc = Object.assign(acc, callback(value, acc, i));
  }
  return acc;
}

export default mapArrayToObject;
