import { connect } from "react-redux";
import { reset } from "redux-form";

import { fleetingFormMeta } from "../../../shared/utils/form";

import {
  clinicianData,
  selectedClinicianId,
  staffData,
  displayInfo,
  selectedStaff,
} from "./reducer";
import {
  requestCustomers,
  selectClinician,
  requestStaff,
  selectStaff,
  deselectStaff,
  deselectClinician,
  clearStaffData,
  updateStaff,
  createStaff,
  bulkCreateStaff,
} from "./actions";

import ManageStaff from "./ManageStaff.jsx";

/* eslint-disable no-mixed-operators */

function mapStateToProps(state) {
  const selectedStaffMember = selectedStaff(state);
  const { clinicianDisplayName, customerDisplayName, clinicianCodes } =
    displayInfo(state);
  return {
    clinicianData: clinicianData(state),
    selectedClinicianId: selectedClinicianId(state),
    clinicianDisplayName,
    customerDisplayName,
    clinicianCodes,
    staffData: staffData(state),
    selectedStaff: selectedStaffMember,
    initialValues: {
      email: selectedStaffMember && selectedStaffMember.email,
      login_blocked:
        (selectedStaffMember && selectedStaffMember.login_blocked) || false,
    },
    message: fleetingFormMeta(state, "manageStaff").message,
  };
}

const dispatch = {
  requestCustomers: () => requestCustomers(),
  selectClinician: ({ clinicianId }) => selectClinician(clinicianId),
  requestStaff: (clinicianId) => requestStaff(clinicianId),
  selectStaff: (staffId) => selectStaff(staffId),
  deselectStaff: () => deselectStaff(),
  deselectClinician: () => deselectClinician(),
  clearStaffData: () => clearStaffData(),
  updateStaff: (values) => updateStaff(values),
  createStaff: (values) => createStaff(values),
  bulkCreateStaff: (clinicianId, csv) => bulkCreateStaff(clinicianId, csv),
  resetStaffForm: () => reset("manageStaff"),
};

export default connect(mapStateToProps, dispatch)(ManageStaff);
