import { connect } from "react-redux";

import ProfilePicture from "./ProfilePicture.jsx";
import upload from "./upload";

function mapActionsToProps() {
  return {
    upload,
  };
}

export default connect(null, mapActionsToProps)(ProfilePicture);
