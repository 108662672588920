import { connect } from "react-redux";

import ExternalService from "./../../../../../User/components/UserView/components/ExternalService";
import { submitFormToAPI } from "../../../../../../shared/utils/form";

import { updateExternalService } from "./api";
import { didUpdateExternalService } from "./actions";

const FORM_ID = "externalService";

function mapDispatchToProps(dispatch, { userId, service }) {
  return {
    onSubmit: (values) =>
      dispatch(
        submitFormToAPI(
          FORM_ID,
          updateExternalService,
          didUpdateExternalService(userId, service, values),
          userId,
          values
        )
      ),
  };
}

export default connect(null, mapDispatchToProps)(ExternalService);
