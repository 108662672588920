import React from "react";
import PropTypes from "prop-types";
import { castArray } from "lodash";
import BodyClassName from "react-body-classname";

// Helper node to render things based of authenticated user type
// Pass type to limit rendering of children based on passed type (as string or array of types)
// Pass userSpecificClassName to have different container classes based of authenticated user
// Pass userSpecificBodyClassName to append to body class based on authenticated user
function UserSpecific({
  type,
  children,
  authenticatedUserType,
  className,
  userSpecificClassName,
  userSpecificBodyClassName,
}) {
  const isOfType = castArray(type).indexOf(authenticatedUserType) !== -1;
  const showChildren = isOfType || !type;

  const bodyClass = userSpecificBodyClassName[authenticatedUserType];
  const bodyClassElement = bodyClass && <BodyClassName className={bodyClass} />;

  if (showChildren) {
    let combinedClassName = `${
      userSpecificClassName[authenticatedUserType] || ""
    } ${className || ""}`.trim();
    if (combinedClassName === "") {
      combinedClassName = null;
    }
    return (
      <div className={combinedClassName}>
        {children}
        {bodyClassElement}
      </div>
    );
  }

  return bodyClassElement || null;
}

UserSpecific.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
  authenticatedUserType: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,

  // Format: { admin: 'admin-classname', demo: 'demo-classname' etc }
  userSpecificClassName: PropTypes.object,
  userSpecificBodyClassName: PropTypes.object,
};

UserSpecific.defaultProps = {
  userSpecificClassName: {},
  userSpecificBodyClassName: {},
};

export default UserSpecific;
