import React from "react";
import PropTypes from "prop-types";
import inflection from "inflection";

class UpdateProcedureType extends React.Component {
  componentDidMount() {
    const { getPossibleTypes, clearMessage } = this.props;
    getPossibleTypes();
    clearMessage();
  }

  render() {
    const { currentType, possibleTypes, onChange, message } = this.props;
    if (!possibleTypes) {
      return null;
    }

    return (
      <div className="update-procedure-type">
        <div className="form-group">
          <label htmlFor="procedureType">Procedure type</label>
          <select
            name="procedureType"
            className="form-control"
            defaultValue={currentType}
            onChange={onChange}
          >
            {!currentType && (
              <option value="none" selected disabled>
                NOT SET
              </option>
            )}
            {possibleTypes.map((type) => (
              <option key={type} value={type}>
                {inflection.humanize(type)}
              </option>
            ))}
          </select>
        </div>
        {message ? <p>{message}</p> : null}
      </div>
    );
  }
}

UpdateProcedureType.propTypes = {
  userId: PropTypes.string.isRequired,

  // Added automatically
  currentType: PropTypes.string.isRequired,
  possibleTypes: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  getPossibleTypes: PropTypes.func.isRequired,
  clearMessage: PropTypes.func.isRequired,
  message: PropTypes.string,
};

UpdateProcedureType.defaultProps = {
  possibleTypes: null,
  message: null,
};

export default UpdateProcedureType;
