import { connect } from "react-redux";

import { fleetingFormMeta } from "../../../../../shared/utils/form";

import UpdateUsersForm from "./UpdateUsersForm.jsx";
import { userInitialValues } from "../../reducer";

function mapStateToProps(state, { onSubmit, onCancel, user, groups }) {
  return {
    initialValues: userInitialValues(state),
    onSubmit,
    onCancel,
    user,
    groups,
    message: fleetingFormMeta(state, "updateUsers").message,
  };
}

export default connect(mapStateToProps)(UpdateUsersForm);
