import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import FormikMdField from "../../../../shared/components/FormFields/FormikMdField/FormikMdField";
import FormikSelectField from "../../../../shared/components/FormFields/FormikSelectField/FormikSelectField";
import FormikTextField from "../../../../shared/components/FormFields/FormikTextField";
import BottomBar from "../Edit/components/BottomBar";
import { createBehavior, updateBehavior } from "./api";
import { mapCardToForm, mapFormToCard } from "./cardFormMapper";

export default ({ card, onSave, pillarOptions, assessmentOptions }) => (
  <Formik
    initialValues={mapCardToForm(card)}
    enableReinitialize={true}
    validationSchema={validation}
    onSubmit={async (values, { setSubmitting }) => {
      await createOrUpdateCard(card?.id, values);
      setSubmitting(false);
      onSave();
    }}
  >
    {(props) => (
      <Form data-test="edit-card">
        <FormikTextField label="Title" name="name" required={true} />
        <FormikMdField label="Content" name="content" required={true} />
        <FormikSelectField
          label="Pillar"
          name="pillar"
          options={pillarOptions}
          required={true}
        />
        <FormikSelectField
          id="action"
          label="Link Type"
          name="linkType"
          options={assessmentTypes}
        />
        {props.values.linkType === "assessment" && (
          <FormikSelectField
            label="Assessment"
            name="assessment"
            options={assessmentOptions}
          />
        )}
        {props.values.linkType === "url" && (
          <FormikTextField name="url" label="Action Url" />
        )}
        {props.initialValues.slug ? (
          <FormikTextField name="slug" label="Slug" disabled="true" />
        ) : (
          <FormikTextField name="slug" label="Slug" />
        )}
        {props.values.slug && (
          <p>
            Card url: https://app.bardavon.com/{props.values.slug}
            /&#123;&#123;contact.id&#125;&#125;
          </p>
        )}
        <FormikMdField label="Description" name="description" />
        <FormikTextField label="Duration" name="duration" />
        <FormikMdField label="Notes" name="notes" />
        <label>
          <Field type="checkbox" name="deleted" data-test="deleted" />
          deleted
        </label>
        <FormikTextField
          label="V1 IDs"
          name="v1Ids"
          description="This is a comma-separated list of v1 card ids that this card is a replacement for. Separate entries are split by a comma."
        />
        <BottomBar />
      </Form>
    )}
  </Formik>
);

const validation = Yup.object({
  name: Yup.string().required("Required"),
  content: Yup.string().required("Required"),
  pillar: Yup.string().required("Required"),
  slug: Yup.string()
    .optional()
    .matches(
      "^[a-z-\\d]*$",
      "Slug must contain only lowercase letters and dashes"
    ),
});

async function createOrUpdateCard(id, form) {
  const card = mapFormToCard(id, form);
  if (!id) {
    await createBehavior(card);
    return;
  }
  await updateBehavior(card.id, card);
}

const assessmentTypes = [
  { label: "Assessment", value: "assessment" },
  { label: "URL", value: "url" },
];
