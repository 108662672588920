import { handleActions } from "redux-actions";
import { onDidShowDeletedChanged } from "./actions";

export const phasesList = (state) => state.ui.Phases.list || [];
export const listCount = (state) => state.ui.Phases.count || 0;
export const showDeleted = (state) => state.ui.Phases.showDeleted || false;

export default handleActions(
  {
    [onDidShowDeletedChanged]: (state, action) => ({
      ...state,
      showDeleted: action.payload,
    }),
  },
  {
    showDeleted: false,
  }
);
