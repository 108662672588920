import { connect } from "react-redux";
import UpdateCareTeamMember from "./../../../../../../components/UserView/components/Forms/UpdateCareTeamMember";

import { updateCareTeamMember } from "./actions";

function mapDispatchToProps(dispatch, { userId, member }) {
  return {
    onSubmit: (values) => {
      dispatch(
        updateCareTeamMember({
          ...values,
          peerId: userId,
          member: member,
        })
      );
    },
  };
}

export default connect(null, mapDispatchToProps)(UpdateCareTeamMember);
