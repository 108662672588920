import { handleActions } from "redux-actions";
import { didDeleteProgram } from "./actions";

export default handleActions(
  {
    [didDeleteProgram]: (state, action) => {
      const programId = action.payload;
      const { [programId]: ignored, ...programs } = { ...state.phases };
      const list = state.list
        .slice()
        .filter((program) => program.id !== programId);
      return { ...state, programs, list };
    },
  },
  {}
);
