import { connect } from "react-redux";
import { push } from "redux-little-router";

import { updateQuery, clearQuery, query } from "../../../shared/routes/query";
import { fleetingFormMeta } from "../../../shared/utils/form";
import { shouldDisplayLoadingIndicator } from "../../../shared/components/LoadingIndicator/reducer";

import {
  requestCustomers,
  requestPrograms,
  requestClinician,
  createClinician,
  updateClinician,
  clearClinicianData,
} from "./actions";
import {
  allCustomers,
  allAggregatePrograms,
  selectedCustomer,
  selectedClinician,
} from "./reducer";

import ManageClinician from "./ManageClinician.jsx";

function mapStateToProps(state) {
  return {
    customerList: allCustomers(state),
    programList: allAggregatePrograms(state),
    customer: selectedCustomer(state),
    customerId: query(state, "customerId"),
    clinician: selectedClinician(state),
    clinicianId: query(state, "clinicianId"),
    isLoading: shouldDisplayLoadingIndicator(state),
    message: fleetingFormMeta(state, "manageClinician").message,
  };
}

const dispatch = {
  requestCustomers: () => requestCustomers(),
  requestPrograms: () => requestPrograms(),
  requestClinician: (clinicianId) => requestClinician(clinicianId),
  createClinician: (values) => createClinician(values),
  updateClinician: (values) => updateClinician(values),
  selectCustomer: (customerId) => updateQuery({ customerId }),
  deselectCustomer: () => clearQuery(),
  selectClinician: (clinicianId) => updateQuery({ clinicianId }),
  deselectClinician: () => updateQuery({ clinicianId: undefined }),
  clearClinicianData: () => clearClinicianData(),
  goToManageStaff: (clinicianId) =>
    push(`/manage-staff/?clinicianId=${clinicianId}`),
  goToManagePrograms: (clinicianId) =>
    push(`/manage-programs/?clinicianId=${clinicianId}`),
};

export default connect(mapStateToProps, dispatch)(ManageClinician);
