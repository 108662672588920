import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class Program extends React.Component {
  constructor(props) {
    super(props);
    this.onEditButton = this.onEditButton.bind(this);
  }

  onEditButton(e) {
    const { id, onEditButton } = this.props;

    onEditButton(id);
    e.stopPropagation();
  }

  render() {
    const { name, isSelected } = this.props;
    return (
      <li
        className={classNames("program-list-item", {
          "program-list-item-active": isSelected,
        })}
        onDoubleClick={this.onEditButton}
      >
        {name}
        <i className="fa fa-pencil-square-o" />
      </li>
    );
  }
}

Program.propTypes = {
  onEditButton: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

export default Program;
