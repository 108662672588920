import axios from "axios";

export const login = ({ email, password, mfa_token }) =>
  axios.post(
    "/auth/signin",
    mfa_token ? { email, password, mfa_token } : { email, password }
  );

export function userInfo() {
  return axios.get("/api/admin/v1/user/info");
}

export const logout = () => axios.post("/auth/logout");
