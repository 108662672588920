import classNames from "classnames";
import { useField } from "formik";
import React from "react";
import Select from "react-select";

const FormikSelectField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { error, touched, warning } = meta;

  const { className, id, multi, name, options = [], required } = props;
  const shouldDisplayError = touched && error;

  const fieldClass = classNames(
    { "has-error": shouldDisplayError && error },
    className
  );

  const handleChange = (value = {}) => {
    const target = multi
      ? { ...field, value }
      : { ...field, value: value.value };
    field.onChange({ target });
  };

  const values =
    !required && !multi
      ? [{ label: "Choose one", value: "" }, ...options]
      : options;

  return (
    <div className="form-group" data-test={name}>
      <label htmlFor={id || name}>
        {label}
        {required ? " *" : null}
      </label>

      <Select
        {...props}
        className={fieldClass}
        joinValues
        onChange={handleChange}
        options={values || []}
        value={field.value}
      />

      {shouldDisplayError &&
        ((error && <span className="error-message">{error}</span>) ||
          (warning && <span className="warning-message">{warning}</span>))}
    </div>
  );
};

export default FormikSelectField;
