import { createAction } from "redux-actions";

const prefixed = (val) => `Peer/Actions/UpdateSurgeryDate::${val}`;

export const updateSurgeryDate = createAction(
  prefixed("UPDATE_SURGERY_DATE"),
  (peerId, date) => ({ peerId, date })
);
export const didUpdateSurgeryDate = createAction(
  prefixed("DID_UPDATE_SURGERY_DATE"),
  (peerId, info) => ({ peerId, info })
);
export const setSurgeryDateMessage = createAction(
  prefixed("SET_SURGERY_DATE_MESSAGE"),
  (message) => message
);
