import ManageCustomerRoute from "./Customer";
import ManageClinicianRoute from "./Clinician";
import ManageUsersRoute from "./Users";
import ManageStaffRoute from "./Staff";
import ManageProgramsRoute from "./Programs";
import ManageCareTeamUsers from "./CareTeamUsers";

export default Object.assign(
  {},
  ManageCustomerRoute,
  ManageClinicianRoute,
  ManageUsersRoute,
  ManageStaffRoute,
  ManageProgramsRoute,
  ManageCareTeamUsers
);
