// Setting up a very basic auto update system
// Checks a certain file every hour, is it different? We have an update! Notify user and refresh

import { createAction } from "redux-actions";
import { put, takeLatest, all } from "redux-saga/effects";
import { delay } from "redux-saga";

import { updateInfo as updateInfoAPI, version as versionAPI } from "./api";

const prefixed = (value) => `APP::${value}`;

const UPDATE_CHECK_INTERVAL = 60 * 60 * 1000; // Each hour
const PREFIX_UPDATE =
  "There is a new update for the application available. Press OK to load the newer version.";

export const requestUpdateCheck = createAction(
  prefixed("REQUEST_UPDATE_CHECK")
);
const requestDelayedUpdateCheck = createAction(
  prefixed("REQUEST_DELAYED_UPDATE_CHECK"),
  (interval = UPDATE_CHECK_INTERVAL) => interval
);
const hasUpdate = createAction(prefixed("HAS_UPDATE"));

let lastDeploymentInfo = null;

function* notifyUserOfUpdate(action) {
  const message = action.payload;
  const confirmMessage = message
    ? `${PREFIX_UPDATE}\n\n${message}`
    : PREFIX_UPDATE;

  // User accepts update reload
  if (window.confirm(confirmMessage)) {
    document.location.reload(true);
  } else {
    // The user denied, trying again next time
    yield put(requestDelayedUpdateCheck());
  }
}

function* checkForUpdates() {
  try {
    const versionResponse = yield versionAPI();
    console.info("Checking app version", versionResponse);
    const deplyomentInfo = versionResponse.deploy_date;

    // Continue update system if there is an update
    if (lastDeploymentInfo && lastDeploymentInfo !== deplyomentInfo) {
      // Get any update description
      const updateInfo = yield updateInfoAPI();
      // Notify the user
      yield put(hasUpdate(updateInfo));
    } else {
      // Keep track of current deployment info, and try again later
      lastDeploymentInfo = deplyomentInfo;
      yield put(requestDelayedUpdateCheck());
    }
  } catch (e) {
    // On an error we just try again later
    yield put(requestDelayedUpdateCheck());
  }
}

function* delayUpdateCheck(action) {
  yield delay(action.payload);
  yield put(requestUpdateCheck());
}

export function* watchUpdates() {
  yield all([
    takeLatest(requestDelayedUpdateCheck, delayUpdateCheck),
    takeLatest(requestUpdateCheck, checkForUpdates),
    takeLatest(hasUpdate, notifyUserOfUpdate),
  ]);
}
