import { handleActions } from "redux-actions";

import { didFetchBehaviorInfo } from "./sagas";
import { keyBy } from "lodash";

export function behaviorInfo(state) {
  // Todo: Should not be stored in behaviors.behaviors
  const behaviors = state.ui.CardMetrics.behaviors.behaviors;
  return behaviors ? keyBy(behaviors, "id") : {};
}

export default handleActions(
  {
    [didFetchBehaviorInfo]: (state, action) => ({
      ...state,
      behaviors: action.payload,
    }),
  },
  {
    behaviors: {},
  }
);
