import { connect } from "react-redux";
import UpdateAssignedTo from "./../../../../../../components/UserView/components/Forms/UpdateAssignedTo";

import {
  updateAssignedToForInvite,
  getAvailableAssignedToUsersForInvite,
} from "./actions";

import {
  availableAssignedToUsersForInvite,
  currentAssignedTo,
} from "./reducer";

export function mapStateToProps(state, { userId }) {
  const assignedToForInvite = currentAssignedTo(state, userId);
  return {
    availableAssignedToUsers: availableAssignedToUsersForInvite(state, userId),
    assignedTo: assignedToForInvite && assignedToForInvite.id,
  };
}

function mapDispatchToProps(dispatch, { userId }) {
  return {
    fetchAvailableAssignedToUsers: () =>
      dispatch(getAvailableAssignedToUsersForInvite(userId)),
    onAssignedToChange: (e) => {
      let value = e.target.value;
      if (value === "-") {
        value = null;
      }
      return dispatch(updateAssignedToForInvite(userId, value));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAssignedTo);
