import React from "react";
import PropTypes from "prop-types";

import "./Assessment.css";
import Overlay from "./Overlay";

const ucfirst = (v) => `${v.charAt(0).toUpperCase()}${v.slice(1)}`;

class Assessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false,
    };

    this.clear = this.clear.bind(this);
  }

  clear() {
    this.setState({ showOverlay: false });
    document.location.reload(); // not sure why without reload breaks
  }

  renderResults(results) {
    return results.map(({ name, value, description }) => (
      <tr key={name}>
        <td>{name}</td>
        <td>{description}</td>
      </tr>
    ));
  }

  render() {
    const { assessment } = this.props;

    return (
      <li
        className="assessment-score"
        onClick={() => this.setState({ showOverlay: true })}
        key={assessment.id}
      >
        <h2>{ucfirst(assessment.name)}</h2>

        {assessment.result && (
          <table className="assessment-table table">
            <tbody>{this.renderResults(assessment.result)}</tbody>
          </table>
        )}

        {!assessment.result && (
          <table className="assessment-table table">
            <tbody>
              <tr>
                <td>Click for details</td>
              </tr>
            </tbody>
          </table>
        )}

        {this.renderOverlay()}
      </li>
    );
  }

  renderOverlay() {
    const { assessment } = this.props;
    const { showOverlay } = this.state;

    if (showOverlay === true) {
      return <Overlay assessment={assessment} onClear={this.clear} />;
    }
    return null;
  }
}

Assessment.propTypes = {
  // Todo: Be more specific
  assessment: PropTypes.object.isRequired,
};

export default Assessment;
