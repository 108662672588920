// XXX Disabling, as seems not used (the "owner" concept replaced the "nurse"); once confirmed (after a while), this file (and all related code) can be removed
const updateNurseDisabledMsg =
  "Update nurse action is disabled, please contact support";

export const updateAssignedToForInvite = () => {
  throw new Error(updateNurseDisabledMsg);
};

export const getAvailableAssignedToUsersForInvite = () => {
  throw new Error(updateNurseDisabledMsg);
};
