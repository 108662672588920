import { connect } from "react-redux";
import { callAPIAction } from "../../../../shared/utils/sagas";
import { didFetchInvite, fetchInvite } from "./actions";
import { fetchInvite as fetchInviteAPI } from "./api";

import UserView from "./../../../User/components/UserView";
import ExternalService from "./components/ExternalService";
import Actions from "./components/Actions";

import {
  invite,
  selectedInviteId,
  inviteEvents,
  careTeam,
  owner,
} from "./reducer";
import { previousPage } from "../../../../shared/routes/query";

// TODO: stealing these calls from /peer route's sagas for now, maybe refactor later
import { requestCareTeam, requestOwner } from "../../Peer/View/sagas";

// We re-use the peer page to display the invite, but with some values missing... So converting our invite information to something that matches a peer
function mapInviteToPeerStub(userInvite) {
  if (!userInvite) {
    return null;
  }
  return {
    ...userInvite, // first, so that when we have new peer / invite values it defaults to adding them
    id: userInvite.id,
    program: userInvite.program,
    formal_program_name: userInvite.formal_program_name,
    about: {
      name: userInvite.fullname,
      email: userInvite.email,
      registration_code: userInvite.code,
      phone: userInvite.phone,
    },
    surgery_date: userInvite.surgery_date,
    language: userInvite.language,
    intake_data: {
      procedure_type: "Not registered",
    },
    customer: userInvite.customer,
    clinician: userInvite.clinician,
    formatted_birthday: "Not registered",
    inviter: userInvite.inviter,
    nurse: userInvite.nurse,
    external_service: userInvite.external_service,
    invited_at: userInvite.created_at,
    options: userInvite.options,
    states: userInvite.states,
    state: userInvite.state,
    archived_at: userInvite.archived_at,
  };
}

function mapInviteToStubProfile(userInvite) {
  if (!userInvite) {
    return null;
  }

  return {
    completion: {
      percentage: 0,
    },
    countdown: {
      current_day: 0,
      end_date: userInvite.surgery_date,
      start_date: userInvite.surgery_date,
      surgery_date: userInvite.surgery_date,
      total_nr_days: Number.POSITIVE_INFINITY,
    },
    metrics: [
      {
        info: {
          title: "Six Minute Walk Test",
        },
      },
      {
        info: {
          title: "Activity Level",
        },
      },
    ],
  };
}

function mapStateToProps(state) {
  const inviteId = selectedInviteId(state);
  const currentInvite = invite(state, inviteId);
  return {
    selectedId: inviteId,
    user: mapInviteToPeerStub(currentInvite),
    profile: mapInviteToStubProfile(currentInvite),
    userType: "invite",
    careTeam: careTeam(state, inviteId),
    owner: owner(state, inviteId),
    returnToUserList: previousPage(state),
    externalService: currentInvite && currentInvite.clinician.external_service,
    externalServiceSettings:
      currentInvite && currentInvite.clinician.external_service_fields,
    events: inviteEvents(currentInvite),

    externalServiceComponent: ExternalService,
    actionComponent: Actions,
  };
}

const dispatch = {
  fetchData: (inviteId) =>
    callAPIAction(fetchInviteAPI, fetchInvite(inviteId), didFetchInvite),
  fetchCareTeam: (peerId) => requestCareTeam(peerId),
  fetchOwner: (peerId) => requestOwner(peerId),
};

export default connect(mapStateToProps, dispatch)(UserView);
