import React from "react";
import PropTypes from "prop-types";

import "./CSVDownload.css";

function CSVDownload({ downloadCSV }) {
  return (
    <button onClick={downloadCSV} className="csv-download-button">
      Download CSV
    </button>
  );
}

CSVDownload.propTypes = {
  downloadCSV: PropTypes.func.isRequired,
  chartData: PropTypes.array.isRequired,
  xAxisFormat: PropTypes.func.isRequired,
  xDescription: PropTypes.string.isRequired,
  fileName: PropTypes.string,
};

CSVDownload.defaultProps = {
  fileName: "",
};

export default CSVDownload;
