import React from "react";
import PropTypes from "prop-types";

import Notifications from "react-notification-system-redux";

function NotificationCenter({ notifications }) {
  const styleOptions = {
    NotificationItem: {
      DefaultStyle: {
        margin: "10px 5px 2px 1px",
      },
    },
  };

  return <Notifications notifications={notifications} style={styleOptions} />;
}

NotificationCenter.propTypes = {
  notifications: PropTypes.array,
};

NotificationCenter.defaultProps = {
  notifications: [],
};

export default NotificationCenter;
