import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { required, numericality } from "redux-form-validators";
import moment from "moment";

import FormGroupField from "./../../shared/components/FormFields/FormGroupField";
import SelectField from "./../../shared/components/FormFields/SelectField";
import DateInputField from "./../../shared/components/FormFields/DateInputField";
import CheckboxField from "./../../shared/components/FormFields/CheckboxField";
import PlaceholderFormField from "./../../shared/components/FormFields/PlaceholderFormField";
import DrillDownSelectField from "../../shared/components/FormFields/DrillDownSelectField";
import CustomerForm from "./CustomerForm";

import guys from "./../../assets/patient_banner_guys.svg";
import android from "./../../assets/android.png";
import desktop from "./../../assets/desktop.png";
import appStore from "./../../assets/app_store.svg";
import sms from "./../../assets/sms.svg";

import "./InvitePortal.css";

/* eslint-disable no-mixed-operators */

// Todo: Split customer selector and invite part into two components
// Then based on url eg /invite-portal/[clinicain_id or 'automated'] you render the invite form itself
// If the url doesn't have clinician_id prop, then render customer selection form
class InvitePortal extends React.Component {
  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps) {
    const { clinicianId, clearProgram } = this.props;
    if (clinicianId !== prevProps.clinicianId) {
      // Triggered by url change
      this.refresh();
    }

    if (this.props.surgeryDate !== prevProps.surgeryDate) {
      clearProgram();
    }
  }

  // Could be done nicer if we had two components
  refresh() {
    const {
      clinicianId,
      requestInvitePortal,
      requestInvitePortalStaff,
      authenticatedUserType,
    } = this.props;
    if (authenticatedUserType === "admin") {
      if (clinicianId && clinicianId !== "demo") {
        // Just a workaround since there needs to be something in the url
        requestInvitePortal(clinicianId);
      } else if (clinicianId === "demo") {
        requestInvitePortal(null, true);
      }
    } else {
      requestInvitePortalStaff();
    }
  }

  renderRightText() {
    return (
      <div className="col-lg-7 col-md-7 col-sm-6 col-xs-12">
        <div className="bannerbox">
          <img
            src={guys}
            title="bardavon recovery+ users out enjoying their life"
            alt="bardavon recovery+ users recover from their joint replacements faster and safer"
          />
        </div>
        <div className="blurb">
          <h2>Why should patients use Bardavon Recovery+?</h2>
          <h5>
            Bardavon Recovery+ gives patients a daily checklist of activities to
            complete --all proven to mentally and physically prep patients for
            surgery, improving recovery times and reducing risk.
          </h5>
        </div>
      </div>
    );
  }

  renderAvailableIcons() {
    return (
      <div className="icons">
        <h4>Available on:</h4>
        <div className="row-apps">
          <div>
            <img src={appStore} alt="" />
            <p>iPhone</p>
          </div>
          <div>
            <img src={android} alt="" />
            <p>Android</p>
          </div>
        </div>
        <div className="row-apps add-top-margin">
          <div>
            <img src={sms} alt="" />
            <p>SMS</p>
          </div>
          <div>
            <img src={desktop} alt="" />
            <p>Desktop</p>
          </div>
        </div>
      </div>
    );
  }

  renderInviteForm() {
    const {
      invite,
      onSubmit,
      programData,
      handleSubmit,
      surgeryDate,
      demo,
      clinicianId,
      staff,
      selectedProgramTypes,
      clearSurgeryDate,
    } = this.props;

    const rehabPrograms =
      invite &&
      invite.programs[0] &&
      Object.keys(invite.programs[0].programs_by_body_part.rehab);
    const prehabPrograms =
      invite &&
      invite.programs[0] &&
      Object.keys(invite.programs[0].programs_by_body_part.prehab);
    const noPrograms = !rehabPrograms && !prehabPrograms;
    const minDate =
      (!(rehabPrograms && rehabPrograms.length) && moment()) || moment(null);
    const maxDate =
      (!(prehabPrograms && prehabPrograms.length) &&
        moment().subtract(1, "days")) ||
      moment(null);

    return (
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="peer_fullname"
          component={PlaceholderFormField}
          label="Patient Full Name"
          placeholder="First Last"
          required
        />
        <Field
          name="mobile_number"
          component={PlaceholderFormField}
          label="Cell Phone Number"
          type="tel"
          formatter={(v) => {
            const numbersOnly = v.replace(/\D/g, "");
            return numbersOnly.substr(0, 10);
          }}
        />
        <Field
          name="email_address"
          component={PlaceholderFormField}
          label="Email Address"
          placeholder="johnsmith@gmail.com"
          required
        />
        <Field
          name="surgery_date"
          component={DateInputField}
          minDate={minDate}
          maxDate={maxDate}
          subLabel="Required, except for PreHab, if the surgery date is not determined"
          label="Surgery Date"
        />

        <input
          type="button"
          value="No surgery date"
          disabled={!surgeryDate}
          onClick={clearSurgeryDate}
        />

        {this.programCheckboxes(selectedProgramTypes)}

        <Field
          name="program"
          component={DrillDownSelectField}
          values={programData}
          label="Program"
          initialLabel="Select a program"
          required
        />

        {invite &&
          invite.programs[0].extra_fields &&
          invite.programs[0].extra_fields.map((field) => {
            const typeToFieldMapping = {
              select: SelectField,
              text: FormGroupField,
              boolean: CheckboxField,
            };

            const FieldForType = typeToFieldMapping[field.type];
            if (FieldForType) {
              return (
                <Field
                  key={field.label + field.name}
                  name={`external_service.${field.name}`}
                  component={FieldForType}
                  label={field.label}
                  validate={[required()]}
                  required
                  values={field.values}
                />
              );
            }

            console.warn(
              `There is no field type specified for intake question of type ${field.type}`
            );
            return null;
          })}

        {demo && (
          <Field
            name="demo.seed"
            component={SelectField}
            values={invite.demo.seed}
            label="Seed"
          />
        )}
        {demo && (
          <Field
            name="demo.day_plans"
            component={PlaceholderFormField}
            type="number"
            placeholder="0"
            label="Number of day plans"
            validate={[numericality({ "<": 301, ">=": 0 })]}
            required
          />
        )}
        {clinicianId && staff && staff.length > 0 && (
          <Field
            name="staff_id"
            component={SelectField}
            values={staff}
            label="Staff"
          />
        )}
        <div className="confirm">
          <Field
            name="consent"
            component={CheckboxField}
            label="Patient agrees to getting SMS messages from Bardavon Recovery+ and acknowledges they can stop at anytime."
            required
          />
        </div>
        {noPrograms && <h4>There are no programs known for this clinician</h4>}
        <input
          type="submit"
          className="btn btn-primary"
          disabled={noPrograms}
          value="Add new patient!"
        />
      </form>
    );
  }

  render() {
    const { clinicianId, invite } = this.props;

    return (
      <div className="invite-portal-container">
        <div className="filler-background">
          <div className="content">
            <div className="jumbotron col-lg-5 col-md-5 col-sm-6 col-xs-12">
              {!clinicianId && <CustomerForm />}
              {clinicianId && invite && this.renderInviteForm()}
              {this.renderAvailableIcons()}
            </div>
            {this.renderRightText()}
          </div>
        </div>
      </div>
    );
  }

  programCheckboxes(programTypes) {
    const boxes = [
      { label: "Prehab", value: "prehab" },
      { label: "Rehab", value: "rehab" },
      { label: "Conservative", value: "conservative_care" },
    ];

    return (
      <div className="program_checkbox">
        <div className="flex-row">
          {boxes.map((box) => (
            <div className="flex-row" key={box.value}>
              <input
                type="checkbox"
                disabled
                checked={programTypes.indexOf(box.value) !== -1}
                className="form-control"
                placeholder={box.label}
              />
              <label>{box.label}</label>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

InvitePortal.propTypes = {
  requestInvitePortalStaff: PropTypes.func.isRequired,
  requestInvitePortal: PropTypes.func.isRequired,
  authenticatedUserType: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  demo: PropTypes.bool,
  change: PropTypes.func,
  surgeryDate: PropTypes.number,
  handleSubmit: PropTypes.func,
  invite: PropTypes.object,
  clinicianId: PropTypes.string,
  programData: PropTypes.array,
  staff: PropTypes.array,
  selectedProgramTypes: PropTypes.array,
};

InvitePortal.defaultProps = {
  invite: null,
};

export default reduxForm({
  form: "invitePortal",
})(InvitePortal);
