import { handleActions } from "redux-actions";
import { get, sortBy } from "lodash";

import { query } from "../../../shared/routes/query";
import {
  didFetchCustomers,
  didFetchPrograms,
  didFetchClinician,
  clearClinicianData,
} from "./actions";

export function allCustomers(state) {
  const customersList = get(state, "ui.ManageClinician.customers");
  // eslint-disable-next-line no-return-assign
  if (customersList)
    customersList.forEach(
      (customer) => (customer.clinicians = sortBy(customer.clinicians, "name"))
    );
  return customersList && sortBy(customersList, "customer.name");
}

export function selectedCustomer(state) {
  const selectedId = query(state, "customerId");
  const customers = state.ui.ManageClinician.customers;

  if (!customers || !selectedId) return null;

  return customers.find(({ customer }) => customer.id === selectedId);
}

export function allAggregatePrograms(state) {
  const programs = get(state, "ui.ManageClinician.programs");

  if (!programs) return null;

  // we get back from the api some programs we don't want to expose in this interface since they shouldnt be used in production
  const excludedProgramsById = [
    "dev-hip-or-knee-replacement",
    "hip-or-knee-replacement-de",
    "sales-demo",
    "sales",
    "pain",
  ];
  const validPrograms = programs.filter(
    ({ id }) => !excludedProgramsById.includes(id)
  );
  return validPrograms.map(({ id, name }) => ({ value: id, label: name }));
}

export function programList(state) {
  return get(state, "ui.ManageClinician.programs");
}

export function selectedClinician(state) {
  return get(state, "ui.ManageClinician.clinician");
}

export function clinicianInitialValues(state) {
  const clinician = selectedClinician(state);
  const allPrograms = programList(state);

  if (!clinician || !allPrograms) return null;

  const initialProgramValues = clinician.programs
    .filter((program) => !program.archived)
    .map((program) => {
      const label = allPrograms.find(
        (p) => p.id === program.aggregate_program
      ).name;

      return {
        value: program.aggregate_program,
        label,
        program,
      };
    });

  const initialArchivedProgramValues = clinician.programs
    .filter((program) => !!program.archived)
    .map((program) => {
      const label = allPrograms.find(
        (p) => p.id === program.aggregate_program
      ).name;

      return {
        value: program.aggregate_program,
        label,
        program,
      };
    });
  return {
    name: clinician.name,
    npi: clinician.npi,
    emails: clinician.emails,
    programs: initialProgramValues,
    archivedPrograms: initialArchivedProgramValues,
  };
}

export const ManageClinicianReducer = handleActions(
  {
    [didFetchCustomers]: (state, action) => ({
      ...state,
      customers: action.payload,
    }),
    [didFetchPrograms]: (state, action) => ({
      ...state,
      programs: action.payload,
    }),
    [didFetchClinician]: (state, action) => ({
      ...state,
      clinician: action.payload,
    }),
    [clearClinicianData]: (state) => ({ ...state, clinician: null }),
  },
  {
    customers: null,
    programs: null,
    clinician: null,
  }
);
