import { connect } from "react-redux";
import CustomerForm from "./CustomerForm.jsx";
import { clinicianFormData } from "./reducer";
import { requestCustomers, selectClinician } from "./actions";
import { isDemoInvite } from "../selectors";
import { push } from "redux-little-router";

function mapStateToProps(state) {
  return {
    clinicianData: clinicianFormData(state),
    initialValues: {
      asDemo: isDemoInvite(state),
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestCustomers: () => dispatch(requestCustomers()),
    selectClinician: ({ clinician, asDemo }) =>
      dispatch(selectClinician(clinician, asDemo)),
    demoInviteWithoutClinician: () =>
      dispatch(push("/invite-portal/demo?demo=true")),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm);
