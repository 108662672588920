import { connect } from "react-redux";

import UpdatePassword from "./UpdatePassword.jsx";

import { formMeta } from "../../../../../utils/form";
import { requestPasswordReset } from "./actions";
import { changePasswordToken } from "./reducers";

function mapStateToProps(state, ownProps) {
  const { requirePasswordChange } = ownProps;

  const didUpdate = formMeta(state, "resetPassword").updated;
  return {
    disabled: didUpdate,
    showLoginMessage: didUpdate,
    initialValues: {
      token: !requirePasswordChange && changePasswordToken(state),
    },
  };
}

const dispatch = {
  onSubmit: ({ password, token }) => requestPasswordReset(password, token),
};

export default connect(mapStateToProps, dispatch)(UpdatePassword);
