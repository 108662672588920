import { handleActions } from "redux-actions";
import { query } from "../../../../../../shared/routes/query";

import { didFetchPinnedCards, didFetchProgramCards } from "./actions";

export const selectedProgramId = (state) => query(state, "edit");
export const selectedProgramType = (state) =>
  state.ui.Programs.programs &&
  state.ui.Programs.programs[selectedProgramId(state)] &&
  state.ui.Programs.programs[selectedProgramId(state)].type;
export const selectedProgramCards = (state) =>
  (state.ui.Programs.programs &&
    state.ui.Programs.programs[selectedProgramId(state)] &&
    state.ui.Programs.programs[selectedProgramId(state)].programCards) ||
  [];
export const selectedPinnedCards = (state) =>
  (state.ui.Programs.programs &&
    state.ui.Programs.programs[selectedProgramId(state)] &&
    state.ui.Programs.programs[selectedProgramId(state)].pinnedCards) ||
  [];

export default handleActions(
  {
    [didFetchPinnedCards]: (state, action) => {
      const programId = action.payload.selectedId;
      const pinnedCardList = action.payload.cardsList;
      const programs = { ...state.programs };
      const currentProgram = programs[programId] || {};
      currentProgram.pinnedCards = [...pinnedCardList];
      programs[programId] = Object.assign({}, currentProgram);
      return { ...state, programs };
    },
    [didFetchProgramCards]: (state, action) => {
      const programId = action.payload.selectedId;
      const programCardList = action.payload.cardsList;
      const programs = { ...state.programs };
      const currentProgram = programs[programId] || {};
      currentProgram.programCards = [...programCardList];
      programs[programId] = Object.assign({}, currentProgram);
      return { ...state, programs };
    },
  },
  {
    pending: null,
    programs: {},
  }
);
