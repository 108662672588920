import { createAction } from "redux-actions";

const prefixed = (val) => `Peer/Actions/UpdateProcedureType::${val}`;

export const requestProcedureTypes = createAction(
  prefixed("REQUEST_PROCEDURE_TYPES"),
  (peerId) => peerId
);
export const didGetProcedureTypes = createAction(
  prefixed("DID_GET_PROCEDURE_TYPES"),
  (peerId, procedureTypes) => ({ peerId, procedureTypes })
);

export const updateProcedureType = createAction(
  prefixed("UPDATE_PROCEDURE_TYPE"),
  (peerId, procedureType) => ({ peerId, procedureType })
);
export const didUpdateProcedureType = createAction(
  prefixed("DID_UPDATE_PROCEDURE_TYPE"),
  (peerId, procedureType) => ({ peerId, procedureType })
);
export const setUpdateProcedureTypeMessage = createAction(
  prefixed("SET_UPDATE_PROCEDURE_TYPE_MESSAGE"),
  (message) => message
);
