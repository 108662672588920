import { connect } from "react-redux";

import Programs from "./Programs.jsx";
import { query, updateQuery } from "../../shared/routes/query";
import { selectedProgram, selectedProgramId, newProgram } from "./reducer";
import { fetchProgram, fetchProgramsList } from "./actions";
import { showDeleted, programsCount } from "./components/ProgramsList/reducer";

function mapStateToProps(state) {
  const programId = selectedProgramId(state);

  return {
    showCreate: !!query(state, "create"),
    showEdit: !!query(state, "edit"),
    showDeleted: showDeleted(state),
    count: programsCount(state),
    selectedProgramId: programId,
    selectedProgram: selectedProgram(state),
    newProgram: newProgram(state),
    filter: query(state, "filter"),
    page: query(state, "page"),
  };
}

const dispatch = {
  fetchProgram: (programId) => fetchProgram(programId),
  fetchProgramsList: (deleted, filter, page) =>
    fetchProgramsList(deleted, filter, page),
  resetPage: () => updateQuery({ page: 0 }),
};

export default connect(mapStateToProps, dispatch)(Programs);
