import { set } from "lodash";

// Converts something like { "foo.bar": "baz" } to  { foo: { bar: "baz" } }
export function expandObject(obj) {
  const expanded = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      set(expanded, key, value);
    }
  }

  return expanded;
}
