import reduceReducers from "reduce-reducers";
import { handleActions } from "redux-actions";
import { get } from "lodash";

import { didFetchCard, didFetchCardsList } from "./actions";
import { query } from "../../shared/routes/query";
import cardsListReducer from "./components/CardsList/reducer";

// Todo: Get actual card
export const card = (state, id) => get(state.ui.Cards, `cards.${id}`);
export const selectedCardId = (state) => query(state, "edit");
export const selectedPreviewId = (state) => query(state, "preview");
export const selectedCard = (state) => card(state, selectedCardId(state));
export const selectedPreview = (state) => card(state, selectedPreviewId(state));

export function cardContent(state) {
  const currentCard = selectedCard(state) || selectedPreview(state);
  if (!currentCard) {
    return null;
  }

  return currentCard.content;
}

const cardsReducer = reduceReducers(
  cardsListReducer,
  handleActions(
    {
      [didFetchCard]: (state, action) => {
        const cards = state.cards || {};
        const payloadCard = action.payload;
        cards[payloadCard.id] = payloadCard;
        return { ...state, cards };
      },
      [didFetchCardsList]: (state, action) => ({
        ...state,
        list: action.payload.cardsList,
        count: action.payload.count,
      }),
    },
    {
      pending: null,
      cards: {},
      list: [],
    }
  )
);

export { cardsReducer };
