import React from "react";

import "./UserView.css";
import "./SplitPane.css";
import UserView from "./UserView.jsx";
import WorkerView from "./WorkerView.jsx";

import { WORKER_ROUTE_NAME } from "../../Peer/View/index";

// Renders correct details view
// based on whether this is a Bardavon Recovery user (patient) or Worker
class DetailsView extends React.Component {
  render() {
    const isWorker = window.location.href.includes(WORKER_ROUTE_NAME);

    if (isWorker) {
      return <WorkerView {...this.props} />;
    } else {
      return <UserView {...this.props} />;
    }
  }
}

export default DetailsView;
