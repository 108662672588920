import { Form, Formik } from "formik";
import React, { useEffect } from "react";
const useState = React.useState;
import * as Yup from "yup";
import FormikMdField from "../../../../shared/components/FormFields/FormikMdField/FormikMdField";
import FormikSelectField from "../../../../shared/components/FormFields/FormikSelectField";
import FormikSortableSelectField from "../../../../shared/components/FormFields/FormikSortableSelectField";
import FormikTextField from "../../../../shared/components/FormFields/FormikTextField";
import BottomBar from "../BottomBar";

const ModulesForm = ({
  fetchAllCards,
  fetchPillars,
  initialValues,
  module,
  onSubmit,
}) => {
  const [cards, setCards] = useState([]);
  const [pillars, setPillars] = useState([]);

  useEffect(async () => {
    setCards((await fetchAllCards()).data);
    setPillars((await fetchPillars()).data);
  }, []);

  const cardOptions = cards.map((card) => ({
    value: card.id,
    label: card.name,
    key: card.id,
  }));

  const pillarOptions = pillars?.map((pillar) => ({
    value: pillar.code,
    label: pillar.name,
  }));

  const typeOptions = [
    { label: "Normal", value: "normal" },
    { label: "PT", value: "pt" },
    { label: "Pre-surgery", value: "presurgery" },
  ];

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
      validationSchema={validation}
    >
      {() => (
        <div className="module-edit" data-test="module-edit">
          <h2>Edit Module</h2>
          <Form>
            <FormikTextField label="Name" name="name" required />
            <FormikSortableSelectField
              label="Cards"
              name="cards"
              options={cardOptions}
            />
            <FormikSelectField
              label="Type"
              name="type"
              options={typeOptions}
              required
            />
            <FormikSelectField
              label="Pillar"
              name="pillar"
              options={pillarOptions}
              required
            />
            <FormikMdField label="Notes" name="notes" />
            <FormikMdField label="Description" name="description" required />
            <BottomBar deleted={module.deleted} moduleId={module.id} />
          </Form>
        </div>
      )}
    </Formik>
  );
};

const validation = Yup.object({
  name: Yup.string().required("Required"),
  pillar: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
});

export default ModulesForm;
