import { get, set } from "lodash";
import { handleActions } from "redux-actions";
import { didFetchAvailablePrograms } from "./actions";

// XXX duplicated in ChangePartner, extract to a common place for reuse?
export const getPeerAvailableParts = (state, peerId) => {
  return get(state, `ui.PeerView.${peerId}.available_parts`);
};

const isGhlId = (id) => id.indexOf("ghl-") === 0;

// XXX duplicated in ChangePartner, extract to a common place for reuse?
export default handleActions(
  {
    [didFetchAvailablePrograms]: (state, action) => {
      const newState = { ...state };
      const peerId = action.payload.peerId;
      const clinicians = action.payload.clinicians;
      const programs = action.payload.programs;

      const currentProgram = newState[peerId].info.aggregate_program;

      // const filteredPrograms = oldStylePrograms(programs, clinicians)
      const filteredPrograms = isGhlId(currentProgram)
        ? ghlPrograms(programs)
        : oldStylePrograms(programs, clinicians);

      // Not sure why this is this way....
      set(newState, `${peerId}.available_parts`, filteredPrograms);
      return newState;
    },
  },
  {}
);

function oldStylePrograms(programs, clinicians) {
  const clinician_programs = clinicians.flatMap((c) =>
    c.programs.map((p) => p.aggregate_program)
  );

  const partsWithLabel = mapPrograms(programs);

  return partsWithLabel.filter(
    (pa) => clinician_programs.indexOf(pa.value.program) !== -1
  );
}

function ghlPrograms(programs) {
  const partsWithLabel = mapPrograms(programs);
  return partsWithLabel.filter((pa) => isGhlId(pa.value.program));
}

function mapPrograms(programs) {
  return programs.flatMap((p) =>
    p.parts.map((pa) => ({
      label: programLabel(pa, p),
      value: {
        program: p.id,
        part: pa.id,
      },
    }))
  );
}

function programLabel(part) {
  return part.version === "2"
    ? `${part.name} - ${part.aggregate_program_name}`
    : part.name;
}
