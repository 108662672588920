import { connect } from "react-redux";

import PhasesForm from "./PhasesForm.jsx";
import { submitFormToAPI } from "../../../../shared/utils/form";

import { didCreatePhase, didUpdatePhase } from "./actions";
import {
  createPhase as createPhaseAPI,
  updatePhase as updatePhaseAPI,
  fetchAllModules,
} from "./api";
import { updateQuery } from "../../../../shared/routes/query";

const FORM_ID = "phase";

function mapStateToProps(state, { phase }) {
  return {
    initialValues: phase,
    fetchAllModules,
  };
}

function mapDispatchToProps(dispatch, { phase }) {
  return {
    onSubmit: (values) => {
      if (values.modules) {
        values.modules = values.modules.map((module) => {
          if (module.value) {
            return module.value;
          }
          return module;
        });
      } else {
        values.modules = [];
      }
      if (phase.id) {
        dispatch(
          submitFormToAPI(
            FORM_ID,
            updatePhaseAPI,
            didUpdatePhase,
            phase.id,
            values
          )
        );
      } else {
        dispatch(
          submitFormToAPI(FORM_ID, createPhaseAPI, didCreatePhase, values)
        );
      }
      dispatch(updateQuery({ create: undefined, edit: undefined }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PhasesForm);
