import { handleActions } from "redux-actions";
import { find, get } from "lodash";

import {
  didFetchCustomers,
  didFetchBookOfBusiness,
  didUpdateEndDate,
  didUpdateStartDate,
  didTriggerBookOfBusiness,
  didResetReport,
} from "./actions";

export function customers(state) {
  return state.ui.BookOfBusiness.customers;
}

export function startDate(state) {
  return state.ui.BookOfBusiness.startDate;
}

export function endDate(state) {
  return state.ui.BookOfBusiness.endDate;
}

// Defaults to first customer if none is selected
export function selectedCustomer(state) {
  const currentCustomers = customers(state);
  if (currentCustomers.length === 0) {
    return null;
  }
  const customerId = state.router.params.customerId;
  return (
    find(currentCustomers, (c) => c.id === customerId) || currentCustomers[0]
  );
}

export function reportForCustomer(state, customerId) {
  return get(state, `ui.BookOfBusiness.reports.${customerId}`);
}

export default handleActions(
  {
    [didFetchCustomers]: (state, action) => ({
      ...state,
      customers: action.payload,
    }),
    [didUpdateStartDate]: (state, action) => ({
      ...state,
      startDate: action.payload,
    }),
    [didUpdateEndDate]: (state, action) => ({
      ...state,
      endDate: action.payload,
    }),
    [didResetReport]: (state, action) => {
      const customerId = action.payload;
      const reports = state.reports;
      reports[customerId] = null;
      return { ...state, reports };
    },
    [didTriggerBookOfBusiness]: (state, action) => {
      const payload = action.payload;
      const customerId = payload.customerId;
      const reports = state.reports;
      reports[customerId] = payload.data;
      return { ...state, reports };
    },
    [didFetchBookOfBusiness]: (state, action) => {
      const payload = action.payload;
      const customerId = payload.customerId;
      const reports = state.reports;
      reports[customerId] = payload.data;
      return { ...state, reports };
    },
  },
  {
    customers: [],
    reports: {},
  }
);
