import { connect } from "react-redux";

import CardsList from "./CardsList.jsx";

import { updateQuery } from "../../../../shared/routes/query";
import {
  cardsList,
  filterCardByAssessment,
  isDownloading,
  showDeleted,
  v1CardDownloadData,
  v2CardDownloadData,
} from "./reducer";
import {
  onFilterByAssessmentsChange,
  onShowDeletedChange,
  onDownloadCards,
  onCardsDownloaded,
  onUploadsCards,
} from "./actions";

function mapStateToProps(state) {
  return {
    cards: cardsList(state),
    isDownloading: isDownloading(state),
    showDeleted: showDeleted(state),
    filterCardByAssessments: filterCardByAssessment(state),
    v1CardDownloadData: v1CardDownloadData(state),
    v2CardDownloadData: v2CardDownloadData(state),
  };
}

const dispatch = {
  updateFilter: (filter) => updateQuery({ filter }),
  onAddCardClicked: () => updateQuery({ create: true, preview: undefined }),
  onShowDeletedChange: (deleted) => onShowDeletedChange(deleted),
  onFilterByAssessmentsChange: (filter) => onFilterByAssessmentsChange(filter),
  onDownloadCardsClicked: () => onDownloadCards(),
  onCardsDownloaded: () => onCardsDownloaded(),
  onUploadCardsClicked: () => onUploadsCards(),
};

export default connect(mapStateToProps, dispatch)(CardsList);
