import React from "react";
import PropTypes from "prop-types";

import CardsList from "./components/CardsList";
import Preview from "./components/Preview";
import Pagination from "../../shared/components/Pagination";

import "./Cards.css";
import CardDetail from "./components/CardDetail/CardDetail";

class Cards extends React.Component {
  componentDidMount() {
    this._fetchCard(this.props);
    this.props.fetchCardsList(
      this.props.showDeleted,
      this.props.filterCardByName,
      this.props.filterCardByAssessment,
      0
    );
  }

  // XXX Legacy hook, react recommends not using this anymore. Will stop working after react 17
  // ref https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
  componentWillReceiveProps(nextProps) {
    const {
      selectedCardId,
      fetchCardsList,
      filterCardByName,
      showDeleted,
      page,
      resetPage,
      selectedPreviewId,
      filterCardByAssessment,
    } = this.props;
    if (
      (nextProps.selectedCardId &&
        selectedCardId !== nextProps.selectedCardId) ||
      (nextProps.selectedPreviewId &&
        selectedPreviewId !== nextProps.selectedPreviewId)
    ) {
      this._fetchCard(nextProps);
    } else if (
      showDeleted !== nextProps.showDeleted ||
      filterCardByName !== nextProps.filterCardByName ||
      filterCardByAssessment !== nextProps.filterCardByAssessment
    ) {
      fetchCardsList(
        nextProps.showDeleted,
        nextProps.filterCardByName,
        nextProps.filterCardByAssessment,
        0
      );
      resetPage();
    } else if (page !== nextProps.page) {
      fetchCardsList(
        showDeleted,
        filterCardByName,
        filterCardByAssessment,
        nextProps.page
      );
    }
  }

  _fetchCard(props) {
    const { selectedCardId, selectedPreviewId, fetchCard } = props;
    if (selectedCardId) {
      fetchCard(selectedCardId);
    } else if (selectedPreviewId) {
      fetchCard(selectedPreviewId);
    }
  }

  renderList() {
    const { selectedPreview, selectedCardPreviewContent, showDeleted } =
      this.props;
    return (
      <div className="card-list-content">
        <div className="card-list-col">
          <CardsList showDeleted={showDeleted} />
          {this.renderPagination()}
        </div>
        {selectedPreview && (
          <div className="preview-col">
            <Preview content={selectedCardPreviewContent} />
          </div>
        )}
      </div>
    );
  }

  renderEdit() {
    const { selectedCardId } = this.props;
    return (
      <CardDetail
        cardId={selectedCardId}
        onSave={this.props.navigateToCardsList}
      />
    );
  }

  renderPagination() {
    const { page, count } = this.props;

    if (count <= 20) {
      return null;
    }

    return (
      <Pagination
        itemCount={count}
        itemsPerPage={20}
        currentPage={page}
        baseUrl={"/cards"}
        pageInQuery
      />
    );
  }

  render() {
    const { showEdit } = this.props;
    return (
      <div className="cards">
        {showEdit ? this.renderEdit() : this.renderList()}
      </div>
    );
  }
}

const cardShape = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

Cards.propTypes = {
  selectedCard: PropTypes.shape(cardShape),
  selectedPreview: PropTypes.shape(cardShape),
  showEdit: PropTypes.bool,
  showDeleted: PropTypes.bool,
  filterCardByAssessment: PropTypes.bool,
  filterCardByName: PropTypes.string,
  updateFilter: PropTypes.func.isRequired,
  selectedCardId: PropTypes.string,
  selectedPreviewId: PropTypes.string,
  fetchCard: PropTypes.func.isRequired,
  downloadCardList: PropTypes.func.isRequired,
  fetchCardsList: PropTypes.func.isRequired,
  selectedCardPreviewContent: PropTypes.string,
  page: PropTypes.number,
  count: PropTypes.number,
  resetPage: PropTypes.func.isRequired,
};

Cards.defaultProps = {
  selectedCard: null,
  selectedPreview: null,
  showEdit: false,
  showDeleted: false,
  filterCardByName: "",
  filterCardByAssessment: false,
  selectedCardId: null,
  selectedPreviewId: null,
  selectedCardPreviewContent: null,
  page: 0,
  count: 0,
};

export default Cards;
