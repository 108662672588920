import { values } from "lodash";

// Colors are named, so it is possibly to give specific bars / lines in the chart colors, and when not set, it just picks the next available from this list (so order affects the looks)
// Todo: Setup named, for now it only works as ordered
export const namedColors = {
  orange: "#FFB931",
  blue: "#49C2C2",
  red: "#CC4F61",
  olive: "#CCED10",
  1: "#FFB931",
  2: "#49C2C2",
  3: "#CC4F61",
  4: "#59660E",
  5: "#00F577",
  6: "#ec6717",
};

export const orderedDashes = [
  "1,0",
  "5,5", // Length of stroke, length of gap
  "5,10",
  "10,5",
  "10, 10",
];

export const orderedColors = values(namedColors);
