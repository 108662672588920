export const asOptionalBool = (v, defaultValue = null) => {
  if (typeof v === "boolean") {
    return v;
  }
  if (typeof v === "number") {
    return v === 1;
  }
  if (typeof v === "string") {
    return v === "true";
  }
  return defaultValue;
};

export const asBool = (v) => !!asOptionalBool(v);

// Helper function to make sure we only limit to a certain set of values
// value must be one of anyValues, else defaultValue returned
export const either = (value, anyValues, defaultValue = null) =>
  anyValues.indexOf(value) === -1 ? defaultValue : value;

export const withDefaults = (arr, defaults) =>
  arr.map((i) => Object.assign({}, defaults, i));

export const arrayMove = (array, fromIndex, toIndex) => {
  const newArray = [...array];
  const startIndex = fromIndex < 0 ? newArray.length + fromIndex : fromIndex;
  if (startIndex >= 0 && startIndex < newArray.length) {
    const endIndex = toIndex < 0 ? newArray.length + toIndex : toIndex;
    const [item] = newArray.splice(fromIndex, 1);
    newArray.splice(endIndex, 0, item);
  }
  return newArray;
};

export const getItemsByIds = (keyArray, options) => {
  if (keyArray?.length === 0 || options?.length === 0) return [];
  return keyArray.map((key) => options.find((opt) => opt.value === key));
};
