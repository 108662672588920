import React from "react";
import PropTypes from "prop-types";

import Chart from "./../Chart";

import "./Locked.css";

// Showing some fake metrics blurred
const fakeMetricStats = {
  best: {
    value: 15,
    formatted: "15°",
  },
  range: {
    y: {
      start: {
        value: 0,
        formatted: 0,
      },
      end: {
        value: 10,
        formatted: 10,
      },
    },
    x: {
      start: {
        value: 0,
        formatted: 0,
      },
      end: {
        value: 8,
        formatted: 8,
      },
    },
    x_ticks: [
      { relative_value: 0, formatted: "Day 0" },
      { relative_value: 1, formatted: "Day 90" },
    ],
  },
  metrics: [
    { value: 3, timestamp: 0, formatted: 3 },
    { value: 5, timestamp: 1, formatted: 5 },
    { value: 4, timestamp: 2, formatted: 4 },
    { value: 5, timestamp: 3, formatted: 5 },
    { value: 6, timestamp: 4, formatted: 6 },
  ],
};

class Locked extends React.Component {
  renderUnlockMessage(metric) {
    const { isDashboard } = this.props;
    if (isDashboard) {
      return `No data available for ${metric.info.title}`;
    }
    return metric.unlock.label;
  }

  render() {
    const { metric, isDashboard } = this.props;
    const withFakeStats = Object.assign({}, metric, fakeMetricStats);

    return (
      <div className="profile-section profile-locked">
        <p className="locked-message">{this.renderUnlockMessage(metric)}</p>

        <div className="blur-container">
          <div className="blur-darker">
            <div className="chart-container">
              <Chart metric={withFakeStats} showActionButton={!isDashboard} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Locked.propTypes = {
  metric: PropTypes.object.isRequired,
  isDashboard: PropTypes.bool.isRequired,
};

export default Locked;
