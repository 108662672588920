import { connect } from "react-redux";
import UpdatePhone from "./UpdatePhone.jsx";

import { fleetingFormMeta } from "../../../../../../../shared/utils/form";

function mapStateToProps(state) {
  return {
    message: fleetingFormMeta(state, "updatePhone").message,
  };
}

export default connect(mapStateToProps)(UpdatePhone);
