import React from "react";
import PropTypes from "prop-types";

/**
 * Component that alerts if you click outside of it
 */
export default class ClickOutsideListener extends React.Component {
  constructor(props) {
    super(props);

    this.wrapperRef = null;
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this._handleClickOutside = this._handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this._handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this._handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  _handleClickOutside(event) {
    const { onClickOutside } = this.props;

    // for react-selects inside modals, so that the modal does not close when you select an option
    if (
      event.target.className.indexOf &&
      event.target.className.indexOf("Select") > -1
    )
      return;

    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (typeof onClickOutside === "function") {
        onClickOutside();
      } else {
        throw new Error(
          "onClickOutside() is a required prop of type 'function' in OutsideAlerter.jsx"
        );
      }
    }
  }

  render() {
    return (
      <div className="click-outside-listener" ref={this.setWrapperRef}>
        {this.props.children}
      </div>
    );
  }
}

ClickOutsideListener.propTypes = {
  children: PropTypes.element.isRequired,
  // if this is used by a modal then this is automatically set, but you can override the default modal behavior with this
  // or if you're not wrapping a modal then you must set this yourself
  onClickOutside: PropTypes.func.isRequired,
};
