import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../../../../../shared/app/requestErrorHandler";
import { setFleetingMessage } from "../../../../../../../../shared/utils/form";
import { didFetchPeerProgramParts } from "../../../../sagas";
import {
  didFetchAvailablePrograms,
  didTransitionPeer,
  fetchAvailablePrograms,
  transitionPeer,
} from "./actions";
import {
  fetchCliniciansByCustomer as fetchCliniciansByCustomerAPI,
  fetchPrograms as fetchProgramsAPI,
  transitionPatient as transitionPatientAPI,
} from "./api";

// XXX duplicated in ChangePartner, extract to a common place for reuse?
function* fetchPrograms(action) {
  try {
    const clinicians = yield fetchCliniciansByCustomerAPI(
      action.payload.customerId
    );
    const programs = yield fetchProgramsAPI();
    yield put(
      didFetchAvailablePrograms({
        clinicians: clinicians.data,
        programs: programs.data,
        peerId: action.payload.userId,
      })
    );
  } catch (e) {
    yield handleApiError(e);
  }
}

function* transitionPeerFunction(action) {
  try {
    const response = yield transitionPatientAPI(
      action.payload.userId,
      action.payload.values.program
    );
    // yield put(didTransitionPeer(response.data));
    // yield put(
    //   didFetchPeerProgramParts({
    //     data: response.data,
    //     id: action.payload.userId,
    //   })
    //  );
    document.location.reload();
  } catch (e) {
    yield handleApiError(e);
  }
}

export default function* watchUpdateTransitionPeerSaga() {
  yield all([
    yield takeLatest(fetchAvailablePrograms, fetchPrograms),
    yield takeLatest(transitionPeer, transitionPeerFunction),
    yield takeLatest(
      didTransitionPeer,
      setFleetingMessage("transitionProgram", {
        message: "Peer has been moved to the new program.",
      })
    ),
  ]);
}
