import React from "react";
import PropTypes from "prop-types";
import {
  castArray,
  flatten,
  groupBy,
  sortBy,
  min,
  max,
  compact,
  find,
} from "lodash";
import classNames from "classnames";

import {
  Crosshair,
  YAxis,
  XAxis,
  VerticalGridLines,
  HorizontalGridLines,
  DiscreteColorLegend,
  Hint,
  FlexibleWidthXYPlot,
} from "react-vis";

import { date } from "./formatters";
import { orderedColors } from "../BaseChart/theme";

import { compactObject } from "../../../utils/compactObject";

import EmptyComponent from "./../../../components/EmptyComponent";

import "react-vis/dist/style.css";
import "./BaseChart.css";

import CSVDownload from "./components/CSVDownload";

const defaultMargin = { bottom: 50, top: 30, left: 75, right: 50 };

// Base component for charting, draws the XYPlot, so the chart it is live resizable
class BaseChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      hoveredValue: null,
      hoveredSeriesIndex: null,
    };

    this._formatHint = this._formatHint.bind(this);
    this._clearHint = this._clearHint.bind(this);
    this._onNearestXY = this._onNearestXY.bind(this);
  }

  componentDidMount() {
    this._setChartMetrics(this.props, this.context);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // Detects incoming metric data changes and converts it into a format we can graph
    if (nextProps.draw !== this.props.draw) {
      this._setChartMetrics(nextProps, nextContext);
    }
  }

  // Converts the raw metric data in a format the chart can be drawn from
  _reformatMetrics(draw, metricData) {
    draw = castArray(draw);

    let index = 0;

    const calculatedChartInfo = draw.map((settings) => {
      const metricDataForType = metricData[settings.metricType];

      // Todo: Improve this
      // We either want to draw things as a whole or individually (e.g per code)
      const groupedMetricData = this._groupMetricData(
        metricDataForType,
        settings.display
      );

      const allResults = [];
      for (const groupedBy in groupedMetricData) {
        const data = groupedMetricData[groupedBy];

        // The actual conversion
        const calculatedData = data ? settings.operation(data) : [];

        // Need to sort the data on the x axis, else the line charts make no sense
        const sortedCalculatedData = sortBy(
          calculatedData,
          (coordinate) => coordinate.x
        );

        // Pretty much forwarding all settings, except operation which is replaced with the actual data
        const result = Object.assign(
          {
            data: sortedCalculatedData,
            groupedBy,
            color: this.colorForIndex(index),
          },
          settings
        );

        delete result.operation;

        index += 1;

        allResults.push(result);
      }

      return allResults;
    });

    return flatten(calculatedChartInfo);
  }

  _setChartMetrics(props, context) {
    const data = this._reformatMetrics(props.draw, context.metricData);
    this.setState({ data });
  }

  _groupMetricData(metricData, display) {
    switch (display) {
      case "individually":
        return groupBy(
          metricData,
          (metric) => metric.grouped_by_id || metric.grouped_by
        );
      default:
        return { combined_selection: metricData };
    }
  }

  colorForIndex(index) {
    const colorIndex = index % orderedColors.length;
    return orderedColors[colorIndex];
  }

  renderPlot(config) {
    const legend = this._legend(config);
    const yAxis = this._yAxis();
    const xAxis = this._xAxis();
    const xGridLines = this._xGridLines();
    const yGridLines = this._yGridLines();
    const goals = this._goals();
    const labels = this._labels();
    const hint = this._getHint();

    const components = [
      legend,
      yAxis,
      xAxis,
      xGridLines,
      yGridLines,
      hint,
      labels,
      // Has to be last! Else I can't do last-child with CSS
      goals,
    ];

    return compact(components);
  }

  _goals() {
    const { goals, yDomain, xDomain } = this.props;
    const { data } = this.state;
    if (!goals) {
      return null;
    }

    const smallestX = min(this._getCalculatedUserDomain(data, xDomain, "x"));

    const goalItems = goals.map((goal) => (
      <Hint
        key={goal.value}
        value={{ y: goal.value, x: smallestX }}
        yDomain={this._getCalculatedUserDomain(this.state.data, yDomain, "y")}
      >
        <div className="goal">
          <span className="lines" />
          <span className="annotation-value">{goal.description}</span>
        </div>
      </Hint>
    ));

    return goalItems;
  }

  _labels() {
    const { labels, xDomain } = this.props;
    if (!labels) {
      return null;
    }

    function renderLabel(label) {
      const values = [{ x: label.value }];

      return (
        <Crosshair key={label.value} values={values} xDomain={xDomain}>
          <div className="annotation-value annotation-value-surgery">
            {label.description}
          </div>
        </Crosshair>
      );
    }

    return labels.map(renderLabel);
  }

  _legend(config) {
    // The legend is grouped by description
    const groupedByDescription = groupBy(config, "description");

    const legends = [];

    for (const key in groupedByDescription) {
      const description = key;
      const metricInfoForDescription = groupedByDescription[key];

      const legendInfo = metricInfoForDescription.map((metricInfo) => ({
        title: metricInfo.groupedBy,
        color: metricInfo.color,
      }));

      // Some charts have just one "bubble" for the legend, so then placing it with the label, instead of with the identifier
      // Not sure why it is 'null' string
      const hasOneBubble =
        legendInfo.length === 1 && legendInfo[0].title === "null";

      const legend = (
        <DiscreteColorLegend items={legendInfo} orientation="horizontal" />
      );

      // The empty component is just here to prevent an error in the console, since react-vis (the chart lib) else tries to pass props to a div, which triggers an error
      legends.push(
        <EmptyComponent>
          <div
            className={classNames("chart-legend", { hasOneBubble })}
            key={description}
          >
            <span className="description">{description}</span> {legend}
          </div>
        </EmptyComponent>
      );
    }

    return legends;
  }

  // Returns the domain of the chart data (so not the user specified domain)
  _getDataDomain(data, axis) {
    if (axis !== "x" && axis !== "y") {
      throw new Error("Can only get domains for x or y domain");
    }

    const values = flatten(
      data.map((line) => line.data.map((value) => value[axis]))
    );

    return [min(values), max(values)];
  }

  // Allows the user to pass an array as domain, or a function that returns the array for dynamic domains
  _getCalculatedUserDomain(data, userDomain, axis) {
    if (!userDomain) {
      return null;
    } else if (typeof userDomain === "function") {
      const chartDomain = this._getDataDomain(data, axis);
      return userDomain(chartDomain[0], chartDomain[1]);
    }
    return userDomain;
  }

  _xAxis() {
    const { xAxisFormat, xLabelAngle, xTickValues, xDomain } = this.props;
    const axisProps = this._axisData(
      xAxisFormat,
      xLabelAngle,
      xDomain,
      xTickValues,
      "x"
    );
    return <XAxis {...axisProps} />;
  }

  _yAxis() {
    const { yAxisFormat, yLabelAngle, yDomain, yTickValues } = this.props;
    const axisProps = this._axisData(
      yAxisFormat,
      yLabelAngle,
      yDomain,
      yTickValues,
      "y"
    );
    return <YAxis {...axisProps} />;
  }

  _axisData(formatter, angle, domain, tickValues, axis) {
    const hasTickValues = tickValues && tickValues.length > 0;
    const hasFormattedTickValues =
      hasTickValues && tickValues[0].formatted !== undefined;

    const calculatedDomain = this._getCalculatedUserDomain(
      this.state.data,
      domain,
      axis
    );

    const axisProps = compactObject({
      // When yTickValues are passed, this takes presedence over the yAxisFormat function
      tickFormat: hasFormattedTickValues
        ? this.formatterForTickValues(tickValues)
        : formatter,
      tickLabelAngle: angle,
      key: `${axis}axis`,
      tickValues: this._tickValues(tickValues),
    });

    axisProps[`${axis}Domain`] = calculatedDomain;

    return axisProps;
  }

  // Returns the tick values as an array of numbers if present
  _tickValues(tickValues) {
    const hasTickValues = tickValues && tickValues.length > 0;
    const hasFormattedTickValues =
      hasTickValues && tickValues[0].formatted !== undefined;
    return hasTickValues
      ? tickValues.map((tick) => (hasFormattedTickValues ? tick.value : tick))
      : undefined;
  }

  // Function that returns the passed formatted value for a tick value
  formatterForTickValues(allTickValues) {
    return function formatTickValue(value) {
      return find(allTickValues, (v) => v.value === value).formatted;
    };
  }

  // Todo: Remove gridlines duplication
  _xGridLines() {
    const { xGridLines, xTickValues, xDomain } = this.props;
    return xGridLines === true ? (
      <VerticalGridLines
        key="x-grid-lines"
        tickValues={this._tickValues(xTickValues)}
        xDomain={xDomain}
      />
    ) : null;
  }

  _yGridLines() {
    const { yGridLines, yTickValues, yDomain } = this.props;
    return yGridLines === true ? (
      <HorizontalGridLines
        key="y-grid-lines"
        tickValues={this._tickValues(yTickValues)}
        yDomain={yDomain}
      />
    ) : null;
  }

  _getHint() {
    const { hoveredValue, hoveredSeriesIndex, data } = this.state;
    const { yDomain, xDomain } = this.props;
    if (!hoveredValue) {
      return null;
    }

    const lineData = data[hoveredSeriesIndex];

    const key = `x${hoveredValue.x}y${hoveredValue.y}`;
    return (
      <Hint
        value={hoveredValue}
        format={(value) => this._formatHint(value, lineData)}
        key={key}
        yDomain={this._getCalculatedUserDomain(this.state.data, yDomain, "y")}
        xDomain={this._getCalculatedUserDomain(this.state.data, xDomain, "x")}
      />
    );
  }

  _formatHint(value, lineData) {
    const { xAxisFormat, xDescription } = this.props;

    const yDescription = lineData.label || "y";

    const yAxisFormat = lineData.formatter ? lineData.formatter : (v) => v;
    const hintSections = [
      { title: yDescription, value: yAxisFormat(value.y) },
      { title: xDescription, value: xAxisFormat(value.x) },
    ];

    // Not sure why string null....
    if (lineData.groupedBy && lineData.groupedBy !== "null") {
      hintSections.unshift({ title: lineData.groupedBy });
    }

    return hintSections.concat(this.props.extraHintValues(value, lineData));
  }

  onMouseLeave() {
    this._clearHint();
  }

  _clearHint() {
    this.setState({ hoveredValue: null, hoveredSeriesIndex: null });
  }

  _onSeriesMouseOver(index, event) {
    this.setState({ hoveredSeriesIndex: index });
  }

  _onNearestXY(seriesIndex, value) {
    const { hoveredSeriesIndex } = this.state;
    if (seriesIndex === hoveredSeriesIndex) {
      this.setState({ hoveredValue: value });
    }
  }

  render() {
    const {
      xAxisFormat,
      xDescription,
      fileName,
      height,
      width,
      margin,
      goals,
      className,
    } = this.props;

    const { data } = this.state;
    if (!data || data.length === 0) {
      return null;
    }

    const allMargins = Object.assign({}, defaultMargin, margin);

    const fullHeight = height
      ? height + allMargins.bottom + allMargins.top
      : null;
    const fullWidth = width ? width + allMargins.left + allMargins.right : null;

    // return
    // Passing the props directly into FlexibelWidthXYPlot, causes width prop to exist, even when undefined, which in turn messes up the width in the flexibleXYPlot, since undefined or null in my prop means auto-width but "existence" in their prop seems to mean 0, just excluding it then instead
    const flexibleWidthXYPlotProps = compactObject({
      height: fullHeight,
      width: fullWidth,
      onMouseLeave: () => (this.onMouseLeave ? this.onMouseLeave() : null),
      margin: allMargins,
    });

    const hasGoals = goals && goals.length > 0;
    return (
      <div className={classNames("chart-container", { hasGoals }, className)}>
        <CSVDownload
          chartData={data}
          xAxisFormat={xAxisFormat}
          xDescription={xDescription}
          fileName={fileName}
        />
        <div className="plot-container">
          <FlexibleWidthXYPlot {...flexibleWidthXYPlotProps}>
            {this.renderPlot(data)}
          </FlexibleWidthXYPlot>
        </div>
      </div>
    );
  }
}

const tickValuesShape = PropTypes.oneOf([
  // If you pass an array, that is displayed through the formatter function
  PropTypes.arrayOf(PropTypes.number),

  // If you pass this, the formatter function is NOT called, but it uses the formatted value as put here
  PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      formatted: PropTypes.string,
    })
  ),
]);

BaseChart.propTypes = {
  // Each draw object with these properties is what helps to draw the line / bars on the chart from the metric data
  // To draw mulitple different lines / bars, you can pass multiple objects
  draw: PropTypes.arrayOf(
    PropTypes.shape({
      // This is a function that accepts our metric data and maps each entry to an object with format {x: dataForX, y: dataForY} which is what the library can draw
      // Our current one's are defined in ./shared/components/charts/LineChart/operations.js
      operation: PropTypes.func.isRequired,
      // The label for the legend for this line / bar
      label: PropTypes.string,
      // The label for the y value in the tooltip
      shortLabel: PropTypes.string,
      // If you want to format the info in the tooltip (so it is for example a percentage) you can pass a function here which accepts a simple value, and should return a formatted value for that value
      // In ./shared/components/chartsLineChart/formatters there are some available for you
      formatter: PropTypes.func,
      // Since one chart can get multiple different metric types, this should match the string of the metric type you want to draw, so this "draw" instance gets the correct metric data
      metricType: PropTypes.string.isRequired,
      // Wheter you want to display the lines / bars side by side if you have multiple codes selected (e.g the operation above is done on a per code basis) or if the operation above is done in one go on all selected items
      display: PropTypes.oneOf(["combined", "individually"]).isRequired,
    })
  ).isRequired,

  // You can use the same formatter functions as aboven in the same file for this
  // This will format the x values on the x axis of the chart
  xAxisFormat: PropTypes.func,
  // And this is to format it on the y axis of the chart
  yAxisFormat: PropTypes.func,

  // These control range of each domain. If not provided, the chart justs the area around the data you supply.
  // If, on the otherhand, the data ranges you _want_ to show is from (0-100), but the actual data you _have_ to show is from (60-80),
  // then to show the data within the full range (0-100), add a yDomain (or xDomain) of [0, 100].
  // When the domain is a function, the function will be called with the highest and lowest value, you can return a domain based of that
  yDomain: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  xDomain: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),

  // Angles (which angle the axis label should be drawn)
  yLabelAngle: PropTypes.number,
  xLabelAngle: PropTypes.number,

  // If set on the tooltip the description of the x value will be this, instead of the litteral "x"
  xDescription: PropTypes.string,

  // Enabling grid lines
  yGridLines: PropTypes.bool,
  xGridLines: PropTypes.bool,

  // If you want to supply what should be on the axis ticks
  xTickValues: tickValuesShape,
  yTickValues: tickValuesShape,

  fileName: PropTypes.string,

  // Chart height in pixels
  height: PropTypes.number.isRequired,
  // Chart with in pixels
  width: PropTypes.number,

  // All in pixels
  margin: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),

  // You can render goal marks on the chart
  goals: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),

  // You can draw labels on positions too
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),

  extraHintValues: PropTypes.func,

  className: PropTypes.string,
};

BaseChart.defaultProps = {
  xAxisFormat: date,
  yAxisFormat: (v) => v,
  xDescription: "Date", // this is typically our default...
  height: 350,
  margin: defaultMargin,
  width: null, // Defaulting to full width,
  labels: [],
  goals: [],
  xGridLines: false,
  yGridLines: true,

  xLabelAngle: 0,
  yLabelAngle: 0,

  yDomain: (minV, maxV) => [minV, maxV],
  xDomain: (minV, maxV) => [minV, maxV],

  className: "",
  fileName: "",

  xTickValues: null,
  yTickValues: null,
  extraHintValues: () => [],
};

BaseChart.contextTypes = {
  metricData: PropTypes.object,
};

export default BaseChart;
