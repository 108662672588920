import { createAction } from "redux-actions";

const prefixed = (val) => `MODULES_LIST::${val}`;

export const onShowDeletedChange = createAction(
  prefixed("SHOW_DELETED_CHANGE"),
  (deleted) => deleted
);
export const onDidShowDeletedChanged = createAction(
  prefixed("DID_SHOW_DELETED_CHANGE"),
  (deleted) => deleted
);
