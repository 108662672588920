import { createAction } from "redux-actions";

const prefixed = (val) => `Authentication/Login::${val}`;

export const requestLogin = createAction(prefixed("REQUEST_LOGIN"));
export const failedLogin = createAction(prefixed("FAILED_LOGIN"));
export const successFulLogin = createAction(
  prefixed("SUCCESSFUL_LOGIN"),
  (
    token,
    refreshToken,
    email,
    userType,
    id,
    externalService,
    tokenType = "Bearer"
  ) => ({
    token,
    refreshToken,
    email,
    userType,
    id,
    tokenType,
    externalService,
  })
);
export const logout = createAction(prefixed("LOGOUT"));
export const resetForm = createAction(prefixed("RESET_FORM"));
export const confirm2Fa = createAction(prefixed("CONFIRM_2FA"));
export const newCodeSent = createAction(prefixed("NEW_CODE_SENT"));

export const requestUserInfo = createAction(prefixed("REQUEST_USER_INFO"));
export const fetchedUserInfo = createAction(
  prefixed("FETCHED_USER_INFO"),
  (userInfo) => userInfo
);
