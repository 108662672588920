import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../shared/app/requestErrorHandler";
import { didFetchKPIStats, requestKPIStats } from "./actions";
import { fetchKPIStats as fetchKPIStatsAPI } from "./api";

function* fetchKPIStats() {
  try {
    const response = yield fetchKPIStatsAPI();
    yield put(didFetchKPIStats(response.data));
  } catch (e) {
    yield handleApiError(e);
  }
}

export default function* watchKPIStatsSagas() {
  yield all([takeLatest(requestKPIStats, fetchKPIStats)]);
}
