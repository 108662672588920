import { connect } from "react-redux";

import { daysSinceDate } from "./utils";

import Chart from "./Chart.jsx";
import buildFormatterOfType from "./formatter";

function mapDispatchToProps(dispatch, ownProps) {
  const formatter = buildFormatterOfType(ownProps.metric.format.y);
  return {
    daysSinceRegistration: (seenFrom) =>
      daysSinceDate(ownProps.registrationDate, seenFrom),
    formatter,
  };
}

export default connect(null, mapDispatchToProps)(Chart);
