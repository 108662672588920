import React from "react";
import PropTypes from "prop-types";

import { reduxForm, Field } from "redux-form";
import FormGroupField from "../../../../../components/FormFields/FormGroupField";

function RequestUpdatePassword({ handleSubmit, onSubmit, message }) {
  return (
    <div className="forgot-password-container">
      <h1 style={{ textAlign: "center" }}>Forgot password</h1>
      <p>
        Fill in your email to receive further instructions to reset your
        password.
      </p>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Field
          className="forgot-password-field"
          name="email"
          component={FormGroupField}
          label="E-mail"
          required
          type="text"
        />

        <div className="bottom">
          <input className="btn btn-main" type="submit" value="Submit" />
        </div>
      </form>

      {message && <p className="info-message">{message}</p>}
    </div>
  );
}

RequestUpdatePassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
};

RequestUpdatePassword.defaultProps = {
  message: null,
};

export default reduxForm({
  form: "requestUpdatePassword",
})(RequestUpdatePassword);
