import classNames from "classnames";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import BreadCrumbBar from "../../components/BreadCrumbBar/BreadCrumbBar";
import LoadingIndicator from "../../shared/components/LoadingIndicator";
import ModalPanel from "../../shared/components/ModalPanel";
import Pagination from "../../shared/components/Pagination";
import SuccessAnimation from "../../shared/components/SuccessAnimation";
import editIconHover from "./../../assets/edit hover.svg";
import CoachForm from "./CoachForm";
import "./Coaches.css";
import { defaultSortBy, defaultSortDirection } from "./api";

const perPage = 30;
class Coaches extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clickedCoach: null,
    };

    this.tableRef = null;

    this.renderColumnHeader = this.renderColumnHeader.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.handleClickRow = this.handleClickRow.bind(this);
    this.handleDoubleClickRow = this.handleDoubleClickRow.bind(this);
  }

  componentDidMount() {
    this.search(this.props);
  }

  componentDidUpdate(prevProps) {
    const { keyword, sortBy, sortDirection, page, searchResults, count } =
      this.props;
    const { clickedCoach } = this.state;

    if (
      keyword !== prevProps.keyword ||
      sortBy !== prevProps.sortBy ||
      sortDirection !== prevProps.sortDirection ||
      page !== prevProps.page
    ) {
      this.search(this.props);
    }

    // we want to make sure that there is always a default "clicked" blue-highlighted row at the top of the list, nicer ui
    if (!isEqual(searchResults, prevProps.searchResults) && count > 0) {
      this.tableRef.scrollTop = 0;
      const firstResult = searchResults[0];
      this.setState({ clickedCoach: firstResult.email });
    }

    if (!clickedCoach && searchResults && searchResults.length) {
      this.setState({ clickedCoach: searchResults[0].email });
    }
  }

  search({ requestCoaches, keyword, sortBy, sortDirection, page }) {
    requestCoaches(keyword, sortBy, sortDirection, page);
  }

  handleSort(column) {
    const { onSortChange, sortBy, sortDirection } = this.props;
    const nextSortBy = column.sortBy;
    const nextSortDirection =
      sortDirection === "asc" && nextSortBy === sortBy ? "desc" : "asc";

    onSortChange(nextSortBy, nextSortDirection);
  }

  handleClickRow(coach) {
    this.setState({ clickedCoach: coach.email });
  }

  handleDoubleClickRow(coach) {
    const { showCoachForm } = this.props;

    showCoachForm(coach.email);
  }

  renderAddCoachButton() {
    const { showCoachForm, isLoading } = this.props;

    const createCoachForm = (
      <ModalPanel
        name="manageCoach"
        backgroundColor="#FAFAFA"
        borderRadius="15px"
      >
        <CoachForm />
      </ModalPanel>
    );

    return (
      <div>
        <button
          className="add-coach-button"
          onClick={() => showCoachForm(null)}
          disabled={isLoading}
        >
          Add coach
        </button>
        {createCoachForm}
      </div>
    );
  }

  renderManagementRow() {
    const { onSearchChange, keyword } = this.props;

    return (
      <BreadCrumbBar>
        <div className="management-row">
          <div className="search-container">
            <input
              className="search-input"
              placeholder="Search name or email"
              onChange={(e) => onSearchChange(e.target.value)}
              value={keyword ?? ""}
            />
          </div>
          {this.renderAddCoachButton()}
        </div>
      </BreadCrumbBar>
    );
  }

  renderColumnHeader(column) {
    const { sortBy, sortDirection } = this.props;

    let arrow = null;
    if (column.sortBy === sortBy) {
      const arrowClassName =
        sortDirection === "asc" ? "caret-up" : "caret-down";
      arrow = <span className={classNames(arrowClassName, "caret")} />;
    }

    const headerText = <span>{column.columnLabel}</span>;

    return (
      <th
        key={column.columnLabel}
        className={classNames({ sortable: !!column.sortBy })}
        onClick={() => column.sortBy && this.handleSort(column)}
      >
        {headerText}
        {arrow}
      </th>
    );
  }

  renderColumnHeaders() {
    const { columns, count } = this.props;
    const columnHeaders = columns.map(this.renderColumnHeader);

    return (
      <thead className={classNames({ hideTableHead: count === 0 })}>
        <tr>{columnHeaders}</tr>
      </thead>
    );
  }

  renderRow(searchResult) {
    const { columns, showCoachForm, showResendInvite, resendInvite, message } =
      this.props;

    const { id, email, name, activated, formData } = searchResult;
    const shouldDisplayResendInvite = !activated;
    const rowClassName = classNames({
      clickedRow: email === this.state.clickedCoach,
    });

    const coachRow = columns.map((column) => (
      <td key={column.columnLabel}>
        {column.displayValue(searchResult) || (
          <span className="not-available">--</span>
        )}
      </td>
    ));

    const coachButtonsColumn = (
      <td key={email} className="td-with-buttons">
        {shouldDisplayResendInvite && (
          <button
            className="resend-invite-button"
            onClick={() => showResendInvite(email)}
          >
            Resend invite
          </button>
        )}
        <button
          className="edit-coach-button"
          onClick={() => showCoachForm(email)}
        >
          {" "}
          <img className="edit-icon" src={editIconHover} alt="edit" />
          Edit
        </button>
      </td>
    );

    // these two are modal popups and are hidden by default -- will only render when the 'showPanel()' action is dispatched

    const updateCoachForm = (
      <ModalPanel
        key={`${email}${name}`}
        name={`manageCoach${email}`}
        withClickOutsideListener
        backgroundColor="#FAFAFA"
        borderRadius="15px"
      >
        <CoachForm formData={formData} />
      </ModalPanel>
    );

    const resendInvitePopup = (
      <ModalPanel
        key={`${name}${email}`}
        name={`resendInvite${email}`}
        withClickOutsideListener
        backgroundColor="#FAFAFA"
        borderRadius="15px"
      >
        <div className="resend-invite">
          <div className="resend-invite-header">Resend invite to {email}?</div>
          {message ? (
            <SuccessAnimation message={message} />
          ) : (
            <button
              className="resend-invite-button"
              onClick={() => resendInvite(id)}
            >
              Resend
            </button>
          )}
        </div>
      </ModalPanel>
    );

    return (
      <tr
        key={email}
        className={rowClassName}
        onClick={() => this.handleClickRow(searchResult)}
        onDoubleClick={() => this.handleDoubleClickRow(searchResult)}
      >
        {[...coachRow, coachButtonsColumn, updateCoachForm, resendInvitePopup]}
      </tr>
    );
  }

  renderRows() {
    const { searchResults, isLoading } = this.props;

    if (!searchResults) return null;

    const coachRows = searchResults.map(this.renderRow);

    return (
      <tbody className={classNames({ hideTableRows: isLoading })}>
        {coachRows}
      </tbody>
    );
  }

  renderSearchResults() {
    return (
      <div
        className="coach-table-container"
        ref={(node) => {
          this.tableRef = node;
        }}
      >
        <table className="coach-table">
          {this.renderColumnHeaders()}
          {this.renderRows()}
        </table>
        {this.renderPagination()}
        <LoadingIndicator className="loading-indicator" />
        {this.renderNoResults()}
      </div>
    );
  }

  renderNoResults() {
    const { searchResults, isLoading, count } = this.props;
    const hasResults = searchResults && count > 0;
    const fieldsToSearchOn = ["Email", "Name"];

    if (hasResults || isLoading) return null;

    return (
      <div className="no-results-container">
        <div className="no-results">
          <h1 className="no-results-found-header">No Results Found</h1>
          <p className="you-can-search-on">You can search on:</p>

          <div className="fields-to-search-on">
            {fieldsToSearchOn.map((field) => (
              <p key={field} className="field-to-search-on">{`- ${field}`}</p>
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderPagination() {
    const { page, count } = this.props;

    if (!count) return null;

    return (
      <Pagination
        itemCount={count}
        itemsPerPage={perPage}
        baseUrl={"/coaches"}
        currentPage={page}
        pageInQuery
      />
    );
  }

  render() {
    return (
      <div className="coaches-list">
        {this.renderManagementRow()}
        {this.renderSearchResults()}
      </div>
    );
  }
}

export default Coaches;

Coaches.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  keyword: PropTypes.string,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  page: PropTypes.number,
};

Coaches.defaultProps = {
  sortBy: defaultSortBy,
  sortDirection: defaultSortDirection,
};
