import { connect } from "react-redux";
import { userType } from "../../../../../../shared/routes/Authentication/Login/reducer";
import { showPanel } from "../../../../../../shared/components/ModalPanel/actions";

import Actions from "./Actions.jsx";

const dispatch = {
  onResetPasswordButton: () => showPanel("resetPassword"),
  onRelease: () => showPanel("release"),
  onDetransitionPeer: () => showPanel("detransitionPeer"),
  onDeletePatient: () => showPanel("deletePatient"),
};

function mapStateToProps(state) {
  return {
    authenticatedUserType: userType(state),
  };
}

export default connect(mapStateToProps, dispatch)(Actions);
