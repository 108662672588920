import React from "react";
import PropTypes from "prop-types";

import "./Engagement.css";

import ColoredBar from "./../../../../../../shared/components/Data/ColoredBar";

function Engagement({ score }) {
  // Colors are the same as the colors for scores in function engagementEventForScore
  let className = "negative";
  if (score >= 5 / 7) {
    className = "positive";
  } else if (score >= 3 / 7) {
    className = "neutral";
  }

  return (
    <div className="engagement">
      <h1>Seven day engagement</h1>
      <p>
        The Bardavon Recovery+ engagement score helps you understand how well a
        patient is engaging with their program. Ideally, patients will be in the
        Good to Outstanding range.
      </p>

      <ColoredBar
        score={score}
        sections={["Poor", "Good", "Outstanding"]}
        className={`colored-bar-${className}`}
        minScore={0.01}
      />
    </div>
  );
}

Engagement.propTypes = {
  score: PropTypes.number.isRequired,
};

export default Engagement;
