import { getProgramsAxios } from "../../../../../../services/api";

export const fetchPinnedCardsAPI = (programId) =>
  getProgramsAxios().get(`/programs/${programId}/pinned-cards`);
export const fetchProgramCardsAPI = (programId) =>
  getProgramsAxios().get(`/programs/${programId}/cards`);
export const pinCardAPI = (programId, values) =>
  getProgramsAxios().post(`/programs/${programId}/pinned-cards`, values);
export const unpinCardAPI = (programId, cardId) =>
  getProgramsAxios().delete(`/programs/${programId}/pinned-cards/${cardId}`);
