import { handleActions } from "redux-actions";
import {
  didUpdateAssignedTo,
  didGetAvailableAssignedToUsersForInvite,
} from "./actions";

export const availableAssignedToUsersForInvite = (state, inviteId) =>
  state.ui.Invite.list[inviteId].availableNurses;
export const currentAssignedTo = (state, inviteId) =>
  state.ui.Invite.list[inviteId].nurse;

export default handleActions(
  {
    [didUpdateAssignedTo]: (state, action) => {
      const { inviteId, assignedToUser } = action.payload;
      state.list[inviteId].nurse = assignedToUser; // In API it's called nurse... so keeping that structure for peer
      return Object.assign({}, state);
    },
    [didGetAvailableAssignedToUsersForInvite]: (state, action) => {
      const { inviteId, assignedToUsers } = action.payload;
      state.list[inviteId].availableNurses = assignedToUsers; // In API it's called nurse...
      return Object.assign({}, state);
    },
  },
  {}
);
