import { createAction } from "redux-actions";

const prefixed = (val) => `Modules/BottomBar::${val}`;

export const deleteModule = createAction(
  prefixed("DELETE_MODULE", (moduleId) => moduleId)
);
export const didDeleteModule = createAction(
  prefixed("DID_DELETE_MODULE", (moduleId) => moduleId)
);

export const restoreModule = createAction(
  prefixed("RESTORE_MODULE", (moduleId) => moduleId)
);
export const didRestoreModule = createAction(
  prefixed("DID_RESTORE_MODULE", (moduleId) => moduleId)
);
