import { handleActions } from "redux-actions";
import { didDeletePhase } from "./actions";

export default handleActions(
  {
    [didDeletePhase]: (state, action) => {
      const phaseId = action.payload;
      const { [phaseId]: ignored, ...phases } = { ...state.phases };
      const list = state.list.slice().filter((phase) => phase.id !== phaseId);
      return { ...state, phases, list };
    },
  },
  {}
);
