import { createAction } from "redux-actions";

const prefixed = (val) => `Invites/View/Phone::${val}`;

export const updatePhoneForInvite = createAction(
  prefixed("UPDATE_PHONE_FOR_INVITE"),
  (inviteId, phone) => ({ phone, inviteId })
);
export const didUpdatePhone = createAction(
  prefixed("DID_UPDATE_PHONE"),
  (inviteId, phone) => ({ phone, inviteId })
);
