import { get, sortBy, castArray } from "lodash";
import { handleActions } from "redux-actions";

import { query } from "../../../shared/routes/query";
import {
  didFetchCustomers,
  didFetchStaff,
  clearStaffData,
  didUpdateStaff,
  didCreateStaff,
} from "./actions";

/* eslint-disable no-mixed-operators */

export function customers(state) {
  const customersList = get(state, "ui.ManageStaff.customers");
  // eslint-disable-next-line no-return-assign
  if (customersList)
    customersList.forEach(
      (customer) => (customer.clinicians = sortBy(customer.clinicians, "name"))
    );
  return customersList && sortBy(customersList, "customer.name");
}

export function clinicianData(state) {
  const currentCustomers = customers(state) || [];
  return currentCustomers.map((customer) => ({
    label: customer.customer.name,
    value: customer.customer.id,
    items: customer.clinicians.map((c) => ({
      value: c.id,
      label: `${c.name} (${castArray(c.emails).join(", ")})`,
    })),
  }));
}

export function selectedClinicianId(state) {
  return query(state, "clinicianId");
}
export function staffData(state) {
  const staff = state.ui.ManageStaff.staff;
  // filtering by whether there is an email is for staging only
  const filtered = staff && staff.filter((staffMember) => staffMember.email);
  return (
    filtered &&
    filtered.sort(
      (a, b) => (a.email && b.email && a.email.localeCompare(b.email)) || 1
    )
  );
}

export function displayInfo(state) {
  const clinicianId = selectedClinicianId(state);
  const currentCustomers = customers(state);

  const defaults = {
    clinicianDisplayName: null,
    customerDisplayName: null,
    clinicianCodes: null,
  };

  if (!currentCustomers || !clinicianId) {
    return defaults;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const customer of currentCustomers) {
    // eslint-disable-next-line no-restricted-syntax
    for (const clinician of customer.clinicians) {
      if (clinician.id === clinicianId) {
        const clinicianDisplayName = clinician.name.replace(/\s-\s.*/, "");
        const isJohnOrtho = clinicianDisplayName.toLowerCase() === "john ortho";
        const customerDisplayName = customer.customer.name;
        return {
          clinicianDisplayName: (!isJohnOrtho && clinicianDisplayName) || "",
          customerDisplayName: (isJohnOrtho && customerDisplayName) || "",
          clinicianCodes: clinician.name.replace(/.*\s-\s/, ""),
        };
      }
    }
  }
  return defaults;
}

// Todo: make this a shared util function and use it elsewhere for improved ux
const updateArrayOfObjects = (arr, item, key) => [
  ...arr.filter((obj) => obj[key] !== item[key]),
  item,
];

export function selectedStaff(state) {
  const selectedId = query(state, "staffId");
  const staff = state.ui.ManageStaff.staff;
  if (!staff || !selectedId) return null;
  return staff.find((staffMember) => staffMember.id === selectedId);
}

export const ManageStaffReducer = handleActions(
  {
    [didFetchCustomers]: (state, action) => ({
      ...state,
      customers: action.payload,
    }),
    [didFetchStaff]: (state, action) => ({ ...state, staff: action.payload }),
    [clearStaffData]: (state) => ({ ...state, staff: null }),
    [didUpdateStaff]: (state, action) => ({
      ...state,
      staff: updateArrayOfObjects(state.staff, action.payload, "id"),
    }),
    [didCreateStaff]: (state, action) => ({
      ...state,
      staff: [...state.staff, action.payload],
    }),
  },
  {
    customers: null,
    staff: null,
  }
);
