import { connect } from "react-redux";

import FetchedMetrics from "./FetchedMetrics.jsx";

import { availableMetricDataOfTypes } from "../Metrics/reducer";

function mapStateToProps(state, ownProps) {
  return {
    metrics: availableMetricDataOfTypes(state, ownProps.types),
  };
}

export default connect(mapStateToProps)(FetchedMetrics);
