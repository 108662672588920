import { connect } from "react-redux";
import UpdateEmail from "./../../../../../../components/UserView/components/Forms/UpdateEmail";

import { updateEmailForInvite } from "./actions";

function mapDispatchToProps(dispatch, { userId }) {
  return {
    onSubmit: ({ email }) => dispatch(updateEmailForInvite(userId, email)),
  };
}

export default connect(null, mapDispatchToProps)(UpdateEmail);
