import { handleActions } from "redux-actions";

import { showPanel, hidePanel, setModalMessage } from "./actions";

export const shouldShowPanel = (state, whichPanel) =>
  whichPanel && state.ui.ModalPanel.show === whichPanel;

export const modalMessage = (state) => state.ui.ModalPanel.message;

export default handleActions(
  {
    [setModalMessage]: (state, action) => ({
      ...state,
      message: action.payload,
    }),
    [showPanel]: (state, action) => ({ ...state, show: action.payload }),
    [hidePanel]: (state, action) => ({ ...state, show: null }),
  },
  {
    show: null,
    message: null,
  }
);
