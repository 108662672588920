import { all, put, takeLatest } from "redux-saga/effects";

import { callAPI } from "../../../shared/utils/sagas";

import { setFleetingMessage } from "../../../shared/utils/form";

import {
  requestAllUsers,
  didFetchAllUsers,
  updateUser,
  didUpdateUser,
} from "./actions";

import {
  fetchAllUsers as fetchAllUsersAPI,
  updateCareTeamMember as updateCareTeamMemberAPI,
} from "./api";

function* updateCareTeamUser(action) {
  const values = action.payload;
  const { selectedId } = values;

  let res;
  res = yield updateCareTeamMemberAPI(selectedId, values);
  yield put(didUpdateUser(res.data));
}

export default function* watchManageCareTeamUsers() {
  yield all([
    // takeLatest(requestUser, didFetchUser),
    takeLatest(requestAllUsers, callAPI(fetchAllUsersAPI, didFetchAllUsers)),
    takeLatest(updateUser, updateCareTeamUser),
    takeLatest(
      didUpdateUser,
      setFleetingMessage(
        "updateCareTeamUserForm",
        { message: "Care team user successfully updated!" },
        5000
      )
    ),
  ]);
}
