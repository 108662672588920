import { handleActions } from "redux-actions";
import { didCreateModule, didUpdateModule } from "./actions";

export default handleActions(
  {
    [didCreateModule]: (state, action) => {
      const { id } = action.payload;
      const modules = { ...state.modules };
      const currentModule = modules[id];
      modules[id] = Object.assign({}, currentModule, action.payload);
      const list = state.list.slice();
      list.push(modules[id]);
      return { ...state, modules, list };
    },
    [didUpdateModule]: (state, action) => {
      const { id } = action.payload;
      const modules = { ...state.modules };
      const currentModule = modules[id];
      modules[id] = Object.assign({}, currentModule, action.payload);
      const list = [...state.list] || [];
      const moduleIndex = list.findIndex((module) => module.id === id);
      list[moduleIndex] = modules[id];
      return { ...state, modules, list };
    },
  },
  {}
);
