import { connect } from "react-redux";

import ModulesForm from "./ModulesForm.jsx";
import { submitFormToAPI } from "../../../../shared/utils/form";

import { didCreateModule, didUpdateModule } from "./actions";
import {
  createModule as createModuleAPI,
  updateModule as updateModuleAPI,
  fetchPillars,
  fetchAllCards,
} from "./api";
import { updateQuery } from "../../../../shared/routes/query";

const FORM_ID = "module";

function mapStateToProps(state, { module }) {
  return {
    initialValues: module,
    fetchPillars,
    fetchAllCards,
  };
}

function mapDispatchToProps(dispatch, { module }) {
  return {
    onSubmit: (values) => {
      if (values.cards) {
        values.cards = values.cards.map((card) => {
          if (card.value) {
            return card.value;
          }
          return card;
        });
      } else {
        values.cards = [];
      }
      if (module.id) {
        dispatch(
          submitFormToAPI(
            FORM_ID,
            updateModuleAPI,
            didUpdateModule,
            module.id,
            values
          )
        );
      } else {
        dispatch(
          submitFormToAPI(FORM_ID, createModuleAPI, didCreateModule, values)
        );
      }
      dispatch(updateQuery({ create: undefined, edit: undefined }));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModulesForm);
