import { handleActions } from "redux-actions";
import { get, sortBy } from "lodash";

import { query } from "../../../shared/routes/query";
import {
  didFetchCustomers,
  didCreateUser,
  didFetchUser,
  clearUserData,
  didFetchAllUsers,
  didUpdateUser,
  didFetchGroups,
} from "./actions";

export function allCustomers(state) {
  const customersList = get(state, "ui.ManageUsers.customers");
  // eslint-disable-next-line no-return-assign
  if (customersList)
    customersList.forEach(
      (customer) => (customer.clinicians = sortBy(customer.clinicians, "name"))
    );
  return customersList && sortBy(customersList, "customer.name");
}

export function allUsers(state) {
  return get(state, "ui.ManageUsers.users");
}

export function groups(state) {
  return get(state, "ui.ManageUsers.groups");
}

export function selectedCustomer(state) {
  const selectedId = query(state, "customerId");
  const customers = state.ui.ManageUsers.customers;

  if (!customers || !selectedId) return null;

  return customers.find(({ customer }) => customer.id === selectedId);
}

export function selectedUser(state) {
  const selectedId = query(state, "userId");
  const users = state.ui.ManageUsers.users;

  if (!users || !selectedId) return null;

  return users.find((user) => user.id === selectedId);
}

export function userInitialValues(state) {
  const user = selectedUser(state);

  if (!user) return null;

  return {
    fullname: user.fullname,
    email: user.email,
    blocked: user.blocked,
  };
}

export const ManageUsersReducer = handleActions(
  {
    [didFetchCustomers]: (state, action) => ({
      ...state,
      customers: action.payload,
    }),
    [didCreateUser]: (state, action) => ({ ...state, user: action.payload }),
    [didFetchGroups]: (state, action) => ({ ...state, groups: action.payload }),
    [didUpdateUser]: (state, action) => ({ ...state, user: action.payload }),
    [didFetchUser]: (state, action) => ({ ...state, user: action.payload }),
    [didFetchAllUsers]: (state, action) => ({
      ...state,
      users: action.payload,
    }),
    [clearUserData]: (state) => ({ ...state, user: null }),
  },
  {
    customers: null,
    user: null,
  }
);
