import { createAction } from "redux-actions";

const prefixed = (val) => `Invite/View/Assignedto::${val}`;

export const updateAssignedToForInvite = createAction(
  prefixed("UPDATE_ASSIGNED_TO_FOR_INVITE"),
  (inviteId, assignedToId) => ({ inviteId, assignedToId })
);
export const didUpdateAssignedTo = createAction(
  prefixed("DID_UPDATE_ASSIGNED_TO_FOR_INVITE"),
  (inviteId, assignedToUser) => ({ inviteId, assignedToUser })
);

export const getAvailableAssignedToUsersForInvite = createAction(
  prefixed("GET_AVAILABLE_ASSIGNED_TO_USERS_FOR_INVITE"),
  (inviteId) => ({ inviteId })
);
export const didGetAvailableAssignedToUsersForInvite = createAction(
  prefixed("DID_GET_AVAILABLE_ASSIGNED_TO_USERS_FOR_INVITe"),
  (inviteId, assignedToUsers) => ({ inviteId, assignedToUsers })
);
