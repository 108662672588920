import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import Form from "./components/Form";
import ModalPanel from "../../../../../../shared/components/ModalPanel/index";
import Set from "../../../../../../shared/components/Data/Set/index";

import { extraFieldsShape } from "../../../../Peer/List/components/Table/proptypes";
import { prettyLabelForExternalFieldValue } from "./index";

function ExternalService({
  authenticatedUserType,
  service,
  settings,
  data,
  fields,
  onUpdateButton,
  userId,
  onSubmit,
}) {
  return (
    <div className="external-service-block">
      <h4>{service}</h4>
      <div>
        {fields.map((field) => {
          const value = get(data, field.fullPath, "-");
          return (
            <Set
              label={field.label}
              key={field.label}
              value={prettyLabelForExternalFieldValue(field, value, settings)}
            />
          );
        })}
      </div>

      <div>
        {authenticatedUserType !== "care_team_user" && (
          <input
            type="button"
            className="btn btn-sm"
            value="Update"
            onClick={onUpdateButton}
          />
        )}
        <ModalPanel name="updateExternalService">
          <Form
            settings={settings}
            service={service}
            data={data}
            userId={userId}
            onSubmit={onSubmit}
          />
        </ModalPanel>
      </div>
    </div>
  );
}

ExternalService.propTypes = {
  service: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape(extraFieldsShape)).isRequired,
  data: PropTypes.object,
  settings: PropTypes.array,
  onUpdateButton: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

ExternalService.defaultProps = {
  data: {},
};

export default ExternalService;
