import { connect } from "react-redux";
import { isInvalid, change, untouch } from "redux-form";

import { fleetingFormMeta } from "../../../../shared/utils/form";
import { hidePanel } from "../../../../shared/components/ModalPanel/actions";

import {
  requestInvite,
  resetInviteAdmin,
  requestSendInvite,
  requestCustomers,
  requestPartnerUsersAdmin,
  requestInviteAdmin,
} from "../../actions";
import { customers, invite } from "../../reducer";
import {
  allRequiredFieldsFilledOut,
  extraFields,
  ownerFormData,
  ownerFullname,
  employerFormData,
  payerFormData,
  geographyData,
  country,
  selectedSurgeryDate,
  selectedProgram,
  selectedProgramType,
  initialValues,
} from "./selectors";

import PatientInviteForm from "./PatientInviteForm.jsx";
import {
  programFormData,
  selectedProgramTypes,
} from "../../../InvitePortal/selectors";

function mapStateToProps(state, { isAdmin }) {
  const disabled =
    isInvalid("patientInvite")(state) || !allRequiredFieldsFilledOut(state);

  return {
    isAdmin,
    initialValues: initialValues(state),
    invite: invite(state),
    ownerName: ownerFullname(state),
    customers: customers(state),
    extraFields: extraFields(state),
    programData: programFormData(
      state,
      invite,
      selectedProgramTypes(state, "patientInvite")
    ),
    partnerUserData: ownerFormData(state),
    employerData: employerFormData(state),
    payerData: payerFormData(state),
    geographyData: geographyData(state),
    country: country(state),
    program: selectedProgram(state),
    programType: selectedProgramType(state),
    programTypes: selectedProgramTypes(state, "patientInvite"),
    surgeryDate: selectedSurgeryDate(state),
    disabled,
    message: fleetingFormMeta(state, "patientInvite").message,
  };
}

const mapDispatchToProps = (dispatch) => ({
  requestInvite: () => dispatch(requestInvite()),
  resetInviteData: () => dispatch(resetInviteAdmin()),
  requestCustomers: () => dispatch(requestCustomers()),
  requestPartnerUsers: (id) => dispatch(requestPartnerUsersAdmin(id)),
  requestInviteAdmin: (clinicianId) =>
    dispatch(requestInviteAdmin(clinicianId)),
  resetSelectedProgram: () => {
    dispatch(change("patientInvite", "program", null));
    dispatch(untouch("patientInvite", "program"));
  },
  onSubmit: (values) => dispatch(requestSendInvite(values)),
  hidePanel: () => dispatch(hidePanel()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientInviteForm);
