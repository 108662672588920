import { put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../shared/app/requestErrorHandler";
import {
  onDidFilterByAssessmentsChanged,
  onDidShowDeletedChanged,
  onDownloadCards,
  onFilterByAssessmentsChange,
  onShowDeletedChange,
  didFetchCardsData,
  onUploadsCards,
} from "./actions";

import { fetchCardV1Data, fetchCardV2Data } from "./../../api";
import { uploadCards } from "../../upload";

function* showDeleteSaga(action) {
  try {
    yield put(onDidShowDeletedChanged(action.payload));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* showFilterAssessmentSaga(action) {
  try {
    yield put(onDidFilterByAssessmentsChanged(action.payload));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* fetchCardsDataSaga(action) {
  var cardV1Response, cardV2Response;

  try {
    cardV1Response = yield fetchCardV1Data(action.payload);
  } catch (error) {
    yield handleApiError(error);
  }
  try {
    cardV2Response = yield fetchCardV2Data(action.payload);
  } catch (error) {
    yield handleApiError(error);
  }

  const cardData = {
    v1Cards: cardV1Response?.data,
    v2Cards: cardV2Response?.data,
  };
  yield put(didFetchCardsData(cardData));
}

export default function* watchCardsListSaga() {
  yield takeLatest(onShowDeletedChange, showDeleteSaga);
  yield takeLatest(onDownloadCards, fetchCardsDataSaga);
  yield takeLatest(onUploadsCards, () => uploadCards());
  yield takeLatest(onFilterByAssessmentsChange, showFilterAssessmentSaga);
}
