import { handleActions } from "redux-actions";
import { set } from "lodash";

import { setSurgeryDateMessage, didUpdateSurgeryDate } from "./actions";

export const surgeryDateMessage = (state) => state.ui.Invite.surgeryDateMessage;

export default handleActions(
  {
    [setSurgeryDateMessage]: (state, action) => ({
      ...state,
      surgeryDateMessage: action.payload,
    }),
    [didUpdateSurgeryDate]: (state, action) => {
      const { inviteId, surgeryDate } = action.payload;
      const stateClone = { ...state };
      set(stateClone, `list.${inviteId}.surgery_date`, surgeryDate);
      return stateClone;
    },
  },
  { surgeryDateMessage: null }
);
