import { createAction } from "redux-actions";

const prefixed = (val) => `Modules/ModulesForm::${val}`;

export const didCreateModule = createAction(
  prefixed("DID_CREATE_MODULE", (moduleId, values) => ({ moduleId, values }))
);
export const didUpdateModule = createAction(
  prefixed("DID_UPDATE_MODULE", (moduleId, values) => ({ moduleId, values }))
);
