import { createAction } from "redux-actions";

const prefixed = (val) => `CARDS_LIST::${val}`;

export const onShowDeletedChange = createAction(
  prefixed("SHOW_DELETED_CHANGE"),
  (deleted) => deleted
);
export const onDidShowDeletedChanged = createAction(
  prefixed("DID_SHOW_DELETED_CHANGE"),
  (deleted) => deleted
);

export const onDownloadCards = createAction(prefixed("DOWNLOAD_CARDS"));

export const onCardsDownloaded = createAction(prefixed("CARDS_DOWNLOADED"));

export const onUploadsCards = createAction(prefixed("UPLOAD_CARDS"));

export const didFetchCardsData = createAction(
  prefixed("DID_FETCH_CARDS_DATA"),
  (cardsData, count) => ({ cardsData, count })
);

export const onFilterByAssessmentsChange = createAction(
  prefixed("SHOW_FILTER_ASSESSMENTS_CHANGE"),
  (filterAssessments) => filterAssessments
);
export const onDidFilterByAssessmentsChanged = createAction(
  prefixed("DID_SHOW_FILTER_ASSESSMENTS_CHANGE"),
  (filterAssessments) => filterAssessments
);
