import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import DataTable from "../../components/DataTable/DataTable.component";
import { SortableData } from "../../components/DataTable/types";
import { getTotalCountFromHeaders } from "../../shared/utils/apiHelpers";
import "./WorkersView.css";
import { ApiStatus, fetchWorkers } from "./api";
import { Worker } from "./types";
import { ErrorWithCode } from "../../shared/utils/ErrorWithCode";
import { ErrorModal } from "../../components/ErrorModal/ErrorModal";
import { ExpiredRefreshTokenError } from "../../shared/app/error/ExpiredRefreshTokenError";
import { signout } from "../../shared/routes/Authentication/Login";

export const workerViewColumns: GridColDef[] = [
  {
    field: "id",
    headerName: "Worker ID",
    width: 150,
  },
  {
    field: "name",
    headerName: "Worker Name",
    width: 250,
  },
  {
    field: "program",
    headerName: "Program",
    width: 120,
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
  },
  {
    field: "createdAt",
    headerName: "Date Added",
    width: 150,
    valueFormatter: (params) => {
      return moment(params?.value).format("MM/DD/YYYY");
    },
  },
  {
    field: "viewDetails",
    headerName: "",
    sortable: false,
    disableColumnMenu: true,
    renderCell: (props) => <a href={props.value}>View Details</a>,
  },
];

const WORKER_DETAIL_PAGE_BASE_URL = "/workers/view/";

const WorkersView = () => {
  const [workers, setWorkers] = useState<Worker[]>([]);
  const [workerViewRows, setWorkerViewRows] = useState<GridRowsProp>([]);
  const [totalWorkers, setTotalWorkers] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorWithCode | null>(null);

  useEffect(() => {
    // Whenever workers are updated, convert to table format
    const newWorkerViewRows: GridRowsProp = workers.map((worker) => {
      return {
        id: worker.id,
        name:
          (worker.lastName || "(no last name)") +
          ", " +
          (worker.firstName || "(no first name)"),
        program: worker.program || "-",
        email: worker.email || "-",
        createdAt: worker.createdAt || "-",
        viewDetails: WORKER_DETAIL_PAGE_BASE_URL + worker.id,
      };
    });
    setWorkerViewRows(newWorkerViewRows);
  }, [workers]);

  const fetchLatestWorkers = async (sortableData: SortableData) => {
    setIsLoading(true);

    // Sort by last name instead of name property
    if (sortableData.sortBy === "name") {
      sortableData.sortBy = "lastName";
    }

    try {
      // Fetch with sort information
      const { status, data, headers } = await fetchWorkers(sortableData);
      if (status === ApiStatus.OK) {
        setWorkers(data);
        // Set total number of records from header
        if (headers) {
          setTotalWorkers(getTotalCountFromHeaders(headers));
        }
      } else {
        setWorkers([]);
        console.warn("fetchWorkers returned KO status: ", {
          status,
          data,
        });
      }
    } catch (error) {
      if (error instanceof ExpiredRefreshTokenError) {
        document.location.href = "/signout";
        return;
      }
      console.error("fetchWorkers failed:", error);
      setError(error as Error);
    }

    setIsLoading(false);
  };

  return (
    <div className="workers">
      {!error ? (
        <DataTable
          columns={workerViewColumns}
          rows={workerViewRows}
          totalItems={totalWorkers}
          fetchItems={fetchLatestWorkers}
          name="Workers"
          description="List workers assigned to a preventive program"
          isLoading={isLoading}
          defaultSortBy={"createdAt"}
        />
      ) : (
        <ErrorModal error={error} onRequestClose={() => setError(null)} />
      )}
    </div>
  );
};

export default WorkersView;
