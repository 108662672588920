import { all, takeLatest, put, call } from "redux-saga/effects";
import { omit } from "lodash";
import {
  startSubmit,
  stopSubmit,
  clearSubmitErrors,
  setSubmitFailed,
  setSubmitSucceeded,
} from "redux-form";

import { callAPI } from "../../shared/utils/sagas";
import { setSuccessMessageThenClose } from "../../shared/components/ModalPanel/sagas";
import { setFleetingMessage, submitFormToAPI } from "../../shared/utils/form";
import { expandObject } from "../../shared/utils/expandObject";
import { updateQuery } from "../../shared/routes/query";

import {
  requestResults,
  didFetchResults,
  requestExternalFields,
  didFetchExternalFields,
  requestCustomers,
  didFetchCustomers,
  createPartnerUser,
  didCreatePartnerUser,
  resendInvite,
  didResendInvite,
  updatePartnerUser,
  didUpdatePartnerUser,
  requestGroupsAsAdmin,
  didFetchGroupsAsAdmin,
} from "./actions";
import {
  fetchTeamResults as fetchResultsAPI,
  fetchExternalFields as fetchExternalFieldsAPI,
  fetchCustomers as fetchCustomersAPI,
  createOwner as createOwnerAPI,
  createPartnerAdmin as createPartnerAdminAPI,
  resendInvite as resendInviteAPI,
  updateOwner as updateOwnerAPI,
  updatePartnerAdmin as updatePartnerAdminAPI,
  updateRole as updateRoleAPI,
  fetchGroupsAsAdmin,
} from "./api";
import { handleApiError } from "../../shared/app/requestErrorHandler";

function* submitCreatePartnerUser(action) {
  const { userable_type } = action.payload;
  const partnerUserAPIToCall =
    userable_type === "partner_admin" ? createPartnerAdminAPI : createOwnerAPI;

  yield put(
    submitFormToAPI(
      "managePartnerUser",
      partnerUserAPIToCall,
      didCreatePartnerUser(action.payload),
      action.payload
    )
  );
}

function* submitUpdatePartnerUser(action) {
  const { requestUpdateRole, userable_type, id } = action.payload;
  // this form is used for updating both owners and partner admins, which have different endpoints currently, and converting between the roles, which is also a separate endpoint, so the saga for it is not pretty
  let currentRole = userable_type;
  if (requestUpdateRole) {
    // then the partner user type in the submitted form is not the same as the initial values of the form
    currentRole = userable_type === "owner" ? "partner_admin" : "owner";
  }

  const partnerUserAPIToCall =
    currentRole === "owner" ? updateOwnerAPI : updatePartnerAdminAPI;

  yield put(clearSubmitErrors("managePartnerUser"));
  yield put(startSubmit("managePartnerUser"));

  if (requestUpdateRole) {
    // then we have to make two separate calls
    try {
      const valuesWithoutRole = omit(action.payload, ["userable_type"]);
      // the api requires that we do these updates in separate calls for now. we cannot update the role AND update the partner user's other info
      // if owners and partner admins didn't have separate endpoints for updating, then this would be unnecessary
      const firstUpdate = yield call(partnerUserAPIToCall, valuesWithoutRole);
      const secondUpdate = yield call(updateRoleAPI, { userable_type, id });

      if (firstUpdate && secondUpdate) {
        yield put(didUpdatePartnerUser());
        yield put(setSubmitSucceeded("managePartnerUser"));
      }
    } catch (error) {
      const { response } = error;
      yield put(setSubmitFailed("managePartnerUser"));
      if (response.status === 400) {
        yield put(
          stopSubmit("managePartnerUser", expandObject(response.data.error))
        );
      }
    }
  } else {
    yield put(
      submitFormToAPI(
        "managePartnerUser",
        partnerUserAPIToCall,
        didUpdatePartnerUser(action.payload),
        action.payload
      )
    );
  }
}

function* fetchGroupsAsAdminAPI(action) {
  const response = yield call(fetchGroupsAsAdmin, action.payload);
  yield put(didFetchGroupsAsAdmin(response.data));
  return null;
}

function* getUpdatedResultsAfterPartnerUserUpdate() {
  const newQuery = {
    keyword: "",
    sortBy: "updated_at",
    sortDirection: "desc",
    page: 0,
  };
  const { keyword, sortBy, sortDirection, page } = newQuery;

  yield put(updateQuery(newQuery));
  yield put(requestResults(keyword, sortBy, sortDirection, page));
}

function* getUpdatedResultsAfterTeamInvite() {
  const newQuery = {
    keyword: "",
    sortBy: "created_at",
    sortDirection: "desc",
    page: 0,
  };
  const { keyword, sortBy, sortDirection, page } = newQuery;

  yield put(updateQuery(newQuery));
  yield put(requestResults(keyword, sortBy, sortDirection, page));
}

export default function* watchTeam() {
  try {
    yield all([
      takeLatest(requestResults, callAPI(fetchResultsAPI, didFetchResults)),
      takeLatest(
        requestExternalFields,
        callAPI(fetchExternalFieldsAPI, didFetchExternalFields)
      ),
      takeLatest(
        requestCustomers,
        callAPI(fetchCustomersAPI, didFetchCustomers)
      ),
      takeLatest(requestGroupsAsAdmin, fetchGroupsAsAdminAPI),
      takeLatest(createPartnerUser, submitCreatePartnerUser),
      takeLatest(
        resendInvite,
        callAPI(resendInviteAPI, didResendInvite, false, false)
      ),
      takeLatest(updatePartnerUser, submitUpdatePartnerUser),
      takeLatest(
        didCreatePartnerUser,
        setFleetingMessage(
          "managePartnerUser",
          { message: "Team member added!" },
          2500
        )
      ),
      takeLatest(didCreatePartnerUser, getUpdatedResultsAfterTeamInvite),
      takeLatest(didResendInvite, setSuccessMessageThenClose("Invite resent")),
      takeLatest(
        didUpdatePartnerUser,
        setFleetingMessage(
          "managePartnerUser",
          { message: "Team member updated!" },
          2500
        )
      ),
      takeLatest(didUpdatePartnerUser, getUpdatedResultsAfterPartnerUserUpdate),
    ]);
  } catch (error) {
    handleApiError(error);
  }
}
