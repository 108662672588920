import { connect } from "react-redux";
import Release from "./Release.jsx";

import { cancelRelease, release } from "./actions";
import {
  isValidForm,
  fleetingFormMeta,
} from "../../../../../../../../shared/utils/form";
import { pendingRelease, releaseData, released, notification } from "./reducer";
import { hidePanel } from "../../../../../../../../shared/components/ModalPanel/actions";

const formName = "release";

function mapStateToProps(state, { userId }) {
  return {
    pendingRelease: pendingRelease(state, userId),
    initialValues: releaseData(state, userId) || { type: "full" },
    isValidForm: isValidForm(state, formName),
    released: released(state, userId),
    message: fleetingFormMeta(state, "release").message,
    notification: notification(state, userId),
  };
}

function mapDispatchToProps(dispatch, { userId }) {
  return {
    onSubmit: ({ date, type }) => dispatch(release(userId, date, type)),
    cancelRelease: () => dispatch(cancelRelease(userId)),
    onClose: () => dispatch(hidePanel("release")),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Release);
