import { handleActions } from "redux-actions";
import { didCreatePhase, didUpdatePhase } from "./actions";

export default handleActions(
  {
    [didCreatePhase]: (state, action) => {
      const { id } = action.payload;
      const phases = { ...state.phases };
      const currentPhase = phases[id];
      phases[id] = Object.assign({}, currentPhase, action.payload);
      const list = state.list.slice();
      list.push(phases[id]);
      return { ...state, phases, list };
    },
    [didUpdatePhase]: (state, action) => {
      const { id } = action.payload;
      const phases = { ...state.phases };
      const currentPhase = phases[id];
      phases[id] = Object.assign({}, currentPhase, action.payload);
      const list = [...state.list] || [];
      const phaseIndex = list.findIndex((phase) => phase.id === id);
      list[phaseIndex] = phases[id];
      return { ...state, phases, list };
    },
  },
  {}
);
