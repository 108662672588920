import moment from "moment";
import { put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../shared/app/requestErrorHandler";
import { callAPI } from "../../../shared/utils/sagas";
import {
  didFetchCustomers,
  didFetchWeeklyAdoptionReport,
  didUpdateEndDate,
  didUpdateStartDate,
  requestCustomers,
  requestUpdateEndDate,
  requestUpdateStartDate,
  requestWeeklyAdoptionReport,
} from "./actions";
import {
  fetchCustomers as fetchCustomersAPI,
  fetchCustomerWeeklyAdoptionReport as fetchCustomerWeeklyAdoptionReportAPI,
} from "./api";

function* fetchWeeklyAdoptionReport(action) {
  const customerId = action.payload.customerId;

  let query = "";
  if (action.payload.startDate) {
    const ISODate = moment(action.payload.startDate).format("YYYY-MM-DD");
    query += `&start_date=${ISODate}`;
  }
  if (action.payload.endDate) {
    const ISODate = moment(action.payload.endDate).format("YYYY-MM-DD");
    query += `&end_date=${ISODate}`;
  }

  try {
    const response = yield fetchCustomerWeeklyAdoptionReportAPI(
      customerId,
      query
    );
    yield put(
      didFetchWeeklyAdoptionReport({ data: response.data, customerId })
    );
  } catch (e) {
    yield handleApiError(e);
  }
}

function* updateStartDate(action) {
  yield put(didUpdateStartDate(action.payload));
}

function* updateEndDate(action) {
  yield put(didUpdateEndDate(action.payload));
}

export default function* watchWeeklyAdoptionReportSaga() {
  yield takeLatest(requestWeeklyAdoptionReport, fetchWeeklyAdoptionReport);
  yield takeLatest(
    requestCustomers,
    callAPI(fetchCustomersAPI, didFetchCustomers)
  );
  yield takeLatest(requestUpdateStartDate, updateStartDate);
  yield takeLatest(requestUpdateEndDate, updateEndDate);
}
