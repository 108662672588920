import { connect } from "react-redux";

import Modules from "./Modules.jsx";
import { query, updateQuery } from "../../shared/routes/query";
import { selectedModule, selectedModuleId, newModule } from "./reducer";
import { fetchModule, fetchModulesList } from "./actions";
import { showDeleted, modulesCount } from "./components/ModulesList/reducer";

function mapStateToProps(state) {
  const moduleId = selectedModuleId(state);

  return {
    showCreate: !!query(state, "create"),
    showEdit: !!query(state, "edit"),
    selectedModuleId: moduleId,
    selectedModule: selectedModule(state),
    showDeleted: showDeleted(state),
    newModule: newModule(state),
    count: modulesCount(state),
    filter: query(state, "filter"),
    page: query(state, "page"),
  };
}

const dispatch = {
  fetchModule: (moduleId) => fetchModule(moduleId),
  fetchModulesList: (deleted, filter, page) =>
    fetchModulesList(deleted, filter, page),
  resetPage: () => updateQuery({ page: 0 }),
};

export default connect(mapStateToProps, dispatch)(Modules);
