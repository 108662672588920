import ReactGA from "react-ga";
import { success } from "react-notification-system-redux";
import { reset } from "redux-form";
import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../shared/app/requestErrorHandler";
import { submitFormToAPI } from "../../shared/utils/form";
import {
  didFetchInvitePortal,
  didPostInvitePortal,
  postInvitePortal,
  requestInvitePortal,
  requestInvitePortalStaff,
} from "./actions";
import {
  fetchInvitePortalAdmin as fetchInvitePortalAdminAPI,
  fetchInvitePortalStaff as fetchInvitePortalStaffAPI,
  postInvitePortal as postInvitePortalAPI,
} from "./api";
import watchCustomerFormSagas from "./CustomerForm/sagas";

function* fetchInvitePortalStaff() {
  try {
    const response = yield fetchInvitePortalStaffAPI();
    yield put(didFetchInvitePortal(response.data));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* fetchInvitePortal(action) {
  const clinicianId = action.payload;

  try {
    const response = yield fetchInvitePortalAdminAPI(clinicianId);

    yield put(didFetchInvitePortal(response.data));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* submitInvitePortal(action) {
  // Endpoint expects the program section of the data be at top level
  const {
    consent,
    email_address,
    external_service,
    language,
    peer_fullname,
    state,
    surgery_date,
    program: {
      registration_code,
      aggregate_program,
      clinician_id,
      phase_id,
      phase_num,
    },
  } = action.payload;

  const formData = {
    consent,
    email_address,
    external_service,
    language,
    peer_fullname,
    state,
    surgery_date,
    registration_code,
    aggregate_program,
    clinician_id,
    phase_id,
    phase_num,
  };

  yield put(
    submitFormToAPI(
      "invitePortal",
      postInvitePortalAPI,
      didPostInvitePortal(formData),
      formData
    )
  );
}

function* resetForm() {
  // record GA event here:
  // this is where we can be sure the invite was sent out successfully
  ReactGA.event({
    category: "Conversion",
    action: "invite_portal_invite_sent",
  });
  // record GA event for web app property as well
  window.gtag("event", "intake_invite_sent");
  yield put(reset("invitePortal"));
  yield put(success({ message: "Invitation has been sent successfully." }));
}

export default function* watchInvitePortalSagas() {
  yield all([
    takeLatest(requestInvitePortalStaff, fetchInvitePortalStaff),
    takeLatest(requestInvitePortal, fetchInvitePortal),
    takeLatest(postInvitePortal, submitInvitePortal),
    takeLatest(didPostInvitePortal, resetForm),
    watchCustomerFormSagas(),
  ]);
}
