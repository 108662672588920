import { connect } from "react-redux";

import { mapToExternalServiceFields } from "../../../../Peer/reducer";
import { showPanel } from "../../../../../../shared/components/ModalPanel/actions";

import ExternalService from "./ExternalService.jsx";

function mapStateToProps(state, { settings }) {
  return {
    fields: mapToExternalServiceFields(settings),
  };
}

const dispatch = {
  onUpdateButton: () => showPanel("updateExternalService"),
};

export default connect(mapStateToProps, dispatch)(ExternalService);
