import { createAction } from "redux-actions";

const prefixed = (val) => `Invite/Actions/UpdateSurgeryDate::${val}`;

export const updateSurgeryDate = createAction(
  prefixed("UPDATE_SURGERY_DATE"),
  (inviteId, date) => ({ inviteId, date })
);
export const didUpdateSurgeryDate = createAction(
  prefixed("DID_UPDATE_SURGERY_DATE"),
  (inviteId, surgeryDate) => ({ inviteId, surgeryDate })
);
export const setSurgeryDateMessage = createAction(
  prefixed("SET_SURGERY_DATE_MESSAGE"),
  (message) => message
);
