import { takeLatest, put, all } from "redux-saga/effects";
import { success } from "react-notification-system-redux";
import { didCreateModule, didUpdateModule } from "./actions";

function* notifyUser() {
  yield put(success({ message: "The changes have been saved." }));
}

export default function* watchModulesFormSaga() {
  yield all([
    yield takeLatest(didCreateModule, notifyUser),
    yield takeLatest(didUpdateModule, notifyUser),
  ]);
}
