// The login system is actually a shared route, since most of it is the same across apps anyway
// In this file some app specific configuration is set

// The types of users that can login, if other types try to login an error will be shown
export const allowedUserTypes = [
  "admin",
  "partner_admin",
  "owner",
  "care_team_user",
  "prevention:read",
  // XXX probably deprecated, remove?:
  "demo",
];

// Default routes to redirect to when hitting a "404" page or the home page (/)
export const defaultRoutes = {
  authenticated: "/patients",
  unauthenticated: "/signin",
};

// Not used yet in dashoard, used in webapp
export const userInfoReducer = null;

export function allowedRole(userType, scope) {
  return (
    allowedUserTypes.includes(userType) || firstSupportedRole(scope) != null
  );
}

export function firstSupportedRole(scope) {
  return scope != null ? allowedUserTypes.find((s) => scope.includes(s)) : null;
}

export function userRole(userable_type, scope) {
  return userable_type ?? firstSupportedRole(scope);
}
