import { createAction } from "redux-actions";

const prefixed = (val) => `CoreReport::${val}`;

export const requestCoreReport = createAction(prefixed("REQUEST_CORE_REPORT"));
export const didFetchCoreReport = createAction(
  prefixed("DID_FETCH_CORE_REPORT"),
  (reportData) => reportData
);

export const requestResetReport = createAction(
  prefixed("REQUEST_RESET_REPORT")
);
export const didResetReport = createAction(prefixed("DID_RESET_REPORT"));

export const requestUpdateEndDate = createAction(
  prefixed("REQUEST_UPDATE_END_DATE")
);
export const didUpdateEndDate = createAction(
  prefixed("DID_UPDATE_END_DATE"),
  (endDate) => endDate
);
