import React from "react";
import PropTypes from "prop-types";

class Phase extends React.Component {
  constructor(props) {
    super(props);
    this.onEditButton = this.onEditButton.bind(this);
  }

  onEditButton(e) {
    const { id, onEditButton } = this.props;

    onEditButton(id);
    e.stopPropagation();
  }

  render() {
    const { name } = this.props;
    return (
      <li className="phase-list-item" onDoubleClick={this.onEditButton}>
        {name}
        <i className="fa fa-pencil-square-o" onClick={this.onEditButton} />
      </li>
    );
  }
}

Phase.propTypes = {
  onEditButton: PropTypes.func.isRequired,
};

export default Phase;
