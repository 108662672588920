import { handleActions } from "redux-actions";
import {
  didFetchCoreReport,
  didResetReport,
  didUpdateEndDate,
} from "./actions";

export function report(state) {
  return state.ui.CoreReports.reports;
}

export function selected(state) {
  if (state.router.params.reportType === "all-customers") {
    return "All Customers";
  } else if (state.router.params.reportType === "predefined-customers") {
    return "Predefined Customers";
  }
  return "Predefined Customers";
}

export function endDate(state) {
  return state.ui.CoreReports.endDate;
}

export default handleActions(
  {
    [didUpdateEndDate]: (state, action) => ({
      ...state,
      endDate: action.payload,
    }),
    [didFetchCoreReport]: (state, action) => ({
      ...state,
      reports: action.payload.data,
    }),
    [didResetReport]: (state) => ({ ...state, reports: null }),
  },
  {
    reports: null,
    selected: null,
  }
);
