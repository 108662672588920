import { createAction } from "redux-actions";

const prefixed = (val) => `MANAGE/CLINICIAN::${val}`;

export const requestCustomers = createAction(prefixed("REQUEST_CUSTOMERS"));
export const didFetchCustomers = createAction(
  prefixed("DID_FETCH_CUSTOMERS"),
  (customers) => customers
);

export const requestPrograms = createAction(prefixed("REQUEST_PROGRAMS"));
export const didFetchPrograms = createAction(prefixed("DID_FETCH_PROGRAMS"));

export const requestClinician = createAction(prefixed("REQUEST_CLINICIAN"));
export const didFetchClinician = createAction(prefixed("DID_FETCH_CLINICIAN"));

export const createClinician = createAction(prefixed("CREATE_CLINICIAN"));
export const didCreateClinician = createAction(
  prefixed("DID_CREATE_CLINICIAN")
);

export const updateClinician = createAction(prefixed("UPDATE_CLINICIAN"));
export const didUpdateClinician = createAction(
  prefixed("DID_UPDATE_CLINICIAN")
);

export const clearClinicianData = createAction(
  prefixed("CLEAR_CLINICIAN_DATA")
);
