import { handleActions } from "redux-actions";
import { get, set } from "lodash";

import reduceReducers from "reduce-reducers";

import surgeryDateReducer from "./components/Actions/components/UpdateSurgeryDate/reducer";
import birthDateReducer from "./components/Actions/components/UpdateBirthDate/reducer";
import procedureTypeReducer from "./components/Actions/components/UpdateProcedureType/reducer";
import surgeryComplicationsReducer from "./components/Actions/components/UpdateSurgeryComplications/reducer";
import nurseReducer from "./components/Actions/components/UpdateAssignedTo/reducer";
import releaseReducer from "./components/Actions/components/Release/reducer";
import backgroundReducer from "./../../components/UserView/components/Forms/UpdateBackground/reducer";
import cardInteractionsReducer from "./../../components/UserView/components/CardInteractions/reducer";
import addCareTeamMemberReducer from "./components/Actions/components/AddCareTeamMember/reducer";
import updateCareTeamMemberReducer from "./components/Actions/components/UpdateCareTeamMember/reducer";
import removeCareTeamMemberReducer from "./components/Actions/components/RemoveCareTeamMember/reducer";
import updateOwnerReducer from "./components/Actions/components/UpdateOwner/reducer";
import transitionProgramReducer from "./components/Actions/components/TransitionProgram/reducer";
import changePartnerReducer from "./components/Actions/components/ChangePartner/reducer";

import {
  didFetchPeer,
  didFetchPeerProfile,
  didFetchCardInteractions,
  didFetchPeerDeviceInfo,
  didFetchPeerHazardPictures,
  didFetchCareTeam,
  didFetchOwner,
  didFetchPeerHazardImage,
  didFetchAllHazardImages,
  didFetchPeerProgramParts,
  didFetchWorker,
  didFetchWorkerEngagement,
} from "./sagas";
import { didUpdateSurgeryDate } from "./components/Actions/components/UpdateSurgeryDate/actions";
import { didUpdateBirthDate } from "./components/Actions/components/UpdateBirthDate/actions";
import { didUpdateProcedureType } from "./components/Actions/components/UpdateProcedureType/actions";
import { didGetEvents } from "../actions";
import { didUpdateEmail } from "./components/Actions/components/UpdateEmail/actions";
import { didUpdatePhone } from "./components/Actions/components/UpdatePhone/actions";
import { didUpdateName } from "../../components/UserView/components/Forms/UpdateName/actions";
import { didUpdateBackground } from "../../components/UserView/components/Forms/UpdateBackground/actions";
import moment from "moment";

export const selectedPeerId = (state) => state.router.params.id;
export const peerInfo = (state, peerId) =>
  get(state, `ui.PeerView.${peerId}.info`);
export const peerProfile = (state, peerId) =>
  get(state, `ui.PeerView.${peerId}.profile`);
export const careTeam = (state, peerId) =>
  get(state, `ui.PeerView.${peerId}.care_team`);
export const owner = (state, peerId) =>
  get(state, `ui.PeerView.${peerId}.owner`);
export const peerProgramParts = (state, peerId) =>
  get(state, `ui.PeerView.${peerId}.program_parts`);
export const peerCardInteractions = (state, peerId) =>
  get(state, `ui.PeerView.${peerId}.card_interactions`);
export const peerStates = (state, peerId) =>
  get(state, `ui.PeerView.${peerId}.info.states`);
export const peerDeviceInfo = (state, peerId) =>
  get(state, `ui.PeerView.${peerId}.device_info`);
export const peerHazardScore = (state, peerId) =>
  get(state, `ui.PeerView.${peerId}.hazard_score`);
export const peerHazardPictures = (state, peerId) =>
  get(state, `ui.PeerView.${peerId}.hazard_pictures`);
export const peerHazardImages = (state, peerId) =>
  get(state, `ui.PeerView.${peerId}.hazard_images`);
export const areHazardImagesFetched = (state, peerId) =>
  get(state, `ui.PeerView.${peerId}.areHazardImagesFetched`);
export const workerInfo = (state, workerId) =>
  get(state, `ui.PeerView.${workerId}.info`);
export const workerEngagementScore = (state, workerId) =>
  get(state, `ui.PeerView.${workerId}.engagement_score`);

export const requiresSurgeryDate = (state, peerId) => {
  const profile = peerInfo(state, peerId);
  return profile.program_kind === "rehab";
};

export const requiresBirthDate = (state, peerId) => {
  const profile = peerInfo(state, peerId);
  return profile.program_kind === "rehab";
};

export const intoHazardDetails = (state) =>
  state.router.pathname.includes("hazardDetails");

// Todo: Abstract it for no duplication
const reducer = handleActions(
  {
    [didFetchPeer]: (state, action) => {
      const newState = { ...state };
      const peerId = action.payload.id;
      set(newState, `${peerId}.info`, action.payload);
      return newState;
    },
    [didFetchWorker]: (state, action) => {
      const newState = { ...state };
      const workerId = action.payload.id;
      set(newState, `${workerId}.info`, mapWorkerToPeerObject(action.payload));
      return newState;
    },
    [didFetchWorkerEngagement]: (state, action) => {
      const newState = { ...state };
      const workerId = action.payload.id;
      set(newState, `${workerId}.engagement_score`, action.payload.score);
      return newState;
    },
    [didFetchPeerProfile]: (state, action) => {
      const newState = { ...state };
      const peerId = action.payload.id;
      set(newState, `${peerId}.profile`, action.payload.profile);
      return newState;
    },
    [didFetchCardInteractions]: (state, action) => {
      const newState = { ...state };
      const peerId = action.payload.id;
      set(newState, `${peerId}.card_interactions`, action.payload.interactions);
      return newState;
    },
    [didFetchPeerDeviceInfo]: (state, action) => {
      const newState = { ...state };
      const peerId = action.payload.length && action.payload[0].peer_id;
      set(newState, `${peerId}.device_info`, action.payload[0]);
      return newState;
    },
    [didFetchPeerHazardPictures]: (state, action) => {
      const newState = { ...state };
      if (!action.payload.length) return newState;
      const peerId = action.payload.length && action.payload[0].header.user_id;
      const allHazardScores = action.payload
        .map((day) => day.body.result.hazard_score)
        .reverse();
      const allMeasurements = action.payload.map(
        (day) => day.body.measurements
      );
      const allfilteredMeasurements = allMeasurements.reverse();
      set(newState, `${peerId}.hazard_score`, allHazardScores);
      set(newState, `${peerId}.hazard_pictures`, allfilteredMeasurements);
      return newState;
    },
    [didFetchPeerHazardImage]: (state, action) => {
      const newState = { ...state };
      if (!action.payload) {
        return newState;
      }
      const peerId = action.payload.peerId;
      set(
        newState,
        `${peerId}.hazard_images.${action.payload.picture.file}`,
        action.payload.data
      );
      return newState;
    },
    [didFetchAllHazardImages]: (state, action) => {
      const newState = { ...state };
      const peerId = action.payload;
      set(newState, `${peerId}.areHazardImagesFetched`, true);
      return newState;
    },
    [didFetchCareTeam]: (state, action) => {
      const newState = { ...state };
      const peerId = action.payload.id;
      set(newState, `${peerId}.care_team`, action.payload.members);
      return newState;
    },
    [didFetchPeerProgramParts]: (state, action) => {
      const newState = { ...state };
      const peerId = action.payload.id;
      set(newState, `${peerId}.program_parts`, action.payload.data);
      return newState;
    },
    [didFetchOwner]: (state, action) => {
      const newState = { ...state };
      const peerId = action.payload.id;
      set(newState, `${peerId}.owner`, action.payload.owner);
      return newState;
    },
    [didGetEvents]: (state, action) => {
      const newState = { ...state };
      const peerId = action.payload.id;
      set(newState, `${peerId}.events`, action.payload.events);
      return newState;
    },
    [didUpdateSurgeryDate]: (state, action) => {
      const { peerId, info } = action.payload;
      const peer = state[peerId];
      const surgery_date = info.peer.intake_data.surgery_date;
      const { program_start_date, program_end_date } = info.peer;
      // This info used to come from the old /update-surgery endpoint (indluded also current_day, start_date but those are not changed)
      peer.profile.countdown = {
        ...peer.profile.countdown,
        total_nr_days: programDays(program_start_date, program_end_date),
        end_date: program_end_date,
        surgery_date,
      };

      peer.info = Object.assign({}, peer.info, {
        end_date: surgery_date, // This equals surgery date.... old api legacy
        surgery_date,
        program_end_date,
      });

      state[peerId] = peer;
      return Object.assign({}, state);
    },
    [didUpdateBirthDate]: (state, action) => {
      const { peerId, info } = action.payload;
      const peer = Object.assign({}, state[peerId].info);
      peer.intake_data.date_of_birth = info;
      state[peerId].info = peer;
      return Object.assign({}, state);
    },
    [didUpdateProcedureType]: (state, action) => {
      const { peerId, procedureType } = action.payload;
      const info = Object.assign({}, state[peerId].info);
      info.intake_data.procedure_type = procedureType;
      state[peerId].info = info;
      return Object.assign({}, state);
    },
    [didUpdateEmail]: (state, action) => {
      const { peerId, email } = action.payload;
      const info = Object.assign({}, state[peerId].info);
      info.about.email = email;
      state[peerId].info = info;
      return Object.assign({}, state);
    },
    [didUpdateName]: (state, action) => {
      const { peerId, fullname } = action.payload;
      const info = Object.assign({}, state[peerId].info);
      info.about.name = fullname;
      state[peerId].info = info;
      return Object.assign({}, state);
    },
    [didUpdatePhone]: (state, action) => {
      const { peerId, phone } = action.payload;
      const info = Object.assign({}, state[peerId].info);
      info.about.phone = phone;
      state[peerId].info = info;
      return Object.assign({}, state);
    },
    [didUpdateBackground]: (state, action) => {
      const { peerId, birthdate, gender, weight, feet, inches } =
        action.payload;
      const info = Object.assign({}, state[peerId].info);
      info.intake_data.weight = weight;
      info.intake_data.date_of_birth = birthdate;
      info.intake_data.gender = gender;
      info.intake_data.height = {};
      info.intake_data.height.feet = feet;
      info.intake_data.height.inches = inches;
      state[peerId].info = info;
      return Object.assign({}, state);
    },
  },
  {}
);

export default reduceReducers(
  reducer,
  surgeryDateReducer,
  backgroundReducer,
  birthDateReducer,
  procedureTypeReducer,
  surgeryComplicationsReducer,
  nurseReducer,
  releaseReducer,
  cardInteractionsReducer,
  addCareTeamMemberReducer,
  updateCareTeamMemberReducer,
  removeCareTeamMemberReducer,
  transitionProgramReducer,
  changePartnerReducer,
  updateOwnerReducer
);

function programDays(start_date, end_date) {
  if (end_date === 0) {
    return Number.POSITIVE_INFINITY;
  }
  const beginning = moment(parseInt(start_date)).startOf("day");
  const end = moment(parseInt(end_date)).endOf("day");
  return Math.abs(end.diff(beginning, "days"));
}

// Converts incoming worker object into standard format for peer.
const mapWorkerToPeerObject = (worker) => {
  return {
    about: {
      email: worker.email ? worker.email : "",
      name:
        (worker.firstName ? worker.firstName : "") +
        " " +
        (worker.lastName ? worker.lastName : ""),
      lang: "",
      phone: "",
      registration_code: "",
      first_name: worker.firstName ? worker.firstName : "",
      last_name: worker.lastName ? worker.lastName : "",
      username: "",
    },
    formal_program_name: worker.program,
    created_at: worker.createdAt,
  };
};
