import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import GroupSelector from "./../Stats/components/GroupSelector";

import CardChart from "./components/CardChart";
import Metrics from "./../../../shared/components/Metrics";
import { sum } from "../../../shared/components/Charts/Number/aggregators";

import BreadCrumbBar from "./../../../components/BreadCrumbBar";

import "./Cards.css";

import Number from "./../../../shared/components/Charts/Number";
import FetchedMetrics from "./../../../shared/components/FetchedMetrics";

// import { metricQueryShape } from '../../../shared/components/Metrics/Metrics'
// Can't seem to import it
export const metricQueryShape = {
  type: PropTypes.string.isRequired,
  frame: PropTypes.string.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  group: PropTypes.object,
};

class Cards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: "",
    };

    this._onGroupsChange = this._onGroupsChange.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this._filterChange = this._filterChange.bind(this);
  }

  renderContent() {
    const { query } = this.props;
    const sort = query.sort;
    const { filter } = this.state;

    return (
      <Metrics query={[query]}>
        <CardChart sortBy={sort} filter={filter} />
      </Metrics>
    );
  }

  // Todo style-ize
  renderBreadcrumbBar() {
    const { showFilter, query } = this.props;
    const sort = query.sort;
    const columnSize = showFilter ? "col-md-4" : "col-md-6";

    const { filter } = this.state;

    return (
      <BreadCrumbBar>
        <div className="container cards-charts-filter-container">
          {showFilter ? (
            <div className="form-group col-md-4">
              <label htmlFor="groups">Group</label>
              <GroupSelector
                onChange={this._onGroupsChange}
                selectedGroups={query.group}
                multiple={false}
                id="group"
              />
            </div>
          ) : null}
          <div className={classNames("form-group", columnSize)}>
            <label htmlFor="sort">Sort</label>
            <select
              onChange={this._onSortChange}
              value={sort}
              id="sort"
              className="form-control"
            >
              <option value="nr_assigned">Number Assigned</option>
              <option value="nr_completed">Number Completed</option>
              <option value="nr_read">Number Read</option>
              <option value="completion_ratio">Completion Ratio</option>
              <option value="read_ratio">Read Ratio</option>
              <option value="title">Title</option>
            </select>
          </div>
          <div className={classNames("form-group", columnSize)}>
            <label htmlFor="filter">Filter</label>
            <input
              type="text"
              value={filter}
              onChange={this._filterChange}
              className="form-control"
              style={{ marginTop: 0, padding: "2px 10px", height: "auto" }}
            />
          </div>
        </div>
      </BreadCrumbBar>
    );
  }

  renderSidebar() {
    return (
      <div>
        <FetchedMetrics types="engagement_card_completion">
          <h1>Total</h1>
          <Number
            className="smaller-number"
            metric="engagement_card_completion"
            dataPath="data.*.nr_assigned"
            label="Assigned"
            aggregator={sum}
          />
          <Number
            className="smaller-number"
            metric="engagement_card_completion"
            dataPath="data.*.nr_completed"
            label="Completed"
            aggregator={sum}
          />
          <Number
            className="smaller-number"
            metric="engagement_card_completion"
            dataPath="data.*.nr_read"
            label="Read"
            aggregator={sum}
          />
        </FetchedMetrics>
      </div>
    );
  }
  render() {
    return (
      <div className="page-container with-sidebar">
        <div className="sidebar">{this.renderSidebar()}</div>
        <div className="page-content">
          {this.renderBreadcrumbBar()}
          {this.renderContent()}
        </div>
      </div>
    );
  }

  // Todo: Move this outside of this, and make it so it can be used in Metric and BuildYourOwn too
  _updateQuery(updates) {
    this.props.gotoQuery(Object.assign({}, this.props.query, updates));
  }

  _onGroupsChange(newGroups) {
    this._updateQuery({ group: newGroups });
  }

  _onSortChange(event) {
    this._updateQuery({ sort: event.target.value });
  }

  _filterChange(event) {
    this.setState({ filter: event.target.value });
  }
}

Cards.propTypes = {
  query: PropTypes.shape(metricQueryShape).isRequired,
  gotoQuery: PropTypes.func.isRequired,
  showFilter: PropTypes.bool.isRequired,
};

export default Cards;
