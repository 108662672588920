import { handleActions } from "redux-actions";
import {
  setUpdateSurgeryComplicationsMessage,
  didUpdateSurgeryComplications,
} from "./actions";

export const surgeryComplicationsMessage = (state) =>
  state.ui.PeerView.surgeryComplicationsMessage;
export const currentSurgeryComplications = (state, peerId) =>
  state.ui.PeerView[peerId].info.has_complications;

export default handleActions(
  {
    [setUpdateSurgeryComplicationsMessage]: (state, action) => ({
      ...state,
      surgeryComplicationsMessage: action.payload,
    }),
    [didUpdateSurgeryComplications]: (state, action) => {
      const { peerId, hasComplications } = action.payload;
      const info = Object.assign({}, state[peerId].info);
      info.has_complications = hasComplications;
      state[peerId].info = info;
      return Object.assign({}, state);
    },
  },
  {
    surgeryComplicationsMessage: null,
  }
);
