import React from "react";

import "./Blocked.css";

function Blocked(props) {
  let { isDeniedPage } = props;
  return (
    <div className="blocked-container">
      <div className="message">
        <img src="https://peerwell-blog-images.s3-us-west-1.amazonaws.com/police+man.png" />
        {isDeniedPage === true ? (
          <div>
            <h3>You don’t have permission to view this page</h3>
            <p>
              If you believe this is incorrect, contact{" "}
              <a href="mailto:digitalrecovery@bardavon.com">
                digitalrecovery@bardavon.com
              </a>{" "}
              for assistance.
            </p>
          </div>
        ) : (
          <div>
            <h3>Your account has been blocked</h3>
            <p>
              Please contact{" "}
              <a href="mailto:digitalrecovery@bardavon.com">
                digitalrecovery@bardavon.com
              </a>{" "}
              for assistance.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Blocked;
