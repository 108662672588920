import InvitePortal from "./InvitePortal.js";
import { authenticationOfTypeRequired } from "../../shared/routes/Authentication/Login/sagas";
import reducer from "./reducer";
import watchInvitePortalSagas from "./sagas";

export default {
  "/invite-portal(/:clinician)": {
    component: InvitePortal,
    reducer: {
      ui: {
        InvitePortal: reducer,
      },
    },
    saga: watchInvitePortalSagas,
    title: "Invite Portal",
    *onEnter() {
      yield authenticationOfTypeRequired(["admin", "demo", "staff"]);
    },
  },
};
