import React from "react";
import PropTypes from "prop-types";

import Select from "react-select";
import "react-select/dist/react-select.css";

import { flatten, castArray } from "lodash";

import { mapObjectToArray } from "../../../../../shared/utils/mapObject";

class GroupSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: props.selectedGroups,
    };

    this._onSelectChange = this._onSelectChange.bind(this);
  }

  componentDidMount() {
    const { requestReportableCodes } = this.props;
    requestReportableCodes();
  }

  // Converts our groups query into a format the multi select understands
  _optionsFromGroups(groups) {
    const formatted = mapObjectToArray(groups, (key, value) => {
      if (Array.isArray(value)) {
        return value.map((v) => ({ value: `${key}.${v}`, label: v }));
      }

      return { label: key, value: key };
    });

    return flatten(formatted);
  }

  _onSelectChange(values) {
    const groups = this._groupsFromOptions(castArray(values));
    this.setState({ groups });
    this.props.onChange(groups);
  }

  // Converts the options of the multi select into a format we use
  _groupsFromOptions(options) {
    // Convert the values to our group query
    const groups = {};

    for (const option of options) {
      const value = option.value;

      if (value.indexOf(".") !== -1) {
        const splitted = value.split(".");
        const key = splitted[0];
        const groupValue = splitted[1];

        if (!groups[key]) {
          groups[key] = [];
        }
        groups[key].push(groupValue);
      } else {
        groups[value] = true;
      }
    }

    return groups;
  }

  render() {
    const { groups, multiple, id } = this.props;

    const selectedValues = this._optionsFromGroups(this.state.groups) || [];
    const selected = multiple ? selectedValues : selectedValues[0];

    return (
      <Select
        name="groups_to_select"
        joinValues
        multi={multiple}
        value={selected}
        clearable={false}
        options={this._optionsFromGroups(groups)}
        onChange={this._onSelectChange}
        id={id}
      />
    );
  }
}

GroupSelector.propTypes = {
  groups: PropTypes.any.isRequired,
  selectedGroups: PropTypes.any,
  onChange: PropTypes.func,
  requestReportableCodes: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
};

GroupSelector.defaultProps = {
  onChange: () => null,
  multiple: true,
  className: null,
  id: null,
};

export default GroupSelector;
