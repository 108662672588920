import { connect } from "react-redux";
import UpdateProcedureType from "./UpdateProcedureType.jsx";

import {
  availableProcedureTypes,
  currentProcedureType,
  procedureTypeMessage,
} from "./reducer";
import {
  updateProcedureType,
  requestProcedureTypes,
  setUpdateProcedureTypeMessage,
} from "./actions";

function mapStateToProps(state, { userId }) {
  return {
    currentType: currentProcedureType(state, userId),
    possibleTypes: availableProcedureTypes(state, userId),
    message: procedureTypeMessage(state),
  };
}

function mapDispatchToProps(dispatch, { userId }) {
  return {
    onChange: (event) => {
      const value = event.target.value;
      dispatch(updateProcedureType(userId, value));
    },
    getPossibleTypes: () => dispatch(requestProcedureTypes(userId)),
    clearMessage: () => dispatch(setUpdateProcedureTypeMessage(null)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateProcedureType);
