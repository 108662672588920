import { delay } from "redux-saga";
import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../../../../../../../shared/app/requestErrorHandler";
import { fetchPeer as fetchPeerAPI } from "../../../../api";
import { didFetchPeer } from "../../../../sagas";
import {
  didUpdateSurgeryComplications,
  setUpdateSurgeryComplicationsMessage,
  updateSurgeryComplications,
} from "./actions";
import { updateSurgeryComplications as updateSurgeryComplicationsAPI } from "./api";

function* updateSurgery(action) {
  yield put(setUpdateSurgeryComplicationsMessage(null));
  const { peerId, hasComplications } = action.payload;
  try {
    if (hasComplications === "true")
      yield updateSurgeryComplicationsAPI(peerId, true);
    if (hasComplications === "false")
      yield updateSurgeryComplicationsAPI(peerId, false);
    yield put(
      setUpdateSurgeryComplicationsMessage(
        "Surgery complications have been updated"
      )
    );
    yield put(didUpdateSurgeryComplications(peerId, hasComplications));

    // Fetch again peer information in order to show or not surgery complications
    const res = yield fetchPeerAPI(peerId);
    yield put(didFetchPeer(res.data));

    // Remove update message after 2.5s
    yield delay(2500);
    yield put(setUpdateSurgeryComplicationsMessage(null));
  } catch (e) {
    yield handleApiError(e);
  }
}

export default function* watchUpdateSurgeryComplicationsSaga() {
  yield all([takeLatest(updateSurgeryComplications, updateSurgery)]);
}
