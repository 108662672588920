import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import moment from "moment";
import { isEqual } from "lodash";
import { Icon } from "react-fa";

import DateInputField from "./../../../../../../../../shared/components/FormFields/DateInputField";
import RadioList from "./../../../../../../../../shared/components/FormFields/RadioList";
import { validateForm } from "../../../../../../../../shared/utils/form";

import "./Release.css";

const ReleaseTypeList = RadioList({
  full: "Full duty",
  partial: "Partial duty",
});

const rules = {
  date: {
    presence: true,
  },
  type: {
    presence: true,
  },
};

class Release extends React.Component {
  componentWillMount() {
    const { initialize, initialValues } = this.props;
    initialize(initialValues);
  }

  componentWillReceiveProps(nextProps) {
    const { initialValues, initialize } = this.props;
    if (!isEqual(nextProps.initialValues, initialValues)) {
      initialize(nextProps.initialValues);
    }
  }

  render() {
    const {
      onSubmit,
      handleSubmit,
      isValidForm,
      onClose,
      message,
      released,
      notification,
      cancelRelease,
    } = this.props;
    const maxDate = moment();

    return (
      <div className="release-form">
        <a role="button" onClick={onClose} className="close-button">
          <Icon name="times" />
        </a>
        <h1>{released ? "Release Status" : "Confirm release"}</h1>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          {(!released || message) && (
            <div>
              <Field
                name="date"
                component={DateInputField}
                required
                maxDate={maxDate}
              />
              <Field
                name="type"
                component={ReleaseTypeList}
                required
                horizontal
              />
            </div>
          )}

          <p className="notification">{notification}</p>

          {released && (
            <a className="undo-release" onClick={cancelRelease} role="button">
              Undo Release
            </a>
          )}

          {released ? (
            <btn className="btn" onClick={onClose}>
              Close
            </btn>
          ) : (
            <input
              type="submit"
              value="Release"
              className="btn btn-primary"
              disabled={!isValidForm}
            />
          )}
        </form>
      </div>
    );
  }
}

Release.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  message: PropTypes.string,
  pendingRelease: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  initialize: PropTypes.func.isRequired,
  isValidForm: PropTypes.bool.isRequired,
  released: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  notification: PropTypes.string.isRequired,
  cancelRelease: PropTypes.func.isRequired,
};

Release.defaultProps = {
  message: null,
  initialValues: null,
};

export default reduxForm({
  form: "release",
  validate: validateForm(rules),
})(Release);
