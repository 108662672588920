import React from "react";
import PropTypes from "prop-types";

import Row from "./components/Row";
import { extraFieldsShape } from "./proptypes";

function Table({ peers, extraFields }) {
  return (
    <div className="responsive-table">
      <table className="table users-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>E-mail</th>
            <th>Surgery Date</th>
            <th>Clinician</th>
            <th>Registration Code</th>
            <th>Procedure</th>
            <th>Hospital</th>
            <th>Inviter</th>
            <th>Patient Registered</th>
            {extraFields &&
              extraFields.map((field) => (
                <th key={field.fullPath}>{field.label}</th>
              ))}
          </tr>
        </thead>
        <tbody className="bold-first-column">
          {peers &&
            peers.map((peer) => (
              <Row key={peer.id} peer={peer} extraFields={extraFields} />
            ))}
        </tbody>
      </table>
    </div>
  );
}

Table.propTypes = {
  peers: PropTypes.arrayOf(PropTypes.object).isRequired,
  extraFields: PropTypes.arrayOf(PropTypes.shape(extraFieldsShape)),
};

Table.defaultProps = {
  extraFields: [],
};

export default Table;
