import React from "react";
import classNames from "classnames";

function SimpleField({
  input,
  label,
  type,
  className,
  meta: { touched, error, warning, pristine },
  disabled,
}) {
  const shouldDisplayError = !pristine || touched;
  const fieldClass = classNames("form-control", className, {
    "has-error": shouldDisplayError && error,
  });

  return (
    <div>
      <input
        {...input}
        className={fieldClass}
        type={type}
        disabled={disabled}
      />
      {shouldDisplayError && error && (
        <span className="error-message">{error}</span>
      )}
    </div>
  );
}

export default SimpleField;

// Todo: Don't know what prop types redux form expects
