import { handleActions } from "redux-actions";
import { setUpdateProcedureTypeMessage, didGetProcedureTypes } from "./actions";

export const procedureTypeMessage = (state) =>
  state.ui.PeerView.procedureTypeMessage;
export const availableProcedureTypes = (state, peerId) =>
  state.ui.PeerView[peerId].availableProcedureTypes;
export const currentProcedureType = (state, peerId) =>
  state.ui.PeerView[peerId].info.intake_data.procedure_type;

export default handleActions(
  {
    [setUpdateProcedureTypeMessage]: (state, action) => ({
      ...state,
      procedureTypeMessage: action.payload,
    }),
    [didGetProcedureTypes]: (state, action) => {
      const { peerId, procedureTypes } = action.payload;
      state[peerId].availableProcedureTypes = procedureTypes;
      return Object.assign({}, state);
    },
  },
  {
    procedureTypeMessage: null,
  }
);
