import DashboardLayout from "./../layouts/DashboardLayout";

import MetricRoute from "./Metric/index";
import UserRoute from "./User";
import ReportRoute from "./Reports";
import CardsRoute from "./Cards";
import InvitePortalRoute from "./InvitePortal";
import ModulesRoute from "./Modules";
import KPIStatsRoute from "./KPIStats";
import ManageRoute from "./Manage";
import PhasesRoute from "./Phases";
import ProgramsRoute from "./Programs";
import TeamRoute from "./Team";
import PatientsRoute from "./Patients";
import WorkersRoute from "./Workers/index.ts";
import CoachesRoute from "./Coaches";

export default Object.assign(
  {},
  MetricRoute,
  InvitePortalRoute,
  ModulesRoute,
  KPIStatsRoute,
  UserRoute,
  ReportRoute,
  CardsRoute,
  ManageRoute,
  PhasesRoute,
  ProgramsRoute,
  TeamRoute,
  PatientsRoute,
  WorkersRoute,
  CoachesRoute
);

export const AppLayout = DashboardLayout;

export const appTitle = "PeerWell - Dashboard";
