import { connect } from "react-redux";

import { fleetingFormMeta } from "../../../../../shared/utils/form";
import { fieldValueInitialValues } from "../../reducer";

import UpdateFieldValueForm from "./UpdateFieldValueForm.jsx";

function mapStateToProps(
  state,
  { onSubmit, onCancel, customerField, customerFieldValue }
) {
  return {
    initialValues: fieldValueInitialValues(state),
    customerField,
    customerFieldValue,
    onSubmit,
    onCancel,
    message: fleetingFormMeta(state, "manageCustomer").message,
  };
}

export default connect(mapStateToProps)(UpdateFieldValueForm);
