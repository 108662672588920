import { connect } from "react-redux";

import UserSpecific from "./UserSpecific.jsx";

import { userType } from "../../routes/Authentication/Login/reducer";

function mapStateToProps(state) {
  return {
    authenticatedUserType: userType(state),
  };
}

export default connect(mapStateToProps)(UserSpecific);
