import { takeLatest, put } from "redux-saga/effects";

import {
  updateSurgeryDate,
  didUpdateSurgeryDate,
  setSurgeryDateMessage,
} from "./actions";
import { updateSurgeryDate as updateSurgeryDateAPI } from "./api";

function* update(action) {
  yield put(setSurgeryDateMessage(null));

  const payload = action.payload;
  try {
    const response = yield updateSurgeryDateAPI(payload.peerId, payload.date);
    yield put(setSurgeryDateMessage("Surgery date has been updated"));
    yield put(didUpdateSurgeryDate(payload.peerId, response.data));
  } catch (e) {
    const error = e.response.data.error.surgery_date[0];
    const shouldBeInPast = error.indexOf("less than");

    const message = shouldBeInPast
      ? "Must be in the past"
      : "Must be in the future";
    yield put(setSurgeryDateMessage(message));
  }
}

export default function* watchUpdateSurgeryDate() {
  yield takeLatest(updateSurgeryDate, update);
}
