import { useEffect, useState } from "react";
import { fetchCard, fetchPillars } from "../../api";
import LivePreview from "../Edit/components/LivePreview";
import { fetchAssessments } from "./api";
import CardForm from "./CardForm";

export default ({ cardId, onSave }) => {
  const [card, setCard] = useState();
  const [pillars, setPillars] = useState();
  const [assessments, setAssessments] = useState();

  useEffect(async () => {
    if (cardId) {
      setCard((await fetchCard(cardId)).data);
    }
    setPillars((await fetchPillars()).data);
    setAssessments((await fetchAssessments()).data);
  }, []);

  const pillarOptions = pillars?.map(({ code, name }) => ({
    value: code,
    label: name,
  }));
  const assessmentOptions = assessments?.map(({ code, name }) => ({
    value: code,
    label: name,
  }));

  return (
    <div className="edit-form">
      <div className="col-md-9 edit-col">
        <CardForm
          card={card}
          onSave={onSave}
          pillarOptions={pillarOptions}
          assessmentOptions={assessmentOptions}
        />
      </div>
      {card && (
        <div className="col-md-3 preview-col">
          <LivePreview card={card} />
        </div>
      )}
    </div>
  );
};
