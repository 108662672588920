import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Markdown from "./../../../../shared/components/Markdown";

import "./Preview.css";

function Preview({ content, inModal }) {
  return (
    <div
      className={classNames("preview", {
        scaledPreview: !inModal,
        scaledPreviewInModal: inModal,
      })}
    >
      <div className="divider" />
      <div className="content">
        <Markdown source={content} />
      </div>
    </div>
  );
}

Preview.propTypes = {
  content: PropTypes.string,
  inModal: PropTypes.bool,
};

Preview.defaultProps = {
  content: null,
  inModal: false,
};

export default Preview;
