import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";

function DetransitionPeer({ onSubmit, handleSubmit, message }) {
  return (
    <div>
      <h1>Are you sure you want to move this patient back to Prehab?</h1>
      <h4>Notice: You cannot undo this!</h4>
      <p>
        Once you transition a patient back to PreHab, check and adjust his/her
        surgery date
      </p>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <input
          type="submit"
          className="btn btn-primary"
          value="Back to Prehab"
        />
        {message ? <p className="form-message">{message}</p> : null}
      </form>
    </div>
  );
}

DetransitionPeer.propTypes = {
  peerId: PropTypes.string.isRequired,
  message: PropTypes.string,

  // Automatically added
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

DetransitionPeer.defaultProps = {
  message: null,
};

export default reduxForm({
  form: "detransitionPeer",
})(DetransitionPeer);
