import { connect } from "react-redux";

import Card from "./Card.jsx";
import { isSelectedForPreview } from "./reducer";

import { editCard, previewCard } from "./actions";
import { userType } from "../../../../../../shared/routes/Authentication/Login/reducer";

function mapStateToProps(state, { id }) {
  return {
    isSelected: isSelectedForPreview(state, id),
    userType: userType(state),
  };
}

const dispatch = {
  onEditButton: (id) => editCard(id),
  onPreviewButton: (id) => previewCard(id),
};

export default connect(mapStateToProps, dispatch)(Card);
