import { flatten, get } from "lodash";
import { formValueSelector, getFormValues } from "redux-form";
import moment from "moment";

import { mapObjectToArray } from "../../../../shared/utils/mapObject";
import { invite, allPartnerUsers } from "../../reducer";

import {
  userType,
  userCustomerId,
} from "../../../../shared/routes/Authentication/Login/reducer";

import { LOCALES } from "./";

export function initialValues(state) {
  const user = userType(state);
  const id = get(state, "userInfo.userable_id");

  const sharedDefaults = {
    language: "en",
  };

  if (["owner", "partner_admin"].includes(user)) {
    return { owner: id, ...sharedDefaults };
  } else if (user === "admin") {
    return { consent: true, ...sharedDefaults };
  }
  return sharedDefaults;
}

export function ownerFullname(state) {
  const user = userType(state);

  if (user === "owner") {
    return get(state, "userInfo.fullname");
  }
  return null;
}

export function selectedSurgeryDate(state) {
  return formValueSelector("patientInvite")(state, "surgery_date");
}

export function selectedProgram(state) {
  return !!formValueSelector("patientInvite")(state, "program");
}

export function selectedProgramType(state) {
  const surgeryDate = selectedSurgeryDate(state);

  if (!surgeryDate || moment().isBefore(surgeryDate)) {
    return "prehab";
  }
  return "rehab";
}

export function isSurgeryProgram(state) {
  const program = selectedProgram(state);

  if (!program) return;

  return program.kind === "prehab" || program.kind === "rehab";
}

function IS_AUSTRALIAN_PARTNER(state) {
  // TODO: hardcode Australian partner's customer id, until API provides a way of retrieving customer's locale
  return userCustomerId(state) === "5f7e11b829f78836d039613c"; // Shell Harbour's customer_id in production
}

export function geographyData(state) {
  const isAustralianPartner = IS_AUSTRALIAN_PARTNER(state);

  return isAustralianPartner ? LOCALES.australia : LOCALES.unitedStates;
}

// all caps to denote a hard-coded constant. this is a temporary, hard-coded hack to accommodate Shell Harbour, our onboarding partner from australia. in the near future, api will send us the partner's country so this can be done dynamically
export function country(state) {
  const isAustralianPartner = IS_AUSTRALIAN_PARTNER(state);

  return isAustralianPartner ? "AU" : "US";
}

export function ownerFormData(state) {
  const users = allPartnerUsers(state);

  if (!users) return null;

  return users
    .map(({ fullname, id }) => ({ label: fullname, value: id }))
    .filter((u) => !!u.label)
    .sort((a, b) => a.label.localeCompare(b.label));
}

export function extraFields(state) {
  const fields = get(state, "ui.Patients.invite.programs[0].extra_fields");

  if (!fields) return null;

  return fields.filter((field) => field.for === "patient");
}

export function payerFormData(state) {
  const externalFields = extraFields(state);

  if (!externalFields) return null;

  const payerSelect = externalFields.find(({ name }) => name === "payer");

  if (!payerSelect) return null;

  return payerSelect.values.filter(({ value }) => value !== null);
}

export function employerFormData(state) {
  const externalFields = extraFields(state);

  if (!externalFields) return null;

  const employerSelect = externalFields.find(({ name }) => name === "employer");

  if (!employerSelect) return null;

  return employerSelect.values.filter(({ value }) => value !== null);
}

export function allRequiredFieldsFilledOut(state) {
  const hasPartnerUsers = !!allPartnerUsers(state);
  const externalFields = extraFields(state);
  const user = userType(state);

  if (!externalFields) return false;

  const requiredExternalFields = externalFields
    .filter((field) => field.presence === true && field.for === "patient")
    .map(({ name }) => name);

  const formState = getFormValues("patientInvite")(state) || {};
  const {
    external_service = {},
    peer_fullname,
    email_address,
    program,
    surgery_date,
    owner,
    consent,
    mobile_number,
  } = formState;

  const staticFieldsFilledOut =
    peer_fullname &&
    (email_address || mobile_number) &&
    (consent || user === "admin");

  const externalFieldsFilledOut = requiredExternalFields.reduce(
    (allFilledOut, currentField) =>
      allFilledOut && !!external_service[currentField],
    true
  );

  // note: program code that includes the string 'back-pain' is the only non-surgery program at the moment, but there will be others (unfortunately has to be hard-coded from frontend for now)
  const nonSurgeryProgramSelected =
    program &&
    !isSurgeryProgram(state) &&
    (surgery_date === null || surgery_date === undefined); // cant do !surgery_date since '0' means no date is selected and that is a thing
  const surgeryProgramSelected =
    program && (surgery_date || surgery_date === 0);
  const validProgramAndSurgeryDateCombo =
    nonSurgeryProgramSelected || surgeryProgramSelected;

  // first condition means it's not applicable since the customer type doesn't have owners, second condition means an owner is selected
  const ownerFieldFilledOutIfApplicable = hasPartnerUsers || owner;

  return (
    validProgramAndSurgeryDateCombo &&
    externalFieldsFilledOut &&
    staticFieldsFilledOut &&
    ownerFieldFilledOutIfApplicable
  );
}
