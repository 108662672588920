import React from "react";
import PropTypes from "prop-types";
import inflection from "inflection";
import { Link } from "redux-little-router";
import Icon from "react-fa";

import { find, filter, sortBy } from "lodash";

import "./FilterList.css";

class FilterList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
    };

    // Todo: debounce
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  onFieldChange(e) {
    const value = e.target.value;
    this.setState({ searchValue: value });
  }

  filteredValues() {
    const { values } = this.props;
    const { searchValue } = this.state;
    const filtered = filter(
      values,
      (v) => v.formatted_value.indexOf(searchValue) !== -1
    );
    const sorted = sortBy(filtered, (f) => f.formatted_value);
    return sorted;
  }

  renderList() {
    const filteredValues = this.filteredValues();
    const { type } = this.props;
    if (filteredValues.length === 0) {
      return null;
    }
    return (
      <ul className="filter-values-list">
        {filteredValues.map((value) => {
          const url = `/peers/overview/0/?filter[${type}]=${value.search_value}`;
          return (
            <li key={value.search_value}>
              <Link href={url}>{value.formatted_value}</Link>
            </li>
          );
        })}
      </ul>
    );
  }

  renderInput() {
    return (
      <input
        type="text"
        name="filter"
        placeholder="Search"
        onChange={this.onFieldChange}
      />
    );
  }

  renderButton() {
    const { selectedValue, type, values } = this.props;

    let title = inflection.humanize(type);
    let clearFilter = null;

    if (selectedValue) {
      const filterValue = find(values, (v) => v.search_value === selectedValue);
      if (filterValue) {
        title = filterValue.formatted_value;
        clearFilter = (
          <Link className="active" href="/peers/overview/0/">
            {title} <Icon name="times" />
          </Link>
        );
      }
    }

    if (clearFilter) {
      return <span>{clearFilter}</span>;
    }

    return (
      <span
        className="dropdown-toggle filter-dropdown-button"
        data-toggle="dropdown"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {title}
        <span className="caret" />
      </span>
    );
  }

  render() {
    return (
      <div className="filter-list">
        <div className="menu">
          {this.renderButton()}
          <div className="dropdown-menu">
            {this.renderInput()}
            {this.renderList()}
          </div>
        </div>
      </div>
    );
  }
}

FilterList.propTypes = {
  type: PropTypes.string.isRequired,
  // values: PropTypes.arrayOf({
  //   search_value: PropTypes.string.isRequired,
  //   formatted_value: PropTypes.string.isRequired
  // })
  values: PropTypes.array,
  selectedValue: PropTypes.string,
};

FilterList.defaultProps = {
  values: null,
  selectedValue: null,
};

export default FilterList;
