import moment from "moment";
import { createAction } from "redux-actions";
import { put, select, takeEvery } from "redux-saga/effects";
import { handleApiError } from "../../app/requestErrorHandler";
import { gotoQuery, query } from "../../routes/query";
import { metrics as metricsAPI } from "./api";

const prefixed = (val) => `Metric/Chart::${val}`;

export const requestMetricData = createAction(prefixed("requestMetricData"));
export const didFetchMetricData = createAction(
  prefixed("didFetchMetricData"),
  (metricInfo, data) => ({ metricInfo, data })
);

export function* setQueryDefaultsIfNeeded() {
  const currentQuery = yield select(query);

  // Checking if one is missing, if so, then just filling all defaults in
  if (!currentQuery.start || !currentQuery.group) {
    const newQuery = {
      start: moment().startOf("day").add(-30, "day").valueOf(),
      end: moment().endOf("day").valueOf(),
      frame: "day",
      group: { all_reportable_codes: true },
    };

    gotoQuery(newQuery);
  }
}

function* fetchMetricData(action) {
  try {
    const metrics = yield metricsAPI(action.payload);
    yield put(didFetchMetricData(action.payload, metrics.data));
  } catch (e) {
    yield handleApiError(e);
  }
}

export function* watchRequestMetricData() {
  yield yield takeEvery(requestMetricData, fetchMetricData);
}
