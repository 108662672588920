import React from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import { reduxForm, Field } from "redux-form";

import { validateForm } from "../../../../../../../shared/utils/form";

import FormGroupField from "./../../../../../../../shared/components/FormFields/FormGroupField";
import checkMark from "../../../../../../../assets/checkmark.svg";

const rules = {
  email: {
    presence: true,
    email: true,
  },
  repeatEmail: {
    presence: true,
    equality: "email",
  },
};

const defaultCheckMarkOptions = {
  loop: false,
  autoplay: true,
  animationData: checkMark,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function UpdateEmail({ onSubmit, handleSubmit, message }) {
  return (
    <div>
      <h1>Update E-mail</h1>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="email"
          component={FormGroupField}
          label="E-mail"
          required
        />
        <Field
          name="repeatEmail"
          component={FormGroupField}
          label="Repeat e-mail"
          required
        />
        {message ? (
          <div>
            <Lottie options={defaultCheckMarkOptions} height={50} width={50} />
            <p className="form-message">{message}</p>
          </div>
        ) : (
          <input type="submit" className="btn btn-primary" value="Update" />
        )}
      </form>
    </div>
  );
}

UpdateEmail.propTypes = {
  userId: PropTypes.string.isRequired,
  message: PropTypes.string,

  // Automatically added
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

UpdateEmail.defaultProps = {
  message: null,
};

export default reduxForm({
  form: "updateEmail",
  validate: validateForm(rules),
})(UpdateEmail);
