import { createAction } from "redux-actions";

const prefixed = (val) => `BookOfBusiness::${val}`;

export const requestCustomers = createAction(prefixed("REQUEST_CUSTOMERS"));
export const didFetchCustomers = createAction(
  prefixed("DID_FETCH_CUSTOMERS"),
  (customers) => customers
);

export const triggerBookOfBusiness = createAction(
  prefixed("TRIGGER_BOOK_OF_BUSINESS")
);
export const didTriggerBookOfBusiness = createAction(
  prefixed("DID_TRIGGER_BOOK_OF_BUSINESS"),
  (reportData) => reportData
);

export const requestResetReport = createAction(
  prefixed("REQUEST_RESET_REPORT")
);
export const didResetReport = createAction(prefixed("DID_RESET_REPORT"));

export const requestBookOfBusiness = createAction(
  prefixed("REQUEST_BOOK_OF_BUSINESS")
);
export const didFetchBookOfBusiness = createAction(
  prefixed("DID_REQUEST_BOOK_OF_BUSINESS"),
  (reportData) => reportData
);

export const requestUpdateStartDate = createAction(
  prefixed("REQUEST_UPDATE_START_DATE")
);
export const didUpdateStartDate = createAction(
  prefixed("DID_UPDATE_START_DATE"),
  (startDate) => startDate
);
export const requestUpdateEndDate = createAction(
  prefixed("REQUEST_UPDATE_END_DATE")
);
export const didUpdateEndDate = createAction(
  prefixed("DID_UPDATE_END_DATE"),
  (endDate) => endDate
);
