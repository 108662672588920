import { createAction } from "redux-actions";

const prefixed = (val) => `PROGRAMS::${val}`;

export const fetchProgram = createAction(
  prefixed("FETCH_PROGRAM"),
  (programId) => ({ programId })
);
export const didFetchProgram = createAction(prefixed("DID_FETCH_PROGRAM"));

export const fetchProgramsList = createAction(
  prefixed("FETCH_PROGRAMS_LIST"),
  (deleted, filter, page) => ({ deleted, filter, page })
);
export const didFetchProgramsList = createAction(
  prefixed("DID_FETCH_PROGRAMS_LIST"),
  (programsList, count) => ({ programsList, count })
);
