import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import moment from "moment";
import { omit } from "lodash";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import BaseComponent from "./../BaseComponent";
import "./DateInputField.css";

// This one is not for use with Redux form, use the one in FormFields instead for that

class DateInputField extends BaseComponent {
  constructor(props) {
    super(props);

    this._onDateChange = this._onDateChange.bind(this);
    this._onMonthChange = this._onMonthChange.bind(this);
  }

  _onDateChange(date) {
    const { onDateChange } = this.props;

    if (onDateChange) {
      onDateChange(date.valueOf());
    }
  }

  _onMonthChange(date) {
    const month = moment(date).utc().startOf("month");
    const { onMonthChange } = this.props;
    this._onDateChange(month);
    if (onMonthChange) {
      onMonthChange(month);
    }
  }

  render() {
    const {
      value,
      placeholder,
      withInput,
      showMonthDropdown,
      showYearDropdown,
      className,
      disabled,
    } = this.props;
    const selectedValue = value ? moment(value) : null;

    let openToDate = null;
    if (placeholder && !value) {
      openToDate = moment(placeholder);
    }

    return (
      <div
        className={classNames(
          {
            withDropDowns: showMonthDropdown || showYearDropdown,
            "disabled-calendar": disabled,
          },
          "input-container"
        )}
      >
        <div className={className}>
          <DatePicker
            {...omit(this.props, ["value"])}
            onChange={this._onDateChange}
            inline={!withInput}
            scrollableYearDropdown
            yearDropdownItemNumber={100}
            selected={selectedValue}
            openToDate={openToDate}
            onMonthChange={this._onMonthChange}
          />
        </div>
      </div>
    );
  }

  focus() {
    // Todo: Actually focus
  }
}

DateInputField.propTypes = {
  onDateChange: PropTypes.func,
  placeholder: PropTypes.any,
  value: PropTypes.object,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  withInput: PropTypes.bool,
  className: PropTypes.string,
  showMonthDropdown: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  disableD: PropTypes.bool,
};

DateInputField.defaultProps = {
  value: null,
  withInput: false,
  className: null,
  showMonthDropdown: true,
  showYearDropdown: true,
};

export default DateInputField;
