import React from "react";
import PropTypes from "prop-types";

import { Link } from "redux-little-router";

import "./Search.css";

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
    };

    this.onFieldChange = this.onFieldChange.bind(this);
  }

  onFieldChange(e) {
    const value = e.target.value;
    this.setState({ value });
    if (value.length > 3) {
      const { searchPeers } = this.props;
      searchPeers(value);
    }
  }

  renderSearch() {
    return (
      <input
        type="text"
        name="search"
        placeholder="Search"
        onChange={this.onFieldChange}
      />
    );
  }

  renderResults() {
    const { peers } = this.props;
    if (peers.length === 0 || this.state.value.length === 0) {
      return null;
    }

    return (
      <ul className="dropdown-menu">
        {peers.map((peer) => (
          <li key={peer.id}>
            <Link href={`/peers/view/${peer.id}`}>
              <span className="name">{peer.about && peer.about.name}</span>
              <span className="email">{peer.about && peer.about.email}</span>
            </Link>
          </li>
        ))}
      </ul>
    );
  }

  render() {
    return (
      <div className="user-search">
        {this.renderSearch()}
        {this.renderResults()}
      </div>
    );
  }
}

Search.propTypes = {
  // Automatically added
  searchPeers: PropTypes.func.isRequired,
  peers: PropTypes.array.isRequired,
};

Search.defaultProps = {
  peers: [],
};

export default Search;
