import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { filter, find } from "lodash";
import Autosuggest from "react-autosuggest";

import "./../FormGroupField/FormGroupField.css";
import "./AutocompleteSelectField.css";

class AutocompleteSelectField extends React.Component {
  constructor(props) {
    const {
      values,
      input: { value },
    } = props;
    super(props);

    // So many values...
    let labelForValue = find(values, (v) => v.value === value);
    if (labelForValue) {
      labelForValue = labelForValue.label;
    } else {
      labelForValue = "";
    }

    this.state = {
      suggestions: [],
      hasFocus: false,
      value: labelForValue || "",
      internalError: null,
      submitValue: null,
    };

    this.onSuggestionsClearRequested =
      this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionsFetchRequested =
      this.onSuggestionsFetchRequested.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSuggestionsFetchRequested({ value }) {
    const { values } = this.props;

    let suggestions = values;

    // Filter if needed
    if (value && value.trim().length > 0) {
      suggestions = filter(values, (suggestion) => {
        const lowercase = suggestion.label.toLowerCase().trim();
        const lowerValue = value.toLowerCase().trim();
        return lowercase.indexOf(lowerValue) !== -1;
      });
    }

    this.setState({
      suggestions,
    });
  }

  onSuggestionsClearRequested() {
    this.setState({ suggestions: [] });
  }

  onBlur() {
    const { value, submitValue } = this.state;

    this.setState({
      hasFocus: false,
    });

    // Empty is allowed
    if (!submitValue && value) {
      this.setState({ internalError: `${value} is not in the list` });
    }
  }

  onFocus(e) {
    this.setState({
      hasFocus: true,
    });
  }

  onChange(e, { newValue, method }) {
    this.setState({ internalError: null });
    const {
      values,
      input: { onChange },
    } = this.props;

    let possibleLabel = null;

    // Make sure typing just works
    if (method === "type") {
      possibleLabel = newValue;
    } else {
      // Put the label in the textbox
      possibleLabel = find(values, (v) => v.value === newValue);
      if (possibleLabel) {
        possibleLabel = possibleLabel.label;
      }
    }

    this.setState({ value: possibleLabel, submitValue: newValue });

    // Don't want to submit empty strings then it should be null
    let onChangeValue = `${newValue || possibleLabel}`.trim();
    if (onChangeValue === "") {
      onChangeValue = null;
    }

    onChange(onChangeValue);
  }

  getSuggestionValue(suggestion) {
    return suggestion.value;
  }

  renderSuggestion(suggestion) {
    return <div>{suggestion.label}</div>;
  }

  render() {
    const {
      input,
      label,
      placeholder,
      required,
      meta: { touched, error, warning, pristine, initial },
      className,
      disabled,
    } = this.props;

    const { suggestions, hasFocus, value, internalError, submitValue } =
      this.state;

    const shouldDisplayError = (!pristine || touched) && !hasFocus;

    const fieldClass = classNames(
      "form-control",
      { "has-error": shouldDisplayError && error },
      className
    );

    return (
      <div className="form-group">
        <label htmlFor={input.name}>
          {label}
          {required ? <span className="asterisk"> *</span> : null}
        </label>

        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          inputProps={{
            className: classNames(fieldClass, "autocomplete-form-control"),
            onBlur: this.onBlur,
            onFocus: this.onFocus,
            onChange: this.onChange,
            placeholder,
            value,
            disabled,
          }}
          renderSuggestion={this.renderSuggestion}
          highlightFirstSuggestion
          getSuggestionValue={this.getSuggestionValue}
        />

        {/*Actually getting submitted with the form... the above works a bit weird with redux form...*/}
        <input type="hidden" {...input} value={submitValue} />

        {(shouldDisplayError || internalError) &&
          (((error || internalError) && (
            <span className="error-message">{error || internalError}</span>
          )) ||
            (warning && <span className="warning-message">{warning}</span>))}
      </div>
    );
  }
}

AutocompleteSelectField.propTypes = {
  dateProps: PropTypes.object,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  // Also a bunch of redux field props
};

AutocompleteSelectField.defaultProps = {
  dateProps: {},
};

export default AutocompleteSelectField;
