import CoreReportRoute from "./CoreReport";
import WeeklyAdoptionReportRoute from "./WeeklyAdoptionReport";
import BillingReportRoute from "./BillingReport";
import BookOfBusinessRoute from "./BookOfBusiness";

export default Object.assign(
  {},
  CoreReportRoute,
  WeeklyAdoptionReportRoute,
  BillingReportRoute,
  BookOfBusinessRoute
);
