import { createAction } from "redux-actions";

const prefixed = (val) => `Peer/Actions/UpdateBirthDate::${val}`;

export const updateBirthDate = createAction(
  prefixed("UPDATE_BIRTHDATE"),
  (peerId, date) => ({ peerId, date })
);
export const didUpdateBirthDate = createAction(
  prefixed("DID_UPDATE_BIRTHDATE"),
  (peerId, info) => ({ peerId, info })
);
export const setBirthDateMessage = createAction(
  prefixed("SET_BIRTHDATE_MESSAGE"),
  (message) => message
);
