import {
  authenticationOfTypeRequired,
  authenticationRequired,
} from "../../shared/routes/Authentication/Login/sagas";
import WorkersView from "./WorkersView.component";

export default {
  "/workers": {
    component: WorkersView,
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired(["admin", "prevention:read"]);
    },
    title: "Workers",
  },
};
