import { put } from "redux-saga/effects";
import { showError } from "../components/ErrorIndicator/actions";
import { signout } from "../routes/Authentication/Login";
import { AbortedReqError } from "./error/AbortedReqError";
import { ExpiredRefreshTokenError } from "./error/ExpiredRefreshTokenError";

export function handleApiError(error) {
  if (error instanceof AbortedReqError) {
    return;
  }

  return handleAxiosError(error);
}

function handleAxiosError(error) {
  if (error instanceof ExpiredRefreshTokenError) {
    return signout();
  }
  const { response } = error;
  if (response) {
    // Ref: https://axios-http.com/docs/handling_errors
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return put(showError(response.data));
  } else {
    console.error(error);
    return put(showError(error));
  }
}
