import { takeLatest, put, all } from "redux-saga/effects";

import {
  setFleetingMessage,
  submitFormToAPI,
} from "../../../../../../../../shared/utils/form";

import { updatePhoneForPeer, didUpdatePhone } from "./actions";
import { updatePhoneForPeer as updatePhoneForPeerAPI } from "./api";

function* submitPhoneUpdate(action) {
  const { peerId, phone } = action.payload;

  yield put(
    submitFormToAPI(
      "updatePhone",
      updatePhoneForPeerAPI,
      didUpdatePhone(peerId, phone),
      peerId,
      phone
    )
  );
}

export default function* watchUpdatePhoneSaga() {
  yield all([
    takeLatest(updatePhoneForPeer, submitPhoneUpdate),
    takeLatest(
      didUpdatePhone,
      setFleetingMessage("updatePhone", { message: "Phone has been updated." })
    ),
  ]);
}
