import { takeLatest, put } from "redux-saga/effects";
import { didRequestUpdatePassword, requestUpdatePassword } from "./actions";
import { submitFormToAPI } from "../../../../../utils/form";
import { requestUpdatePasswordAPI } from "./api";

function* handleRequestUpdatePassword({ payload: email }) {
  yield put(
    submitFormToAPI(
      "requestUpdatePassword",
      requestUpdatePasswordAPI,
      didRequestUpdatePassword,
      email
    )
  );
}

export default function* watchRequestUpdatePasswordSaga() {
  yield takeLatest(requestUpdatePassword, handleRequestUpdatePassword);
}
