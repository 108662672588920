import {
  authenticationOfTypeRequired,
  authenticationRequired,
} from "../../shared/routes/Authentication/Login/sagas";

import Coaches from "./Coaches.js";
import { CoachesReducer } from "./reducer";
import watchCoaches from "./sagas";

export default {
  "/coaches": {
    component: Coaches,
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired(["admin"]);
    },
    reducer: {
      ui: {
        Coaches: CoachesReducer,
      },
    },
    saga: watchCoaches,
    title: "Coaches",
  },
};
