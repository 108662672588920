import { connect } from "react-redux";
import { showPanel } from "./../../../../../../shared/components/ModalPanel/actions";
import CardInteractionsWorker from "./CardInteractionsWorker.component";

function mapStateToProps(state) {
  return {};
}

const dispatch = {
  showEventCard: (event) =>
    showPanel(`showEventCard-${event.createdAt}-${event.card.id}`),
};

export default connect(mapStateToProps, dispatch)(CardInteractionsWorker);
