import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Select from "react-select";

import "./PlaceholderSelectField.css";

class PlaceholderSelectField extends React.Component {
  constructor(props) {
    super(props);

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(option) {
    this.props.input.onChange(option ? option.value : null);
  }

  render() {
    const {
      input,
      label,
      required,
      type,
      meta: { touched, error, warning, pristine, initial },
      disabled = false,
      className,
      values = [],
      placeholder,
      clearable = false,
    } = this.props;

    const shouldDisplayError = !pristine || touched;

    const labelClass = classNames({ disabled });
    const fieldClass = classNames(
      { "has-error": shouldDisplayError && error, disabled },
      className
    );

    const value =
      input.value !== undefined && input.value !== "" ? input.value : initial;

    return (
      <div className="form-group placeholder-select-field">
        <label className={labelClass} htmlFor={input.name}>
          {required ? label : <span className="optional-label">{label}</span>}
          {required && <span className="asterisk"> *</span>}
        </label>
        <Select
          className={fieldClass}
          options={values || []}
          value={value}
          placeholder={placeholder}
          onChange={this.handleSelectChange}
          clearable={clearable}
          disabled={disabled}
        />
        {shouldDisplayError &&
          ((error && <span className="error-message">{error}</span>) ||
            (warning && <span className="warning-message">{warning}</span>))}
      </div>
    );
  }
}

export default PlaceholderSelectField;
