import React from "react";
import { flatten } from "lodash";

import { LineMarkSeries } from "react-vis";

import BaseChart from "../BaseChart";

import { compactObject } from "../../../utils/compactObject";

class LineChart extends BaseChart {
  renderPlot(config) {
    const lines = this._getChartLines(config);

    const otherComponents = super.renderPlot(config);
    return otherComponents.concat(lines);
  }

  _getChartLines(config) {
    const { yDomain, xDomain } = this.props;

    const allLines = config.map((chartConfig, index) => {
      const color = this.colorForIndex(index);

      const lineProps = compactObject({
        data: chartConfig.data,
        key: `draw-line-${index}`,
        color,
        yDomain: this._getCalculatedUserDomain(this.state.data, yDomain, "y"),
        xDomain: this._getCalculatedUserDomain(this.state.data, xDomain, "x"),
      });

      const lineHoverProps = compactObject({
        data: chartConfig.data,
        onNearestXY: (value) => this._onNearestXY(index, value),
        onSeriesMouseOut: this._clearHint,
        onSeriesMouseOver: (event) => this._onSeriesMouseOver(index, event),
        key: `hover-line-${index}`,
        color: "rgba(0,0,0,0)",
        strokeWidth: 25,
        yDomain: this._getCalculatedUserDomain(this.state.data, yDomain, "y"),
        xDomain: this._getCalculatedUserDomain(this.state.data, xDomain, "x"),
      });

      // We create two lines for each line to draw, one visible one with the normal thickness, and one invisible one which is much thicker. So you don't have to aim your mouse to hit the line exactly
      return [
        // The line that displays the info to the user
        <LineMarkSeries {...lineProps} />,
        // The line that handles the interaction events but is not visible by itself
        <LineMarkSeries {...lineHoverProps} />,
      ];
    });

    return flatten(allLines);
  }
}

LineChart.defaultProps = {
  ...BaseChart.defaultProps,
  yGridLines: true,
};

export default LineChart;
