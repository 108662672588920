import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import LoadingIndicator from "./../../../../../../../shared/components/LoadingIndicator";
import SelectField from "./../../../../../../../shared/components/FormFields/SelectField";

class UpdateAssignedTo extends React.Component {
  componentDidMount() {
    const { fetchAvailableAssignedToUsers } = this.props;
    fetchAvailableAssignedToUsers();
  }

  render() {
    const {
      message,
      availableAssignedToUsers,
      assignedTo,
      onAssignedToChange,
    } = this.props;

    // todo: Improve this, uses half redux form half not...
    //  Todo: Don't use loading indicator'
    if (!availableAssignedToUsers) {
      return <LoadingIndicator />;
    }

    let values = [{ label: "-", value: null }];
    values = values.concat(
      availableAssignedToUsers.map((theAssignedTo) => ({
        label: theAssignedTo.email,
        value: theAssignedTo.id,
      }))
    );

    return (
      <div>
        <h1>Update assigned to</h1>
        <form className="form">
          <Field
            name="nurse"
            component={SelectField}
            values={values}
            defaultValue={assignedTo}
            onChange={onAssignedToChange}
          />
          {message && <p className="form-message">{message}</p>}
        </form>
      </div>
    );
  }
}

UpdateAssignedTo.propTypes = {
  userId: PropTypes.string.isRequired,
  message: PropTypes.string,
  assignedTo: PropTypes.object,
  availableAssignedToUsers: PropTypes.array,
  fetchAvailableAssignedToUsers: PropTypes.func.isRequired,
  onAssignedToChange: PropTypes.func.isRequired,
};

UpdateAssignedTo.defaultProps = {
  message: null,
};

export default reduxForm({
  form: "updateAssignedTo",
})(UpdateAssignedTo);
