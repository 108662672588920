import { handleActions } from "redux-actions";
// import { update } from 'react-addons-update'
import { didUpdateCareTeamMember } from "./actions";

export default handleActions(
  {
    [didUpdateCareTeamMember]: (state, action) => {
      const { peerId, member, job } = action.payload;
      let careTeam = state[peerId].care_team;
      let newCareTeam = careTeam.map((carer, i) =>
        carer.id === member.id ? { ...carer, job: job } : carer
      );
      state[peerId].care_team = newCareTeam;
      return { ...state };
    },
  },
  {}
);
