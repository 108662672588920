export enum AxiosHeader {
  ContentLength = "content-length",
  XTotalCount = "x-total-count",
}

export const getTotalCountFromHeaders = (headers: any) => {
  const countString = headers[AxiosHeader.XTotalCount];
  if (!countString) {
    console.warn(
      "getTotalCountFromHeaders: Total count was not found in headers."
    );
    return 0;
  }
  return parseInt(countString);
};
