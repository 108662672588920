import { debounce } from "lodash";
import { connect } from "react-redux";
import { shouldDisplayLoadingIndicator } from "../../shared/components/LoadingIndicator/reducer";
import { showPanel } from "../../shared/components/ModalPanel/actions";
import { modalMessage } from "../../shared/components/ModalPanel/reducer";
import { query, updateQuery } from "../../shared/routes/query";
import Coaches from "./Coaches.jsx";
import { requestCoaches, resendInvite } from "./actions";
import { columns, count, results } from "./reducer";

function mapStateToProps(state) {
  return {
    searchResults: results(state),
    columns: columns(),
    count: count(state),
    keyword: query(state, "keyword"),
    page: parseInt(query(state, "page"), 10) || 0,
    sortBy: query(state, "sortBy"),
    sortDirection: query(state, "sortDirection"),
    message: modalMessage(state),
    isLoading: shouldDisplayLoadingIndicator(state),
  };
}

function searchIfNeeded(keyword, sortBy, sortDirection, page, dispatch) {
  if (!keyword || keyword.length === 0) {
    return dispatch(requestCoaches("", sortBy, sortDirection, page)); // Empty string, else it gets send as null, which the server parses as null string
  } else if (keyword.length <= 2) {
    // No need to search on 2 letter keywords
    return null;
  }

  return dispatch(requestCoaches(keyword, sortBy, sortDirection, page));
}

const mapDispatchToProps = (dispatch) => ({
  requestCoaches: debounce(
    (keyword, sortBy, sortDirection, page) =>
      searchIfNeeded(keyword, sortBy, sortDirection, page, dispatch),
    300
  ),
  resendInvite: (coachId) => dispatch(resendInvite(coachId)),
  onSearchChange: (keyword) => dispatch(updateQuery({ keyword, page: 0 })),
  onSortChange: (sortBy, sortDirection) =>
    dispatch(updateQuery({ sortDirection, sortBy, page: 0 })),
  showCoachForm: (coachId) =>
    dispatch(showPanel(`manageCoach${coachId || ""}`)),
  showResendInvite: (coachId) => dispatch(showPanel(`resendInvite${coachId}`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Coaches);
