import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isEqual } from "lodash";
import { Link } from "redux-little-router";

import editIconHover from "./../../assets/edit hover.svg";
import BreadCrumbBar from "../../components/BreadCrumbBar/BreadCrumbBar";
import ModalPanel from "../../shared/components/ModalPanel";
import Pagination from "../../shared/components/Pagination";
import SuccessAnimation from "../../shared/components/SuccessAnimation";
import LoadingIndicator from "../../shared/components/LoadingIndicator";

import PartnerUserForm from "./PartnerUserForm";

import "./Team.css";

const perPage = 30;
class Team extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clickedPartnerUser: null,
    };

    this.tableRef = null;

    this.renderColumnHeader = this.renderColumnHeader.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.handleClickRow = this.handleClickRow.bind(this);
    this.handleDoubleClickRow = this.handleDoubleClickRow.bind(this);
  }

  componentDidMount() {
    const { requestExternalFields, requestCustomers, authenticatedUserType } =
      this.props;
    if (authenticatedUserType === "admin") {
      requestCustomers();
    }
    requestExternalFields();
    this.search(this.props);
  }

  componentDidUpdate(prevProps) {
    const { keyword, sortBy, sortDirection, page, searchResults, count } =
      this.props;
    const { clickedPartnerUser } = this.state;

    if (
      keyword !== prevProps.keyword ||
      sortBy !== prevProps.sortBy ||
      sortDirection !== prevProps.sortDirection ||
      page !== prevProps.page
    ) {
      this.search(this.props);
    }

    // we want to make sure that there is always a default "clicked" blue-highlighted partner user at the top of the list, nicer ui
    if (!isEqual(searchResults, prevProps.searchResults) && count > 0) {
      this.tableRef.scrollTop = 0;
      const firstResult = searchResults[0];
      this.setState({ clickedPartnerUser: firstResult.email });
    }

    if (!clickedPartnerUser && searchResults && searchResults.length) {
      this.setState({ clickedPartnerUser: searchResults[0].email });
    }
  }

  search({ requestResults, keyword, sortBy, sortDirection, page }) {
    requestResults(keyword, sortBy, sortDirection, page);
  }

  handleSort(column) {
    const { onSortChange, sortBy, sortDirection } = this.props;
    const nextSortBy = column.sortBy;
    const nextSortDirection =
      sortDirection === "asc" && nextSortBy === sortBy ? "desc" : "asc";

    onSortChange(nextSortBy, nextSortDirection);
  }

  handleClickRow(partnerUser) {
    this.setState({ clickedPartnerUser: partnerUser.email });
  }

  handleDoubleClickRow(partnerUser) {
    const { showPartnerUserForm } = this.props;

    showPartnerUserForm(partnerUser.email);
  }

  renderAddPartnerUserButton() {
    const { showPartnerUserForm, authenticatedUserType, isLoading } =
      this.props;
    const modalWidth = authenticatedUserType === "admin" ? "1041px" : "837px";

    const createPartnerUserForm = (
      <ModalPanel
        name="managePartnerUser"
        backgroundColor="#FAFAFA"
        borderRadius="15px"
        width={modalWidth}
      >
        <PartnerUserForm isAdmin={authenticatedUserType === "admin"} />
      </ModalPanel>
    );

    return (
      <div>
        <button
          className="add-partner-user-button"
          onClick={() => showPartnerUserForm(null)}
          disabled={isLoading}
        >
          Add team member
        </button>
        {createPartnerUserForm}
      </div>
    );
  }

  renderManagementRow() {
    const { onSearchChange, keyword } = this.props;

    return (
      <BreadCrumbBar>
        <div className="management-row">
          <div className="search-container">
            <input
              className="search-input"
              placeholder="Search name or email"
              onChange={(e) => onSearchChange(e.target.value)}
              value={keyword}
            />
          </div>
          {this.renderAddPartnerUserButton()}
        </div>
      </BreadCrumbBar>
    );
  }

  renderColumnHeader(column) {
    const { sortBy, sortDirection } = this.props;

    let arrow = null;
    if (column.sortBy === sortBy) {
      const arrowClassName =
        sortDirection === "asc" ? "caret-up" : "caret-down";
      arrow = <span className={classNames(arrowClassName, "caret")} />;
    }

    const headerText = <span>{column.columnLabel}</span>;

    return (
      <th
        key={column.columnLabel}
        className={classNames({ sortable: !!column.sortBy })}
        onClick={() => column.sortBy && this.handleSort(column)}
      >
        {headerText}
        {arrow}
      </th>
    );
  }

  renderColumnHeaders() {
    const { columns, count } = this.props;
    const columnHeaders = columns.map(this.renderColumnHeader);

    return (
      <thead className={classNames({ hideTableHead: count === 0 })}>
        <tr>{columnHeaders}</tr>
      </thead>
    );
  }

  renderRow(partnerUser) {
    const {
      columns,
      showPartnerUserForm,
      showResendInvite,
      resendInvite,
      message,
      authenticatedUserType,
    } = this.props;

    const isAdmin = authenticatedUserType === "admin";
    const shouldDisplayResendInvite = isAdmin && !partnerUser.activated;
    const href = `/patients?groupType=owner&groupValue=${partnerUser.id}&page=0`;
    const modalWidth = isAdmin ? "1071px" : "837px";
    const rowClassName = classNames({
      clickedRow: partnerUser.email === this.state.clickedPartnerUser,
    });
    const tdWithButtonsClass = classNames("td-with-buttons", {
      tdWithButtonsExtraWidth: shouldDisplayResendInvite,
    });

    const partnerUserRow = columns.map((column) => (
      <td key={column.columnLabel}>
        {column.displayValue(partnerUser) || (
          <span className="not-available">--</span>
        )}
      </td>
    ));

    const partnerUserButtonsColumn = (
      <td key={partnerUser.email} className={tdWithButtonsClass}>
        {shouldDisplayResendInvite && (
          <button
            className="resend-invite-button"
            onClick={() => showResendInvite(partnerUser.email)}
          >
            Resend invite
          </button>
        )}
        <Link href={href}>
          <button className="view-patients-button">View patients</button>
        </Link>
        <button
          className="edit-partner-user-button"
          onClick={() => showPartnerUserForm(partnerUser.email)}
        >
          {" "}
          <img className="edit-icon" src={editIconHover} alt="edit" />
          Edit
        </button>
      </td>
    );

    // these two are modal popups and are hidden by default -- will only render when the 'showPanel()' action is dispatched

    const updatePartnerUserForm = (
      <ModalPanel
        key={`${partnerUser.email}${partnerUser.name}`}
        name={`managePartnerUser${partnerUser.email}`}
        withClickOutsideListener
        backgroundColor="#FAFAFA"
        borderRadius="15px"
        width={modalWidth}
      >
        <PartnerUserForm formData={partnerUser.formData} />
      </ModalPanel>
    );

    const resendInvitePopup = (
      <ModalPanel
        key={`${partnerUser.name}${partnerUser.email}`}
        name={`resendInvite${partnerUser.email}`}
        withClickOutsideListener
        backgroundColor="#FAFAFA"
        borderRadius="15px"
        width="400px"
      >
        <div className="resend-invite">
          <div className="resend-invite-header">
            Resend invite for {partnerUser.fullname}?
          </div>
          {message ? (
            <SuccessAnimation message={message} />
          ) : (
            <button
              className="resend-invite-button"
              onClick={() => resendInvite(partnerUser.id)}
            >
              Resend
            </button>
          )}
        </div>
      </ModalPanel>
    );

    return (
      <tr
        key={partnerUser.email}
        className={rowClassName}
        onClick={() => this.handleClickRow(partnerUser)}
        onDoubleClick={() => this.handleDoubleClickRow(partnerUser)}
      >
        {[
          ...partnerUserRow,
          partnerUserButtonsColumn,
          updatePartnerUserForm,
          resendInvitePopup,
        ]}
      </tr>
    );
  }

  renderRows() {
    const { searchResults, isLoading } = this.props;

    if (!searchResults) return null;

    const partnerUserRows = searchResults.map(this.renderRow);

    return (
      <tbody className={classNames({ hideTableRows: isLoading })}>
        {partnerUserRows}
      </tbody>
    );
  }

  renderSearchResults() {
    return (
      <div
        className="partner-user-table-container"
        ref={(node) => {
          this.tableRef = node;
        }}
      >
        <table className="partner-user-table">
          {this.renderColumnHeaders()}
          {this.renderRows()}
        </table>
        {this.renderPagination()}
        <LoadingIndicator className="loading-indicator" />
        {this.renderNoResults()}
      </div>
    );
  }

  renderNoResults() {
    const { searchResults, isLoading, count } = this.props;
    const hasResults = searchResults && count > 0;
    const fieldsToSearchOn = ["Name", "Email", "Group"];

    if (hasResults || isLoading) return null;

    return (
      <div className="no-results-container">
        <div className="no-results">
          <h1 className="no-results-found-header">No Results Found</h1>
          <p className="you-can-search-on">You can search on:</p>

          <div className="fields-to-search-on">
            {fieldsToSearchOn.map((field) => (
              <p key={field} className="field-to-search-on">{`- ${field}`}</p>
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderPagination() {
    const { page, count } = this.props;

    if (!count) return null;

    return (
      <Pagination
        itemCount={count}
        itemsPerPage={perPage}
        baseUrl={"/team"}
        currentPage={page}
        pageInQuery
      />
    );
  }

  render() {
    return (
      <div className="team-list">
        {this.renderManagementRow()}
        {this.renderSearchResults()}
      </div>
    );
  }
}

export default Team;

Team.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  keyword: PropTypes.string,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  page: PropTypes.number,
};

Team.defaultProps = {
  sortBy: "fullname",
};
