import { createAction } from "redux-actions";

const prefixed = (val) => `MODULES::${val}`;

export const fetchModule = createAction(
  prefixed("FETCH_MODULE"),
  (moduleId) => ({ moduleId })
);
export const didFetchModule = createAction(prefixed("DID_FETCH_MODULE"));

export const fetchModulesList = createAction(
  prefixed("FETCH_MODULES_LIST"),
  (deleted, filter, page) => ({ deleted, filter, page })
);
export const didFetchModulesList = createAction(
  prefixed("DID_FETCH_MODULES_LIST"),
  (modulesList, count) => ({ modulesList, count })
);
