import { handleActions } from "redux-actions";
import hash from "object-hash";

import { values } from "lodash";
import mapArrayToObject from "../../utils/mapArrayToObject";

import { didFetchMetricData } from "./sagas";

export function availableMetricDataForQuery(state, metricQuery) {
  const lookupHash = hash(metricQuery);
  const infoForQuery = state.ui.Metrics.metrics[lookupHash];
  if (infoForQuery) {
    return infoForQuery.data;
  }
  return null;
}

export function availableMetricDataOfTypes(state, types) {
  const allMetricData = values(state.ui.Metrics.metrics);
  if (allMetricData && allMetricData.length > 0) {
    return mapArrayToObject(allMetricData, (metricData, acc) => {
      const type = metricData.metricInfo.type;
      // Only add the one's we want
      if (types.indexOf(type) === -1) {
        return;
      }
      // Add it to the list
      acc[type] = metricData.data;
    });
  }
  return null;
}

export const metricReducer = handleActions(
  {
    [didFetchMetricData]: (state, action) => {
      const payload = action.payload;
      const metrics = state.metrics;
      const lookupHash = hash(payload.metricInfo);
      metrics[lookupHash] = {
        data: payload.data,
        metricInfo: payload.metricInfo,
      };
      return { ...state, metrics };
    },
  },
  { metrics: {} }
);
