import { all, takeLatest } from "redux-saga/effects";

import { callAPI } from "../../../shared/utils/sagas";
import { setFleetingMessage } from "../../../shared/utils/form";

import {
  requestCustomers,
  didFetchCustomers,
  requestCustomerTypes,
  didFetchCustomerTypes,
  requestCustomerDetails,
  didFetchCustomerDetails,
  requestCustomerSelectFields,
  didFetchCustomerSelectFields,
  requestCustomerSelectField,
  didFetchCustomerSelectField,
  createCustomer,
  didCreateCustomer,
  updateCustomer,
  didUpdateCustomer,
  createFieldValue,
  didCreateFieldValue,
  updateFieldValue,
  didUpdateFieldValue,
} from "./actions";
import {
  fetchCustomers as fetchCustomersAPI,
  fetchCustomerTypes as fetchCustomerTypesAPI,
  fetchCustomerSelectFields as fetchCustomerSelectFieldsAPI,
  fetchCustomerDetails as fetchCustomerDetailsAPI,
  fetchCustomerSelectField as fetchCustomerSelectFieldAPI,
  createCustomer as createCustomerAPI,
  updateCustomer as updateCustomerAPI,
  createFieldValue as createFieldValueAPI,
  updateFieldValue as updateFieldValueAPI,
} from "./api";

export default function* watchManageCustomer() {
  yield all([
    takeLatest(requestCustomers, callAPI(fetchCustomersAPI, didFetchCustomers)),
    takeLatest(
      requestCustomerTypes,
      callAPI(fetchCustomerTypesAPI, didFetchCustomerTypes)
    ),
    takeLatest(
      requestCustomerDetails,
      callAPI(fetchCustomerDetailsAPI, didFetchCustomerDetails)
    ),
    takeLatest(
      requestCustomerSelectFields,
      callAPI(fetchCustomerSelectFieldsAPI, didFetchCustomerSelectFields)
    ),
    takeLatest(
      requestCustomerSelectField,
      callAPI(fetchCustomerSelectFieldAPI, didFetchCustomerSelectField)
    ),
    takeLatest(createCustomer, callAPI(createCustomerAPI, didCreateCustomer)),
    takeLatest(updateCustomer, callAPI(updateCustomerAPI, didUpdateCustomer)),
    takeLatest(
      createFieldValue,
      callAPI(createFieldValueAPI, didCreateFieldValue)
    ),
    takeLatest(
      updateFieldValue,
      callAPI(updateFieldValueAPI, didUpdateFieldValue)
    ),
    takeLatest(
      didCreateCustomer,
      setFleetingMessage(
        "manageCustomer",
        { message: "Customer successfully created!" },
        2000
      )
    ),
    takeLatest(
      didUpdateCustomer,
      setFleetingMessage(
        "manageCustomer",
        { message: "Customer successfully updated!" },
        2000
      )
    ),
    takeLatest(
      didCreateFieldValue,
      setFleetingMessage(
        "manageCustomer",
        { message: "Value successfully created!" },
        2000
      )
    ),
    takeLatest(
      didUpdateFieldValue,
      setFleetingMessage(
        "manageCustomer",
        { message: "Value successfully updated!" },
        2000
      )
    ),
  ]);
}
