import React from "react";
import PropTypes from "prop-types";

import "./Programs.css";
import ProgramsList from "./components/ProgramsList/ProgramsList";
import ProgramsForm from "./components/ProgramsForm";
import Pagination from "../../shared/components/Pagination";

class Programs extends React.Component {
  componentDidMount() {
    this._fetchProgram(this.props);
    this.props.fetchProgramsList(this.props.showDeleted, this.props.filter, 0);
  }

  componentWillReceiveProps(nextProps) {
    const {
      selectedProgramId,
      fetchProgramsList,
      showDeleted,
      filter,
      page,
      resetPage,
    } = this.props;
    if (
      nextProps.selectedProgramId &&
      selectedProgramId !== nextProps.selectedProgramId
    ) {
      this._fetchProgram(nextProps);
    } else if (
      showDeleted !== nextProps.showDeleted ||
      filter !== nextProps.filter
    ) {
      fetchProgramsList(nextProps.showDeleted, nextProps.filter, 0);
      resetPage();
    } else if (page !== nextProps.page) {
      fetchProgramsList(showDeleted, filter, nextProps.page);
    }
  }

  _fetchProgram(props) {
    const { selectedProgramId, fetchProgram } = props;

    if (selectedProgramId) {
      fetchProgram(selectedProgramId);
    }
  }

  renderList() {
    const { showDeleted } = this.props;

    return (
      <div className="programs-columns">
        <div className="program-list-col">
          <ProgramsList showDeleted={showDeleted} />
        </div>
      </div>
    );
  }

  renderCreate() {
    const { newProgram } = this.props;
    return (
      <div className="edit-form">
        <div className="col-md-12 edit-col">
          <ProgramsForm program={newProgram} />
        </div>
      </div>
    );
  }

  renderEdit() {
    const { selectedProgram } = this.props;
    if (selectedProgram) {
      return (
        <div className="edit-form">
          <div className="edit-col">
            <ProgramsForm program={selectedProgram} />
          </div>
        </div>
      );
    }
    return null;
  }

  renderPagination() {
    const { page, count } = this.props;

    if (count <= 20) {
      return null;
    }

    return (
      <Pagination
        itemCount={count}
        itemsPerPage={20}
        currentPage={page}
        baseUrl={"/programs"}
        pageInQuery
      />
    );
  }

  render() {
    const { showCreate, showEdit } = this.props;
    return (
      <div>
        <div className="programs">
          {showEdit && this.renderEdit()}
          {showCreate && this.renderCreate()}
          {!showEdit && !showCreate && this.renderList()}
          {!showEdit && !showCreate && this.renderPagination()}
        </div>
      </div>
    );
  }
}

Programs.propTypes = {
  selectedProgram: PropTypes.object,
  newProgram: PropTypes.object,
  showCreate: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDeleted: PropTypes.bool,
  selectedProgramId: PropTypes.string,
  fetchProgram: PropTypes.func.isRequired,
  fetchProgramsList: PropTypes.func.isRequired,
  filter: PropTypes.string,
  page: PropTypes.number,
  count: PropTypes.number,
  resetPage: PropTypes.func.isRequired,
};

Programs.defaultProps = {
  selectedProgram: null,
  newProgram: null,
  showCreate: false,
  showEdit: false,
  showDeleted: false,
  selectedProgramId: null,
  filter: "",
  page: 0,
  count: 0,
};

export default Programs;
