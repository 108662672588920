import { selectFiles } from "./helper";
import { attachPicture, uploadPicture } from "./api";

export default async function upload(ownerId) {
  const files = await selectFiles();
  if (files.length === 0) {
    return;
  }

  const file = files[0];
  try {
    const res = await uploadPicture(file);
    const id = res.data;
    await attachPicture(ownerId, id);
    return id;
  } catch (e) {
    const response = e.response;
    if (response.status === 400) {
      alert(response.data.error.path[0]);
    } else {
      alert("Could not upload image");
    }
  }
}
