import React from "react";
import PropTypes from "prop-types";

import { mapObjectToArray } from "../../../utils/mapObject";
import classNames from "classnames";

import "./RadioList.css";

// Don't call this function directly in a render cycle, get the list from this function put it in a var and render that
// If you call it each render cycle you get a new instance each time and state is not kept correctly
function RadioListWrapper(options) {
  class RadioList extends React.Component {
    constructor(props) {
      super(props);

      this.renderOption = this.renderOption.bind(this);
      this.updateValue = this.updateValue.bind(this);
    }

    updateValue(e) {
      const { input } = this.props;
      input.onChange(e);
    }

    renderOption(value, label, disabled, selectedValue) {
      const { input } = this.props;
      const selected = selectedValue === value;
      return (
        <div className="control-group" key={value}>
          <input
            id={value}
            name={input.name}
            type="radio"
            value={value}
            checked={selected}
            onChange={this.updateValue}
            disabled={disabled}
          />
          <label htmlFor={value}>{label}</label>
        </div>
      );
    }

    render() {
      const {
        label,
        required,
        horizontal,
        meta: { touched, error, warning, pristine },
        disabled,
        input,
        defaultValue,
      } = this.props;
      const selectedValue = input.value || defaultValue;

      const shouldDisplayError = !pristine || touched;

      return (
        <div className={classNames("radio-list", { horizontal })}>
          {label && (
            <label>
              {label}
              {required && "*"}
            </label>
          )}
          <div className="options">
            {mapObjectToArray(options, (value, label) =>
              this.renderOption(value, label, disabled, selectedValue)
            )}
          </div>

          {shouldDisplayError &&
            ((error && <span className="error-message">{error}</span>) ||
              (warning && <span className="warning-message">{warning}</span>))}
        </div>
      );
    }
  }

  RadioList.propTypes = {
    horizontal: PropTypes.bool,
  };

  RadioList.defaultProps = {
    horizontal: false,
  };

  return RadioList;
}

export default RadioListWrapper;
