import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";

import "./ManagePrograms.css";

class ManagePrograms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <div className="manage-programs">
        <h2>Manage Programs</h2>
      </div>
    );
  }
}

ManagePrograms.propTypes = {
  // redux-form
  handleSubmit: PropTypes.func.isRequired,
};

ManagePrograms.defaultProps = {};

export default reduxForm({
  form: "managePrograms",
  enableReinitialize: true,
})(ManagePrograms);
