import reduceReducers from "reduce-reducers";
import { handleActions } from "redux-actions";
import { get } from "lodash";
import { query } from "../../shared/routes/query";

import { didFetchProgram, didFetchProgramsList } from "./actions";
import pinnedCardsReducer from "./components/ProgramsForm/component/PinnedCards/reducer";
import programsFormReducer from "./components/ProgramsForm/reducer";
import bottomBarReducer from "./components/BottomBar/reducer";
import programsListReducer from "./components/ProgramsList/reducer";

export const program = (state, id) => get(state.ui.Programs, `programs.${id}`);
export const newProgram = (state) =>
  get(state.ui.Programs, "newProgram", { name: "", description: "" });
export const selectedProgramId = (state) => query(state, "edit");
export const selectedProgram = (state) =>
  program(state, selectedProgramId(state));

export const programsReducer = reduceReducers(
  bottomBarReducer,
  programsFormReducer,
  programsListReducer,
  pinnedCardsReducer,
  handleActions(
    {
      [didFetchProgram]: (state, action) => {
        const programs = { ...state.programs } || {};
        const payloadProgram = action.payload;
        programs[payloadProgram.id] = payloadProgram;
        return { ...state, programs };
      },
      [didFetchProgramsList]: (state, action) => ({
        ...state,
        list: action.payload.programsList,
        count: action.payload.count,
      }),
    },
    {
      pending: null,
      programs: {},
    }
  )
);
