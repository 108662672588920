import { takeLatest } from "redux-saga/effects";

import { callAPI } from "../../../../../../../../shared/utils/sagas";

import { deletePatient, didDeletePatient } from "./actions";
import { deletePatient as deletePatientAPI } from "./api";

export default function* watchDeletePatientSaga() {
  yield takeLatest(deletePatient, callAPI(deletePatientAPI, didDeletePatient));
}
