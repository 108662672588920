import BillingReport from "./BillingReport.js";

import {
  authenticationOfTypeRequired,
  authenticationRequired,
} from "../../../shared/routes/Authentication/Login/sagas";
import reducer from "./reducer";
import watchBillingReportSaga from "./sagas";

export default {
  "/billing-report(/:customerId)": {
    component: BillingReport,
    reducer: {
      ui: {
        BillingReports: reducer,
      },
    },
    saga: watchBillingReportSaga,
    title: "Billing Report",
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired(["admin"]);
    },
  },
};
