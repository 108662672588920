import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Link } from "redux-little-router";

import BreadCrumbBar from "../../../components/BreadCrumbBar";
import "./CoreReport.css";
import DateInputField from "../../../shared/components/DateInputField";

class CoreReport extends React.Component {
  constructor(props) {
    super(props);
    this._onChangeEndDate = this._onChangeEndDate.bind(this);
  }

  componentDidMount() {
    const { updateEndDate } = this.props;
    updateEndDate(moment().endOf("week"));
  }

  componentWillReceiveProps(nextProps) {
    const { requestCoreReport, endDate, selected, updateEndDate, resetReport } =
      this.props;

    if (nextProps.selected && selected !== nextProps.selected) {
      updateEndDate(moment().endOf("week"));
    } else if (nextProps.endDate && endDate !== nextProps.endDate) {
      resetReport();
      requestCoreReport({ reportType: selected, endDate: nextProps.endDate });
    }
  }

  _onChangeEndDate(timestamp) {
    const { updateEndDate } = this.props;
    updateEndDate(timestamp);
  }

  _renderExportButton() {
    const { selected, report, endDate } = this.props;

    if (report) {
      const date = endDate || new Date().getTime();
      const dateMoment = moment(date).format("MM-DD-YYYY");

      return (
        <CSVLink
          data={report}
          filename={`${selected} Core Report ${dateMoment}.csv`}
          className="btn btn-primary matrix-export-button"
          target="_blank"
        >
          Export {selected} Core Report to CSV
        </CSVLink>
      );
    }
    return null;
  }

  renderDescription() {
    return (
      <div className="core-description">
        <h1>Core Report</h1>
        <h2>Operational metrics for reporting to leadership/board members</h2>
      </div>
    );
  }

  renderCalendar() {
    const { selected, endDate } = this.props;

    const maxDate = moment().endOf("week");

    if (selected) {
      return (
        <div className="calendars">
          <div className="core-calendar-container">
            <label className="no-date-label">
              Report End Date (8 report weeks)
            </label>
            <DateInputField
              className="inline date-field"
              value={endDate}
              onDateChange={this._onChangeEndDate}
              onMonthChange={this._onChangeEndDate}
              maxDate={maxDate}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  renderCustomerDropdown() {
    const { selected } = this.props;

    return (
      <BreadCrumbBar>
        <div className="dropdown">
          <span
            className="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {selected}
            <span className="caret" />
          </span>
          <ul className="dropdown-menu">
            <li key={"predefinedCustomers"}>
              <Link href={"/core-report/predefined-customers"}>
                Include predefined customers
              </Link>
            </li>
            <li key={"allCustomers"}>
              <Link href={"/core-report/all-customers"}>
                Include all customers
              </Link>
            </li>
          </ul>
        </div>
      </BreadCrumbBar>
    );
  }

  render() {
    return (
      <div>
        {this.renderCustomerDropdown()}
        <div className="content-container">{this._renderExportButton()}</div>
        {this.renderDescription()}
        {this.renderCalendar()}
      </div>
    );
  }
}

CoreReport.propTypes = {
  selected: PropTypes.string.isRequired,
  report: PropTypes.string,
  requestCoreReport: PropTypes.func.isRequired,
  updateEndDate: PropTypes.func.isRequired,
  resetReport: PropTypes.func.isRequired,
  endDate: PropTypes.number,
};

CoreReport.defaultProps = {
  selected: null,
  reportType: null,
  report: null,
  endDate: null,
};

export default CoreReport;
