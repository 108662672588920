import { connect } from "react-redux";
import UpdateAssignedTo from "./UpdateAssignedTo.jsx";

import { fleetingFormMeta } from "../../../../../../../shared/utils/form";

export function mapStateToProps(state) {
  return {
    message: fleetingFormMeta(state, "updateAssignedTo").message,
  };
}

export default connect(mapStateToProps, null)(UpdateAssignedTo);
