import React from "react";
import PropTypes from "prop-types";

import { keys, difference } from "lodash";

// Similar to Metrics.jsx, but that one actually fetches metrics from the API
// If you want to use the same metric information in a component in another location you can use this
// It will pass the metrics through context to the child component
// It will only render if all the types of metrics (passed through the types props) are loaded
class FetchedMetrics extends React.Component {
  getChildContext() {
    return {
      metricData: this.props.metrics,
    };
  }

  shouldRender() {
    const { metrics, types } = this.props;
    if (!metrics) {
      return false;
    }
    if (difference(types, keys(metrics)).length > 0) {
      return false;
    }
    return true;
  }

  render() {
    const { children } = this.props;
    if (this.shouldRender()) {
      return <div>{children}</div>;
    }

    return null;
  }
}

FetchedMetrics.propTypes = {
  // Added through connect
  metrics: PropTypes.object,

  // Options
  types: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  children: PropTypes.node,
};

FetchedMetrics.defaultProps = {
  waitFor: [],
};

FetchedMetrics.childContextTypes = {
  metricData: PropTypes.object,
};

export default FetchedMetrics;
