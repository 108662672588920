import BookOfBusiness from "./BookOfBusiness.js";

import {
  authenticationOfTypeRequired,
  authenticationRequired,
} from "../../../shared/routes/Authentication/Login/sagas";
import reducer from "./reducer";
import watchBookOfBusinessSaga from "./sagas";

export default {
  "/book-of-business(/:customerId)": {
    component: BookOfBusiness,
    reducer: {
      ui: {
        BookOfBusiness: reducer,
      },
    },
    saga: watchBookOfBusinessSaga,
    title: "Book Of Business",
    *onEnter() {
      yield authenticationRequired();
      yield authenticationOfTypeRequired(["admin"]);
    },
  },
};
