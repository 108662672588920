import { takeLatest, put } from "redux-saga/effects";

import { callAPI } from "../../../../../../../../shared/utils/sagas";
import { setFleetingFormMeta } from "../../../../../../../../shared/utils/form";

import { updatePasswordForPeer, didUpdatePassword } from "./actions";
import { updatePasswordForPeer as updatePasswordForPeerAPI } from "./api";

export function* watchUpdatePasswordSaga() {
  yield takeLatest(
    updatePasswordForPeer,
    callAPI(updatePasswordForPeerAPI, didUpdatePassword)
  );
  yield takeLatest(didUpdatePassword, function* () {
    yield put(
      setFleetingFormMeta("resetPassword", {
        message: "Password has been updated.",
      })
    );
  });
}
