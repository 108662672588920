import { connect } from "react-redux";

import KPIStats from "./KPIStats.jsx";
import { requestKPIStats } from "./actions";
import { stats } from "./reducer";

function mapStateToProps(state) {
  return {
    stats: stats(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestKPIStats: (adminId) => dispatch(requestKPIStats(adminId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KPIStats);
