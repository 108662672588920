import { connect } from "react-redux";
import { userType } from "../../../../../../shared/routes/Authentication/Login/reducer";
import Basic from "./Basic.jsx";
import { showPanel } from "../../../../../../shared/components/ModalPanel/actions";

function mapStateToProps(state) {
  return {
    authenticatedUserType: userType(state),
  };
}

const dispatch = {
  onAddCareTeamMember: () => showPanel("addCareTeamMember"),
  onEditCareTeamMember: (email) => showPanel(`updateCareTeamMember${email}`), // TODO: switch param to ID
  onRemoveCareTeamMember: (email) => showPanel(`removeCareTeamMember${email}`),
  onRelease: () => showPanel("release"),
  onEditOwner: () => showPanel("updateOwner"),
  onEditEmail: () => showPanel("updateEmail"),
  onEditPhone: () => showPanel("updatePhone"),
  onEditProcedure: () => showPanel("updateProcedureType"),
  onEditSurgeryComplications: () => showPanel("updateSurgeryComplications"),
  onEditSurgeryDate: () => showPanel("updateSurgeryDate"),
  onEditAssignedTo: () => showPanel("updateAssignedTo"),
  onEditBirthDate: () => showPanel("updateBirthDate"),
  onEditName: () => showPanel("updateName"),
  onTransitionPatient: () => showPanel("transitionProgram"),
  onChangePartner: () => showPanel("changePartner"),
  onChangeLanguage: () => showPanel("updateLanguage"),
};

export default connect(mapStateToProps, dispatch)(Basic);
