import { useEffect, useRef, useState } from "react";
import { fetchWorkerInteractions, WorkerEvent } from "./api";
import styles from "./CardInteractionsWorker.module.css";
import EventItem from "./components/EventItem.component";

const CardInteractionsWorker = (props: any) => {
  const perPage = 100;

  const [workerEvents, setWorkerEvents] = useState<WorkerEvent[]>([]);
  const [page, setPage] = useState(0);
  const [isLoadingEvents, setIsLoadingEvents] = useState(true);
  const [loadedAllEvents, setLoadedAllEvents] = useState(false);

  const scrollableElement = useRef<HTMLUListElement>(null);

  // On load, fetch events.
  useEffect(() => {
    loadWorkerInteractions();
  }, []);

  // Fetch events, load next page, check if no more entries.
  const loadWorkerInteractions = async () => {
    setIsLoadingEvents(true);

    const workerInteractionsRes = await fetchWorkerInteractions({
      workerId: props?.workerId,
      page: page,
      perPage: perPage,
    });
    setPage(page + 1);
    setWorkerEvents([...workerEvents, ...workerInteractionsRes.data]);
    if (workerInteractionsRes.data.length === 0) {
      setLoadedAllEvents(true);
    }

    setIsLoadingEvents(false);
  };

  // On scroll, check if near bottom, load more events if so.
  const handleOnScroll = async () => {
    // Don't bother calculating if not necessary
    if (!isLoadingEvents && !loadedAllEvents && scrollableElement.current) {
      const bottom =
        scrollableElement.current.scrollHeight -
          scrollableElement.current.clientHeight <=
        scrollableElement.current.scrollTop + 50;
      if (bottom) {
        await loadWorkerInteractions();
      }
    }
  };

  return (
    <div className={styles["card-interactions-worker"]}>
      <h2>Program content interacted with</h2>
      <ul onScroll={(e) => handleOnScroll()} ref={scrollableElement}>
        {workerEvents.map((event) => {
          return (
            <EventItem
              key={event.createdAt}
              event={event}
              onAction={() => props.showEventCard(event)}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default CardInteractionsWorker;
