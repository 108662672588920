import { createAction } from "redux-actions";

const prefixed = (val) => `Peer/Actions/UpdateSurgeryComplications::${val}`;

export const updateSurgeryComplications = createAction(
  prefixed("UPDATE_SURGERY_COMPLICATIONS"),
  (peerId, hasComplications) => ({ peerId, hasComplications })
);
export const didUpdateSurgeryComplications = createAction(
  prefixed("DID_UPDATE_SURGERY_COMPLICATIONS"),
  (peerId, hasComplications) => ({ peerId, hasComplications })
);
export const setUpdateSurgeryComplicationsMessage = createAction(
  prefixed("SET_UPDATE_SURGERY_COMPLICATIONS_MESSAGE"),
  (message) => message
);
