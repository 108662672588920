import { all, put, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../../shared/app/requestErrorHandler";
import { callAPI } from "../../shared/utils/sagas";
import {
  didFetchProgram,
  didFetchProgramsList,
  fetchProgram,
  fetchProgramsList,
} from "./actions";
import {
  fetchProgram as fetchProgramAPI,
  fetchProgramsList as fetchProgramsListAPI,
} from "./api";
import watchBottomBarSaga from "./components/BottomBar/saga";
import watchPinnedCardsSaga from "./components/ProgramsForm/component/PinnedCards/saga";
import watchProgramsFormSaga from "./components/ProgramsForm/saga";
import watchProgramsListSaga from "./components/ProgramsList/saga";

function* fetchProgramsListSaga(action) {
  try {
    const response = yield fetchProgramsListAPI(action.payload);
    const count = response.headers["x-total-count"];
    yield put(didFetchProgramsList(response.data, count));
  } catch (e) {
    yield handleApiError(e);
  }
}

function* watchProgramsSaga() {
  yield all([
    takeLatest(fetchProgram, callAPI(fetchProgramAPI, didFetchProgram)),
    takeLatest(fetchProgramsList, fetchProgramsListSaga),
  ]);
}

export default function* () {
  yield all([
    watchProgramsSaga(),
    watchProgramsListSaga(),
    watchBottomBarSaga(),
    watchProgramsFormSaga(),
    watchPinnedCardsSaga(),
  ]);
}
